import React from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import { GET, extractData } from '../../../../Consumer';
import { toLocalTimeString } from '../../../../Utilities';
import NoResults from '../../../common/Tables/NoResults';
import Pagination from '../../../common/Tables/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload} from '@fortawesome/free-solid-svg-icons';
import Actions from '../../../common/Tables/Actions';
import Endpoints from '../../../common/Endpoints';
import { isNullOrEmptyGuid } from '../../../../Utilities';

import './BulkOrderTable.scss';

const renderStatus = (status) => {
    switch (status) {
        case "Shipped":
        case "Awaiting Collection":
            return <span className="text-status-green">{status}</span>;
        case "Awaiting Fulfilment":
            return <span className="text-status-purple">{status}</span>;
        case "Fulfilment In Progress":
            return <span className="text-status-blue">{status}</span>;
        case "Cancelled":
        case "Unprocessed":
            return <span className="text-status-red">{status}</span>;
        default:
            return <span className="text-status-grey">{status}</span>;
    }
}
const getLinkPackingSlip = (bulkorderId) =>{

    if (!isNullOrEmptyGuid(bulkorderId)) {
        
        const url = new URL(Endpoints.INTERNAL.GET.BULK_ORDER_PACKING_SLIP + bulkorderId) ;
        
        return GET(url)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                window.open(data, '_blank');                
            })
            .catch(error => console.log(error));
    }
}

const getActions = (param) => {
    const packingSlip =  <Button  variant="link" onClick={() => getLinkPackingSlip(param)}><FontAwesomeIcon icon={faDownload} />Packing Slip</Button>;
    const rowActions = [];
    rowActions.push({ action: packingSlip, danger: false });

    return rowActions;
}

const BulkOrderTableRow = ({ bulkOrder, handleViewBulkOrderClick, showCancel, handleCancelClick, showPackingSlip}) =>
        <tr>
            <td>{bulkOrder.purchaseOrderNumber}</td>
            <td>{bulkOrder.totalOrderItems}</td>
            <td>{toLocalTimeString(bulkOrder.createdDate)}</td>
            <td>{renderStatus(bulkOrder.status)}</td>
            <td>{bulkOrder.requiresShipping ? "Selazar Fulfilled" : "Self Fulfilled"}</td>
            {showCancel && <td className="text-center py-2">
                <Button variant="danger" size="sm" onClick={() => handleCancelClick(bulkOrder.id, bulkOrder.purchaseOrderNumber)}>Cancel</Button>
            </td>}
            <td className="text-center py-2">
                <Button className="link-button" onClick={() => handleViewBulkOrderClick(bulkOrder.id)} variant="secondary" size="sm" >View Order</Button>
            </td>
            
            {showPackingSlip && <td className="text-center py-2">
                <Actions title="" actions={getActions(bulkOrder.id)} />
            </td>}
        </tr>

const BulkOrderTable = ({ bulkOrders, handleViewBulkOrderClick, showCancel = false, handleCancelClick, onNext, onPrevious, onStart, onEnd, pageIndex, pageCount ,showPackingSlip=false}) =>
    <Card className="card-table">
        <div className="table-responsive">
            <Table striped bordered size="sm" className={!bulkOrders.length ? "table-empty" : ""}>
                <thead>
                    <tr>
                        <th>Purchase Order Number</th>
                        <th>Item Total</th>
                        <th>Date Created</th>
                        <th>Status</th>
                        <th>Shipping</th>
                        {showCancel && <th></th>}
                        <th></th>
                        {showPackingSlip && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {bulkOrders.length 
                        ? bulkOrders.map(bulkOrder => <BulkOrderTableRow key={bulkOrder.id} bulkOrder={bulkOrder} handleViewBulkOrderClick={handleViewBulkOrderClick} showCancel={showCancel} handleCancelClick={handleCancelClick} showPackingSlip={showPackingSlip} />) 
                        : <NoResults colSpan="7" text="No bulk orders available" /> 
                    }
                </tbody>
            </Table>
            <Pagination onNext={onNext} onPrevious={onPrevious} onStart={onStart} onEnd={onEnd} pageIndex={pageIndex} pageCount={pageCount} />
        </div>
    </Card>

export default BulkOrderTable;