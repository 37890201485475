import React, { Component, Fragment } from 'react';
import { Button, Col, Form, FormGroup, FormControl, Container, Row, InputGroup } from 'react-bootstrap';
import { GET, extractData } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import Header from '../../common/Header';
import { RETAILER_ORDER_SUB_NAV_LIST } from '../../common/constants'
import { format } from 'date-fns'
import { ErrorAlert } from '../../common/Alert';
import LoadingBar from '../../common/LoadingBar';

const TrackingInformation = ({ error, message }) =>
    <Fragment>
        <h4>Tracking Reference</h4>
        {error && <ErrorAlert errorMessage={message} />}
        <section>
            <p className="title">What is tracking reference?</p>
            <p>This allows you to download a CSV export of the tracking numbers for each order between two dates.</p>
        </section>
    </Fragment>

const TrackingForm = ({ handleSubmit, filter, handleDateChange }) =>
    <Form onSubmit={handleSubmit}>
        <FormGroup>
            <p className="mb-0"><strong>Date Range</strong></p>
            <p className="mb-2">Enter the date range that you require tracking references for</p>
            <InputGroup>
                <FormControl id="dateFromField" type="date" name="dateFrom" value={filter.dateFrom} onChange={(e) => handleDateChange("dateFrom", e.target.value)} />
                <FormControl id="dateToField" type="date" name="dateTo" value={filter.dateTo} onChange={(e) => handleDateChange("dateTo", e.target.value)} />
            </InputGroup>
        </FormGroup>
        <FormGroup className="mt-med">
            <Button variant="primary" className="float-right" type="submit">Export Tracking References</Button>
        </FormGroup>
    </Form>

class TrackingReference extends Component {
    state = {
        loading: true,
        error: false,
        companyName: '',
        filter:
        {
            dateFrom: '',
            dateTo: ''
        },
        message: ''
    };

    async componentDidMount() {
        await this.getCompanyName();
        this.setState({ loading: false });
    }

    getCompanyName = () => {
        const user = JSON.parse(localStorage.getItem('user'));

        GET(Endpoints.COMPANY.GET.COMPANY_BY_ID + user.companyID)
            .then(res => {
                if (res && res.ok) return res.json();
            })
            .then(result => {
                if (result) {
                    this.setState({ companyName: extractData(result).name });
                }
            });
    }

    handleDateChange = (name, value) => this.setState(prevState => ({ filter: { ...prevState.filter, [name]: value } }));

    handleSubmit = (e) => {
        e.preventDefault();
        const filter = { ...this.state.filter };
        const { companyName } = this.state;
        let url = new URL(Endpoints.ORDERS.GET.TRACKING);

        if (this.validate(filter)) {

            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));

            this.setState({ loading: true })

            return GET(url)
                .then(response => {
                    if (response.ok) return response.blob();
                })
                .then(result => {
                    if (result) {
                        const date = new Date();
                        const downloadLink = document.createElement("a");
                        const blob = new Blob(["\ufeff", result]);
                        const url = URL.createObjectURL(blob);
                        downloadLink.href = url;
                        downloadLink.download = companyName + "TrackingExport_" + format(date, 'DDMMYYYY') + ".csv";

                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                        this.setState({ loading: false, error: false });
                    } else this.setState({ loading: false, error: true, message: "We could not find tracking information for the given date range." });
                })
                .catch(error => console.log(error))
                .finally(() => this.setState({ loading: false }));
        }
    }

    validate = (filter) => {
        if (filter.dateTo && filter.dateFrom && (filter.dateTo < filter.dateFrom)) {
            this.setState({ error: true, message: 'Date To must be after Date From.' });
            return false;
        }

        return true;
    }

    render() {
        const { error, message, filter, loading } = this.state;

        return (
            <Fragment>
                {loading
                    ? <LoadingBar />
                    : <Fragment>
                        <Header title="Orders" subNavList={RETAILER_ORDER_SUB_NAV_LIST} activeKey="Tracking" />
                        <Container fluid>
                            <Row>
                                <Col sm="12" md="6">
                                    <TrackingInformation error={error} message={message} />
                                    <TrackingForm handleSubmit={this.handleSubmit} filter={filter} handleDateChange={this.handleDateChange} />
                                </Col>
                            </Row>
                        </Container>
                    </Fragment>
                }
            </Fragment>
        );
    }
}

export default TrackingReference;