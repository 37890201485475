import React, { Fragment } from 'react';
import { Row, Col, Button, Container, FormGroup } from 'react-bootstrap';
import { SuccessAlert } from '../../common/Alert';
import Confirm from '../../common/Confirm';
import { checkPermission } from '../../../Utilities';

import './Payments.scss';

const CONFIRMATION_TITLE = "Remove Card Confirmation";
const CONFIRMATION_MESSAGE = "Are you sure you would like to remove your payment card?";
const CONFIRMATION_BUTTON_TEXT = "Remove";
const REMOVE_CARD_MESSAGE = "Card successfully disabled";
const EXISTING_CARD_SUMMARY_LENGTH = 4;
const MASK_NUMBER_STRING = "xxxxxxxxxxxx";

const Confirmation = () =>
    <SuccessAlert successMessage={REMOVE_CARD_MESSAGE}></SuccessAlert>

const CardActions = ({ hideShowRemoveModal, onReplaceCardClick, active }) =>
    <FormGroup className="float-right mt-4">
        {active && checkPermission('NewCard', <Button variant="link" className="mr-2" type="button" onClick={hideShowRemoveModal}>Remove Card</Button>)}
        {checkPermission('NewCard', <Button variant="secondary" onClick={onReplaceCardClick}>Replace Card</Button>)}
    </FormGroup>

const Status = ({ active }) =>
    <div>
        <h5>Card Status</h5>
        {active
            ? <span className="text-status-green"><strong>Active</strong></span>
            : <span className="text-status-red"><strong>Inactive</strong></span>
        }
    </div>

const Card = ({ details }) =>
    <div className={`payment-card ${details.active ? "active" : "inactive"}`}>
        <p className="payment-card-title">Your Card Details</p>
        <div className="payment-card-mark"></div>
        <p className="payment-card-number">{details.cardSummary !== null ? details.cardSummary.length === EXISTING_CARD_SUMMARY_LENGTH ? MASK_NUMBER_STRING + details.cardSummary : details.cardSummary : null}</p>
        <p className="payment-card-name">{details.name}</p>
    </div>

const CardDetails = ({ details, showRemoveModal, hideShowRemoveModal, onRemoveCardAction, onReplaceCardClick, showRemoveCardConfirmation }) =>
    <Container fluid>
        <Fragment>
            {showRemoveCardConfirmation &&
                <Row className="px-2">
                    <Col sm={12} md={6}>
                        <Confirmation showRemoveCardConfirmation={showRemoveCardConfirmation} />
                    </Col>
                </Row>
            }
            <Row className="mb-4">
                <Col sm={12} md={12}>
                    <Card details={details} />
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={6}>
                    <Status active={details.active} />
                    <CardActions hideShowRemoveModal={hideShowRemoveModal} onReplaceCardClick={onReplaceCardClick} active={details.active} />
                </Col>
            </Row>
            <Confirm
                title={CONFIRMATION_TITLE}
                text={CONFIRMATION_MESSAGE}
                buttonVariant="danger"
                handleConfirmClose={hideShowRemoveModal}
                handleConfirmAction={onRemoveCardAction}
                buttonText={CONFIRMATION_BUTTON_TEXT}
                show={showRemoveModal}
            />
        </Fragment>
    </Container>

export default CardDetails
