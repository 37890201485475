import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { PREFERENCES_SUB_NAV_LIST } from '../../../common/constants'
import Header from '../../../common/Header';
import Breadcrumbs from '../../../common/Breadcrumbs';
import FormValidator from '../../../common/FormValidator';
import LoadingBar from '../../../common/LoadingBar';
import { SuccessAlert, ErrorAlert } from '../../../common/Alert';
import Endpoints from '../../../common/Endpoints';
import { extractData, PUT, GET } from '../../../../Consumer';
import { isNullOrEmptyGuid } from '../../../../Utilities';
import { CustomPackageForm, CustomPackageFormActions, CancelModal } from './Snippets';

const GET_BOX_ERROR = "No custom box found. Please try again.";
const UPDATE_BOX_ERROR = "Unable to update custom box. Ensure another custom box does not have the same name and that it is disabled. Please try again.";

const breadcrumbs = [
    { text: "Packaging", link: "/retailer/preferences/packaging" },
    { text: "Custom Packaging", link: "/retailer/preferences/packaging/custompackaging" },
    { text: "Edit Custom Box", link: null }
];

class EditCustomBox extends Component {
    constructor(props) {
        super(props);

        this.boxValidator = new FormValidator([
            {
                field: 'name',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a package friendly name'
            },
            {
                field: 'height',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 10.00 }],
                message: 'Enter a minimum of 10 height for the box'
            },
            {
                field: 'width',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 10.00 }],
                message: 'Enter a minimum of 10 width for the box'
            },
            {
                field: 'depth',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 10.00 }],
                message: 'Enter a minimum of 10 depth for the box'
            },
            {
                field: 'maxWeight',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Enter the weight limit of the box'
            },
        ]);

        this.state = {
            loading: true,
            id: props.match.params.id || null,
            showCancelModal: false,
            showSuccess: false,
            errorMessage: "",
            boxValidation: this.boxValidator.valid(),
            box: {}
        };
    }

    async componentDidMount() {
        await this.fetchCustomBox();
        this.setState({ loading: false });
    }

    fetchCustomBox = async () => {
        const { id } = this.state;
        if (!isNullOrEmptyGuid(id)) {
            return GET(Endpoints.PACKAGING.GET.BOX + id)
                .then(response => {
                    if (response.ok) return response.json();
                })
                .then(result => {
                    const data = extractData(result) || {};
                    this.setState({ box: data ? data : {}, errorMessage: data ? "" : GET_BOX_ERROR });
                })
                .catch(error => {
                    this.setState({ errorMessage: GET_BOX_ERROR });
                    console.log(error); 
                });
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            box: {
                ...prevState.box,
                ...{ [name]: value }
            }
        }));
    }

    handleShowHideCancelModal = () => this.setState(prevState => ({ showCancelModal: !prevState.showCancelModal }));

    handleUpdatePackage = async () => {
        this.setState({ loading: true });

        const { box } = this.state;
        const validation = this.boxValidator.validate(box);
        this.setState({ boxValidation: validation });

        if (validation.isValid) {
            return await PUT(Endpoints.PACKAGING.PUT.BOX, box)
                .then(response => {
                    if (response && response.ok) return response.json();
                })
                .then(results => {
                    const updated = extractData(results);
                    updated
                        ? this.setState({ showSuccess: true, errorMessage: "", loading: false })
                        : this.setState({ errorMessage: UPDATE_BOX_ERROR, showSuccess: false, loading: false });
                })
                .catch(error => {
                    this.setState({ errorMessage: UPDATE_BOX_ERROR, showSuccess: false, loading: false });
                    console.log(error);
                });
        }
        this.setState({ loading: false });
    }

    render() {
        const { loading, box, boxValidation, errorMessage, showSuccess, showCancelModal } = this.state;
        return (
            <React.Fragment>
                <Header title="Preferences" subNavList={PREFERENCES_SUB_NAV_LIST} activeKey="Packaging" />
                {loading ? <LoadingBar /> :
                    <Container fluid>
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                        <Row>
                            <Col sm={12} md={6}>
                                <h5 className="mb-3">Edit Custom Box</h5>
                                {errorMessage && <ErrorAlert errorMessage={errorMessage} />}
                                {showSuccess && <SuccessAlert successMessage="Successfully updated custom box." />}
                                <CustomPackageForm isBox={true} packageDetails={box} packageValidation={boxValidation} handleInputChange={this.handleInputChange} />
                                <CustomPackageFormActions buttonText="Update Custom Box" handleCancel={this.handleShowHideCancelModal} handleAction={this.handleUpdatePackage} />
                            </Col>
                        </Row>
                        <CancelModal isBox={true} isAdd={false} handleClose={this.handleShowHideCancelModal} showModal={showCancelModal} handleConfirm={() => this.props.history.push("/retailer/preferences/packaging/custompackaging/view/boxes")} />
                    </Container>}
            </React.Fragment>
        )
    }
}

export default EditCustomBox;