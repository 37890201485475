export const SAVE_CREATE_STOCKBOX_SUCCESS_MESSAGE = 'New stock box added';
export const SAVE_CREATE_STOCKBOX_GENERIC_ERROR_MESSAGE = 'Something went wrong, Please try again.';
export const EMPTY_BOX_NAME_ERROR = 'Please enter box name.';
export const EMPTY_BOX_HEIGHT_ERROR = 'Please enter height.';
export const EMPTY_BOX_WIDTH_ERROR = 'Please enter width.';
export const EMPTY_BOX_DEPTH_ERROR = 'Please enter depth.';
export const INVALID_BOX_DIMENSION_ERROR = 'Value must be 1 or greater.';
export const INVALID_BOX_DIMENSION_DECIMAL_ERROR = 'Value must be whole number.';
export const DUPLICATED_BOX_NAME_MESSAGE = 'Box name already exists. Please try another name.';
export const STOCKBOX_LIMIT_MESSAGE = 'Your stock box library has reached its limit of 200 boxes. Please delete a box before trying to add a new one.';
export const CONFIRM_DELETE_WARNING_MESSAGE = 'Are you sure you want to delete this stockbox?';
export const DELETE_SUCCESS_MESSAGE = 'Stock box has been deleted';
export const UPDATE_SUCCESS_MESSAGE = 'Stock box details have been updated';
export const PFP_DIMENSIONS_MESSAGE = 'This stock box qualifies for PFP. If you wish to continue, click "Save" to continue, or "Back" to amend the dimensions.';