import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Table, Card, Button } from 'react-bootstrap';
import { extractData, GET, DELETE, PUT } from '../../../Consumer';
import { checkPermission, hasPermission, toLocalTimeString } from '../../../Utilities';
import Endpoints from '../../common/Endpoints';
import LoadingBar from '../../common/LoadingBar';
import SelazarLinkContainer from '../../common/SelazarLinkContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faUserLock, faUserTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Actions from '../../common/Tables/Actions';
import Confirm from '../../common/Confirm';
import Pagination from '../../common/Tables/Pagination';
import UserFilter from './UserFilter';
import { ErrorAlert } from '../../common/Alert';
import Header from '../../common/Header';
import { USER_SUB_NAV_LIST, USER_RESTRICTED_SUB_NAV_LIST } from '../../common/constants';

const USER_RETRIEVAL_ERROR = "Could not retrieve users";
const TOGGLE_ERROR = "Could not toggle user";
const DELETE_ERROR = "Could not delete user";

class Users extends Component {

    state = {
        loading: true,
        totalCount: 0,
        pageIndex: 1,
        pageCount: 1,
        users: [],
        showDeleteUserConfirmation: false,
        showToggleUserConfirmation: false,
        selectedUser: null,
        toggleMessage: "",
        deleteMessage: "",
        filter: {
            name: "",
            sortBy: ""
        }
    };

    componentDidMount = () => this.fetchUsers();

    fetchUsers = () => {
        const { filter, pageIndex } = this.state;
        const url = new URL(Endpoints.GET_FILTERED_USERS);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)

        return GET(url)
            .then(response => response.json())
            .then(result => {
                const data = extractData(result);
                if (data === null) this.setState({ users: [], warning: result.message || USER_RETRIEVAL_ERROR, loading: false });
                else if (data === undefined) this.setState({ users: [], warning: USER_RETRIEVAL_ERROR, loading: false });
                else this.setState({ users: data.users, totalCount: data.total, pageCount: data.pageCount, loading: false });
            })
            .catch(error => {
                this.setState({ warning: USER_RETRIEVAL_ERROR, loading: false });
                console.log(error);
            });
    }

    getActions = (user) => {
        const editUser = hasPermission('EditUser') && <Button onClick={() => this.props.history.push(`/company/users/editdetails/${user.id}`)} variant="link"><FontAwesomeIcon icon={faEdit} />Edit Details</Button>;

        const currentUser = JSON.parse(localStorage.getItem('user'));
        const isSameUser = currentUser.id === user.id;
        const editPermissions = hasPermission('EditPermission') && <Button variant="link" onClick={() => this.props.history.push(`/users/permissions/${user.id}`)}><FontAwesomeIcon icon={faUserLock} />Permissions</Button>;
        const toggleUser = !isSameUser && hasPermission('ToggleUser') && <Button variant="link" onClick={() => this.handleToggleShowHide(user)}><FontAwesomeIcon icon={faUserTimes} />{user.active ? "Deactivate" : "Activate"}</Button>;
        const deleteUser = !isSameUser && hasPermission('DeleteUser') && <Button variant="link" className="action-danger" onClick={() => this.handleDeleteShowHide(user)}><FontAwesomeIcon icon={faTrashAlt} />Remove</Button>;

        const rowActions = [];
        rowActions.push({ action: editUser, danger: false });
        editPermissions && rowActions.push({ action: editPermissions, danger: false });
        toggleUser && rowActions.push({ action: toggleUser, danger: false });
        deleteUser && rowActions.push({ action: deleteUser, danger: true });

        return rowActions;
    }

    handleToggleShowHide = (user) => {
        user ?
            this.setState((prevState) => ({ showToggleUserConfirmation: !prevState.showToggleUserConfirmation, selectedUser: user, toggleMessage: `Are you sure you want to ${user.active ? 'deactivate' : 'activate'} ${user.forename} ${user.surname}?` })) :
            this.setState((prevState) => ({ showToggleUserConfirmation: !prevState.showToggleUserConfirmation }));
    }

    handleDeleteShowHide = (user) => {
        user ?
            this.setState((prevState) => ({ showDeleteUserConfirmation: !prevState.showDeleteUserConfirmation, selectedUser: user, deleteMessage: `Are you sure you want to delete ${user.forename} ${user.surname}?` })) :
            this.setState((prevState) => ({ showDeleteUserConfirmation: !prevState.showDeleteUserConfirmation }));
    }

    handleToggleAction = () => {
        const { selectedUser } = this.state;
        if (Object.keys(selectedUser).indexOf('id') === -1) {
            this.setState({ warning: 'Unable to toggle user' });
            return;
        }

        this.setState({ loading: true, showToggleUserConfirmation: false });

        return PUT(Endpoints.TOGGLE_USER + selectedUser.id)
            .then(response => response.json())
            .then(result => {
                const data = extractData(result);
                if (data === null) this.setState({ warning: result.message || TOGGLE_ERROR, loading: false });
                else if (data === undefined) this.setState({ warning: TOGGLE_ERROR, loading: false });
                else this.fetchUsers();
            })
            .catch(error => {
                this.setState({ warning: TOGGLE_ERROR, loading: false });
                console.log(error);
            });
    }

    handleDeleteAction = () => {
        const { selectedUser } = this.state;
        if (Object.keys(selectedUser).indexOf('id') === -1) {
            this.setState({ warning: 'Unable to delete user' });
            return;
        }

        this.setState({ loading: true, showDeleteUserConfirmation: false });

        return DELETE(Endpoints.DELETE_USER + selectedUser.id)
            .then(response => response.json())
            .then(result => {
                const data = extractData(result);
                if (data === null) this.setState({ warning: result.message || DELETE_ERROR, loading: false });
                else if (data === undefined) this.setState({ warning: DELETE_ERROR, loading: false });
                else this.fetchUsers();
            })
            .catch(error => {
                this.setState({ warning: DELETE_ERROR, loading: false });
                console.log(error);
            });
    }

    onNext = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.fetchUsers);
        }
    }

    onPrevious = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.fetchUsers);
        }
    }

    onStart = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true }, this.fetchUsers);
        }
    }

    onEnd = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true }, this.fetchUsers);
        }
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    onClear = () => {
        this.setState({
            users: [],
            loading: true,
            filter: {
                name: "",
                sortBy: ""
            }
        }, this.fetchUsers);
    }

    onSearch = (e) => {
        e.preventDefault();
        this.setState({ users: [], loading: true }, this.fetchUsers);
    }

    render() {
        const { loading, users, selectedUser, showToggleUserConfirmation, showDeleteUserConfirmation, toggleMessage, deleteMessage, pageIndex, pageCount, warning } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <Header title="Users" subNavList={hasPermission("AddUser") ? USER_SUB_NAV_LIST : USER_RESTRICTED_SUB_NAV_LIST} activeKey="Users" />
                    <Container fluid>
                        <Row className="my-3">
                            <Col>
                                <UserFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...this.state.filter} />
                            </Col>
                        </Row>
                        {warning && <Row>
                            <Col md="6">
                                <ErrorAlert errorMessage={warning} />
                            </Col>
                        </Row>}
                        <Row className="mt-4">
                            <Col md={12}>
                                <Card className="card-table">
                                    <div className="table-responsive">
                                        <Table striped bordered size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Email</th>
                                                    <th>Full Name</th>
                                                    <th>Last Login</th>
                                                    <th>Status</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.length ? users.map((u) => {
                                                    return (
                                                        <tr key={u.id}>
                                                            <td>{u.email}</td>
                                                            <td>{u.forename} {u.surname}</td>
                                                            <td>{u.lastLogin ? toLocalTimeString(u.lastLogin) : "No Logins"}</td>
                                                            <td>{u.active ? <span className="text-status-green">Active</span> : <span className="text-status-red">Deactivated</span>}</td>
                                                            <td className="text-center py-2">
                                                                {checkPermission("ViewUser", <SelazarLinkContainer to={`/company/users/account/${u.id}`} exact>
                                                                    <Button className="link-button" variant="secondary" size="sm">View User</Button>
                                                                </SelazarLinkContainer>)}
                                                            </td>
                                                            <td><Actions title="Edit User" actions={this.getActions(u)} /></td>
                                                        </tr>);
                                                }) : <tr><td colSpan={7}>No users to show.</td></tr>}
                                            </tbody>
                                        </Table>
                                        <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onStart={this.onStart} onEnd={this.onEnd} pageIndex={pageIndex} pageCount={pageCount} />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        {showToggleUserConfirmation && <Confirm title={selectedUser.active ? "Confirm Deactivation" : "Confirm Activation"} text={toggleMessage} buttonText={selectedUser.active ? "Deactivate" : "Activate"} buttonVariant={selectedUser.active ? "danger" : "secondary"} handleConfirmClose={this.handleToggleShowHide} handleConfirmAction={this.handleToggleAction} />}
                        {showDeleteUserConfirmation && <Confirm title={"Confirm Deletion"} text={deleteMessage} buttonText={"Delete"} buttonVariant="danger" handleConfirmClose={this.handleDeleteShowHide} handleConfirmAction={this.handleDeleteAction} />}
                    </Container>
                </Fragment>
        );
    }
}

export default Users;