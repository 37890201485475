import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Endpoints from '../../common/Endpoints';
import { GET, extractData, POST } from '../../../Consumer';
import FormValidator from '../../common/FormValidator';
import LoadingBar from '../../common/LoadingBar';
import Header from '../../common/Header';
import { ErrorAlert, SuccessAlert } from '../../common/Alert';
import { USER_SUB_NAV_LIST, STRONG_PASSWORD_PATTERN, NUMBER_PATTERN, SPECIAL_CHAR_PATTERN, LENGTH_PATTERN } from '../../common/constants';
import UserForm from './UserForm';

const ROLE_OPTIONS_ERROR_MESSAGE = "Could not retrieve role options";
const SUCCESS_MESSAGE = "Your user has been created successfully."

const AddSuccessful = () =>
    <Fragment>
        <h5 className="mt-2 mb-3">Confirmation</h5>
        <SuccessAlert successMessage={SUCCESS_MESSAGE} />
        <Link to='/company/users' className="link-button">
            <Button variant="primary" className="float-right">Return to Users</Button>
        </Link>
    </Fragment>

class AddUser extends Component {

    validator = new FormValidator([
        {
            field: 'forename',
            method: 'isEmpty',
            validWhen: false,
            message: 'Forename is required'
        },
        {
            field: 'surname',
            method: 'isEmpty',
            validWhen: false,
            message: 'Surname is required'
        },
        {
            field: 'email',
            method: 'isEmpty',
            validWhen: false,
            message: 'Email is required'
        },
        {
            field: 'password',
            method: 'matches',
            args: [STRONG_PASSWORD_PATTERN],
            validWhen: true,
            message: 'Password must contain at least one number, one special character and at least 6 characters'
        },
        {
            field: 'password',
            method: 'isLength',
            args: [{min: 6, max: 50}],
            validWhen: true,
            message: 'Password must be between 6 and 50 characters long'
        }
    ]);

    state = {
        loading: true,
        user: {
            id: '',
            forename: '',
            surname: '',
            email: '',
            password: '',
            roles: []
        },
        validationMessages: {
            roles: false,
            passwords: false
        },
        validation: this.validator.valid(),
        roleOptions: [],
        passwordInputType: 'password',
        passwordInputIcon: faEye,
        passwordChecks: {
            number: false,
            specialChar: false,
            length: false
        },
        rolesFetchError: null,
        userSaveErrors: [],
        showSuccess: false
    }

    async componentDidMount() {
        await this.fetchRoleOptions();
        this.setState({ loading: false });
    }

    fetchRoleOptions = () => {
        return GET(Endpoints.GET_ROLE_OPTIONS_FOR_COMPANY)
            .then(response => response.json())
            .then(result => {
                const data = extractData(result);
                if (data === null || data === undefined) this.setState({ roleOptions: [], rolesFetchError: ROLE_OPTIONS_ERROR_MESSAGE });
                else this.setState({ roleOptions: data, rolesFetchError: null });
            })
            .catch(error => {
                this.setState({ rolesFetchError: ROLE_OPTIONS_ERROR_MESSAGE });
                console.log(error);
            });
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password"
            ? this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash })
            : this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    handleInputChange = (e) => {
        const { name, value, type } = e.target;

        if (name === "password") {
            const numberPass = NUMBER_PATTERN.test(value);
            const specialCharPass = SPECIAL_CHAR_PATTERN.test(value);
            const lengthPass = LENGTH_PATTERN.test(value);

            this.setState({
                passwordChecks: {
                    number: numberPass,
                    specialChar: specialCharPass,
                    length: lengthPass
                }
            });
        }

        if (type === 'checkbox') {
            let currRole = this.state.user.roles.indexOf(name);
            if (currRole > -1) {
                this.setState(prevState => ({
                    user: {
                        ...prevState.user,
                        ...{ roles: [...prevState.user.roles.slice(0, currRole), ...prevState.user.roles.slice(currRole + 1)] }
                    }
                }));
            }
            else {
                let newRoles = this.state.user.roles;
                newRoles.push(name);
                this.setState(prevState => ({
                    user: {
                        ...prevState.user,
                        ...{ roles: newRoles }
                    }
                }));
            }
        }
        else {
            this.setState(prevState => ({
                user: {
                    ...prevState.user,
                    ...{ [name]: value }
                }
            }));
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const { user } = this.state;
        if (!user.password) user.password = '';

        const validation = this.validator.validate(user);
        this.setState({ validation: validation });

        if (user.roles.length === 0) {
            validation.isValid = false;
            this.setState({ validationMessages: { roles: true } });
        } else this.setState({ validationMessages: { roles: false } });

        if (validation.isValid) {
            POST(Endpoints.ADD_COMPANY_USER, user)
                .then(response => response.json())
                .then(e => {
                    if (!e.error) {
                        this.setState({ showSuccess: true, userSaveErrors: [], loading: false });
                    } else {
                        let arrays = Object.keys(e).map(k => e[k]);
                        let errors = [].concat.apply([], arrays);
                        this.setState({ userSaveErrors: errors, loading: false });
                    }
                })
                .catch(error => {
                    this.setState({ userSaveErrors: ["Could not add user"], loading: false });
                    console.log(error);
                });
        }
        else this.setState({ loading: false });
    }

    render() {
        const { loading, rolesFetchError, userSaveErrors, user, validation, passwordInputType, passwordInputIcon, passwordChecks, roleOptions, validationMessages, showSuccess } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <Header title="Users" subNavList={USER_SUB_NAV_LIST} activeKey="Add New User" />
                    <Container fluid>
                        <Row>
                            <Col sm={12} md={6}>
                                {showSuccess
                                    ? <AddSuccessful />
                                    : <Fragment>
                                        {rolesFetchError && <ErrorAlert errorMessage={rolesFetchError} />}
                                        {userSaveErrors.length > 0 && <ErrorAlert errorMessage={<Fragment>{userSaveErrors.map((e, i) => e ? <p key={i}>{e}</p> : null)}</Fragment>} />}
                                        <UserForm
                                            handleSubmit={this.handleSubmit}
                                            user={user}
                                            handleInputChange={this.handleInputChange}
                                            validation={validation}
                                            handleShowHidePassword={this.handleShowHidePassword}
                                            passwordInputType={passwordInputType}
                                            passwordInputIcon={passwordInputIcon}
                                            passwordChecks={passwordChecks}
                                            showRoles={true}
                                            roleOptions={roleOptions}
                                            validationMessages={validationMessages}
                                        />
                                    </Fragment>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Fragment>
        )
    }
}

export default AddUser;
