import React, { Fragment, useState, useEffect, useCallback } from "react";
import Endpoints from '../../../common/Endpoints';
import Header from '../../../common/Header';
import LoadingBar from '../../../common/LoadingBar';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { GET_ASYNC, PUT, POST_FILE, extractData } from '../../../../Consumer';
import { PREFERENCES_SUB_NAV_LIST } from '../../../common/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { toLocalDateMonthString } from '../../../../Utilities';
import { SuccessAlert, ErrorAlert } from '../../../common/Alert';
import { GradingActionsView, GradeDescription, Grades, ToggleReturnsActivation, ProductsTable, ConfirmImage } from '../returns/shared/Snippets';
import Confirm from '../../../common/Confirm';
import Pagination from '../../../common/Tables/Pagination';
import { useFilePicker } from 'use-file-picker';

const ERROR_MESSAGE = 'Error. Something has went wrong. Please try again.';
const IMAGE_SUCCESS_MESSAGE = 'Successfully uploaded image.';
const IMAGE_ERROR_MESSAGE = 'Could not upload image. Please try again.';
const ACTIVATE_MESSAGE = 'You have successfully activated returns.';
const DEACTIVATE_MESSAGE = 'You have successfully deactivated returns.';
const DEACTIVATE_TITLE = 'Deactivate Returns';
const DEACTIVATE_TEXT = 'Are you sure you want to deactivate returns? Any returns in process will not be affected.';
const DEACTIVATE_CONFIRM = 'Deactivate Returns';
const MODAL_CANCEL = 'Cancel';

const ReturnPreferences = ({ data, handleClick, disabled }) => {
  const { returnable, selection, lastModified, } = data;
  return (
    <div className={disabled ? "mb-4 disabled-container" : "mb-4 "}>
      <h5 className="mb-3">Return Preferences <span className="ml=1"><Button disabled={disabled} variant="link" className="p-1 ml-2" onClick={() => handleClick(returnable, selection)}>Edit</Button><FontAwesomeIcon size="sm" icon={faEdit} /></span></h5>
      <p><strong>Return Selection</strong></p>
      {returnable ? <p>All products are returnable</p> : <p>All products are non returnable</p>}
      {lastModified != null ? <small>Last updated: {toLocalDateMonthString(lastModified)}</small> : null}
    </div>);
};

const ProductImages = ({ products, handleSelectImage, onNext, onPrevious, onStart, onEnd, pageIndex, pageCount, errors, uploadError, uploadErrorMessage, uploadSuccess }) => {
  return (
    <div className="mb-4">
      <h5 className="mb-3">Product Images Required</h5>
      <p>Please add a photo to each product. Returns will not be activated until all products have an image attached.</p>
      <div className="form-input-description">
        <p>Max image size is 1MB</p>
      </div>
      {uploadSuccess && <SuccessAlert successMessage={IMAGE_SUCCESS_MESSAGE} />}
      {uploadError && <ErrorAlert errorMessage={ uploadErrorMessage ? `Could not upload image: ${uploadErrorMessage}. Please try again.`: IMAGE_ERROR_MESSAGE} />}
      {errors.length > 0 && 
      <div>
        {errors[0].fileSizeToolarge && <ErrorAlert errorMessage={'File size is too large'} />}
        {errors[0].readerError && <ErrorAlert errorMessage={'Problem occured while reading file'} />}
        {errors[0].maxLimitExceeded && <ErrorAlert errorMessage={'Too many files'} />}
      </div>}
      <Card className="card-table">
        <div className="table-responsive">
          <ProductsTable products={products} handleSelectImage={handleSelectImage} />
          <Pagination onNext={onNext} onPrevious={onPrevious} onStart={onStart} onEnd={onEnd} pageIndex={pageIndex} pageCount={pageCount} />
        </div>
      </Card>
    </div>);
};

const Returns = ({ location: { state }, history }) => {

  //#region State
  const [loadingData, setLoadingData] = useState(true);
  const [successEditReturnPref, setSuccessEditReturnPref] = useState(false);
  const [successMessage, setSuccessMessage] = useState("")
  const [error] = useState(state?.error || false);
  const [uploadError, setUploadError] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [activationButtonToggled, setActivationButtonToggled] = useState(false);
  const [productsFetched, setProductsFetched] = useState(false);
  
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [activationToggled, setActivationToggled] = useState(false);
  const [showDeactivateModel, setShowDeactivateModel] = useState(false);
  const [showProductTable, setShowProductTable] = useState(false);
  const [retailerReturnConfiguration, setRetailerReturnConfiguration] = useState(null);
  const [products, setProducts] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [returnPreferences, setReturnPreferences] = useState(null);
  const [gradingOptions, setGradingOptions] = useState(null);
  const [currentGrading, setCurrentGrading] = useState(null);

  const [openFileSelector, { errors, plainFiles, clear } ] = useFilePicker({
    multiple: true,
    accept: "image/*",
    limitFilesConfig: { min: 1, max: 1 },
    minFileSize: 0,
    maxFileSize: 1
  });

  useEffect(() => {
    setSuccessMessage(state?.successMessage);
    setSuccessEditReturnPref(state?.success);
    history.replace({ ...history.location, state: {}})
  }, [])

  //#endregion

  //#region API

  const fetchRetailerReturnConfiguration = async () => {
    try {
      const response = await GET_ASYNC(Endpoints.RETURNS.GET.CONFIGURATION);

      if (response?.ok) {
        const data = await response.json();
        setRetailerReturnConfiguration(extractData(data));
      } else {
        setRetailerReturnConfiguration({});
      }
    } catch (error) {
      setRetailerReturnConfiguration({});
    }
  }

  const enableRetailerReturnConfiguration = async () => {
    try {
      const response = await PUT(Endpoints.RETURNS.PUT.ENABLE);

      if (response?.ok) {
        const data = await response.json();
        setRetailerReturnConfiguration(extractData(data));
        setActivationToggled(true);
        setSuccessEditReturnPref(false);
        setShowProductTable(false);
      } else {
        setRetailerReturnConfiguration({});
      }
    } catch (error) {
      setRetailerReturnConfiguration({});
    } finally {
      setActivationButtonToggled(false);
      setProductsFetched(false);
    }
  }

  const disableRetailerReturnConfiguration = async () => {

    try {
      const response = await PUT(Endpoints.RETURNS.PUT.DISABLE);

      if (response?.ok) {
        const data = await response.json();
        setRetailerReturnConfiguration(extractData(data));
        setActivationToggled(true);
        setSuccessEditReturnPref(false);
        setShowDeactivateModel(false);
      } else {
        setRetailerReturnConfiguration({});
      }
    } catch (error) {
      setRetailerReturnConfiguration({});
    } finally {
      setActivationButtonToggled(false);
      setProductsFetched(false);
    }
  }

  const fetchReturnPreferences = async () => {

    try {
      const response = await GET_ASYNC(Endpoints.RETURNS.GET.RETURN_PREFERENCES);

      if (response?.ok) {
        const data = await response.json();
        setReturnPreferences(extractData(data));
      } else {
        setReturnPreferences({});
      }
    } catch (error) {
      setReturnPreferences({});
    }
  }

  const fetchGradingActionsOptions = async () => {

    try {
      const response = await GET_ASYNC(Endpoints.RETURNS.GET.OPTIONS);

      if (response?.ok) {
        const data = await response.json();
        setGradingOptions(extractData(data));
      } else {
        setGradingOptions({});
      }
    } catch (error) {
      setGradingOptions({});
    }
  }

  const fetchGradingActions = async () => {
    try {
      const response = await GET_ASYNC(Endpoints.RETURNS.GET.GRADE_PREFERENCES);

      if (response?.ok) {
        const data = await response.json();
        setCurrentGrading(extractData(data).grading);
      } else {
        setCurrentGrading({});
      }
    } catch (error) {
      setCurrentGrading({});
    }
  }

  const fetchProducts = async () => {
    try {
      let url = new URL(Endpoints.RETURNS.GET.PRODUCTS);
      url.searchParams.append("pageIndex", pageIndex)

      const response = await GET_ASYNC(url);

      if (response?.ok) {
        const data = await response.json();
        const productModel = extractData(data)
        setProducts(productModel.products);     
        setPageCount(productModel.pageCount);     
        setUploadSuccess(false);
      } else {
        setProducts([]);
      }
    } catch (error) {
      setProducts([]);
    }
  }

  const refetchProducts = async () => {
      if(activationButtonToggled) {
        setLoadingData(true);
        await fetchProducts();
        setLoadingData(false);
        setProductsFetched(true);
      }
  }

  const uploadImage = async () => {
    try {
      let formData = new FormData();
      plainFiles.forEach((f, i) => formData.append(`files`, plainFiles[i], f.name));
      const url = selectedProduct.skuCode 
        ? `${Endpoints.STOCK.POST.UPLOAD_IMAGE}/${selectedProduct.itemID}/${selectedProduct.skuCode}` 
        : `${Endpoints.STOCK.POST.UPLOAD_IMAGE}/${selectedProduct.itemID}`

      const response = await POST_FILE(url, formData)
    
      if (response?.ok) {
        const data = await response.json();
        setUploadSuccess(true);      
        await handleActivateConfirm();   
      } else {
        const data = await response.json();
        setUploadError(true);     
        setUploadErrorMessage(data.message);     
      }
    } catch (error) {
      setUploadError(true);
    }        
  }

  const fetchData = useCallback(async () => {
    setLoadingData(true);
    await Promise.all([fetchRetailerReturnConfiguration(), fetchGradingActions(), fetchGradingActionsOptions(), fetchReturnPreferences()])
    setLoadingData(false);
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData]);

  useEffect(() => {
    (async () => {
      await fetchProducts()
    })();
  }, [pageIndex, uploadSuccess]);

  useEffect(() => {
    (async () => {
      await refetchProducts()
    })();
  }, [activationButtonToggled]);

  useEffect(() => {
    (async () => {
      await handleActivationToggle()
    })();
  }, [productsFetched]);


  //#endregion

  //#region Controls

  const handleEditReturnPreferenceClick = (returnable, selection) => {
    setReturnPreferences({ returnable: returnable, selection: selection })
    history.push({ pathname: "/retailer/preferences/returns/edit/preferences", state: { returnable: returnable, selection: selection } });
  };

  const handleEditGradingClick = () => {
    history.push({ pathname: "/retailer/preferences/returns/edit/grading" });
  };

  const handleActivationButtonClick = async () => {
    setActivationButtonToggled(true);
  };

  const handleActivationToggle = async () => {
      if(productsFetched) {
        if(retailerReturnConfiguration && retailerReturnConfiguration.enabled) {
            setShowDeactivateModel(true);
        } else {
        if(products && products.length > 0) {
          setShowProductTable(true);
        } else {
          handleActivateConfirm();
        }
      }
    }
  };

  const handleDeactivateConfirm = async () => {
    await disableRetailerReturnConfiguration();
  };

  const handleActivateConfirm = async () => {
    await enableRetailerReturnConfiguration();
  };

  const handleModelClose = () => {
    setShowDeactivateModel(false);
  };

  const handleSelectImage = (product) => {
    setSelectedProduct(product);
    openFileSelector();
  }

  const handleImageUpload = async () => {
    setLoadingData(true);
    await uploadImage();
    setSelectedProduct({});
    clear();
    setLoadingData(false);
  };

  const onNext = () => {
    if (pageIndex < pageCount) setPageIndex((index) => index + 1);
  };

  const onPrevious = () => {
    if (pageIndex > 1) setPageIndex((index) => index - 1);
  };

  const onStart = () => setPageIndex(1);
  const onEnd = () => setPageIndex(pageCount);

  const disableControls = () => retailerReturnConfiguration ? !retailerReturnConfiguration.enabled : true;

  //#endregion

  //#region Render

  return (
    loadingData
      ? <LoadingBar />
      : <Fragment>
        <Header title="Preferences" subNavList={PREFERENCES_SUB_NAV_LIST} activeKey="Returns" headerClass="mb-2" />
        <Container fluid>
        {showProductTable 
            ? <ProductImages products={products} handleSelectImage={handleSelectImage} onNext={onNext} onPrevious={onPrevious} onStart={onStart} onEnd={onEnd} pageIndex={pageIndex} pageCount={pageCount} errors={errors} uploadSuccess={uploadSuccess} uploadError={uploadError} uploadErrorMessage={uploadErrorMessage}/>
            : <Row>
                <Col sm={12} md={6}>
                  {successEditReturnPref && <SuccessAlert successMessage={successMessage} />}
                  {activationToggled && <SuccessAlert successMessage={retailerReturnConfiguration.enabled ? ACTIVATE_MESSAGE : DEACTIVATE_MESSAGE} />}
                  {error && <ErrorAlert errorMessage={ERROR_MESSAGE} />}
                  {<ToggleReturnsActivation retailerReturnConfiguration={retailerReturnConfiguration} handleActivationButtonClick={handleActivationButtonClick} />}
                  {returnPreferences && <ReturnPreferences data={returnPreferences} handleClick={handleEditReturnPreferenceClick} disabled={disableControls()} />}
                  {gradingOptions && <GradeDescription handleClick={handleEditGradingClick} edit={true} disabled={disableControls()}/>}
                  {currentGrading && gradingOptions && <GradingActionsView grades={Grades} currentGrading={currentGrading} disabled={disableControls()}/>}
                </Col>
              </Row>}
        </Container>
        <Confirm title={DEACTIVATE_TITLE} text={DEACTIVATE_TEXT} buttonText={DEACTIVATE_CONFIRM} 
                    handleConfirmClose={handleModelClose} handleConfirmAction={handleDeactivateConfirm} 
                    show={showDeactivateModel} cancelText={MODAL_CANCEL} size="lg" />
        <ConfirmImage show={plainFiles.length} file={plainFiles[0]} product={selectedProduct} handleClose={clear} handleConfirm={handleImageUpload} />
      </Fragment>
  );

  //#endregion
};

export default Returns;