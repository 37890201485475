import React, { Component } from 'react';
import ReactDropzone from 'react-dropzone';
import ProductHeader from '../../ProductHeader';
import Endpoints from '../../../../common/Endpoints';
import LoadingBar from '../../../../common/LoadingBar';
import FormValidator from '../../../../common/FormValidator';
import { FormGroup, FormLabel, FormControl, Row, Col, Button, Container, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GET, GET_ASYNC, PUT, DELETE, extractData, POST_FILE } from '../../../../../Consumer';
import { defaultGuid } from '../../../../../Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEdit, faFingerprint, faImage, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { SuccessAlert, ErrorAlert, WarningAlert } from '../../../../common/Alert';
import { NoBarcodeTooltip, BatchManagementTooltip, EanTooltip, Tooltip } from '../shared';

const UNSAVED_CHANGES_TITLE_VALIDATION = "Unsaved Changes";
const UNSAVED_CHANGES_MESSAGE_VALIDATION = "You have unsaved changes which will be lost if you add or edit a SKU."

const NO_BARCODE_TITLE_VALIDATION = "No-Barcode Product";
const NO_BARCODE_MESSAGE_VALIDATION = "You are required to upload a product image.";

const NO_BARCODE_SKU_TITLE_VALIDATION = "No-Barcode Product SKU Variants";
const NO_BARCODE_SKU_MESSAGE_VALIDATION = "You are required to upload a product image for each SKU variant.";

const NO_BARCODE_DELETE_IMAGE = "Unable to remove image from a no-barcode product.";

const RETURNS_ACTIVE = "Returns Active";
const RETURNS_DELETE_IMAGE = "Unable to remove image while returns are active.";
const RETURNS_MESSAGE = "A Product image is required while returns are active."

const Skus = ({ stockKeepingUnits, id, pageIndex, showSaveWarning }) =>
  <FormGroup className="mb-5">
    <div className="mb-0">
      <FormLabel htmlFor="addSku">SKUs <span className="form-label-optional">- optional</span></FormLabel>
    </div>
    {showSaveWarning && <WarningAlert warningTitle={UNSAVED_CHANGES_TITLE_VALIDATION} warningMessage={UNSAVED_CHANGES_MESSAGE_VALIDATION} />}
    {(stockKeepingUnits || []).length > 0 && <div className="identifier-list">
      <ul>
        {stockKeepingUnits.map((f, i) => <li key={i}>
          <Link to={`/supplier/products/edit/uniqueidentifiers/sku/${id}/${pageIndex}/${i}`}>
            <FontAwesomeIcon icon={faFingerprint} className="form-fileicon" />{f.code}
            <FontAwesomeIcon className="form-fileicon-edit float-right" index={i} icon={faEdit} /></Link></li>
        )}
      </ul>
    </div>}
    <div className="w-100 float-right mb-4">
      <Link to={`/supplier/products/edit/uniqueidentifiers/sku/${id}/${pageIndex}/add`}>
        <Button variant="secondary" className="float-right" id="addSku">Add SKU</Button>
      </Link>
    </div>
  </FormGroup>

const ImageUpload = ({ images = [], dropzoneEnabled, onDrop, deleteImage, noScan, returnsEnabled }) =>
  <FormGroup>
    <FormLabel className="mb-0">Product Image</FormLabel>
    <div className="form-input-description mb-3">
      <p>A product image is required for no-barcode products.</p>
    </div>
    {dropzoneEnabled &&
      <ReactDropzone accept="image/*" onDrop={onDrop} maxSize={999999} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div id="file-upload-container" {...getRootProps()} className="file-upload text-center">
            <input {...getInputProps()} />
            <Image src='Images/upload-cloud.svg' className="upload-cloud" />
            <p className="file-upload-secondarytext my-2">Drag and drop image here</p>
            <p className="file-upload-secondarytext my-2">or</p>
            <Button variant="secondary">Browse</Button>
          </div>
        )}
      </ReactDropzone>
    }
    {images.length > 0 && <div className="form-image-list mt-3">
      {images.length === 1 && (noScan || returnsEnabled)
        ? <React.Fragment>
          {returnsEnabled && <WarningAlert warningTitle={RETURNS_ACTIVE} warningMessage={RETURNS_DELETE_IMAGE} />}
          {noScan && <WarningAlert warningTitle={NO_BARCODE_TITLE_VALIDATION} warningMessage={NO_BARCODE_DELETE_IMAGE} />}
          <ul>
            <li key={0}><FontAwesomeIcon icon={faImage} className="form-fileicon" />{images[0].fileName}</li>
          </ul>
        </React.Fragment>
        : <ul>
          {images.map((image, i) => <li key={i}><FontAwesomeIcon icon={faImage} className="form-fileicon" />{image.fileName}<FontAwesomeIcon icon={faTrashAlt} className="form-fileicon-action float-right" id={image.id} index={i} onClick={() => deleteImage(image.id, i)} /></li>)}
        </ul>
      }
    </div>}
  </FormGroup>

class EditUniqueIdentifiers extends Component {

  constructor(props) {
    super(props);

    this.eanValidator = new FormValidator([
      {
        field: 'ean',
        method: 'isLength',
        validWhen: true,
        args: [{ min: 0, max: 14 }],
        message: 'EAN cannot exceed 14 characters'
      }
    ]);

    this.state = {
      loading: true,
      warning: null,
      companyItemID: (this.props.match && this.props.match.params) ? this.props.match.params.id : null,
      pageIndex: this.props.match.params.pageIndex || 1,
      categories: [],
      companyItem: {},
      eanValidation: this.eanValidator.valid(),
      currentSku: {
        id: defaultGuid,
        code: "",
        description: "",
        itemImageID: "",
        companyItemID: "",
        quantity: 0
      },
      dropzoneEnabled: true,
      currentSkuIndex: null,
      noPackagingNeeded: true,
      showBarcodeWarning: false,
      showSaveWarning: false,
      showDeleteError: false,
      showImageSuccess: false,
      showImageError: false,
      showReturnsImageWarning: false,
      noScanServerValue: false,
      retailerReturnConfiguration: null
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.getCompanyItem(),
      this.fetchRetailerReturnConfiguration()
    ]);
    this.setState({ loading: false });
  }

  getCompanyItem = () => {
    const { companyItemID } = this.state;
    return GET(Endpoints.STOCK.GET.BY_ID + companyItemID)
      .then(response => response.json())
      .then(data => {
        const companyItem = extractData(data);
        const warning = companyItem ? null : "No product available.";
        this.setState({ companyItem: companyItem, noScanServerValue: companyItem.noScan, warning: warning, dropzoneEnabled: (companyItem.item.itemImages.length === 0) });
      });
  }

  fetchRetailerReturnConfiguration = async () => {
    const response = await GET_ASYNC(Endpoints.RETURNS.GET.CONFIGURATION);
    if (response?.ok) {
      const data = await response.json();
      this.setState({ retailerReturnConfiguration: extractData(data) });
    }
  }

  handleInputChange = (e) => {
    let { name, value } = e.target;

    if (e.target.getAttribute('parent')) {
      const parent = e.target.getAttribute('parent');
      const parentValue = this.state.companyItem[parent];
      parentValue[name] = value;

      name = parent;
      value = parentValue;
    }

    if (name === "noScan") {
      this.setState(prevState => ({
        companyItem: {
          ...prevState.companyItem,
          noScan: !prevState.companyItem.noScan
        }
      }));
    } else {
      if (name === "batch") {
        this.setState(prevState => ({
          ...prevState,
          companyItem: {
            ...prevState.companyItem,
            item: { ...prevState.companyItem.item, batch: !prevState.companyItem.item.batch }
          }
        }));
      } else {
        this.setState(prevState => ({
          ...prevState,
          companyItem: {
            ...prevState.companyItem,
            ...{ [name]: value }
          }
        }));
      }
    }

    this.setState({ showSaveWarning: true });
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      alert("File could not be uploaded. Ensure file size is under 1MB.");
    } else {
      this.submitImage(acceptedFiles);
    }
  }

  submitImage = (files) => {
    const { companyItem } = this.state;
    if (files) {
      this.setState({ loading: true });

      const formData = new FormData();
      files.forEach((f, i) => formData.append(`files`, files[i], f.name));

      return POST_FILE(Endpoints.STOCK.POST.UPLOAD_IMAGES + companyItem.itemID, formData)
        .then(r => r.json())
        .then(response => {
          if (!response.error) {
            let images = companyItem.item.itemImages;
            images = images.concat(extractData(response));

            const dropzoneEnabled = (images.length === 0);

            this.setState(prevState => ({
              ...prevState,
              companyItem: {
                ...prevState.companyItem,
                ...{
                  item: {
                    ...prevState.companyItem.item,
                    ...{ itemImages: images }
                  }
                }
              },
              dropzoneEnabled: dropzoneEnabled,
              loading: false,
              showImageSuccess: true,
              showImageError: false,
              showBarcodeWarning: false
            }));

          }
          else {
            const dropzoneEnabled = (companyItem.item.itemImages.length === 0);
            this.setState({ loading: false, showImageSuccess: false, showImageError: true, error: response.message, dropzoneEnabled: dropzoneEnabled });
          }
        })
        .catch(error => console.log(error));
    }
  }

  deleteImage = (id, idx) => {
    return DELETE(Endpoints.STOCK.DELETE.ITEM_IMAGE + id)
      .then(r => r.json())
      .then(response => {
        if (!response.error) {
          const images = [
            ...this.state.companyItem.item.itemImages.slice(0, idx),
            ...this.state.companyItem.item.itemImages.slice(idx + 1)
          ];

          this.setState(prevState => ({
            ...prevState,
            companyItem: {
              ...prevState.companyItem,
              ...{
                item: {
                  ...prevState.companyItem.item,
                  ...{ itemImages: images }
                }
              }
            },
            dropzoneEnabled: images.length === 0,
            showDeleteError: false
          }));

          window.location.reload();
        }
        else {
          this.setState({ loading: false, showDeleteError: true });
        }
      })
      .catch(error => console.log(error));
  }

  handleSubmit = async () => {
    this.setState({ loading: true });
    const { companyItem, retailerReturnConfiguration } = this.state;
    let valid = true;

    const returnsEnabled = retailerReturnConfiguration && retailerReturnConfiguration.enabled;
    const validation = this.eanValidator.validate(companyItem.item);
    this.setState({ eanValidation: validation });
    valid = validation.isValid;

    if (companyItem.noScan || returnsEnabled) {
      if (companyItem.stockKeepingUnits.length > 0) {
        const noImageSku = companyItem.stockKeepingUnits.find(s => s.itemImageID === null);
        if (noImageSku !== undefined) {
          valid = false;
          if (returnsEnabled) this.setState({ showReturnsImageWarning: true });
          if (companyItem.noScan) this.setState({ showBarcodeWarning: true });
        } else this.setState({ showBarcodeWarning: false, showReturnsImageWarning: false });
      } else {
        if (companyItem.item.itemImages.length > 0) {
          const noImage = companyItem.item.itemImages.find(i => i.itemImageID === null);
          if (noImage !== undefined) {
            valid = false;
            if (returnsEnabled) this.setState({ showReturnsImageWarning: true });
            if (companyItem.noScan) this.setState({ showBarcodeWarning: true });
          } else this.setState({ showBarcodeWarning: false, showReturnsImageWarning: false });
        } else {
          valid = false;
          if (returnsEnabled) this.setState({ showReturnsImageWarning: true });
          if (companyItem.noScan) this.setState({ showBarcodeWarning: true });
        }
      }
    } else this.setState({ showBarcodeWarning: false, showReturnsImageWarning: false });

    if (valid) {

      if (companyItem.item.selazarUniqueID === null && (!companyItem.item.ean || companyItem.item.ean.length === 0)) {
        companyItem.item.ean = null;
      }

      return await PUT(Endpoints.STOCK.PUT.UPDATE, companyItem)
        .then(response => response.json()).then((result) => {
          if (!result.error) {
            this.getCompanyItem();
            this.setState({ loading: false, success: true, showImageSuccess: false, warning: null, showSaveWarning: false });
          } else {
            this.setState({ loading: false, success: false, showImageSuccess: false, warning: result.message });
          }
        });
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const { companyItem, loading, warning, success, eanValidation, pageIndex, dropzoneEnabled, showSaveWarning, showBarcodeWarning, showDeleteError, showImageSuccess, showImageError, noScanServerValue, showReturnsImageWarning, retailerReturnConfiguration } = this.state;
    const returnsEnabled = retailerReturnConfiguration && retailerReturnConfiguration.enabled;
    return (
      <React.Fragment>
        {loading ? <LoadingBar /> : (
          <React.Fragment>
            <ProductHeader activeKey="Products" headerClass="mb-0" />
            <Container fluid>
              <Row>
                <Col md={12}>
                  <Link to={`/supplier/products/edit/${companyItem.id}/${pageIndex}`} className="link-button">
                    <Button variant="link" className="button-nav-return p-0 mb-3"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Product</Button>
                  </Link>
                </Col>
              </Row>

              <Row>
                <Col id="title-container" sm={12} md={6}>
                  <h5>Unique Identifiers</h5>
                  {warning && <ErrorAlert errorMessage={warning} />}
                  {success && <SuccessAlert successMessage="Product successfully updated." />}
                  {showSaveWarning && <WarningAlert warningTitle={UNSAVED_CHANGES_TITLE_VALIDATION} warningMessage={UNSAVED_CHANGES_MESSAGE_VALIDATION} />}
                  {showDeleteError && <ErrorAlert errorMessage="There was a problem deleting the image. Please try again." />}
                  {showImageSuccess && <SuccessAlert successMessage="Product image successfully uploaded." />}
                  {showImageError && <ErrorAlert errorMessage="There was a problem uploading the image. Please try again." />}
                  {showReturnsImageWarning && <WarningAlert warningTitle={RETURNS_ACTIVE} warningMessage={RETURNS_MESSAGE} />}
                  <p className="my-3"><strong>Enter existing unique product identifiers here:</strong></p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup id="ean-container" className='ean-container'>
                    <FormLabel htmlFor="eanField">EAN <span className="form-label-optional">- optional</span><Tooltip placement="top" text={EanTooltip} /></FormLabel>
                    <FormControl id="eanField" className='text-input' type="text" placeholder="EAN" parent="item" name="ean" value={companyItem?.item?.ean} onChange={this.handleInputChange} />
                  </FormGroup>

                  <FormGroup id="isbn-container">
                    <FormLabel htmlFor="isbnField">ISBN <span className="form-label-optional">- optional</span></FormLabel>
                    <FormControl id="isbnField" className='text-input' type="text" placeholder="ISBN" parent="item" name="isbn" value={companyItem?.item?.isbn} onChange={this.handleInputChange} />
                  </FormGroup>

                  <FormGroup id="sku-container">
                    <FormLabel htmlFor="addSku">SKU <span className="form-label-optional">- optional</span></FormLabel>

                    {(companyItem.stockKeepingUnits || []).length > 0 && <div className="identifier-list">
                      <ul>
                        {companyItem.stockKeepingUnits.map((f, i) => (
                          <li key={i}>
                            <span>SKU</span>
                            <span>{f.code}</span>
                            <Link to={`/supplier/products/edit/uniqueidentifiers/sku/${companyItem.id}/${pageIndex}/${i}`}>
                              <FontAwesomeIcon className="form-fileicon-edit float-right" index={i} icon={faEdit} />
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>}

                    <div className="w-100 mb-4">
                      <Link to={`/supplier/products/edit/uniqueidentifiers/sku/${companyItem.id}/${pageIndex}/add`}>
                        <Button variant="secondary" id="addSku">Add SKU</Button>
                      </Link>
                    </div>
                  </FormGroup>

                  <FormGroup id="batch-management">
                    <FormLabel className="mb-0"><span>Batch management</span><Tooltip placement="top" text={BatchManagementTooltip} /></FormLabel>
                    <FormGroup className="custom-control custom-checkbox">
                      <input id="batch" className="custom-control-input" type="checkbox" name="batch" checked={companyItem?.item?.batch} onChange={this.handleInputChange} />
                      <FormLabel className="custom-control-label" htmlFor="batch">This product is handled in batches</FormLabel>
                    </FormGroup>
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup id="no-barcode-container">
                    <FormLabel><span>No-Barcode Product</span><Tooltip placement="top" text={NoBarcodeTooltip} /></FormLabel>
                    <div className="form-input-description mb-3 barcode-product">
                      <p className="">If your product cannot have a physical barcode, please select below. This product will be manually confirmed using its image throughout pick and pack.</p>
                    </div>
                    <FormGroup className="custom-control custom-checkbox">
                      <input id="noScan" className="custom-control-input" type="checkbox" name="noScan" checked={companyItem?.noScan} onChange={this.handleInputChange} />
                      <FormLabel className="custom-control-label" htmlFor="noScan">This is a no-barcode product</FormLabel>
                    </FormGroup>
                    {showBarcodeWarning &&
                      <WarningAlert
                        warningTitle={(companyItem.stockKeepingUnits || []).length > 0 ? NO_BARCODE_SKU_TITLE_VALIDATION : NO_BARCODE_TITLE_VALIDATION}
                        warningMessage={(companyItem.stockKeepingUnits || []).length > 0 ? NO_BARCODE_SKU_MESSAGE_VALIDATION : NO_BARCODE_MESSAGE_VALIDATION}
                      />
                    }
                  </FormGroup>
                  {!companyItem.stockKeepingUnits.length > 0 && <ImageUpload images={companyItem.item.itemImages} dropzoneEnabled={dropzoneEnabled} onDrop={this.onDrop} deleteImage={this.deleteImage} noScan={noScanServerValue} returnsEnabled={returnsEnabled} />}
                </Col>
              </Row>

              <Row className="action-buttons-container">
                <div className="w-100 float-left mt-4">
                  <div className="float-right">
                    <Link to={`/supplier/products/edit/${this.props.match.params.id}/${this.props.match.params.pageIndex}`} className="link-button">
                      <Button variant="link" className="mr-3">Cancel</Button>
                    </Link>
                    <Button variant="primary" className="float-right" onClick={this.handleSubmit}>Save</Button>
                  </div>
                </div>
              </Row>

            </Container>
          </React.Fragment>
        )}
      </React.Fragment >
    )
  }
}

export default EditUniqueIdentifiers;