import React from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import { ErrorAlert } from '../../common/Alert';
import { checkPermission } from '../../../Utilities';

const NO_CARD_MESSAGE = "It looks like you currently do not have a card on this account. Be aware we will not be able to process orders until you have added an active card";

const AddCard = ({ onAddCardClick }) =>
    <Container fluid>
        <h2 className="mb-4">Payments</h2>
        <Row>
            <Col sm={12} md={6} className="px-2">
                <ErrorAlert errorMessage={NO_CARD_MESSAGE}></ErrorAlert>
                {checkPermission('NewCard', <Button variant="primary" className="float-right" onClick={onAddCardClick}>Add Card</Button>)}
            </Col>
        </Row>
    </Container>


export default AddCard;