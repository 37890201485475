import React, { Component } from 'react';
import { Container, Row, Col, FormLabel, FormGroup, Button, Card, Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Endpoints from '../../common/Endpoints';
import { GET, PUT, extractData } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import { ErrorAlert, InfoAlert } from '../../common/Alert';
import Breadcrumbs from '../../common/Breadcrumbs';

const PERMISSIONS_ERROR_MESSAGE = 'Could not retrieve preferences';
const USER_ERROR_MESSAGE = 'Could not retrieve user information';
const UPDATE_ERROR_MESSAGE = 'Could not update preferences';

const DEFAULT_BREADCRUMBS = [
    { text: "Users", link: "/company/users" },
    { text: "Edit Preferences", link: null }
];

const accountBreadcrumbs = (id) => [
    { text: "Users", link: "/company/users" },
    { text: "View User Account", link: `/company/users/account/${id}` },
    { text: "Edit Preferences", link: null }
];

const PermissionsAccordion = ({ parent, activeCollapseName, handleToggleClick, handleInputChange, handleParentInputChange }) =>
    <Accordion activeKey={activeCollapseName} className="permissions-card mb-3">
        <Card>
            <Card.Header>
                <h6 className="float-left pt-2">{parent.name}</h6>
                <Accordion.Toggle as={Button} onClick={() => handleToggleClick(parent.name)} variant="link" eventKey={parent.name} className="float-right">
                    <FontAwesomeIcon icon={activeCollapseName === parent.name ? faAngleDown : faAngleRight} />
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={parent.name}>
                <Card.Body className="pt-0">
                    <Row>
                        <Col>
                            <FormGroup key={`${parent.name}-check`} className="custom-control custom-checkbox ml-2">
                                <input id={parent.name} name={parent.name} className="custom-control-input" type="checkbox" onChange={handleParentInputChange} checked={Object.values(parent.permissions).every(p => p.enabled === true)} />
                                <FormLabel className="custom-control-label permission-text" htmlFor={parent.name}>Select All</FormLabel>
                            </FormGroup>
                        </Col>
                    </Row>
                    {parent.permissions.length ?
                        <Row>
                            {parent.permissions.map((permission) =>
                                <Col key={`permission-${permission.id}`} xs={12} sm={6}>
                                    <FormGroup className="custom-control custom-checkbox ml-2">
                                        <input id={permission.id} name={permission.id} className="custom-control-input" type="checkbox" onChange={handleInputChange} checked={permission.enabled} />
                                        <FormLabel className="custom-control-label permission-text" htmlFor={permission.id}><span>{permission.featureEnumName}</span></FormLabel>
                                    </FormGroup>
                                </Col>
                            )}
                        </Row> : null}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    </Accordion>

class Permissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allPermissions: [],
            loading: true,
            userID: props.match.params.id || null,
            permissionsErrorMessage: "",
            userErrorMessage: "",
            updateErrorMessage: "",
            userName: "",
            activeCollapseName: "",
            fromAccount: props.location.state && props.location.state.fromAccount || false
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.loadAllPermissions(true),
            this.fetchUser()
        ]);

        this.setState({ loading: false });
    }

    loadAllPermissions = (setActiveCollapseName = false) => {
        const { userID } = this.state;

        return GET(Endpoints.GET_FEATURE_BREAKDOWN_FOR_USER + userID)
            .then(response => response.json())
            .then(results => {
                const data = extractData(results);
                if (data === null || data === undefined) this.setState({ allPermissions: [], permissionsErrorMessage: PERMISSIONS_ERROR_MESSAGE });
                else {
                    setActiveCollapseName && this.setState({ activeCollapseName: data[0].name })
                    this.setState({ allPermissions: data, permissionsErrorMessage: '' });
                }
            })
            .catch(error => {
                this.setState({permissionsErrorMessage: PERMISSIONS_ERROR_MESSAGE});
                console.log(error);
            });
    }

    fetchUser = () => {
        const { userID } = this.state;

        return GET(new URL(Endpoints.GET_USER + userID))
            .then(response => response.json())
            .then(results => {
                const data = extractData(results);
                if (data === null || data === undefined) this.setState({ userErrorMessage: USER_ERROR_MESSAGE });
                else this.setState({ userName: data.forename + " " + data.surname });
            })
            .catch(error => {
                this.setState({userErrorMessage: USER_ERROR_MESSAGE});
                console.log(error);
            });
    }

    handleInputChange = (e) => {
        const { name, checked } = e.target;
        const { userID } = this.state;
        this.updatePermission(name, userID, checked)
    }

    handleParentInputChange = (e) => {
        const { name, checked } = e.target;
        const { allPermissions, userID } = this.state;
        const childPermissions = allPermissions.find(a => a.name === name);
        this.setState({ loading: true });

        childPermissions.permissions.map(permission => {
            this.updatePermission(permission.id, userID, checked)
        });

        this.setState({ loading: false });
    }

    handleToggleClick = (activeCollapseName) => this.setState(prevState => ({ activeCollapseName: prevState.activeCollapseName !== activeCollapseName ? activeCollapseName : "" }));

    updatePermission = (permissionID, userId, access) => {
        PUT(`${Endpoints.UPDATE_OR_CREATE_FEATUREUSER}${permissionID}/${userId}/${String(access)}`)
            .then(response => response.json())
            .then(results => {
                const data = extractData(results);
                if (data === null || data === undefined) this.setState({ loading: false, updateErrorMessage: UPDATE_ERROR_MESSAGE });
                else this.loadAllPermissions();
            })
            .catch(error => {
                this.setState({updateErrorMessage: UPDATE_ERROR_MESSAGE, loading: false});
                console.log(error);
                
            });
    }

    render() {
        const { loading, permissionsErrorMessage, userErrorMessage, updateErrorMessage, userName, allPermissions, activeCollapseName, fromAccount, userID } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Container fluid>
                    <h2>Edit User Preferences : {userName}</h2>
                    <Breadcrumbs breadcrumbs={fromAccount ? accountBreadcrumbs(userID) : DEFAULT_BREADCRUMBS} />
                    <Row className="mt-4">
                        <Col sm={12} md={6}>
                            {permissionsErrorMessage && <ErrorAlert messages={permissionsErrorMessage} />}
                            {userErrorMessage && <ErrorAlert messages={userErrorMessage} />}
                            {updateErrorMessage && <ErrorAlert messages={updateErrorMessage} />}
                            {allPermissions.length
                                ? allPermissions.map(parent => <PermissionsAccordion key={`permissions-${parent.name}`} activeCollapseName={activeCollapseName} handleToggleClick={this.handleToggleClick} parent={parent} handleInputChange={this.handleInputChange} handleParentInputChange={this.handleParentInputChange} />)
                                : <InfoAlert message="No user preferences available" />}
                        </Col>
                    </Row>
                </Container>
        );
    }
}

export default Permissions;