import React, { Component, Fragment } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { RETAILER_ORDER_SUB_NAV_LIST } from '../../common/constants'
import Header from '../../common/Header';
import OrderListFilter from './OrderListFilter';
import AllOrderListFilter from './AllOrderListFilter';
import OrderTable from './OrderTable';
import { GET, extractData, GET_ASYNC } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import { ErrorAlert } from '../../common/Alert';
import Pagination from '../../common/Tables/Pagination';

class AllOrders extends Component {

    state = {
        pageCount: 0,
        pageIndex: 1,
        orders: [],
        loading: true,
        warning: "",
        showIssueFilterOption: false,
        filter: {
            isFiltered: false,
            orderNumber: "",
            OrderSearchText: "",
            dateFrom: "",
            dateTo: "",
            sortBy: ""
        }
    };

    async componentDidMount() {
        await this.fetchAllOrders();
        this.setState({ loading: false });
    }

    fetchAllOrders = async () => {
        const { pageIndex, filter } = this.state;
        const url = new URL(Endpoints.RETAILER.GET.ALLORDERS);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)

        return GET_ASYNC(url)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];                    
                const warning = null;
                this.setState({ orders: data.orders ? data.orders : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false, warning: warning });
            })
            .catch(error => {         
                this.setState({ warning: error.message });
			    console.log(error); 
            });
    }

    onClear = async (e) => {
        e.preventDefault();
        this.setState({
            filter: { OrderSearchText: "", dateFrom: "", dateTo: "", sortBy: "" },
            loading: true
        }, await this.fetchAllOrders);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true,pageIndex: 1}, await this.fetchAllOrders);
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, await this.fetchAllOrders);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, await this.fetchAllOrders);
        }
    }

    onStart = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true }, this.fetchAllOrders);
        }
    }

    onEnd = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true }, this.fetchAllOrders);
        }
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                ...{ [name]: value, isFiltered: true }
            }
        }));
    }

    handleViewOrderClick = (order) => this.props.history.push(`/retailer/orders/orderdetails/${order.id}`);

    render() {
        const { loading, orders, pageIndex, pageCount, warning, filter } = this.state;

        return (
            <Fragment>
                {loading
                    ? <LoadingBar />
                    : <Fragment>
                        <Header title="Orders" subNavList={RETAILER_ORDER_SUB_NAV_LIST} activeKey="All Orders" />
                        <Container fluid>                                                                                                            
                            {warning && <Row><Col sm={12} md={6} className="px-2"><ErrorAlert errorMessage={warning} /></Col></Row>}
                            <Fragment>
                                <Row className="my-4">
                                    <Col>
                                        <AllOrderListFilter 
                                            onSearch={this.onSearch}
                                            onFilterChange={this.onFilterChange}
                                            onClear={this.onClear} 
                                            {...this.state} />
                                    </Col>
                                </Row>
                                <Card className="card-table">
                                    <div className="table-responsive">
                                        <OrderTable orders={orders} handleViewOrderClick={this.handleViewOrderClick} />
                                        <Pagination onNext={this.onNext} 
                                            onPrevious={this.onPrevious} 
                                            onStart={this.onStart} 
                                            onEnd={this.onEnd} 
                                            pageIndex={pageIndex} 
                                            pageCount={pageCount} />
                                    </div>
                                </Card>
                            </Fragment>
                        </Container>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default AllOrders;