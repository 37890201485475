import React from 'react';
import { Container, Row, Card, Image, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Footer from '../../layout/Footer';
import './RegistrationResult.scss'

const RegistrationResult = () =>
    <Container className="h-100">
        <Row className="justify-content-center row-form-adjustedheight">
            <Col md={6} className="align-self-center">
                <Card className="p-5" style={{ borderRadius: '14px' }}>
                    <Image src='Images/logo.png' className="mx-auto mb-5 logo-size" />
                    <h5 className="text-center mb-2 font-weight-bold">Verify Your Email to Complete Registration</h5>
                    <p className="text-center mb-0">We have sent a verification email, follow the link in the email to verify your account. You are unable to login until your email has been verified.</p>
                    <Image src='Images/email.svg' className="mx-auto mb-3 image-card" />
                    <Link to='/' className="link-button">
                        <button className='registerResultButton'>Login</button>
                    </Link>
                    <p className="text-center mt-4">Have you not received a verification email? Contact us on <a href="mailto:support@selazar.com" style={{ color: '#FC158A' }}>support@selazar.com</a></p>
                </Card>
            </Col>
        </Row>
        <Footer />
    </Container>

export default RegistrationResult;