import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image, Button } from 'react-bootstrap';
import Endpoints from '../../common/Endpoints';
import { GET, POST, extractData } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Footer from '../../layout/Footer';
import './RegistrationResult.scss'

const LoginButton = () =>
    <Link to='/' className="link-button">
        <button className='registerResultButton'>Login</button>
    </Link>

const SuccessConfirmEmail = () =>
    <Fragment>
        <h5 className="text-center title-weight">Your Email Address Has Been Confirmed</h5>
        <p className="text-center mb-0">You can login and get started with Selazar.</p>
        <Image src='Images/verify-email.svg' className="mx-auto mb-4 image-card" />
        <LoginButton />
    </Fragment>

const ErrorConfirmEmail = () =>
    <Fragment>
        <h5 className="text-center title-weight">An Error has Occurred</h5>
        <p className="text-center">Your email address could not be confirmed.</p>
        <LoginButton />
    </Fragment>

class ConfirmEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmEmailRequestID: this.props.match.params.id || null,
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getConfirmEmailRequest();
    }

    getConfirmEmailRequest = () => {
        const { confirmEmailRequestID } = this.state;

        return GET(Endpoints.REGISTRATION.GET.CONFIRM_EMAIL_REQUEST + confirmEmailRequestID)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ confirmEmailRequest: extractData(result) }, this.confirmEmailRequest);
                } else {
                    this.setState({ loading: false, error: true });
                }
            })
            .catch(error => console.log(error));
    }

    confirmEmailRequest = () => {
        const { confirmEmailRequestID } = this.state;

        return POST(Endpoints.REGISTRATION.POST.EMAIL.CONFIRM + confirmEmailRequestID)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ confirmEmailRequest: extractData(result), loading: false });
                } else {
                    this.setState({ loading: false, error: true });
                }
            })
            .catch(error => console.log(error));
    }

    render() {
        const { loading, error } = this.state;

        return (
            <Fragment>
                {loading
                    ? <LoadingBar />
                    : <Container className="h-100 font-component">
                        <Row className="justify-content-center row-form-adjustedheight">
                            <Col md={6} className="align-self-center">
                                <Card className="p-5" style={{ borderRadius: '14px' }}>
                                    <Image src='Images/logo.png' className="mx-auto mb-5 logo-size" />
                                    {error
                                        ? <ErrorConfirmEmail />
                                        : <SuccessConfirmEmail />
                                    }
                                </Card>
                            </Col>
                        </Row>
                        <Footer />
                    </Container>
                }
            </Fragment>
        );
    }
}
export default ConfirmEmail;