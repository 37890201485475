import React, { Fragment, useState, useEffect, useCallback } from "react";
import Endpoints from '../../../common/Endpoints';
import LoadingBar from '../../../common/LoadingBar';
import { Container, Card } from 'react-bootstrap';
import { GET_ASYNC, POST_FILE, extractData } from '../../../../Consumer';
import { SuccessAlert, ErrorAlert } from '../../../common/Alert';
import { ProductsTable, ConfirmImage } from '../../../retailer/preferences/returns/shared/Snippets';
import Pagination from '../../../common/Tables/Pagination';
import { useFilePicker } from 'use-file-picker';
import ProductHeader from "../ProductHeader";

const IMAGE_SUCCESS_MESSAGE = 'Successfully uploaded image.';
const IMAGE_ERROR_MESSAGE = 'Could not upload image. Please try again.';

const ProductImages = ({ products, handleSelectImage, onNext, onPrevious, onStart, onEnd, pageIndex, pageCount, errors, uploadError, uploadErrorMessage, uploadSuccess }) => {
  return (
    <div className="mb-4">
      <h5 className="mb-3">Add Images to Products</h5>
      <p>An image is required for all products if your returns are being handled by Selazar. A product will not be active until an image has been added.</p>
      <div className="form-input-description">
        <p>Max image size is 1MB</p>
      </div>
      {uploadSuccess && <SuccessAlert successMessage={IMAGE_SUCCESS_MESSAGE} />}
      {uploadError && <ErrorAlert errorMessage={ uploadErrorMessage ? `Could not upload image: ${uploadErrorMessage}. Please try again.`: IMAGE_ERROR_MESSAGE} />}
      {errors.length > 0 && 
      <div>
        {errors[0].fileSizeToolarge && <ErrorAlert errorMessage={'File size is too large'} />}
        {errors[0].readerError && <ErrorAlert errorMessage={'Problem occured while reading file'} />}
        {errors[0].maxLimitExceeded && <ErrorAlert errorMessage={'Too many files'} />}
      </div>}
      <Card className="card-table">
        <div className="table-responsive">
          <ProductsTable products={products} handleSelectImage={handleSelectImage} />
          <Pagination onNext={onNext} onPrevious={onPrevious} onStart={onStart} onEnd={onEnd} pageIndex={pageIndex} pageCount={pageCount} />
        </div>
      </Card>
    </div>);
};

const Inactive = ({ location: { state }, history }) => {

  //#region State

  const [loadingData, setLoadingData] = useState(true);
  const [uploadError, setUploadError] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [products, setProducts] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [openFileSelector, { filesContent, loading, errors, plainFiles, clear } ] = useFilePicker({
    multiple: true,
    accept: "image/*",
    limitFilesConfig: { min: 1, max: 1 },
    minFileSize: 0,
    maxFileSize: 1
  });


  //#endregion

  //#region API

  const fetchProducts = async () => {
    setLoadingData(true);
    try {
      let url = new URL(Endpoints.STOCK.GET.INACTIVE);
      url.searchParams.append("pageIndex", pageIndex)

      const response = await GET_ASYNC(url);

      if (response?.ok) {
        const data = await response.json();
        const productModel = extractData(data)
        setProducts(productModel.products);     
        setPageCount(productModel.pageCount);    
        setUploadSuccess(false);
        if(productModel.products.length == 0){
            handleProductsRedirect();
        } 
      } else {
        setProducts([]);
      }
    } catch (error) {
        setProducts([]);
    } finally {
        setLoadingData(false);
    }
  }

  const uploadImage = async () => {
    try {
      let formData = new FormData();
      plainFiles.forEach((f, i) => formData.append(`files`, plainFiles[i], f.name));
      const url = selectedProduct.skuCode 
        ? `${Endpoints.STOCK.POST.UPLOAD_IMAGE}/${selectedProduct.itemID}/${selectedProduct.skuCode}` 
        : `${Endpoints.STOCK.POST.UPLOAD_IMAGE}/${selectedProduct.itemID}`

      const response = await POST_FILE(url, formData)
    
      if (response?.ok) {
        await response.json();
        setUploadSuccess(true);
        setPageIndex(1);
      } else {
        const data = await response.json();
        setUploadError(true);     
        setUploadErrorMessage(data.message);     
      }
    } catch (error) {
      setUploadError(true);
    }        
  }

  const fetchData = useCallback(async () => {
    await fetchProducts();
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData]);

  useEffect(() => {
    (async () => {
      await fetchProducts()
    })();
  }, [pageIndex, uploadSuccess]);

  //#endregion

  //#region Controls

  const handleSelectImage = (product) => {
    setSelectedProduct(product);
    openFileSelector();
  }

  const handleImageUpload = async () => {
    setLoadingData(true);
    await uploadImage();
    setSelectedProduct({});
    clear();
    setLoadingData(false);
  };

  const onNext = () => {
    if (pageIndex < pageCount) setPageIndex((index) => index + 1);
  };

  const onPrevious = () => {
    if (pageIndex > 1) setPageIndex((index) => index - 1);
  };

  const onStart = () => setPageIndex(1);
  const onEnd = () => setPageIndex(pageCount);

  const handleProductsRedirect = () => {
    history.push({ pathname: "/supplier/products" });
  };

  //#endregion

  //#region Render

  return (
    loadingData
      ? <LoadingBar />
      : <Fragment>
        <ProductHeader activeKey="Inactive" />
        <Container fluid>
            <ProductImages products={products} handleSelectImage={handleSelectImage} onNext={onNext} onPrevious={onPrevious} onStart={onStart} onEnd={onEnd} pageIndex={pageIndex} pageCount={pageCount} errors={errors} uploadSuccess={uploadSuccess} uploadError={uploadError} uploadErrorMessage={uploadErrorMessage}/>           
        </Container>      
        <ConfirmImage show={plainFiles.length} file={plainFiles[0]} product={selectedProduct} handleClose={clear} handleConfirm={handleImageUpload} />
      </Fragment>
  );

  //#endregion
};

export default Inactive;