import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { toLocalTimeString } from '../../../Utilities';
import NoResults from '../../common/Tables/NoResults';

const OrderRow = ({ order, handleViewOrderClick }) =>
    <tr>
        <td>{order.customerReference}</td>
        <td>{order.source}</td>
        <td>{toLocalTimeString(order.createdDate)}</td>
        <td>{order.status == 'Pending' ? <span className="text-status-yellow">Awaiting Stock</span> : <span className="text-status-grey">{order.status}</span>}</td>
        <td className="text-center">
            <Button className="link-button" variant="secondary" size="sm" onClick={handleViewOrderClick}>View Order</Button>
        </td>
    </tr>

const OnHoldTable = ({ orders, handleViewOrderClick }) =>
    <Table striped bordered size="sm" className={!orders.length ? "table-empty" : ""}>
        <thead>
            <tr>
                <th>Order Number</th>
                <th>Source</th>
                <th>Date Created</th>
                <th>Status</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {orders.length
                ? orders.map(order => <OrderRow key={order.orderID} order={order} handleViewOrderClick={() => handleViewOrderClick(order)} />)
                : <NoResults colSpan="6" text="No orders available." /> 
            }
        </tbody>
    </Table>

export default OnHoldTable;