import React, { useState, useEffect, useCallback } from 'react';
import { Route, Switch, withRouter, useHistory, useLocation } from 'react-router';
import ForgotPassword from './components/login/password/ForgotPassword';
import ResetPassword from './components/login/password/ResetPassword';
import ResetResult from './components/login/password/ResetResult';
import NotFound from './components/layout/NotFound';
import NotFoundLogin from './components/layout/NotFoundLogin';
import ProtectedRoute from './components/common/ProtectedRoute';
import AddUser from './components/company/users/AddUser';
import EditUser from './components/company/users/EditUser';
import EditDetails from './components/company/users/EditDetails';
import Password from './components/company/users/Password';
import Account from './components/company/users/Account';
import Permissions from './components/company/users/Permissions';
import Users from './components/company/users/Users';
import Dashboard from './components/company/dashboard/Dashboard';
import Layout from './components/layout/Layout';
import Login from './components/login/Login';
import CardPayment from './components/retailer/finance/CardPayment';
import CardReturn from './components/retailer/finance/CardReturn';
import NewDirectDebit from './components/retailer/finance/NewDirectDebit';
import RetailerDashboard from './components/retailer/orders/RetailerDashboard';
import PendingOrder from './components/retailer/orders/PendingOrders';
import InProcessOrders from './components/retailer/orders/InProcessOrders';
import ProcessedOrders from './components/retailer/orders/ProcessedOrders';
import UnsuccessfulOrders from './components/retailer/orders/UnsuccessfulOrders';
import OrderDetails from './components/retailer/orders/OrderDetails';
import UnsuccessfulOrderDetails from './components/retailer/orders/UnsuccessfulOrderDetails';
import PartialOrders from './components/retailer/orders/partial/PartialOrders';
import PartialOrderDetails from './components/retailer/orders/partial/PartialOrderDetails';
import ListReturns from './components/retailer/returns/returnprocessing/ListReturns';
import ViewReturn from './components/retailer/returns/returnprocessing/ViewReturn';
import ProcessedReturns from './components/retailer/returns/ProcessedReturns';
import UnprocessedReturns from './components/retailer/returns/UnprocessedReturns';
import UploadOrderCSV from './components/retailer/orders/UploadOrderCsv';
import ConfirmEmail from './components/login/registration/ConfirmEmail';
import RegistrationResult from './components/login/registration/RegistrationResult';
import RetailerRegistration from './components/login/registration/RetailerRegistration';
import EditProduct from './components/supplier/stock/product/EditProduct';
import EditProductDetails from './components/supplier/stock/product/edit/EditProductDetails';
import EditProductOverview from './components/supplier/stock/product/edit/EditProductOverview';
import EditPackagingPreferences from './components/supplier/stock/product/edit/EditPackagingPreferences';
import EditPricing from './components/supplier/stock/product/edit/EditPricing';
import EditUniqueIdentifiers from './components/supplier/stock/product/edit/EditUniqueIdentifiers';
import EditSKU from './components/supplier/stock/product/edit/EditSKU';
import EditReturnPreferences from './components/supplier/stock/product/edit/EditReturnPreferences';
import AddProduct from './components/supplier/stock/product/AddProduct';
import StockConsignmentList from './components/supplier/stock/consignments/StockConsignmentList';
import StockConsignment from './components/supplier/stock/consignments/StockConsignment';
import StockConsignmentItems from './components/supplier/stock/consignments/StockConsignmentItems';
import StockList from './components/supplier/stock/StockList';
import UploadCompanyItemCSV from './components/supplier/stock/UploadCompanyItemCsv';
import UploadSingleStock from './components/supplier/stock/UploadSingleStock';
import UploadStockCsv from './components/supplier/stock/UploadStockCsv';
import Invoices from './components/company/invoices/invoice/Invoices';
import CollectionAdvice from './components/company/invoices/collection/CollectionAdvice';
import RemittanceAdvice from './components/company/invoices/remittance/RemittanceAdvice';
import Courier from './components/retailer/preferences/Courier';
import Region from './components/retailer/preferences/Region';
import Security from './components/retailer/preferences/Security';
import Taxes from './components/retailer/preferences/Taxes';
import CustomerSupportAccess from './components/retailer/preferences/CustomerSupportAccess';
import Warehouse from './components/retailer/preferences/Warehouse';
import Packaging from './components/retailer/preferences/packaging/Packaging';
import CustomPackaging from './components/retailer/preferences/packaging/CustomPackaging';
import AddCustomBox from './components/retailer/preferences/packaging/AddCustomBox';
import AddCustomMailingBag from './components/retailer/preferences/packaging/AddCustomMailingBag';
import EditCustomBox from './components/retailer/preferences/packaging/EditCustomBox';
import EditCustomMailingBag from './components/retailer/preferences/packaging/EditCustomMailingBag';
import ViewCustomBox from './components/retailer/preferences/packaging/ViewCustomBox';
import ViewCustomMailingBag from './components/retailer/preferences/packaging/ViewCustomMailingBag';
import Integrations from './components/retailer/integrations/Integrations';
import EditIntegration from './components/retailer/integrations/EditIntegration';
import Apps from './components/retailer/integrations/apps/Apps';
import AddApp from './components/retailer/integrations/apps/AddApp';
import EditApp from './components/retailer/integrations/apps/EditApp';
import CreateBulkOrder from './components/retailer/bulkorder/createbulkorder/CreateBulkOrder';
import BulkOrderWizard from './components/retailer/bulkorder/createbulkorder/BulkOrderWizard';
import PendingBulkOrders from './components/retailer/bulkorder/bulkorders/PendingBulkOrders';
import InProcessBulkOrders from './components/retailer/bulkorder/bulkorders/InProcessBulkOrders';
import ProcessedBulkOrders from './components/retailer/bulkorder/bulkorders/ProcessedBulkOrders';
import UnsuccessfulBulkOrders from './components/retailer/bulkorder/bulkorders/UnsuccessfulBulkOrders';
import BulkOrderDetails from './components/retailer/bulkorder/bulkorders/BulkOrderDetails';
import BulkOrderSplitDetails from './components/retailer/bulkorder/bulkorders/BulkOrderSplitDetails';
import QuarantineList from './components/supplier/stock/consignments/QuarantineList';
import QualityReport from './components/supplier/stock/consignments/QualityReport';
import TrackingReference from './components/retailer/orders/TrackingReference';
import Bundles from './components/supplier/stock/bundles/Bundles';
import Bundle from './components/supplier/stock/bundles/Bundle';
import BundleWizard from './components/supplier/stock/bundles/bundlewizard/BundleWizard';
import PartialOrderPreference from './components/retailer/preferences/PartialOrderPreference';
import OnHoldOrders from './components/retailer/orders/OnHoldOrders';
import AllOrders from './components/retailer/orders/AllOrders';
import OnHoldOrderDetails from './components/retailer/orders/OnHoldOrderDetails';
import Returns from './components/retailer/preferences/returns/Returns';
import EditDefaultReturnPreferences from './components/retailer/preferences/returns/EditDefaultReturnPreferences';
import EditDefaultGradingPreferences from './components/retailer/preferences/returns/EditDefaultGradingPreferences'
import Inactive from './components/supplier/stock/product/Inactive';
import {getUser, hasTokenExpired, useInterval  } from './Utilities';
import PurchaseOrdersList from './components/supplier/purchaseorders/PurchaseOrdersList';
import { PurchaseOrderDetails } from './components/supplier/purchaseorders/PurchaseOrderDetails/PurchaseOrderDetails';
import { StockBoxLibraryList } from './components/supplier/stockboxlibrary/StockBoxLibraryList';
import AddressBookList from './components/retailer/bulkorder/addressBook/AddressBookList';

// 15 seconds
const CHECK_TOKEN_INTERVAL = 15000;
const unAuthenticatedRoutes = ['account', 'register'];

const App = () => {

    const isLoggedIn = () => {
        const user = getUser();
        return user ? true : false;
    };

    //#region State

    const [loggedIn, setLoggedIn] = useState(isLoggedIn());
    const [tokenExpired, setTokenExpired] = useState(false);
  
    //#endregion
    

    const history = useHistory();
    const location = useLocation();
  
    //#region Hooks

    useEffect(()=> {
            if(!loggedIn){
                if(!unAuthenticatedRoutes.includes(location.pathname.split("/")[1])) onLogout();
            }  
    }, [loggedIn]);

    // run every 15 seconds
    const checkToken = useCallback(() => {
        const loggedIn = isLoggedIn();
        setLoggedIn(loggedIn);

        if(loggedIn){
            const isTokenExpired = hasTokenExpired();
            setTokenExpired(isTokenExpired);
            setLoggedIn(!isTokenExpired);
        }
    }, [loggedIn]);

    useInterval(checkToken, CHECK_TOKEN_INTERVAL);

    //#endregion


    //#region Functions

    const onLogin = (data) => {
        if (data.token) {
            localStorage.setItem('user', JSON.stringify(data));
            setTokenExpired(false);
            setLoggedIn(true);
        }
    };

    const onLogout = () => {
        setLoggedIn(false);
        history.push("/");
        localStorage.clear();       
    };

    //#endregion


    //#region Render

    if(!loggedIn)
        return (
            <>
            <Switch>
                <Route exact path='/' render={() => (<Login onLogin={onLogin} tokenExpired={tokenExpired} />)} />
                <Route path='/register/retailer' component={RetailerRegistration} exact />
                <Route path='/register/result' component={RegistrationResult} exact />
                <Route path='/register/email/confirm/:id' component={ConfirmEmail} exact />
                <Route path='/account/password/forgot' component={ForgotPassword} exact />
                <Route path='/account/password/result' component={ResetResult} exact />
                <Route path='/account/password/reset/:id' component={ResetPassword} exact />
                <Route component={NotFoundLogin} />
            </Switch>
            </>
        )        
        return (
            <>
            <Layout onLogout={onLogout}>
                <Switch>
                    <Route path={'/'} component={Dashboard} exact />
                    <ProtectedRoute feature='ViewUser' path='/company/users' component={Users} exact />
                    <ProtectedRoute feature='AddUser' path='/company/users/add' component={AddUser} exact />
                    <ProtectedRoute feature='EditUser' path='/company/users/edit/:id' component={EditUser} exact />
                    <ProtectedRoute feature='EditUser' path='/company/users/editdetails/:id' component={EditDetails} exact />
                    <ProtectedRoute feature='EditUser' path='/company/users/password' component={Password} exact />
                    <ProtectedRoute feature='ViewUser' path='/company/users/account/:id' component={Account} exact />
                    <ProtectedRoute feature='ViewInvoice' path='/company/invoices' component={Invoices} exact />
                    <ProtectedRoute feature='ViewInvoice' path='/company/invoices/collectionadvice' component={CollectionAdvice} exact />
                    <ProtectedRoute feature='ViewInvoice' path='/company/invoices/remittanceadvice' component={RemittanceAdvice} exact />

                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/products' component={StockList} exact />
                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/products/list/:pageIndex' component={StockList} exact />
                    <ProtectedRoute profile='Supplier' feature='AddStock' path='/supplier/products/add' component={AddProduct} exact />

                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/:id' component={EditProduct} exact />
                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/:id/:pageIndex' component={EditProduct} exact />

                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/details/:id/:pageIndex' component={EditProductDetails} exact />
                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/overview/:id/:pageIndex' component={EditProductOverview} exact />
                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/packagingpreferences/:id/:pageIndex' component={EditPackagingPreferences} exact />
                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/pricing/:id/:pageIndex' component={EditPricing} exact />
                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/uniqueidentifiers/:id/:pageIndex' component={EditUniqueIdentifiers} exact />
                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/uniqueidentifiers/sku/:id/:pageIndex/:skuIndex' component={EditSKU} exact />
                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/returnpreferences/:id/:pageIndex' component={EditReturnPreferences} exact />

                    <ProtectedRoute profile='Supplier' feature='UploadCSV' path='/supplier/products/upload' component={UploadCompanyItemCSV} exact />

                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/products/bundles' component={Bundles} exact />
                    <ProtectedRoute profile='Supplier' feature='AddStock' path='/supplier/products/bundles/create' component={BundleWizard} exact />
                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/products/bundles/:id' component={Bundle} exact />

                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/stockconsignments' component={StockConsignmentList} exact />
                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/stockconsignments/consignment/:id' component={StockConsignment} exact />
                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/stockconsignments/consignmentitems/:id' component={StockConsignmentItems} exact />
                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/stockconsignments/quarantine' component={QuarantineList} exact />
                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/stockconsignments/quarantine/qualityreport/:id' component={QualityReport} exact />
                    <ProtectedRoute profile='Supplier' feature='AddStock' path='/supplier/stockconsignments/create' component={UploadSingleStock} exact />                    
                    
                    <ProtectedRoute profile='Supplier' feature='UploadCSV' path='/supplier/stockconsignments/upload' component={UploadStockCsv} exact />

                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/products/inactive' component={Inactive} exact />

                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/purchaseorders' component={ PurchaseOrdersList } exact />                
                    <ProtectedRoute profile='Supplier' feature='AddStock' path='/supplier/purchaseorders/create/' component={ PurchaseOrderDetails } exact />
                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/purchaseorders/editpurchaseorder/:id' component={ PurchaseOrderDetails } exact />

                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/stockboxlibrary' component={ StockBoxLibraryList } exact />    

                    <ProtectedRoute feature='EditPermission' path='/users/permissions/:id' component={Permissions} exact />
                    <ProtectedRoute profile='Retailer' feature='UploadOrderCSV' path='/retailer/orders/upload' component={UploadOrderCSV} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders' component={RetailerDashboard} exact /> 
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/allorders' component={AllOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/pendingorders' component={PendingOrder} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/inprocessorders' component={InProcessOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/processedorders' component={ProcessedOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/unsuccessfulorders' component={UnsuccessfulOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/orderdetails/:id' component={OrderDetails} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/unsuccessfulorderdetails' component={UnsuccessfulOrderDetails} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/tracking' component={TrackingReference} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/partial' component={PartialOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/partial/orderdetails/:id' component={PartialOrderDetails} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/onhold' component={OnHoldOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/onholddetails/:id' component={OnHoldOrderDetails} exact />

                    <ProtectedRoute profile='Retailer' feature='ViewReturn' path='/retailer/returns' component={ListReturns} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewReturn' path='/retailer/returns/:id' component={ViewReturn} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewReturn' path='/retailer/collectplus' component={UnprocessedReturns} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewReturn' path='/retailer/collectplus/processed' component={ProcessedReturns} exact />

                    <ProtectedRoute profile='Retailer' feature='ManageCourierPreferences' path='/retailer/preferences' component={Courier} exact />
                    <ProtectedRoute profile='Retailer' feature='ManageCourierPreferences' path='/retailer/preferences/region' component={Region} exact />
                    
                    <ProtectedRoute profile='Retailer' feature='ManageCourierPreferences' path='/retailer/bulkorder/addressBook' component={AddressBookList} exact />

                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/security' component={Security} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/customersupportaccess' component={CustomerSupportAccess} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/taxes' component={Taxes} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/warehouse' component={Warehouse} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/orders' component={PartialOrderPreference} exact />
                    
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/returns' component={Returns} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/returns/edit/preferences' component={EditDefaultReturnPreferences} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/returns/edit/grading' component={EditDefaultGradingPreferences} exact />
                    
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging' component={Packaging} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging/custompackaging' component={CustomPackaging} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging/custompackaging/add/box' component={AddCustomBox} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging/custompackaging/add/mailingbag' component={AddCustomMailingBag} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging/custompackaging/edit/box/:id' component={EditCustomBox} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging/custompackaging/edit/mailingbag/:id' component={EditCustomMailingBag} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging/custompackaging/view/boxes' component={ViewCustomBox} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging/custompackaging/view/mailingbags' component={ViewCustomMailingBag} exact />

                    <ProtectedRoute profile='Retailer' feature='ManageIntegrations' path='/retailer/integrations' component={Integrations} exact />
                    <ProtectedRoute profile='Retailer' feature='ManageIntegrations' path='/retailer/integrations/add' component={EditIntegration} exact />   
                    <ProtectedRoute profile='Retailer' feature='ManageIntegrations' path='/retailer/integrations/apps' component={Apps} exact />
                    <ProtectedRoute profile='Retailer' feature='ManageIntegrations' path='/retailer/integrations/apps/add' component={AddApp} exact />
                    <ProtectedRoute profile='Retailer' feature='ManageIntegrations' path='/retailer/integrations/apps/edit/:id' component={EditApp} exact />
                    
                    <ProtectedRoute feature='ViewCard' path='/retailer/finance/newcard' component={CardPayment} exact />
                    <ProtectedRoute feature='ViewCard' path='/retailer/finance/newcard/return' component={CardReturn} exact />

                    <ProtectedRoute feature='ViewCard' path='/retailer/finance/newdirectdebit/' component={NewDirectDebit} exact />
                    <ProtectedRoute feature='ViewCard' path='/retailer/finance/newdirectdebit/:formCancelled' component={NewDirectDebit} exact />

                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder' component={PendingBulkOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder/inprocessorders' component={InProcessBulkOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder/processedorders' component={ProcessedBulkOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder/unsuccessfulorders' component={UnsuccessfulBulkOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder/bulkorderdetails/:id' component={BulkOrderDetails} exact />
                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder/splitdetails' component={BulkOrderSplitDetails} exact />
                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder/info' component={CreateBulkOrder} exact />
                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder/create' component={BulkOrderWizard} exact />

                    <Route path='/*' component={NotFound} />
                </Switch>
            </Layout>
            </>
        );
               
        //#endregion
};


export default withRouter(App);


