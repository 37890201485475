import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { toLocalTimeString } from '../../../Utilities';
import NoResults from '../../common/Tables/NoResults';

const OrderRow = ({ order, onViewDetailsClick }) => {
    return (
        <tr>
            <td>{order?.identifier}</td>
            <td>{order?.sourceDisplay ?? order?.source}</td>
            <td>{toLocalTimeString(order?.createdDate)}</td>
            <td>{!order?.cancelled ? <span className="text-status-purple">{order?.status}</span> : <span className="text-status-red">{order?.status}</span>}</td>
            <td className="text-center">
                <Button className="link-button" variant="secondary" size="sm" key={order?.id} onClick={onViewDetailsClick}>View Order</Button>
            </td>
        </tr>
    );
};

const UnsuccessfulOrderTable = ({ orders, onViewDetailsClick }) =>
    <Table striped bordered size="sm" className={!orders.length ? "table-empty" : ""}>
        <thead>
            <tr>
                <th>Order Identifier</th>
                <th>Customer Name</th>
                <th>Date Created</th>
                <th>Status</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {orders?.length ? orders?.map(order => <OrderRow key={order?.id} order={order} onViewDetailsClick={() => onViewDetailsClick(order)} />) 
            : <NoResults colSpan="6" text="No unsuccessful orders available." />}
        </tbody>
    </Table>

export default UnsuccessfulOrderTable;