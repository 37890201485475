import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { SuccessAlert } from './../../../common/Alert';

const SUCCESS_MESSAGE = "Your bulk order has been created successfully. This may take up to 15 minutes to display in your Pending Orders."
const CANCEL_INFO = "Bulk Orders can be cancelled as long as they are at the Pending orders stage, awaiting Fulfilment by our warehouse staff. We have no way to estimate how long this will be."

const BulkOrderSuccess = () =>
    <Fragment>
        <h5 className="mt-2 mb-3">Confirmation</h5>
        <SuccessAlert successMessage={SUCCESS_MESSAGE} />
        <div className="form-input-description mb-4 py-1">
            <p className="mt-1"><strong>Need to cancel?</strong></p>
            <p className="mb-1">{CANCEL_INFO}</p>
        </div>
        <Link to='/retailer/bulkorder' className="link-button">
            <Button variant="primary" className="float-right">Proceed to Bulk Order Main Menu</Button>
        </Link>
    </Fragment>

export default BulkOrderSuccess;