import React from 'react';
import { Container, Card, Table } from 'react-bootstrap';
import { INVENTORY_SUB_NAV_LIST } from '../../common/constants'
import { ErrorAlert, SuccessAlert } from '../../common/Alert';
import { StockBoxLibraryHeader } from '../stockboxlibrary/StockBoxLibraryHeader';
import { StockboxDetails } from '../stockboxlibrary/StockboxDetails';
import { StockBoxLibraryRow } from './StockBoxLibraryRow';
import { useStockBoxModal } from './hooks/useStockBoxModal';
import { STOCKBOX_LIMIT_MESSAGE } from './shared/StringConstants';
import { useFetchStockbox } from './hooks/useFetchStockbox';
import LoadingBar from '../../common/LoadingBar';
import Pagination from '../../common/Tables/Pagination';
import NoResults from '../../common/Tables/NoResults';
import Confirm from '../../common/Confirm';
import './StockBoxLibrary.scss';

export const StockBoxLibraryList = () => {

  const {
    showModal,
    stockBoxState,
    isSubmited,
    showDuplicatedNameModal,
    isEditMode,
    showPfpDimensionsModal,
    setIsSubmited,
    handleInputChange,
    handleToggleStockboxDetailsModal,
    handleToggleDuplicatedNameModal,
    handleShowHideModal,
    setStockBoxState,
    setIsEditMode,
    handleResetStockBoxState,
    handleTogglePfpDimensionsModal,
    closeAllModals
  } = useStockBoxModal();

  const { 
    loading,
    pageIndex,
    pageCount,
    items,
    errorState,
    successState,
    hasReachedStockBoxLimit,
    totalStockBoxes,
    setErrorState,
    setSuccessState,
    onNext,
    onPrevious,
    onEnd,
    onStart,
    getStockBoxLibraryItems,
    setHasReachedStockBoxLimit,
    createStockBoxLibraryItem,
    updateStockBoxLibraryItem,
    deleteStockBoxLibraryItems,
    setPageIndex
  } = useFetchStockbox();

  const handleAddStockbox = () => {
    setSuccessState({ message: '', show: false });
    if (totalStockBoxes === 200) {
      setHasReachedStockBoxLimit(true);
      return;
    }

    handleShowHideModal();
  }

  const hideStockboxLimitModal = () => {
    setHasReachedStockBoxLimit(false);
  }

  return (
    <>
      {
        loading ? <LoadingBar /> :
        <>
          <StockBoxLibraryHeader headerClass="mb-0" handleAddStockbox={handleAddStockbox} subNavList={INVENTORY_SUB_NAV_LIST} />
          <Container fluid className="p-0">
            {successState.show && <SuccessAlert successMessage={successState.message} headerClass="card-success mb-3 save-success" />}
            {errorState.show && <ErrorAlert errorMessage={errorState.message} headerClass="card-danger mb-3 save-error" />}
            <Card className="card-table">
              <div className="table-responsive">
                <Table striped bordered hover size="sm" className={!items.length ? "table-empty" : ""}>
                  <thead>
                    <tr className="table-header">
                      <th id="stockbox-table-header-name" className="stockbox-table-header">Box Name</th>
                      <th id="stockbox-table-header-dimensions" className="stockbox-table-header header-centered">Dimensions</th>
                      <th id="stockbox-table-header-pfp"></th>
                      <th id="stockbox-table-header-button"></th>
                    </tr>
                  </thead>
                  <tbody style={{ maxHeight: "200px" }}>
                    {
                      items.length ?
                        items.map(item => <StockBoxLibraryRow key={item.id} item={item} setStockBoxState={setStockBoxState} handleShowHideModal={handleShowHideModal} setIsEditMode={setIsEditMode} setErrorState={setErrorState} setSuccessState={setSuccessState} />) :
                        <NoResults colSpan="5" text="No stock boxes added" missingImage='Images/missing-data-isp.svg' />
                    }
                  </tbody>
                </Table>
                <Pagination onNext={onNext} onPrevious={onPrevious} onStart={onStart} onEnd={onEnd} pageIndex={pageIndex} pageCount={pageCount} />
              </div>
            </Card>
          </Container>
          <StockboxDetails
            stockboxState={{ ...stockBoxState }}
            handleConfirmClose={handleShowHideModal}
            setSuccessState={setSuccessState}
            show={showModal}
            setErrorState={setErrorState}
            getStockBoxLibraryItems={getStockBoxLibraryItems}
            handleToggleStockboxDetailsModal={handleToggleStockboxDetailsModal}
            handleInputChange={handleInputChange}
            handleToggleDuplicatedNameModal={handleToggleDuplicatedNameModal}
            isSubmited={isSubmited}
            loading={loading}
            setIsSubmited={setIsSubmited}
            showDuplicatedNameModal={showDuplicatedNameModal}
            isEditMode={isEditMode}
            handleResetStockBoxState={handleResetStockBoxState}
            createStockBoxLibraryItem={createStockBoxLibraryItem}
            updateStockBoxLibraryItem={updateStockBoxLibraryItem}
            deleteStockBoxLibraryItems={deleteStockBoxLibraryItems}
            showPfpDimensionsModal={showPfpDimensionsModal}
            handleTogglePfpDimensionsModal={handleTogglePfpDimensionsModal}
            closeAllModals={closeAllModals}
            items={items}
            setPageIndex={setPageIndex}
          />
          <Confirm title="Stock box library limit" text={STOCKBOX_LIMIT_MESSAGE} show={hasReachedStockBoxLimit} showCloseButton={false} closeButton={false} buttonText="Ok" buttonVariant="primary" handleConfirmAction={hideStockboxLimitModal} />
        </>
      }
    </>
  );
};