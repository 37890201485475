import React, { Component } from 'react';
import { FormGroup, FormLabel, FormControl, Row, Col } from 'react-bootstrap';

class ConsignmentDetails extends Component {

    renderBoxSize = (loading, data, validationMessages, handleInputChange) => {
        return (<React.Fragment>
            <FormGroup as={Row}>
                <Col xs={12} md={3}>
                    <FormLabel htmlFor="heightField">Box Height (cm)</FormLabel>
                    <FormControl disabled={loading} id="heightField" type="number" name="boxHeight" value={data.boxHeight} onChange={handleInputChange} min="0" />
                </Col>
                <Col md={12}>
                    {validationMessages.boxHeightError && <span className="text-danger">Box height must be greater than 0</span>}
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Col xs={12} md={3}>
                    <FormLabel htmlFor="widthField">Box Width (cm)</FormLabel>
                    <FormControl disabled={loading} id="widthField" type="number" name="boxWidth" value={data.boxWidth} onChange={handleInputChange} min="0" />
                </Col>
                <Col md={12}>
                    {validationMessages.boxHeightError && <span className="text-danger">Box height must be greater than 0</span>}
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Col xs={12} md={3}>
                    <FormLabel htmlFor="depthField">Box Depth(cm)</FormLabel>
                    <FormControl disabled={loading} id="depthField" type="number" name="boxDepth" value={data.boxDepth} onChange={handleInputChange} min="0" />
                </Col>
                <Col md={12}>
                    {validationMessages.boxDepthError && <span className="text-danger">Box depth must be greater than 0</span>}
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Col xs={12} md={3}>
                    <FormLabel htmlFor="weightField">Box Weight (kg)</FormLabel>
                    <FormControl disabled={loading} id="weightField" type="number" name="boxWeight" value={data.boxWeight} onChange={handleInputChange} min="0" />
                </Col>
                <Col md={12}>
                    {validationMessages.boxWeightError && <span className="text-danger">Box weight must be greater than 0</span>}
                </Col>
            </FormGroup>
        </React.Fragment>);
    }

    renderGTINForm = (loading, data, validationMessages, handleInputChange, minGtinLength) => {
        return (<React.Fragment>
            <FormGroup as={Row}>
                <Col xs={12} md={6}>
                    <FormLabel htmlFor="gtinField">GTIN Identifier</FormLabel>
                    <FormControl disabled={loading} id="gtinField" type="text" name="gtin" pattern="/^(?:[A-Za-z]+|\d+)$/" value={data.gtin} onChange={handleInputChange} maxLength="50" />
                </Col>
                <Col md={12}>
                    {validationMessages.gtinError && <span className="text-danger">{`Please enter a GTIN of minimum ${minGtinLength} characters`}</span>}
                </Col>
            </FormGroup>
        </React.Fragment>);
    }

    renderLabelOptions = (data, labelChoice, handleInputChange) => {
        return (<React.Fragment>
            <p className="title">Which labels do you want Selazar to affix?</p>
            {(data.requiresGTIN !== 'true') && <FormGroup className="custom-control custom-checkbox mb-0">
                <input className="custom-control-input" type="checkbox" id="stockBoxLabelField" name="stockBox" checked={labelChoice.stockBox} onChange={handleInputChange} />
                <FormLabel className="custom-control-label" htmlFor="stockBoxLabelField">Stock box labels(s)</FormLabel>
            </FormGroup>}
            <FormGroup className="custom-control custom-checkbox">
                <input className="custom-control-input" type="checkbox" id="productLabelField" name="product" checked={labelChoice.product} onChange={handleInputChange} />
                <FormLabel className="custom-control-label" htmlFor="productLabelField">Product label(s)</FormLabel>
            </FormGroup>
        </React.Fragment>);
    }

    render() {
        const { data, handleInputChange, validation, labelChoice, loading, validationMessages, isSingleUpload } = this.props;

        const boxSize = (data.isPallet === 'false' && isSingleUpload) ? this.renderBoxSize(loading, data, validationMessages, handleInputChange) : null;
        const labelOption = (data.supplierPrintedLabels === 'false') ? this.renderLabelOptions(data, labelChoice, handleInputChange) : null;

        return (
            <React.Fragment>
                <h5 className="mt-3">Shipping & Labelling</h5>
                <div className="section-item">
                    <h6>Shipping</h6>
                    <FormLabel htmlFor="shippingReferenceField">Shipping Reference <span className="form-label-optional">- optional</span></FormLabel>
                    <FormControl disabled={loading} id="shippingReferenceField" type="text" name="shippingReference" value={data.shippingReference} onChange={handleInputChange} />
                </div>

                {boxSize}

                <h6>Labelling</h6>
                <p className="title">Would you like Selazar to print any necessary stock consignment labels for you upon receipt?</p>
                <div className="form-input-description">
                    <p>Please be aware that by selecting this labelling service the consignment will attract additional processing costs.</p>
                </div>
                <FormGroup className="custom-control custom-radio mb-0">
                    <input className="custom-control-input" type='radio' id='radio-3' name='supplierPrintedLabels' value={'true'}
                        checked={data.supplierPrintedLabels === 'true'} onChange={handleInputChange} />
                    <FormLabel className="custom-control-label" htmlFor="radio-3">No, I will affix all labels</FormLabel>
                </FormGroup>
                <FormGroup className="custom-control custom-radio">
                    <input className="custom-control-input" type='radio' id='radio-4' name='supplierPrintedLabels' value={'false'}
                        checked={data.supplierPrintedLabels === 'false'} onChange={handleInputChange} />
                    <FormLabel className="custom-control-label" htmlFor="radio-4">Yes, affix labels for me</FormLabel>
                </FormGroup>
                <span className="text-danger">{validation.supplierPrintedLabels.message}</span>
                {labelOption}
            </React.Fragment>
        )
    }
}

export default ConsignmentDetails;