import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { GET } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import { ErrorAlert } from '../../common/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { isNullOrEmptyGuid } from '../../../Utilities';
import OrderBundleDetails from './OrderBundleDetails';
import {
    OrderBreakdown,
    DeliveryDetails,
    ProductDetails
} from './shared/OrderDetailsSnippets';

const ONHOLD_LINK = "/retailer/orders/onhold";
const ERROR_MESSAGE = "There was a problem retrieving the order. Please try again."

class OnHoldOrderDetails extends Component {

    state = {
        orderId: (this.props.match && this.props.match.params) ? this.props.match.params.id : null,
        orderDetails: null,
        loading: true,
        error: null,
        showProductBundle: false,
        selectedProductBundle: null
    };

    async componentDidMount() {
        await this.fetchOrderDetails();
        this.setState({ loading: false });
    }

    fetchOrderDetails = async () => {
        const { orderId } = this.state;
        if (!isNullOrEmptyGuid(orderId)) {
            return GET(Endpoints.ORDERS.GET.ON_HOLD_DETAILS + orderId)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        this.setState({ error: ERROR_MESSAGE });
                    }
                })
                .then(result => {
                    this.setState({ orderDetails: result.data ? result.data : null, error: result.data ? null : ERROR_MESSAGE });
                })
                .catch(error => {
                    this.setState({ error: ERROR_MESSAGE });
                    console.log(error);
                });
        }
    }

    getTotalProducts = (orderContents) => {
        let companyItems = 0;
        let productBundles = 0;

        if (orderContents) {
            if (orderContents.orderedCompanyItems.length > 0) companyItems = orderContents.orderedCompanyItems.reduce((prev, cur) => prev + cur.quantity, 0)
            if (orderContents.orderedProductBundles.length > 0) {
                orderContents.orderedProductBundles.map((orderedProductBundle) => {
                    productBundles += orderedProductBundle.productBundle.totalProducts * orderedProductBundle.quantity;
                });
            }
        }

        return companyItems + productBundles;
    }

    hideProductBundleDetails = async () => this.setState({ showProductBundle: false });
        
    showProductBundleDetails = async (productBundle) => this.setState({ showProductBundle: true, selectedProductBundle: productBundle });

    render() {
        const { orderDetails, loading, error, showProductBundle, selectedProductBundle } = this.state;

        if (showProductBundle) {
            return (
                <Container fluid>
                    <Row>
                        <OrderBundleDetails productBundle={selectedProductBundle} hideDetails={this.hideProductBundleDetails} awaitingStockSkuCodes={orderDetails.awaitingStockSKUCodes} />
                    </Row>
                </Container>
            );
        }

        return (
            loading
                ? <LoadingBar />
                : <Container fluid>
                    <Row>
                        <Col sm={12} md={6}>
                            {error
                                ? <Fragment>
                                    <h2 className="mb-4">Order Details</h2>
                                    <ErrorAlert errorMessage={error} />
                                </Fragment>
                                : <Fragment>
                                    <Link to={ONHOLD_LINK} className="link-button">
                                        <Button variant="link" className="button-nav-return p-0  mb-4"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Orders</Button>
                                    </Link>
                                    <h2 className="mb-4">Order Details</h2>

                                    <OrderBreakdown customerReference={orderDetails.order.customerReference} received={orderDetails.order.received} customerOrganisationPersonName={orderDetails.order.customerOrganisationPersonName} onHold={true} />
                                    <ProductDetails orderContents={orderDetails.order.orderContents} showProductBundleDetails={this.showProductBundleDetails} totalProducts={this.getTotalProducts} awaitingStockSkuCodes={orderDetails.awaitingStockSKUCodes} />
                                    <DeliveryDetails customerOrganisationDepartmentName={orderDetails.order.customerOrganisationDepartmentName} customerAddressLine1={orderDetails.order.customerAddressLine1} customerAddressLine2={orderDetails.order.customerAddressLine2}
                                        customerAddressTown={orderDetails.order.customerAddressTown} customerAddressPostcode={orderDetails.order.customerAddressPostcode} onHold={true} />
                                </Fragment>
                            }
                        </Col>
                    </Row>
                </Container>
        )
    }
}

export default OnHoldOrderDetails;