import React from 'react';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';

const ProductOverview = ({ companyItem, categories, productOverviewValidation, handleInputChange }) =>
    <React.Fragment>
        <h5>Product Overview</h5>
        <FormGroup>
            <FormLabel htmlFor="nameField">Product Name</FormLabel>
            <FormControl id="nameField" type="text" parent="item" name="name" maxLength={98} value={companyItem.item.name} onChange={handleInputChange} />
            <span className="text-danger">{productOverviewValidation.name.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="descriptionField">Product Description</FormLabel>
            <FormControl id="descriptionField" type="text" as="textarea" rows="3" parent="item" name="description" value={companyItem.item.description} onChange={handleInputChange} />
            <span className="text-danger">{productOverviewValidation.description.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="brandField">Brand</FormLabel>
            <FormControl id="brandField" type="text" parent="item" name="brand" value={companyItem.item.brand} onChange={handleInputChange} />
            <span className="text-danger">{productOverviewValidation.brand.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="categoryField">Category</FormLabel>
            <FormControl id="categoryField" name="categoryID" parent="item" as="select" value={companyItem.item.categoryID} onChange={handleInputChange}>
                <option key="" value="">Please Select...</option>;
                {categories.map(category => {
                    return <option key={category.id} value={category.id}>{category.name}</option>;
                })}
            </FormControl>
            <span className="text-danger">{productOverviewValidation.categoryID.message}</span>
        </FormGroup>
    </React.Fragment>

export default ProductOverview;