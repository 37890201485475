import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class SingleConfirmation extends Component {
    render() {
        const { data, companyItems, handleEditStep } = this.props;
        const item = companyItems.find((i) => i.companyItemID === data.companyItemID);
        return (
            <React.Fragment>
                <h5>Confirmation</h5>
                <div className="section-item mt-3">
                    <h6>Item Selection</h6>
                    <p className="title">Item</p>
                    <p>{item ? item.name : "N/A"}</p>
                    <p className="title">SKU</p>
                    <p>{data.skuCode ? data.skuCode : "N/A"}</p>
                    <Button variant="link" className="p-0" onClick={() => handleEditStep(0)}>Edit Single Item</Button>
                </div>
                {data.isPallet === "false" &&
                    <div className="section-item mt-3">
                        <h6>Inventory Details</h6>
                        <p className="title">Is this a pallet</p>
                        <p>No</p>
                        <p className="title">Consignment Box Dimensions (cm)</p>
                        <p>{data.isPallet === "true" ? "N/A" : data.boxHeight + " x " + data.boxWidth + " x " + data.boxDepth}</p>
                        <p className="title">Consignment Weight (kg)</p>
                        <p>{data.isPallet === "true" ? "N/A" : data.boxWeight}</p>
                        <p className="title">Do you want to print your own labels</p>
                        <p>Yes, print labels for me</p>
                        <Button variant="link" className="p-0" onClick={() => handleEditStep(1)}>Edit Consignment Details</Button>
                    </div>
                }
                <div className="section-item mt-3">
                    <h6>Stock Details</h6>
                    <p className="title">Stock Box Quantity</p>
                    <p>{data.boxQuantity}</p>
                    <p className="title">Items Per Stock Box</p>
                    <p>{data.itemsPerBox}</p>
                    <p className="title">Total Stock Quantity</p>
                    <p>{data.stockQuantity}</p>
                    <p className="title">Stock Box Dimensions (cm)</p>
                    <p>{data.stockBoxHeight} x {data.stockBoxWidth} x {data.stockBoxDepth}</p>
                    <p className="title">Stock Box Weight (kg)</p>
                    <p>{data.stockBoxWeight}</p>
                    <p className="title">Batch Number</p>
                    <p>{data.batchNumber ? data.batchNumber : "Not entered"}</p>
                    <p className="title">Expiry Date</p>
                    <p>{data.expiryDate ? data.expiryDate : "Not entered"}</p>
                    {data.isPallet === "true" &&
                        <React.Fragment>
                            <p className="title">Pallet or Consignment</p>
                            <p>{data.isPallet === "true" ? "Pallet" : "Consignment"}</p>
                        </React.Fragment>
                    }
                    <Button variant="link" className="p-0" onClick={() => handleEditStep(1)}>Edit Stock Details</Button>
                </div>
                {data.isPallet === "true" ?
                    <div className="section-item mt-3">
                        <h6>Shipping & Labelling</h6>
                        <p className="title">Shipping Reference</p>
                        <p>{data.shippingReference ? data.shippingReference : "Not entered"}</p>
                        <p className="title">Labelling</p>
                        <p>{data.supplierPrintedLabels === "true" ? "No, I will affix all labels" : "Yes, affix labels for me"}</p>
                        <Button variant="link" className="p-0" onClick={() => handleEditStep(2)}>Edit Shipping & Labelling</Button>
                    </div>
                    :
                    <div className="section-item mt-3">
                        <h6>Shipping Details</h6>
                        <p className="title">Shipping Reference</p>
                        <p>{data.shippingReference ? data.shippingReference : "Not entered"}</p>
                        <Button variant="link" className="p-0" onClick={() => handleEditStep(2)}>Edit Shipping Details</Button>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default SingleConfirmation;