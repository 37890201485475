import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button, FormControl } from 'react-bootstrap';
import { PREFERENCES_SUB_NAV_LIST } from '../../common/constants';
import Endpoints from '../../common/Endpoints';
import { GET, POST, extractData } from '../../../Consumer';
import Header from '../../common/Header';
import LoadingBar from '../../common/LoadingBar';
import { SuccessAlert, ErrorAlert } from '../../common/Alert';

class Warehouse extends Component {

    state = {
        loading: true,
        warehouseSelection: {},
        warehouses: [],
        selectedWarehouseId: "",
        invalidWarehouse: false,
        showSuccess: false,
        showError: false
    };

    async componentDidMount() {
        await Promise.all([
            this.fetchWarehouseSelection(),
            this.fetchAllWarehouses()
        ]);
        this.setState({ loading: false });
    }

    fetchWarehouseSelection = async () =>
        GET(Endpoints.WAREHOUSE.GET.SELECTION)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result);
                this.setState({ warehouseSelection: data, selectedWarehouseId: data.id });
            })
            .catch(error => console.log(error));

    fetchAllWarehouses = async () =>
        GET(Endpoints.WAREHOUSE.GET.ALL)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ warehouses: extractData(results) });
            })
            .catch(error => console.log(error));

    setSelectedWarehouse = (id) => this.setState({ selectedWarehouseId: id });

    onSubmit = () => {
        const { selectedWarehouseId } = this.state;

        if (selectedWarehouseId !== "" && selectedWarehouseId !== null) {
            this.setState({ loading: true, invalidWarehouse: false });

            return POST(Endpoints.WAREHOUSE.POST.SELECTION + selectedWarehouseId)
                .then(response => {
                    if (response && response.ok) return response.json();
                })
                .then(result => {
                    const data = extractData(result);
                    if(data !== null){
                        localStorage.setItem('user', JSON.stringify(data.userToken));
                        this.setState({ warehouseSelection: data, showSuccess: true, showError: false, loading: false })
                    }
                    else this.setState({ showError: true, showSuccess: false, loading: false });
                })
                .catch(error => {
                    this.setState({ showError: true, showSuccess: false, loading: false });
                    console.log(error);
                });
        } else this.setState({ invalidWarehouse: true });
    }

    render() {
        const { loading, warehouseSelection, warehouses, selectedWarehouseId, showSuccess, showError, invalidWarehouse } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <Header title="Preferences" subNavList={PREFERENCES_SUB_NAV_LIST} activeKey="Warehouse" headerClass="mb-2" />
                    <Container fluid>
                        <Row>
                            <Col sm={12} md={6}>
                                <h5 className="mb-3">Warehouse Preferences</h5>
                                {showSuccess && <SuccessAlert successMessage="Warehouse selection successfully updated." />}
                                {showError && <ErrorAlert errorMessage="Warehouse selection failed, please try again later." />}
                                {warehouseSelection.hasCard
                                    ? <Fragment>
                                        <p className="mb-0"><strong>Warehouse Selection</strong></p>
                                        <p>{warehouseSelection.name}</p>
                                    </Fragment>
                                    : <Fragment>
                                        <p>We need to know what warehouse you would like your inventory to be stored and fulfilled from. If you would like advice on the most suitable warehouse for you, contact our customer service team by phoning <a href="tel:02039507860">020 3950 7860</a> or send an email to <a href="mailto:customer.service@selazar.com.">customer.service@selazar.com</a>.</p>
                                        <p><strong>Warehouse Selection</strong></p>
                                        <div className="form-input-description">
                                            <p>Once you select a warehouse and save your selection you will be unable to undo this action.</p>
                                        </div>
                                        <FormControl id="warehouse" name="warehouse" as="select" onChange={e => this.setSelectedWarehouse(e.target.value)} value={selectedWarehouseId}>
                                            <option key="" value="">Please Select...</option>;
                                            {warehouses.map(w => <option key={w.id} value={w.id}>{w.name}</option>)}
                                        </FormControl>
                                        {invalidWarehouse && <span className="text-danger">Please select a warehouse</span>}
                                        <Button className="float-right mt-4" onClick={this.onSubmit}>Save Warehouse</Button>
                                    </Fragment>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Fragment>
        )
    }
}

export default Warehouse;

