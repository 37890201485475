import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { toLocalDateString } from '../../../../Utilities';
import NoResults from '../../../common/Tables/NoResults';

const RemittanceAdviceRow = ({ remittanceAdvice }) =>
    <tr>
        <td>{toLocalDateString(remittanceAdvice.remittanceDate)}</td>
        <td>{remittanceAdvice.remittanceAdviceNumber ? remittanceAdvice.remittanceAdviceNumber : "Not Charged"}</td>
        <td>{remittanceAdvice.collectionAdvice.currencyAbbreviation}</td>
        <td>{remittanceAdvice.collectionAdvice.formattedTotal}</td>
        <td className="text-center py-2"><Button variant="secondary" size="sm" onClick={() => window.open(remittanceAdvice.link, '_blank')}>View</Button></td>
    </tr>

const RemittanceAdviceTable = ({ remittanceAdvices }) =>
    <Table striped bordered size="sm" className={!remittanceAdvices.length ? "table-empty" : ""}>
        <thead>
            <tr>
                <th>Date</th>
                <th>Remittance Advice Number</th>
                <th>Currency</th>
                <th>Total</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {remittanceAdvices.length
                ? remittanceAdvices.map(remittanceAdvice => <RemittanceAdviceRow key={remittanceAdvice.id} remittanceAdvice={remittanceAdvice} />)
                : <NoResults colSpan="4" text="No remittances available." />
            }
        </tbody>
    </Table>

export default RemittanceAdviceTable;