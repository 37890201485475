import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { isNullOrEmptyGuid, checkPermission } from '../../../Utilities';
import { GET, PUT, DELETE, extractData } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import SelazarLinkContainer from '../../common/SelazarLinkContainer';
import { toLocalTimeString } from '../../../Utilities';
import LoadingBar from '../../common/LoadingBar';
import Confirm from '../../common/Confirm';
import { ErrorAlert } from '../../common/Alert';
import Breadcrumbs from '../../common/Breadcrumbs';

import './Account.scss';

const USER_ERROR_MESSAGE = "There was a problem retrieving the user. Please try again.";
const ROLE_OPTIONS_ERROR_MESSAGE = "Could not retrieve role options";
const USER_PERMISSIONS_ERROR_MESSAGE = "Could not retrieve user permissions";
const TOGGLE_USER_ERROR_MESSAGE = "Could not toggle user";
const DELETE_USER_ERROR_MESSAGE = "Could not delete user";

const BREADCRUMBS = [
    { text: "Users", link: "/company/users" },
    { text: "View User Account", link: null }
];

const UserDetails = ({ userId, forename, surname, email, roleOptions, roles, createdDate }) =>
    <Card>
        <Card.Header><strong>User Details</strong></Card.Header>
        <Card.Body>
            <Card.Text><strong>Forename</strong></Card.Text>
            <Card.Text className="mb-2">{forename}</Card.Text>

            <Card.Text><strong>Surname</strong></Card.Text>
            <Card.Text className="mb-2">{surname}</Card.Text>

            <Card.Text><strong>Email</strong></Card.Text>
            <Card.Text className="mb-2">{email}</Card.Text>
            {Object.keys(roleOptions).map((o, i) =>
                <div key={o} className="mb-2">
                    <Card.Text key={`${o}-${i}`}><strong>{o} Roles</strong></Card.Text>
                    {roleOptions[o].map((r, i) =>
                        roles.indexOf(r.id) > -1 && <Card.Text key={`${r.name}-${i}`}>{r.name}</Card.Text>
                    )}
                </div>
            )}
            {createdDate && <React.Fragment>
                <Card.Text><strong>Account Created</strong></Card.Text>
                <Card.Text className="mb-2">{toLocalTimeString(createdDate)}</Card.Text>
            </React.Fragment>}
            {checkPermission('EditUser', <SelazarLinkContainer to={{ pathname: `/company/users/editdetails/${userId}`, state: { returnToAccount: true } }}><Button variant="link" className="float-right p-0">Edit User Details</Button></SelazarLinkContainer>)}
        </Card.Body>
    </Card>

const UserPermissions = ({ userPermissions, editLink }) =>
    <Card>
        <Card.Header><strong>Permissions</strong></Card.Header>
        <Card.Body>
            {userPermissions.length ? userPermissions.map((parent) =>
                <React.Fragment>
                    <Card.Text key={`${parent.name}-permissions`}><strong>{parent.name}</strong></Card.Text>
                    {parent.permissions.length ? parent.permissions.filter((permission) => permission.enabled === true).map((permission) =>
                        <Card.Text>{permission.featureEnumName}</Card.Text>
                    ) : null}
                </React.Fragment>
            ) : null}
            {editLink && editLink}
        </Card.Body>
    </Card>

class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            userID: props.match.params.id || null,
            roleOptions: {},
            userPermissions: [],
            warning: null,
            error: false,
            redirect: false,
            showDeleteUserConfirmation: false,
            showToggleUserConfirmation: false,
            user: {
                id: '',
                forename: '',
                surname: '',
                email: '',
                active: true,
                roles: [],
                createdDate: null
            }
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchUser(null),
            this.fetchRoleOptions(),
            this.fetchUserPermissions(),
            this.setState({ loading: false })
        ])
    }

    fetchUser = (loading) => {
        const { userID } = this.state;
        if (!isNullOrEmptyGuid(userID)) {
            GET(Endpoints.GET_USER_WITH_ROLES + userID)
                .then(response => response.json())
                .then(result => {
                    const data = extractData(result);
                    if (data === null || data === undefined) this.setState({ user: [], error: true });
                    else {
                        const user = { ...data, roles: data.userRoles.map((ur) => ur.roleID) };
                        this.setState({ user: user, error: false });
                    }
                })
                .catch(error => {
                    this.setState({ error: true });
                    console.log(error);
                });
                   
        }
        if(loading != null) this.setState({ loading: loading });
    }

    fetchRoleOptions = () => {
        return GET(Endpoints.GET_ROLE_OPTIONS_FOR_COMPANY)
            .then(response => response.json())
            .then((result) => {
                const data = extractData(result);
                if (data === null) this.setState({ roleOptions: [], warning: result.message || ROLE_OPTIONS_ERROR_MESSAGE });
                else if(data === undefined) this.setState({ warning: ROLE_OPTIONS_ERROR_MESSAGE, roleOptions: [] });
                else this.setState({ roleOptions: data });
            })
            .catch(error => {
                this.setState({ warning: ROLE_OPTIONS_ERROR_MESSAGE });
                console.log(error);
            });
    }

    fetchUserPermissions = () => {
        const { userID } = this.state;
        if (!isNullOrEmptyGuid(userID)) {
            return GET(Endpoints.GET_FEATURE_BREAKDOWN_FOR_USER + userID)
                .then(response => response.json())
                .then(result => {
                    const data = extractData(result);
                    if (data === null) this.setState({ userPermissions: [], warning: result.message || USER_PERMISSIONS_ERROR_MESSAGE });
                    else if(data === undefined) this.setState({ userPermissions: [], warning: USER_PERMISSIONS_ERROR_MESSAGE });
                    else this.setState({ userPermissions: data });
                })
                .catch(error => {
                    this.setState({ warning: USER_PERMISSIONS_ERROR_MESSAGE });
                    console.log(error);
                });
        }
    }

    handleToggleShowHide = () => this.setState((prevState) => ({ showToggleUserConfirmation: !prevState.showToggleUserConfirmation }));
    handleDeleteShowHide = () => this.setState((prevState) => ({ showDeleteUserConfirmation: !prevState.showDeleteUserConfirmation }));

    handleToggleAction = () => {
        const { user } = this.state;
        if (Object.keys(user).indexOf('id') === -1) {
            this.setState({ warning: 'Unable to toggle user' });
            return;
        }

        this.setState({ loading: true, showToggleUserConfirmation: false });

        return PUT(Endpoints.TOGGLE_USER + user.id)
            .then(response => response.json())
            .then(result => {
                const data = extractData(result);
                if (data === null) this.setState({ warning: result.message || TOGGLE_USER_ERROR_MESSAGE, loading: false });
                else if (data === undefined) this.setState({ warning: TOGGLE_USER_ERROR_MESSAGE, loading: false });
                else this.fetchUser(false);
            })
            .catch(error => {
                this.setState({ warning: TOGGLE_USER_ERROR_MESSAGE, loading: false });
                console.log(error);
            });
    }

    handleDeleteAction = () => {
        const { user } = this.state;
        if (Object.keys(user).indexOf('id') === -1) {
            this.setState({ warning: 'Unable to delete user' });
            return;
        }

        this.setState({ loading: true, showDeleteUserConfirmation: false });

        return DELETE(Endpoints.DELETE_USER + user.id)
            .then(response => response.json())
            .then(result => {
                const data = extractData(result);
                if (data === null) this.setState({ loading: false, warning: result.message || DELETE_USER_ERROR_MESSAGE });
                else if (data === undefined) this.setState({ loading: false, warning: DELETE_USER_ERROR_MESSAGE });
                else {
                    if (data === true) this.setState({ loading: false, redirect: true });
                    else this.setState({ warning: result.message || DELETE_USER_ERROR_MESSAGE, loading: false });
                }
            })
            .catch(error => {
                this.setState({ warning: DELETE_USER_ERROR_MESSAGE, loading: false });
                console.log(error);
            });
    }

    render() {
        const { user, warning, loading, redirect, roleOptions, userPermissions, showToggleUserConfirmation, showDeleteUserConfirmation, error } = this.state;
        const currentUser = JSON.parse(localStorage.getItem('user'));
        const currentUserID = currentUser.id;
        const isSameUser = currentUserID === user.id;
        const toggleMessage = `Are you sure you want to ${user.active ? 'deactivate' : 'activate'} ${user.forename} ${user.surname}?`;
        const deleteMessage = `Are you sure you want to delete ${user.forename} ${user.surname}?`
        const editPermissions = checkPermission('EditPermission', <SelazarLinkContainer to={{ pathname: `/users/permissions/${user.id}`, state: { fromAccount: true } }}><Button variant="link" className="float-right p-0">Edit User Permissions</Button></SelazarLinkContainer>);

        return (
            <Container fluid>
                <h2>User Account</h2>
                <Breadcrumbs breadcrumbs={BREADCRUMBS} />
                {loading ?
                    <LoadingBar /> :
                    redirect ?
                        <Redirect to='/company/users' /> :
                        <React.Fragment>
                            {error ?
                                <Row className="mt-4">
                                    <Col sm={12} md={6}>
                                        <ErrorAlert errorMessage={USER_ERROR_MESSAGE} />
                                    </Col>
                                </Row> :
                                <React.Fragment>
                                    <Row className="mt-4">
                                        <Col sm={12} md={6}>
                                            {warning && <ErrorAlert errorMessage={warning} />}
                                            <UserDetails userId={user.id} forename={user.forename} surname={user.surname} email={user.email} roleOptions={roleOptions} roles={user.roles} createdDate={user.createdDate} />
                                        </Col>
                                        <Col sm={12} md={6}>
                                            {user.lastLogin && <p>Last Login <strong>{toLocalTimeString(user.lastLogin)}</strong></p>}
                                            <p><strong>Actions</strong></p>
                                            <div className="link-button">
                                                <SelazarLinkContainer to={{ pathname: '/company/users/password', state: { user: user } }}>
                                                    <Button variant="secondary" className="d-block account-button-action link-button mb-2">Change Password</Button>
                                                </SelazarLinkContainer>
                                            </div>
                                            {!isSameUser && checkPermission('ToggleUser', <Button variant={user.active ? "danger" : "secondary"} className="d-block account-button-action mb-2" onClick={this.handleToggleShowHide}>{user.active ? "Deactivate User" : "Activate User"}</Button>)}
                                            {!isSameUser && checkPermission('DeleteUser', <Button variant="danger" className="d-block account-button-action mb-2" onClick={this.handleDeleteShowHide}>Delete User</Button>)}
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col sm={12} md={6}>
                                            <UserPermissions userPermissions={userPermissions} editLink={editPermissions} />
                                        </Col>
                                    </Row>
                                    {showToggleUserConfirmation && <Confirm title={user.active ? "Confirm Deactivation" : "Confirm Activation"} text={toggleMessage} buttonText={user.active ? "Deactivate" : "Activate"} buttonVariant={user.active ? "danger" : "secondary"} handleConfirmClose={this.handleToggleShowHide} handleConfirmAction={this.handleToggleAction} />}
                                    {showDeleteUserConfirmation && <Confirm title={"Confirm Deletion"} text={deleteMessage} buttonText={"Delete"} buttonVariant="danger" handleConfirmClose={this.handleDeleteShowHide} handleConfirmAction={this.handleDeleteAction} />}
                                </React.Fragment>}
                        </React.Fragment>
                }

            </Container>
        );
    }
}

export default Account;