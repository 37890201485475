import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormControl, FormLabel, FormGroup, Button } from 'react-bootstrap';
import { hasPermission } from '../../../Utilities';
import LoadingBar from '../../common/LoadingBar';
import { GET, extractData, POST, PUT } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import FormValidator from '../../common/FormValidator';
import { isNullOrEmptyGuid } from '../../../Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ErrorAlert } from '../../common/Alert';
import Header from '../../common/Header';
import { USER_SUB_NAV_LIST, USER_RESTRICTED_SUB_NAV_LIST } from '../../common/constants';

const strongPasswordPattern = /^(?=.*[a-z])(?=.*[0-9])(?=.*[#$^+=!*()@%&"'[\]{},_\\/;:`\-~<>]).{6,}/;
const numberPattern = /^(?=.*[0-9])/;
const specialCharPattern = /^(?=.*[#$^+=!*()@%&"'[\]{},_\\/;:`\-~<>])/;
const lengthPattern = /^.{6,}/;

const ROLE_OPTIONS_ERROR_MESSAGE = "Could not retrieve role options";
const USER_ERROR_MESSAGE = "Could not retrieve user";

class EditUser extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'forename',
                method: 'isEmpty',
                validWhen: false,
                message: 'Forename is required'
            },
            {
                field: 'surname',
                method: 'isEmpty',
                validWhen: false,
                message: 'Surname is required'
            },
            {
                field: 'email',
                method: 'isEmpty',
                validWhen: false,
                message: 'Email is required'
            },
            {
                field: 'password',
                method: 'matches',
                args: [strongPasswordPattern],
                validWhen: true,
                message: 'Password must contain at least one number, one special character and at least 6 characters'
            },
            {
                field: 'password',
                method: 'isLength',
                args: [{min: 6, max: 50}],
                validWhen: true,
                message: 'Password must be between 6 and 50 characters long'
            }
        ]);

        this.state = {
            loading: true,
            validation: this.validator.valid(),
            errors: [],
            roleOptions: {},
            userID: props.match.params.id || null,
            passwordNumberCheck: false,
            passwordSpecialCharCheck: false,
            passwordLengthCheck: false,
            passwordInputType: 'password',
            passwordInputIcon: faEye,
            user: {
                id: '',
                forename: '',
                surname: '',
                email: '',
                password: '',
                roles: []
            },
            validationMessages: {
                roles: false,
                passwords: false
            }
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchRoleOptions(),
            this.fetchUser(),
            this.setState({ loading: false })
        ]);
    }

    validate = (user) => {
        let validation = this.validator.validate(user);

        if (!user.password) {
            validation.isValid = false;
            validation.password.isInvalid = true;
        }

        return validation;
    }

    fetchUser = () => {
        let { userID } = this.state;
        if (!isNullOrEmptyGuid(userID)) {
            return GET(Endpoints.GET_USER_WITH_ROLES + userID)
                .then(response => response.json())
                .then(result => {
                    const data = extractData(result);
                    if (data === null) this.setState({ user: [], warning: result.message || USER_ERROR_MESSAGE });
                    else if (data === undefined) this.setState({ user: [], warning: USER_ERROR_MESSAGE });
                    else {
                        const user = { ...data, roles: data.userRoles.map((ur) => ur.roleID) };
                        this.setState({ user: user });
                    }
                })
                .catch(error => {
                    this.setState({ warning: USER_ERROR_MESSAGE });
                    console.log(error);
                });
        }
    }

    fetchRoleOptions = () => {
        return GET(Endpoints.GET_ROLE_OPTIONS_FOR_COMPANY)
            .then(response => response.json())
            .then(result => {
                const data = extractData(result);
                if (data === null) this.setState({ roleOptions: [], warning: result.message || ROLE_OPTIONS_ERROR_MESSAGE });
                else if (data === undefined) this.setState({ roleOptions: [], warning: ROLE_OPTIONS_ERROR_MESSAGE });
                else this.setState({ roleOptions: data });
            })
            .catch(error => {
                this.setState({ warning: ROLE_OPTIONS_ERROR_MESSAGE });
                console.log(error);
            });
    }

    handleInputChange = (e) => {
        const { name, value, type } = e.target;

        if (name === "password") {
            const numberPass = numberPattern.test(value);
            const specialCharPass = specialCharPattern.test(value);
            const lengthPass = lengthPattern.test(value);

            this.setState(prevState => ({
                passwordNumberCheck: numberPass,
                passwordSpecialCharCheck: specialCharPass,
                passwordLengthCheck: lengthPass,
                user: {
                    ...prevState.user,
                },
                validationMessages: {
                    ...prevState.validationMessage
                }
            }));
        }

        if (type === 'checkbox') {
            let currRole = this.state.user.roles.indexOf(name);
            if (currRole > -1) {
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        ...{ roles: [...prevState.user.roles.slice(0, currRole), ...prevState.user.roles.slice(currRole + 1)] }
                    }
                }));
            }
            else {
                let newRoles = this.state.user.roles;
                newRoles.push(name);
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        ...{ roles: newRoles }
                    }
                }));
            }
        }
        else {
            this.setState(prevState => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    ...{ [name]: value }
                }
            }));
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { user } = this.state;
        const userID = JSON.parse(localStorage.getItem('user')).id;

        if (!user.password) user.password = '';

        const validation = this.validator.validate(user);
        this.setState({ validation: validation, loading: validation.isValid });
        this.submitted = true;

        if (userID !== user.ID) {
            if (user.roles.length === 0) {
                validation.isValid = false;
                this.setState({ validationMessages: { roles: true } });
            }
        }

        if (validation.isValid) {
            if (isNullOrEmptyGuid(user.id)) {
                POST(Endpoints.ADD_COMPANY_USER, user)
                    .then(response => response.json())
                    .then(e => {
                        if (!e.error) {
                            this.props.history.push('/company/users');
                        } else {
                            let arrays = Object.keys(e).map(k => e[k]);
                            let errors = [].concat.apply([], arrays);
                            this.setState({ errors: errors, loading: false });
                        }
                    })
                    .catch(error => {
                        this.setState({ errors: ["Could not add user"], loading: false });
                        console.log(error);
                    });
            } else {
                PUT(Endpoints.EDIT_COMPANY_USER, user)
                    .then(response => response.json())
                    .then(e => {
                        if (!e.error) {
                            this.props.history.push('/company/users');
                        } else {
                            let arrays = Object.keys(e).map(k => e[k]);
                            let errors = [].concat.apply([], arrays);
                            this.setState({ errors: errors, loading: false });
                        }
                    })
                    .catch(error => {
                        this.setState({ errors: ["Could not save user"], loading: false });
                        console.log(error);
                    });
            }
        }
        else this.setState({ loading: validation.isValid, loading: false });
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ?
            this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) :
            this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    render() {
        const { loading, warning, errors, user, validationMessages, passwordNumberCheck, passwordSpecialCharCheck, passwordLengthCheck, passwordInputType, passwordInputIcon } = this.state;
        const loggedInUser = JSON.parse(localStorage.getItem('user'));
        const validation = this.submitted ? this.validate(user) : this.state.validation;
        const userID = JSON.parse(localStorage.getItem('user')).id;

        if (userID !== user.ID && this.submitted) {
            if (user.roles.length === 0) {
                validation.isValid = false;
                validationMessages.roles = true;
            }
        }

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <Header title="Users" subNavList={hasPermission("AddUser") ? USER_SUB_NAV_LIST : USER_RESTRICTED_SUB_NAV_LIST} activeKey={hasPermission("AddUser") ? "Add New User" : "Users"} />
                    <Container fluid>
                        <Row>
                            <Col sm={12} md={6}>
                                {warning && <ErrorAlert errorMessage={warning} bsStyle="warning" />}
                                {errors.length > 0 && <ErrorAlert errorMessage={<React.Fragment>{errors.map((e, i) => e ? <p key={i}>{e}</p> : null)}</React.Fragment>} />}
                                <Form onSubmit={this.handleSubmit}>
                                    <input type="hidden" name="id" value={user.id} />
                                    <fieldset>
                                        <h5>Personal Information</h5>
                                        <FormGroup>
                                            <FormLabel htmlFor="forename">Forename</FormLabel>
                                            <FormControl id="forename" type="text" name="forename" value={user.forename} onChange={this.handleInputChange} />
                                            <span className="text-danger">{validation.forename.message}</span>
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel htmlFor="surname">Surname</FormLabel>
                                            <FormControl id="surname" type="text" name="surname" value={user.surname} onChange={this.handleInputChange} />
                                            <span className="text-danger">{validation.surname.message}</span>
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel htmlFor="email">Email</FormLabel>
                                            <FormControl id="email" type="email" name="email" autoComplete="off" value={user.email} onChange={this.handleInputChange} />
                                            <span className="text-danger">{validation.email.message}</span>
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel htmlFor="password">Password</FormLabel>
                                            <FormControl id="password" type={passwordInputType} name="password" autoComplete="new-password" value={user.password} maxLength="50" onChange={this.handleInputChange} />
                                            <span className="form-password-icon" onClick={this.handleShowHidePassword}><FontAwesomeIcon icon={passwordInputIcon} /></span>
                                            <span className="text-danger">{validation.password.message}</span>
                                        </FormGroup>
                                        <div className="form-password-checklist">
                                            <ul>
                                                <li className={passwordNumberCheck ? "pass" : "fail"}>One number</li>
                                                <li className={passwordSpecialCharCheck ? "pass" : "fail"}>One special character</li>
                                                <li className={passwordLengthCheck ? "pass" : "fail"}>Six characters long</li>
                                            </ul>
                                        </div>
                                    </fieldset>
                                    {loggedInUser.id !== user.id && Object.keys(this.state.roleOptions).map((k) =>
                                        <div key={k} className="py-2">
                                            <h5 className="pb-2">{k} Roles</h5>
                                            {this.state.roleOptions[k].map((r) =>
                                                <FormGroup key={r.id} className="custom-control custom-checkbox mb-1">
                                                    <input className="custom-control-input" id={r.id} type="checkbox" name={r.id} onChange={this.handleInputChange} checked={user.roles.indexOf(r.id) > -1} />
                                                    <FormLabel className="custom-control-label" htmlFor={r.id}>{r.name}</FormLabel>
                                                </FormGroup>
                                            )}
                                        </div>
                                    )}
                                    {validationMessages.roles && <span className="text-danger">Select a Role</span>}
                                    <FormGroup className="mt-med">
                                        <Button className="btn btn-primary float-right" type="submit">Submit</Button>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Fragment>
        );
    }
}

export default EditUser;