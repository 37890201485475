import React from 'react';
import { Button, Col, FormLabel, Form, FormControl, FormGroup, Row, InputGroup } from 'react-bootstrap';
import { GET, extractData } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ErrorAlert } from '../../common/Alert';

class StockListFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = { categoryOptions: [] };
    }

    componentDidMount() {
        Promise.all([this.fetchCategories()]);
    }

    fetchCategories = () => {
        return GET(Endpoints.CATEGORY.GET.ALL)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const categories = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ categoryOptions: categories });
            })
            .catch(error => console.log(error));
    }

    render() {
        const { searchText, category, onFilterChange, onReset, onSearch, onExport, exportError, message } = this.props;
        const categoryOptions = this.state.categoryOptions || [];

        return (<Form onSubmit={onSearch}>
            <FormGroup>
                {exportError &&
                    <Row>
                        <Col>
                            <ErrorAlert errorMessage={message} />
                        </Col>
                    </Row>}
                <Row>
                    <Col md={3}>
                        <InputGroup>
                            <FormControl type="text" id="searchText" className="input-filter" name="searchText" value={searchText} onChange={onFilterChange} placeholder="Search by Name, SKU, EAN or SUID" />
                            <InputGroup.Append>
                                <InputGroup.Text className="input-filter-icon" onClick={onSearch}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col md={1} className="pr-0">
                        <FormLabel htmlFor="invoiceType" className="float-right label-filter">Category</FormLabel>
                    </Col>
                    <Col md={3}>
                        <FormControl id="category" name="category" as="select" value={category} onChange={onFilterChange}>
                            <option key="" value="">Please Select...</option>;
                            {categoryOptions.map((option) => <option key={option.id} value={option.name}>{option.name}</option>)}
                        </FormControl>
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Button variant="primary" className="float-right ml-2" name="export" onClick={onExport}>Export CSV</Button>
                <Button variant="primary" className="float-right" type="submit">Apply filter</Button>
                <Button variant="link" className="float-right" name="filterReset" onClick={onReset}>Clear filter</Button>
            </FormGroup>
        </Form>);
    }
}

export default StockListFilter;