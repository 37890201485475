import React, { Fragment, useCallback, useEffect, useState } from 'react';
import LoadingBar from '../../common/LoadingBar';
import Header from '../../common/Header';
import { PAYMENTS_RESTRICTED_SUB_NAV_LIST, PAYMENTS_SUB_NAV_LIST } from '../../common/constants';
import AddCard from './AddCard';
import CompleteCard from './CompleteCard';
import CardForm from './CardForm';
import CardDetails from './CardDetails';
import Endpoints from '../../common/Endpoints';
import { GET_ASYNC, DELETE } from '../../../Consumer';
import { Container, Col, Row } from 'react-bootstrap';
import MissingDetails from '../../common/MissingDetails';
import { WarningAlert } from '../../common/Alert';
import { useHistory } from 'react-router';

const MISSING_DETAILS_MESSAGE = "You need to complete the following before you are able to add a card.";
const ERROR_ALERT_MESSAGE = "An API error has occured, please try again.";

const CardPayment = ({location}) => {
    
    //#region State

    const { push } = useHistory();

    const [loading, setLoading] = useState(true);
    const [cardValidityLoading, setcardValidityLoading] = useState(false);
    const [hasValidWarehouseLoading, setHasValidWarehouseLoading] = useState(false);
    const [getLatestCardDetailsLoading, setGetLatestCardDetails] = useState(false);
    const [directDebitEnabledLoading, setDirectDebitEnabledLoading] = useState(false);
    const [deleteCurrentCardLoading, setDeleteCurrentCardLoading] = useState(false);

    const [showError, setShowError] = useState(false);

    const [directDebitEnabled, setDirectDebitEnabled] = useState(false);
    const [hasValidWarehouse, setHasValidWarehouse] = useState(false);
    const [replaceCardClick, setReplaceCardClick] = useState(false);
    const [removeCardSuccess, setRemoveCardSuccess] = useState(false);
    const [showRemoveCardConfirmation, setShowRemoveCardConfirmation] = useState(false);
    const [cardDetails, setCardDetails] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [showCompleteError, setShowCompleteError] = useState(location?.state?.showCompleteError || false);
    const [completeErrorMessage] = useState(location?.state?.completeErrorMessage || undefined);
    const [renders, setRenders] = useState(0);

    useEffect(() => {
        setLoading(hasValidWarehouseLoading || getLatestCardDetailsLoading || directDebitEnabledLoading || deleteCurrentCardLoading || cardValidityLoading)
    }, [hasValidWarehouseLoading, getLatestCardDetailsLoading, directDebitEnabledLoading, deleteCurrentCardLoading, cardValidityLoading])

    //#endregion

    //#region API 

    useEffect(() => {
        (async () => {
            setcardValidityLoading(true);
            try {
                const response = await GET_ASYNC(Endpoints.FINANCE.CARD.GET.HAS_VALID_DETAILS);
                if (!response?.ok || response?.status !== 200) {
                    setShowError(true);
                }
            } catch (error) {
                console.log(error);
                setShowError(true);
            } finally {
                setcardValidityLoading(false);
            }
        })();
    }, [renders]);

    useEffect(() => {
        (async () => {
            setHasValidWarehouseLoading(true);
            try {
                const response = await GET_ASYNC(Endpoints.WAREHOUSE.GET.SELECTION);
                if (response?.ok) {
                    const data = await response.json();
                    setHasValidWarehouse(data.data.id !== null && data.data.id !== "");
                }
            } catch (error) {
                console.log(error);
                setShowError(true);
            } finally {
                setHasValidWarehouseLoading(false);
            }
        })();
    }, [renders]);

    useEffect(() => {
        (async () => {
            setGetLatestCardDetails(true);
            try {
                const response = await GET_ASYNC(Endpoints.FINANCE.CARD.GET.LATEST);
                if (response?.ok) {
                    const data = await response.json();
                    // if card is in error state we don't show it
                    if(data.data.isError){
                        setCardDetails(null);
                    } else {
                        setCardDetails({ name: data.data.holderName, cardSummary: data.data.cardSummary, active: data.data.isActive });
                    }
                    
                }
            } catch (error) {
                console.log(error);
                setShowError(true);
            } finally {
                setGetLatestCardDetails(false);
            }
        })();
    }, [removeCardSuccess, renders]);

    useEffect(() => {
        (async () => {
            setDirectDebitEnabledLoading(true);
            try {
                const response = await GET_ASYNC(Endpoints.FINANCE.DIRECT_DEBIT.ENABLED);
                if (response?.ok) {
                    const data = await response.json();
                    setDirectDebitEnabled(data.data);
                }
            } catch (error) {
                console.log(error);
                setShowError(true);
            } finally {
                setDirectDebitEnabledLoading(false);
            }
        })();
    }, [renders]);

    const disableCurrentCard = useCallback(async () => {
        setDeleteCurrentCardLoading(true);
        try {
            const response = await DELETE(Endpoints.FINANCE.CARD.DELETE.REMOVE)
            if(response?.ok) {
                setRemoveCardSuccess(true);
                setShowRemoveCardConfirmation(true);
                setShowRemoveModal(false)
            }
        } catch (error) {
            console.log(error);
            setShowError(true);
        } finally {
            setDeleteCurrentCardLoading(false);
        }
    }, [])


    //#endregion

    //#region Actions

    const onAddCardClick = () => setShowForm(true);

    const onTryAgainClick = () =>  {
        setShowCompleteError(false);
        push("/retailer/finance/newcard", { showCompleteError:false, completeErrorMessage: undefined });
        setRenders(renders => renders + 1);
    }

    const onRemoveCardAction = async () => {
        await disableCurrentCard();
    }

    const onReplaceCardClick = () => {
        setShowForm(true);
        setReplaceCardClick(cardDetails.active);
    }

    const onCancelClick = () => setShowForm(false);
    const hideShowRemoveModal = () => setShowRemoveModal(prevState => !prevState);

    //#endregion

    //#region Render
    
    if (loading) {
        return <LoadingBar />;
    }

    return (
        showError 
        ? <>
            <Header title="Payments" subNavList={directDebitEnabled ? PAYMENTS_SUB_NAV_LIST : PAYMENTS_RESTRICTED_SUB_NAV_LIST} activeKey="Card Payment" />
            <WarningAlert linkRequired="true" link="retailer/finance/newcard" linkPreText={ERROR_ALERT_MESSAGE} linkText="Reload" /></>
            : <>
                <Header title="Payments" subNavList={directDebitEnabled ? PAYMENTS_SUB_NAV_LIST : PAYMENTS_RESTRICTED_SUB_NAV_LIST} activeKey="Card Payment" />
                {hasValidWarehouse
                    ?
                    (showCompleteError)
                        ? <CompleteCard errorMessages={completeErrorMessage} onTryAgainClick={onTryAgainClick} />
                        : (cardDetails && !showForm)
                            ? <CardDetails
                                details={cardDetails}
                                showRemoveModal={showRemoveModal}
                                hideShowRemoveModal={hideShowRemoveModal}
                                onRemoveCardAction={onRemoveCardAction}
                                onReplaceCardClick={onReplaceCardClick}
                                showRemoveCardConfirmation={showRemoveCardConfirmation}

                            />
                            : showForm
                                ? <CardForm
                                    handleCancelClick={onCancelClick}
                                    replaceCardClick={replaceCardClick}
                                />
                                : <AddCard onAddCardClick={onAddCardClick} />
                    : <Container fluid>
                        <Row>
                            <Col sm={12} md={6}>
                                <MissingDetails message={MISSING_DETAILS_MESSAGE} showWarehouse={!hasValidWarehouse} showCard={false} />
                            </Col>
                        </Row>
                    </Container>
                }
            </>
    )
}

export default CardPayment;

//#endregion