import React from 'react';
import { Button, Col, FormLabel, Form, FormControl, FormGroup, Row, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SORT_OPTIONS = [
    { id: "1", name: "Recently Added" },
    { id: "2", name: "A-Z" },
    { id: "3", name: "Z-A" }
];

const BulkOrderStockListFilter = ({ onFilterChange, onReset, onSearch, filter }) =>
    <Form onSubmit={onSearch}>
        <FormGroup>
            <Row>
                <Col md={3}>
                    <InputGroup>
                        <FormControl type="text" id="searchText" className="input-filter" name="searchText" value={filter.searchText} onChange={onFilterChange} placeholder="Search by Name, SKU, EAN or SUID" />
                        <InputGroup.Append>
                            <InputGroup.Text className="input-filter-icon" onClick={onSearch}>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Col md={0} className="mt-1 ml-3">
                    <FormLabel htmlFor="sortBy" className="float-right label-filter">Sort by</FormLabel>
                </Col>
                <Col md={3}>
                    <FormControl id="sortBy" name="sortBy" as="select" value={filter.sortBy} onChange={onFilterChange}>
                        <option key="" value="">Please Select...</option>
                        {SORT_OPTIONS.map((option) => <option key={option.id} value={option.id}>{option.name}</option>)}
                    </FormControl>
                </Col>
            </Row>
        </FormGroup>
        <FormGroup>
            <Button variant="primary" className="float-right" type="submit">Apply filter</Button>
            <Button variant="link" className="float-right" name="filterReset" onClick={onReset}>Clear filter</Button>
        </FormGroup>
    </Form>

export default BulkOrderStockListFilter;