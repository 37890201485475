import React from 'react';
import { Button } from 'react-bootstrap';
import { SuccessAlert } from '../../../../common/Alert';
import './BundleWizard.scss';

class ConfirmBundle extends React.Component {

    render() {
        const { bundleDetails, selectedItems, items, handleEditStep, success } = this.props;
        let selectedProducts = [];
        selectedItems.productBundleCompanyItems.map(pb => { 
            const item = items.find(i => i.companyItemID === pb.companyItemID && i.sku === pb.sku)
            selectedProducts.push(Object.assign(pb, item));
        });

        return (
            <React.Fragment>
                {success &&
                    <React.Fragment>
                        <h5 className="mt-2 mb-3">Bundle Created Successfully</h5>
                        <SuccessAlert successMessage={`You have successfully created ${bundleDetails.name}.`} />
                    </React.Fragment>
                }
                <h5>{bundleDetails.name}</h5>
                <div className="mt-3">
                    <h6>Bundle Details</h6>
                    <dl>
                        <dt className="mb-0">Bundle Name</dt>
                        <dd className="mb-1">{bundleDetails.name}</dd>
                        <dt className="mb-0">Bundle Description</dt>
                        <dd className="mb-1">{bundleDetails.description ? bundleDetails.description : "N/A"}</dd>
                        <dt className="mb-0">Bundle SKU</dt>
                        <dd className="mb-1">{bundleDetails.skuCode}</dd>
                        <dt className="mb-0">Bundle Price (£)</dt>
                        <dd className="mb-1">{bundleDetails.price ? bundleDetails.price : "N/A"}</dd>
                    </dl>
                </div>
                {!success && <Button variant="link" className="p-0" onClick={() => handleEditStep(0)}>Edit Bundle Details</Button>}
                <div className="mt-4">
                    <h6>Product Selection</h6>
                    {selectedProducts.map((item, i) =>
                         <dl key={i}>
                            <dt className="mb-0">Product {i + 1}</dt>
                            <dd className="mb-0">{item.name}</dd>
                            <dd className="mb-0">EAN: {item.ean ? item.ean : "N/A"}</dd>
                            <dd className="mb-0">SKU: {item.skuCode ? item.skuCode : "N/A"}</dd>
                            <dd className="mb-0">SUID: {item.selazarUniqueID ? item.selazarUniqueID : "N/A"}</dd>
                            <dd className="mb-1">Quantity: {item.quantity}</dd>
                        </dl>
                    )}
                </div>
                {!success && <Button variant="link" className="p-0" onClick={() => handleEditStep(1)}>Edit Product Selection</Button>}
            </React.Fragment>
        );
    }
}

export default ConfirmBundle;