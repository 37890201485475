import React from 'react';
import { Form, FormControl, FormLabel, FormGroup } from 'react-bootstrap';
import './BulkOrderOptions.scss';

class BulkOrderOptions extends React.Component {

    toggleShowHide = (showOptions, handleToggleShowHide) => {
        if(showOptions) this.setState({ showPackagingOptions: true});
        else {
            this.setState({ showPackagingOptions: false});
            this.setState(prevState => ({
                ...prevState,
                options: {
                    ...prevState.options,
                    ...{ packingPreference: 'paper' }
                }
            }));
        }

        handleToggleShowHide(showOptions);
    };

    render() {
        const { options, validation, handleInputChange, handleToggleShowHide } = this.props;
        const shippingRequired = options.shippingRequired  === 'true';
        const packagingRequired = options.packagingRequired  === 'true';

        return (
            <Form>
                <br />
                <fieldset>
                    <h5 className="mb-4">Bulk Order Options</h5>

                    {/* Shipping Required */}
                    <FormGroup>
                        <FormLabel htmlFor="shippingRequired">Does this order require shipping?</FormLabel>
                        <FormGroup className="custom-control custom-radio mb-0">
                            <FormControl className="custom-control-input" type='radio' id='shippingRequired-yes' name='shippingRequired' value={'true'}
                                checked={options.shippingRequired === 'true'} onChange={handleInputChange} />
                            <FormLabel className="custom-control-label" htmlFor="shippingRequired-yes">Yes, shipping is needed</FormLabel>
                        </FormGroup>
                        <FormGroup className="custom-control custom-radio mb-0">
                            <FormControl className="custom-control-input" type='radio' id='shippingRequired-no' name='shippingRequired' value={'false'}
                                checked={options.shippingRequired === 'false'} onChange={handleInputChange} />
                            <FormLabel className="custom-control-label" htmlFor="shippingRequired-no">No, shipping is not needed</FormLabel>
                        </FormGroup>
                        <span className="text-danger">{validation.shippingRequired.message}</span>
                    </FormGroup>

                    {/* Packaging Required */}
                    { !shippingRequired ?
                        <FormGroup>
                        <FormLabel className="mb-0" htmlFor="packagingRequired">Does this self-fulfilled bulk order require box packaging?</FormLabel>
                        <div className="form-input-description">
                            <p>Self-fulfilled bulk orders will be palletised. You can choose whether you would like your items to be packaged in boxes first, or if you would like them to be loose within the shrink-wrapped pallet.</p>
                        </div>
                        <FormGroup className="custom-control custom-radio mb-0">
                            <FormControl className="custom-control-input" type='radio' id='packagingRequired-yes' name='packagingRequired' value={'true'}
                                checked={options.packagingRequired === 'true'} onChange={handleInputChange} onClick={() => this.toggleShowHide(true, handleToggleShowHide)}/>
                            <FormLabel className="custom-control-label" htmlFor="packagingRequired-yes">Yes, package my items in boxes</FormLabel>
                        </FormGroup>
                        <FormGroup className="custom-control custom-radio mb-0">
                            <FormControl className="custom-control-input" type='radio' id='packagingRequired-no' name='packagingRequired' value={'false'}
                                checked={options.packagingRequired === 'false'} onChange={handleInputChange} onClick={() => this.toggleShowHide(false, handleToggleShowHide)}/>
                            <FormLabel className="custom-control-label" htmlFor="packagingRequired-no">No, do not package my items in boxes</FormLabel>
                        </FormGroup>
                        <span className="text-danger">{validation.packagingRequired.message}</span>
                    </FormGroup> : null }

                    {/* Box Split */}
                    { packagingRequired ? 
                    <FormGroup>
                        <FormLabel className="mb-0" htmlFor="ownBoxSplit">Do you want to specify your own box split?</FormLabel>
                        <div className="form-input-description">
                            <p>You can specify how you would like boxes to be split before they are posted, you will confirm this at a later stage in the process</p>
                        </div>
                        <FormGroup className="custom-control custom-radio mb-0">
                            <FormControl className="custom-control-input" type='radio' id='ownBoxSplit-yes' name='ownBoxSplit' value={'true'}
                                checked={options.ownBoxSplit === 'true'} onChange={handleInputChange} />
                            <FormLabel className="custom-control-label" htmlFor="ownBoxSplit-yes">Yes</FormLabel>
                        </FormGroup>
                        <FormGroup className="custom-control custom-radio mb-0">
                            <FormControl className="custom-control-input" type='radio' id='ownBoxSplit-no' name='ownBoxSplit' value={'false'}
                                checked={options.ownBoxSplit === 'false'} onChange={handleInputChange} />
                            <FormLabel className="custom-control-label" htmlFor="ownBoxSplit-no">No</FormLabel>
                        </FormGroup>
                        <span className="text-danger">{validation.ownBoxSplit.message}</span>
                    </FormGroup> : null }

                    {/*  Separate SKUs */}
                    { packagingRequired ? 
                    <FormGroup>
                        <FormLabel className="mb-0" htmlFor="separateSKUs">Do the bulk order boxes require separate SKUs?</FormLabel>
                        <div className="form-input-description">
                            <p>Please note you cannot have separate SKUs if you specify your own box split</p>
                        </div>
                        <FormGroup className="custom-control custom-radio mb-0">
                            <FormControl className="custom-control-input" type='radio' id='separateSKUs-yes' name='separateSKUs' value={'true'}
                                checked={options.separateSKUs === 'true'} onChange={handleInputChange} />
                            <FormLabel className="custom-control-label" htmlFor="separateSKUs-yes">Yes</FormLabel>
                        </FormGroup>
                        <FormGroup className="custom-control custom-radio mb-0">
                            <FormControl className="custom-control-input" type='radio' id='separateSKUs-no' name='separateSKUs' value={'false'}
                                checked={options.separateSKUs === 'false'} onChange={handleInputChange} />
                            <FormLabel className="custom-control-label" htmlFor="separateSKUs-no">No</FormLabel>
                        </FormGroup>
                        <span className="text-danger">{validation.separateSKUs.message}</span>
                    </FormGroup> : null }

                    {/* Fragile Items */}
                    <FormGroup>
                        <FormLabel htmlFor="fragileItems">Are there fragile items in this bulk order?</FormLabel>
                        <FormGroup className="custom-control custom-radio mb-0">
                            <FormControl className="custom-control-input" type='radio' id='fragileItems-yes' name='fragileItems' value={'true'}
                                checked={options.fragileItems === 'true'} onChange={handleInputChange} />
                            <FormLabel className="custom-control-label" htmlFor="fragileItems-yes">Yes, it contains fragile items</FormLabel>
                        </FormGroup>
                        <FormGroup className="custom-control custom-radio mb-0">
                            <FormControl className="custom-control-input" type='radio' id='fragileItems-no' name='fragileItems' value={'false'}
                                checked={options.fragileItems === 'false'} onChange={handleInputChange} />
                            <FormLabel className="custom-control-label" htmlFor="fragileItems-no">No, there are no fragile items</FormLabel>
                        </FormGroup>
                        <span className="text-danger">{validation.fragileItems.message}</span>
                    </FormGroup>

                    {/* Packing Preference */}
                    { packagingRequired ?
                    <FormGroup>
                        <FormLabel className='packing-label' htmlFor="packingPreference">Packing</FormLabel>    
                            <div className="form-input-description">
                                <p>Your bulk order will be packaged in paper</p>
                            </div>
                    </FormGroup> : null }
                    
                </fieldset>
            </Form>
        );
    }
}

export default BulkOrderOptions;