import React, { Fragment } from 'react';
import { Row, Col, FormControl, FormGroup, FormLabel, Button, Card, Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import NoResults from '../../../../common/Tables/NoResults';
import Confirm from '../../../../common/Confirm';

const IMAGE_TITLE = 'Confirm Image Upload';
const IMAGE_CONFIRM = 'Upload Image';
const MODAL_CANCEL = 'Cancel';

const Actions = ({ selection, handleInputChange }) =>
    <Fragment>
        <FormGroup className="m-3">
            <FormGroup className="custom-control custom-radio mb-2">
                <FormControl className="custom-control-input" type="radio" id="radio-nested-1" name="radio-nested" value="ApplyToAllProducts" checked={selection == 'ApplyToAllProducts'} onChange={() => handleInputChange("selection", "ApplyToAllProducts")} />
                <FormLabel className="custom-control-label" htmlFor="radio-nested-1">I would like to apply these changes to ALL products.</FormLabel>
                <p><small>All existing products will be updated to use the chosen return settings value you have selcted, as well as any new products you add going forward.</small></p>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-2">
                <FormControl className="custom-control-input" type="radio" id="radio-nested-2" name="radio-nested" value="ApplyToOnlyNewProducts" checked={selection == 'ApplyToOnlyNewProducts'} onChange={() => handleInputChange("selection", "ApplyToOnlyNewProducts")} />
                <FormLabel className="custom-control-label" htmlFor="radio-nested-2">I would like to apply these changes ONLY to new products going forward.</FormLabel>
                <p><small>There will be no impact to existing product return settings. Only products added after the update will take on the new return settings values you have set.</small></p>
            </FormGroup>
        </FormGroup>
    </Fragment>

const ActionDescription = ({ action }) =>
    action === "Restock" ? <span>Your product will be placed back on the shelves to be sold again</span>
        : action === "Quarantine" ? <span>Your products will be held in quarantine until you tell us how to action them</span>
            : <span>Your products will be discarded</span>

const Grades = ["A", "B", "C"];

const GradingActions = ({ grades, currentGrading, gradingOptions, readOnly, handleInputChange, disabled }) =>
    <Fragment>
        <div className={disabled && "disabled-container"}>
            {grades.map(g =>
                <div className="mb-3" key={g}>
                    <p><strong>Grade {g}</strong></p>
                    <Row>
                        <Col sm={6} md={4}>
                            <GradeDropdown grade={g} currentGrading={currentGrading} gradingOptions={gradingOptions} readOnly={readOnly} handleChange={handleInputChange} disabled={disabled} />
                        </Col>
                        <Col sm={6} md={8}>
                            <ActionDescription action={currentGrading?.find(f => f.grade == g).action} />
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    </Fragment>

const GradingActionsView = ({ grades, currentGrading, gradingOptions, readOnly, handleInputChange, disabled }) =>
    <Fragment>
        <div className={disabled && "disabled-container"}>
            {grades.map(g =>
                <div className="mb-3" key={g}>
                    <p><strong>Grade {g}</strong></p>
                    <Row>
                        <Col sm={6} md={4}>
                            <span>{currentGrading?.find(f => f.grade == g).action}</span>
                        </Col>
                        <Col sm={6} md={8}>
                            <ActionDescription action={currentGrading?.find(f => f.grade == g).action} />
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    </Fragment>

const GradeDescription = ({ edit, handleClick, disabled }) =>
    <Fragment>
        <div className={disabled && "disabled-container"}>
            <h5 className="mb-3">Grading Actions {edit && <span className="ml=1"><Button disabled={disabled} variant="link" className="p-1 ml-2" onClick={() => handleClick()}>Edit</Button><FontAwesomeIcon size="sm" icon={faEdit} /></span>}</h5>
            <div className="form-input-description">
                <p>When customers return products we will take action based on the actions you have set.</p>
            </div>
        </div>
    </Fragment>

const GradeDropdown = ({ grade, currentGrading, gradingOptions, readOnly, handleChange, disabled }) =>
    <FormControl disabled={disabled} id={grade} name="grade" as="select" readOnly={readOnly} value={currentGrading?.find(f => f.grade == grade).action} onChange={handleChange} required>
        {gradingOptions.map(o => <option key={o.id} value={o.grade}>{o.grade}</option>)}
    </FormControl>

const GradeInformation = ({ information }) =>
    <Card>
        <Card.Body>
            <h5 className="mb-3">Grading Options</h5>
            <p>These are the options that we use in the Selazar warehouse to grade your returns.</p>
            {information.sort((a, b) => a.grade.localeCompare(b.grade)).map(i =>
                <div className="mb-3" key={i}>
                    <strong>{i.grade} - {i.description}</strong>
                    <p>{i.meaning}</p>
                </div>
            )}
        </Card.Body>
    </Card>


const ProcessingModal = ({ showModal, title }) =>
    <Modal show={showModal} centered={true} backdropClassName="overlay" >
        <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormGroup>
                <p>We are applying your changes in the background. Please do not close this page.</p>
            </FormGroup>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
    </Modal>

const ToggleReturnsActivation = ({ retailerReturnConfiguration, handleActivationButtonClick }) =>
<Fragment>
    <div className="mb-3">
        <Button variant="primary" onClick={async() => await handleActivationButtonClick()}>{retailerReturnConfiguration && retailerReturnConfiguration.enabled ? "Deactivate Returns" : "Activate Returns"}</Button>
    </div>
</Fragment>

const ProductRow = ({ product, handleSelectImage }) =>
    <tr>
        <td>{product.name}</td>
        <td>{product.skuCode || "N/A"}</td>
        <td>{product.imageName || "No File Selected"}</td>
        <td className="text-center">
            <Button variant="secondary" size="sm" onClick={handleSelectImage}>Select Image</Button>
        </td>
    </tr>

const ProductsTable = ({products, handleSelectImage }) => 
    <Table striped bordered size="sm" className={!products.length ? "table-empty" : ""}>
        <thead>
            <tr>
                <th>Product</th>
                <th>SKU</th>
                <th>Image</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {products.length
                ? products.map(product => <ProductRow key={`${product.companyItemID}-${product.skuCode}`} product={product} handleSelectImage={() => handleSelectImage(product)} />)
                : <NoResults colSpan="6" text="No Products available." /> 
            }
        </tbody>
    </Table>

const getImageModalText = (file, product) => `Confirm upload of image '${file && file.name}' for product '${product && product.name}${product && product.skuCode ? ` (SKU: ${product.skuCode})` : ""}'.`;

const ConfirmImage = ({show, file, product, handleClose, handleConfirm}) =>
    <Confirm title={IMAGE_TITLE} text={getImageModalText(file, product)} buttonText={IMAGE_CONFIRM} 
        handleConfirmClose={handleClose} handleConfirmAction={handleConfirm} 
        show={show} cancelText={MODAL_CANCEL} size="lg" />

export {
    Actions,
    ActionDescription,
    Grades,
    GradingActions,
    GradeDescription,
    GradeDropdown,
    GradeInformation,
    ProcessingModal,
    ToggleReturnsActivation,
    ProductRow,
    ProductsTable,
    ConfirmImage,
    GradingActionsView
}