import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Footer from './Footer';

const NotFoundLogin = () =>
    <Container className="h-100">
        <Row className="justify-content-center row-form-adjustedheight">
            <Col md={6} className="align-self-center text-center">
                <h2>Page Not Found</h2>
                <p className="my-4">Page you are looking for is not found.</p>
                <Link to="/">
                    <Button>Return to Login</Button>
                </Link>
            </Col>
        </Row>
        <Footer />
    </Container>

export default NotFoundLogin;