import { useEffect, useState } from 'react'
import { fetchCreateStockBoxLibraryItems } from '../services/fetchCreateStockBoxLibraryItems';
import { fetchDeleteStockBoxLibraryItems } from '../services/fetchDeleteStockBoxLibraryItems';
import { fetchGetStockBoxLibraryItems } from '../services/fetchGetStockBoxLibraryItems';
import { fetchUpdateStockboxLibraryItem } from '../services/fetchUpdateStockboxLibraryItem';
import { SAVE_CREATE_STOCKBOX_GENERIC_ERROR_MESSAGE } from '../shared/StringConstants';

export const useFetchStockbox = () => {

  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [items, setItems] = useState([]);
  const [totalStockBoxes, setTotalStockBoxes] = useState(0);
  const [hasReachedStockBoxLimit, setHasReachedStockBoxLimit] = useState(false);
  const [errorState, setErrorState] = useState({ message: '', show: false });
  const [successState, setSuccessState] = useState({ message: '', show: false });
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setSuccessState({ message: '', show: false });
    setErrorState({ message: '', show: false });
    getStockBoxLibraryItems(signal);
    return () => controller.abort();
  }, [pageIndex]);

  const getStockBoxLibraryItems = async (signal) => {
    try {
      setLoading(true);
      const { data, data: { items, total } } = await fetchGetStockBoxLibraryItems(pageIndex, signal);
      setItems(items || []);
      setPageCount(data.pageCount || 0);
      setTotalStockBoxes(total);
      setLoading(false);
    } catch (error) {
      if (error.name !== "AbortError") setLoading(false);
      console.log(error);
    }
  }

  const createStockBoxLibraryItem = async (stockboxState) => {
    try {
      setLoading(true);
      const data = await fetchCreateStockBoxLibraryItems({ ...stockboxState });
      setLoading(false);
      return data;
    } catch (error) {
      setSuccessState({ message: '', show: false }); 
      setErrorState({ message: SAVE_CREATE_STOCKBOX_GENERIC_ERROR_MESSAGE, show: true }); 
      setLoading(false);
      console.log(error);
    }
  } 

  const updateStockBoxLibraryItem = async (stockboxState) => {
    try {
      setLoading(true);
      const data = await fetchUpdateStockboxLibraryItem({ ...stockboxState });
      setLoading(false);
      return data;
    } catch (error) {
      setSuccessState({ message: '', show: false }); 
      setErrorState({ message: SAVE_CREATE_STOCKBOX_GENERIC_ERROR_MESSAGE, show: true }); 
      setLoading(false);
      console.log(error);
    }
  } 

  const deleteStockBoxLibraryItems = async (stockboxState) => {
    try {
      setLoading(true);
      const data = await fetchDeleteStockBoxLibraryItems({ ...stockboxState });
      setLoading(false);
      return data;
    } catch (error) {
      setSuccessState({ message: '', show: false }); 
      setErrorState({ message: SAVE_CREATE_STOCKBOX_GENERIC_ERROR_MESSAGE, show: true }); 
      setLoading(false);
      console.log(error);
    }
  }

  const onNext = async () => {
    if (pageIndex < pageCount) {
      setPageIndex(pageIndex + 1);
      setLoading(true);
    }
  }

  const onPrevious = async () => {
    if (pageIndex > 1) {
      setPageIndex(pageIndex - 1);
      setLoading(true);
    }
  }

  const onStart = async () => {
    if (pageIndex > 1) {
      setPageIndex(1);
      setLoading(true);
    }
  }

  const onEnd = async () => {
    if (pageIndex < pageCount) {
      setPageIndex(pageCount);
      setLoading(true);
    }
  }


  return {
    createStockBoxLibraryItem,
    deleteStockBoxLibraryItems,
    updateStockBoxLibraryItem,
    loading,
    pageIndex,
    pageCount,
    items,
    errorState,
    successState,
    hasReachedStockBoxLimit,
    totalStockBoxes,
    setErrorState,
    setSuccessState,
    onNext,
    onPrevious,
    onEnd,
    onStart,
    getStockBoxLibraryItems,
    setHasReachedStockBoxLimit,
    setPageIndex
  }
}
