import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { GET, GET_ASYNC, extractData } from "../../../Consumer";
import LoadingBar from "../../common/LoadingBar";
import Endpoints from "../../common/Endpoints";
import StockListFilter from "./StockListFilter";
import StockItem from "../../common/StockItemCard";
import { ErrorAlert } from "../../common/Alert";
import Pagination from "../../common/Tables/Pagination";
import { format } from "date-fns";
import ProductHeader from "./ProductHeader";
import Confirm from "../../common/Confirm";

class StockList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalCount: 0,
      pageIndex: this.props.match.params.pageIndex || 1,
      pageCount: 1,
      items: [],
      currentPage: [],
      loading: true,
      warning: "",
      filter: {
        searchText: "",
        category: "",
      },
      companyName: "",
      exportError: false,
      message: "",
      showExportModal: false,
    };
  }

  async componentDidMount() {
    await Promise.all([this.fetchStockItems(), this.getCompanyName()]);
    this.setState({ loading: false });
  }

  onReset = async (e) => {
    e.preventDefault();

    this.setState(
      {
        filter: { searchText: "", category: "" },
        exportError: false,
        loading: true,
      },
      await this.fetchStockItems
    );
  };

  onShowExport = (e) => {
    this.setState({ showExportModal: true });
  };

  onCloseExport = (e) => {
    this.setState({ showExportModal: false });
  };

  onExport = async (e) => {
    e.preventDefault();
    this.setState(
      { loading: true, showExportModal: false },
      await this.exportStockItems
    );
  };

  onSearch = async (e) => {
    e.preventDefault();
    this.setState({ loading: true }, await this.fetchStockItems);
  };

  onNext = async () => {
    const { pageIndex, pageCount } = this.state;
    if (pageIndex < pageCount) {
      this.setState(
        { pageIndex: parseInt(pageIndex) + 1, loading: true },
        await this.fetchStockItems
      );
    }
  };

  onPrevious = async () => {
    const { pageIndex } = this.state;
    if (pageIndex > 1) {
      this.setState(
        { pageIndex: parseInt(pageIndex) - 1, loading: true },
        await this.fetchStockItems
      );
    }
  };

  onStart = () => {
    const { pageIndex } = this.state;
    if (pageIndex > 1) {
      this.setState({ pageIndex: 1, loading: true }, this.fetchStockItems);
    }
  };

  onEnd = () => {
    const { pageIndex, pageCount } = this.state;
    if (pageIndex < pageCount) {
      this.setState(
        { pageIndex: pageCount, loading: true },
        this.fetchStockItems
      );
    }
  };

  onFilterChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      ...prevState,
      pageIndex: 1,
      filter: {
        ...prevState.filter,
        ...{ [name]: value },
      },
    }));
  };

  getCompanyName = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    return await GET(Endpoints.COMPANY.GET.COMPANY_BY_ID + user.companyID)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ companyName: extractData(result).name });
      });
  };

  exportStockItems = async () => {
    const { pageIndex } = this.state;
    let filter = { ...this.state.filter };
    filter.pagingRequired = false;
    let url = new URL(Endpoints.STOCK.GET.EXPORT_CSV);

    Object.keys(filter).forEach((k) => url.searchParams.append(k, filter[k]));
    url.searchParams.append("pageIndex", pageIndex);

    return await GET(url)
      .then((response) => {
        if (response.ok) return response;
      })
      .then((result) => {
        if (result) {
          this.setState(
            { loading: false, exportError: false },
            this.fetchStockItems
          );
        } else
          this.setState(
            {
              loading: false,
              exportError: true,
              message: "Unable to process your export, please try again.",
            },
            this.fetchStockItems
          );
      })
      .catch(error => console.log(error));
  };

  fetchStockItems = async () => {
    const { pageIndex } = this.state;
    const filter = { ...this.state.filter };
    let url = new URL(Endpoints.STOCK.GET.BY);

    Object.keys(filter).forEach((k) => url.searchParams.append(k, filter[k]));
    url.searchParams.append("pageIndex", pageIndex);

    return await GET(url)
      .then((response) => {
        if (response.ok) return response.json();
      })
      .then((result) => {
        const data = extractData(result) || [];
        const warning = data.items === 0 ? "No stock found" : null;
        this.setState({
          items: data.items ? data.items : [],
          totalCount: data.total ? data.total : 0,
          pageCount: data.pageCount ? data.pageCount : 0,
          pageIndex: pageIndex > data.pageCount ? 1 : pageIndex,
          loading: false,
          warning: warning,
        });
      })
      .catch(error => console.log(error));
  };

  render() {
    const {
      loading,
      items,
      warning,
      pageIndex,
      pageCount,
      exportError,
      message,
      showExportModal,
    } = this.state;
    return loading ? (
      <LoadingBar />
    ) : (
      <Fragment>
        <ProductHeader activeKey="Products" />
        <Container fluid>
          <Row className="my-4">
            <Col>
              <StockListFilter
                onSearch={this.onSearch}
                onFilterChange={this.onFilterChange}
                onReset={this.onReset}
                onExport={this.onShowExport}
                exportError={exportError}
                message={message}
                {...this.state.filter}
              />
            </Col>
          </Row>
          {warning && (
            <Row>
              <Col sm={12} md={6} className="px-2">
                <ErrorAlert errorMessage={warning} />
              </Col>
            </Row>
          )}
          <Row>
            {items.length ? (
              items.map((item) => (
                <Col md={4} sm={12} key={item.companyItemID} className="px-2">
                  <Link
                    className="link-button"
                    to={`/supplier/products/edit/${item.companyItemID}/${pageIndex}`}
                  >
                    <StockItem
                      name={item.name}
                      category={item.category}
                      ean={item.ean}
                      sku={item.sku}
                      suid={item.selazarUniqueID}
                      totalStock={item.totalStock}
                      status={item.status}
                      showButton={false}
                    />
                  </Link>
                </Col>
              ))
            ) : (
              <p>
                <strong>No products to show.</strong>
              </p>
            )}
          </Row>
          <div className="card-pagination">
            <Pagination
              onNext={this.onNext}
              onPrevious={this.onPrevious}
              onStart={this.onStart}
              onEnd={this.onEnd}
              pageIndex={pageIndex}
              pageCount={pageCount}
            />
          </div>
          <Confirm
            title="Export Product List"
            text='Your product CSV will be sent to the email address associated with this account.
                            If you wish to receive the CSV at a different email address, please edit your email in "Users" and try again.'
            buttonText="Export"
            handleConfirmAction={this.onExport}
            handleConfirmClose={this.onCloseExport}
            show={showExportModal}
          />
        </Container>
      </Fragment>
    );
  }
}

export default StockList;
