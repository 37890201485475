import React, { useState } from 'react';
import { Modal, Button, FormGroup } from 'react-bootstrap';
import { EMPTY_BOX_DEPTH_ERROR, EMPTY_BOX_HEIGHT_ERROR, EMPTY_BOX_WIDTH_ERROR, EMPTY_BOX_NAME_ERROR, INVALID_BOX_DIMENSION_ERROR, DUPLICATED_BOX_NAME_MESSAGE, UPDATE_SUCCESS_MESSAGE, CONFIRM_DELETE_WARNING_MESSAGE, PFP_DIMENSIONS_MESSAGE, DELETE_SUCCESS_MESSAGE, SAVE_CREATE_STOCKBOX_GENERIC_ERROR_MESSAGE, SAVE_CREATE_STOCKBOX_SUCCESS_MESSAGE, INVALID_BOX_DIMENSION_DECIMAL_ERROR } from './shared/StringConstants';
import Confirm from '../../common/Confirm';
import LoadingBar from '../../common/LoadingBar';
import './StockBoxLibrary.scss';

export const StockboxDetails = ({
  stockboxState,
  show = true,
  isSubmited,
  setIsSubmited,
  handleInputChange,
  handleToggleStockboxDetailsModal,
  showDuplicatedNameModal,
  handleToggleDuplicatedNameModal,
  loading,
  isEditMode,
  updateStockBoxLibraryItem,
  createStockBoxLibraryItem,
  setSuccessState,
  setErrorState,
  getStockBoxLibraryItems,
  deleteStockBoxLibraryItems,
  showPfpDimensionsModal,
  handleTogglePfpDimensionsModal,
  closeAllModals,
  handleResetStockBoxState,
  items,
  setPageIndex
}) => {

  const { name, height, width, depth } = stockboxState;

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);  

  const handleSubmit = async () => {

    setIsSubmited(true);
    if (!isValidStockboxData()) return;

    if (isPfp()) {
      handleTogglePfpDimensionsModal();
      return;
    }

    await handleProcessSubmit();
  }

  const handleProcessSubmit = async () => {
    let data = null;
    const stockboxRequestBody = {...stockboxState, name: stockboxState.name.trim()};
    isEditMode ? data = await updateStockBoxLibraryItem(stockboxRequestBody) : data = await createStockBoxLibraryItem(stockboxRequestBody);
    const successMessage = isEditMode ? UPDATE_SUCCESS_MESSAGE : SAVE_CREATE_STOCKBOX_SUCCESS_MESSAGE;

    if(data.message === DUPLICATED_BOX_NAME_MESSAGE) {
      if(showPfpDimensionsModal) handleTogglePfpDimensionsModal();
      handleToggleDuplicatedNameModal();
    } else {
      setSuccessState({ message: successMessage, show: true });
      setErrorState({ show: false, message: '' });
      handleToggleStockboxDetailsModal();
      closeAllModals();
      await getStockBoxLibraryItems();
    }

  }
  
  const isValidStockboxData = () => {
    return !hasEmptyFields() && !hasInvalidData() && !hasDecimalNumbers();
  }
  
  const hasEmptyFields = () => {
    return name === '' || height === '' || width === '' || depth === '';
  }
  
  const hasInvalidData = () => {
    return height <= 0 || width <= 0 || depth <= 0;
  }

  const hasDecimalNumbers = () => {
    return height.includes('.') || width.includes('.') || depth.includes('.'); 
  }
  
  const isPfp = () => {
    return parseInt(height) * parseInt(width) * parseInt(depth) >= 20250;
  }

  const handleToggleDelete = () => {
    setShowConfirmDeleteModal(!showConfirmDeleteModal);
    handleToggleStockboxDetailsModal(false);
  }

  const handleConfirmDelete = async () => {
    const data = await deleteStockBoxLibraryItems({...stockboxState});
    if(data) {
      setSuccessState({ message: DELETE_SUCCESS_MESSAGE, show: true });
      if(items.length === 1) {
        setPageIndex(prevState =>  prevState !== 1 ? prevState - 1 :  prevState);
      } else getStockBoxLibraryItems();
    } else setErrorState({ message: SAVE_CREATE_STOCKBOX_GENERIC_ERROR_MESSAGE, show: true });

    handleResetStockBoxState();
  }

  return (
    <>
      {loading && <LoadingBar />}
      <Modal className={loading && "stockbox-modal-loading"} id="stockbox-modal-container" centered show={show} onHide={() => handleToggleStockboxDetailsModal(true)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditMode ? 'Edit Stock Box' : 'Add Stock Box'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="stockbox-modal-content-container">
          <FormGroup>
            <div className="stockbox-field-container mb-3">
              <label htmlFor="name">Box Name</label>
              <div className="stockbox-input-container">
                <input name="name" className="stockbox-input" onChange={handleInputChange} value={name} maxLength={50} type="text" id="box-name"></input>
                {isSubmited && name === "" && <span className="text-danger">{EMPTY_BOX_NAME_ERROR}</span>}
              </div>
            </div>

            <div className="stockbox-field-container mb-3">
              <label htmlFor="name">Height</label>
              <div className="stockbox-dimension-input-container mr-3">
                <input name="height" className="stockbox-input" onChange={handleInputChange} value={height} maxLength={50} type="text" id="box-height"></input>
                {isSubmited && height === "" && <span className="text-danger invalid-dimension-error">{EMPTY_BOX_HEIGHT_ERROR}</span>}
                {isSubmited && parseInt(height) <= 0 && <span className="text-danger invalid-dimension-error">{INVALID_BOX_DIMENSION_ERROR}</span>}
                {isSubmited && height.includes('.') && <span className="text-danger invalid-dimension-error">{INVALID_BOX_DIMENSION_DECIMAL_ERROR}</span>}
              </div>
              <span className="stockbox-measure-units">cm</span>
            </div>

            <div className="stockbox-field-container mb-3">
              <label htmlFor="name">Width</label>
              <div className="stockbox-dimension-input-container mr-3">
                <input name="width" className="stockbox-input" onChange={handleInputChange} value={width} maxLength={50} type="text" id="box-width"></input>
                {isSubmited && width === "" && <span className="text-danger invalid-dimension-error">{EMPTY_BOX_WIDTH_ERROR}</span>}
                {isSubmited && parseInt(width) <= 0 && <span className="text-danger invalid-dimension-error">{INVALID_BOX_DIMENSION_ERROR}</span>}
                {isSubmited && width.includes('.') && <span className="text-danger invalid-dimension-error">{INVALID_BOX_DIMENSION_DECIMAL_ERROR}</span>}
              </div>
              <span className="stockbox-measure-units">cm</span>
            </div>

            <div className="stockbox-field-container mb-3">
              <label htmlFor="name">Depth</label>
              <div className="stockbox-dimension-input-container mr-3">
                <input name="depth" className="stockbox-input" onChange={handleInputChange} value={depth} maxLength={50} type="text" id="box-depth"></input>
                {isSubmited && depth === "" && <span className="text-danger invalid-dimension-error">{EMPTY_BOX_DEPTH_ERROR}</span>}
                {isSubmited && parseInt(depth) <= 0 && <span className="text-danger invalid-dimension-error">{INVALID_BOX_DIMENSION_ERROR}</span>}
                {isSubmited && depth.includes('.') && <span className="text-danger invalid-dimension-error">{INVALID_BOX_DIMENSION_DECIMAL_ERROR}</span>}
              </div>
              <span className="stockbox-measure-units">cm</span>
            </div>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="pt-0 mb-2">
          {isEditMode && <Button variant="link" onClick={handleToggleDelete}>Delete</Button>}
          <Button variant="primary" onClick={handleSubmit}>Save</Button>
        </Modal.Footer>
      </Modal>
      <Confirm title="Delete Stock Box" text={CONFIRM_DELETE_WARNING_MESSAGE} show={showConfirmDeleteModal} showCloseButton={true} buttonText="Yes, delete" buttonVariant="primary" handleConfirmClose={handleToggleDelete} handleConfirmAction={handleConfirmDelete} />
      <Confirm title="Duplicated Name" text={DUPLICATED_BOX_NAME_MESSAGE} show={showDuplicatedNameModal} showCloseButton={false} buttonText="Ok" closeButton={false} buttonVariant="primary" handleConfirmClose={() => handleToggleDuplicatedNameModal()} handleConfirmAction={() => handleToggleDuplicatedNameModal(true)} />
      <Confirm title="PFP Dimensions" text={PFP_DIMENSIONS_MESSAGE} show={showPfpDimensionsModal} showCloseButton={true} closeButtonVariant="secondary" buttonText="Save" cancelText="Back" buttonVariant="primary" handleConfirmClose={handleTogglePfpDimensionsModal} handleConfirmAction={handleProcessSubmit} />
    </>
  )
}