import React, { Fragment } from "react";
import { Button, Card, Image, Row, Col } from "react-bootstrap";
import { SuccessAlert, ErrorAlert } from "../../../common/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { UNTRACKED_SERVICE_PREFIX, UNTRACKED_SERVICE } from "./StringConstants";
import Confirm from "../../../common/Confirm";

import "./OrderDetailsSnippets.scss";
import { toLocalTimeString } from '../../../../Utilities';
const CANCEL_ITEMS_TITLE = "Cancel all items in order";
const CANCEL_ITEMS_MESSAGE =
  "Are you sure you want to cancel all items? This action cancels the entire order and cannot be done.";
const CANCEL_ITEM_TITLE = "Cancel Item";
const CANCEL_ITEM_MESSAGE =
  "Are you sure you want to cancel this item? This action cancels fulfilment of this item for this order and cannot be undone.";

const renderStatus = (statusName) => {
  switch (statusName) {
    case "Awaiting Collection":
    case "Part Fulfilled":
    case "Collection Booked":
    case "Shipped":
      return <span className="text-status-green">{statusName}</span>;
    case "Received":
      return <span className="text-status-yellow">{statusName}</span>;
    case "Awaiting Fulfilment":
    case "Fulfilling Partial Order":
    case "Fulfilment In Progress":
    case "Picking In Progress":
    case "Packing In Progress":
    case "Picked":
    case "Packed":
      return <span className="text-status-blue">{statusName}</span>;
    case "Processing Failed":
    case "Cancelled":
      return <span className="text-status-red">{statusName}</span>;
    default:
      return <span className="text-status-grey">{statusName}</span>;
  }
};

const ItemDetails = ({ item }) => (
  <dl>
    <dt className="mb-0">{item.name}</dt>
    <dd className="mb-1">Quantity: {item.quantity}</dd>
    {item.ean && <dd className="mb-0">EAN: {item.ean}</dd>}
    {item.skuCode && <dd className="mb-0">SKU: {item.skuCode}</dd>}
    {item.selazarUniqueID && (
      <dd className="mb-0">SUID: {item.selazarUniqueID}</dd>
    )}
  </dl>
);

const OrderBreakdown = ({
  customerReference,
  internalReference,
  received,
  customerOrganisationPersonName,
  statusName = "",
  onHold = false,
}) => (
  <div className="section-item">
    <h6>Order Breakdown</h6>
    <p className="title">Order Number</p>
    <p>{customerReference}</p>
    {!onHold && (
      <Fragment>
        <p className="title">Internal Reference</p>
        <p>{internalReference}</p>
        <p className="title">Status</p>
        <p>{renderStatus(statusName)}</p>
      </Fragment>
    )}
    <p className="title">Order Received</p>
    <p>{toLocalTimeString(received)}</p>
    <p className="title">Customer Name</p>
    <p>{customerOrganisationPersonName}</p>
  </div>
);

const ShipmentDetails = ({ index, shipment }) => (
  <div className="section-item">
    <p className="title">Shipment {index + 1}</p>
    <p>
      {shipment.status === "Shipped - PPO" &&
        shipment.missingItems.length === 0 ? (
        <span className="text-status-green">Shipped</span>
      ) : shipment.status === "Shipped - PPO" &&
        shipment.missingItems.length > 0 ? (
        <span className="text-status-green">Part Fulfilled</span>
      ) : (
        renderStatus(shipment.status.replace(" - PPO", ""))
      )}
    </p>
    {shipment.items.length > 0 &&
      shipment.items.map((item) => (
        <div key={`item-breakdown-${item.id}`}>
          <dl>
            <dt className="mb-0">{item.name}</dt>
            <dd className="mb-1">Quantity: {item.quantity}</dd>
            {item.ean && <dd className="mb-0">EAN: {item.ean}</dd>}
            {item.skuCode && <dd className="mb-0">SKU: {item.skuCode}</dd>}
            {item.selazarUniqueID && (
              <dd className="mb-0">SUID: {item.selazarUniqueID}</dd>
            )}
          </dl>
        </div>
      ))}

    {shipment.missingItems.length > 0 && (
      <>
        <p className="title">Items Missing</p>
        {shipment.missingItems.map((item) => (
          <div key={`item-breakdown-${item.id}`}>
            <dl>
              <dt className="mb-0">{item.name}</dt>
              <dd className="mb-1">Quantity: {item.quantity}</dd>
              {item.ean && <dd className="mb-0">EAN: {item.ean}</dd>}
              {item.skuCode && <dd className="mb-0">SKU: {item.skuCode}</dd>}
              {item.selazarUniqueID && (
                <dd className="mb-0">SUID: {item.selazarUniqueID}</dd>
              )}
            </dl>
          </div>
        ))}
      </>
    )}
    <p className="title">Weight</p>
    <p>{shipment.weight}kg</p>
    <p className="title">Warehouse</p>
    <p>{shipment.warehouseName}</p>
    <p className="title">Parcel Reference/Tracking Number</p>
    {shipment.trackingUrl ? (
      shipment.parcelReference?.toUpperCase().includes(UNTRACKED_SERVICE_PREFIX) ? <p>{UNTRACKED_SERVICE}</p> :
        <Button
          className="mb-2"
          href={shipment.trackingUrl + shipment.parcelReference}
          target="_blank"
          rel="noopener noreferrer"
          variant="link"
        >
          {shipment.parcelReference}
        </Button>
    ) : (
      <p>{shipment.parcelReference ? shipment.parcelReference : "N/A"}</p>
    )}
    <p className="title">Courier Provider</p>
    <p>{shipment.providerName ? shipment.providerName : "N/A"}</p>
  </div>
);

const AwaitingShipmentDetails = ({
  cancelledPartialOrderItems,
  pendingPartialOrderItems,
  failedPartialOrderItems,
  onCancelClick,
}) => (
  <div className="section-item">
    {failedPartialOrderItems.length > 0 && (
      <Fragment>
        {failedPartialOrderItems.map((item) => (
          <ItemDetails key={`item-details-${item.id}`} item={item} />
        ))}
        <p>
          <span className="text-status-red">Failed</span>
        </p>
      </Fragment>
    )}
    {cancelledPartialOrderItems.length > 0 && (
      <Fragment>
        {cancelledPartialOrderItems.map((item) => (
          <ItemDetails key={`item-details-${item.id}`} item={item} />
        ))}
        <p>
          <span className="text-status-red">Cancelled</span>
        </p>
      </Fragment>
    )}
    {pendingPartialOrderItems.length > 0 && (
      <Fragment>
        {pendingPartialOrderItems.map((item) => (
          <ItemDetails key={`item-details-${item.id}`} item={item} />
        ))}
        <p>
          <span className="text-status-yellow">Out of Stock</span>
        </p>
        <Button variant="link" onClick={onCancelClick}>
          Cancel Items
        </Button>
      </Fragment>
    )}
  </div>
);

const CostDetails = ({ orderCostBreakdown }) => (
  <div className="section-item">
    <h6>Cost Details</h6>
    <p className="title">Grand Total</p>
    <p>£{orderCostBreakdown.total.toFixed(2)}</p>
    <p className="title">Breakdown</p>
    <p className="mb-0">
      Cross Zonal Charge: £{orderCostBreakdown.crossZonalCharge.toFixed(2)}
    </p>
    <p className="mb-0">
      Fuel Charge: £{orderCostBreakdown.fuelCharge.toFixed(2)}
    </p>
    <p className="mb-0">
      Item Picking Charge: £{orderCostBreakdown.pickingCharge.toFixed(2)}
    </p>
    <p className="mb-0">
      Packing Charge: £{orderCostBreakdown.packingCharge.toFixed(2)}
    </p>
    <p className="mb-0">
      Packaging Charge: £{orderCostBreakdown.packagingCharge.toFixed(2)}
    </p>
    <p className="mb-0">
      Service Charge: £
      {(
        orderCostBreakdown.serviceCharge + orderCostBreakdown.selazarMarkup
      ).toFixed(2)}
    </p>
    <p className="mb-0">Subtotal: £{orderCostBreakdown.subtotal.toFixed(2)}</p>
    <p className="mb-0">
      Selazar charge ({orderCostBreakdown.adminRate}%): £
      {orderCostBreakdown.adminCharge.toFixed(2)}
    </p>
    <p className="mb-0">VAT: £{orderCostBreakdown.vatCharge.toFixed(2)}</p>
  </div>
);

const DeliveryDetails = ({
  customerOrganisationDepartmentName,
  customerAddressLine1,
  customerAddressLine2,
  customerAddressTown,
  customerAddressCounty,
  customerAddressPostcode,
  parcelReferences,
  trackingUrls,
  providerName,
  onHold = false,
}) => (
  <div className="section-item">
    <h6>Delivery Details</h6>
    <p className="title">Delivery Address</p>
    {customerOrganisationDepartmentName && <p className="mb-0">{customerOrganisationDepartmentName}</p>}
    <p className="mb-0">{customerAddressLine1}</p>
    <p className="mb-0">{customerAddressLine2}</p>
    <p className="mb-0">{customerAddressTown}</p>
    <p className="mb-0">{customerAddressCounty}</p>
    <p>{customerAddressPostcode}</p>
  </div>
);

const ProductDetailsLegacyOrders = ({ products }) => (
  <div className="section-item">
    <h6>Product Details</h6>
    <p className="title">Total Products Ordered</p>
    <p className="mb-1">{products.totalProducts}</p>
    {products.items.length > 0
      ? products.items.map((item, i) => (
        <dl key={i}>
          <dt className="mb-0">{item.name}</dt>
          <dd className="mb-1">Quantity: {item.quantity}</dd>
          <dd className="mb-0">EAN: {item.ean ? item.ean : "N/A"}</dd>
          <dd className="mb-0">SKU: {item.sku ? item.sku : "N/A"}</dd>
          <dd className="mb-0">
            SUID: {item.selazarUniqueID ? item.selazarUniqueID : "N/A"}
          </dd>
        </dl>
      ))
      : null}
    {products.productBundles.length > 0
      ? products.productBundles.map((productBundle, i) => (
        <dl key={i}>
          <dt className="mb-0">{productBundle.name}</dt>
          <dd className="mb-1">
            Quantity Ordered: {productBundle.quantityOrdered}
          </dd>
          <dd className="mb-1">
            Total Items Included: {productBundle.totalOrderedProducts}
          </dd>
          <dd className="mb-0">
            SKU: {productBundle.skuCode ? productBundle.skuCode : "N/A"}
          </dd>
        </dl>
      ))
      : null}
  </div>
);

const ProductDetails = ({
  orderContents,
  showProductBundleDetails,
  totalProducts,
  awaitingStockSkuCodes = [],
}) => (
  <div className="section-item">
    <h6>Product Details</h6>
    <p className="title">Total Products Ordered</p>
    <p className="mb-1">{totalProducts(orderContents)}</p>
    {orderContents.orderedCompanyItems.length > 0
      ? orderContents.orderedCompanyItems.map((orderedCompanyItem, i) => (
        <dl key={i}>
          <dt className="mb-0">{orderedCompanyItem.companyItem.item.name}</dt>
          <dd className="mb-1">Quantity: {orderedCompanyItem.quantity}</dd>
          <dd className="mb-0">
            EAN:{" "}
            {orderedCompanyItem.companyItem.item.ean
              ? orderedCompanyItem.companyItem.item.ean
              : "N/A"}
          </dd>
          <dd className="mb-0">
            SKU:{" "}
            {orderedCompanyItem.skuCode ? orderedCompanyItem.skuCode : "N/A"}
          </dd>
          <dd className="mb-0">
            SUID:{" "}
            {orderedCompanyItem.companyItem.item.selazarUniqueID
              ? orderedCompanyItem.companyItem.item.selazarUniqueID
              : "N/A"}
          </dd>
          {awaitingStockSkuCodes.includes(orderedCompanyItem.skuCode) ? (
            <dd className="mb-0">
              <span className="text-status-yellow">Awaiting Stock</span>
            </dd>
          ) : null}
        </dl>
      ))
      : null}
    {orderContents.orderedProductBundles.length > 0
      ? orderContents.orderedProductBundles.map((orderedProductBundle, i) => (
        <dl key={i}>
          <dt className="mb-0">{orderedProductBundle.productBundle.name}</dt>
          <dd className="mb-1">
            Quantity Ordered: {orderedProductBundle.quantity}
          </dd>
          <dd className="mb-1">
            Total Items Included:{" "}
            {orderedProductBundle.productBundle.totalProducts *
              orderedProductBundle.quantity}
          </dd>
          <dd className="mb-0">
            SKU:{" "}
            {orderedProductBundle.productBundle.skuCode
              ? orderedProductBundle.productBundle.skuCode
              : "N/A"}
          </dd>
          <Button
            variant="link"
            className="p-0"
            onClick={() =>
              showProductBundleDetails(orderedProductBundle.productBundle)
            }
          >
            View Bundle Details
          </Button>
        </dl>
      ))
      : null}
  </div>
);

const ProductSummary = ({
  orderContents,
  showProductBundleDetails,
  totalProductsOrdered,
  totalCancelledProducts,
}) => (
  <div className="section-item">
    <h6>Product Details</h6>
    <p className="title">Total Products Ordered</p>
    <p>{totalProductsOrdered}</p>
    {totalCancelledProducts > 0 && (
      <Fragment>
        <p className="title">Total Products Cancelled</p>
        <p>{totalCancelledProducts}</p>
      </Fragment>
    )}
    {orderContents.orderedProductBundles.length > 0 && (
      <Fragment>
        <p className="title">Bundles</p>
        {orderContents.orderedProductBundles.map((orderedProductBundle, i) => (
          <Fragment key={`product-bundle-${i}`}>
            <p>
              {orderedProductBundle.productBundle.name} SKU:{" "}
              {orderedProductBundle.productBundle.skuCode
                ? orderedProductBundle.productBundle.skuCode
                : "N/A"}
            </p>
            <Button
              variant="link"
              className="p-0"
              onClick={() =>
                showProductBundleDetails(orderedProductBundle.productBundle)
              }
            >
              View Bundle Details
            </Button>
          </Fragment>
        ))}
      </Fragment>
    )}
  </div>
);

const CancellableItems = ({
  cancellableItems,
  onCancelItemsReturnClick,
  onCancelItemsAllClick,
  onCancelItemClick,
  showCancellableShowError,
  showCancelFailedError,
  showCancelSuccess,
}) => (
  <Fragment>
    <Button
      variant="link"
      className="button-nav-return p-0  mb-4"
      onClick={onCancelItemsReturnClick}
    >
      <FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Order
    </Button>
    <h2 className="mb-4">Cancel Selazar Fulfilment of Items</h2>
    {showCancellableShowError ? (
      <ErrorAlert errorMessage="Unable to retrieve items, please try again." />
    ) : (
      <Fragment>
        {showCancelSuccess && (
          <SuccessAlert successMessage="Successfully cancelled item(s)" />
        )}
        {showCancelFailedError && (
          <ErrorAlert errorMessage="Unable to cancel item(s), please try again." />
        )}
        {cancellableItems.length > 0 && (
          <Fragment>
            <Button
              variant="link"
              className="link-danger p-0 mb-4"
              onClick={onCancelItemsAllClick}
            >
              Cancel All Items
            </Button>
            {cancellableItems.map((item) => (
              <Row key={`cancel-item-${item.id}`}>
                <Col sm={12} md={10} className="mb-2">
                  <Card className="card-item">
                    {item.imageUrl && (
                      <div className="image-item-container">
                        <Image src={item.imageUrl} className="rounded" />
                      </div>
                    )}
                    <div className="details-item-container">
                      <p className="mb-0">{item.name}</p>
                      {item.skuCode && (
                        <p className="mb-0">SKU: {item.skuCode}</p>
                      )}
                      <p>Quantity: {item.quantity}</p>
                    </div>
                  </Card>
                </Col>
                <Col sm={12} md={2} className="mb-2">
                  <Button
                    variant="danger"
                    onClick={() => onCancelItemClick(item.id)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            ))}
          </Fragment>
        )}
      </Fragment>
    )}
  </Fragment>
);

const CancelItemsConfirm = ({
  showCancelItemsModal,
  handleCancelItemsClose,
  handleCancelItemsAction,
}) => (
  <Confirm
    title={CANCEL_ITEMS_TITLE}
    text={CANCEL_ITEMS_MESSAGE}
    buttonText="Yes, cancel all items"
    buttonVariant="danger"
    cancelText="Go back"
    handleConfirmClose={handleCancelItemsClose}
    handleConfirmAction={handleCancelItemsAction}
    show={showCancelItemsModal}
  />
);

const CancelItemConfirm = ({
  showCancelItemModal,
  handleCancelItemClose,
  handleCancelItemAction,
}) => (
  <Confirm
    title={CANCEL_ITEM_TITLE}
    text={CANCEL_ITEM_MESSAGE}
    buttonText="Yes, cancel item"
    buttonVariant="danger"
    cancelText="Go back"
    handleConfirmClose={handleCancelItemClose}
    handleConfirmAction={handleCancelItemAction}
    show={showCancelItemModal}
  />
);

export {
  OrderBreakdown,
  ShipmentDetails,
  CostDetails,
  AwaitingShipmentDetails,
  DeliveryDetails,
  ProductDetailsLegacyOrders,
  ProductDetails,
  ProductSummary,
  CancellableItems,
  CancelItemsConfirm,
  CancelItemConfirm,
};
