import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import './Breadcrumbs.scss';

class Breadcrumbs extends Component {

    handleStateUpdate = (state, link) => {
        this.props.history.push({ pathname: link, state: state });
    }

    render() {
        const { breadcrumbs = [] } = this.props

        return breadcrumbs.length ? (
            <Row className="crumbs mb-3">
                <Col md={12}>
                    {breadcrumbs.map((b, i) => <React.Fragment>
                        {b.link ? <React.Fragment key={`${i}_link`}>
                            {b.state ? <Button variant="link" className="crumb-link p-0" onClick={() => this.handleStateUpdate(b.state, b.link)}>{b.text}</Button> : <Link className="link" to={b.link}>{b.text}</Link>}&nbsp;<FontAwesomeIcon className="mx-1" icon={faAngleRight} />&nbsp;</React.Fragment> :
                            <span key={`${i}_active_link`}>{b.text}</span>}
                    </React.Fragment>)}
                </Col>
            </Row>
        ) : null;
    }
}

export default withRouter(Breadcrumbs);
