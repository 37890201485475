import React from 'react';
import './Integrations.scss';

const ShopifyShippingSummaryBox = ({ shippingRates, selectedShippingRates, getShippingRateClass }) => {
    return (
        <div className="shipping-summary">
            <h5 className="mb-3">Shopify Shipping Summary</h5>
            <div>
                <p>Total Shipping Options: {shippingRates.length}</p>
                <p>Unassigned Shipping Options: {shippingRates.length - selectedShippingRates.filter(s => s.shippingRateID != null ).length || 0}</p>
                <p className="title">Shopify Shipping Options</p>
                {shippingRates.map((v, i) => 
                <p key={i} className={getShippingRateClass(v.shippingRateID)}>{v.name}-{v.shippingZoneName}-£{v.price.toFixed(2)}</p>)}
            </div>
        </div>
    )
}

export default ShopifyShippingSummaryBox;