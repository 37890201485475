import { useState } from "react";

export const useStockBoxModal = () => {

  const initialStockBoxState = { name: '', height: '', width: '', depth: '' };
  const [showModal, setShowModal] = useState(false);
  const [stockBoxState, setStockBoxState] = useState(initialStockBoxState);
  const [isSubmited, setIsSubmited] = useState(false);
  const [showDuplicatedNameModal, setShowDuplicatedNameModal] = useState(false);
  const [showPfpDimensionsModal, setShowPfpDimensionsModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  
  const handleShowHideModal = () => {
    setShowModal(!showModal);
  }

  const handleInputChange = ({ target: { name, value }}) => {
    if (name !== 'name' && !isNumber(value)) {
      return;
    }

    if(value.trim().length === 0) value = '';

    setStockBoxState({ ...stockBoxState, [name]: value })
  }

  const isNumber = (value) => {
    return !isNaN(value);
  }

  const handleResetStockBoxState = () => {
    setStockBoxState(initialStockBoxState);
    setIsSubmited(false);
    setIsEditMode(false);
  }

  const handleToggleStockboxDetailsModal = (resetState = true) => {
    if(resetState) {
      handleResetStockBoxState();
    }

    setShowModal(!showModal);
  }

  const handleToggleDuplicatedNameModal = (showStockboxDetails = false) => {
    setShowDuplicatedNameModal(!showDuplicatedNameModal);
    setShowModal(showStockboxDetails);
  }

  const handleTogglePfpDimensionsModal = () => {
    setShowPfpDimensionsModal(!showPfpDimensionsModal);
    handleToggleStockboxDetailsModal(false);
  }

  const closeAllModals = () => {
    setShowPfpDimensionsModal(false);
    setShowDuplicatedNameModal(false);
    setShowModal(false);
    handleResetStockBoxState();
  }

  return {
    ...stockBoxState,
    showModal,
    handleShowHideModal,
    handleInputChange,
    handleToggleStockboxDetailsModal,
    isSubmited,
    setIsSubmited,
    showDuplicatedNameModal,
    handleToggleDuplicatedNameModal,
    stockBoxState,
    setStockBoxState,
    isEditMode,
    setIsEditMode,
    handleResetStockBoxState,
    showPfpDimensionsModal,
    handleTogglePfpDimensionsModal,
    closeAllModals
  }

}
