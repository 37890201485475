import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { GET, POST, extractData } from '../../../../Consumer';
import Endpoints from '../../../common/Endpoints';
import FormValidator from '../../../common/FormValidator';
import { SuccessAlert, ErrorAlert } from '../../../common/Alert';
import LoadingBar from '../../../common/LoadingBar';
import ApiKeyCopy from './shared/ApiKeyCopy';
import Confirm from '../../../common/Confirm';
import { ALPHANUMERIC_DASH_MIN_PATTERN } from '../../../common/constants';
import { SQSForm, SQSFormValidationRules, INVENTORY_READ_AND_WRITE_ACCESS_ID, ORDER_READ_AND_WRITE_ACCESS_ID } from "./shared/SQSForm"

const NO_ACCESS_TEXT = "No Access";
const CANCEL_MODAL_TEXT = "Are you sure you want to cancel this App? Changes will not be saved and this action cannot be undone.";

const FAQ = () =>
    <section>
        <p className="title">Set up Information</p>
        <p className="mb-3">Please use our FAQ page for help setting up the API key.</p>
        <Button variant="primary" className="px-4" onClick={() => window.open("https://support.selazar.com", "_blank")}>FAQ</Button>
    </section>

const AppDetails = ({ name, handleInputChange, validation }) =>
    <section>
        <p className="title my-4">App Details</p>
        <p className="title">Name</p>
        <p className="mb-3">Create a name for your app to help you keep track.</p>
        <div className="form-input-description">
            <p>Name must be unique per integration.</p>
        </div>
        <FormGroup>
            <FormControl id="nameField" type="text" name="name" value={name} minLength="3" maxLength="50" onChange={(e) => handleInputChange("name", e.target.value)} />
            <span className="text-danger">{validation.name.message}</span>
        </FormGroup>
    </section>

const Permissions = ({ inventorySQSSettingsValidation, permissions, onPermChange, handleInventoryInputChange, inventorySQSSettings, ordersSQSSettings, ordersSQSSettingsValidation, handleOrdersInputChange }) =>
    <section>
        <p className="title">Permissions</p>
        <p className="mb-3">You should only choose the permissions your private apps need to work. Don’t use API access in ways that violate the Selazar API License terms.</p>
        {permissions.length && permissions.map(permission => {
            const selectedPerm = permission.modulePermissions.find(p => p.access === true);
            const selectedValue = selectedPerm ? selectedPerm.id : "";
            const isInventorySQSEnabled = permission.name == "Stock" && selectedValue == INVENTORY_READ_AND_WRITE_ACCESS_ID;
            const isOrderSQSEnabled = permission.name == "Orders" && selectedValue == ORDER_READ_AND_WRITE_ACCESS_ID;

            return (
                <div className="mb-3" key={permission.name}>
                    <FormLabel htmlFor={permission.name}>{permission.name}</FormLabel>
                    <FormControl id={permission.name} name={permission.name} as="select" value={selectedValue} onChange={(e) => onPermChange(permission.name, e.target.value)}>
                        <option value="">{NO_ACCESS_TEXT}</option>
                        {permission.modulePermissions.length && permission.modulePermissions.map(modulePerm => <option key={modulePerm.id} value={modulePerm.id}>{modulePerm.friendlyName}</option>)}
                    </FormControl>
                    {isInventorySQSEnabled && < SQSForm validator={inventorySQSSettingsValidation} changeHandler={handleInventoryInputChange} sqsSettings={inventorySQSSettings} type="Inventory"/>}
                    {isOrderSQSEnabled && < SQSForm validator={ordersSQSSettingsValidation} changeHandler={handleOrdersInputChange} sqsSettings={ordersSQSSettings} type="Orders"/>}
                </div>
            )
        })}
    </section>

const Terms = ({ terms, handleInputChange, validation }) =>
    <section className="mt-4">
        <FormGroup className="custom-control custom-checkbox mb-1">
            <input className="custom-control-input" id="termsField" type="checkbox" name="terms" checked={terms} onChange={(e) => handleInputChange("terms", e.target.checked)} />
            <FormLabel className="custom-control-label" htmlFor="termsField">
                {"I confirm and agree to the privacy policy and terms & conditions."}
            </FormLabel>
        </FormGroup>
        <span className="text-danger">{validation.terms.message}</span>
    </section>

const Submission = ({ onSaveClick, onCancelClick }) =>
    <FormGroup className="mt-med float-right">
        <Button variant="link" className="button-nav-link mr-4 mt-1" onClick={onCancelClick}>Cancel</Button>
        <Button variant="primary" onClick={onSaveClick}>Save Changes</Button>
    </FormGroup>

const SuccessfulAdd = ({ name, apiKey, onCopyClick, copied }) =>
    <section className="mt-med">
        <p className="title">API Key</p>
        <p className="mb-3">Use this key for your integration.</p>
        <p className="mb-1">{name}</p>
        <ApiKeyCopy apiKey={apiKey} copied={copied} onCopyClick={onCopyClick} />
        <Link to='/retailer/integrations/apps'>
            <Button variant="secondary" className="float-right mt-med">Return to App List</Button>
        </Link>
    </section>
  
class AddApp extends Component {

    validator = new FormValidator([
        {
            field: 'name',
            method: 'matches',
            args: [ALPHANUMERIC_DASH_MIN_PATTERN],
            validWhen: true,
            message: 'Please enter your app name and ensure it only contains letters, numbers, dashes and is at least 3 characters'
        },
        {
            field: 'terms',
            method: 'isBoolean',
            validWhen: true,
            message: 'Please confirm that you have read and agree to the Terms and Conditions and Privacy Policy'
        }
    ]);

    inventorySQSSettingsValidator = new FormValidator(SQSFormValidationRules);

    ordersSQSSettingsValidator = new FormValidator(SQSFormValidationRules);

    state = {
        loading: true,
        app: {
            name: '',
            apiKey: '',
            permissions: [],
            inventorySQSSettings: {
                enabled: false,
                url: '',
                accessKey: '',
                privateKey: ''
            },
            ordersSQSSettings: {
                enabled: false,
                url: '',
                accessKey: '',
                privateKey: ''
            },
            terms: false
        },
        copied: false,
        validation: this.validator.valid(),
        inventorySQSSettingsValidation: this.inventorySQSSettingsValidator.valid(),
        ordersSQSSettingsValidation: this.ordersSQSSettingsValidator.valid(),
        loadError: false,
        saveError: false,
        showCancelModal: false
    }

    async componentDidMount() {
        await this.fetchModules();
        this.setState({ loading: false });
    }

    fetchModules = async () => {
        return GET(Endpoints.INTEGRATIONS.APP.GET.MODULES)
            .then(response => response.json())
            .then(data => {
                const modules = extractData(data);
                const loadError = !modules || module.length === 0;
                this.setState(prevState => ({ app: { ...prevState.app.inventorySQSSettings, ...prevState.app, permissions: modules ? modules : [] }, loadError: loadError }));
            })
            .catch(error => {
                this.setState({ loadError: true });
                console.log(error);
            });
    }

    handleInputChange = (name, value) => this.setState(prevState => ({ ...prevState.app.inventorySQSSettings, app: { ...prevState.app, [name]: value } }));

    handleInventoryInputChange = (name, value) => {
        this.setState(prevState => ({ 
            app: { 
                ...prevState.app,
                inventorySQSSettings: {
                    ...prevState.app.inventorySQSSettings,
                    [name]: value
                }
            }
        }));

        // Clear validation when SQS settings are made visible
        if (name === 'enabled' && value === true) {
            this.setState({ inventorySQSSettingsValidation: this.inventorySQSSettingsValidator.valid() });
        }
    }   

    handleOrdersInputChange = (name, value) => {
        this.setState(prevState => ({ 
            app: { 
                ...prevState.app,
                ordersSQSSettings: {
                    ...prevState.app.ordersSQSSettings,
                    [name]: value
                }
            }
        }));

        // Clear validation when SQS settings are made visible
        if (name === 'enabled' && value === true) {
            this.setState({ ordersSQSSettingsValidation: this.ordersSQSSettingsValidator.valid() });
        }
    } 

    handleCancelShowHide = () => this.setState((prevState) => ({ showCancelModal: !prevState.showCancelModal }));

    onPermChange = (permName, value) => {
        const { app } = this.state;
        const modulePerms = app.permissions.find(p => p.name === permName).modulePermissions;

        if (value === "") {
            app.permissions.find(p => p.name === permName).modulePermissions = modulePerms.map(mp => ({ ...mp, access: false }));
        } else {
            app.permissions.find(p => p.name === permName).modulePermissions = modulePerms.map(mp => {
                return mp.id.toString() === value ? ({ ...mp, access: true }) : ({ ...mp, access: false });
            });
        }

        this.setState({ app: app });
    }

    onCopyClick = () => {
        navigator.clipboard.writeText(this.state.app.apiKey);
        this.setState({ copied: true });
    }

    onSaveClick = () => {
        const { app } = this.state;
        this.setState({ loading: true });

        // Deal with name whitespace
        const trimmedName = app.name.trim();
        app.name = trimmedName;
        this.setState(prevState => ({ app: { ...prevState.app, name: trimmedName } }));

        const isValid = this.handleValidation();
        if (isValid) {
            POST(Endpoints.INTEGRATIONS.APP.POST.INTEGRATION, app)
                .then(response => response.json())
                .then(data => {
                    if (!data.error) {
                        const app = extractData(data);
                        const saveError = !app;
                        this.setState({ app: app, saveError: saveError, loading: false });
                    } else {
                        this.setState({ saveError: true, loading: false });
                    }
                })
                .catch(error => {
                    this.setState({ saveError: true, loading: false });
                    console.log(error);
                });
        }
        else this.setState({ loading: false });
    }

    handleValidation = () => {
        const { app } = this.state;

        const inventorySQSSettingsValidation = this.inventorySQSSettingsValidator.validate(app.inventorySQSSettings);
        if (app.inventorySQSSettings.enabled === undefined || app.inventorySQSSettings.enabled === false) inventorySQSSettingsValidation.isValid = true;

        const ordersSQSSettingsValidation = this.ordersSQSSettingsValidator.validate(app.ordersSQSSettings);
        if (app.ordersSQSSettings.enabled === undefined || app.ordersSQSSettings.enabled === false) ordersSQSSettingsValidation.isValid = true;

        const validation = this.validator.validate(app);
        this.setState({ validation: validation, inventorySQSSettingsValidation: inventorySQSSettingsValidation, ordersSQSSettingsValidation: ordersSQSSettingsValidation });

        return validation.isValid && inventorySQSSettingsValidation.isValid && ordersSQSSettingsValidation.isValid;
    }

    render() {
        const { loading, app, copied, validation, inventorySQSSettingsValidation, ordersSQSSettingsValidation, loadError, saveError, showCancelModal } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <Container fluid>
                        <h1 className="page-title mb-4">App Details</h1>
                        <Row>
                            <Col sm={12} md={6}>
                                {loadError
                                    ? <ErrorAlert errorMessage="No permissions available. Unable to create app integration" />
                                    : app.apiKey
                                        ? <Fragment>
                                            <SuccessAlert successMessage="Successfully created app integration." />
                                            <FAQ />
                                            <SuccessfulAdd name={app.name} apiKey={app.apiKey} onCopyClick={this.onCopyClick} copied={copied} />
                                        </Fragment>
                                        : <Fragment>
                                            {saveError && <ErrorAlert errorMessage="Unable to create app integration. Ensure app integration name is unique and try again." />}
                                            <FAQ />
                                            <AppDetails name={app.name} handleInputChange={this.handleInputChange} validation={validation} />
                                            <Permissions permissions={app.permissions} onPermChange={this.onPermChange} handleInventoryInputChange={this.handleInventoryInputChange}
                                                inventorySQSSettingsValidation={inventorySQSSettingsValidation} inventorySQSSettings={app.inventorySQSSettings} 
                                                handleOrdersInputChange={this.handleOrdersInputChange} ordersSQSSettings={app.ordersSQSSettings} ordersSQSSettingsValidation={ordersSQSSettingsValidation} />
                                            <Terms terms={app.terms} handleInputChange={this.handleInputChange} validation={validation} />
                                            <Submission onSaveClick={this.onSaveClick} onCancelClick={this.handleCancelShowHide} />
                                        </Fragment>
                                }
                            </Col>
                        </Row>
                        <Confirm
                            show={showCancelModal}
                            title="Cancel App"
                            text={CANCEL_MODAL_TEXT}
                            buttonText="Cancel App"
                            buttonVariant="danger"
                            cancelText="Continue Editing"
                            handleConfirmClose={this.handleCancelShowHide}
                            handleConfirmAction={() => this.props.history.push("/retailer/integrations/apps")}
                        />
                    </Container>
                </Fragment>
        )
    }
}

export default AddApp;
