import React, { Fragment } from "react"
import { Image, Button, Form, FormGroup, FormLabel } from 'react-bootstrap';
import { toLocalDateString } from "../../../Utilities";
import { ErrorAlert, SuccessAlert } from '../../common/Alert';

const EditShopifyAppIntegration = (props) => {

    const imageUrl = "Images/shopify-logo.png";
    const { integration, handleInputChange, isError, isSuccess } = props;
    integration.fulfillmentService = integration.metadata.fulfillmentService;

    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = true;

        integration.integrationType = "Shopify";

        if (!integration.metadata.shopName || integration.metadata.shopName === '') isValid = false;
        else integration.shopName = integration.metadata.shopName;

        if (!integration.metadata.storeUrl || integration.metadata.storeUrl === '') isValid = false;
        else integration.storeUrl = integration.metadata.storeUrl;

        if (!integration.metadata.username || integration.metadata.username === '') isValid = false;
        else integration.username = integration.metadata.username;

        if (isValid) props.handleEdit(integration);
    }

    return (
        <Fragment>
            {isError && <ErrorAlert errorMessage="We could not validate your credentials with your provider. Please check the details below and try again." />}
            {isSuccess && <SuccessAlert successMessage={integration.connected ? "You have successfully connected your Shopify store to Selazar." : "You have successfully saved your integration."} />}
            
            <div><Image src={imageUrl} className="mx-auto mb-2 integration-image" /> <strong>v2.0</strong></div>
            <p><strong>Store Name </strong> {props.integration.shopName} </p>
            <p><strong>Date of Integration </strong> {toLocalDateString(props.integration.dateOfIntegration)} </p>
            <div className="mb-4">
                <strong>Status   </strong>
                {props.integration.connected ? <span className="text-status-green">Connected</span>
                    : <span className="text-status-red">Not Connected</span>}
            </div>
            <Button className="mb-4" onClick={() => props.showEditShippingRates()}>Rate Matcher Configuration</Button>
            <Form onSubmit={handleSubmit}>
                <fieldset>
                    <p><strong>Do you want this integration to be connected now?</strong></p>
                    <div className="form-input-description">
                        <p>You can connect and disconnect your integration at any stage. Please note until this integration is connected it will not work.</p>
                    </div>
                    <FormGroup className="custom-control custom-radio mb-2">
                        <input id="connected" className="custom-control-input" name="connected" type="radio" checked={integration.connected === true} onChange={handleInputChange} value={true} />
                        <FormLabel className="custom-control-label" htmlFor="connected">Connected</FormLabel>
                    </FormGroup>
                    <FormGroup className="custom-control custom-radio mb-4">
                        <input id="disconnected" className="custom-control-input" name="connected" type="radio" checked={integration.connected === false} onChange={handleInputChange} value={false} />
                        <FormLabel className="custom-control-label" htmlFor="disconnected">Disconnected</FormLabel>
                    </FormGroup>

                    <FormGroup>
                        <p><strong>Do you want to allow for Shopify to use inventory levels from Selazar?</strong></p>
                        <div className="form-input-description">
                            <p>You can connect and disconnect your integration at any stage. Please note until this integration is connected it will not work.</p>
                        </div>
                        <FormGroup className="custom-control custom-radio mb-2">
                            <input id="inventoryManaged" className="custom-control-input" name="inventoryManagement" type="radio" checked={integration.inventoryManagement} onChange={handleInputChange} value={true} />
                            <FormLabel className="custom-control-label" htmlFor="inventoryManaged">Yes, allow Selazar inventory management</FormLabel>
                        </FormGroup>
                        <FormGroup className="custom-control custom-radio mb-4">
                            <input id="inventoryUnmanaged" className="custom-control-input" name="inventoryManagement" type="radio" checked={!integration.inventoryManagement} onChange={handleInputChange} value={false} />
                            <FormLabel className="custom-control-label" htmlFor="inventoryUnmanaged">No, do not allow Selazar inventory management</FormLabel>
                        </FormGroup>
                    </FormGroup>
                    <Button onClick={() => props.setStep('home')}>Back</Button>
                    <input className="pr-3 pl-3 pt-2 pb-2 btn btn-primary float-right" type="submit" value="Save" />
                    <Button variant="link" className="float-right p-2" onClick={() => props.setStep('home')}>Cancel</Button>
                </fieldset>
            </Form>
        </Fragment>
    )
}

export default EditShopifyAppIntegration;