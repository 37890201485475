import React, { Fragment, useState, useCallback, useEffect } from 'react';
import Endpoints from '../../../../common/Endpoints';
import LoadingBar from '../../../../common/LoadingBar';
import { FormGroup, FormLabel, FormControl, Row, Col, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GET_ASYNC, POST, extractData } from '../../../../../Consumer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ErrorAlert, SuccessAlert, WarningAlert } from '../../../../common/Alert';
import { Grades, GradeDescription, GradingActions, GradeInformation } from '../../../../retailer/preferences/returns/shared/Snippets';
import ProductHeader from '../../ProductHeader';

const Returnable = ({ returnable, handleInputChange }) =>
    <Fragment>
        <p>Please select an option below:</p>
        <FormGroup className="m-3">
            <FormGroup className="custom-control custom-radio mb-2">
                <FormControl className="custom-control-input" type="radio" id="radio-1" name="radio" value={returnable} checked={returnable} onChange={() => handleInputChange(!returnable)} />
                <FormLabel className="custom-control-label" htmlFor="radio-1">This product is returnable.</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-2">
                <FormControl className="custom-control-input" type="radio" id="radio-2" name="radio" value={!returnable} checked={!returnable} onChange={() => handleInputChange(!returnable)} />
                <FormLabel className="custom-control-label" htmlFor="radio-2">This product is non-returnable.</FormLabel>
            </FormGroup>
        </FormGroup>
    </Fragment>

const EditReturnPreferences = ({ match }) => {

    const user = JSON.parse(localStorage.getItem('user'));

    //#region State

    const [loading, setLoading] = useState(true);
    const [warning, setWarning] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [companyItemID] = useState((match && match.params) ? match.params.id : null);
    const [pageIndex] = useState(match.params.pageIndex || 1);

    const [companyItem, setCompanyItem] = useState({ companyID: user.companyID });
    const [gradingOptions, setGradingOptions] = useState(null);
    const [gradeInformation, setGradeInformation] = useState(null);
    const [currentGrading, setCurrentGrading] = useState(null);
    const [returnable, setReturnable] = useState(true);
    const [preferences, setPreferences] = useState();

    //#endregion

    //#region API

    const getCompanyItem = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.STOCK.GET.BY_ID + companyItemID);

            if (response?.ok) {
                const data = await response.json();
                setCompanyItem(extractData(data));
                setWarning(extractData(companyItem ? null : "No product available."));
            } else {
                setCompanyItem({});
                setWarning({});
            }
        } catch (error) {
            setCompanyItem(null);
            setWarning(null);
        }
    }

    const fetchGradingActionsOptions = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.RETURNS.GET.OPTIONS);

            if (response?.ok) {
                const data = await response.json();
                setGradingOptions(extractData(data));
            } else {
                setGradingOptions({});
            }
        } catch (error) {
            setGradingOptions({});
        }
    }

    const fetchGradingInformation = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.RETURNS.GET.INFORMATION);

            if (response?.ok) {
                const data = await response.json();
                setGradeInformation(extractData(data));
            } else {
                setGradeInformation({});
            }
        } catch (error) {
            setGradeInformation({});
        }
    }

    const getReturnInformation = async () => {
        try {

            const response = await GET_ASYNC(Endpoints.STOCK.GET.GET_RETURN_INFO_BY_ID + companyItemID);

            if (response?.ok) {
                const data = await response.json();
                const extractReturnData = extractData(data);
                setReturnable(extractReturnData.returnable);
                setCurrentGrading(extractReturnData.grading);
                setPreferences({ currentGrading: extractReturnData.grading, returnable: extractReturnData.returnable });
            } else {
                setReturnable({});
                setCurrentGrading({});
            }
        } catch (error) {
            setReturnable(null);
            setCurrentGrading(null);
        }
    }

    const savePreferences = async () => {
        setLoading(true);

        try {

            const data = { returnable: preferences.returnable, grading: preferences.currentGrading != null ? preferences.currentGrading : gradingOptions }
            const response = await POST(Endpoints.STOCK.POST.UPDATE_RETURN_INFO_BY_ID + companyItemID, data);

            if (response?.ok) {
                setLoading(false);
                setSuccess(true);
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const fetchData = useCallback(async () => {
        setLoading(true);
        await Promise.all([getCompanyItem(), fetchGradingActionsOptions(), fetchGradingInformation(), getReturnInformation()])
        setLoading(false);
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData]);

    //#endregion

    //#region Controls

    const handleInputChange = (returnable) => {
        setReturnable(returnable);
        setPreferences((prevState) => ({ ...prevState, returnable, }));
    }

    const handleGradingActions = (e) => {
        const { id, value } = e.target;

        if (value == '') {
            setPreferences((prevState) => ({ ...prevState, currentGrading, }));
            return
        }

        const targetIndex = currentGrading.findIndex(g => g.grade == id);
        if (targetIndex !== -1) {
            currentGrading[targetIndex].action = value;
            setPreferences((prevState) => ({ ...prevState, currentGrading, }));
        }
    }

    const handleNextStep = () => { return savePreferences(); }

    //#endregion

    //#region Render

    return (
        <Fragment>
            <ProductHeader activeKey="Products" headerClass="mb-0" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Link to={`/supplier/products/edit/${companyItem.id}/${pageIndex}`} className="link-button">
                            <Button variant="link" className="button-nav-return p-0 mb-3"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Product</Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    {loading ? <LoadingBar /> :
                        <Fragment>
                            <Col sm={12} md={12}>
                                <h5>Return Preference</h5>
                                <Row>
                                    <Col sm={6} md={6}>
                                        {error && <ErrorAlert errorMessage="" />}
                                        {warning && <WarningAlert errorMessage={warning} />}
                                        {success && <SuccessAlert successMessage="Settings successfully updated." />}</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Returnable returnable={returnable} handleInputChange={handleInputChange} />
                                        <GradeDescription />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        {currentGrading && gradingOptions && <GradingActions grades={Grades} currentGrading={currentGrading} gradingOptions={gradingOptions} handleInputChange={handleGradingActions} readOnly={!preferences.returnable} />}
                                    </Col>
                                    <Col sm={12} md={6}>
                                        {gradeInformation && <GradeInformation information={gradeInformation} />}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Button variant="primary" className="float-right" onClick={handleNextStep}>Save</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Fragment>}
                </Row>
            </Container>
        </Fragment>
    )

    //#endregion    

}

export default EditReturnPreferences
