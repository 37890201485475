import React from 'react';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Tooltip = ({ placement, text }) => {
  return (
    <>
      <OverlayTrigger
        placement={placement}
        delay={{ show: 150, hide: 650 }}
        overlay={text}
      >
        <FontAwesomeIcon icon={faQuestionCircle} className="mr-1 tooltip-container" />
      </OverlayTrigger>
    </>
  )
}