import React, { Component } from 'react';
import { FormGroup, FormLabel, FormControl, Row, Col, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GET, GET_ASYNC, PUT, extractData } from '../../../../../Consumer';
import { defaultGuid } from '../../../../../Utilities';
import Endpoints from '../../../../common/Endpoints';
import LoadingBar from '../../../../common/LoadingBar';
import FormValidator from '../../../../common/FormValidator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { SuccessAlert, ErrorAlert, WarningAlert } from '../../../../common/Alert';
import ProductHeader from '../../ProductHeader';

const ITEM_ERROR_MESSAGE = "There was a problem retrieving the item. Please try again.";

class EditProductOverview extends Component {

    constructor(props) {
        super(props);

        this.itemOverviewValidator = new FormValidator([
            {
                field: 'name',
                method: 'isEmpty',
                validWhen: false,
                message: 'Name is required'
            },
            {
                field: 'description',
                method: 'isEmpty',
                validWhen: false,
                message: 'Description is required'
            },
            {
                field: 'brand',
                method: 'isEmpty',
                validWhen: false,
                message: 'Brand is required'
            },
            {
                field: 'categoryID',
                method: 'isEmpty',
                validWhen: false,
                message: 'Category is required'
            }
        ]);

        const user = JSON.parse(localStorage.getItem('user'));

        this.state = {
            loading: true,
            error: null,
            warning: null,
            companyItemID: (this.props.match && this.props.match.params) ? this.props.match.params.id : null,
            pageIndex: this.props.match.params.pageIndex || 1,
            categories: [],
            companyItem: {
                id: defaultGuid,
                editable: false,
                active: false,
                companyID: user.companyID,
                itemID: defaultGuid,
                expectedWeeklySales: 0,
                isPrePacked: false,
                requiresBoxPackaging: false,
                requiresPaperPackaging: false,
                isFragile: false,
                stockKeepingUnits: [],
                item: {
                    id: defaultGuid,
                    name: '',
                    description: '',
                    brand: '',
                    height: 0.00,
                    width: 0.00,
                    depth: 0.00,
                    weight: 0.00,
                    categoryID: '',
                    ean: null,
                    selazarUniqueID: null,
                    jan: null,
                    upc: null,
                    isbn: null,
                    batch: false,
                    mpn: null,
                    countryCodeOfManufacture: '',
                    commodityCode: '',
                    itemImages: []
                },
                currentPrice:
                {
                    id: defaultGuid,
                    companyItemID: defaultGuid,
                    price: 0.00,
                    active: true
                },
                noBarcode: false,
                dropzoneEnabled: true
            },
            itemOverviewValidation: this.itemOverviewValidator.valid()
        };
    }

    async componentDidMount() {
        Promise.all([
            this.getCategories(),
            this.getCompanyItem()]);
        this.setState({ loading: false });
    }

    getCategories = () => {
        return GET(Endpoints.CATEGORY.GET.ALL)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const categories = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ categories: categories });
            })
            .catch(error => console.log(error));
    }

    getCompanyItem = () => {
        const { companyItemID } = this.state;
        return GET(Endpoints.STOCK.GET.BY_ID + companyItemID)
            .then(response => response.json())
            .then(data => {
                const companyItem = extractData(data);
                this.setState({ companyItem: companyItem, error: companyItem ? null : ITEM_ERROR_MESSAGE });
            })
            .catch(error => {                
                this.setState({ companyItem: null, error: ITEM_ERROR_MESSAGE });
                console.error(error);
            });
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;

        if (e.target.getAttribute('parent')) {
            const parent = e.target.getAttribute('parent');
            const parentValue = this.state.companyItem[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            companyItem: {
                ...prevState.companyItem,
                ...{ [name]: value }
            }
        }));
    };

    handleSubmit = async () => {
        this.setState({ loading: true });
        const { companyItem } = this.state;
        this.submitted = true;

        const validation = this.itemOverviewValidator.validate(companyItem.item);
        this.setState({ itemOverviewValidation: validation });

        if (validation.isValid) {
            return await PUT(Endpoints.STOCK.PUT.UPDATE, companyItem)
                .then(response => response.json()).then((result) => {
                    if (!result.error) {
                        this.setState({ loading: false, success: true, warning: null, error: null });
                    } else {
                        this.setState({ loading: false, success: false, warning: result.message, error: null });
                    }
                });
        } else {
            this.setState({ loading: false });
        }
    }

    render() {
        const { companyItem, categories, loading, warning, error, success, itemOverviewValidation, pageIndex } = this.state;

        return (
            <React.Fragment>
                <ProductHeader activeKey="Products" headerClass="mb-0" />
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Link to={`/supplier/products/edit/${companyItem ? companyItem.id : null}/${pageIndex}`} className="link-button">
                                <Button variant="link" className="button-nav-return p-0 mb-3"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Product</Button>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        {loading ? <LoadingBar /> :
                            <React.Fragment>
                                <Col sm={12} md={6}>
                                    <h5>Product Overview</h5>
                                    {success && <SuccessAlert successMessage="Product successfully updated." />}
                                    {warning && <WarningAlert warningTitle="Warning" warningMessage={warning} />}
                                    {error
                                        ? <div className="mt-3">
                                            <ErrorAlert errorMessage={error} />
                                        </div>
                                        : <React.Fragment>
                                            <FormGroup>
                                                <FormLabel htmlFor="nameField">Product Name</FormLabel>
                                                <FormControl id="nameField" type="text" parent="item" name="name" value={companyItem.item.name} maxLength={98} onChange={this.handleInputChange} />
                                                <span className="text-danger">{itemOverviewValidation.name.message}</span>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel htmlFor="descriptionField">Product Description</FormLabel>
                                                <FormControl id="descriptionField" type="text" as="textarea" rows="3" parent="item" name="description" value={companyItem.item.description} onChange={this.handleInputChange} />
                                                <span className="text-danger">{itemOverviewValidation.description.message}</span>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel htmlFor="brandField">Brand</FormLabel>
                                                <FormControl id="brandField" type="text" parent="item" name="brand" value={companyItem.item.brand} onChange={this.handleInputChange} />
                                                <span className="text-danger">{itemOverviewValidation.brand.message}</span>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel htmlFor="categoryField">Category</FormLabel>
                                                <FormControl id="categoryField" name="categoryID" parent="item" as="select" value={companyItem.item.categoryID} onChange={this.handleInputChange}>
                                                    <option key="" value="">Please Select...</option>;
                                                {categories.map(category => {
                                                        return <option key={category.id} value={category.id}>{category.name}</option>;
                                                    })}
                                                </FormControl>
                                                <span className="text-danger">{itemOverviewValidation.categoryID.message}</span>
                                            </FormGroup>
                                            <Button variant="primary" className="float-right" onClick={this.handleSubmit}>Save</Button>
                                        </React.Fragment>}
                                </Col>
                            </React.Fragment>}
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default EditProductOverview;