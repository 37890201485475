import React from 'react';
import { Button, Col, FormLabel, Form, FormControl, FormGroup, Row, InputGroup } from 'react-bootstrap';
import { GET, extractData } from  "../../../../Consumer";
import Endpoints from '../../../common/Endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ErrorAlert } from '../../../common/Alert';

class StockListFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = { categoryOptions: [] };
    }

    componentDidMount() {
        Promise.all([this.fetchCategories()]);
    }

    fetchCategories = () => {
        return GET(Endpoints.CATEGORY.GET.ALL)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const categories = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ categoryOptions: categories });
            })
            .catch(error => console.log(error));
    }

    render() {
        const { name, category, onFilterChange, onReset, onSearch, onExport, exportError, message } = this.props;
        const categoryOptions = this.state.categoryOptions || [];

        return (<Form onSubmit={onSearch}>
            <FormGroup>
                {exportError &&
                    <Row>
                        <Col>
                            <ErrorAlert errorMessage={message} />
                        </Col>
                    </Row>}
                <Row>
                    <Col md={4}>
                        <InputGroup>
                            <FormControl type="text" id="searchText" className="input-filter" name="searchText" value={name} onChange={onFilterChange} placeholder="Search by Name, Company name, Email, Phone or Location" />
                            <InputGroup.Append>
                                <InputGroup.Text className="input-filter-icon" onClick={onSearch}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>                                      
                </Row>
            </FormGroup>            
        </Form>);
    }
}

export default StockListFilter;