import React, { useState } from 'react';
import { toLocalTimeString } from '../../../Utilities';
import { Button, Table, Collapse, Card, Row, Col } from 'react-bootstrap';
import NoResults from '../../common/Tables/NoResults';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './PurchaseOrders.scss';
import { useHistory } from 'react-router-dom';
import ItemSelection from '../stock/StockUploadWizard/ItemSelection';

const renderOpenCloseStatus = (completed) => completed ? <span className="text-status-red" >Closed</span> : <span className="text-status-green">Open</span>;

const renderStatus = (status) => {
    switch (status.name) {
        case "Awaiting Stock":
            return <span className="text-status-grey">{status.name}</span>;
        case "Completed":
            return <span className="text-status-green">{status.name}</span>;
        default:
            return <span className="text-status-grey">Unknown</span>;
    }
}

const PurchaseOrdersRow = ({ item, onClose, onOpen }) => {
    const [open, setOpen] = useState(false);
    const history = useHistory();        

    const handleClick = (id) => {
        const items = item.lines.map(item => {
            const { companyItem } = item;
            const { item: product } = companyItem;
            const { ean, selazarUniqueID, name } = product;


            return {
                sku: item.sku,
                quantity: item.quantity,
                companyItemID: item.companyItemID,
                name,
                ean       : ean ? ean : selazarUniqueID,
                isActive  : false
            }
        });

        const purchaseOrder = {
            purchaseOrderReference: item.purchaseOrderReference,
            purchaseOrderID: item.id,
            statusID: item.statusID,
            items
        }

        history.push({ pathname: `/supplier/purchaseorders/editpurchaseorder/${id}`, state: purchaseOrder });
    }
    
    return (
        <>
            <tr>
                <td>{item.purchaseOrderReference}</td>
                <td>{renderStatus(item.status)}</td>
                <td>{toLocalTimeString(item.createdDate)}</td>
                <td>{renderOpenCloseStatus(item.status.name === "Completed")}</td>
                <td className="text-center py-2">
                    <Button
                        id={"lineCl"+item.id}
                        className="link-button" 
                        onClick={() => setOpen(!open)}
                        aria-controls={"lineCl"+item.id}
                        aria-expanded={open}
                        variant="secondary" size="sm"
                        style={{borderRadius: '5px'}}
                    >
                        Details
                        <FontAwesomeIcon style={{marginLeft: '5px'}} icon={ open ? faChevronUp : faChevronDown } />
                    </Button>
                </td>
            </tr>
            <tr></tr>
            <tr>
                <td colSpan={5} className="hiddenRow">
                    <Collapse in={open}>
                        <div id={"lineCl"+item.id}>
                            <Card className='cardDetail'>
                                <Table striped size="sm" className={!item.lines.length ? "table-empty" : ""}>
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th className='header-centered'>EAN/SUID</th>
                                            <th className='header-centered'>SKU</th>
                                            <th className='header-centered'>Quantity Expected</th>
                                            <th><FontAwesomeIcon style={{cursor: 'pointer'}} onClick= { () => handleClick(item.id) } icon={ faPen } /></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{maxHeight: '200px'}}>
                                        {item.lines.length
                                            ? item.lines.map(line => <tr key={line.id} className='innerDetail'>
                                                <td>{line.companyItem.item.name}</td>
                                                <td className='value-centered'>{!line.companyItem.item.ean ? line.companyItem.item.selazarUniqueID : line.companyItem.item.ean}</td>
                                                <td className='value-centered'>{line.sku}</td>
                                                <td className='value-centered'>{line.quantity}</td>
                                                <td></td>
                                            </tr>)
                                            : <NoResults colSpan="5" text="No details available." />
                                        }
                                    </tbody>
                                </Table>
                                <Row className='close-purchaseorder-row'>
                                    <Col md={{ span: 4 }}>
                                        {item.status.name === "Awaiting Stock" ? 
                                        <Button
                                            className="link-button float-right mr-3" 
                                            onClick={onClose}
                                            variant="danger" size="sm"
                                            style={{borderRadius: '5px'}}
                                        >
                                            Close Purchase Order
                                        </Button> : 
                                        <Button
                                            className="link-button float-right mr-3" 
                                            onClick={onOpen}
                                            variant="secondary" size="sm"
                                            style={{borderRadius: '5px'}}
                                        >
                                            Open Purchase Order
                                        </Button>}
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </Collapse>
                </td>
            </tr>
        </>
    );
};

export default PurchaseOrdersRow;