import React, { Fragment } from 'react';
import { COUNTRY_LIST } from '../../../common/constants';
import { ErrorAlert } from '../../../common/Alert';

const findCountryName = (code) => COUNTRY_LIST.find(c => c.code === code).name;

const createBoxBreakdown = (box, boxIdx) =>
    (box.items.length > 0)
        ? <div className="mb-3" key={`box-${boxIdx}`}>
            <p className="mb-1"><strong>Box {boxIdx + 1}</strong></p>
            {box.items.map((item, i) => item.quantity > 0
                ? <div key={`box_${boxIdx}_item_${item.companyItemID}_${i}`} className="mb-1">
                    {item.sku
                        ? <Fragment><p className="mb-1">{`${item.name}: ${item.quantity}`}</p><p className="small">{item.sku}</p></Fragment>
                        : <p>{`${item.name}: ${item.quantity}`}</p>
                    }
                </div>
                : null)
            }
        </div>
        : null;

const createBulkSummary = (boxes) => {
    const summary = [];
    for (const box of boxes) {
        for (const item of box.items) {
            const foundIndex = summary.findIndex(fi => fi.companyItemID === item.companyItemID);
            if (foundIndex === -1) {
                summary.push({ companyItemID: item.companyItemID, name: item.name, quantity: item.quantity });
            } else {
                const currentItem = summary[foundIndex];
                summary[foundIndex] = { companyItemID: currentItem.companyItemID, name: currentItem.name, quantity: currentItem.quantity + item.quantity }
            }
        }
    }
    return summary;
}

const Shipping = ({ shippingService, shippingRequired }) =>
    <section className="mt-3">
        <h6>Shipping</h6>
        {shippingRequired === "true"
            ? <Fragment>
                <p className="title">Shipping Service</p>
                <p>{`${shippingService.name}, ${shippingService.service}, £${shippingService.cost}`}</p>
            </Fragment>
            : <p>None needed, self arranged</p>
        }
    </section>

const SplitDetails = ({ boxes, packagingRequired }) =>
    <section className="mt-3">
        <h6>Split Details</h6>
        {packagingRequired === "true"
            ? boxes.map((box, i) => createBoxBreakdown(box, i))
            : <p>No box packaging selected</p>
        }
    </section>

const BulkOrderSummary = ({ boxes }) => {
    const summary = createBulkSummary(boxes);

    return (
        <section className="mt-3">
            <h6>Bulk Order Summary</h6>
            <p className="title">Bulk Order Products</p>
            {summary.length
                ? summary.map((item, i) => <p key={`summary-${item.companyItemID}-${i}`}>{`${item.name}: ${item.quantity}`}</p>)
                : null
            }
        </section>
    );
};

const BulkOrderOptions = ({ options }) =>
    <section className="mt-3">
        <h6>Bulk Order Options</h6>
        <p className="title">How would you like this bulk order to be packed into boxes?</p>
        <p className="mb-1">
            {options.ownBoxSplit === "true"
                ? "Custom box split, you select what products go in which box"
                : options.separateSKUs === "true" ? "SKU split" : "Default split"
            }
        </p>
        <p className="title">Does this order require shipping?</p>
        <p className="mb-1">{options.shippingRequired === "true" ? "Yes, shipping is needed" : "No, shipping is not needed"}</p>
        <p className="title">Does this order require packaging?</p>
        <p className="mb-1">{options.packagingRequired === "true" ? "Yes, packing is needed" : "No, packaging is not needed"}</p>
        <p className="title">Packing</p>
        <p className="mb-1">Paper packaging</p>
        <p className="title">Are there fragile items in this bulk pick?</p>
        <p className="mb-1">{options.fragileItems === "true" ? "Yes, there is fragile items" : "No, there is no fragile items"}</p>
    </section>

const RecipientDetails = ({ orderInformation, contactDetails, destinationAddress }) =>
    <section className="mt-3">
        <h6>Recipient Details</h6>
        <p className="title">Purchase Order Number</p>
        <p>{orderInformation.purchaseOrderNumber}</p>
        <p className="title">Name</p>
        <p>{contactDetails.name}</p>
        <p className="title">Email Address</p>
        <p>{contactDetails.emailAddress}</p>
        <p className="title">Contact Number</p>
        <p>{contactDetails.contactNumber}</p>
        <p className="title">Shipping Address</p>
        <p className="mb-1">{destinationAddress.addressLine1}</p>
        {destinationAddress.addressLine2 && <p className="mb-1">{destinationAddress.addressLine2}</p>}
        <p className="mb-1">{destinationAddress.town}</p>
        <p className="mb-1">{destinationAddress.county}</p>
        <p className="mb-1">{findCountryName(destinationAddress.country)}</p>
        <p>{destinationAddress.postCode}</p>
    </section>

const BulkOrderConfirmation = ({ orderInformation, contactDetails, destinationAddress, options, boxSplit, shippingService, showConfirmError }) =>
    <Fragment>
        <h5 className="mt-2 mb-3">Confirm Bulk Order</h5>
        {showConfirmError && <ErrorAlert errorMessage="There was a problem with submitting the bulk order. Please try again." />}
        <RecipientDetails orderInformation={orderInformation} contactDetails={contactDetails} destinationAddress={destinationAddress} />
        <BulkOrderOptions options={options} />
        <BulkOrderSummary boxes={boxSplit.all.boxes} />
        <SplitDetails boxes={boxSplit.all.boxes} packagingRequired={options.packagingRequired} />
        <Shipping shippingService={shippingService} shippingRequired={options.shippingRequired} />
    </Fragment>

export default BulkOrderConfirmation;