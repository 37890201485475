import React from 'react';
import { Button, Col, FormLabel, Form, FormControl, FormGroup, Row, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

class QuarantineFilter extends React.Component {
    
    render() {
        const { name, quarantineDateFrom, quarantineDateTo, sortBy, onClear, onFilterChange, onSearch } = this.props;
        return (<Form onSubmit={onSearch}>
            <FormGroup>
                <Row>
                    <Col md={3}>
                        <InputGroup>
                            <FormControl type="text" id="name" className="input-filter" name="name" value={name} onChange={onFilterChange} placeholder="Search by File Name" />
                            <InputGroup.Append>
                                <InputGroup.Text className="input-filter-icon" onClick={onSearch}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col md={4}>
                        <InputGroup>
                            <FormControl id="dateFromField" type="date" name="dateFrom" value={quarantineDateFrom} onChange={onFilterChange} />
                            <FormControl id="dateToField" type="date" name="dateTo" value={quarantineDateTo} onChange={onFilterChange} />
                        </InputGroup>
                    </Col>
                    <Col md={2} className="pr-0">
                        <FormLabel htmlFor="sortBy" className="float-right label-filter">Sort By</FormLabel>
                    </Col>
                    <Col md={3}>
                        <FormControl id="sortBy" name="sortBy" as="select" value={sortBy} onChange={onFilterChange}>
                            <option key="" value="">Please Select...</option>;
                            <option value="NAME">Filename/Item Name</option>
                            <option value="QUARANTINE_DATE">On Hold Date</option>
                            <option value="STATUS">Status</option>
                            <option value="QUARANTINE_OUTCOME">Outcome</option>
                        </FormControl>
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Button variant="primary" className="float-right" type="submit">Apply filter</Button>
                <Button variant="link" className="float-right" name="filterReset" onClick={onClear}>Clear filter</Button>
            </FormGroup>
        </Form>);
    }
}

export default QuarantineFilter;