import React from 'react';

const BulkOrderSummaryBox = ({ selectedItems }) => {
    const summaryDetailsClass = selectedItems.items.filter(i => i.quantity > 0).length > 2 ? "scrollable" : undefined;
    return (
        <div className="summary">
            <h5 className="mb-3">Bulk Order Summary</h5>
            <div className={summaryDetailsClass}>
                {Object.entries(selectedItems.items).map(([key, value]) => {
                    if (value.quantity !== 0) {
                        const identifier = (value.sku !== undefined && value.sku !== '' && value.sku !== null) ? value.companyItemID + "_" + value.sku : value.companyItemID;
                        const assignedClass = value.assigned === value.quantity ? "summary-positive" : value.assigned > value.quantity ? "summary-negative" : "";
                        const unassignedClass = (value.unassigned === 0 && value.assigned === value.quantity) ? "summary-positive" : value.unassigned < 0 ? "summary-negative" : "";
                        return (
                            <div key={identifier + "-summary"} className="item-info mb-3">
                                <div className="mb-1">
                                    <p><strong>{value.name}</strong></p>
                                    {value.sku && <p className="small">{value.sku}</p>}
                                </div>
                                <p>Total: {value.quantity}</p>
                                <p className={unassignedClass}>Unassigned: {value.unassigned || 0}</p>
                                <p className={assignedClass}>Assigned: {value.assigned || 0}</p>
                            </div>
                        )
                    }
                 
                })}
            </div>
        </div>
    )
}

export default BulkOrderSummaryBox;