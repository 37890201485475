import React, { Fragment } from 'react';
import { Card, ListGroup, Image } from 'react-bootstrap';
import { Doughnut, Bar } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './DashboardCards.scss'

const setStatusClass = (status) => status === "INCREASE" ? "card-status-increase" : status === "DECREASE" ? "card-status-decrease" : "card-status-nochange";
const setStatusText = (status) => status === "INCREASE" ? "Increased" : status === "DECREASE" ? "Decreased" : "No change";
const setStatusIcon = (status) => status === "INCREASE" ? faChevronUp : status === "DECREASE" ? faChevronDown : faChevronRight;

const DefaultCard = ({ title, count, loading, text, status, statusText }) =>
    <Card className="default-card">
        <Card.Header>{title}</Card.Header>
        <Card.Body className="pt-2">
            {loading
                ? <div className="skeleton-loading">
                    <Card.Text className="card-text-count count"></Card.Text>
                    <Card.Text className="text-75"></Card.Text>
                    <Card.Text className="text-90 mt-4"></Card.Text>
                </div>
                : <Fragment>
                    <Card.Text className="card-text-count">{count}</Card.Text>
                    <Card.Text>{text}</Card.Text>
                    {status && <Card.Text className={`${setStatusClass(status)} mt-3`}><FontAwesomeIcon icon={setStatusIcon(status)} className="mr-2" />{setStatusText(status)} {statusText}</Card.Text>}
                </Fragment>
            }
        </Card.Body>
    </Card>

const ListCard = ({ title, data = [], loading }) =>
    <Card className="list-card">
        <Card.Header>{title}</Card.Header>
        <ListGroup className="h-100">
            {loading
                ? <div className="skeleton-loading">
                    {[...Array(10)].map((_, i) => <ListGroup.Item key={`${i}-popular-loading`}>
                        <span className="list-number mr-3"></span><span className="text-75"></span>
                    </ListGroup.Item>)
                    }
                </div>
                : data.length > 0
                    ? data.map((item, i) => <ListGroup.Item key={item.pickAndPackCompanyItemID}><span className="mr-2">{i + 1}.</span> {item.name}</ListGroup.Item>)
                    : <div className="my-auto pb-5">
                        <Image src='Images/search-result.svg' className="mx-auto d-block" width="140px" />
                        <p className="text-noresults text-center">No data is available at the minute</p>
                    </div>
            }
        </ListGroup>
    </Card>

const OrderListCard = ({ title, orders = [], loading }) =>
    <Card className="order-list-card">
        <Card.Header>{title}</Card.Header>
        {loading
            ? <ListGroup className="skeleton-loading h-100">
                {[...Array(5)].map((_, i) => <ListGroup.Item key={`${i}-orders-loading`}>
                    <p className="text-90"></p>
                    <p className="text-90"></p>
                    <p className="text-90"></p>
                    <span className="text-50"></span>
                </ListGroup.Item>)
                }
            </ListGroup>
            : <ListGroup className="h-100">
                {orders.length ?
                    orders.map(order =>
                        <ListGroup.Item key={order.id}>
                            <p className="title">{order.customerReference}</p>
                            <p className="subtitle">Customer Name</p><p className="subtitle">Internal Reference</p>
                            <p className="data">{order.customerOrganisationPersonName}</p><p className="data">{order.internalReference}</p>
                            <span className="text-status-red">Processing Failed</span>
                        </ListGroup.Item>) :
                    <div className="my-auto pb-5">
                        <Image src='Images/search-result.svg' className="mx-auto d-block" width="140px" />
                        <p className="text-noresults text-center">No data is available at the minute</p>
                    </div>}
            </ListGroup>
        }
    </Card>

const pieChartOptions = {
    legend: {
        position: 'left',
        labels: {
            boxWidth: 12
        }
    },
    tooltips: {
        callbacks: {
            label: (tooltipItem, data) => ` ${data.labels[tooltipItem.index]}: ${data.datasets[0].data[tooltipItem.index]}`
        }
    }
}

const PieChartCard = ({ title, data, loading }) =>
    <Card className="piechart-card">
        <Card.Header>{title}</Card.Header>
        {loading
            ? <div className="skeleton-loading doughnut">
                <span className="doughnut-chart"></span>
            </div>
            : data
                ? <div className="p-2 my-auto">
                    <Doughnut data={data} options={pieChartOptions} />
                </div>
                : <div className="p-2 my-auto">
                    <Image src='Images/search-result.svg' className="mx-auto d-block" width="140px" />
                    <p className="text-noresults text-center">No data is available at the minute</p>
                </div>
        }
    </Card>

const barOptions = {
    legend: {
        display: false
    },
    tooltips: {
        callbacks: {
            label: tooltipItem => ` ${tooltipItem.yLabel}`
        }
    },
    scales: {
        xAxes: [
            {
                display: false,
            }
        ],
        yAxes: [
            {
                display: false,
            }
        ]
    }
}

const barDecimalOptions = {
    legend: {
        display: false
    },
    tooltips: {
        callbacks: {
            label: tooltipItem => ` £${(tooltipItem.yLabel).toFixed(2)}`
        }
    },
    scales: {
        xAxes: [
            {
                display: false,
            }
        ],
        yAxes: [
            {
                display: false,
            }
        ]
    }
}

const BarCard = ({ title, data, loading, useDecimal = false }) =>
    <Card className="bar-card">
        <Card.Header>{title}</Card.Header>
        {loading
            ? <div className="skeleton-loading bar">
                <span className="bar-chart"></span>
            </div>
            : data
                ? <div className="p-2">
                    <div className="bar-container">
                        <Bar data={data} options={useDecimal ? barDecimalOptions : barOptions} height={93} />
                    </div>
                </div>
                : <div className="p-2 mt-4">
                    <Image src='Images/search-result.svg' className="mx-auto d-block" width="140px" />
                    <p className="text-noresults text-center">No data is available at the minute</p>
                </div>
        }
    </Card>

export {
    DefaultCard,
    ListCard,
    OrderListCard,
    PieChartCard,
    BarCard
}