import React, { useEffect, useState } from "react";
import xmark from "../../../common/shared/Close_x.svg";
import { Button, FormGroup, Modal } from "react-bootstrap";
import { COUNTRY_LIST } from "../../../common/constants";
import "./RecipientsModal.scss";
import Confirm from "../../../common/Confirm";
import FormValidator from "../../../common/FormValidator";

const DELETE_RECIPIENT_CONFIRMATION = "Are you sure you want to delete this recipient from the address book?";

export const RecipientsModal = ({
  show,
  recipientID = "",
  name = "",
  emailAddress = "",
  contactNumber = "",
  companyName = "",
  addressLine1 = "",
  addressLine2 = "",
  town = "",
  county = "",
  country = "GB",
  postCode = "",
  showDeleteConfirmationModal,
  handleShowRecipientsModal,
  handleToggleDeleteConfirmationModal,
  handleEdit,
  handleRecipientDelete,
  cancelRecipientDelete,
  modalStatusForm
}) => {

  const [modalState, setModalState] = useState({
    recipientID,
    contactDetails: {
      name,
      email: emailAddress,
      phone: contactNumber,
      companyName,
    },
    destinationAddress: {
      addressLine1,
      addressLine2,
      town,
      county,
      country,
      postCode,
    },
  });

  const [emailValidFormat, setEmailValidFormat] = useState(true);
  const [phoneValidFormat, setPhoneValidFormat] = useState(true);

  const [initialModalState, setInitialModalState] = useState(null);

  useEffect(() => {
    if (show) {
      if (initialModalState === null) {
        setInitialModalState({
          recipientID,
          contactDetails: {
            name,
            email: emailAddress,
            phone: contactNumber,
            companyName,
          },
          destinationAddress: {
            addressLine1,
            addressLine2,
            town,
            county,
            country,
            postCode,
          },
        });
      }
      
      setModalState({
        recipientID,
        contactDetails: {
          name,
          email: emailAddress,
          phone: contactNumber,
          companyName,
        },
        destinationAddress: {
          addressLine1,
          addressLine2,
          town,
          county,
          country,
          postCode,
        },
      });
    }

  }, [show]);

  const [isSubmited, setIsSubmited] = useState(false);

  const onClickSave = () => {
    setIsSubmited(true);
    const validationResult = handleValidationForm();
    const contactInformation = Object.values(modalState.contactDetails);
    const addressInformation = Object.values(modalState.destinationAddress);
    if (
      !contactInformation.some((s) => s.length === 0) ||
      !addressInformation.some(
        (s) => s.length === 0 && s.key !== "addressLine2"
      )
    ) {
      if (validationResult.isValid && emailValidFormat && phoneValidFormat) handleEdit(modalState);
    }
  };

  const handleContactDetailsInputChange = ({ target: { name, value } }) => {
    if (name === "email") {
      const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      setEmailValidFormat(regex.test(value))
    }

    if (name === "phone") {
      const regex = new RegExp(/^(?:(?:\+|0)(?:[0-9]\d{0,}))?[1-9]\d*(?:\s?[.-]?\s?\d+)*$/);
      setPhoneValidFormat(regex.test(value))
    }

    setModalState((prevState) => ({
      ...modalState,
      contactDetails: { ...prevState.contactDetails, [name]: value },
    }));
  };

  const handleDestinationAddressInputChange = ({ target: { name, value } }) => {
    setModalState((prevState) => ({
      ...modalState,
      destinationAddress: { ...prevState.destinationAddress, [name]: value },
    }));
  };

  const handleDelete = async () => {
    handleToggleDeleteConfirmationModal(true);
    handleShowRecipientsModal(false);
  };

  const handleValidationForm = () => {

    const body = {
      companyID: modalState.companyID,
      name: modalState.contactDetails.name,
      companyName: modalState.contactDetails.companyName,
      email: modalState.contactDetails.email,
      phone: modalState.contactDetails.phone,
      addressLine1: modalState.destinationAddress.addressLine1,
      addressLine2: modalState.destinationAddress.addressLine2,
      country: modalState.destinationAddress.country,
      county: modalState.destinationAddress.county,
      town: modalState.destinationAddress.town,
      postCode: modalState.destinationAddress.postCode,
    };

    const validator = new FormValidator([
      {
        field: 'name',
        method: 'isEmpty',
        validWhen: false,
        message: 'required'
      },
      {
        field: 'companyName',
        method: 'isEmpty',
        validWhen: false,
        message: 'required'
      },
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: 'required'
      },
      {
        field: 'phone',
        method: 'isEmpty',
        validWhen: false,
        message: 'required'
      },
      {
        field: 'addressLine1',
        method: 'isEmpty',
        validWhen: false,
        message: 'required'
      },
      {
        field: 'country',
        method: 'isEmpty',
        validWhen: false,
        message: 'required'
      },
      {
        field: 'county',
        method: 'isEmpty',
        validWhen: false,
        message: 'required'
      },
      {
        field: 'town',
        method: 'isEmpty',
        validWhen: false,
        message: 'required'
      },
      {
        field: 'postCode',
        method: 'isEmpty',
        validWhen: false,
        message: 'required'
      }
    ]);

    const validation = validator.validate(body);
    return validation
  }

  const handleCloseModal = () => {
    handleShowRecipientsModal(false);
    if (modalStatusForm !== "edit") setModalState(initialModalState);
    setIsSubmited(false);
  }

  return (
    <>
      <Modal
        id="recipients-modal-container"
        centered
        show={show}
        onHide={handleCloseModal}
        className="modal-class"
      >
        <Modal.Header>
          <Modal.Title className="title-modal-recipients">
            Recipient Details
          </Modal.Title>
          <img
            className="xmark-modal"
            onClick={handleCloseModal}
            src={xmark}
          />
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <div className="recipients-field-container mb-3">
              <div className="title-recipients-fields">
                <label htmlFor="name" className="recipients-label">
                  Name
                </label>
                {isSubmited && modalState.contactDetails.name.length == 0 && (
                  <span className="span-modal">required</span>
                )}
              </div>
              <div className="recipient-input-container">
                <input
                  name="name"
                  className={
                    isSubmited && modalState.contactDetails.name.length == 0
                      ? "recipient-input input-error"
                      : "recipient-input"
                  }
                  onChange={handleContactDetailsInputChange}
                  value={modalState.contactDetails.name}
                  maxLength={50}
                  type="text"
                  id="recipient-name"
                ></input>
              </div>
            </div>

            <div className="recipients-field-container mb-3">
              <div className="title-recipients-fields">
                <label htmlFor="companyName" className="recipients-label">
                  Company Name
                </label>
                {isSubmited &&
                  modalState.contactDetails.companyName.length == 0 && (
                    <span className="span-modal">required</span>
                  )}
              </div>

              <div className="recipient-input-container">
                <input
                  name="companyName"
                  className={
                    isSubmited &&
                      modalState.contactDetails.companyName.length == 0
                      ? "recipient-input input-error"
                      : "recipient-input"
                  }
                  onChange={handleContactDetailsInputChange}
                  value={modalState.contactDetails.companyName}
                  maxLength={50}
                  type="text"
                  id="recipient-company-name"
                ></input>
              </div>
            </div>

            <div className="recipients-field-container mb-3">
              <div className="title-recipients-fields">
                <label htmlFor="email" className="recipients-label">
                  Email
                </label>
                {isSubmited && modalState.contactDetails.email.length == 0 && (
                  <span className="span-modal">required</span>
                )}
                {isSubmited && modalState.contactDetails.email.length > 0 && !emailValidFormat && (
                  <span className="span-modal">email invalid format</span>
                )}
              </div>
              <div className="recipient-input-container">
                <input
                  name="email"
                  className={
                    isSubmited && (modalState.contactDetails.email.length == 0 || !emailValidFormat)
                      ? "recipient-input input-error"
                      : "recipient-input"
                  }
                  onChange={handleContactDetailsInputChange}
                  value={modalState.contactDetails.email}
                  maxLength={50}
                  type="email"
                  id="recipient-email"
                ></input>
              </div>
            </div>

            <div className="recipients-field-container mb-3">
              <div className="title-recipients-fields">
                <label htmlFor="phone" className="recipients-label">
                  Phone
                </label>
                {isSubmited && modalState.contactDetails.phone.length == 0 && (
                  <span className="span-modal">required</span>
                )}
                {isSubmited && modalState.contactDetails.phone.length > 0 && !phoneValidFormat && (
                  <span className="span-modal">phone invalid format</span>
                )}
              </div>
              <div className="recipient-input-container">
                <input
                  name="phone"
                  className={
                    isSubmited && (modalState.contactDetails.phone.length == 0 || !phoneValidFormat)
                      ? "recipient-input input-error"
                      : "recipient-input"
                  }
                  onChange={handleContactDetailsInputChange}
                  value={modalState.contactDetails.phone}
                  maxLength={50}
                  type="text"
                  id="recipient-phone"
                ></input>
              </div>
            </div>

            <div className="recipients-field-container mb-3">
              <div className="title-recipients-fields">
                <label htmlFor="addressLine1" className="recipients-label">
                  Address Line 1
                </label>
                {isSubmited &&
                  modalState.destinationAddress.addressLine1.length == 0 && (
                    <span className="span-modal">required</span>
                  )}
              </div>
              <div className="recipient-input-container">
                <input
                  name="addressLine1"
                  className={
                    isSubmited &&
                      modalState.destinationAddress.addressLine1.length == 0
                      ? "recipient-input input-error"
                      : "recipient-input"
                  }
                  onChange={handleDestinationAddressInputChange}
                  value={modalState.destinationAddress.addressLine1}
                  maxLength={50}
                  type="text"
                  id="recipient-address1"
                ></input>
              </div>
            </div>

            <div className="recipients-field-container mb-3">
              <div className="title-recipients-fields">
                <label htmlFor="addressLine2" className="recipients-label">
                  Address Line 2
                </label>
                {isSubmited && <span className="optional-field">Optional</span>}
              </div>
              <div className="recipient-input-container">
                <input
                  name="addressLine2"
                  className="recipient-input"
                  onChange={handleDestinationAddressInputChange}
                  value={modalState.destinationAddress.addressLine2}
                  maxLength={50}
                  type="text"
                  id="recipient-address2"
                ></input>
              </div>
            </div>

            <div className="recipients-field-container mb-3">
              <div className="title-recipients-fields">
                <label htmlFor="town" className="recipients-label">
                  Town
                </label>
                {isSubmited &&
                  modalState.destinationAddress.town.length == 0 && (
                    <span className="span-modal">required</span>
                  )}
              </div>
              <div className="recipient-input-container">
                <input
                  name="town"
                  className={
                    isSubmited && modalState.destinationAddress.town.length == 0
                      ? "recipient-input input-error"
                      : "recipient-input"
                  }
                  onChange={handleDestinationAddressInputChange}
                  value={modalState.destinationAddress.town}
                  maxLength={50}
                  type="text"
                  id="town"
                ></input>
              </div>
            </div>

            <div className="recipients-field-container mb-3">
              <div className="title-recipients-fields">
                <label htmlFor="postcode" className="recipients-label">
                  Postcode
                </label>
                {isSubmited &&
                  modalState.destinationAddress.postCode.length == 0 && (
                    <span className="span-modal">required</span>
                  )}
              </div>
              <div className="recipient-input-container">
                <input
                  name="postCode"
                  className={
                    isSubmited &&
                      modalState.destinationAddress.postCode.length == 0
                      ? "recipient-input input-error"
                      : "recipient-input"
                  }
                  onChange={handleDestinationAddressInputChange}
                  value={modalState.destinationAddress.postCode}
                  maxLength={50}
                  type="text"
                  id="postcode"
                ></input>
              </div>
            </div>

            <div className="recipients-field-container mb-3">
              <div className="title-recipients-fields">
                <label htmlFor="county" className="recipients-label">
                  County
                </label>
                {isSubmited &&
                  modalState.destinationAddress.county.length == 0 && (
                    <span className="span-modal">required</span>
                  )}
              </div>
              <div className="recipient-input-container">
                <input
                  name="county"
                  className={
                    isSubmited &&
                      modalState.destinationAddress.county.length == 0
                      ? "recipient-input input-error"
                      : "recipient-input"
                  }
                  onChange={handleDestinationAddressInputChange}
                  value={modalState.destinationAddress.county}
                  maxLength={50}
                  type="text"
                  id="county"
                ></input>
              </div>
            </div>

            <div className="recipients-field-container mb-3">
              <div className="title-recipients-fields">
                <label htmlFor="country" className="recipients-label">
                  Country
                </label>
                {isSubmited &&
                  modalState.destinationAddress.country.length == 0 && (
                    <span className="span-modal">required</span>
                  )}
              </div>
              <div className="recipient-input-container">
                <select
                  name="country"
                  className={
                    isSubmited &&
                      modalState.destinationAddress.country.length == 0
                      ? "recipient-input input-error"
                      : "recipient-input"
                  }
                  onChange={handleDestinationAddressInputChange}
                  id="country"
                  value={modalState.contactDetails.country}
                >
                  <option></option>
                  {COUNTRY_LIST.map((v, i) => (
                    <option key={i} selected={v.code == country} value={v.code}>
                      {v.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="buttons-edit-modal">
          {modalStatusForm === "edit" ?
            <Button
              variant="secondary"
              className="width-buttons"
              onClick={handleDelete}
            >
              Delete
            </Button> : null
          }
          <Button
            variant="primary"
            className="width-buttons"
            onClick={onClickSave}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Confirm
        id="confirm-delete-recipient-modal"
        size="md"
        show={showDeleteConfirmationModal}
        handleConfirmClose={cancelRecipientDelete}
        handleConfirmAction={() => handleRecipientDelete(recipientID)}
        title="Delete Recipient"
        buttonText="Yes, Delete"
        text={DELETE_RECIPIENT_CONFIRMATION}
      />
    </>
  );
};
