import React, { Component, Fragment } from 'react';
import { Button, Col, Form, FormGroup, Container, Row } from 'react-bootstrap';
import ReactDropzone from 'react-dropzone';
import { POST_FILE, extractData, GET } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import OrderUploadPdf from '../Selazar-Order-Upload-Instructions.pdf'
import OrderExample from '../OrderExample.csv'
import Header from '../../common/Header';
import { RETAILER_ORDER_SUB_NAV_LIST } from '../../common/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faDownload, faFileUpload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ErrorAlert, SuccessAlert } from '../../common/Alert';
import MissingDetails from '../../common/MissingDetails';

const MISSING_DETAILS_MESSAGE = "You need to complete the following before you are able to submit orders.";

const UploadInfo = () =>
    <Fragment>
        <h4>CSV Example and Documentation</h4>
        <p><strong>Before you upload please read the following information.</strong></p>
        <p>Use the template and instructions below to upload orders.</p>
        <p>Order Uploads can contain multiple orders. Each row represents a product in the order.</p>
        <p>If you need any further support on this, you can access our <a target='_blank' rel="noopener noreferrer" href="https://support.selazar.com">FAQs</a>, or contact <a href="mailto:customer.service@selazar.com">customer.service@selazar.com</a>.</p>
        <div className="file-list">
            <ul className>
                <li className="file-list-file"><FontAwesomeIcon icon={faFile} className="form-fileicon" />Order CSV example<a href={OrderExample} target='_blank' rel="noopener noreferrer" className="float-right" alt='Order CSV example - opens in a new window'><FontAwesomeIcon className="form-fileicon-download" icon={faDownload} /></a></li>
                <li className="file-list-file"><FontAwesomeIcon icon={faFile} className="form-fileicon" />Order CSV instructions<a href={OrderUploadPdf} target='_blank' rel="noopener noreferrer" className="float-right" alt='Order CSV instructions - opens in a new window'><FontAwesomeIcon className="form-fileicon-download" icon={faDownload} /></a></li>
            </ul>
        </div>
    </Fragment>

const UploadResult = ({ result }) => {
    if (result.isError) {
        const errors = result.message ? result.message.split(',') : [];

        if (result.isMalformed) {
            return <ErrorAlert errorMessage={`Formatting error, ${result.fileName} error detected with formatting`} />
        } else if (result.message && result.isError && errors.length > 0) {
            const errorMessage = <React.Fragment>
                <p>Upload Error</p>
                {errors.map((e, i) => <p key={i}>{e}</p>)}
            </React.Fragment>
            return <ErrorAlert errorMessage={errorMessage} />
        } else if (result.message && result.isError) {
            return <ErrorAlert errorMessage={`Upload error, ${result.message}`} />
        } else {
            const errorMessage = <React.Fragment>
                <p>Upload error</p>
                <p>{result.fileName}: {result.successfulUploads} Items uploaded successfully.</p>
                <p>{result.errorUploads} Items could not be uploaded.</p>
                {result.errorLines.length && <React.Fragment>
                    <p>Errors on lines:</p>
                    <ul>
                        {result.errorLines.map((e, i) => <li key={i}>{e}</li>)}
                    </ul>
                </React.Fragment>}
            </React.Fragment>

            return <ErrorAlert errorMessage={errorMessage} />
        }
    } else {
        return <SuccessAlert successMessage={`${result.fileName} uploaded successfully.`} />
    }
}

const UploadForm = ({ onSubmit, onDrop, files, removeFile, results, errorMessage }) =>
    <Form onSubmit={onSubmit}>
        <FormGroup>
            <ReactDropzone accept=".csv" onDrop={onDrop} maxSize={999999}>
                {({ getRootProps, getInputProps }) => (
                    <Fragment>
                        <div {...getRootProps({ className: "file-upload text-center" })}>
                            <input {...getInputProps()} />
                            <FontAwesomeIcon icon={faFileUpload} className="file-upload-icon" />
                            <p className="file-upload-primarytext my-2">Drag and drop your CSV file(s)</p>
                            <p className="file-upload-secondarytext my-2">or</p>
                            <Button variant="secondary">Browse</Button>
                        </div>
                        {files.length > 0 &&
                            <div className="file-list mt-4">
                                <ul>
                                    {files.map((f, i) => <li key={i}><FontAwesomeIcon icon={faFile} className="form-fileicon" />{f.name} - {f.size} bytes <FontAwesomeIcon icon={faTrashAlt} className="form-fileicon-action float-right" index={i} onClick={removeFile} /></li>)}
                                </ul>
                            </div>
                        }
                    </Fragment>
                )}
            </ReactDropzone>
        </FormGroup>
        {results.map((result, i) => <UploadResult key={`upload-result-${i}`} result={result} />)}
        {errorMessage && <ErrorAlert errorMessage={errorMessage} />}
        <FormGroup className="float-right mt-3">
            <Button variant="primary" type="submit">Upload File(s)</Button>
        </FormGroup>
    </Form>

class UploadOrderCsv extends Component {

    state = {
        files: [],
        loading: true,
        hasValidCardDetails: false,
        hasValidWarehouse: false,
        errorMessage: null,
        results: []
    };

    async componentDidMount() {
        await Promise.all([
            this.validateCardDetails(),
            this.validateWarehouse()
        ]);
        this.setState({ loading: false });
    }

    validateCardDetails = () =>
        GET(Endpoints.FINANCE.CARD.GET.HAS_VALID_DETAILS)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    console.log(res.message);
                }
                else extractData(res) ? this.setState({ hasValidCardDetails: true }) : this.setState({ hasValidCardDetails: false });
            })
            .catch(error => console.log(error));

    validateWarehouse = () =>
        GET(Endpoints.WAREHOUSE.GET.SELECTION)           
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result);
                this.setState({ hasValidWarehouse: data?.id !== null && data?.id !== "" });
            })
            .catch(error => console.log(error));

    removeFile = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const idx = e.target.getAttribute('index');

        this.setState({
            files: [
                ...this.state.files.slice(0, idx),
                ...this.state.files.slice(idx + 1)
            ]
        });
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            this.setState({ results: [], error: true, message: "File could not be uploaded. Please ensure the file type is CSV and the file size is under 1MB." });
        }

        this.setState(prevState => ({
            files: prevState.files.concat(acceptedFiles)
        }));
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (this.state.files.length) {
            this.setState({ loading: true });

            let formData = new FormData();
            this.state.files.forEach((f, i) => formData.append(`files`, this.state.files[i], f.name));

            return POST_FILE(Endpoints.ORDERS.POST.UPLOAD, formData)
                .then(r => r.json())
                .then(response => {
                    if (!response.error) {
                        this.setState({ loading: false, error: false, files: [], message: 'Orders successfully uploaded.', results: extractData(response) });
                    }
                    else {
                        this.setState({ loading: false, error: true, files: [], message: response.message });
                    }
                })
                .catch(error => console.log(error));

        } else {
            this.setState({ results: [], error: true, message: "No file selected, Ensure a file is selected for upload." });
        }
    }

    render() {
        const { loading, files, results, errorMessage, hasValidWarehouse, hasValidCardDetails } = this.state;

        return (
            <Fragment>
                {loading
                    ? <LoadingBar />
                    : <Fragment>
                        <Header title="Orders" subNavList={RETAILER_ORDER_SUB_NAV_LIST} activeKey="Upload" />
                        <Container fluid>
                            <Row>
                                <Col sm={12} md={6}>
                                    {hasValidWarehouse && hasValidCardDetails
                                        ? <Fragment>
                                            <UploadInfo />
                                            <UploadForm onSubmit={this.onSubmit} onDrop={this.onDrop} files={files} removeFile={this.removeFile} results={results} errorMessage={errorMessage} />
                                        </Fragment>
                                        : <MissingDetails message={MISSING_DETAILS_MESSAGE} showWarehouse={!hasValidWarehouse} showCard={!hasValidCardDetails} />
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Fragment>
                }
            </Fragment>
        );
    }
}

export default UploadOrderCsv;