import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { toLocalDateString } from '../../../../Utilities';
import NoResults from '../../../common/Tables/NoResults';

const CollectionAdviceRow = ({ collectionAdvice }) =>
    <tr>
        <td>{toLocalDateString(collectionAdvice.collectionDate)}</td>
        <td>{collectionAdvice.collectionAdviceNumber ? collectionAdvice.collectionAdviceNumber : "Not Charged" }</td>
        <td>{collectionAdvice.currencyAbbreviation}</td>
        <td>{collectionAdvice.formattedTotal}</td>
        <td className="text-center py-2"><Button variant="secondary" size="sm" onClick={() => window.open(collectionAdvice.link, '_blank')}>View</Button></td>
    </tr>

const CollectionAdviceTable = ({ collectionAdvices }) =>
    <Table striped bordered size="sm" className={!collectionAdvices.length ? "table-empty" : ""}>
        <thead>
            <tr>
                <th>Date</th>
                <th>Collection Advice Number</th>
                <th>Currency</th>
                <th>Total</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {collectionAdvices.length
                ? collectionAdvices.map(collectionAdvice => <CollectionAdviceRow key={collectionAdvice.id} collectionAdvice={collectionAdvice} />)
                : <NoResults colSpan="4" text="No collections available." />
            }
        </tbody>
    </Table>

export default CollectionAdviceTable;