import React from 'react';

const LoadingBar = () =>
    <div className="overlay" onClick={() => false}>
        <div className="loading">
            <div className="loading-bar" />
            <div className="loading-bar" />
            <div className="loading-bar" />
            <div className="loading-bar" />
            <span>Loading, please wait...</span>
        </div>
    </div>

export default LoadingBar;