import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NavMenu from './NavMenu';
import Header from './Header';

import './Layout.scss';

const Layout = ({ onLogout, children }) =>
  <React.Fragment>
    <Header onLogout={onLogout} />
    <div className="content-wrapper">
      <NavMenu />
      <Container fluid>
        <Row className="p-4">
          <Col lg={12}>
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>

  export default Layout;
