import React, { Component } from 'react';
import { FormControl, FormGroup, FormLabel, Row, Col, Button } from 'react-bootstrap';
import StockUploadPdf from '../../Selazar-Stock-Upload-Instructions.pdf';
import StockUploadExample from '../../StockUploadExample.csv';
import { ErrorAlert } from '../../../common/Alert';
import ReactDropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faDownload, faFileUpload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

class FileUpload extends Component {

    renderBoxSize = (data, validationMessages, handleInputChange) =>
        (<React.Fragment>
            <FormGroup as={Row}>
                <Col xs={6} md={2}>
                    <p className="title">Box Height (cm)</p>
                    <FormControl id="heightField" type="number" name="boxHeight" value={data.boxHeight} onChange={handleInputChange} min="0" />
                </Col>
                <Col md={12}>
                    {validationMessages.boxHeightError && <span className="text-danger">Box height must be greater than 0</span>}
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Col xs={6} md={2}>
                    <p className="title">Box Width (cm)</p>
                    <FormControl id="widthField" type="number" name="boxWidth" value={data.boxWidth} onChange={handleInputChange} min="0" />
                </Col>
                <Col md={12}>
                    {validationMessages.boxHeightError && <span className="text-danger">Box height must be greater than 0</span>}
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Col xs={6} md={2}>
                    <p className="title">Box Depth(cm)</p>
                    <FormControl id="depthField" type="number" name="boxDepth" value={data.boxDepth} onChange={handleInputChange} min="0" />
                </Col>
                <Col md={12}>
                    {validationMessages.boxDepthError && <span className="text-danger">Box depth must be greater than 0</span>}
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Col xs={12} md={2}>
                    <p className="title">Box Weight (kg)</p>
                    <FormControl id="weightField" type="number" name="boxWeight" value={data.boxWeight} onChange={handleInputChange} min="0" />
                </Col>
                <Col md={12}>
                    {validationMessages.boxWeightError && <span className="text-danger">Box weight must be greater than 0</span>}
                </Col>
            </FormGroup>
        </React.Fragment>);

    render() {
        const { files, validationMessages, message, onDrop, removeFile, noInputAlert, pickFromPalletAlert, suidItemCountAlert, invalidItemAlert, showStockCSVFormattingValidation, palletCount, onPalletCountChange, isPallet, handleInputChange, data } = this.props;
        const boxSize = (data.isPallet === 'false') ? this.renderBoxSize(data, validationMessages, handleInputChange) : null;

        return (
            <React.Fragment>
                <Row>
                    <Col sm={12} md={6}>
                        <h4>Inventory Details</h4>
                        {noInputAlert}
                        {pickFromPalletAlert}
                        {suidItemCountAlert}
                        {invalidItemAlert}
                        {showStockCSVFormattingValidation && <div className="mt-3"><ErrorAlert errorMessage={message}></ErrorAlert></div>}
                        <p>You can use this tool to build a consignment of inventory that you are sending in to the Selazar warehouse for processing. This consignment can consist of a mix of products and stock boxes, which you will be able to configure in a CSV upload using this tool.</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        <p>Each Inventory Upload CSV should represent either one pallet or one consignment box of items as a consignment.</p>
                        <p>Our system will create labels for you to place on your consignment and these are unique to this consignment. These labels cannot be reused across multiple consignments.</p>
                        <p>If you have any questions about sending in your inventory, please contact us via support@selazar.com.</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        <p className="title">Is this a pallet?</p>
                        <FormGroup className="custom-control custom-radio mb-0">
                            <input className="custom-control-input" type='radio' id='yesRadio' name='isPallet' value={'true'}
                                checked={isPallet === 'true'} onChange={handleInputChange} />
                            <FormLabel className="custom-control-label" htmlFor="yesRadio">Yes </FormLabel>
                        </FormGroup>
                        <FormGroup className="custom-control custom-radio">
                            <input className="custom-control-input" type='radio' id='noRadio' name='isPallet' value={'false'}
                                checked={isPallet === 'false'} onChange={handleInputChange} />
                            <FormLabel className="custom-control-label" htmlFor="noRadio">No </FormLabel>
                        </FormGroup>
                    </Col>
                </Row>
                {boxSize}
                <Row>
                    <Col sm={12} md={6}>
                        <p className="title">Repeat Consignments</p>
                        <div className="form-input-description">
                            <p>Choose the quantity of consignments you are sending in with the same configuration that is in your CSV.</p>
                        </div>
                        <FormControl className="mb-4" id="palletCount" name="palletCount" as="select" value={palletCount} onChange={onPalletCountChange}>
                            {this.props.generatePalletCountOptions()}
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        <p className="title">CSV Upload</p>
                        <p>Please use the following CSV example and Documentation to learn how to send us a box or a pallet as a stock consignment to us.</p>
                        <div className="file-list">
                            <ul>
                                <li className="file-list-file"><FontAwesomeIcon icon={faFile} className="form-fileicon" />Stock CSV template<span className="text-status-new ml-3">New!</span><a href={StockUploadExample} target='_blank' rel="noopener noreferrer" className="float-right" alt='Stock CSV example - opens in a new window'><FontAwesomeIcon className="form-fileicon-download" icon={faDownload} /></a></li>
                                <li className="file-list-file"><FontAwesomeIcon icon={faFile} className="form-fileicon" />Stock CSV instructions<span className="text-status-new ml-3">New!</span><a href={StockUploadPdf} target='_blank' rel="noopener noreferrer" className="float-right" alt='Stock CSV instructions - opens in a new window'><FontAwesomeIcon className="form-fileicon-download" icon={faDownload} /></a></li>
                            </ul>
                        </div>
                        <FormGroup>
                            <ReactDropzone accept=".csv" onDrop={onDrop} maxSize={999999}>
                                {({ getRootProps, getInputProps }) => (
                                    <React.Fragment>
                                        <div {...getRootProps({ className: "file-upload text-center" })}>
                                            <input {...getInputProps()} />
                                            <FontAwesomeIcon icon={faFileUpload} className="file-upload-icon" />
                                            <p className="file-upload-primarytext my-2">Drag and drop your CSV file(s)</p>
                                            <p className="file-upload-secondarytext my-2">or</p>
                                            <Button variant="secondary">Browse</Button>
                                        </div>
                                        <div className="file-list mt-4">
                                            <ul>
                                                {files.map((f, i) => <li key={i}><FontAwesomeIcon icon={faFile} className="form-fileicon" />{f.name} - {f.size} bytes <FontAwesomeIcon icon={faTrashAlt} className="form-fileicon-action float-right" index={i} onClick={removeFile} /></li>)}
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                )}
                            </ReactDropzone>
                        </FormGroup>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default FileUpload;