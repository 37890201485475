import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';

import './Actions.scss';

const Actions = ({ title, actions = [] }) =>
    <div className="action-container">
        <Dropdown className="table-dropdown-action">
            <Dropdown.Toggle data-boundary="viewport" className="dropdown-toggle-action">...</Dropdown.Toggle>
            <Dropdown.Menu>
                {title && <Dropdown.Header>{title}</Dropdown.Header>}
                {title && <Dropdown.Divider></Dropdown.Divider>}
                {actions.length && actions.map((a, i) => a && <Dropdown.Item key={`action-${i}`} className={a.danger ? "danger" : ""}>{a.action}</Dropdown.Item>)}
            </Dropdown.Menu>
        </Dropdown>
    </div>

export default Actions;