import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { PREFERENCES_SUB_NAV_LIST } from '../../../common/constants'
import Header from '../../../common/Header';
import Breadcrumbs from '../../../common/Breadcrumbs';
import LoadingBar from '../../../common/LoadingBar';
import { ViewCustomPackageCard, AddPackageCard, DeleteModal } from './Snippets';
import { ErrorAlert, SuccessAlert } from '../../../common/Alert';
import Endpoints from '../../../common/Endpoints';
import { GET, extractData, PUT } from '../../../../Consumer';

const DELETE_ERROR_MESSAGE = "Unable to delete custom box. Please try again.";
const DELETE_SUCCESS_MESSAGE = "Successfully deleted custom box.";
const ENABLE_ERROR_MESSAGE = "Unable to update enable status of custom box. Please try again.";

const breadcrumbs = [
    { text: "Packaging", link: "/retailer/preferences/packaging" },
    { text: "Custom Packaging", link: "/retailer/preferences/packaging/custompackaging" },
    { text: "View Custom Box", link: null }
];

class ViewCustomBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            showDeleteModal: false,
            showDeleteSuccess: false,
            showDeleteError: false,
            showEnableError: false,
            deletePackageName: "",
            deletePackageId: null,
            boxes: []
        };
    }

    async componentDidMount() {
        await this.fetchBoxes();
        this.setState({ loading: false });
    }

    fetchBoxes = async () => {
        return await GET(Endpoints.PACKAGING.GET.BOXES)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ boxes: extractData(results) });
            })
            .catch(error => console.log(error));
    }

    handleAddPackageClick = () => {
        this.props.history.push({ pathname: "/retailer/preferences/packaging/custompackaging/add/box", state: { returnToMain: false }});
    }

    handleShowHideDeleteModal = (deletePackageName, deletePackageId) => this.setState(prevState => ({ showDeleteModal: !prevState.showDeleteModal, deletePackageName: deletePackageName, deletePackageId: deletePackageId }));

    handleDeletePackage = async () => {
        this.setState({ loading: true });
        const { deletePackageId, boxes } = this.state;
        this.handleShowHideDeleteModal("", null);

        return await PUT(Endpoints.PACKAGING.PUT.ARCHIVE + deletePackageId)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result)
                if (data) {
                    const filteredBoxes = boxes.filter(b => b.id !== deletePackageId);
                    this.setState({ boxes: filteredBoxes, showDeleteSuccess: true, showDeleteError: false, showEnableError: false, loading: false });
                } else this.setState({showDeleteSuccess: false, showDeleteError: true, showEnableError: false, loading: false });
            });
    }

    handleEnablePackage = async (id) => {
        this.setState({ loading: true });
        const { boxes } = this.state;

        return await PUT(Endpoints.PACKAGING.PUT.ENABLE + id)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result)
                if (data) {
                    boxes.find(b => b.enabled !== true && b.id === id).enabled = true;
                    this.setState({ boxes: boxes, showEnableError: false, showDeleteSuccess: false, showDeleteError: false, loading: false });
                } else this.setState({ showEnableError: true, showDeleteSuccess: false, showDeleteError: false, loading: false });
            });
    }

    handleDisablePackage = async (id) => {
        this.setState({ loading: true });
        const { boxes } = this.state;

        return await PUT(Endpoints.PACKAGING.PUT.DISABLE + id)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result)
                if (data) {
                    boxes.find(b => b.enabled !== false && b.id === id).enabled = false;
                    this.setState({ boxes: boxes, showEnableError: false, showDeleteSuccess: false, showDeleteError: false, loading: false });
                } else this.setState({ showEnableError: true, showDeleteSuccess: false, showDeleteError: false, loading: false });
            });
    }

    render() {
        const { loading, boxes, deletePackageName, deletePackageId, showDeleteModal, showDeleteError, showEnableError, showDeleteSuccess } = this.state;
        return (
            <React.Fragment>
                <Header title="Preferences" subNavList={PREFERENCES_SUB_NAV_LIST} activeKey="Packaging" />
                {loading ? <LoadingBar /> :
                    <Container fluid>
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                        <Row>
                            <Col>
                                <h5 className="mb-3">Custom Boxes</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                            {showDeleteError && <ErrorAlert errorMessage={DELETE_ERROR_MESSAGE} />}
                                {showEnableError && <ErrorAlert errorMessage={ENABLE_ERROR_MESSAGE} />}
                                {showDeleteSuccess && <SuccessAlert successMessage={DELETE_SUCCESS_MESSAGE} />}
                            </Col>
                        </Row>
                        <Row>
                            {boxes.map(box => <ViewCustomPackageCard key={box.id} isBox={true} customPackage={box} handleDeleteClick={() => this.handleShowHideDeleteModal(box.friendlyName, box.id)} handleEditClick={() => this.props.history.push(`/retailer/preferences/packaging/custompackaging/edit/box/${box.id}`)} handleEnableToggle={box.enabled ? () => this.handleDisablePackage(box.id) : () => this.handleEnablePackage(box.id)} />)}
                            <AddPackageCard isBox={true} handleAddPackageClick={this.handleAddPackageClick} />
                        </Row>
                        <DeleteModal isBox={true} packageName={deletePackageName} deletePackageId={deletePackageId} handleClose={() => this.handleShowHideDeleteModal("", null)} handleDeletePackage={this.handleDeletePackage} showModal={showDeleteModal} />
                    </Container>}
            </React.Fragment>
        )
    }
}

export default ViewCustomBox;