import React, { Component, Fragment } from 'react'
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import { extractData, GET, DELETE } from '../../../../Consumer';
import Endpoints from '../../../common/Endpoints';
import LoadingBar from '../../../common/LoadingBar';
import Header from '../../../common/Header';
import Confirm from '../../../common/Confirm';
import { SuccessAlert, ErrorAlert } from '../../../common/Alert';
import { INTEGRATIONS_SUB_NAV_LIST } from '../../../common/constants';
import { toLocalDateString } from '../../../../Utilities';

const DELETE_MODAL_MESSAGE = "Are you sure you want to delete this app? Deleting will revoke credentials and break any connections that app is used for."

const AppIntegrationAdd = ({ history }) =>
    <Col Col sm={12} md={4}>
        <Card className="card-integration-add">
            <Card.Body className="mt-5">
                <p><strong>Want to add another app?</strong></p>
                <Button variant="secondary" onClick={() => history.push("/retailer/integrations/apps/add")}>Set up App</Button>
            </Card.Body>
        </Card>
    </Col>

const AppIntegration = ({ app, history, handleDeleteClick }) =>
    <Col md={12} lg={4} className="mb-3">
        <Card className="card-integration-view">
            <Card.Body>
                <div className="card-integration-details">
                    <p className="title">App Name <span>{app.name}</span></p>
                    <p className="title">Date Created <span>{toLocalDateString(app.createdDate)}</span></p>
                </div>
                <Row className="d-none d-md-flex">
                    <Col md={12} lg={6}>
                        <Button variant="danger" className="mt-3 px-4" onClick={() => handleDeleteClick(app.id)}>Delete App</Button>
                    </Col>
                    <Col md={12} lg={6}>
                        <Button variant="secondary" className="mt-3 px-4 ml-auto float-right" onClick={() => history.push(`/retailer/integrations/apps/edit/${app.id}`)}>Edit Details</Button>
                    </Col>
                </Row>
                <Row className="d-flex d-md-none text-center">
                    <Col md={12} lg={6}>
                        <Button variant="danger" className="mt-3" onClick={() => handleDeleteClick(app.id)} block>Delete App</Button>
                    </Col>
                    <Col md={12} lg={6}>
                        <Button variant="secondary" className="mt-3" onClick={() => history.push(`/retailer/integrations/apps/edit/${app.id}`)} block>Edit Details</Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </Col>

const NoAppIntegrationsCard = ({ history }) =>
    <Row>
        <Col Col sm={12} md={4}>
            <Card className="card-integration-add">
                <Card.Body className="mt-5">
                    <p><strong>You currently have no apps set up</strong></p>
                    <Button variant="secondary" className="px-4" onClick={() => history.push("/retailer/integrations/apps/add")}>Set Up App</Button>
                </Card.Body>
            </Card>
        </Col>
    </Row>

class Apps extends Component {

    state = {
        loading: true,
        apps: [],
        showDeleteModal: false,
        deleteAppId: null,
        deleteSuccess: null,
        deleteError: null
    };

    async componentDidMount() {
        await this.fetchApps();
        this.setState({ loading: false });
    }

    fetchApps = () =>
        GET(Endpoints.INTEGRATIONS.APP.GET.INTEGRATIONS)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const apps = extractData(results);
                this.setState({ apps: apps ? apps : [] });
            })
            .catch(error => console.log(error));

    handleDeleteShowHide = () => this.setState((prevState) => ({ showDeleteModal: !prevState.showDeleteModal }));

    handleDeleteClick = (id) => this.setState({ deleteAppId: id, showDeleteModal: true });

    handleDeleteAction = async () => {
        const { deleteAppId } = this.state;
        this.setState({ loading: true });

        return await DELETE(Endpoints.INTEGRATIONS.APP.DELETE.INTEGRATION + deleteAppId)
            .then(r => r.json())
            .then(result => {
                const data = extractData(result)
                if (data) {
                    this.fetchApps();
                    this.setState({ deleteSuccess: true, deleteError: null, deleteAppId: null, loading: false, showDeleteModal: false });
                } else this.setState({ deleteSuccess: false, deleteError: true, deleteAppId: null, loading: false, showDeleteModal: false });
            });
    }

    render() {
        const { loading, apps, showDeleteModal, deleteSuccess, deleteError } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <Header title="Integrations" subNavList={INTEGRATIONS_SUB_NAV_LIST} activeKey="Manage Apps" />
                    <Container fluid>
                        {deleteSuccess &&
                            <Row>
                                <Col sm={12} md={6}>
                                    <SuccessAlert successMessage="Successfully deleted app integration." />
                                </Col>
                            </Row>
                        }
                        {deleteError &&
                            <Row>
                                <Col sm={12} md={6}>
                                    <ErrorAlert errorMessage="Unable to delete app integration. Please try again." />
                                </Col>
                            </Row>
                        }
                        {apps.length
                            ? <Row>
                                {apps.map(app => <AppIntegration key={app.id} app={app} history={this.props.history} handleDeleteClick={this.handleDeleteClick} />)}
                                <AppIntegrationAdd history={this.props.history} />
                            </Row>
                            : <NoAppIntegrationsCard history={this.props.history} />
                        }
                    </Container>
                    <Confirm
                        show={showDeleteModal}
                        title="Delete App"
                        text={DELETE_MODAL_MESSAGE}
                        buttonText="Delete App"
                        buttonVariant="danger"
                        cancelText="Continue Editing"
                        handleConfirmClose={this.handleDeleteShowHide}
                        handleConfirmAction={this.handleDeleteAction}
                    />
                </Fragment>
        )
    }
}

export default Apps;