import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const MissingDetails = ({ message, showWarehouse, showCard }) =>
    <Fragment>
        <section className="mt-0 mb-4">
            <p className="title mb-3">Please complete the following tasks</p>
            <p>{message}</p>
        </section>
        {showWarehouse &&
            <section className="mb-4">
                <p className="title mb-3">1. Select Your Warehouse</p>
                <p>We work with multiple warehouses and need to know which one you want to operate from.</p>
                <Link to="/retailer/preferences/warehouse">
                    <Button variant="secondary" className="mt-3 mb-2">Select Warehouse</Button>
                </Link>
            </section>
        }
        {showCard &&
            <section>
                <p className="title mb-3">{`${showWarehouse ? "2." : "1."} Add Card Details`}</p>
                <p>We need you to add your card details. We need a payment method on file to allow us to charge you for our services.</p>
                <Link to="/retailer/finance/newcard">
                    <Button variant="secondary" className="mt-3">Add Card Details</Button>
                </Link>
            </section>
        }
    </Fragment>

export default MissingDetails;