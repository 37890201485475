import React from 'react';

export const BatchManagementTooltip = (props) => (
  <div>
    <div>
      <div id="tooltip-inner-info" className='batch-tooltip' {...props}>
        <em>Stock quantity is managed in batches within the warehouse. If an expiry date is provided, it will be handled "First expired, First out".</em>
      </div>
    </div>
  </div>
);