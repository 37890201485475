import React, { useEffect, useState } from 'react'
import LoadingBar from '../../../common/LoadingBar';
import { ProductListTable } from '../shared/ProductListTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Container, Button, Card } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { PurchaseOrdersHeader } from '../PurchaseOrdersHeader';
import { ErrorAlert, SuccessAlert } from '../../../common/Alert';
import { usePurchaseOrder } from '../hooks/usePurchaseOrder';
import { GET, extractData } from '../../../../Consumer';
import Endpoints from '../../../common/Endpoints';
import { PO_REFERENCE_ERROR, SAVE_ERROR_MESSAGE, SAVE_ADD_PO_SUCCESS_MESSAGE, SAVE_UPDATE_PO_SUCCESS_MESSAGE, EXISTING_POREFERENCE_MESSAGE } from '../shared/StringConstants';
import '../shared/PurchaseOrderDetails.scss';

const PURCHASE_ORDERS_LINK = "/supplier/purchaseorders/";
const getReturnLink = () => PURCHASE_ORDERS_LINK;

export const PurchaseOrderDetails = () => {

  const location = useLocation();
  const poReference = location?.state?.purchaseOrderReference || '';
  const items = location?.state?.items || [];
  const purchaseOrderID = location?.state?.purchaseOrderID || null;
  const statusID = location?.state?.statusID || null;
  
  const purchaseOrder = {
    poReference,
    items,
    purchaseOrderID,
    statusID
  }

  const {
    products, 
    companyProducts, 
    purchaseOrderReference, 
    isPOReferenceEmpty, 
    loading,
    saveError,
    showSuccess, 
    poReferenceExists,
    initialState,
    id,
    setCompanyProducts, 
    setPurchaseOrderReference, 
    setIsPOReferenceEmpty, handleAdd, 
    handleDelete, handleSelectChange, 
    handleInputChange, 
    handleSubmit,
    setLoading,
    setPOReferenceExists,
    stateHasChanged,
    setStateHasChanged
  }  = usePurchaseOrder(purchaseOrder);
  
  useEffect(() => {
    fetchCompanyItems();
  }, []);

  useEffect(() => {
    setStateHasChanged(somethingHasChanged());  
  }, [products, purchaseOrderReference]);

  const fetchCompanyItems = async () => {

    setLoading(true);
    let url = new URL(Endpoints.STOCK.GET.BY_WITHOUT_STOCK);

    try {
      const response = await GET(url);
      let newCompanyProductsState = [];
      if (response.ok) {
        const data = extractData(await response.json());
        const { items: itemsFromFetch  } = data;
        const products = itemsFromFetch.map(item => {
          const ean = item.ean ? item.ean : item.selazarUniqueID;
          return  { ...item, ean, isActive: true }
        });

        if (items.length > 0) {
          newCompanyProductsState = products.filter(product => !items.some(item => item.companyItemID === product.companyItemID));
        }
        else newCompanyProductsState = products;

        setCompanyProducts([...companyProducts, ...newCompanyProductsState]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const somethingHasChanged = () => {
    const { products: initialStateProducts, purchaseOrderReference: initialStatePOReference } = initialState;
    if(purchaseOrderReference.trim().toLowerCase() !== initialStatePOReference?.trim().toLowerCase()) return true;
    if(products.length > initialStateProducts?.length || products.length < initialStateProducts?.length) return true;

    const changedProducts = products.filter(product => { 
      const initialStateProduct = initialStateProducts?.find(is => is.companyItemID === product.companyItemID);
      if(product.quantity !== '' && parseInt(product?.quantity) !== parseInt(initialStateProduct?.quantity)) return true;
      if(product.quantity === '' && initialStateProduct?.quantity === '' && product.name === '' && initialStateProduct?.name === '') return false;
      if(product.name != initialStateProduct?.name) return true;
      if(initialStateProduct?.quantity !== '' && parseInt(initialStateProduct?.quantity) !== parseInt(product.quantity)) return true;
    });

    return changedProducts.length > 0;
  }

  const handlePOReferenceInputChange = ({ target : { value }}) => {
    
    if (value.trim().length === 0) value = '';
    setPurchaseOrderReference(value);

    if (value.trim().length !== 0) {
      setIsPOReferenceEmpty(false);
      setPOReferenceExists();
    }

  }

  const handleSave = () => {
    handleSubmit();
  }

  return (
    <>
      {
        loading ? <LoadingBar /> 
        : 
        <Container fluid>
          <Link to={ getReturnLink() } className="link-button">
            <Button variant="link" className="button-nav-return p-0  mb-1"><FontAwesomeIcon icon={ faChevronLeft } className="mr-1" /> Return to Purchase Order References</Button>
          </Link> 
          <PurchaseOrdersHeader title="Inventory" headerClass={ 'mb-0' } subNavList="" />
          {saveError && <ErrorAlert errorTitle='Error' errorMessage={ SAVE_ERROR_MESSAGE } headerClass={ 'card-danger mb-3 save-error' } />}
          {showSuccess && <SuccessAlert successMessage={ id ? SAVE_UPDATE_PO_SUCCESS_MESSAGE : SAVE_ADD_PO_SUCCESS_MESSAGE } headerClass={ 'card-success mb-3 save-success' } />}
          <div className='mb-5 po-reference'>
              <h6 className='page-title-bold'>PO Reference</h6>
              <div className='poreference-container'>
                <input name="POReference" value={ purchaseOrderReference } disabled={ purchaseOrderID } maxLength={50} onChange={ handlePOReferenceInputChange } type="text" id='POReference' className={`${isPOReferenceEmpty ? 'po-reference-inputtext border-danger' : 'po-reference-inputtext'}`} ></input>
                { isPOReferenceEmpty && <span className="text-danger">{ PO_REFERENCE_ERROR }</span> }
                { poReferenceExists && <span className="text-danger">{ EXISTING_POREFERENCE_MESSAGE }</span> }
              </div>
          </div>
          {
            <>
              <Card className="card-table mb-5">
                <ProductListTable 
                  products={ products }
                  companyProducts={ companyProducts }
                  handleAdd={ handleAdd } 
                  handleDelete={ handleDelete }
                  handleSelectChange={ handleSelectChange }
                  handleInputChange={ handleInputChange }
                />                                            
              </Card>
              <Button disabled={ !stateHasChanged } id='save-button' variant="primary" onClick={ handleSave } className="float-right" >Save</Button>
            </>
          }
        </Container>   
      }
    </>
  )
}
