import React from 'react';
import { Button, Col, FormLabel, Form, FormControl, FormGroup, Row, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const OrderListFilter = ({ filter, showStatusFilter, onClear, onFilterChange, onSearch, showIssueFilterOption, 
    searchText = "Search by Order Number", showSortBy = true }) =>

    <Form onSubmit={onSearch}>
        <FormGroup>
            <Row>
                <Col md={3}>
                    <InputGroup>
                        <FormControl type="text" id="orderNumber" className="input-filter" name="orderNumber" value={filter?.orderNumber} onChange={onFilterChange} placeholder={searchText} />
                        <InputGroup.Append>
                            <InputGroup.Text className="input-filter-icon" onClick={onSearch}>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Col md={4}>
                    <InputGroup>
                        <FormControl id="dateFromField" type="date" name="dateFrom" value={filter?.dateFrom} onChange={onFilterChange} />
                        <FormControl id="dateToField" type="date" name="dateTo" value={filter?.dateTo} onChange={onFilterChange} />
                    </InputGroup>
                </Col>
                {showSortBy &&
                    <React.Fragment>
                        <Col md={2} className="pr-0">
                            <FormLabel htmlFor="sortBy" className="float-right label-filter">Sort By</FormLabel>
                        </Col>
                        <Col md={3}>
                            <FormControl id="sortBy" name="sortBy" as="select" value={filter?.sortBy} onChange={onFilterChange}>
                                <option key="" value="">Please Select...</option>
                                <option value="ORDER_NUMBER">Order Number</option>
                                <option value="CUSTOMER_NAME">Customer Name</option>
                                <option value="DATE_CREATED">Date Created</option>
                                {showIssueFilterOption && (<option value="ISSUE">Issue</option>)}
                            </FormControl>
                        </Col>
                    </React.Fragment>}
                {showStatusFilter &&
                    <React.Fragment>
                        <Col md={2} className="pr-0">
                            <FormLabel htmlFor="status" className="float-right label-filter">Status</FormLabel>
                        </Col>
                        <Col md={3}>
                            <FormControl id="status" name="status" as="select" value={filter?.status} onChange={onFilterChange}>
                                <option key="" value="">Please Select...</option>
                                <option value="PROCESSING_FAILED">Processing Failed</option>
                                <option value="CANCELLED">Cancelled</option>
                            </FormControl>
                        </Col>
                    </React.Fragment>}
            </Row>
        </FormGroup>
        <FormGroup>
            <Button variant="primary" className="float-right" type="submit">Apply filter</Button>
            <Button variant="link" className="float-right" name="filterReset" onClick={onClear}>Clear filter</Button>
        </FormGroup>
    </Form>

export default OrderListFilter;