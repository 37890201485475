import React, { Fragment, useState, useEffect, useCallback } from "react";
import Endpoints from '../../../common/Endpoints';
import Header from '../../../common/Header';
import Breadcrumbs from '../../../common/Breadcrumbs';
import LoadingBar from '../../../common/LoadingBar';
import { GET_ASYNC, POST, extractData } from '../../../../Consumer';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { PREFERENCES_SUB_NAV_LIST } from '../../../common/constants';
import { ErrorAlert } from '../../../common/Alert';
import { Actions, GradingActions, GradeDescription, GradeInformation, Grades, ProcessingModal } from './shared/Snippets';

const SUCCESS_MESSAGE = 'Successfully updated your grading actions.'
const ERROR_MESSAGE = 'Error. Something has went wrong. Please try again.';

const breadcrumbs = [
    { text: "Returns", link: "/retailer/preferences/returns" },
    { text: "Grading Actions ", link: null }
];

const EditDefaultGradingPreferences = ({ history }) => {

    //#region State

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [gradeInformation, setGradeInformation] = useState(null);
    const [gradingOptions, setGradingOptions] = useState(null);
    const [currentGrading, setCurrentGrading] = useState(null);
    const [preferences, setPreferences] = useState(null);
    const [modal, setModal] = useState();

    //#endregion

    //#region API

    const fetchGradingActionsOptions = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.RETURNS.GET.OPTIONS);

            if (response?.ok) {
                const data = await response.json();
                setGradingOptions(extractData(data));
            } else {
                setGradingOptions({});
            }
        } catch (error) {
            setGradingOptions({});
        }
    }

    const fetchGradingActions = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.RETURNS.GET.GRADE_PREFERENCES);

            if (response?.ok) {
                const data = await response.json();
                setCurrentGrading(extractData(data).grading);
                setPreferences((prevState) => ({ ...prevState, selection: extractData(data).selection, currentGrading: extractData(data).grading, }));
            } else {
                setCurrentGrading({});
            }
        } catch (error) {
            setCurrentGrading({});
        }
    }

    const fetchGradingInformation = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.RETURNS.GET.INFORMATION);

            if (response?.ok) {
                const data = await response.json();
                setGradeInformation(extractData(data));
            } else {
                setGradeInformation({});
            }
        } catch (error) {
            setGradeInformation({});
        }
    }

    const applyToAllAsync = async () => {
        setModal(true);
        try {
            const response = await POST(Endpoints.RETURNS.POST.GRADING_APPLY_TO_ALL);

            if (response?.ok) {
                await toggleReturnPreferences();
            } else {
                setError(true);
                setModal(false);
            }
        } catch (error) {
            setError(true);
            setModal(false);
        }
    }

    const applyToNewAsync = async () => {
        setModal(true);
        try {
            const response = await POST(Endpoints.RETURNS.POST.GRADING_APPLY_TO_NEW);

            if (response?.ok) {
                await toggleReturnPreferences();
            } else {
                setError(true);
                setModal(false);
            }
        } catch (error) {
            setError(true);
            setModal(false);
        }
    }

    const toggleReturnPreferences = async () => {
        try {
            const data = { grading: preferences.currentGrading, selection: preferences.selection }
            const response = await POST(Endpoints.RETURNS.POST.TOGGLE_GRADING_PREFERENCES, data);

            if (response?.ok) {
                setModal(false);
                if (response && !response.error) {
                    history.push({ pathname: "/retailer/preferences/returns", state: { success: true, successMessage: SUCCESS_MESSAGE } })
                }
                else {
                    history.push({ pathname: "/retailer/preferences/returns", state: { error: true } });
                }
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
        } finally {
            setModal(false);
        }
    }

    const fetchData = useCallback(async () => {
        setLoading(true);
        await Promise.all([fetchGradingActions(), fetchGradingActionsOptions(), fetchGradingInformation()])
        setLoading(false);
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData]);

    //#endregion

    //#region Controls

    const handleGradingActions = (e) => {
        const { id, value } = e.target;

        if (value == '') {
            setPreferences((prevState) => ({ ...prevState, currentGrading, }));
            return
        }

        const targetIndex = currentGrading.findIndex(g => g.grade == id);
        if (targetIndex !== -1) {
            currentGrading[targetIndex].action = value;
            setPreferences((prevState) => ({ ...prevState, currentGrading, }));
        }
    };

    const handleActionChange = (name, value) => { setPreferences((prevState) => ({ ...prevState, [name]: value, })); };

    const handleNextStep = async () => {
        return preferences.selection === "ApplyToAllProducts" ? await applyToAllAsync() : await applyToNewAsync();
    };

    //#endregion

    //#region Render

    return (
        loading
            ? <LoadingBar />
            : <Fragment>
                <Header title="Preferences" subNavList={PREFERENCES_SUB_NAV_LIST} activeKey="Returns" headerClass="mb-2" />
                <Container fluid>
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                    <Row>
                        {error && <ErrorAlert errorMessage={ERROR_MESSAGE} />}
                        <Col sm={12} md={6}>
                            <GradeDescription />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            {currentGrading && gradingOptions && <GradingActions grades={Grades} currentGrading={currentGrading} gradingOptions={gradingOptions} handleInputChange={handleGradingActions} readOnly={false} />}
                        </Col>
                        <Col sm={12} md={6}>
                            {gradeInformation && <GradeInformation information={gradeInformation} />}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            {currentGrading && <Actions selection={preferences.selection} handleInputChange={handleActionChange} />}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            <small>Note: These changes will not affect returns currently in process</small>
                            <Button variant="primary" className="float-right" onClick={handleNextStep}>Save Grading Actions</Button>
                        </Col>
                    </Row>
                </Container>
                <ProcessingModal showModal={modal} title="We're working on it" />
            </Fragment>
    );

    //#endregion

}

export default EditDefaultGradingPreferences;
