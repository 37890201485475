import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button, FormControl, FormLabel, FormGroup } from 'react-bootstrap';
import { GET, PUT, extractData } from '../../../../Consumer';
import FormValidator from '../../../common/FormValidator';
import Endpoints from '../../../common/Endpoints';
import ReturnLink from '../../../common/ReturnLink';
import LoadingBar from '../../../common/LoadingBar';
import { SuccessAlert, ErrorAlert } from '../../../common/Alert';
import ApiKeyCopy from './shared/ApiKeyCopy';
import { SQSForm, SQSFormValidationRules, INVENTORY_READ_AND_WRITE_ACCESS_ID, ORDER_READ_AND_WRITE_ACCESS_ID } from "./shared/SQSForm"

const NO_ACCESS_TEXT = "No Access";

const AppEdit = ({ name, apiKey, permissions, inventorySQSSettings, onPermChange, onSaveClick, copied, onCopyClick, handleInventoryInputChange, inventorySQSSettingsValidation, handleOrdersInputChange, ordersSQSSettings, ordersSQSSettingsValidation }) =>
    <section>
        <p className="title">API Key</p>
        <p className="mb-4">Use this key for your integration</p>
        <p className="mb-1">{name}</p>
        <ApiKeyCopy apiKey={apiKey} copied={copied} onCopyClick={onCopyClick} />
        <p className="title my-3">Permissions</p>
        {permissions.length && permissions.map(permission => {
            const selectedPerm = permission.modulePermissions.find(p => p.access === true);
            const selectedValue = selectedPerm ? selectedPerm.id : "";
            const isInventorySQSEnabled = permission.name == "Stock" && selectedValue == INVENTORY_READ_AND_WRITE_ACCESS_ID;
            const isOrderSQSEnabled = permission.name == "Orders" && selectedValue == ORDER_READ_AND_WRITE_ACCESS_ID;

            return (
                <div className="mb-3" key={permission.name}>
                    <FormLabel htmlFor={permission.name}>{permission.name}</FormLabel>
                    <FormControl id={permission.name} name={permission.name} as="select" value={selectedValue} onChange={(e) => onPermChange(permission.name, e.target.value)}>
                        <option value="">{NO_ACCESS_TEXT}</option>
                        {permission.modulePermissions.length && permission.modulePermissions.map(modulePerm => <option key={modulePerm.id} value={modulePerm.id}>{modulePerm.friendlyName}</option>)}
                    </FormControl>
                    {isInventorySQSEnabled && < SQSForm validator={inventorySQSSettingsValidation} changeHandler={handleInventoryInputChange} sqsSettings={inventorySQSSettings} type="nventory"/>}
                    {isOrderSQSEnabled && < SQSForm validator={ordersSQSSettingsValidation} changeHandler={handleOrdersInputChange} sqsSettings={ordersSQSSettings} type="orders"/>}
                </div>
            )
        })}
        <Button className="float-right mt-med" onClick={onSaveClick}>Save Changes</Button>
    </section>

class EditApp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            id: (this.props.match && this.props.match.params) ? this.props.match.params.id : null,
            app: {},
            loadError: false,
            saveError: false,
            saveSuccess: false,
            copied: false,
            inventorySQSSettingsValidation: this.inventorySQSSettingsValidator.valid(),
            ordersSQSSettingsValidation: this.ordersSQSSettingsValidator.valid()
        };
    }

    inventorySQSSettingsValidator = new FormValidator(SQSFormValidationRules);

    ordersSQSSettingsValidator = new FormValidator(SQSFormValidationRules);

    async componentDidMount() {
        await this.fetchApp();
        this.setState({ loading: false });
    }

    fetchApp = async () => {
        const { id } = this.state;
        return GET(Endpoints.INTEGRATIONS.APP.GET.INTEGRATION + id)
            .then(response => response.json())
            .then(data => {
                const app = extractData(data);
                const loadError = !app;
                this.setState({ app: app, loadError: loadError });

            })
            .catch(error => {
                this.setState({ loadError: true });
                console.log(error); 
            });
    }

    onPermChange = (permName, value) => {
        const { app } = this.state;
        const modulePerms = app.permissions.find(p => p.name === permName).modulePermissions;

        if (value === "") {
            app.permissions.find(p => p.name === permName).modulePermissions = modulePerms.map(mp => ({ ...mp, access: false }));
        } else {
            const modulePermissions = modulePerms.map(mp => { return mp.id.toString() === value ? ({ ...mp, access: true }) : ({ ...mp, access: false }); });
            app.permissions.find(p => p.name === permName).modulePermissions = modulePermissions;
        }

        this.setState({ app: app });
    }

    handleInventoryInputChange = (name, value) => 
        this.setState(prevState => ({ 
            app: { 
                ...prevState.app,
                inventorySQSSettings: {
                    ...prevState.app.inventorySQSSettings,
                    [name]: value
                }
            }
        }));

        handleOrdersInputChange = (name, value) => 
        this.setState(prevState => ({ 
            app: { 
                ...prevState.app,
                ordersSQSSettings: {
                    ...prevState.app.ordersSQSSettings,
                    [name]: value
                }
            }
        }));

    onCopyClick = () => {
        navigator.clipboard.writeText(this.state.app.apiKey);
        this.setState({ copied: true });
    }

    onSaveClick = async () => {
        const { app } = this.state;
        this.setState({ loading: true });

        const isValid = this.handleValidation();

        if (isValid) {
            await PUT(Endpoints.INTEGRATIONS.APP.PUT.INTEGRATION, app)
            .then(response => response.json())
            .then(result => {
                const app = extractData(result);
                const saveError = !app;
                this.setState({ app: app, saveSuccess: !saveError, saveError: saveError, loading: false });
                
            })
            .catch(error => {             
                this.setState({ saveSuccess: false, saveError: true, loading: false });
                console.log(error); 
            });
        }
        else this.setState({ loading: false });
    }

    handleValidation = () => {
        const { app } = this.state

        const inventorySQSSettingsValidation = this.inventorySQSSettingsValidator.validate(app.inventorySQSSettings);
        const ordersSQSSettingsValidation = this.ordersSQSSettingsValidator.validate(app.ordersSQSSettings);

        if (app.inventorySQSSettings.enabled === false) inventorySQSSettingsValidation.isValid = true;
        this.setState({ inventorySQSSettingsValidation: inventorySQSSettingsValidation });

        if (app.ordersSQSSettings.enabled === false) ordersSQSSettingsValidation.isValid = true;
        this.setState({ ordersSQSSettingsValidation: ordersSQSSettingsValidation });

        return inventorySQSSettingsValidation.isValid && ordersSQSSettingsValidation.isValid;
    }

    render() {
        const { loading, app, loadError, saveError, saveSuccess, copied, inventorySQSSettingsValidation, ordersSQSSettingsValidation } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Container fluid>
                    <Row>
                        <Col sm={12} md={6}>
                            <ReturnLink link="/retailer/integrations/apps" text="Return to Integrations" />
                            <h2 className="mb-4">App Details</h2>
                            {loadError
                                ? <ErrorAlert errorMessage="Unable to load app integration. Please try again." />
                                : <Fragment>
                                    {saveSuccess && <SuccessAlert successMessage="Successfully updated app integration." />}
                                    {saveError && <ErrorAlert successMessage="Unable to update app integration. Please try again." />}
                                    <AppEdit name={app.name} apiKey={app.apiKey} permissions={app.permissions} onPermChange={this.onPermChange}
                                        onSaveClick={this.onSaveClick} copied={copied} onCopyClick={this.onCopyClick} handleInventoryInputChange={this.handleInventoryInputChange}
                                        inventorySQSSettings={app.inventorySQSSettings} inventorySQSSettingsValidation={inventorySQSSettingsValidation}
                                        ordersSQSSettingsValidation={ordersSQSSettingsValidation} ordersSQSSettings={app.ordersSQSSettings} handleOrdersInputChange={this.handleOrdersInputChange}/>
                                </Fragment>
                            }
                        </Col>
                    </Row>
                </Container>
        )
    }
}

export default EditApp;
