import React, { useRef, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './DropDownWithFilter.scss';

export const DropDownWithFilter = ({ items, valueField, onItemChange, hasError, initialValue, selectedItem }) => {

    const divHeight = (items.length*48)+'px';
    const buttonRef = useRef();
    const inputRef = useRef();

    const [item, setItem] = useState({ name: initialValue });
    const [inputValue, setInputValue] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [focus, setFocus] = useState(false);
    
    useEffect(() => {

      function handleClickOutside(event) {
        if (buttonRef.current && !buttonRef.current.contains(event.target)) {
            if (event.target !== inputRef.current &&  event.target.className !== 'dropdown-item') {
                setIsActive(false);
                setFocus(false);
            }
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
    
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      }

    }, [buttonRef]);

    useEffect(() => {
      if (focus) {
        inputRef.current.focus();
      }
    }, [focus])
    
    const toggleDropDown = () => {
        setIsActive(!isActive);
        setFocus(!focus);
    }

    const handleChange = ({ target }) => {
        const { value } = target;
        setInputValue(value);
    }

    const handleSelect = (item) => {
        setItem(item);
        toggleDropDown();
        onItemChange(item);
    }

    return (
        <>    
            <div className="dropdown">
                <button ref={ buttonRef } onClick={ toggleDropDown } className={`${ hasError ? 'drop-btn border-danger' : 'drop-btn'}`}><span className='test'>{ selectedItem[valueField] !== '' ? selectedItem[valueField] : item[valueField] }</span><FontAwesomeIcon className='drop-chevron' icon={ faChevronDown } /></button>
                <div id="myDropdown" style={ items.length < 8 ? { height : { divHeight }}: { height : '400px' }} className={`${isActive  ? 'dropdown-content show': 'dropdown-content'}`}>
                    <InputFilter inputRef={ inputRef } inputValue={ inputValue } handleChange={ handleChange }>
                        { items.length > 0 && items.map((item, index) => <a className='dropdown-item' onClick={ () => handleSelect(item) } key={ index }>{ item[valueField] }</a>)}
                    </InputFilter>
                </div>
            </div>
        </>
    )
}

const InputFilter = ({ children, inputRef, inputValue, handleChange }) => {

    return(
        <>
            <input autoFocus ref={ inputRef } type="text" value={ inputValue } placeholder="Type to filter..." id="myInput" onChange={ handleChange } ></input>
            {
                React.Children.toArray(children).filter(
                    child => !inputValue || 
                    child.props.children.toLowerCase().startsWith(inputValue.toLowerCase()) ||
                    child.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                )
            }
        </>
    )
}