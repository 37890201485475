import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { toLocalTimeString } from '../../../../Utilities';
import NoResults from '../../../common/Tables/NoResults';

const TableRow = ({ order, handleViewOrderClick }) =>
    <tr>
        <td>{order.customerReference}</td>
        <td>{order.sourceName}</td>
        <td>{toLocalTimeString(order.createdDate)}</td>
        <td>{order.containsCancelledPartialOrder
            ? <span className="text-status-red">Part Cancellation</span>
            : <span className="text-status-yellow">Awaiting Stock</span>
        }
        </td>
        <td className="text-center">
            <Button className="link-button" variant="secondary" size="sm" onClick={handleViewOrderClick}>View Order</Button>
        </td>
    </tr>

const PartialOrderTable = ({ orders, handleViewOrderClick }) =>
    <Table striped bordered size="sm" className={!orders.length ? "table-empty" : ""}>
        <thead>
            <tr>
                <th>Order Number</th>
                <th>Source</th>
                <th>Date Created</th>
                <th>Status</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {orders.length
                ? orders.map(order => <TableRow key={order.id} order={order} handleViewOrderClick={() => handleViewOrderClick(order.id)} />)
                : <NoResults colSpan="6" text="No orders available." />
            }
        </tbody>
    </Table>

export default PartialOrderTable
