import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, FormLabel, FormControl, Col, Button, Container, Row, Card, Image } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import FormValidator from '../../common/FormValidator';
import { POST } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import Footer from '../../layout/Footer';
import LoadingBar from '../../common/LoadingBar';
import { ErrorAlert } from '../../common/Alert';
import { STRONG_PASSWORD_PATTERN, NUMBER_PATTERN, SPECIAL_CHAR_PATTERN, LENGTH_PATTERN } from '../../common/constants';

import Terms from './terms.pdf';
import Privacy from './privacy.pdf';

const captchaKey = process.env.REACT_APP_CAPTCHA_TOKEN;

const PersonalDetails = ({ registration, handleInputChange, validation, handlePasswordInputChange, handleShowHidePassword, passwordInputType, passwordInputIcon, passwordChecks }) =>
    <Fragment>
        <h5 className="mt-2">Personal Details</h5>
        <FormGroup>
            <FormLabel htmlFor="titleField">Title</FormLabel>
            <FormControl id="titleField" type="text" maxLength="10" name="title" value={registration.title} onChange={(e) => handleInputChange("title", e.target.value)} />
            <span className="text-danger">{validation.title.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="forenameField">Forename</FormLabel>
            <FormControl id="forenameField" type="text" maxLength="50" name="forename" value={registration.forename} onChange={(e) => handleInputChange("forename", e.target.value)} />
            <span className="text-danger">{validation.forename.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="surnameField">Surname</FormLabel>
            <FormControl id="surnameField" type="text" maxLength="50" name="surname" value={registration.surname} onChange={(e) => handleInputChange("surname", e.target.value)} />
            <span className="text-danger">{validation.surname.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="emailField">Email</FormLabel>
            <FormControl id="emailField" type="email" maxLength="50" name="email" value={registration.email} onChange={(e) => handleInputChange("email", e.target.value)} />
            <span className="text-danger">{validation.email.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="phoneField">Phone</FormLabel>
            <FormControl id="phoneField" type="tel" maxLength="50" name="phone" value={registration.phone} onChange={(e) => handleInputChange("phone", e.target.value)} />
            <span className="text-danger">{validation.phone.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="passwordField">Password</FormLabel>
            <FormControl id="passwordField" type={passwordInputType} maxLength="50" name="password" value={registration.password} onChange={(e) => handlePasswordInputChange(e.target.value)} />
            <span className="form-password-icon" onClick={handleShowHidePassword}><FontAwesomeIcon icon={passwordInputIcon} /></span>
            <span className="text-danger">{validation.password.message}</span>
        </FormGroup>
        <div className="form-password-checklist">
            <ul>
                <li className={passwordChecks.number ? "pass" : "fail"}>One number</li>
                <li className={passwordChecks.specialChar ? "pass" : "fail"}>One special character</li>
                <li className={passwordChecks.length ? "pass" : "fail"}>Six characters long</li>
            </ul>
        </div>
        <FormGroup>
            <FormLabel htmlFor="confirmPasswordField">Confirm Password</FormLabel>
            <FormControl id="confirmPasswordField" type="password" maxLength="50" name="confirmPassword" value={registration.confirmPassword} onChange={(e) => handleInputChange("confirmPassword", e.target.value)} />
            <span className="text-danger">{validation.confirmPassword.message}</span>
        </FormGroup>
    </Fragment>

const CompanyDetails = ({ companyName, handleInputChange, validation }) =>
    <Fragment>
        <h5 className="mt-2">Company Details</h5>
        <FormGroup>
            <FormLabel htmlFor="companyNameField">Company Name</FormLabel>
            <FormControl id="companyNameField" type="text" name="companyName" maxLength="50" value={companyName} onChange={(e) => handleInputChange("companyName", e.target.value)} />
            <span className="text-danger">{validation.companyName.message}</span>
        </FormGroup>
    </Fragment>

const BillingAddress = ({ registration, handleInputChange, validation }) =>
    <Fragment>
        <h5 className="mt-2">Billing Address</h5>
        <FormGroup>
            <FormLabel htmlFor="line1Field">Address Line 1</FormLabel>
            <FormControl id="line1Field" type="text" name="line1" maxLength="50" value={registration.line1} onChange={(e) => handleInputChange("line1", e.target.value)} />
            <span className="text-danger">{validation.line1.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="line2Field">Address Line 2 <span className="form-label-optional">- optional</span></FormLabel>
            <FormControl id="line2Field" type="text" name="line2" maxLength="50" value={registration.line2} onChange={(e) => handleInputChange("line2", e.target.value)} />
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="townField">Town</FormLabel>
            <FormControl id="townField" type="text" name="town" maxLength="50" value={registration.town} onChange={(e) => handleInputChange("town", e.target.value)} />
            <span className="text-danger">{validation.town.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="countyField">County</FormLabel>
            <FormControl id="countyField" type="text" name="county" maxLength="50" value={registration.county} onChange={(e) => handleInputChange("county", e.target.value)} />
            <span className="text-danger">{validation.county.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="countryField">Country</FormLabel>
            <FormControl id="countryField" type="text" name="country" maxLength="50" value={registration.country} onChange={(e) => handleInputChange("country", e.target.value)} />
            <span className="text-danger">{validation.country.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="postcodeField">Postcode</FormLabel>
            <FormControl id="postcodeField" type="text" name="postcode" maxLength="50" value={registration.postcode} onChange={(e) => handleInputChange("postcode", e.target.value)} />
            <span className="text-danger">{validation.postcode.message}</span>
        </FormGroup>
    </Fragment>

const LegalsAndPrivacy = ({ tsandcs, handleInputChange, validation }) =>
    <Fragment>
        <h5 className="mt-2">Legals and Privacy</h5>
        <FormGroup className="custom-control custom-checkbox mb-1">
            <input className="custom-control-input" id="tsandcsField" type="checkbox" name="tsandcs" checked={tsandcs} onChange={(e) => handleInputChange("tsandcs", e.target.checked)} />
            <FormLabel className="custom-control-label" htmlFor="tsandcsField">
                <span>I agree to Selazar's <a href={Terms} target='_blank' rel="noopener noreferrer" alt='Selazar Terms and Conditions - opens in a new window'>Terms and Conditions</a> and <a href={Privacy} target='_blank' rel="noopener noreferrer" alt='Selazar Privacy Policy - opens in a new window'>Privacy Policy</a></span>
            </FormLabel>
        </FormGroup>
        <span className="text-danger">{validation.tsandcs.message}</span>
    </Fragment>

const GoogleRecaptcha = ({ handleRecaptcha, recaptchaError }) =>
    <Fragment>
        <Container className="d-flex justify-content-center my-4">
            <ReCAPTCHA sitekey={captchaKey} onChange={handleRecaptcha} />
        </Container>
        {recaptchaError && <p className="text-danger">Please complete captcha</p>}
    </Fragment>

const RegistrationFrom = ({ handleSubmit, errorMessage, registration, handleInputChange, validation, handlePasswordInputChange, handleShowHidePassword, passwordInputType, passwordInputIcon, passwordChecks, handleRecaptcha, recaptchaError }) =>
    <Form onSubmit={handleSubmit}>
        {errorMessage && <ErrorAlert errorMessage={errorMessage} />}
        <CompanyDetails companyName={registration.companyName} handleInputChange={handleInputChange} validation={validation} />
        <PersonalDetails registration={registration} handleInputChange={handleInputChange} validation={validation} handlePasswordInputChange={handlePasswordInputChange} handleShowHidePassword={handleShowHidePassword} passwordInputType={passwordInputType} passwordInputIcon={passwordInputIcon} passwordChecks={passwordChecks} />
        <BillingAddress registration={registration} handleInputChange={handleInputChange} validation={validation} />
        <LegalsAndPrivacy tsandcs={registration.tsandcs} handleInputChange={handleInputChange} validation={validation} />
        <GoogleRecaptcha handleRecaptcha={handleRecaptcha} recaptchaError={recaptchaError} />
        <Button size="lg" type="submit" value="Register" block>Register</Button>
        <Container className="d-flex justify-content-center mt-4">
            <p>Already registered? <Link to='/'>Sign in?</Link></p>
        </Container>
    </Form>

class RetailerRegistration extends Component {

    validator = new FormValidator([
        {
            field: 'companyName',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your company name'
        },
        {
            field: 'title',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your title'
        },
        {
            field: 'forename',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your forename'
        },
        {
            field: 'surname',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your surname'
        },
        {
            field: 'email',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your email'
        },
        {
            field: 'phone',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your phone number'
        },
        {
            field: 'password',
            method: 'matches',
            args: [STRONG_PASSWORD_PATTERN],
            validWhen: true,
            message: 'Password must contain at least one number, one special character and at least 6 characters'
        },
        {
            field: 'password',
            method: 'isLength',
            args: [{min: 6, max: 50}],
            validWhen: true,
            message: 'Password must be between 6 and 50 characters long'
        },
        {
            field: 'confirmPassword',
            method: 'isEmpty',
            validWhen: false,
            message: 'Confirm Password is required'
        },
        {
            field: 'line1',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your address line 1'
        },
        {
            field: 'town',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your town'
        },
        {
            field: 'county',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your county'
        },
        {
            field: 'country',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your country'
        },
        {
            field: 'postcode',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your postcode'
        },
        {
            field: 'tsandcs',
            method: 'isBoolean',
            validWhen: true,
            message: 'Please confirm that you have read and agree to the Terms and Conditions and Privacy Policy'
        }
    ]);

    state = {
        registration: {
            companyName: '',
            line1: '',
            line2: '',
            town: '',
            county: '',
            country: '',
            postcode: '',
            longitude: 0,
            latitude: 0,
            title: '',
            forename: '',
            surname: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: '',
            tsandcs: false
        },
        passwordInputType: 'password',
        passwordInputIcon: faEye,
        passwordChecks: {
            number: false,
            specialChar: false,
            length: false
        },
        recaptcha: false,
        validation: this.validator.valid(),
        recaptchaError: false,
        errorMessage: null,
        loading: false
    };

    validateForm = (registration) => {
        let validation = this.validator.validate(registration);

        if (!registration.tsandcs) {
            validation.isValid = false;
            validation.tsandcs.isInvalid = true;
            validation.tsandcs.message = "Please confirm that you have read and agree to the Terms and Conditions and Privacy Policy";
        }

        return validation;
    }

    handleRecaptcha = () => this.setState({ recaptcha: true });

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const { registration, recaptcha } = this.state;

        const validation = this.validateForm(registration);
        this.setState({ validation: validation, recaptchaError: !recaptcha });

        if (recaptcha && validation.isValid) {
            return POST(Endpoints.REGISTRATION.POST.RETAILER.REGISTER, registration)
                .then(response => response.json())
                .then(result => {
                    result.error 
                        ? this.setState({ errorMessage: result.message, loading: false, recaptcha: false }) 
                        : this.props.history.push("/register/result");
                })
                .catch(error => {                    
                    this.setState({errorMessage: error.message, loading: false, recaptcha: false });
                    console.log(error);
                });

        } else this.setState({ loading: false });
    }

    handleInputChange = (name, value) => this.setState(prevState => ({ registration: { ...prevState.registration, [name]: value } }));

    handlePasswordInputChange = (value) => {
        const numberPass = NUMBER_PATTERN.test(value);
        const specialCharPass = SPECIAL_CHAR_PATTERN.test(value);
        const lengthPass = LENGTH_PATTERN.test(value);

        this.setState(prevState => ({
            passwordChecks: {
                number: numberPass,
                specialChar: specialCharPass,
                length: lengthPass
            },
            registration: {
                ...prevState.registration,
                password: value
            }
        }));
    }

    handleShowHidePassword = () => this.setState(prevState => ({
        passwordInputType: prevState.passwordInputType === "password"
            ? "text"
            : "password",
        passwordInputIcon: prevState.passwordInputType === "password"
            ? faEyeSlash
            : faEye
    }));

    render() {
        const { loading, errorMessage, registration, validation, passwordInputType, passwordInputIcon, passwordChecks, recaptchaError } = this.state;

        return (
            <Fragment>
                {loading
                    ? <LoadingBar />
                    : <Container className="h-100">
                        <Row className="justify-content-center row-form-adjustedheight">
                            <Col md={6} className="align-self-center py-5">
                                <Card className="p-5">
                                    <Image src='Images/logo.png' className="mx-auto mb-4 logo" />
                                    <p className="text-center">Register with Selazar and start selling with a straight forward and cost effective ecommerce platform.</p>
                                    <RegistrationFrom
                                        handleSubmit={this.handleSubmit}
                                        errorMessage={errorMessage}
                                        registration={registration}
                                        handleInputChange={this.handleInputChange}
                                        validation={validation}
                                        handlePasswordInputChange={this.handlePasswordInputChange}
                                        handleShowHidePassword={this.handleShowHidePassword}
                                        passwordInputType={passwordInputType}
                                        passwordInputIcon={passwordInputIcon}
                                        passwordChecks={passwordChecks}
                                        handleRecaptcha={this.handleRecaptcha}
                                        recaptchaError={recaptchaError}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Footer />
                    </Container>
                }
            </Fragment>
        );
    }
}
export default RetailerRegistration;