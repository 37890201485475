import React from 'react';
import { SuccessAlert } from '../../../../common/Alert';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const Success = ({ name }) =>
    <React.Fragment>
        <SuccessAlert successMessage={`You have successfully added ${name} to your items`}></SuccessAlert>
        <Link to={'/supplier/products'} className="link-button">
            <Button variant="primary" className="float-right">Continue to Stock Items</Button>
        </Link>
        <Button variant="secondary" onClick={() => window.location.reload()} className="float-right mr-2">Add Another Item</Button>
    </React.Fragment>

export default Success;