import React, { Component } from 'react';
import { Button, Card } from 'react-bootstrap';
import { ErrorAlert, SuccessAlert } from '../../../common/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

class CsvConfirmation extends Component {

    renderUploadResult = (results) => {
        const errorMessages = results.filter((result, i, self) =>
            self.findIndex(res => res.fileName === result.fileName && result.isError === true) === i).map(result => {
                const errors = result.message ? result.message.split(',') : [];
                if (result.isMalformed) {
                    return (<React.Fragment>
                        <p>Filename: {result.fileName}</p>
                        <p>Error detected with formatting</p>
                    </React.Fragment>);
                } else if (result.message && errors.length > 0) {
                    return (<React.Fragment>
                        <p>Filename: {result.fileName}</p>
                        {errors.map((e, i) => <p key={i}>{e}</p>)}
                    </React.Fragment>);
                } else if (result.message) {
                    return (<React.Fragment>
                        <p>Filename: {result.fileName}</p>
                        <p>{result.message}</p>
                    </React.Fragment>);
                }
            });

        const successMessages = results.filter(f => f.isError === false).map(result => {
            if (!result.isError) {
                return <p>{result.fileName} uploaded successfully.</p>
            }
        });

        const errorCard = errorMessages.length ?
            <ErrorAlert errorMessage={errorMessages.map((e, i) => <div className="pb-2" key={`${i}-error`}>{e}</div>)} />
            : null;

        const successCard = successMessages.length ?
            <SuccessAlert successMessage={successMessages.map((e, i) => <div className="pb-2" key={`${i}-success`}>{e}</div>)} />
            : null;

        return (<React.Fragment>{errorCard}{successCard}</React.Fragment>);
    }

    displayValidation = (errors) => {
        const errorMessage = <React.Fragment>
            {errors.map((e, i) => e && e.length 
                ? <React.Fragment key={i}><p className="mb-0">{e}</p></React.Fragment> 
                : null)}
        </React.Fragment>;

        return <ErrorAlert errorMessage={errorMessage} />;
    }

    render() {
        const { data, results, files, handleEditStep, palletCount, errors } = this.props;
        const validationErrorsAlert = errors.length > 0 ? this.displayValidation(errors) : null;
        return (
            <React.Fragment>
                {this.renderUploadResult(results)}
                {validationErrorsAlert}
                <h5 className="mt-4">Confirmation</h5>

                <div className="section-item mt-3">
                    <h6>File Upload</h6>
                    <div className="file-list mt-4">
                        <ul>
                            {files.map((f, i) => <li key={i}><FontAwesomeIcon icon={faFile} className="form-fileicon" />{f.name} - {f.size} bytes <FontAwesomeIcon icon={faCheckCircle} className="form-fileicon float-right" /></li>)}
                        </ul>
                    </div>
                    <Button variant="link" className="p-0" onClick={() => handleEditStep(0)}>Edit Inventory Details</Button>
                </div>
                <div className="section-item mt-3">
                    <h6>Inventory Details</h6>
                    <p className="title">Is this a pallet?</p>
                    <p>{data.isPallet === "true" ? "Yes" : "No"}</p>
                    <p className="title">Repeat Consignments</p>
                    <p>{palletCount}</p>
                    {data.isPallet === "false" &&
                        <React.Fragment>
                            <p className="title">Dimensions (cm)</p>
                            <p>{data.boxHeight} x {data.boxWidth} x {data.boxDepth}</p>
                            <p className="title">Weight (kg)</p>
                            <p>{data.boxWeight}</p>
                        </React.Fragment>
                    }
                </div>
                <div className="section-item mt-3">
                    <h6>Shipping & Labelling</h6>
                    <p className="title">Shipping Reference</p>
                    <p>{data.shippingReference ? data.shippingReference : "N/A"}</p>
                    <p className="title">Labelling</p>
                    <p>{data.supplierPrintedLabels === "true" ? "No, I will affix all labels" : "Yes, affix labels for me"}</p>
                    <Button variant="link" className="p-0" onClick={() => handleEditStep(1)}>Edit Shipping & Labelling</Button>
                </div>
            </React.Fragment>
        )
    }
}

export default CsvConfirmation;