import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './Stepper.scss';

const StepperButtons = ({ cancelLink, handleBack, showBack = false, handleNext, showNext = false, nextButtonText = "Next Step", cssClass = "" }) => 
    <div className={`mt-4 ${cssClass}`}>
        {showBack && <div className="float-left">
            <Button variant="secondary" onClick={handleBack}>Back</Button>
        </div>}
        <div className="float-right">
            <Link to={cancelLink} className="link-button">
                <Button variant="link" className="button-nav-link mr-3">Cancel</Button>
            </Link>
            {showNext && <Button variant="primary" onClick={handleNext}>{nextButtonText}</Button>}
        </div>
    </div>


export default StepperButtons;
