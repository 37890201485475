import React, { Fragment, useState, useEffect, useCallback } from "react";
import Endpoints from '../../../common/Endpoints';
import LoadingBar from '../../../common/LoadingBar';
import { Link } from 'react-router-dom';
import { GET_ASYNC, extractData } from '../../../../Consumer';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { ErrorAlert } from '../../../common/Alert';
import { COUNTRY_LIST } from '../../../common/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faImage } from '@fortawesome/free-solid-svg-icons';

import './EditProduct.scss'
import ProductHeader from "../ProductHeader";

const PRODUCT_ERROR_MESSAGE = "There was a problem retrieving the product. Please try again.";

const ProductOverview = ({ id, name, description, brand, category = {}, pageIndex }) => (
    <div className="section-item mt-3">
        <h6>Product Overview</h6>
        <p className="title">Product Name</p>
        <p>{name}</p>
        <p className="title">Product Description</p>
        <p>{description}</p>
        <p className="title">Brand</p>
        <p>{brand}</p>
        <p className="title">Category</p>
        <p>{category ? category.name : "N/A"}</p>
        <Link to={`/supplier/products/edit/overview/${id}/${pageIndex}`} className="link-button">
            <Button variant="link" className="p-0">Edit Product Overview</Button>
        </Link>
    </div>
);

const ProductDetails = ({ id, dimensions, weight, country = {}, commodityCode, pageIndex }) => (
    <div className="section-item">
        <h6>Product Details</h6>
        <p className="title">Dimensions (cm)</p>
        <p>{dimensions}</p>
        <p className="title">Weight (kg)</p>
        <p>{weight}</p>
        <p className="title">Country of Manufacturing</p>
        <p>{country ? country.name : "N/A"}</p>
        <p className="title">Commodity Code</p>
        <p>{commodityCode}</p>
        <Link to={`/supplier/products/edit/details/${id}/${pageIndex}`} className="link-button">
            <Button variant="link" className="p-0" >Edit Product Details</Button>
        </Link>
    </div>
);

const Pricing = ({ id, price, expectedWeeklySales, pageIndex }) => (
    <div className="section-item">
        <h6>{"Sales & Pricing"}</h6>
        <p className="title">Product Price</p>
        <p>{`£${parseFloat(price).toFixed(2)}`}</p>
        <p className="title">Expected Weekly Sales</p>
        <p>{expectedWeeklySales}</p>
        <Link to={`/supplier/products/edit/pricing/${id}/${pageIndex}`} className="link-button">
            <Button variant="link" className="p-0" >{"Edit Sales & Pricing"}</Button>
        </Link>
    </div>
);

const UniqueIdentifiers = ({ id, ean, skus = [], suid, noScan, batch, itemImages = [], pageIndex }) => (
    <div className="section-item">
        <h6>Unique Identifiers</h6>
        <p className="title">EAN</p>
        <p>{ean ? ean : "No EAN entered"}</p>
        <p className="title">SKUs</p>
        <div className="mb-3">
            {skus.length > 0 ? skus.map((f, i) => <p className="mb-0" key={`sku-${i}`}>{f.code}</p>) : <p className="mb-0">No SKUs entered</p>}
        </div>
        <p className="title">SUID</p>
        <p>{suid ? suid : "No SUID generated"}</p>
        <p className="title">Batch management</p>
        <p>{batch ? "Yes, this product is handled in batches" : "No, this product is not handled in batches"}</p>
        <p className="title">No-Barcode Product</p>
        <p>{noScan ? "No-barcode product" : "Barcoded product"}</p>
        <p className="title">Product Image</p>
        {skus.length > 0 ?
            skus.filter(s => s.itemImage !== null).length > 0 ?
                <div className="form-image-list mt-2">
                    <ul>
                        {skus.filter(s => s.itemImage !== null).map((sku, i) => <li key={i}><FontAwesomeIcon icon={faImage} className="form-fileicon" />{sku.itemImage.fileName}</li>)}
                    </ul>
                </div> : <p>You do not have an image, you can upload one at anytime.</p>
            : itemImages.length > 0 ? <div className="form-image-list mt-2">
                <ul>
                    {itemImages.map((image, i) => <li key={i}><FontAwesomeIcon icon={faImage} className="form-fileicon" />{image.fileName}</li>)}
                </ul>
            </div> : <p>You do not have an image, you can upload one at anytime.</p>}
        <Link to={`/supplier/products/edit/uniqueidentifiers/${id}/${pageIndex}`} className="link-button">
            <Button variant="link" className="p-0">Edit Unique Identifiers</Button>
        </Link>
    </div>
);

const PackagingPreferences = ({ id, isPrePacked, requiresBoxPackaging, requiresPaperPackaging, isFragile, pageIndex }) => (
    <div className="section-item">
        <h6>Packaging Preferences</h6>
        <p className="title">Is this product pre-packed?</p>
        <p>{isPrePacked ? "Yes, it is pre-packed" : "No, it is not pre-packed"}</p>
        <p className="title">Is this product box packed?</p>
        <p>{requiresBoxPackaging ? "Yes, it is box packed" : "No, it is not box packed"}</p>
        <p className="title">Packaging Preference</p>
        <p>
            {requiresPaperPackaging ? "Paper packaging" : "No packaging preference"}
        </p>
        <p className="title">Is this product fragile</p>
        <p>{isFragile ? "Yes, it is fragile" : "No, it is not fragile"}</p>
        <Link to={`/supplier/products/edit/packagingpreferences/${id}/${pageIndex}`} className="link-button">
            <Button variant="link" className="p-0">Edit Packaging Preferences</Button>
        </Link>
    </div>
);

const ReturnPreferences = ({ id, isReturnable, grades, pageIndex, disabled }) => (
    <div className={`section-item ${disabled && "disabled-container"}`}>
        <h6>Return Information</h6>
        <p className="title">Is this item returnable?</p>
        <p>{isReturnable ? "Yes" : "No"}</p>
        {grades && grades.length && isReturnable && grades.map((g) =>
            <Fragment>
                <div className="mt-4">
                    <p className="title">Grade</p>
                    <p>{g.grade}</p>
                    <p className="title">Action</p>
                    <p>{g.action}</p>
                </div>
            </Fragment>)
        }
        {disabled || 
        <Link to={`/supplier/products/edit/returnpreferences/${id}/${pageIndex}`} className="link-button">
            <Button variant="link" className="p-0">Edit Returns Preferences</Button>
        </Link>}
    </div>
);

const AdditionalInformation = ({ pickFromPallet }) => (
    <div className="section-item">
        <h6>Additional Information</h6>
        {pickFromPallet &&
            <Fragment>
                <p className="title">Pick from Pallet</p>
                <p>We have detected you are uploading a large product. These products will be kept on pallets and picked directly from them, providing you with more cost-effective storage.</p>
            </Fragment>
        }
    </div>
);

const EditProduct = ({ match }) => {

    const user = JSON.parse(localStorage.getItem('user'));

    //#region State

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [companyItemID] = useState((match && match.params) ? match.params.id : null);
    const [pageIndex] = useState(match.params.pageIndex || 1);
    const [categories, setCategories] = useState(null);
    const [companyItem, setCompanyItem] = useState({ companyID: user.companyID });
    const [returnInformation, setReturnInformation] = useState(null);
    const [retailerReturnConfiguration, setRetailerReturnConfiguration] = useState(null);

    //#endregion

    //#region API

    const getCompanyItem = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.STOCK.GET.BY_ID + companyItemID);

            if (response?.ok) {
                const data = await response.json();
                setCompanyItem(extractData(data));
                setError(extractData(companyItem ? null : PRODUCT_ERROR_MESSAGE));
            } else {
                setCompanyItem({});
                setError({});
            }
        } catch (error) {
            setCompanyItem(null);
            setError(PRODUCT_ERROR_MESSAGE);
        }
    }

    const getCategories = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.CATEGORY.GET.ALL);

            if (response?.ok) {
                const data = await response.json();
                const categories = Array.isArray(extractData(data)) ? extractData(data) : [];
                setCategories(categories);
            } else {
                setCategories({});
            }
        } catch (error) {
            setCategories({});
        }
    }

    const getReturnInformation = async () => {
        try {

            const response = await GET_ASYNC(Endpoints.STOCK.GET.GET_RETURN_INFO_BY_ID + companyItemID);

            if (response?.ok) {
                const data = await response.json();
                const extractReturnData = extractData(data);
                setReturnInformation({ returnable: extractReturnData.returnable, grades: extractReturnData.grading });
            } else {
                setReturnInformation({});
            }
        } catch (error) {
            setReturnInformation(null);
        }
    }

    const fetchRetailerReturnConfiguration = async () => {
        try {
          const response = await GET_ASYNC(Endpoints.RETURNS.GET.CONFIGURATION);
    
          if (response?.ok) {
            const data = await response.json();
            setRetailerReturnConfiguration(extractData(data));
          } else {
            setRetailerReturnConfiguration({});
          }
        } catch (error) {
          setRetailerReturnConfiguration({});
        }
      }

    const fetchData = useCallback(async () => {
        setLoading(true);
        await Promise.all([getCompanyItem(), getReturnInformation(), getCategories(), fetchRetailerReturnConfiguration()])
        setLoading(false);
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData]);

    //#endregion

    //#region Render

    const category = () => companyItem !== null ? categories.find(c => c.id === companyItem.item.categoryID) : null;
    const country = () => companyItem !== null ? COUNTRY_LIST.find(c => c.code === companyItem.item.countryCodeOfManufacture) : null;
    const disableControls = () => retailerReturnConfiguration ? !retailerReturnConfiguration.enabled : true;

    return (
        <Fragment>
            <ProductHeader activeKey="Products" headerClass="mb-0" />
            <Container fluid>
                <Row>
                    <Col sm={12} md={6}>
                        <Link to={`/supplier/products/list/${pageIndex}`} className="link-button">
                            <Button variant="link" className="button-nav-return p-0"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Products</Button>
                        </Link>
                        {loading ? <LoadingBar /> :
                            <Fragment>
                                {error
                                    ? <div className="mt-3">
                                        <ErrorAlert errorMessage={error} />
                                    </div>
                                    : <Fragment>
                                        <ProductOverview id={companyItem.id} name={companyItem.item.name} description={companyItem.item.description} brand={companyItem.item.brand} category={category()} pageIndex={pageIndex} />
                                        <ProductDetails id={companyItem.id} dimensions={`${companyItem.item.height} x ${companyItem.item.width} x ${companyItem.item.depth}`} weight={companyItem.item.weight} country={country()} commodityCode={companyItem.item.commodityCode} pageIndex={pageIndex} />
                                        <Pricing id={companyItem.id} price={companyItem.currentPrice.price} expectedWeeklySales={companyItem.expectedWeeklySales} pageIndex={pageIndex} />
                                        <UniqueIdentifiers id={companyItem.id} ean={companyItem.item.ean} batch={companyItem.item.batch} skus={companyItem.stockKeepingUnits} suid={companyItem.item.selazarUniqueID} noScan={companyItem.noScan} itemImages={companyItem.item.itemImages} pageIndex={pageIndex} />
                                        <PackagingPreferences id={companyItem.id} isPrePacked={companyItem.isPrePacked} requiresBoxPackaging={companyItem.requiresBoxPackaging} requiresPaperPackaging={companyItem.requiresPaperPackaging} isFragile={companyItem.isFragile} pageIndex={pageIndex} />
                                        <ReturnPreferences id={companyItem.id} isReturnable={returnInformation.returnable} grades={returnInformation.grades} pageIndex={pageIndex} disabled={disableControls()} />
                                        {companyItem.pickFromPallet && <AdditionalInformation pickFromPallet={companyItem.pickFromPallet} />}
                                    </Fragment>}
                            </Fragment>}
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );

    //#endregion

}

export default EditProduct;