import React from 'react'
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserForm = ({ handleSubmit, user, handleInputChange, validation, handleShowHidePassword, passwordInputType, passwordInputIcon, passwordChecks, showRoles, roleOptions, validationMessages }) =>
    <Form onSubmit={handleSubmit}>
        <fieldset>
            <h5>Personal Information</h5>
            <FormGroup>
                <FormLabel htmlFor="forename">Forename</FormLabel>
                <FormControl id="forename" type="text" name="forename" value={user.forename} onChange={handleInputChange} />
                <span className="text-danger">{validation.forename.message}</span>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor="surname">Surname</FormLabel>
                <FormControl id="surname" type="text" name="surname" value={user.surname} onChange={handleInputChange} />
                <span className="text-danger">{validation.surname.message}</span>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor="email">Email</FormLabel>
                <FormControl id="email" type="email" name="email" autoComplete="off" value={user.email} onChange={handleInputChange} />
                <span className="text-danger">{validation.email.message}</span>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor="password">Password</FormLabel>
                <FormControl id="password" type={passwordInputType} name="password" autoComplete="new-password" value={user.password} maxLength="50" onChange={handleInputChange} />
                <span className="form-password-icon" onClick={handleShowHidePassword}><FontAwesomeIcon icon={passwordInputIcon} /></span>
                <span className="text-danger">{validation.password.message}</span>
            </FormGroup>
            <div className="form-password-checklist">
                <ul>
                    <li className={passwordChecks.number ? "pass" : "fail"}>One number</li>
                    <li className={passwordChecks.specialChar ? "pass" : "fail"}>One special character</li>
                    <li className={passwordChecks.length ? "pass" : "fail"}>Six characters long</li>
                </ul>
            </div>
        </fieldset>
        {showRoles && Object.keys(roleOptions).map((k) =>
            <div key={k} className="py-2">
                <h5 className="pb-2">{k} Roles</h5>
                {roleOptions[k].map((r) =>
                    <FormGroup key={r.id} className="custom-control custom-checkbox mb-1">
                        <input className="custom-control-input" id={r.id} type="checkbox" name={r.id} onChange={handleInputChange} checked={user.roles.indexOf(r.id) > -1} />
                        <FormLabel className="custom-control-label" htmlFor={r.id}>{r.name}</FormLabel>
                    </FormGroup>
                )}
            </div>
        )}
        {validationMessages.roles && <span className="text-danger">Select a Role</span>}
        <FormGroup className="mt-med">
            <Button variant="primary" className="float-right" type="submit">Submit</Button>
        </FormGroup>
    </Form>

export default UserForm;
