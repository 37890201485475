import React, { useRef, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './DropDownWithFilterAndDetails.scss';

export const DropDownWithFilterAndDetails = ({ items, valueField, onItemChange, selectedItem, initialValue}) => {

  useEffect(() => {
    setHeight(items.length > 3 ? '148' : items.length * 50);
  }, [items]);

  const divHeight = items.length < 8 ? (items.length * 48) : '400';

  const buttonRef = useRef();
  const inputRef = useRef();
  const myDropdown = useRef();
  const containerOptions = useRef([]);
  const titleOption = useRef([]);
  const subTitleOption = useRef([]);
  
  const [inputValue, setInputValue] = useState('');
  const [isActive, setIsActive] = useState(false);  
  const [height, setHeight] = useState(divHeight);

  const heightStyle = {
    height: items.length > 3 ? height + 'px' : 'fit-content'
  };

  useEffect(() => {

    function handleClickOutside(event) {
      if (buttonRef.current && !buttonRef.current.contains(event.target) && event.target !== myDropdown.current 
      &&  !containerOptions.current.includes(event.target) 
      &&  !titleOption.current.includes(event.target)  &&  !subTitleOption.current.includes(event.target) ) {
        if (event.target !== inputRef.current && event.target.className !== 'dropdown-item') {
          setIsActive(false);          
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    }

  }, [buttonRef]);

  const toggleDropDown = ({ target }) => {
    const { value } = target;
    if(value.length > 0) setIsActive(true);
    if(value.length === 0) setIsActive(false);
    setInputValue(value);
  }

  const handleSelect = (item) => {
    setInputValue(selectedItem ? item.name : "");
    setIsActive(false);
    onItemChange(item);
  }

  const handleClick = () => setIsActive(true);

  const formatAddressDetails = (item) => {
    const addressLine2 = item.addressLine2.length > 0 ? `${item.addressLine2}, ` : '';
    return `${item.addressLine1}, ${addressLine2} ${item.county}, ${item.town}, ${item.postCode}`
  }

  return (
    <>
      <div className="dropdown">
        <div className='drop-btn'>
            <input ref={buttonRef} value={inputValue} placeholder={initialValue} onChange={toggleDropDown} onClick={handleClick} className= 'input-search'></input>
            <FontAwesomeIcon className='search-icon mr-2' icon={faChevronDown} onClick={handleClick}></FontAwesomeIcon>
        </div>
        <div ref={myDropdown} id="myDropdown" style={heightStyle} className={`${isActive ? 'dropdown-content show': 'dropdown-content'}`}>
          <Values setHeight={setHeight} inputValue={inputValue}>
            {items.length > 0 && items.map((item, index) => 
                <div ref={(element) => { containerOptions.current[index] = element}} className='dropdown-div-item' onClick={() => handleSelect(item)} key={index} >
                    <span ref={(element) => { titleOption.current[index] = element}} onClick={() => handleSelect(item)} name={item.name}>{item[valueField] + ' - ' + item.companyName }</span>
                    <span ref={(element) => { subTitleOption.current[index] = element}} onClick={() => handleSelect(item)} className='sub-details-drop-down'>{formatAddressDetails(item)}</span>
                </div>)}
          </Values>
        </div>
      </div>
    </>
  )
}

const validateResults = (results) => results.length > 0 ? results : "No results found";

const Values = ({ children, setHeight, inputValue }) => {
  return (
    <>
      {        
        validateResults(React.Children.toArray(children).filter(
          child => {
            const value = inputValue.toLowerCase();
            return (
              !inputValue ||
              child.props.children[0].props.children.toLowerCase().startsWith(value) ||
              child.props.children[0].props.children.toLowerCase().indexOf(value) > -1 ||
              child.props.children[0].name?.toLowerCase().startsWith(value) ||
              child.props.children[0].name?.toLowerCase().indexOf(value) > -1 
              ||
              child.props.children[1].props.children.toLowerCase().startsWith(value) ||
              child.props.children[1].props.children.toLowerCase().indexOf(value) > -1 ||
              child.props.children[1].name?.toLowerCase().startsWith(value) ||
              child.props.children[1].name?.toLowerCase().indexOf(value) > -1
            );
          }
        ), setHeight)
      }
    </>
  )
}