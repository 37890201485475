import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Confirm from '../common/Confirm';

import './Header.scss';

class Header extends Component {

  state = {
    showLogoutModal: false
  };

  logout = () => {
    this.handleShowHideLogout();
    this.props.onLogout();
  }

  handleShowHideLogout = () => this.setState((prevState) => ({ showLogoutModal: !prevState.showLogoutModal }));

  render() {

    return (
      <Navbar className="shadow-sm fixed-top">
        <Navbar.Brand href="/">
          <img alt="Selazar Logo" src="/Images/headerselazarsogo.svg" className="d-inline-block align-top" />
        </Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link href="https://support.selazar.com" target="_blank">FAQs</Nav.Link>
        </Nav>
        <Nav className="ml-auto">
          <Nav.Link onClick={this.handleShowHideLogout}>Sign Out <FontAwesomeIcon className="ml-1" icon={faSignOutAlt} /></Nav.Link>
        </Nav>
        <Confirm
          title={"Log Out"}
          text={"Are you sure you want to log out?"}
          show={this.state.showLogoutModal}
          handleConfirmClose={this.handleShowHideLogout}
          handleConfirmAction={this.logout}
          handleCancelAction={this.handleShowHideLogout}
          buttonText="Log out"
          linkText="Stay logged in" />
      </Navbar>
    );
  }
}

export default Header;