import React, { Component, Fragment } from 'react';
import { Col, Row, Container, Card, Form, FormGroup, Table, Button } from 'react-bootstrap';
import { GET, extractData, POST } from '../../../../Consumer';
import LoadingBar from '../../../common/LoadingBar';
import Endpoints from '../../../common/Endpoints';
import { ADDRESS_BOOK_SUB_NAV_LIST } from '../../../common/constants'
import Header from '../../../common/Header';
import { ErrorAlert, SuccessAlert } from '../../../common/Alert';
import Pagination from '../../../common/Tables/Pagination';
import AddressBookRow from './AddressBookRow';
import AddressBookListFilter from './AddressBookListFilter';
import NoResults from '../../../common/Tables/NoResults';
import { RecipientsModal } from '../createbulkorder/RecipientsModal';
import FormValidator from '../../../common/FormValidator';

const UPDATED_RECIPIENT_SUCCESS_MESSAGE = 'Recipient has been updated.';
const DELETED_RECIPIENT_SUCCESS_MESSAGE = 'Recipient has been deleted.';
const GENERIC_RECIPIENTS_ERROR_MESSAGE = 'Recipient details could not be saved at this time';
const ADDED_RECIPIENT_SUCCESS_MESSAGE = 'Recipient has been added.';
const DUPLICATED_RECIPIENT_MESSAGE = 'Please select recipient address from the address book';
const UNSUCCESSFUL = 'Unsuccessful';

class AddressBookList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageIndex: 1,
            pageCount: 0,
            items: [],
            loading: true,
            warning: "",
            filter: {
                name: ""
            },
            showSuccessMessage: false,
            successMessage: "",
            showErrorMessage: false,
            errorMessage: "",
            errorTitle: "",
            showModal: false,
            modalStatusForm: ""
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchItems()]);
        this.setState({ loading: false });
    }

    fetchItems = async () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        const { companyID } = JSON.parse(localStorage.getItem('user'));
        let url = new URL(Endpoints.COMPANY_CUSTOMER_ADDRESS.GET.GET_CUSTOMER_ADDRESS_BY + companyID);
        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex);

        return GET(url)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const warning = data.items === 0 ? "No address book found" : null;
                this.setState({ items: data.items ? data.items : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false, warning: warning });
            })
            .catch(error => console.log(error));
    }

    handleShowSuccessMessage = async (isSuccess, isupdate) => {
        if (isSuccess) {
            this.setState({ showSuccessMessage: isSuccess, successMessage: (isupdate ? UPDATED_RECIPIENT_SUCCESS_MESSAGE : DELETED_RECIPIENT_SUCCESS_MESSAGE) }, await this.fetchItems);

        }
    }

    handleShowErrorMessage = (isError) => {
        if (isError) {
            this.setState({ showErrorMessage: isError })
        }
    }

    handleLoading = (isLoading) => {

        this.setState({ loading: isLoading })
    }

    renderAddressBookRows() {
        const { items, pageIndex, pageCount, showSuccessMessage, showErrorMessage, loading, successMessage, errorMessage, errorTitle } = this.state;
        return (
            loading ? <LoadingBar /> :
                <Fragment>
                    {showSuccessMessage && <SuccessAlert successMessage={successMessage} successTitle="Success" />}
                    {showErrorMessage && <ErrorAlert errorMessage={errorMessage} errorTitle={errorTitle} />}
                    <Card className="card-table">
                        <div className="table-responsive">
                            <Table striped bordered size="sm" className={!items.length ? "table-empty" : ""}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Company Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Location</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.length
                                        ? items.map(item => <AddressBookRow key={item.id} item={item} handleShowSuccessMessage={this.handleShowSuccessMessage} handleShowErrorMessage={this.handleShowErrorMessage} handleLoading={this.handleLoading} />)
                                        : <NoResults colSpan="5" text="No address book available." />
                                    }
                                </tbody>
                            </Table>
                            <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onStart={this.onStart} onEnd={this.onEnd} pageIndex={pageIndex} pageCount={pageCount} />
                        </div>
                    </Card>

                </Fragment>
        );
    }

    onClear = async (e) => {
        e.preventDefault();

        this.setState({
            filter: { name: "" },
            loading: true
        }, await this.fetchItems);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true }, await this.fetchItems);
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, await this.fetchItems);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, await this.fetchItems);
        }
    }

    onStart = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true }, await this.fetchItems);
        }
    }

    onEnd = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true }, await this.fetchItems);
        }
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...{ name: value }
            }
        }));
    }

    handleShowRecipientsModal = (show = false) => {
        this.setState({ modalStatusForm: "add", showModal: show })
    }

    handleContactDetailsInputChange = (e) => {
        let { name, value } = e.target;

        if (name === "contactNumber" && value !== "") value = value.replace(/[^0-9.]/gi, '');

        this.setState(prevState => ({
            ...prevState,
            contactDetails: {
                ...prevState.contactDetails,
                ...{ [name]: value }
            },
        }));
    }

    handleDestinationAddressInputChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            destinationAddress: {
                ...prevState.destinationAddress,
                ...{ [name]: value }
            },
        }));
    }

    createRecipient = async (requestBody) => {
        try {
            const { companyID } = JSON.parse(localStorage.getItem('user'));
            const { contactDetails, destinationAddress } = requestBody;

            const url = Endpoints.COMPANY_CUSTOMER_ADDRESS.POST.CREATE_CUSTOMER_ADDRESS_FOR_COMPANY;

            const body = {
                companyID: companyID,
                name: contactDetails.name,
                companyName: contactDetails.companyName,
                email: contactDetails.email,
                phone: contactDetails.phone,
                addressLine1: destinationAddress.addressLine1,
                addressLine2: destinationAddress.addressLine2,
                country: destinationAddress.country,
                county: destinationAddress.county,
                town: destinationAddress.town,
                postCode: destinationAddress.postCode,
            };
            
            this.setState({ loading: true });

            const response = await POST(url, body);

            if (response.ok) {

                this.setState({
                    showModal: false,
                    showSuccessMessage: true,
                    showErrorMessage: false,
                    errorMessage: "",
                    successMessage: ADDED_RECIPIENT_SUCCESS_MESSAGE,
                    isRecipientSelected: true,
                    loading: false,
                }, await this.fetchItems);

            } else {
                
                this.setState({
                    showModal: false,
                    showErrorMessage: true,
                    errorMessage: DUPLICATED_RECIPIENT_MESSAGE,
                    errorTitle: "Recipient already exists",
                    showSuccessMessage: false,
                    successMessage: '',
                    loading: false,
                });
            }
        } catch (error) {
           
            console.log(error);
            this.setState({
                showModal: false,
                loading: false,
                showErrorMessage: true,
                errorMessage: GENERIC_RECIPIENTS_ERROR_MESSAGE,
                errorTitle: UNSUCCESSFUL,
                showSuccessMessage: false,
                successMessage: ''
            });
        }
    }

    render() {
        const { loading, warning, showModal, modalStatusForm } = this.state;

        return (
            <React.Fragment>
                {loading ? <LoadingBar /> :
                    <React.Fragment>
                        <Form >
                            <FormGroup>
                                <Button variant="primary" className="float-right" onClick={() => this.handleShowRecipientsModal(true)} >+ Add Recipient</Button>
                            </FormGroup>
                        </Form>
                        <Header title="Address Book" subNavList={ADDRESS_BOOK_SUB_NAV_LIST} activeKey="Address Book" />
                        <Container fluid>
                            {warning ?
                                <Row><Col sm={12} md={6} className="px-2"><ErrorAlert errorMessage={warning} /></Col></Row> :
                                <React.Fragment>
                                    <Row className="my-4">
                                        <Col>
                                            <AddressBookListFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...this.state.filter} />
                                        </Col>
                                    </Row>
                                    {this.renderAddressBookRows()}
                                </React.Fragment>}
                        </Container>
                        <RecipientsModal
                            show={showModal}
                            recipientID={0}
                            showDeleteConfirmationModal={null}
                            handleShowRecipientsModal={this.handleShowRecipientsModal}
                            handleToggleDeleteConfirmationModal={null}
                            handleContactDetailsInputChange={this.handleContactDetailsInputChange}
                            handleDestinationAddressInputChange={this.handleDestinationAddressInputChange}
                            handleEdit={this.createRecipient}
                            cancelRecipientDelete={null}
                            handleRecipientDelete={null}
                            modalStatusForm={modalStatusForm}
                        />
                    </React.Fragment>}
            </React.Fragment>
        );
    }
}

export default AddressBookList;