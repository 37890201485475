import React, { Component } from 'react';
import { POST, extractData } from '../../Consumer';
import { Form, FormGroup, FormLabel, FormControl, Container, Row, Card, Button, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Endpoints from '../common/Endpoints';
import Footer from '../layout/Footer';
import { ErrorAlert, WarningAlert } from '../common/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import './Login.scss';

const TOKEN_EXPIRED = { "warningTitle" :  "Session Expired", "warningMessage" : "Your session has timed out. Please login." }

class Login extends Component {

    state = {
        email: '',
        password: '',
        loginError: '',
        passwordInputType: 'password',
        passwordInputIcon: faEye,
        tokenExpired: this.props.tokenExpired 
    }

    validateForm = () => this.state.email.length > 0 && this.state.password.length > 0;

    handleChange = (e) => this.setState({ [e.target.id]: e.target.value });

    handleSubmit = (e) => {
        e.preventDefault();
        this.tryLogin();
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" 
            ? this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) 
            : this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    tryLogin = () => {
        return POST(Endpoints.LOGIN, { email: this.state.email, password: this.state.password })
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                    throw result.message ? new Error(result.message) : new Error("Login failed");
                } else {
                    this.props.onLogin(extractData(result));
                }
            })
            .catch(error => {
                console.log(error); 
                this.setState({ loginError: error.message, email: '', password: '' });
            });
    }

    render() {
        const { loginError, email, password, passwordInputType, passwordInputIcon, tokenExpired } = this.state;

        return (
            <Container className="h-100">
                <Row className="justify-content-center row-form-adjustedheight">
                    <Col md={6} className="align-self-center">
                        <Card className="p-5">
                          <div className="logo-container">
                            <Image src='Images/logo.png' className="mx-auto mb-4 logo" />
                          </div>
                            {tokenExpired && <WarningAlert warningTitle={TOKEN_EXPIRED.warningTitle} warningMessage={TOKEN_EXPIRED.warningMessage}></WarningAlert>}
                            <Form onSubmit={this.handleSubmit}>
                                {loginError && <ErrorAlert errorMessage={loginError} />}
                                <FormGroup>
                                    <FormLabel className="font-weight-bold">Email</FormLabel>
                                    <FormControl autoFocus type="email" id="email" value={email} onChange={this.handleChange} />
                                </FormGroup>
                                <FormGroup className="mb-1">
                                    <FormLabel className="font-weight-bold">Password</FormLabel>
                                    <FormControl type={passwordInputType} id="password" value={password} maxLength="50" onChange={this.handleChange} />
                                    <span className="form-password-icon" onClick={this.handleShowHidePassword}><FontAwesomeIcon icon={passwordInputIcon} /></span>
                                </FormGroup>
                                <FormGroup>
                                    <Link to='/account/password/forgot'>Forgot Password?</Link>
                                </FormGroup>
                                <Button size="lg" type="submit" block>Login</Button>
                                <p className="decorated mb-3 mt-3"><span>Not registered yet?</span></p>
                            </Form>
                            <Link to='/register/retailer' className="link-button">
                                <Button variant="secondary" size="lg" type="submit" block>Register Now</Button>
                            </Link>
                        </Card>
                    </Col>
                </Row>
                <Footer />
            </Container>
        );
    }
}

export default Login;