import React, { useState, useCallback, useEffect } from 'react';
import Header from '../../common/Header';
import Endpoints from '../../common/Endpoints';
import { GET_ASYNC, extractData } from '../../../Consumer';

const getEnabledTabs = (showInactiveTab) => {
    var tabs = [
        { text: "Products", link: "/supplier/products" },
        { text: "Add Product", link: "/supplier/products/add" },
        { text: "Upload Product CSV", link: "/supplier/products/upload" },
        { text: "Bundles", link: "/supplier/products/bundles" }
    ];

    if(showInactiveTab) tabs.push({ text: "Inactive", link: "/supplier/products/inactive" });
    return tabs;
}

const ProductHeader = ({activeKey, headerClass}) => {
    const [showInactiveTab, setShowInactiveTab] = useState(false);

    const fetchInactiveProducts = async () => {
        try {
          let url = new URL(Endpoints.STOCK.GET.INACTIVE);
          url.searchParams.append("pageIndex", 1)
    
          const response = await GET_ASYNC(url);
    
          if (response?.ok) {
            const data = await response.json();
            const productModel = extractData(data);
            setShowInactiveTab(productModel.products.length > 0);
          } else {
            setShowInactiveTab(false);
          }
        } catch (error) {
            setShowInactiveTab(false);
        }
    }

    const fetchData = useCallback(async () => {
        await fetchInactiveProducts();
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData]);

    return(
        <Header title="Products" subNavList={getEnabledTabs(showInactiveTab)} activeKey={activeKey} headerClass={headerClass} />
    );
}
  
export default ProductHeader;