import React from 'react';
import { Button } from 'react-bootstrap';
import './StockBoxLibrary.scss';

export const StockBoxLibraryRow = ({ item, setStockBoxState, handleShowHideModal, setIsEditMode, setSuccessState, setErrorState}) => {

  const { name, height, width, depth, id } = item;

  const isPfp = () => height * width * depth >= 20250;

  const handleEditClick = () => {
    setStockBoxState({ ...item, height: height.toString(), width: width.toString(), depth:  depth.toString() });
    setIsEditMode(true);
    handleShowHideModal();
    setSuccessState({ message: '', show: false });
    setErrorState({ message: '', show: false });
  }

  return (
    <>
      {
        <tr key={id} className='innerDetail'>
          <td className='box-name'>{name}</td>
          <td className='value-centered'> 
            <div className='stockbox-dimensions-container'>
              <span>{height} cm x {width} cm x {depth} cm </span>
            </div>
          </td>
          {isPfp() ? <td id='pfp-status'><div className="text-status-purple">PFP</div></td> : <td></td>}
          <td className="text-center py-2 edit-stockbox-field">
            <Button
              id={"stockboxl" + id}
              className="link-button edit-stockbox-button"
              onClick={ handleEditClick }
              aria-controls={"lineCl" + id}
              variant="secondary" size="sm"
              style={{ borderRadius: '5px' }}
            >
              Edit
            </Button>
          </td>
        </tr>
      }
    </>
  );
};