import React from 'react';
import { NavItem } from 'react-bootstrap';
import { hasProfile, hasPermission } from '../../Utilities';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faUsers, faFileInvoice, faShoppingCart, faClipboardList, faTasks, faCreditCard, faCog, faUndo, faPlug, faBoxes, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import './NavMenu.scss';

const SideNavItem = ({ link, icon, title, exactLink = true }) =>
    <LinkContainer to={link} exact={exactLink}>
        <NavItem><FontAwesomeIcon className="mr-1" icon={icon} /> {title}</NavItem>
    </LinkContainer>

const CompanyNav = () =>
    <React.Fragment>
        <h5>Company</h5>
        <SideNavItem link="/" icon={faChartBar} title="Dashboard" />
        {hasPermission('ViewUser') && <SideNavItem link="/company/users" icon={faUsers} title="Users" />}
        {hasPermission("ViewInvoice") && <SideNavItem link="/company/invoices" icon={faFileInvoice} title="Invoicing" exactLink={false} />}
    </React.Fragment>

const RetailerNav = () =>
    <React.Fragment>
        <h5>Retailer</h5>
        {hasPermission('ViewOrder') && <SideNavItem link="/retailer/orders" icon={faShoppingCart} title="Orders" exactLink={false} />}
        {hasPermission('BulkPick') && <SideNavItem link="/retailer/bulkorder" icon={faBoxes} title="Bulk Order" exactLink={false} />}
        {hasPermission('ViewReturn') && <SideNavItem link="/retailer/returns" icon={faUndo} title="Returns" />}
    </React.Fragment>

const StockNav = () =>
    <React.Fragment>
        <h5>Stock</h5>
        {hasPermission('ViewStock') && <SideNavItem link="/supplier/products" icon={faClipboardList} title="Products" exactLink={false} />}
        {hasPermission('ViewStock') && <SideNavItem link="/supplier/stockconsignments" icon={faTasks} title="Inventory" exactLink={false} />}
    </React.Fragment>

const SettingsNav = () =>
    <React.Fragment>
        <h5>Settings</h5>
        {hasPermission('ViewCard') && <SideNavItem link="/retailer/bulkorder/addressBook" icon={faAddressBook} title="Address Book" exactLink={false}/>}
        {hasPermission('ViewCard') && <SideNavItem link="/retailer/finance/newcard" icon={faCreditCard} title="Payments" />}
        {hasPermission('ManageIntegrations') && <SideNavItem link="/retailer/integrations" icon={faPlug} title="Integrations" exactLink={false} />}
        {hasPermission('ManagePreferences') && <SideNavItem link="/retailer/preferences" icon={faCog} title="Preferences" exactLink={false} />}
    </React.Fragment>

const NavMenu = () => {
    const showSettings = (hasPermission('ViewCard') || hasPermission('ManageIntegrations') || hasPermission('ManagePreferences'));
    const showRetailer = (hasPermission('ViewOrder') || hasPermission('BulkPick') || hasPermission('ViewReturn'));

    return (
        <div className="sidebar-wrapper">
            <CompanyNav />
            {showRetailer && hasProfile("Retailer") ? <RetailerNav /> : null}
            {hasPermission('ViewStock') && hasProfile("Supplier") ? <StockNav /> : null}
            {showSettings && <SettingsNav />}
        </div>
    )
}

export default NavMenu;