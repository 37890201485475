import React, { Component, Fragment } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RETAILER_ORDER_SUB_NAV_LIST } from '../../../common/constants';
import Endpoints from '../../../common/Endpoints';
import { GET, extractData } from '../../../../Consumer';
import LoadingBar from '../../../common/LoadingBar';
import Header from '../../../common/Header';
import { ErrorAlert } from '../../../common/Alert';
import PartialOrdersFilter from './PartialOrdersFilter';
import PartialOrderTable from './PartialOrderTable';
import Pagination from '../../../common/Tables/Pagination';

class PartialOrders extends Component {
    state = {
        pageCount: 0,
        pageIndex: 1,
        orders: [],
        loading: true,
        error: null,
        filter: {
            search: "",
            sortBy: ""
        }
    };

    async componentDidMount() {
        await this.fetchPartialOrders();
        this.setState({ loading: false });
    }

    fetchPartialOrders = async () => {
        const { pageIndex, filter } = this.state;
        const url = new URL(Endpoints.RETAILER.GET.PARTIAL_ORDERS);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)

        return GET(url)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                this.setState({ orders: data.orders ? data.orders : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false, error: null });
            })
            .catch(error => {
                this.setState({ error: error.message });
                console.log(error)
            });
    }

    onClear = async (e) => {
        e.preventDefault();
        this.setState({
            filter: { search: "", sortBy: "" },
            loading: true
        }, await this.fetchPartialOrders);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true }, await this.fetchPartialOrders);
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, await this.fetchPartialOrders);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, await this.fetchPartialOrders);
        }
    }

    onStart = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true }, this.fetchPartialOrders);
        }
    }

    onEnd = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true }, this.fetchPartialOrders);
        }
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    handleViewOrderClick = (id) => this.props.history.push(`/retailer/orders/partial/orderdetails/${id}`);

    render() {
        const { loading, filter, orders, pageIndex, pageCount, error } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <Header title="Orders" subNavList={RETAILER_ORDER_SUB_NAV_LIST} activeKey="Partial" />
                    <Container fluid>
                        {error && <Row><Col sm={12} md={6} className="px-2"><ErrorAlert errorMessage={error} /></Col></Row>}
                        <Row>
                            <Col sm={12} md={6}>
                                <p>Partial orders can be turned on/off in your <Link target='_blank' to="/retailer/preferences/orders">preferences</Link>.</p>
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col>
                                <PartialOrdersFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...filter} />
                            </Col>
                        </Row>
                        <Card className="card-table">
                            <div className="table-responsive">
                                <PartialOrderTable orders={orders} handleViewOrderClick={this.handleViewOrderClick} />
                                <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onStart={this.onStart} onEnd={this.onEnd} pageIndex={pageIndex} pageCount={pageCount} />
                            </div>
                        </Card>
                    </Container>
                </Fragment>
        )
    }
}

export default PartialOrders;
