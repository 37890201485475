import React, { Component, Fragment } from 'react';
import { Col, Row, Container, Card } from 'react-bootstrap';
import LoadingBar from "../../../common/LoadingBar";
import Endpoints from "../../../common/Endpoints";
import { INVOICING_SUB_NAV_LIST } from '../../../common/constants'
import Header from '../../../common/Header';
import { GET, extractData } from '../../../../Consumer';
import InvoiceTable from './InvoiceTable';
import InvoiceFilter from './InvoiceFilter';
import Pagination from '../../../common/Tables/Pagination';

class Invoices extends Component {

    state = {
        totalCount: 0,
        pageIndex: 1,
        pageCount: 0,
        invoices: [],
        types: [],
        loading: true,
        filter: { invoiceNumber: "", invoiceType: "", dateFrom: "", dateTo: "", orderDescending: true }
    };

    async componentDidMount() {
        await Promise.all([
            this.getInvoices(),
            this.getInvoiceTypes()]);
        this.setState({ loading: false });
    }

    getInvoices = () => {
        const { filter, pageIndex } = this.state;

        let url = new URL(Endpoints.INVOICES.GET.ALL);

        if (filter.invoiceNumber || filter.invoiceType || filter.dateFrom || filter.dateTo || filter.orderDescending) {
            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        }
        url.searchParams.append("pageIndex", pageIndex)

        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    const data = extractData(result);
                    this.setState({ invoices: data.invoices, totalCount: data.total, pageCount: data.pageCount ? data.pageCount : 0, loading: false });
                }
                else {
                    this.setState({ loading: false });
                }
            });
    }

    getInvoiceTypes = () => {
        return GET(Endpoints.INVOICES.GET.TYPES)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ types: extractData(result) });
                }
            });
    }

    onFilterChange = (e) => {
        let { name, value, type } = e.target;

        if (type === "checkbox") {
            let { checked } = e.target;
            value = checked;
        }

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    onClear = () => {
        this.setState({
            invoices: [],
            filter: {
                invoiceNumber: "",
                invoiceType: "",
                dateFrom: "",
                dateTo: "",
                orderDescending: true
            }
        }, this.getInvoices);
    }

    onSearch = (event) => {
        event.preventDefault();
        this.setState({ invoices: [], loading: true }, this.getInvoices);
    }

    onNext = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.getInvoices);
        }
    }

    onPrevious = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.getInvoices);
        }
    }

    onStart = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true }, this.getInvoices);
        }
    }

    onEnd = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true }, this.getInvoices);
        }
    }

    render() {
        const { loading, types, filter, invoices, pageIndex, pageCount } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <Header title="Invoicing" subNavList={INVOICING_SUB_NAV_LIST} activeKey="Invoice" />
                    <Container fluid>
                        <Row className="my-4">
                            <Col>
                                <InvoiceFilter types={types} onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...filter} />
                            </Col>
                        </Row>
                        <Card className="card-table">
                            <div className="table-responsive">
                                <InvoiceTable invoices={invoices} />
                                <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onStart={this.onStart} onEnd={this.onEnd} pageIndex={pageIndex} pageCount={pageCount} />
                            </div>
                        </Card>
                    </Container>
                </Fragment>
        );
    }
}

export default Invoices;