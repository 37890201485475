import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Confirm from '../../common/Confirm';
import { checkPermission } from '../../../Utilities';
import { SuccessAlert } from '../../common/Alert';

const SUCCESSFULLY_SUBMITTED_MESSAGE = "Direct debit application has been submitted successfully. It will be reviewed within 6 business days. We will notify you regarding the bank account details being accepted or rejected.";
const REMOVE_DIRECT_DEBIT_TITLE = "Remove Bank Account";
const REMOVE_DIRECT_DEBIT_MESSAGE = "Are you sure you want to remove your bank details? We will automatically charge your credit card until new bank details have been added and approved.";

const DirectDebitDetails = ({ existingAccount, paymentTerms, handleRemoveShowHide, handleRemoveAction, showRemoveDirectDebitModal  }) => {

    const isPending = existingAccount.status === "PENDING";

    return (
        <Container fluid>
            <Row>
                <Col sm={12} md={6}>
                    {isPending && <SuccessAlert successTitle="Successfully Submitted" successMessage={SUCCESSFULLY_SUBMITTED_MESSAGE} />}
                    <p className="title">Name</p>
                    <p>{existingAccount.name}</p>
                    <p className="title">Bank Account Type</p>
                    <p>{existingAccount.type}</p>
                    <p className="title">Last Status Check</p>
                    <p>{existingAccount.lastChecked}</p>
                    <p className="title">Direct Debit Status</p>
                    <p>{isPending ? <span className="text-status-yellow"><strong>Pending</strong></span> :
                            existingAccount.status === "ACTIVE" ? <span className="text-status-green"><strong>Active</strong></span> 
                            : <span className="text-status-red"><strong>Inactive</strong></span>}</p>
                    <p className="title">Payment Term</p>
                    <p>{paymentTerms} days</p>
                    {checkPermission('NewCard',
                        <div className="float-right">
                            <Button id="removeBankAccount" variant="danger" onClick={handleRemoveShowHide}>Remove Bank Account</Button>
                        </div>)}
                </Col>
            </Row>
            {showRemoveDirectDebitModal && <Confirm title={REMOVE_DIRECT_DEBIT_TITLE} text={REMOVE_DIRECT_DEBIT_MESSAGE} buttonText={REMOVE_DIRECT_DEBIT_TITLE} buttonVariant={"danger"}
                handleConfirmClose={handleRemoveShowHide} handleConfirmAction={handleRemoveAction} show={showRemoveDirectDebitModal} />}
        </Container>
    )
}

export default DirectDebitDetails;
