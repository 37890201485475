import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SELECT_PRODUCT, PRODUCT_ERROR_MESSAGE, QUANTITY_ERROR_MESSAGE, PRODUCT_DELETE_MESSAGE } from './StringConstants';

import Confirm from '../../../common/Confirm';
import { DropDownWithFilter } from '../../../common/DropDownWithFilter';

export const ProductItem = ({ product, handleDelete, handleSelectChange, handleInputChange, companyProducts }) => {

  const [showDeleteProductConfirmation, setShowDeleteProductConfirmation] = useState(false);

  const onProductChange = ( product ) => {
    if (product.name === SELECT_PRODUCT) {
      handleSelectChange(null);
      return;
    }

    const newProduct = { ...product, quantity: '' };
    handleSelectChange(newProduct);
  }

  const onQuantityChange = ({ target }) => {
    const { value } = target;

    if (isNumber(value)) {
      handleInputChange(product, value);
      return;
    }
  }

  const isNumber = (value) => {  
    return !isNaN(value);
  }

  const onDelete = () => {
    setShowDeleteProductConfirmation(true);
  }

  const handleDeleteProduct = () => {
    handleDelete(product);
    setShowDeleteProductConfirmation(false);
  }

  const handleDeleteShowHide = () => {
    setShowDeleteProductConfirmation(false);
  }
 
  return (
    <>
      <tr className='product-row'>
        <td className='input-fields'> 
          {
            product.isActive && companyProducts.length !== 0 ? 
            <>
              <DropDownWithFilter items={ companyProducts } selectedItem={ product } valueField='name' onItemChange={ onProductChange } hasError={ product.isEmpty } initialValue='Select product' />
            </>
            : 
            product.name
          }
          { product.isEmpty && <span className="text-danger">{ PRODUCT_ERROR_MESSAGE }</span> }
        </td>
        <td className='value-centered table-data-restricted-md product-cell'>{ product?.ean ? <span>{ product?.ean }</span> : <FontAwesomeIcon icon={ faMinus }/> }</td>
        <td className='value-centered'>{ product?.sku ? <span>{ product?.sku }</span> : <FontAwesomeIcon icon={ faMinus } />}</td>
        <td className='value-centered input-fields' id='quantity-centered'>
          {
            <input name='quantity' onChange={ onQuantityChange } value={ product.quantity } maxLength={50} type='text' id='productQuantity' className={`${product.isQuantityEmpty ? 'product-quantity border-danger' : 'product-quantity'}`}></input>
          }
          { product.isQuantityEmpty && <span className="text-danger quantity-error">{ QUANTITY_ERROR_MESSAGE }</span> }
        </td>        
        <td className="text-center">
          <FontAwesomeIcon className='deleteproduct-button' onClick={ () => onDelete(product) } icon={ faTrash } />
        </td>
      </tr>
      {showDeleteProductConfirmation && <Confirm title={"Delete Product"} text={ PRODUCT_DELETE_MESSAGE } buttonText={"Delete"} buttonVariant="danger" handleConfirmClose={ handleDeleteShowHide } handleConfirmAction={ handleDeleteProduct } />}
    </>
  )
}

