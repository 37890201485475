import React, { Component, Fragment } from 'react';
import { Table, Container, Card } from 'react-bootstrap';
import LoadingBar from '../../common/LoadingBar';
import ReturnModal from './ReturnModal';
import { RETURNS_SUB_NAV_LIST } from '../../common/constants'
import Header from '../../common/Header';
import Endpoints from '../../common/Endpoints';
import { GET, extractData } from '../../../Consumer';
import { toLocalTimeString } from '../../../Utilities';
import NoResults from '../../common/Tables/NoResults';

class UnprocessedReturns extends Component {

    state = {
        unprocessed: [],
        modalData: {},
        show: false,
        loading: true
    };

    async componentDidMount() {
        await this.fetchUnprocessed();
        this.setState({ loading: false });
    }

    fetchUnprocessed = () =>
        GET(Endpoints.RETAILER.GET.UNPROCESSED_RETURNS)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const unprocessed = extractData(results);
                this.setState({ unprocessed: unprocessed ? unprocessed : [] });
            })
            .catch(error => console.log(error));

    handleClose = () => this.setState({ show: false });

    handleShow = (modalReturn) =>
        this.setState({
            modalData: modalReturn,
            show: true
        });

    render() {
        const { unprocessed, show, modalData, loading } = this.state;

        return (
            <Fragment>
                {loading
                    ? <LoadingBar />
                    : <Fragment>
                        <Header title="Returns" subNavList={RETURNS_SUB_NAV_LIST} activeKey="Collect+ Unprocessed" />
                        <Container fluid>
                            <Card className="card-table">
                                <div className="table-responsive">
                                    <Table striped bordered size="sm" className={`mb-0 ${!unprocessed.length ? "table-empty" : ""}`}>
                                        <thead>
                                            <tr>
                                                <th>Return Reference</th>
                                                <th>Order Reference</th>
                                                <th>Status</th>
                                                <th>Check-in Start</th>
                                                <th>Check-in End</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {unprocessed.length
                                                ? unprocessed.map((u) => {
                                                    return (<tr key={u.id}>
                                                        <td><a className="table-id-link" onClick={() => this.handleShow(u)}>{u.reference}</a></td>
                                                        <td>{u.orderReference}</td>
                                                        <td>{u.status === 0 ? "Unprocessed" : "Checked-in"}</td>
                                                        <td>{u.checkinStarted ? toLocalTimeString(u.checkinStarted) : "N/A"}</td>
                                                        <td>{u.checkinEnded ? toLocalTimeString(u.checkinEnded) : "N/A"}</td>
                                                    </tr>);
                                                })
                                                : <NoResults colSpan="5" text="No returns to show." />
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </Container>
                    </Fragment>
                }
                <ReturnModal show={show} handleClose={this.handleClose} modalData={modalData} modalProcessed={false} />
            </Fragment>
        )
    }
}

export default UnprocessedReturns;

