import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GET, extractData } from '../../../../Consumer';
import LoadingBar from '../../../common/LoadingBar';
import Endpoints from '../../../common/Endpoints';
import BundleFilter from './BundleFilter';
import BundleCard from './BundleCard';
import Bundle from './Bundle';
import Pagination from '../../../common/Tables/Pagination';
import './Bundles.scss';
import ProductHeader from '../ProductHeader';

class Bundles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totalCount: 0,
            pageIndex: this.props.match.params.pageIndex || 1,
            pageCount: 1,
            productBundles: [],
            currentPage: [],
            loading: true,
            showDetails: false,
            filter: {
                searchText: "",
                pagingRequired: true
            }
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchProductBundles()
        ]);
        this.setState({ loading: false });
    }

    onReset = async (e) => {
        e.preventDefault();

        this.setState({
            filter: { searchText: "" },
            loading: true
        }, await this.fetchProductBundles);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true }, await this.fetchProductBundles);
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, await this.fetchProductBundles);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, await this.fetchProductBundles);
        }
    }

    onStart = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true }, await this.fetchProductBundles);
        }
    }

    onEnd = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true }, await this.fetchProductBundles);
        }
    }

    onCardClick = (productBundle) => {
        this.props.history.push({ pathname: `/supplier/products/bundles/${productBundle.id}`, state: { allowEdit: true } });
    }

    hideDetails = async () => {
        this.setState({ showDetails: false }, await this.fetchProductBundles);
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    fetchProductBundles = async () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(Endpoints.STOCK.GET.PRODUCT_BUNDLES);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)


        return await GET(url)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];                
                this.setState({ productBundles: data.productBundles ? data.productBundles : [], totalCount: data.total ? data.total : 0, pageCount: data.pageCount ? data.pageCount : 0, pageIndex: pageIndex > data.pageCount ? 1 : pageIndex, loading: false });
            })
            .catch(error => console.log(error));
    }

    render() {
        const { loading, productBundles, productBundle, pageIndex, pageCount, showDetails } = this.state;

        if (showDetails) {
            return (
                <Bundle productBundle={productBundle} hideDetails={this.hideDetails} allowEdit={true} />
            );
        }

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <ProductHeader activeKey="Bundles" />
                    <Container fluid>
                        <Row className="my-4">
                            <Col>
                                <BundleFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onReset={this.onReset} {...this.state.filter} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} sm={12} className="px-2">
                                <Card className="card-add-bundle mb-3">
                                    <Card.Body>
                                        <p><strong>Want to create another bundle?</strong></p>
                                        <div className="text-center">
                                            <Link to={`bundles/create`}>
                                                <Button className="link-button" variant="secondary" size="sm" onClick={this.setStep}>Create Bundle</Button>
                                            </Link>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            {productBundles.length > 0 && productBundles.map(productBundle =>
                                <Col md={4} sm={12} key={productBundle.id} className="px-2">
                                    <BundleCard productBundle={productBundle} showDetails={this.onCardClick} />
                                </Col>)}
                        </Row>
                        <div className="card-pagination">
                            <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onStart={this.onStart} onEnd={this.onEnd} pageIndex={pageIndex} pageCount={pageCount} />
                        </div>
                    </Container>
                </Fragment>
        )
    }
}

export default Bundles;