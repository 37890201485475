import React, { Component } from 'react';
import LoadingBar from '../../common/LoadingBar';
import { GET } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';

class CardReturn extends Component {

    async componentDidMount() {
        await this.getLatestCardDetails();
    }

    getLatestCardDetails = () => {
        GET(Endpoints.FINANCE.CARD.GET.LATEST)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const latestCardDetails = results ? results.data : [];
                this.props.history.push({ pathname: "/retailer/finance/newcard", state: { showCompleteError: latestCardDetails.isError, completeErrorMessage: latestCardDetails.message } })
            })
            .catch(error => {              
                this.props.history.push({ pathname: "/retailer/finance/newcard", state: { showCompleteError: false } });
                console.log(error);
            });
    }

    render() {
        return (
            <LoadingBar />
        )
    }
}

export default CardReturn;