import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormControl, FormLabel, FormGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { hasPermission } from '../../../Utilities';
import LoadingBar from '../../common/LoadingBar';
import { GET, extractData, PUT } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import FormValidator from '../../common/FormValidator';
import { isNullOrEmptyGuid } from '../../../Utilities';
import { ErrorAlert, SuccessAlert } from '../../common/Alert';
import Header from '../../common/Header';
import { USER_SUB_NAV_LIST, USER_RESTRICTED_SUB_NAV_LIST } from '../../common/constants';

const FETCH_ERROR_MESSAGE = "There was a problem retrieving the user. Please try again.";
const ROLE_OPTIONS_ERROR_MESSAGE = "Could not retrieve role options";
const SUBMISSION_ERROR_MESSAGE = "Could not submit details";

const SuccessfulUpdate = ({ userId, userName, returnToAccount }) =>
    <React.Fragment>
        <SuccessAlert successTitle="Success" successMessage={`${userName} account has been update successfully`} />
        <Link to={returnToAccount ? `/company/users/account/${userId}` : '/company/users'} className="float-right">
            <Button variant="primary">{returnToAccount ? "Continue to Account" : "Continue to Users"}</Button>
        </Link>
    </React.Fragment>

class EditDetails extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'forename',
                method: 'isEmpty',
                validWhen: false,
                message: 'Forename is required'
            },
            {
                field: 'surname',
                method: 'isEmpty',
                validWhen: false,
                message: 'Surname is required'
            },
            {
                field: 'email',
                method: 'isEmpty',
                validWhen: false,
                message: 'Email is required'
            }],
        );

        this.state = {
            loading: true,
            validation: this.validator.valid(),
            errors: [],
            errorFetch: null,
            success: false,
            returnToAccount: (this.props.location.state && this.props.location.state.returnToAccount) ? this.props.location.state.returnToAccount : false,
            userID: props.match.params.id || null,
            user: {
                id: '',
                forename: '',
                surname: '',
                email: '',
                roles: []
            },
            validationMessages: {
                roles: false,
            },
            roleOptions: []
        };
    };

    async componentDidMount() {
        await Promise.all([
            this.fetchUser(),
            this.fetchRoleOptions(),
            this.setState({ loading: false })]);
    }

    validate = (user) => this.validator.validate(user);

    fetchUser = () => {
        const { userID } = this.state;
        if (!isNullOrEmptyGuid(userID)) {
            return GET(Endpoints.GET_USER_WITH_ROLES + userID)
                .then(response => response.json())
                .then(result => {
                    const data = extractData(result);
                    if (data === null) this.setState({ errorFetch: [result.message] || [FETCH_ERROR_MESSAGE], user: [] });
                    else if (data === undefined) this.setState({ errorFetch: [FETCH_ERROR_MESSAGE], user: [] });
                    else {
                        const user = { ...data, roles: data.userRoles.map((ur) => ur.roleID) };
                        this.setState({ user: user });
                    }
                })
                .catch(error => {
                    this.setState({ errorFetch: [FETCH_ERROR_MESSAGE] });
                    console.log(error);
                });
        }
    }

    fetchRoleOptions = () => {
        return GET(Endpoints.GET_ROLE_OPTIONS_FOR_COMPANY)
            .then(response => response.json())
            .then(result => {
                const data = extractData(result);
                if (data === null) this.setState({ roleOptions: [], errorFetch: [result.message] || [ROLE_OPTIONS_ERROR_MESSAGE] });
                else if (data === undefined) this.setState({ roleOptions: [], errorFetch: [ROLE_OPTIONS_ERROR_MESSAGE] });
                else this.setState({ roleOptions: data });
            })
            .catch(error => {
                this.setState({ errorFetch: [ROLE_OPTIONS_ERROR_MESSAGE] });
                console.log(error);
            });
    }

    handleInputChange = (e) => {
        const { name, value, type } = e.target;

        if (type === 'checkbox') {
            const currRole = this.state.user.roles.indexOf(name);
            if (currRole > -1) {
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        ...{ roles: [...prevState.user.roles.slice(0, currRole), ...prevState.user.roles.slice(currRole + 1)] }
                    }
                }));
            }
            else {
                const newRoles = this.state.user.roles;
                newRoles.push(name);
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        ...{ roles: newRoles }
                    }
                }));
            }
        }
        else {
            this.setState(prevState => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    ...{ [name]: value }
                }
            }));
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { user } = this.state;
        const userID = JSON.parse(localStorage.getItem('user')).id;
        const validation = this.validator.validate(user);
        this.setState({ validation: validation, loading: validation.isValid });
        this.submitted = true;

        if (userID !== user.ID) {
            if (user.roles.length === 0) {
                validation.isValid = false;
                this.setState({ validationMessages: { roles: true } });
            }
        }

        if (validation.isValid) {
            PUT(Endpoints.EDIT_USER_DETAILS, user)
                .then(response => response.json())
                .then(result => {
                    const data = extractData(result);
                    if (data === null) this.setState({ success: false, loading: false, errors: [result.message] || [SUBMISSION_ERROR_MESSAGE] });
                    else if (data === undefined) this.setState({ success: false, loading: false, errors: [SUBMISSION_ERROR_MESSAGE] });
                    else this.setState({ success: true, errors: [], loading: false });
                })
                .catch(error => {
                    this.setState({ errors: [SUBMISSION_ERROR_MESSAGE], loading: false });
                    console.log(error);
                });
        }
        else this.setState({ loading: validation.isValid });
    }

    render() {
        const { loading, success, errors, returnToAccount, user, validationMessages, errorFetch, roleOptions } = this.state;
        const loggedInUser = JSON.parse(localStorage.getItem('user'));
        const validation = this.submitted ? this.validate(user) : this.state.validation;
        const userID = JSON.parse(localStorage.getItem('user')).id;

        if (errorFetch == null && userID !== user.ID && this.submitted) {
            if (user.roles.length === 0) {
                validation.isValid = false;
                validationMessages.roles = true;
            }
        }

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <Header title="Users" subNavList={hasPermission("AddUser") ? USER_SUB_NAV_LIST : USER_RESTRICTED_SUB_NAV_LIST} activeKey={"Users"} />
                    <Container fluid>
                        <Row className="mt-4">
                            <Col sm={12} md={6}>
                                {success ? <SuccessfulUpdate userId={user.id} userName={`${user.forename} ${user.surname}`} returnToAccount={returnToAccount} /> :
                                    errorFetch
                                        ? <div className="mt-3">
                                            <h5>User Details</h5>
                                            <ErrorAlert errorMessage={errorFetch} />
                                        </div>
                                        : <React.Fragment>
                                            {errors.length > 0 && <ErrorAlert errorMessage={<React.Fragment>{errors.map((e, i) => e && e.length ? <p key={i} className="mb-0">{e}</p> : null)}</React.Fragment>} />}
                                            <Form onSubmit={this.handleSubmit}>
                                                <input type="hidden" name="id" value={user.id} />
                                                <fieldset>
                                                    <h5>User Details</h5>
                                                    <FormGroup>
                                                        <FormLabel htmlFor="forename">Forename</FormLabel>
                                                        <FormControl id="forename" type="text" name="forename" value={user.forename} onChange={this.handleInputChange} />
                                                        <span className="text-danger">{validation.forename.message}</span>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormLabel htmlFor="surname">Surname</FormLabel>
                                                        <FormControl id="surname" type="text" name="surname" value={user.surname} onChange={this.handleInputChange} />
                                                        <span className="text-danger">{validation.surname.message}</span>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormLabel htmlFor="email">Email</FormLabel>
                                                        <FormControl id="email" type="email" name="email" value={user.email} onChange={this.handleInputChange} />
                                                        <span className="text-danger">{validation.email.message}</span>
                                                    </FormGroup>
                                                </fieldset>
                                                {loggedInUser.id !== user.id && Object.keys(roleOptions).map((k) =>
                                                    <div key={k} className="py-2">
                                                        <h5 className="pb-2">{k} Roles</h5>
                                                        {
                                                            roleOptions[k].map((r) =>
                                                                <FormGroup key={r.id} className="custom-control custom-checkbox mb-1">
                                                                    <input className="custom-control-input" id={r.id} type="checkbox" name={r.id} onChange={this.handleInputChange} checked={user.roles.indexOf(r.id) > -1} />
                                                                    <FormLabel className="custom-control-label" htmlFor={r.id}>{r.name}</FormLabel>
                                                                </FormGroup>
                                                            )}
                                                    </div>
                                                )}
                                                {validationMessages.roles && <span className="text-danger">Select a Role</span>}
                                                <FormGroup className="mt-med">
                                                    <Button className="btn btn-primary float-right" type="submit">Save Changes</Button>
                                                    <Link to={returnToAccount ? `/company/users/account/${user.id}` : '/company/users'} className="link-button">
                                                        <Button variant="link" className="button-nav-link float-right mr-4 mt-1">Cancel</Button>
                                                    </Link>
                                                </FormGroup>
                                            </Form>
                                        </React.Fragment>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Fragment>
        );
    }
}

export default EditDetails;