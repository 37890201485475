import React, { Component, Fragment } from 'react';
import { Col, Row, Container, Card } from 'react-bootstrap';
import LoadingBar from "../../../common/LoadingBar";
import Endpoints from "../../../common/Endpoints";
import { INVOICING_SUB_NAV_LIST } from '../../../common/constants'
import Header from '../../../common/Header';
import { GET, extractData } from '../../../../Consumer';
import CollectionAdviceFilter from './CollectionAdviceFilter';
import CollectionAdviceTable from './CollectionAdviceTable';
import Pagination from '../../../common/Tables/Pagination';

class CollectionAdvice extends Component {

    state = {
        totalCount: 0,
        pageIndex: 1,
        pageCount: 0,
        collectionAdvices: [],
        loading: true,
        filter: { collectionAdviceNumber: "", dateFrom: "", dateTo: "", orderDescending: true }
    }

    componentDidMount() {
        this.getCollectionAdvices();
    }

    getCollectionAdvices = () => {
        const { filter, pageIndex } = this.state;

        let url = new URL(Endpoints.COLLECTION_ADVICE.GET.ALL);

        if (filter.collectionAdviceNumber || filter.dateFrom || filter.dateTo || filter.orderDescending) {
            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        }
        url.searchParams.append("pageIndex", pageIndex)
        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    const data = extractData(result);
                    this.setState({ collectionAdvices: data.collectionAdvices, totalCount: data.total, pageCount: data.pageCount ? data.pageCount : 0, loading: false });
                }
                else {
                    this.setState({ loading: false });
                }
            });
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    onClear = () => {
        this.setState({
            collectionAdvices: [],
            filter: {
                collectionAdviceNumber: "",
                dateFrom: "",
                dateTo: "",
                orderDescending: true
            }
        }, this.getCollectionAdvices);
    }

    onSearch = (e) => {
        e.preventDefault();
        this.setState({ collectionAdvices: [], loading: true }, this.getCollectionAdvices);
    }

    onNext = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.getCollectionAdvices);
        }
    }

    onPrevious = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.getCollectionAdvices);
        }
    }

    onStart = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true }, this.getCollectionAdvices);
        }
    }

    onEnd = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true }, this.getCollectionAdvices);
        }
    }

    render() {
        const { collectionAdvices, pageIndex, pageCount, loading } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <Header title="Invoicing" subNavList={INVOICING_SUB_NAV_LIST} activeKey="Collection" />
                    <Container fluid>
                        <Row className="my-4">
                            <Col>
                                <CollectionAdviceFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...this.state.filter} />
                            </Col>
                        </Row>
                        <Card className="card-table">
                            <div className="table-responsive">
                                <CollectionAdviceTable collectionAdvices={collectionAdvices} />
                                <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onStart={this.onStart} onEnd={this.onEnd} pageIndex={pageIndex} pageCount={pageCount} />
                            </div>
                        </Card>
                    </Container>
                </Fragment>
        );
    }
}

export default CollectionAdvice;