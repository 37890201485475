import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { RETAILER_ORDER_SUB_NAV_LIST } from '../../common/constants'
import Header from '../../common/Header';
import UnsuccessfulOrderTable from './UnsuccessfulOrderTable';
import OrderListFilter from './OrderListFilter';
import { GET_ASYNC, extractData } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import { ErrorAlert } from '../../common/Alert';
import Pagination from '../../common/Tables/Pagination';

const MAX_DAYS_AGO = 30;

const initialFilter = {
    orderNumber: "",
    dateFrom: null,
    dateTo: null,
    sortBy: "",
    status: ""
};

const UnsuccessfulOrders = () => {

    //#region State

    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);
    const [searchClicked, setSearchClicked] = useState(false);
    const [orders, setOrders] = useState([]);
    const [warning, setWarning] = useState("");
    const [filter, setFilter] = useState(initialFilter);
    const { push } = useHistory();

    //#endregion State

    useEffect(() => {
        fetchUnsuccessfulOrders()
    }, [pageIndex, searchClicked]);

    const fetchUnsuccessfulOrders = async () => {
        setLoading(true);
        setWarning("");

        const url = new URL(Endpoints.RETAILER.GET.UNSUCCESSFUL);
        url.searchParams.append("pageIndex", pageIndex);
        url.searchParams.append("maxDaysAgo", MAX_DAYS_AGO);
        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));

        try {
            const response = await GET_ASYNC(url);
            if (response?.ok) {
                const result = await response.json();
                const data = extractData(result) || [];
                setOrders(data.orders ? data.orders : []);
                setPageCount(data.pageCount ? data.pageCount : 0);
            }
        } catch (error) {
            setWarning(error.message);
            console.log(error); 
        } finally {
            setLoading(false);
            setSearchClicked(false);
        }
    }

    const onNext = () => { if (pageIndex < pageCount) setPageIndex(parseInt(pageIndex) + 1); }
    const onPrevious = () => { if (pageIndex > 1) setPageIndex(parseInt(pageIndex) - 1); }
    const onStart = () => { if (pageIndex > 1) setPageIndex(1); }
    const onEnd = () => { if (pageIndex < pageCount) setPageIndex(pageCount); }

    const onSearch = async (e) => {
        e.preventDefault();
        setPageIndex(1);
        setSearchClicked(true);
    }

    const onFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter(prevState => ({ ...prevState, ... { [name]: value }}));
    }

    const onClear = async (e) => {
        e.preventDefault();
        setOrders([]);
        setFilter(initialFilter);
        setSearchClicked(true);
    }
    const onViewDetailsClick = (order) => push("/retailer/orders/unsuccessfulorderdetails", { order: order });

    return (
        <React.Fragment>
            <Header title="Orders" subNavList={RETAILER_ORDER_SUB_NAV_LIST} activeKey="Unsuccessful" />
            {loading ? <LoadingBar /> :
                <Container fluid>
                {MAX_DAYS_AGO > 0 && <div className="form-input-description-large"><p>Displaying failed or cancelled orders from the past {MAX_DAYS_AGO} days</p></div>}
                    {warning && <Row><Col sm={12} md={6} className="px-2"><ErrorAlert errorMessage={warning} /></Col></Row>}
                    <Row className="my-4">
                        <Col>
                            <OrderListFilter
                                onSearch={onSearch}
                                onFilterChange={onFilterChange}
                                onClear={onClear}
                                searchText="Search by Order Identifier"
                                filter={filter}
                                showStatusFilter={true}
                                showSortBy={false}
                            />
                        </Col>
                    </Row>
                    <Card className="card-table">
                        <div className="table-responsive">
                            <UnsuccessfulOrderTable orders={orders} onViewDetailsClick={onViewDetailsClick} />
                            <Pagination onNext={onNext} onPrevious={onPrevious} onStart={onStart} onEnd={onEnd} pageIndex={pageIndex} pageCount={pageCount} />
                        </div>
                    </Card>
                </Container>}
        </React.Fragment>
    );
}

export default UnsuccessfulOrders;