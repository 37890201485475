import React, { Component, Fragment } from 'react';
import { Table, Container, Col, Row, Card } from 'react-bootstrap';
import LoadingBar from '../../common/LoadingBar';
import ReturnModal from './ReturnModal';
import { RETURNS_SUB_NAV_LIST } from '../../common/constants'
import Header from '../../common/Header';
import Endpoints from '../../common/Endpoints';
import { GET, extractData } from '../../../Consumer';
import { toLocalTimeString } from '../../../Utilities';
import NoResults from '../../common/Tables/NoResults';

class ProcessedReturns extends Component {

    state = {
        processed: [],
        loading: true,
        modalData: {},
        show: false,
        loading: true
    };

    async componentDidMount() {
        await this.fetchProcessed();
        this.setState({ loading: false });
    }

    handleClose = () => this.setState({ show: false });

    handleShow = (modalReturn) =>
        this.setState({
            modalData: modalReturn,
            show: true
        });

    fetchProcessed = () =>
        GET(Endpoints.RETAILER.GET.PROCESSED_RETURNS)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const processed = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ processed: processed });
            })
            .catch(error => console.log(error));

    render() {
        const { processed, show, modalData, loading } = this.state;

        return (
            <Fragment>
                {loading
                    ? <LoadingBar />
                    : <Fragment>
                        <Header title="Returns" subNavList={RETURNS_SUB_NAV_LIST} activeKey="Collect+ Processed" />
                        <Container fluid>
                            <Card className="card-table">
                                <div className="table-responsive">
                                    <Table striped bordered size="sm" className={`mb-0 ${!processed.length ? "table-empty" : ""}`}>
                                        <thead>
                                            <tr>
                                                <th>Return Reference</th>
                                                <th>Order Reference</th>
                                                <th>Check-in Start</th>
                                                <th>Check-in End</th>
                                                <th>Clean/Repack Start</th>
                                                <th>Clean/Repack End</th>
                                                <th>Processing Started</th>
                                                <th>Processing Ended</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {processed.length
                                                ? processed.map((p) => {
                                                    return (<tr key={p.id}>
                                                        <td className="py-2"><a className="table-id-link" onClick={() => this.handleShow(p, true)}>{p.reference}</a></td>
                                                        <td>{p.orderReference}</td>
                                                        <td>{p.checkinStarted ? toLocalTimeString(p.checkinStarted) : "N/A"}</td>
                                                        <td>{p.checkinEnded ? toLocalTimeString(p.checkinEnded) : "N/A"}</td>
                                                        <td>{p.cleaningRepackStarted ? toLocalTimeString(p.cleaningRepackStarted) : "N/A"}</td>
                                                        <td>{p.cleaningRepackEnded ? toLocalTimeString(p.cleaningRepackEnded) : "N/A"}</td>
                                                        <td>{p.processingStarted ? toLocalTimeString(p.processingStarted) : "N/A"}</td>
                                                        <td>{p.processingEnded ? toLocalTimeString(p.processingEnded) : "N/A"}</td>
                                                    </tr>);
                                                })
                                                : <NoResults colSpan="8" text="No returns to show." />
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </Container>
                    </Fragment>
                }
                <ReturnModal show={show} handleClose={this.handleClose} modalData={modalData} modalProcessed={true} />
            </Fragment>
        )
    }
}

export default ProcessedReturns;

