import React, { Component, Fragment } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { RETAILER_ORDER_SUB_NAV_LIST } from '../../common/constants'
import Header from '../../common/Header';
import UnprocessedOrderTable from './UnprocessedOrderTable';
import { GET, PUT, extractData } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import { SuccessAlert, ErrorAlert } from '../../common/Alert';
import { isNullOrEmptyGuid } from '../../../Utilities';
import Pagination from '../../common/Tables/Pagination';
import Confirm from '../../common/Confirm';

const SHOPIFY_ORDER_SOURCE = "Shopify";
const WOOCOMMERCE_ORDER_SOURCE = "WooCommerce";
const CANCEL_ORDER = 'Cancel Order';

const getCancelEndpoint = (source) => source === SHOPIFY_ORDER_SOURCE
    ? Endpoints.RETAILER.PUT.CANCEL_SHOPIFY_ORDER
    : source === WOOCOMMERCE_ORDER_SOURCE
        ? Endpoints.RETAILER.PUT.CANCEL_WOOCOMMERCE_ORDER
        : Endpoints.RETAILER.PUT.CANCEL_ORDER;

class PendingOrders extends Component {

    state = {
        pageCount: 0,
        pageIndex: 1,
        orders: [],
        loading: true,
        showCancellationConfirmationModal: false,
        currentItemID: '',
        currentItemSource: '',
        showError: false,
        showSuccess: false
    };

    async componentDidMount() {
        await Promise.all([
            this.fetchPendingOrders()]);
        this.setState({ loading: false });
    }

    fetchPendingOrders = async () => {
        const { pageIndex } = this.state;
        const url = new URL(Endpoints.RETAILER.GET.PENDING);
        url.searchParams.append("pageIndex", pageIndex);

        return GET(url)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                this.setState({ orders: data.orders ? data.orders : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false });
            })
            .catch(error => console.log(error));
    }

    showCancellationConfirmationModal = (id, identifier, source, show) => this.setState({ currentItemID: id, identifier: identifier, currentItemSource: source, showCancellationConfirmationModal: show });

    handleCancellationConfirmationClose = () => this.setState({ showCancellationConfirmationModal: false });

    cancelOrder = (id, source) => {
        const cancelEndpoint = getCancelEndpoint(source);

        if (!isNullOrEmptyGuid(id)) {
            PUT(cancelEndpoint + "/" + id)
                .then(response => {
                    if (response.ok) return response.json();
                })
                .then(result => {
                    const data = extractData(result);
                    if (data === true) {
                        this.setState({ showSuccess: true, showError: false });
                        this.fetchPendingOrders();
                    }
                    else { this.setState({ showSuccess: false, showError: true }); }
                    this.handleCancellationConfirmationClose();
                })
                .catch(error => console.log(error));
        }
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.fetchPendingOrders);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.fetchPendingOrders);
        }
    }

    onStart = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true }, this.fetchPendingOrders);
        }
    }

    onEnd = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true }, this.fetchPendingOrders);
        }
    }

    render() {
        const { loading, orders, pageIndex, pageCount, identifier, currentItemID, currentItemSource, showError, showSuccess } = this.state;

        return (
            <Fragment>
                {loading
                    ? <LoadingBar />
                    : <Fragment>
                        <Header title="Orders" subNavList={RETAILER_ORDER_SUB_NAV_LIST} activeKey="Pending" />
                        <Container fluid>
                            {showError && <Row><Col sm={12} md={6}><ErrorAlert errorMessage={`Order ${identifier} has not been cancelled.`} /></Col></Row>}
                            {showSuccess && <Row><Col sm={12} md={6}><SuccessAlert successMessage={`Order ${identifier} has been cancelled.`} /></Col></Row>}
                            <Card className="card-table">
                                <div className="table-responsive">
                                    <UnprocessedOrderTable orders={orders} showCancellationConfirmationModal={this.showCancellationConfirmationModal} />
                                    <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onStart={this.onStart} onEnd={this.onEnd} pageIndex={pageIndex} pageCount={pageCount} />
                                </div>
                            </Card>
                        </Container>
                        <Confirm title={CANCEL_ORDER} text={"Are you sure you want to cancel order " + identifier + "? You cannot undo this action."} buttonText={CANCEL_ORDER} buttonVariant={"danger"} handleConfirmClose={this.handleCancellationConfirmationClose} handleConfirmAction={() => { this.cancelOrder(currentItemID, currentItemSource); }} show={this.state.showCancellationConfirmationModal} />
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default PendingOrders;