import React from 'react';
import NoResults from '../../../common/Tables/NoResults';
import '../PurchaseOrders.scss';

import { Table } from 'react-bootstrap';
import { ProductItem } from './ProductItem';
import { Container } from 'react-bootstrap';

export const ProductListTable = ({ products, companyProducts, handleAdd, handleDelete, handleSelectChange, handleInputChange }) => {

    return (
        <>
            <Container fluid>
                <div className="table-responsive">
                    <Table striped bordered size="sm" >
                        <thead>
                            <tr className='table-header'>
                                <th id='product-header'>Product</th>
                                <th id='ean-header' className='header-centered'>EAN/SUID</th>
                                <th id='sku-header' className='header-centered'>SKU</th>
                                <th id='quantity-header' className='header-centered'>Quantity Expected</th>                
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                products.length !== 0
                                ? products.map((product, index) => 
                                    <ProductItem 
                                        key={ index } 
                                        product={ product } 
                                        companyProducts={ companyProducts }
                                        handleDelete={ handleDelete } 
                                        products={ products }
                                        handleSelectChange = { handleSelectChange }
                                        handleInputChange={ handleInputChange }
                                        handleAdd={ handleAdd }
                                    />
                                )
                                : <NoResults colSpan="6" text="No products added." /> 
                            }
                        </tbody>
                        <tfoot>
                            { 
                                companyProducts.length >= 1 &&
                                <tr>
                                    <th className='addproduct-button' onClick={ handleAdd }>
                                        <span className='dot mr-2'><span>+</span></span>                                    
                                        Add product
                                    </th>
                                </tr>
                            }
                        </tfoot>
                    </Table>
                </div>
            </Container>
        </>
    )
}
