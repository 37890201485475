import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { GET, PUT } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import { ErrorAlert } from '../../common/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { isNullOrEmptyGuid, checkPermission } from '../../../Utilities';
import OrderBundleDetails from './OrderBundleDetails';
import {
    OrderBreakdown,
    ShipmentDetails,
    CostDetails,
    AwaitingShipmentDetails,
    DeliveryDetails,
    ProductDetailsLegacyOrders,
    ProductSummary,
    CancellableItems,
    CancelItemsConfirm,
    CancelItemConfirm
} from './shared/OrderDetailsSnippets';

const AWAITING_COLLECTION_STATUS = "Awaiting Collection";
const COLLECTION_BOOKED_STATUS = "Collection Booked";
const SHIPPED_STATUS = "Shipped";
const PROCESSED_LINK = "/retailer/orders/processedorders";
const INPROCESS_LINK = "/retailer/orders/inprocessorders";
const ERROR_MESSAGE = "There was a problem retrieving the order. Please try again."

const getReturnLink = (statusName) => statusName === AWAITING_COLLECTION_STATUS || statusName === COLLECTION_BOOKED_STATUS || statusName === SHIPPED_STATUS ? PROCESSED_LINK : INPROCESS_LINK;

class OrderDetails extends Component {

    state = {
        orderId: (this.props.match && this.props.match.params) ? this.props.match.params.id : null,
        order: null,
        loading: true,
        error: null,
        subtotal: 0,
        totalCost: 0,
        vatCharge: 0,
        adminCharge: 0,
        adminRate: 0,
        trackingUrls: [],
        products: null,
        showProductBundle: false,
        selectedProductBundle: null,
        showCancellableItems: false,
        cancellableItems: [],
        showCancellableShowError: false,
        showCancelFailedError: false,
        showCancelSuccess: false,
        showCancelItemsModal: false,
        showCancelItemModal: false,
        cancelItemId: null
    };

    async componentDidMount() {
        await this.fetchOrderDetails();

        await Promise.all([
            this.fetchTrackingRootUrls(),
            this.fetchItems()
        ]);
        this.setState({ loading: false });
    }

    fetchOrderDetails = async () => {
        const { orderId } = this.state;
        if (!isNullOrEmptyGuid(orderId)) {
            return GET(Endpoints.ORDERS.GET.DETAILS + orderId)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        this.setState({ error: ERROR_MESSAGE });
                    }
                })
                .then(result => {
                    this.setState({ order: result.data ? result.data : null, error: result.data ? null : ERROR_MESSAGE });
                })
                .catch(error => {
                    this.setState({ error: ERROR_MESSAGE });
                    console.log(error);
                });
        }
    }

    fetchTrackingRootUrls = async () => {
        const { order } = this.state;
        if (order) {
            for (let i = 0; i < order.providersAndParcelReferences.length; i++) {
                const papr = order.providersAndParcelReferences[i];
                await this.fetchTrackingRootUrl(papr.providerId, papr.parcelReference);
            }
        }
    }

    fetchTrackingRootUrl = async (providerId, parcelReference) => {
        return GET(Endpoints.RETAILER.GET.ORDER_TRACKING_ROOT_URL + providerId)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                if (result !== undefined) {
                    const joinedTrackingUrls = this.state.trackingUrls.concat({ "url": result.data + parcelReference });
                    this.setState({ trackingUrls: joinedTrackingUrls });
                }
            })
            .catch(error => console.log(error));
    }

    fetchItems = async () => {
        const { order } = this.state;
        if (order && !order.orderContents) {
            return GET(Endpoints.ORDERS.GET.ITEMS + order.id + '/items')
                .then(response => {
                    if (response.ok) return response.json();
                })
                .then(result => {
                    if (result !== undefined) {
                        this.setState({ products: result.data });
                    }
                })
                .catch(error => console.log(error));
        }
    }

    fetchCancellableItems = () => {
        const { orderId } = this.state;
        if (!isNullOrEmptyGuid(orderId)) {
            return GET(Endpoints.RETAILER.GET.CANCELLABLE_ITEMS.replace("{id}", orderId))
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        this.setState({ showCancellableShowError: true, loading: false });
                    }
                })
                .then(result => {
                    this.setState({ cancellableItems: result.data ? result.data : null, showCancellableShowError: false, showCancelFailedError: false, loading: false });
                })
                .catch(error => {
                    this.setState({ showCancellableShowError: true, loading: false });
                    console.log(error);
                });
        }
    }

    cancelAllItems = () => {
        const { orderId } = this.state;
        this.setState({ loading: true });
        PUT(Endpoints.RETAILER.PUT.CANCEL_ALL_ITEMS.replace("{id}", orderId))
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    this.setState({ showCancellableShowError: true, loading: false });
                }
            })
            .then(result => {
                this.setState(prevState => ({ showCancelSuccess: result.data, showCancelFailedError: !result.data, cancellableItems: result.data ? [] : prevState.cancellableItems, loading: false }));
            })
            .catch(error => {
                this.setState({ showCancellableShowError: true, loading: false });
                console.log(error);
            });
    }

    cancelItem = () => {
        const { orderId, cancelItemId } = this.state;
        this.setState({ loading: true });
        PUT(Endpoints.RETAILER.PUT.CANCEL_ITEM.replace("{id}", orderId).replace("{itemId}", cancelItemId))
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    this.setState({ showCancelFailedError: true, showCancelSuccess: false, loading: false });
                }
            })
            .then(result => {
                result.data
                    ? this.setState({ showCancelSuccess: true, showCancelFailedError: false, cancellableItems: result.data, loading: false })
                    : this.setState({ showCancelSuccess: false, showCancelFailedError: true, loading: false });
            })
            .catch(error => {
                this.setState({ showCancelFailedError: true, showCancelSuccess: false, loading: false });
                console.log(error);
            });
    }

    getTotalProducts = (orderContents) => {
        let companyItems = 0;
        let productBundles = 0;

        if (orderContents) {
            if (orderContents.orderedCompanyItems.length > 0) companyItems = orderContents.orderedCompanyItems.reduce((prev, cur) => prev + cur.quantity, 0)
            if (orderContents.orderedProductBundles.length > 0) {
                orderContents.orderedProductBundles.map((orderedProductBundle) => {
                    productBundles += orderedProductBundle.productBundle.totalProducts * orderedProductBundle.quantity;
                });
            }
        }

        return companyItems + productBundles;
    }

    hideProductBundleDetails = async () => this.setState({ showProductBundle: false });

    showProductBundleDetails = async (productBundle) => this.setState({ showProductBundle: true, selectedProductBundle: productBundle });

    onCancelClick = () => {
        this.setState({ loading: true, showCancellableItems: true });
        this.fetchCancellableItems();
    }

    onCancelItemsReturnClick = () => this.setState({ showCancellableItems: false, showCancelFailedError: false, showCancellableShowError: false });

    onCancelItemsModalShowHide = () => this.setState(prevState => ({ showCancelItemsModal: !prevState.showCancelItemsModal }));
    onCancelItemModalShowHide = () => this.setState(prevState => ({ showCancelItemModal: !prevState.showCancelItemModal }));

    onCancelItemClick = (itemId) => {
        this.setState({ cancelItemId: itemId });
        this.onCancelItemModalShowHide();
    }

    onCancelItemsAction = () => {
        this.onCancelItemsModalShowHide();
        this.cancelAllItems();
    }

    onCancelItemAction = () => {
        this.onCancelItemModalShowHide();
        this.cancelItem();
    }

    render() {
        const { order, cancellableItems, loading, error, trackingUrls, products, showProductBundle, selectedProductBundle, showCancellableItems, showCancellableShowError, showCancelFailedError, showCancelSuccess, showCancelItemsModal, showCancelItemModal } = this.state;

        if (showProductBundle) {
            return (
                <Container fluid>
                    <Row>
                        <OrderBundleDetails productBundle={selectedProductBundle} hideDetails={this.hideProductBundleDetails} />
                    </Row>
                </Container>
            );
        }

        return (
            loading
                ? <LoadingBar />
                : <Container fluid>
                    <Row>
                        <Col sm={12} md={6}>
                            {showCancellableItems
                                ? <Fragment>
                                    <CancellableItems cancellableItems={cancellableItems} onCancelItemsReturnClick={this.onCancelItemsReturnClick} onCancelItemsAllClick={this.onCancelItemsModalShowHide} onCancelItemClick={this.onCancelItemClick} showCancellableShowError={showCancellableShowError} showCancelFailedError={showCancelFailedError} showCancelSuccess={showCancelSuccess} />
                                    <CancelItemsConfirm showCancelItemsModal={showCancelItemsModal} handleCancelItemsAction={this.onCancelItemsAction} handleCancelItemsClose={this.onCancelItemsModalShowHide} />
                                    <CancelItemConfirm showCancelItemModal={showCancelItemModal} handleCancelItemAction={this.onCancelItemAction} handleCancelItemClose={this.onCancelItemModalShowHide} />
                                </Fragment>
                                : error
                                    ? <Fragment>
                                        <h2 className="mb-4">Order Details</h2>
                                        <ErrorAlert errorMessage={error} />
                                    </Fragment>
                                    : <Fragment>
                                        <Link to={getReturnLink(order.status.name)} className="link-button">
                                            <Button variant="link" className="button-nav-return p-0  mb-4"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Orders</Button>
                                        </Link>
                                        <h2 className="mb-4">Order Details</h2>

                                        <OrderBreakdown customerReference={order.customerReference} internalReference={order.internalReference} received={order.received} customerOrganisationPersonName={order.customerOrganisationPersonName} statusName={order.status.name} />
                                        {order.orderContents
                                            ? <ProductSummary orderContents={order.orderContents} showProductBundleDetails={this.showProductBundleDetails} totalProductsOrdered={this.getTotalProducts(order.orderContents)} totalCancelledProducts={order.cancelledItemsCount} />
                                            : <ProductDetailsLegacyOrders products={products} />
                                        }
                                        <h6>Shipment Info</h6>
                                        {order.shipments.length > 0
                                            ? order.shipments.map((shipment, i) => <ShipmentDetails key={`shipment=${i}`} index={i} shipment={shipment} />)
                                            : <div className="section-item">
                                                <p>Shipment details currently not available</p>
                                            </div>
                                        }
                                        {(order.cancelledPartialOrderItems.length > 0 || order.pendingPartialOrderItems.length > 0|| order.failedPartialOrderItems.length > 0) &&
                                            <Fragment>
                                                <h6>Awaiting Shipment</h6>
                                                <AwaitingShipmentDetails cancelledPartialOrderItems={order.cancelledPartialOrderItems} pendingPartialOrderItems={order.pendingPartialOrderItems} failedPartialOrderItems={order.failedPartialOrderItems} onCancelClick={this.onCancelClick} />
                                            </Fragment>
                                        }
                                        {checkPermission('ViewInvoice', <CostDetails orderCostBreakdown={order.orderCostBreakdown} />)}
                                        <DeliveryDetails customerOrganisationDepartmentName={order.customerOrganisationDepartmentName} customerAddressLine1={order.customerAddressLine1} customerAddressLine2={order.customerAddressLine2}
                                            customerAddressTown={order.customerAddressTown} customerAddressPostcode={order.customerAddressPostcode}
                                            parcelReferences={order.parcelReferences} trackingUrls={trackingUrls}
                                            providerName={order.providerNames.length > 0 ? order.providerNames.join(', ') : "N/A"} />
                                    </Fragment>
                            }
                        </Col>
                    </Row>
                </Container>
        )
    }
}

export default OrderDetails;