import React from 'react';
import { Button, Col, Form, FormGroup, Row, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const RemittanceAdviceFilter = ({ remittanceAdviceNumber, dateFrom, dateTo, onSearch, onFilterChange, onClear }) =>
    <Form onSubmit={onSearch}>
        <FormGroup>
            <Row>
                <Col md={3}>
                    <InputGroup>
                        <FormControl id="remittanceAdviceNumberField" className="input-filter" type="text" name="remittanceAdviceNumber" placeholder="Search by Reference" value={remittanceAdviceNumber} onChange={onFilterChange} />
                        <InputGroup.Append>
                            <InputGroup.Text className="input-filter-icon" onClick={onSearch}>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Col md={4}>
                    <InputGroup>
                        <FormControl id="dateFromField" className="input-filter" type="date" name="dateFrom" value={dateFrom} onChange={onFilterChange} />
                        <FormControl id="dateToField" className="input-filter" type="date" name="dateTo" value={dateTo} onChange={onFilterChange} />
                    </InputGroup>
                </Col>
            </Row>
        </FormGroup>

        <FormGroup>
            <Button variant="primary" className="float-right" type="submit">Apply filter</Button>
            <Button variant="link" className="float-right" onClick={onClear}>Clear filter</Button>
        </FormGroup>
    </Form>

export default RemittanceAdviceFilter;