import { GET, extractData } from '../../../../Consumer';
import Endpoints from '../../../common/Endpoints';

export const fetchGetStockBoxLibraryItems = async (pageIndex, signal) => {
  let url = new URL(Endpoints.STOCK_BOX_LIBRARY.GET.ALL);
  url.searchParams.append("pageIndex", pageIndex);
  const response = await GET(url, signal);

  let data = {};
  let warning = '';
  if (response.ok) {
    data = extractData(await response.json());
    warning = data.items === 0 ? 'No stock box library found' : '';
  }
  
  return { data, warning };
}