const USER_API = process.env.REACT_APP_USER_API;
const SUPPLIER_API = process.env.REACT_APP_SUPPLIER_API;
const COURIER_API = process.env.REACT_APP_COURIER_API;
const RETAILER_API = process.env.REACT_APP_RETAILER_API;
const INTERNAL_API = process.env.REACT_APP_SELAZAR_API;

const Endpoints = {
  LOGIN: USER_API + 'api/user/login',
  GET_FILTERED_USERS: USER_API + 'api/user/by',
  GET_USER: USER_API + 'api/user/getbyuserid/',
  GET_USER_WITH_ROLES: USER_API + 'api/user/getuserwithroles/',
  DELETE_USER: USER_API + 'api/user/',
  ADD_COMPANY_USER: USER_API + 'api/user/createforcompany',
  EDIT_COMPANY_USER: USER_API + 'api/user/editforcompany',
  EDIT_USER_DETAILS: USER_API + 'api/user/editdetails',
  TOGGLE_USER: USER_API + 'api/user/toggleuser/',
  EDIT_PASSWORD_USER: USER_API + 'api/user/editpassword',

  GET_ROLE_OPTIONS_FOR_COMPANY: USER_API + 'api/role/getroleoptionsforcompany/',

  GET_FEATURE_BREAKDOWN_FOR_USER: USER_API + 'api/feature/byuser/',
  UPDATE_OR_CREATE_FEATUREUSER: USER_API + 'api/featureuser/updateorcreatefeatureuser/',

  CATEGORY: {
    GET: {
      ALL: SUPPLIER_API + 'api/category/getall'
    }
  },

  STOCK: {
    GET: {
      ALL: SUPPLIER_API + 'api/companyitem/getstockforcompany',
      BY: SUPPLIER_API + 'api/companyitem/by',
      BY_WITHOUT_STOCK: SUPPLIER_API + 'api/companyitem/bywithoutstock',
      BY_ID: SUPPLIER_API + 'api/companyitem/getbyid/',
      COUNT: SUPPLIER_API + 'api/companyitem/getstockcount/',
      TOTAL_STOCK_COUNT: SUPPLIER_API + 'api/companyitem/totalstock/',
      SKUS: SUPPLIER_API + 'api/companyitem/:id/skus',
      CONSIGNMENTS_BY: SUPPLIER_API + 'api/stockcsv/by',
      BY_ID_WITH_ITEMS: SUPPLIER_API + 'api/stockcsv/getbyidwithitems/',
      MAX_LIMIT_UPLOADS: SUPPLIER_API + 'api/stockcsv/getmaxitemuploads/',
      QUARANTINE: SUPPLIER_API + 'api/stockcsv/quarantine',
      QUALITY_REPORT: SUPPLIER_API + 'api/stockcsv/qualityreport/',
      EXPORT_CSV: SUPPLIER_API + 'api/companyitem/export',
      PRODUCT_BUNDLES: SUPPLIER_API + 'api/productbundles',
      PRODUCT_BUNDLE: SUPPLIER_API + 'api/productbundle/',
      PRODUCT_BUNDLE_SKU_CHECK: SUPPLIER_API + 'api/productbundle/sku/',
      GET_RETURN_INFO_BY_ID: SUPPLIER_API + 'api/productreturnsettings/getforcompanyitem/',
      INACTIVE: SUPPLIER_API + 'api/companyitem/inactive',
      FILE_BY_ID: SUPPLIER_API + 'api/stockcsv/getfilebyid/',
      LABEL_DOWNLOAD: SUPPLIER_API + 'api/stockconsignmentlabel/labeldownload/'
    },
    POST: {
      VALIDATE_CSV: SUPPLIER_API + 'api/stockcsv/validatenumberofitemstoupload',
      UPLOAD_CSV: SUPPLIER_API + 'api/stockcsv/upload',
      UPLOAD_ITEM_CSV: SUPPLIER_API + 'api/companyitemcsv/upload',
      CREATE: SUPPLIER_API + 'api/companyitem/create',
      UPLOAD_IMAGES: SUPPLIER_API + 'api/itemimage/upload/',
      UPLOAD_IMAGE: SUPPLIER_API + 'api/itemimage/uploadsingle',
      DOWNLOAD_LABELS: SUPPLIER_API + 'api/stockconsignmentlabel/download/',
      QUALITY_REPORT_RESPONSE: SUPPLIER_API + 'api/stockcsv/qualityreport/response',
      STOCK_BOX_VALIDATION: SUPPLIER_API + 'api/stockcsv/boxvalidation',
      STOCK_PFP_VALIDATION: SUPPLIER_API + 'api/stockcsv/pickfrompalletvalidation',
      CLEAR_DELETED_SKU_STOCK_COUNTS: SUPPLIER_API + 'api/companyItem/cleardeletedskustock/',
      PRODUCT_BUNDLE: SUPPLIER_API + 'api/productbundle',
      UPDATE_RETURN_INFO_BY_ID: SUPPLIER_API + 'api/productreturnsettings/setforcompanyitem/'
    },
    PUT: {
      UPDATE: SUPPLIER_API + 'api/companyitem/update',
      ADD_STOCK: SUPPLIER_API + 'api/stockcsv/uploadsingle/',
      AFFIX_LABELS: SUPPLIER_API + 'api/stockcsv/LabelsAffixed/',
      PRODUCT_BUNDLE: SUPPLIER_API + 'api/productbundle'
    },
    DELETE: {
      ITEM_IMAGE: SUPPLIER_API + 'api/itemimage/delete/',
      IMAGE_FOR_SKU: SUPPLIER_API + 'api/itemimage/deleteforstockkeepingunit/',
      PRODUCT_BUNDLE: SUPPLIER_API + 'api/productbundle/'
    }
  },

  INTEGRATIONS: {
    GET: {
      ALL: RETAILER_API + 'api/integrations',
      INTEGRATION_BY_ID: RETAILER_API + 'api/integration/'
    },
    SHOPIFY: {
      GET: {
        SHIPPING_RATES_PREFIX: RETAILER_API + 'api/integration/shopify/',
        SHIPPING_RATES_SUFFIX: '/shippingrates',
      },
      POST: {
        CREATE: RETAILER_API + 'api/integration/shopify'
      },
      PUT: {
        UPDATE: RETAILER_API + 'api/integration/shopify/'
      }
    },
    WOOCOMMERCE: {
      POST: {
        CREATE: RETAILER_API + 'api/integration/woocommerce'
      },
      PUT: {
        UPDATE: RETAILER_API + 'api/integration/woocommerce/'
      }
    },
    APP: {
      GET: {
        INTEGRATIONS: RETAILER_API + 'api/thirdpartyintegration/all',
        INTEGRATION: RETAILER_API + 'api/thirdpartyintegration/',
        MODULES: RETAILER_API + 'api/thirdpartyintegration/modules'
      },
      PUT: {
        INTEGRATION: RETAILER_API + 'api/thirdpartyintegration/edit'
      },
      POST: {
        INTEGRATION: RETAILER_API + 'api/thirdpartyintegration/create'
      },
      DELETE: {
        INTEGRATION: RETAILER_API + 'api/thirdpartyintegration/'
      }
    }
  },

  PURCHASE_ORDERS: {
    GET: {
      ALL: SUPPLIER_API + 'api/purchaseorder/getpurchaseordersforcompany'
    },
    POST: {
      CREATE: SUPPLIER_API + 'api/purchaseorder/createpurchaseorderforcompany'
    },
    PUT: {
      UPDATE: SUPPLIER_API + 'api/purchaseorder/updatepurchaseorderforcompany',
      CLOSE: SUPPLIER_API + 'api/purchaseorder/close',
      REOPEN: SUPPLIER_API + 'api/purchaseorder/reopen'
    }
  },

  STOCK_BOX_LIBRARY: {
    GET: {
      ALL: SUPPLIER_API + 'api/stockboxlibrary/getstockboxlibrariesforcompany'
    },
    POST: {
      CREATE: SUPPLIER_API + 'api/stockboxlibrary/createstockboxlibraryforcompany'
    },
    PUT: {
      UPDATE: SUPPLIER_API + 'api/stockboxlibrary/updatestockboxlibraryforcompany',
    },
    DELETE: {
      DELETESTOCKBOX: SUPPLIER_API + 'api/stockboxlibrary/deletestockboxlibraryforcompany',
    }
  },

  ORDERS: {
    GET: {
      AWAITING_FULFILMENT_ORDER_COUNT: RETAILER_API + 'api/order/awaitingfulfilment/count',
      ORDER_COUNT: RETAILER_API + 'api/order/countorders',
      ORDER_AVERAGE: RETAILER_API + 'api/order/averagecost',
      POPULAR_ORDERS: RETAILER_API + 'api/order/items/popular',
      TOTALS_BY_AREA: RETAILER_API + 'api/order/area/totals',
      FULFILMENT_TOTALS: RETAILER_API + 'api/order/fulfilment/totals/today',
      TOTALS_BY_DATE: RETAILER_API + 'api/order/totals/bydate',
      COSTS_BY_DATE: RETAILER_API + 'api/order/costs/bydate',
      UNPROCESSED: RETAILER_API + 'api/order/unprocessed',
      UNPROCESSED_TODAY: RETAILER_API + 'api/order/unprocessed/totals/today',
      AWAITING_FULFILMENT_TODAY: RETAILER_API + 'api/order/awaitingfulfilment/totals/today',
      COMPLETED_TODAY: RETAILER_API + 'api/order/completed/totals/today',
      RECENT_FAILED: RETAILER_API + 'api/order/failed/recent',
      TRACKING: RETAILER_API + 'api/order/tracking',
      ITEMS: RETAILER_API + 'api/order/',
      DETAILS: RETAILER_API + 'api/order/details/',
      ON_HOLD_DETAILS: RETAILER_API + 'api/order/queue/details/',
      UNSUCCESSFUL_DETAILS: RETAILER_API + 'api/order/unsuccessful/details/'
    },
    POST: {
      UPLOAD: RETAILER_API + 'api/ordercsv/upload'
    }
  },

  COMPANY: {
    GET: {
      COMPANY_BY_ID: USER_API + "api/company/getbyid/"
    }
  },

  FINANCE: {
    CARD: {
      POST: {
        NEW: RETAILER_API + 'api/carddetails/addcard'
      },
      DELETE: {
        REMOVE: RETAILER_API + 'api/carddetails/remove'
      },
      PUT: {
        REPLACE: RETAILER_API + 'api/carddetails/replace'
      },
      GET: {
        LATEST: RETAILER_API + 'api/carddetails/latest',
        HAS_VALID_DETAILS: RETAILER_API + 'api/carddetails/checkcardvalidity'
      }
    },
    DIRECT_DEBIT: {
      GET_FOR_COMPANY: USER_API + 'api/companydirectdebit/getforcompany',
      ENABLED: USER_API + 'api/companydirectdebit/enabled',
      CREATE: RETAILER_API + 'api/directdebitdetails/createsession',
      CURRENT: RETAILER_API + 'api/directdebitdetails/current',
      DISABLE_CURRENT: RETAILER_API + 'api/directdebitdetails/disablecurrent',
      REMOVE_CURRENT: RETAILER_API + 'api/directdebitdetails/removecurrent',
      HAS_VALID_DETAILS: RETAILER_API + 'api/directdebitdetails/checkcardvalidity'
    }
  },

  INVOICES: {
    GET: {
      ALL: INTERNAL_API + 'api/invoice/getforcompany',
      TYPES: INTERNAL_API + 'api/invoice/gettypes'
    },
    POST: {
      REGENERATE_INVOICE_LINK: INTERNAL_API + 'api/Invoice/regenerateinvoicelink/'
    }
  },

  COLLECTION_ADVICE: {
    GET: {
      ALL: INTERNAL_API + 'api/collectionadvice/getforcompany'
    }
  },

  REMITTANCE_ADVICE: {
    GET: {
      ALL: INTERNAL_API + 'api/remittanceadvice/getforcompany'
    }
  },

  RETAILER: {
    GET: {
      ORDERS: RETAILER_API + 'api/order/getall',
      ALLORDERS: RETAILER_API + 'api/order/allorders',
      PENDING: RETAILER_API + 'api/order/pending',
      UNSUCCESSFUL: RETAILER_API + 'api/order/unsuccessful',
      PROCESSING: RETAILER_API + 'api/order/processing',
      PROCESSED: RETAILER_API + 'api/order/processed',
      ORDER_COST_BREAKDOWN: RETAILER_API + 'api/order/costbreakdown/',
      ORDER_TRACKING_ROOT_URL: RETAILER_API + 'api/order/trackingrooturl/',
      PROCESSED_RETURNS: RETAILER_API + 'api/return/processedreturns',
      UNPROCESSED_RETURNS: RETAILER_API + 'api/return/unprocessedreturns',
      COURIER_PARTNER_PREFERENCES: RETAILER_API + 'api/partnerpreference/courier',
      COURIER_REGIONAL_PREFERENCES: RETAILER_API + 'api/regionpreference',
      SERVICES_TIMES_OPTIONS: RETAILER_API + 'api/regionpreference/options/servicetimes',
      CUSTOMER_SERVICE_ACCESS: RETAILER_API + 'api/securitypreference/customerserviceaccess',
      BULK_ORDER_PENDING: RETAILER_API + 'api/bulkorder/pending',
      BULK_ORDER_PROCESSING: RETAILER_API + 'api/bulkorder/processing',
      BULK_ORDER_PROCESSED: RETAILER_API + 'api/bulkorder/processed',
      BULK_ORDER_UNSUCCESSFUL: RETAILER_API + 'api/bulkorder/unsuccessful',
      BULK_ORDER_DETAILS: RETAILER_API + 'api/bulkorder/details/',
      EORI_DETAILS: RETAILER_API + 'api/taxdetails/eori',
      VAT_DETAILS: RETAILER_API + 'api/taxdetails/vat',
      VERIFY_EORI: RETAILER_API + 'api/taxdetails/verify/',
      PARTIAL_ORDER_PREFERENCE: RETAILER_API + 'api/partialorderpreference',
      PARTIAL_ORDERS: RETAILER_API + 'api/order/partial',
      ON_HOLD_ORDERS: RETAILER_API + 'api/order/queue',
      CANCELLABLE_ITEMS: RETAILER_API + 'api/order/partial/{id}/cancellable',
      IOSS_NUMBER: RETAILER_API + 'api/taxdetails/ioss'
    },
    POST: {
      COURIER_PARTNER_PREFERENCES: RETAILER_API + 'api/partnerpreference/courier',
      COURIER_REGIONAL_PREFERENCES: RETAILER_API + 'api/regionpreference',
      SECURITY_PREFERENCE: RETAILER_API + 'api/securitypreference',
      BULK_ORDER_SKU_SPLIT_VALIDATE: RETAILER_API + 'api/bulkorder/skusplit/validate/',
      BULK_ORDER_NO_PACKAGING_VALIDATE: RETAILER_API + 'api/bulkorder/nopackaging/validate',
      BULK_ORDER_MANUAL_SPLIT_VALIDATE: RETAILER_API + 'api/bulkorder/manualsplit/validate',
      BULK_ORDER_SELECTED_ITEMS_VALIDATE: RETAILER_API + 'api/bulkorder/selectedstock/validate',
      BULK_ORDER_SUBMIT: RETAILER_API + 'api/bulkorder/submit',
      BULK_ORDER_UPDATEPAYLOAD: RETAILER_API + 'api/bulkorder/updatepayload/',
      UPDATE_EORI: RETAILER_API + 'api/taxdetails/eori',
      REMOVE_EORI: RETAILER_API + 'api/taxdetails/eori/remove/',
      UPDATE_VAT: RETAILER_API + 'api/taxdetails/vat',
      PARTIAL_ORDER_PREFERENCE: RETAILER_API + 'api/partialorderpreference/toggle/',
      IOSS_NUMBER: RETAILER_API + 'api/taxdetails/ioss',
      IOSS_NUMBER_REMOVE: RETAILER_API + 'api/taxdetails/ioss/remove'
    },
    PUT: {
      CANCEL_ORDER: RETAILER_API + 'api/ordercsv/cancelorder',
      CANCEL_SHOPIFY_ORDER: RETAILER_API + 'api/shopifyorder/cancel',
      CANCEL_WOOCOMMERCE_ORDER: RETAILER_API + 'api/woocommerceorder/cancel',
      BULK_ORDER_CANCEL: RETAILER_API + 'api/bulkorder/cancel/',
      CANCEL_ALL_ITEMS: RETAILER_API + 'api/order/partial/{id}/cancel',
      CANCEL_ITEM: RETAILER_API + 'api/order/partial/{id}/cancel/{itemId}'
    }
  },

  COURIER: {
    GET: {
      ACTIVE: COURIER_API + 'api/company/active'
    }
  },

  REGISTRATION: {
    GET: {
      CONFIRM_EMAIL_REQUEST: USER_API + 'api/confirmemailrequest/getliverequestbyid/'
    },
    POST: {
      RETAILER: {
        REGISTER: USER_API + 'api/registration/retailer'
      },
      EMAIL: {
        CONFIRM: USER_API + 'api/confirmemailrequest/confirm/'
      }
    }
  },

  PASSWORD_RESET: {
    GET: {
      BY_ID: USER_API + 'api/passwordresetrequest/getliverequestbyid/'
    },
    PUT: {
      CHANGE_PASSWORD: USER_API + 'api/passwordresetrequest/changepassword',
      RESET_PASSWORD: USER_API + 'api/passwordresetrequest/resetpassword/'
    }
  },

  INTERNAL: {
    GET: {
      BULK_ORDER_LIMITS: INTERNAL_API + 'api/bulkorder/limits',
      BULK_ORDER_PACKING_SLIP: INTERNAL_API + 'api/BulkOrder/generate/',
      BULK_ORDER_AVAILABLE_SERVICE_TIMES: INTERNAL_API + 'api/bulkorder/getavailableservicetimes',
    },
    POST: {
      BULK_ORDER_FIND_SHIPPING_OPTIONS: INTERNAL_API + 'api/bulkorder/couriers/calculate'
    }
  },

  PACKAGING: {
    GET: {
      CUSTOM_OVERVIEW: RETAILER_API + 'api/custompackaging/overview',
      BOXES: RETAILER_API + 'api/custompackaging/boxes',
      MAILING_BAGS: RETAILER_API + 'api/custompackaging/mailingbags',
      BOX: RETAILER_API + 'api/custompackaging/box/',
      MAILING_BAG: RETAILER_API + 'api/custompackaging/mailingbag/',
      EXTRAS: RETAILER_API + 'api/packagingextra/packagingextras'
    },
    PUT: {
      ARCHIVE: RETAILER_API + 'api/custompackaging/archive/',
      ENABLE: RETAILER_API + 'api/custompackaging/enable/',
      DISABLE: RETAILER_API + 'api/custompackaging/disable/',
      BOX: RETAILER_API + 'api/custompackaging/box/',
      MAILING_BAG: RETAILER_API + 'api/custompackaging/mailingbag/'
    },
    POST: {
      EXTRA: RETAILER_API + 'api/packagingextra',
      BOX: RETAILER_API + 'api/custompackaging/box',
      MAILING_BAG: RETAILER_API + 'api/custompackaging/mailingbag',
      ENFORCE: RETAILER_API + 'api/custompackaging/enforcepackaging'
    }
  },

  WAREHOUSE: {
    GET: {
      SELECTION: RETAILER_API + 'api/warehouse/selection',
      ALL: RETAILER_API + 'api/warehouse/getall',
    },
    POST: {
      SELECTION: USER_API + 'api/warehouse/'
    }
  },

  RETURNS: {
    GET: {
      CONFIGURATION: RETAILER_API + 'api/returns/config',
      RETURN_PREFERENCES: RETAILER_API + 'api/returnspreference/returnpreference',
      OPTIONS: RETAILER_API + 'api/gradingactionspreference/options',
      INFORMATION: RETAILER_API + 'api/gradingactionspreference/information',
      GRADE_PREFERENCES: RETAILER_API + 'api/gradingactionspreference/gradingpreferences',
      PRODUCTS: SUPPLIER_API + 'api/companyitem/noimage',
      PROCESSED_RETURNS: RETAILER_API + 'api/retailerreturns/processedreturns',
      PROCESSED_RETURN_BY_ID: RETAILER_API + 'api/retailerreturns/',
      WAREHOUSE_IMAGE_BY_ID: RETAILER_API + 'api/retailerreturns/image/',
    },
    POST: {
      TOGGLE_RETURN_PREFERENCES: RETAILER_API + 'api/returnspreference/toggle',
      RETURN_APPLY_TO_ALL: RETAILER_API + 'api/returnspreference/all',
      RETURN_APPLY_TO_NEW: RETAILER_API + 'api/returnspreference/new',
      TOGGLE_GRADING_PREFERENCES: RETAILER_API + 'api/gradingactionspreference/toggle',
      GRADING_APPLY_TO_ALL: RETAILER_API + 'api/gradingactionspreference/all',
      GRADING_APPLY_TO_NEW: RETAILER_API + 'api/gradingactionspreference/new',
    },
    PUT: {
      ENABLE: RETAILER_API + 'api/returns/enable',
      DISABLE: RETAILER_API + 'api/returns/disable',
    }
  },

  COMPANY_CUSTOMER_ADDRESS: {
    GET: {
      GET_CUSTOMER_ADDRESS_FOR_COMPANY: USER_API + 'api/companycustomeraddress/getcompanycustomeraddressforcompany/',
      GET_CUSTOMER_ADDRESS_BY: USER_API + 'api/companycustomeraddress/by/'
    },
    POST: {
      CREATE_CUSTOMER_ADDRESS_FOR_COMPANY: USER_API + 'api/companycustomeraddress/createcompanycustomeraddressforcompany'
    },
    PUT: {
      UPDATE_CUSTOMER_ADDRESS_FOR_COMPANY: USER_API + 'api/companycustomeraddress/updatecompanycustomeraddressforcompany/'
    },
    DELETE: {
      DELETE_CUSTOMER_ADDRESS_FOR_COMPANY: USER_API + 'api/companycustomeraddress/deletecompanycustomeraddressforcompany/'
    }
  }
};

export default Endpoints;
