import React, { Fragment } from 'react';
import Header from '../../../common/Header';
import { Container} from 'react-bootstrap';
import { BULK_ORDER_SUB_NAV_LIST } from '../../../common/constants';
import LoadingBar from '../../../common/LoadingBar';

const BulkOrderManualLoading = () => 
    <Fragment>
        <LoadingBar />
        <Header title="Bulk Order" subNavList={BULK_ORDER_SUB_NAV_LIST} activeKey="Create Bulk Order" />
        <Container fluid>
            <h5>Please Note</h5>
            <h6>Box splits for orders with large quantities of items may take some time to process</h6>
        </Container>
    </Fragment>

export default BulkOrderManualLoading