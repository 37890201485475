import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { toLocalTimeString } from '../../../../Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { GET, extractData } from '../../../../Consumer';
import Endpoints from '../../../common/Endpoints';
import LoadingBar from '../../../common/LoadingBar';
import { ErrorAlert } from '../../../common/Alert';

const PASSWORD_MASK = '**************************';

class ShopifyDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            shippingRates: [],
            loading: true
        }
    }

    async componentDidMount() {
        await Promise.all([
            this.getShippingRates(),
        ]);
        this.setState({ loading: false });
    }

    getShippingRates = async () => {
        const { integration } = this.props;
        const url = new URL(Endpoints.INTEGRATIONS.SHOPIFY.GET.SHIPPING_RATES_PREFIX + integration.id + Endpoints.INTEGRATIONS.SHOPIFY.GET.SHIPPING_RATES_SUFFIX);

        return GET(url)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const shippingRates = extractData(result) || [];
                this.setState({ shippingRates: shippingRates });
            })
            .catch(error => console.log(error));
    }

    checkForMissingRates = (selected) => {
        const { shippingRates } = this.state;
        const isError = selected.findIndex(s => s.shippingRateID !== "" && s.shippingRateID !== null && !shippingRates.flatMap(r => r.shippingRateID).includes(s.shippingRateID)) > -1;
        return isError
            ? <ErrorAlert errorTitle="A Shopify shipping rate has been removed" errorMessage="Update your shipping rate configuration to resolve this." />
            : null;
    }

    getUnnassignedCount = (selected) => {
        const { shippingRates } = this.state;
        const count = shippingRates.length - selected.filter(s => s.shippingRateID != null).length;
        return (count >= 0) ? count : 0;
    }

    render() {
        const { integration, editIntegrationClick, backToIntegrationsClick, showShippingRates, showEditShippingRates } = this.props;
        const { shippingRates, loading } = this.state;
        let credentials = integration.metadata;
        credentials.password = PASSWORD_MASK;
        return (
            loading ? <LoadingBar /> :
                <Container fluid>
                    <Row>
                        <Col sm="12" md="6">
                            <a className="link-button link-pointer" onClick={backToIntegrationsClick}>
                                <FontAwesomeIcon icon={faAngleLeft} />  Return to Integrations
                            </a>
                            <h2 className="mt-4">Integrations</h2>
                            <section>
                                <h5 className="mt-4 integration-section-header">Shopify Credentials</h5>
                                <p className="mt-2">
                                    <p className="title">Store Name</p>
                                    <span className="d-block">{credentials.shopName}</span>
                                </p>
                                <p className="mt-2">
                                    <p className="title">Shopify URL</p>
                                    <span className="d-block">{credentials.storeUrl}</span>
                                </p>
                                <p>
                                    <p className="title">Username</p>
                                    <span className="d-block">{credentials.username}</span>
                                </p>
                                <p>
                                    <p className="title">Password</p>
                                    <span className="d-block">{credentials.password}</span>
                                </p>
                            </section>
                            <section>
                                <h5 className="mt-4 integration-section-header">Credential Information</h5>
                                <p className="mt-2">
                                    <p className="title">Date of Integration</p>
                                    <span className="d-block">{toLocalTimeString(integration.dateOfIntegration)}</span>
                                </p>
                                <p>
                                    <p className="title d-block">Status</p>
                                    {integration.connected ? <span className="text-status-green">Connected</span>
                                        : <span className="text-status-red">Not Connected</span>}
                                </p>
                                <Button variant="link" onClick={editIntegrationClick}>Edit Shopify Credentials</Button>
                            </section>

                            {integration.connected &&
                                <section>
                                    <h5 className="mt-4 integration-section-header">Shopify Shipping Configuration</h5>
                                    {credentials.shopifyShippingRatePreferences ?
                                        <React.Fragment>
                                            <div>
                                                {this.checkForMissingRates(credentials.shopifyShippingRatePreferences)}
                                                <p className="title">Shopify Shipping Summary</p>
                                                <p>Total Shipping Options: {shippingRates.length}</p>
                                                <p>Unassigned Shipping Options:  {this.getUnnassignedCount(credentials.shopifyShippingRatePreferences)}</p>
                                            </div>
                                            <Button variant="link" onClick={() => showShippingRates(false)}>View Shopify Shipping Configuration</Button>
                                        </React.Fragment> :
                                        <React.Fragment>
                                            <ErrorAlert errorTitle="Configuration Not Setup" errorMessage="Currently you do not have your Shopify rates configured. We will automatically send all orders out on the fastest service we have. This can cost more than what your customers have paid for, therefore it is important that you configure your shipping settings on Shopify with Selazar's shipping settings as soon as possible." />
                                            <Button className="float-right" variant="secondary" onClick={() => showEditShippingRates()}>Setup Shopify Configuration</Button>
                                        </React.Fragment>}
                                </section>}
                        </Col>
                    </Row>
                </Container >
        );
    }
}

export default ShopifyDetails;