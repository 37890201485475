import React from 'react';
import { Card } from 'react-bootstrap';

const ErrorAlert = ({ errorTitle = "There seems to be a problem", errorMessage, headerClass = null }) =>
    <Card className={headerClass ? headerClass : "card-danger mb-3"} >
        {errorTitle && <Card.Title>{errorTitle}</Card.Title>}
        <Card.Body>{errorMessage}</Card.Body>
    </Card>

const SuccessAlert = ({ successTitle = "Success", successMessage, headerClass = null }) =>
    <Card className={headerClass ? headerClass : "card-success mb-3"} >
        {successTitle && <Card.Title>{successTitle}</Card.Title>}
        <Card.Body>{successMessage}</Card.Body>
    </Card>

const WarningAlert = ({ warningTitle = "There seems to be a problem", warningMessage, linkRequired, linkPreText, linkPostText, link, linkText }) =>
    <Card className="card-warning mb-3">
        {warningTitle && <Card.Title>{warningTitle}</Card.Title>}
        <Card.Body>
            {linkRequired ? <p>{warningMessage} {linkPreText} <a href={link}>{linkText}</a> {linkPostText}</p> : warningMessage}
        </Card.Body>
    </Card>

const InfoAlert = ({ infoTitle, infoMessage }) =>
    <Card className="card-info mb-3">
        {infoTitle && <Card.Title>{infoTitle}</Card.Title>}
        <Card.Body>{infoMessage}</Card.Body>
    </Card>

const ErrorAlertDynamic = ({ errorMessage }) =>
<Card className="card-danger mb-3">
    <Card.Body>{errorMessage}</Card.Body>
</Card>

export {
    ErrorAlert,
    SuccessAlert,
    WarningAlert,
    InfoAlert,
    ErrorAlertDynamic

}