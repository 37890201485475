import React from 'react';
import { Card, FormGroup, FormLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import Confirm from '../../../common/Confirm';
import { SuccessAlert } from '../../../common/Alert';

import './Snippets.scss';

const CustomPackageForm = ({ isBox, packageDetails, packageValidation, handleInputChange }) => (
    <React.Fragment>
        <FormGroup>
            <FormLabel htmlFor="nameField">Package Name</FormLabel>
            <FormControl id="nameField" type="text" parent="item" name="name" value={packageDetails.name} onChange={handleInputChange} />
            <span className="text-danger">{packageValidation.name.message}</span>
        </FormGroup>
        <FormGroup as={Row}>
            <Col xs={12} md={3}>
                <FormLabel htmlFor="heightField">Height (cm)</FormLabel>
                <FormControl id="heightField" type="number" name="height" value={packageDetails.height} onChange={handleInputChange} min="0" />
            </Col>
            <Col md={12}>
                <span className="text-danger">{packageValidation.height.message}</span>
            </Col>
        </FormGroup>
        <FormGroup as={Row}>
            <Col xs={12} md={3}>
                <FormLabel htmlFor="widthField">Width (cm)</FormLabel>
                <FormControl id="widthField" type="number" name="width" value={packageDetails.width} onChange={handleInputChange} min="0" />
            </Col>
            <Col md={12}>
                <span className="text-danger">{packageValidation.width.message}</span>
            </Col>
        </FormGroup>
        {isBox &&
            <FormGroup as={Row}>
                <Col xs={12} md={3}>
                    <FormLabel htmlFor="depthField">Depth(cm)</FormLabel>
                    <FormControl id="depthField" type="number" name="depth" value={packageDetails.depth} onChange={handleInputChange} min="0" />
                </Col>
                <Col md={12}>
                    <span className="text-danger">{packageValidation.depth.message}</span>
                </Col>
            </FormGroup>
        }
        <FormGroup as={Row}>
            <Col xs={12} md={3}>
                <FormLabel htmlFor="maxWeightField">Weight Limit (kg)</FormLabel>
                <FormControl id="maxWeightField" type="number" name="maxWeight" value={packageDetails.maxWeight} onChange={handleInputChange} min="0" />
            </Col>
            <Col md={12}>
                <span className="text-danger">{packageValidation.maxWeight.message}</span>
            </Col>
        </FormGroup>
    </React.Fragment>
);

const CustomPackageFormActions = ({ buttonText, handleCancel, handleAction }) => (
    <div className="float-right">
        <Button variant="link" className="mr-3" onClick={handleCancel}>Cancel</Button>
        <Button variant="primary" onClick={handleAction}>{buttonText}</Button>
    </div>
);

const CancelModal = ({ isBox, isAdd, handleClose, handleConfirm, showModal }) => <Confirm
    title={`Cancel ${isAdd ? "Adding" : "Updating"} Custom ${isBox ? "Box" : "Mailing Bag"}`}
    text={`Are you sure you want to cancel ${isAdd ? "adding a" : "updating this"} custom ${isBox ? "box" : "mailing bag"}?`}
    buttonText={`Cancel ${isAdd ? "Adding" : "Updating"} ${isBox ? "Box" : "Mailing Bag"}`}
    buttonVariant="danger"
    handleConfirmClose={handleClose}
    handleConfirmAction={handleConfirm}
    show={showModal}
    cancelText="Cancel"
/>

const DeleteModal = ({ isBox, packageName, handleClose, handleDeletePackage, showModal }) => <Confirm
    title={`Delete ${packageName}`}
    text={`Are you sure you want to delete ${packageName}? Please be aware that any in progress orders using this custom ${isBox ? "box" : "mailing bag"} will not be affected.`}
    buttonVariant="danger"
    buttonText="Delete"
    handleConfirmClose={handleClose}
    handleConfirmAction={handleDeletePackage}
    show={showModal}
    cancelText="Cancel"
/>

const AddPackageCard = ({ isBox, handleAddPackageClick }) => (
    <Col sm={12} md={4} className="mb-4">
        <Card className="card-add">
            <p><strong>{`Want to add another custom ${isBox ? "box" : "mailing bag"}?`}</strong></p>
            <Button variant="secondary" onClick={handleAddPackageClick}>{`Add Custom ${isBox ? "Box" : "Mailing Bag"}`}</Button>
        </Card>
    </Col>
)

const ViewCustomPackageCard = ({ isBox, customPackage, handleEnableToggle, handleEditClick, handleDeleteClick }) => (
    <Col sm={12} md={4} className="mb-4">
        <Card className="card-package-details">
            <h5>{customPackage.friendlyName}</h5>
            <p className="mb-1"><strong>Dimensions (cm)</strong> {`${customPackage.height}x${customPackage.width}${isBox ? `x${customPackage.depth}` : ""}`}</p>
            <p><strong>Weight Limit (kg)</strong> {customPackage.maxWeight}</p>
            <label className="switch mb-3">
                <input id={isBox ? "box-toggle" : "bag-toggle"} type="checkbox" onChange={handleEnableToggle} checked={customPackage.enabled} />
                <span className="slider round"><span className="toggle-text">{customPackage.enabled ? "Enabled" : "Disabled"}</span></span>
            </label>
            <div>
                {!customPackage.enabled && <React.Fragment>
                    <Button variant="link" className="p-0 pr-1" onClick={handleEditClick}>Edit</Button>
                    <span>|</span>
                </React.Fragment>}
                <Button variant="link" className="p-0 pl-1 link-danger" onClick={handleDeleteClick}>Delete</Button>
            </div>
        </Card>
    </Col>
)

const SuccessfulAddPackage = ({ name }) => {
    return (
        <React.Fragment>
            <SuccessAlert successMessage={`${name} has been added successfully`} />
            <div className="form-input-description">
                <p className="pt-2"><strong>Next Steps</strong></p>
                <p className="pb-2">We will be in contact when we receive your packaging at the warehouse. Do not enable your packaging option before we have confirmed that it has been received as it can cause issues with orders.</p>
            </div>
        </React.Fragment>
    )
}

export {
    CustomPackageForm,
    CustomPackageFormActions,
    CancelModal,
    DeleteModal,
    AddPackageCard,
    ViewCustomPackageCard,
    SuccessfulAddPackage
}