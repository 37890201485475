import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { POST, PUT } from '../../../../Consumer';
import Endpoints from '../../../common/Endpoints';

export const usePurchaseOrder = ({ poReference = '', items = [], purchaseOrderID = null, statusID = null }) => {

    let newProduct = {
        name      : '',
        ean       : '',
        sku       : '',
        quantity  : '',
        isActive  : true
    }

    let initialProducts = items.length > 0 ? items : [newProduct];

    const [purchaseOrderReference, setPurchaseOrderReference] = useState(poReference);
    const [isPOReferenceEmpty, setIsPOReferenceEmpty] = useState(false);
    const [products, setProducts] = useState(initialProducts);
    const [companyProducts, setCompanyProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saveError, setSaveError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [poReferenceExists, setPOReferenceExists] = useState(false);
    const [initialState, setInitialState] = useState({ products: [...products], purchaseOrderReference});
    const [stateHasChanged, setStateHasChanged] = useState(false);

    const { id } = useParams();

    const handleAdd = () => {
        if (products.length === 0) {
            setProducts([newProduct]);
            return;
        }

        if(hasEmptyProduct()) return;
        
        const inactiveProducts = changeLastProductStatus();
        const newCompanyProductsState = companyProducts.filter(cp => !inactiveProducts.some(ip => ip.sku === cp.sku));

        if(newCompanyProductsState.length === 0) {
            setProducts([...inactiveProducts]);
        } else {
            setProducts([...inactiveProducts, newProduct]);   
        }

        setCompanyProducts([...newCompanyProductsState]);
    }
    
    const changeLastProductStatus = () => {
        if (products.length >= 1) {
            return products.map((product) => { 
                return { ...product, isActive: false } 
            });
        }
    }
      
    const handleDelete = ( product ) => {
        if(products.length === 1) return;
        const index = products.indexOf(product);
        products.splice(index, 1);
        setProducts([...products]);

        if(product.name !== '') {
            if (!companyProducts.includes(product)) {
                const newProduct = { ...product, isActive: true };
                setCompanyProducts(prevState => [...prevState, newProduct]);
            }
        }
    }

    const handleSelectChange = ( value ) => {
        products.pop();
        if (value !== undefined && value !== null) {
            setProducts([...products, value]);
            return;
        }

        setProducts([...products, newProduct]);
    }

    
    const handleInputChange = ( product, value ) => {
        if (value.trim().length === 0) value = '';

        const newProductState = products.map((obj, index) => {
            if (products.indexOf(product) === index ) {
                return { ...obj, quantity: value, isQuantityEmpty: false}
            } 
            return obj;
        });

        setProducts([...newProductState]);
    }

    const hasEmptyProduct = () => {
        const newProductsState = products.map(product => {
            if (product.name === '') return { ...product, isEmpty: true };
            return product;
        });

        setProducts([...newProductsState]);
        return newProductsState.filter(product => product.isEmpty).length >= 1;
    }

    const request = async () => {
        const requestBody = await createRequestBody();

        if (id) {
            return PUT(Endpoints.PURCHASE_ORDERS.PUT.UPDATE, requestBody);
        }
        
        return POST(Endpoints.PURCHASE_ORDERS.POST.CREATE, requestBody);

    }

    const handleSubmit = async () => {
        const isValidPurchaseOrder = validatePurchaseOrder();
        if (isValidPurchaseOrder) {
            setLoading(true);

            try {
                const response = await request();
                const data = await response.json();

                if (!data.error) {
                    setSuccessStatus();
                } else {
                    const poReferenceAlreadyExists = data.message.toLowerCase().indexOf('already exists') > -1;
                    if (poReferenceAlreadyExists) {
                        setPOReferenceExists(true);
                    } else {
                        throw new Error();
                    }
                }
            } catch (error) {
                setSaveError(true);
                setShowSuccess(false);
                console.log(error);
            } finally {
                setLoading(false);
                setStateHasChanged(false);
            } 
        }
    }

    const validatePurchaseOrder = () => {
        const isValidPurchaseOrder = validatePurchaseOrderReference();
        const areValidProducts = validateProducts();
        
        return areValidProducts && isValidPurchaseOrder;
    }

    const validateProducts = () => {
        const newProductsState = products.map(product => {
            let newProduct = { ...product };
            if (product.name === '') {
                newProduct = { ...newProduct, isEmpty: true }
            }

            if (product.quantity === '') {
                newProduct = { ...newProduct, isQuantityEmpty: true }
            }

            return newProduct
        });

        setProducts([...newProductsState]);
        return !newProductsState.some(product => product.isEmpty || product.isQuantityEmpty);
    }

    const validatePurchaseOrderReference = () => {
        const hasPOReference = purchaseOrderReference !== '';
        if (!hasPOReference) {
          setIsPOReferenceEmpty(true);
        }
    
        return hasPOReference;
    }

    const createRequestBody = () => {        
        const productsRequestBody = products.map(product => {
            return {
                companyItemID: product.companyItemID,
                sku: product.sku,
                quantity: product.quantity,
                ...(purchaseOrderID && { purchaseOrderID })
            }
        });

        return {
            purchaseOrderReference: purchaseOrderReference.trim().toUpperCase(),
            lines: [
                ...productsRequestBody,
            ],
            ...(statusID && { statusID })
        }
    }

    const resetPurchaseOrderState = () => {

        const newCompanyProductsState = products.filter(product => !companyProducts.some(cp => cp.companyItemID === product.companyItemID));

        if (purchaseOrderID) {
            const newProductsState = products.map(product => {
                return {...product, isActive: false };
            });
            setProducts([...newProductsState]);
            setInitialState({ products: [...newProductsState], purchaseOrderReference });
            return;
        }

        setCompanyProducts([...companyProducts, ...newCompanyProductsState]);
        setProducts([newProduct]);
        setPurchaseOrderReference('');
        setInitialState({ products: [newProduct], purchaseOrderReference: '' });
    }

    const setSuccessStatus = () => {
        setSaveError(false);
        setLoading(false);
        setShowSuccess(true);
        setPOReferenceExists(false);
        resetPurchaseOrderState();
    }

    return {
        products,
        setProducts,
        companyProducts,
        setCompanyProducts,
        purchaseOrderReference,
        isPOReferenceEmpty,
        setPurchaseOrderReference,
        setIsPOReferenceEmpty,
        loading,
        setLoading,
        handleAdd,
        handleDelete,
        handleSelectChange,
        handleInputChange,
        handleSubmit,
        saveError,
        showSuccess,
        poReferenceExists,
        setPOReferenceExists,
        stateHasChanged,
        setStateHasChanged,
        id,
        initialState
    }

}
