import React, { Component } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Endpoints from '../../common/Endpoints';
import { GET, extractData } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import { ErrorAlert, SuccessAlert } from '../../common/Alert';

import './Integrations.scss';

const SFP = 'Self Fulfilled Package';
const SFP_VALUE = 'SFP';
const UK = 'UK';
const EU = 'EU';
const ROW = 'RestOfWorld';
const SERVICE_TIMES_ORDERED = ["TwentyFourPreTenThirty", "TwentyFourPreNoon", "TwentyFour", "FortyEight", "SeventyTwo", "OneToTwo", "TwoToThree", "ThreeToFive", "FiveToTen"];

class ShopifyShippingConfiguration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            integration: null,
            shopifyShippingRates: null,
            serviceTimeOptions: [],
            currentPreferences: [],
            selectedShippingRates: [],
            ukDefault: {
                shippingRateID: null,
                shippingRateName: null,
                shippingRatePrice: null,
                countryRegion: UK,
                serviceTime: null
            },
            euDefault: {
                shippingRateID: null,
                shippingRateName: null,
                shippingRatePrice: null,
                countryRegion: EU,
                serviceTime: null
            },
            rowDefault: {
                shippingRateID: null,
                shippingRateName: null,
                shippingRatePrice: null,
                countryRegion: ROW,
                serviceTime: null
            }
        }
    }

    async componentDidMount() {
        await Promise.all([
            this.getRetailerIntegration(),
            this.fetchServiceTimes(),
            this.fetchCurrentPreferences(),
            this.getShippingRates()
        ]);
        this.setState({ loading: false });
    }

    getRetailerIntegration = async () => {
        const { integration } = this.props;
        const url = new URL(Endpoints.INTEGRATIONS.GET.INTEGRATION_BY_ID + integration.id);

        return await GET(url)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const shopifyMetadata = JSON.parse(data.metadata);

                if (shopifyMetadata.shopifyShippingRatePreferences) {
                    const existingShippingRates = shopifyMetadata.shopifyShippingRatePreferences.filter(s => s.shippingRateID !== null) || null;

                    this.setDefaults(shopifyMetadata.shopifyShippingRatePreferences);
                    this.setState({ integration: data, selectedShippingRates: existingShippingRates });
                } else {
                    this.setState({ integration: data })
                }
            })
            .catch(error => console.log(error));
    }

    fetchServiceTimes = async () => {
        return await GET(Endpoints.RETAILER.GET.SERVICES_TIMES_OPTIONS)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ serviceTimeOptions: extractData(results) });
            })
            .catch(error => console.log(error));
    }

    fetchCurrentPreferences = async () => {
        return await GET(Endpoints.RETAILER.GET.COURIER_REGIONAL_PREFERENCES)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const currentPreferences = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ currentPreferences: currentPreferences });
            })
            .catch(error => console.log(error));
    }

    getShippingRates = async () => {
        const { integration } = this.props;
        const url = new URL(Endpoints.INTEGRATIONS.SHOPIFY.GET.SHIPPING_RATES_PREFIX + integration.id + Endpoints.INTEGRATIONS.SHOPIFY.GET.SHIPPING_RATES_SUFFIX);

        return await GET(url)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                this.setState({ shopifyShippingRates: data });
            })
            .catch(error => console.log(error));
    }

    setDefaults = (shopifyShippingRatePreferences) => {
        const uk = shopifyShippingRatePreferences.find(s => s.shippingRateID === null && s.countryRegion === UK);
        const eu = shopifyShippingRatePreferences.find(s => s.shippingRateID === null && s.countryRegion === EU);
        const row = shopifyShippingRatePreferences.find(s => s.shippingRateID === null && s.countryRegion === ROW);

        if (uk) this.setState({ ukDefault: uk });
        if (eu) this.setState({ euDefault: eu });
        if (row) this.setState({ rowDefault: row });
    }

    getShippingRateName = (shippingRateID, i) => {
        const { shopifyShippingRates } = this.state;
        const shippingRate = shopifyShippingRates.find(s => s.shippingRateID === shippingRateID);
        return shippingRate
            ? <p key={i}>{shippingRate.name}-{shippingRate.shippingZoneName}-£{shippingRate.price.toFixed(2)}</p>
            : <ErrorAlert errorTitle="A Shopify shipping rate has been removed" errorMessage="Update your shipping rate configuration to resolve this." />;
    }

    getDefaultValue = (serviceTimes, availableServiceTimes, serviceTime) =>
        availableServiceTimes.includes(serviceTime)
            ? <p>{serviceTimes[serviceTime]}</p>
            : <ErrorAlert errorTitle="Service Time Preference Removed" errorMessage="Update your shipping rate configuration to set a new default service time." />

    sortServiceTimes = (serviceTimes) => {
        serviceTimes.sort(function (a, b) {
            if (SERVICE_TIMES_ORDERED.indexOf(a) > SERVICE_TIMES_ORDERED.indexOf(b)) return 1;
            else return -1;
        });
        return serviceTimes;
    }

    render() {
        const { loading, serviceTimeOptions, currentPreferences, selectedShippingRates, ukDefault, euDefault, rowDefault } = this.state;
        const { showEditShippingRates, backToIntegrationsClick, showSuccess } = this.props;

        return (
            loading ? (<LoadingBar />) : <Container fluid>
                <a className="link-button link-pointer" onClick={backToIntegrationsClick}>
                    <FontAwesomeIcon icon={faAngleLeft} />  Return to Integrations
                </a>
                <h2 className="mt-4">Integrations</h2>
                <Row>
                    <Col sm="12" md="6" className="mt-4">
                        <h6>Shopify Shipping Configuration</h6>

                        {showSuccess && <SuccessAlert successMessage="Your Shopify shipping configuration has been saved successfully." />}

                        {serviceTimeOptions.map((st, wi) => {
                            const serviceTimesList = st.availableServiceTimeOptions.filter(s => s.companyName != SFP).flatMap(m => m.serviceTimes),
                                serviceTimes = Object.assign({}, ...serviceTimesList);

                            let availableServiceTimes;
                            let isSFP = false;
                            const currentRegionPreference = currentPreferences.find(c => c.countryRegion === st.countryRegion);
                            if (currentRegionPreference) {
                                availableServiceTimes = currentRegionPreference.preferences.flatMap(p => p.ServiceTimes);
                                isSFP = currentRegionPreference.preferences.flatMap(p => p.ServiceTimes).findIndex(s => s === SFP_VALUE) > -1;
                            }

                            let uniqueServiceTimes = Object.keys(serviceTimes);

                            if (availableServiceTimes) uniqueServiceTimes = uniqueServiceTimes.filter(u => availableServiceTimes.includes(u));

                            uniqueServiceTimes = this.sortServiceTimes(uniqueServiceTimes);

                            let defaultValue = {};
                            switch (st.countryRegion) {
                                case UK:
                                    defaultValue = ukDefault;
                                    break;
                                case EU:
                                    defaultValue = euDefault;
                                    break;
                                case ROW:
                                    defaultValue = rowDefault;
                                    break;
                            }

                            return (
                                !isSFP &&
                                <React.Fragment key={`world-area-${wi}`}>
                                    <h5>{st.countryRegionName}</h5>
                                    <p className="title">Default {st.countryRegionName} Shipping</p>
                                    {this.getDefaultValue(serviceTimes, uniqueServiceTimes, defaultValue.serviceTime)}
                                    {uniqueServiceTimes.map((serviceTime, i) => {
                                        const shippingRates = selectedShippingRates.filter(s => s.countryRegion === st.countryRegion && s.serviceTime === serviceTime);
                                        return (
                                            <React.Fragment>
                                                <p className="title">{serviceTimes[serviceTime]}</p>
                                                {(shippingRates && shippingRates.length > 0) ? shippingRates.map((shippingRate, i) =>
                                                    this.getShippingRateName(shippingRate.shippingRateID, i)
                                                ) :
                                                    <p>No shipping rates selected</p>}
                                            </React.Fragment>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })}
                        <Button variant="link" className="button-nav-link mr-3" onClick={() => showEditShippingRates()}>Edit Shopify Shipping Configuration</Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default ShopifyShippingConfiguration;