import React, { Component, Fragment } from 'react';
import { extractData, POST, PUT, GET } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import LoadingBar from '../../common/LoadingBar';
import DirectDebitDetails from './DirectDebitDetails';
import Header from '../../common/Header';
import { ErrorAlert } from '../../common/Alert';
import { PAYMENTS_SUB_NAV_LIST } from '../../common/constants';
import AddDirectDebit from './AddDirectDebit';
import NoDirectDebit from './NoDirectDebit';
import { toLocalTimeString } from '../../../Utilities';
import FormValidator from '../../common/FormValidator';
import { loadStripe } from '@stripe/stripe-js';

const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

class NewDirectDebit extends Component {

    directDebitDetailsValidator = new FormValidator([
        {
            field: 'customerName',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter the account holder's name`
        },
        {
            field: 'email',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your email address'
        },
        {
            field: 'phone',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your phone number'
        },
        {
            field: 'addressLine1',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter address line 1 of the account holder's address`
        },
        {
            field: 'addressTown',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter the town of the account holder's address`
        },
        {
            field: 'addressPostcode',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter the postcode of the account holder's address`
        },
        {
            field: 'bankAccountType',
            method: 'isEmpty',
            validWhen: false,
            message: `Please select the bank account type`
        },
    ]);

    state = {
        showForm: false,
        showCancelAddModal: false,
        showRemoveDirectDebitModal: false,
        loading: true,
        directDebitDetailsValidation: this.directDebitDetailsValidator.valid(),
        form: {
            customerName: "",
            email: "",
            phone: "",
            addressLine1: "",
            addressLine2: "",
            addressTown: "",
            addressPostcode: "",
            bankAccountType: "",
            bankAccountStatus: "",
            bankAccountNumber: "",
            bankAccountSortCode: ""
        },
        paymentTerms: 7,
        existingAccount: null,
        isNewlyCreated: false,
        showError: false,
        errorMessage: null
    };

    async componentDidMount() {
        if (this.props.match.params.formCancelled === "formCancelled") this.onCancelStripeForm();

        await Promise.all([
            this.getCompanyDirectDebit(),
            this.getCurrentDirectDebitDetails()]);

        this.setState({ loading: false });
    }

    checkDirectDebitValidity() {
        return GET(Endpoints.FINANCE.DIRECT_DEBIT.HAS_VALID_DETAILS)
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .catch(error => console.log(error));
    }

    getCurrentDirectDebitDetails() {
        return GET(Endpoints.FINANCE.DIRECT_DEBIT.CURRENT)
            .then(res => {
                if (res && res.ok) return res.json();
                else return null;
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    if (data) {
                        const acc = JSON.parse(data.directDebitDetailsContentsPayload).AccountHolder;
                        if (acc) {
                            this.setState({
                                existingAccount: {
                                    name: acc.Name,
                                    type: data.bankAccountType,
                                    status: data.status,
                                    lastChecked: data.lastChecked ? toLocalTimeString(data.lastChecked) : "Pending"
                                }
                            });
                        }
                    }
                }
            }, err => { console.error(err); })
            .catch(error => console.log(error));
    }

    getCompanyDirectDebit() {
        return GET(Endpoints.FINANCE.DIRECT_DEBIT.GET_FOR_COMPANY)
            .then(res => {
                if (res && res.ok) { return res.json(); }
                else { throw new Error('Error fetching data'); }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ paymentTerms: data.paymentTerms });
                }
            }, err => { console.error(err); })
            .catch(error => console.log(error));
    }

    onNext = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const { form } = this.state;

        const directDebitDetailsValidation = this.directDebitDetailsValidator.validate(form);
        this.setState({ directDebitDetailsValidation: directDebitDetailsValidation });

        if (directDebitDetailsValidation.isValid) {
            const stripePromise = loadStripe(STRIPE_API_KEY);
            const stripe = await stripePromise;

            const formData = {
                name: form.customerName,
                email: form.email,
                phone: form.phone,
                address: {
                    line1: form.addressLine1,
                    line2: form.addressLine2,
                    city: form.addressTown,
                    postalCode: form.addressPostcode
                },
                successURL: "/retailer/finance/newdirectdebit",
                cancelURL: "/retailer/finance/newdirectdebit/formCancelled",
                bankAccountType: form.bankAccountType
            };

            POST(Endpoints.FINANCE.DIRECT_DEBIT.CREATE, formData)
                .then(response => response.json())
                .then(result => {
                    if (!result.error) {
                        const data = extractData(result);
                        if (data) {
                            stripe.redirectToCheckout({ sessionId: data.id });
                            this.setState({ showForm: false });
                        }
                        else this.setState({ furtherAuth: data.id });
                    }
                    else alert("An error occurred: " + result.message);
                })
                .catch(error => {
                    alert("There was an error saving the card details.");
                    console.log(error);
                });
        }

        this.setState({ loading: false });
    }

    onBreadcrumbClick = async (e) => {
        e.preventDefault();
        this.setState({ showForm: false, existingAccount: null });
    }

    onChange = (e) => {
        const { id, value } = e.target;
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{ [id]: value }
            }
        }));
    };

    handleRemoveShowHide = () => { this.setState((prevState) => ({ showRemoveDirectDebitModal: !prevState.showRemoveDirectDebitModal })) };

    handleCancelAddShowHide = () => { this.setState((prevState) => ({ showCancelAddModal: !prevState.showCancelAddModal })); }

    handleRemoveAction = async () => {
        PUT(Endpoints.FINANCE.DIRECT_DEBIT.DISABLE_CURRENT)
            .then(res => {
                if (res && res.ok) return res.json();
                else return null;
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    if (data) {
                        this.setState({ existingAccount: null, showRemoveDirectDebitModal: false });
                        this.onCancelForm();
                    }
                }
            }, err => { console.error(err); })
            .catch(error => console.log(error));
    }

    onCancelStripeForm = async () => {
        PUT(Endpoints.FINANCE.DIRECT_DEBIT.REMOVE_CURRENT)
            .then(response => response.json())
            .then(result => {
                if (result.error) result.data !== null ? this.setState({ errorMessage: result.data }) : this.setState({ errorMessage: result.message });
                else {
                    this.setState({ existingAccount: null, showRemoveDirectDebitModal: false, showError: false, errorMessage: null });
                }
            })
            .catch(error => {
                this.setState({ showError: true, errorMessage: "An error occurred while returning from Stripe." });
                console.log(error);
            });
    }

    handleCancelAddAction = () => {
        this.setState({
            showCancelAddModal: false,
            existingAccount: null,
            form: {
                customerName: "",
                email: "",
                phone: "",
                addressLine1: "",
                addressLine2: "",
                addressTown: "",
                addressPostcode: "",
                bankAccountType: "",
                bankAccountStatus: "",
                bankAccountNumber: "",
                bankAccountSortCode: ""
            }
        });

        this.onCancelForm();
    }

    onLoadForm = () => { this.setState({ showForm: true }); }

    onCancelForm = async () => {
        this.setState({ loading: true, showForm: false, showCancelAddModal: false, showRemoveDirectDebitModal: false });

        await Promise.all([
            this.getCurrentDirectDebitDetails(),
            this.getCompanyDirectDebit()]);
        this.setState({ loading: false });
    }

    render() {
        const { loading, existingAccount, paymentTerms, showForm, form, directDebitDetailsValidation, showCancelAddModal, showRemoveDirectDebitModal,
            showError, errorMessage } = this.state;

        return (
            <Fragment>
                {loading
                    ? <LoadingBar />
                    : <Fragment>
                        <Header title="Payments" subNavList={PAYMENTS_SUB_NAV_LIST} activeKey="Direct Debit" />
                        {showError && <ErrorAlert errorMessage={errorMessage} />}
                        {(existingAccount && !showForm)
                            ? <DirectDebitDetails
                                existingAccount={existingAccount}
                                paymentTerms={paymentTerms}
                                showRemoveDirectDebitModal={showRemoveDirectDebitModal}
                                handleRemoveShowHide={this.handleRemoveShowHide}
                                handleRemoveAction={this.handleRemoveAction}
                            />
                            : showForm
                                ? <AddDirectDebit
                                    onNext={this.onNext}
                                    onBreadcrumbClick={this.onBreadcrumbClick}
                                    onChange={this.onChange}
                                    form={form}
                                    onCancelForm={this.onCancelForm}
                                    directDebitDetailsValidation={directDebitDetailsValidation}
                                    showCancelAddModal={showCancelAddModal}
                                    handleCancelAddShowHide={this.handleCancelAddShowHide}
                                    handleCancelAddAction={this.handleCancelAddAction}
                                    paymentTerms={paymentTerms}
                                />
                                : <NoDirectDebit onLoadForm={this.onLoadForm} />
                        }
                    </Fragment>
                }
            </Fragment>
        );
    }
}

export default NewDirectDebit;