  import React, { Component, Fragment } from 'react';
  import { Col, Row, Container, Card, Table } from 'react-bootstrap';
  import { GET, PUT, extractData } from '../../../Consumer';
  import LoadingBar from '../../common/LoadingBar';
  import Endpoints from '../../common/Endpoints';
  import { INVENTORY_SUB_NAV_LIST } from '../../common/constants'
  import { ErrorAlert } from '../../common/Alert';
  import Pagination from '../../common/Tables/Pagination';
  import {PurchaseOrdersHeader} from './PurchaseOrdersHeader';
  import PurchaseOrdersRow from './PurchaseOrdersRow';
  import PurchaseOrdersFilter from './PurchaseOrdersFilter';
  import NoResults from '../../common/Tables/NoResults';
  import './PurchaseOrders.scss';
  import Confirm from '../../common/Confirm';
  
  class PurchaseOrdersList extends Component {
      constructor(props) {
          super(props);
  
          this.state = {
              pageIndex: 1,
              pageCount: 0,
              items: [],
              loading: true,
              warning: "",
              filter: {
                  purchaseOrderReference: "",
                  sortBy: ""
              },
              showOpenModal: false,
              showCloseModal: false,
              tempItem: {}
          };
      }

      handleShowHideOpen(item) {
        this.setState((prevState) => ({ showOpenModal: !prevState.showOpenModal, tempItem: item }))
      }
      handleShowHideClose(item) {
        this.setState((prevState) => ({ showCloseModal: !prevState.showCloseModal, tempItem: item }))
      }
  
      async componentDidMount() {
          await Promise.all([
              this.fetchPurchaseOrdersItems()]);
          this.setState({ loading: false });
      }
  
      fetchPurchaseOrdersItems = async () => {
          const { pageIndex } = this.state;
          const filter = { ...this.state.filter };
          let url = new URL(Endpoints.PURCHASE_ORDERS.GET.ALL);
  
          Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
          url.searchParams.append("pageIndex", pageIndex)
  
          return GET(url)
          .then(response => {
              if (response.ok) return response.json();
          })
          .then(result => {
              const data = extractData(result) || [];
              const warning = data.items === 0 ? "No stock found" : null;
              this.setState({ items: data.items ? data.items : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false, warning: warning });
          })
          .catch(error => console.log(error));
      }

      closePurchaseOrder = async () =>{
        const { tempItem } = this.state;
        let url = new URL(Endpoints.PURCHASE_ORDERS.PUT.CLOSE);

        await PUT(url, tempItem)
          .then(response => {
              if (response.ok) return response.json();
          })
          .then(result => {
              this.fetchPurchaseOrdersItems()
              this.handleShowHideClose(null)
          })
          .catch(error => console.log(error));
      }

      openPurchaseOrder = async () =>{
        const { tempItem } = this.state;        
        let url = new URL(Endpoints.PURCHASE_ORDERS.PUT.REOPEN);

        await PUT(url, tempItem)
          .then(response => {
              if (response.ok) return response.json();
          })
          .then(result => {
              this.fetchPurchaseOrdersItems()
              this.handleShowHideOpen(null)
          })
          .catch(error => console.log(error));
      }

      renderPurchaseOrderRows() {
        const { items, pageIndex, pageCount } = this.state;
        return (
          <>
            <Card className="card-table">
              <div className="table-responsive">
                  <Table striped bordered hover size="sm" className={!items.length ? "table-empty" : ""}>
                    <thead>
                        <tr>
                            <th>PO Reference</th>
                            <th>Status</th>
                            <th>Date Created</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.length
                            ? items.map(item => <PurchaseOrdersRow key={item.id} item={item} onOpen={() => this.handleShowHideOpen(item)} onClose={() => this.handleShowHideClose(item)} />)
                            : <NoResults colSpan="5" text="No purchase orders available." />
                        }
                    </tbody>
                  </Table>
                  <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onStart={this.onStart} onEnd={this.onEnd} pageIndex={pageIndex} pageCount={pageCount} />
              </div>
            </Card>
          </>
        );
      }
  
      onClear = async (e) => {
          e.preventDefault();
  
          this.setState({
              filter: { purchaseOrderReference: "", sortBy: "" },
              loading: true
          }, await this.fetchPurchaseOrdersItems);
      }
  
      onSearch = async (e) => {
          e.preventDefault();
          this.setState({ loading: true }, await this.fetchPurchaseOrdersItems);
      }
  
      onNext = async () => {
          const { pageIndex, pageCount } = this.state;
          if (pageIndex < pageCount) {
              this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, await this.fetchPurchaseOrdersItems);
          }
      }
  
      onPrevious = async () => {
          const { pageIndex } = this.state;
          if (pageIndex > 1) {
              this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, await this.fetchPurchaseOrdersItems);
          }
      }
  
      onStart = async () => {
          const { pageIndex } = this.state;
          if (pageIndex > 1) {
              this.setState({ pageIndex: 1, loading: true }, await this.fetchPurchaseOrdersItems);
          }
      }
  
      onEnd = async () => {
          const { pageIndex, pageCount } = this.state;
          if (pageIndex < pageCount) {
              this.setState({ pageIndex: pageCount, loading: true }, await this.fetchPurchaseOrdersItems);
          }
      }
  
      onFilterChange = (e) => {
          const { name, value } = e.target;
  
          this.setState(prevState => ({
              ...prevState,
              pageIndex: 1,
              filter: {
                  ...prevState.filter,
                  ...{ [name]: value }
              }
          }));
      }
  
      render() {
          const { loading, warning } = this.state;
  
          return (
              <Fragment>
                  {loading ? <LoadingBar /> :
                      <Fragment>
                          <PurchaseOrdersHeader headerClass={ 'mb-0' } subNavList={ INVENTORY_SUB_NAV_LIST } isMainPage={ true }/>
                          <Container fluid>
                              {warning ?
                                  <Row><Col sm={12} md={6} className="px-2"><ErrorAlert errorMessage={warning} /></Col></Row> :
                                  <Fragment>
                                      <Row className="my-4">
                                          <Col>
                                              <PurchaseOrdersFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...this.state.filter} />
                                          </Col>
                                      </Row>
                                      {this.renderPurchaseOrderRows()}
                                  </Fragment>}
                          </Container>
                      </Fragment>}
                      <Confirm
                        title={"Close Purchase Order"}
                        text={"Are you sure you want to close this purchase order?"}
                        show={this.state.showCloseModal}
                        handleConfirmClose={() => this.handleShowHideClose(null)}
                        handleConfirmAction={this.closePurchaseOrder}
                        handleCancelAction={() => this.handleShowHideClose(null)}
                        buttonText="Close"
                        buttonVariant="danger"
                        linkText="Cancel" />
                      <Confirm
                        title={"Open Purchase Order"}
                        text={"Are you sure you want to open this purchase order?"}
                        show={this.state.showOpenModal}
                        handleConfirmClose={() => this.handleShowHideOpen(null)}
                        handleConfirmAction={this.openPurchaseOrder}
                        handleCancelAction={() => this.handleShowHideOpen(null)}
                        buttonText="Open"
                        buttonVariant="secondary"
                        linkText="Cancel" />
              </Fragment>
          );
      }
  }
  
  export default PurchaseOrdersList;