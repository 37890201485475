import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { extractData, GET } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { toLocalDateString, checkPermission } from '../../../Utilities';
import { DefaultCard, OrderListCard, BarCard } from '../../common/DashboardCards';
import { RETAILER_ORDER_SUB_NAV_LIST } from '../../common/constants'
import Header from '../../common/Header';

const barChartColour = "#229BD8";

const extractOrderByDateData = (totalsByDate) => {
    const labels = totalsByDate.map(orderTotal => toLocalDateString(orderTotal.date));
    const data = totalsByDate.map(orderTotal => orderTotal.total);
    const orderDataByDay = {
        labels: labels,
        data: data,
        loading: false
    }
    return orderDataByDay;
}

class RetailerDashboard extends Component {

    state = {
        unprocessedCount: {
            count: 0,
            loading: true
        },
        awaitingFulfilmentCount: {
            count: 0,
            loading: true
        },
        completedCount: {
            count: 0,
            loading: true
        },
        recentUnsuccessfulOrders: {
            data: [],
            loading: true
        },
        costsByDate: {
            labels: [],
            data: [],
            loading: true
        },
        totalsByDate: {
            labels: [],
            data: [],
            loading: true
        }
    }

    componentDidMount() {
        this.getUnproccesedCount();
        this.getAwaitingFulfilmentCount();
        this.getCompletedCount();
        this.getRecentUnsuccessfulOrders();
        this.getOrderCostsByDate();
        this.getOrderTotalsByDate();
    }

    getUnproccesedCount = () => {
        return GET(Endpoints.ORDERS.GET.UNPROCESSED_TODAY)
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ unprocessedCount: { count: data, loading: false } });
                }
            }, err => {
                console.error(err);
            })
            .catch(error => console.log(error));
    }

    getAwaitingFulfilmentCount = () => {
        return GET(Endpoints.ORDERS.GET.AWAITING_FULFILMENT_TODAY)
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ awaitingFulfilmentCount: { count: data, loading: false } });
                }
            }, err => {
                console.error(err);
            })
            .catch(error => console.log(error));
    }

    getCompletedCount = () => {
        return GET(Endpoints.ORDERS.GET.COMPLETED_TODAY)
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ completedCount: { count: data, loading: false } });
                }
            }, err => {
                console.error(err);
            })
            .catch(error => console.log(error));
    }

    getRecentUnsuccessfulOrders = () => {
        return GET(Endpoints.ORDERS.GET.RECENT_FAILED)
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ recentUnsuccessfulOrders: { data: data, loading: false } });
                }
            }, err => {
                console.error(err);
            })
            .catch(error => console.log(error));
    }

    getOrderTotalsByDate = () => {
        return GET(Endpoints.ORDERS.GET.TOTALS_BY_DATE)
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    const totalsByDate = extractOrderByDateData(data);
                    this.setState({ totalsByDate: totalsByDate });
                }
            }, err => {
                console.error(err);
            })
            .catch(error => console.log(error));
    }

    getOrderCostsByDate = () => {
        return GET(Endpoints.ORDERS.GET.COSTS_BY_DATE)
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    const costsByDate = extractOrderByDateData(data);
                    this.setState({ costsByDate: costsByDate });
                }
            }, err => {
                console.error(err);
            })
            .catch(error => console.log(error));
    }

    render() {
        const { unprocessedCount, awaitingFulfilmentCount, completedCount, recentUnsuccessfulOrders, costsByDate, totalsByDate } = this.state;

        const orderCostsData = {
            labels: costsByDate.labels,
            datasets: [
                {
                    backgroundColor: barChartColour,
                    data: costsByDate.data
                }
            ]
        };
        const orderTotalsData = {
            labels: totalsByDate.labels,
            datasets: [
                {
                    backgroundColor: barChartColour,
                    data: totalsByDate.data
                }
            ]
        };

        return (
            <Fragment>
                <Header title="Orders" subNavList={RETAILER_ORDER_SUB_NAV_LIST} activeKey="Dashboard" headerClass={"mb-0"} />
                <Container fluid>
                    <Row>
                        <Col sm="12" md="4" className="mt-3">
                            <DefaultCard title="Number of Unprocessed Orders" count={unprocessedCount.count} loading={unprocessedCount.loading} text="orders today" />
                        </Col>
                        <Col sm="12" md="4" className="mt-3">
                            <DefaultCard title="Number of Orders Awaiting Fulfilment" count={awaitingFulfilmentCount.count} loading={awaitingFulfilmentCount.loading} text="orders today" />
                        </Col>
                        <Col sm="12" md="4" className="mt-3">
                            <DefaultCard title="Orders Completed" count={completedCount.count} loading={completedCount.loading} text="orders today" />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="4" className="mt-4">
                            <OrderListCard title="Recent Unsuccessful Orders" orders={recentUnsuccessfulOrders.data} loading={recentUnsuccessfulOrders.loading} />
                        </Col>
                        <Col sm="12" md="8">
                            <Row className="h-100">
                                {checkPermission('ViewInvoice',
                                    <Col md="12" className="mt-4">
                                        <BarCard title="Order Value for the Past 28 Days (£)" data={costsByDate.data.reduce((a, b) => a + b, 0) > 0 ? orderCostsData : null} loading={costsByDate.loading} useDecimal={true} />
                                    </Col>)}
                                <Col md="12" className="mt-4">
                                    <BarCard title="Order Totals for the Past 28 Days" data={totalsByDate.data.reduce((a, b) => a + b, 0) > 0 ? orderTotalsData : null} loading={totalsByDate.loading} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default RetailerDashboard;