import React, { Component } from 'react';
import { Col, Row, Container, Card, Table } from 'react-bootstrap';
import { GET, extractData } from '../../../../Consumer';
import LoadingBar from '../../../common/LoadingBar';
import Endpoints from '../../../common/Endpoints';
import { INVENTORY_SUB_NAV_LIST } from '../../../common/constants'
import Header from '../../../common/Header';
import { ErrorAlert } from '../../../common/Alert';
import Pagination from '../../../common/Tables/Pagination';
import StockConsignmentRow from './StockConsignmentRow';
import StockConsignmentFilter from './StockConsignmentFilter';
import NoResults from '../../../common/Tables/NoResults';

class StockConsignmentList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageIndex: 1,
            pageCount: 0,
            items: [],
            loading: true,
            warning: "",
            filter: {
                name: "",
                dateFrom: undefined,
                dateTo: undefined,
                sortBy: ""
            }
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchStockItems()]);
        this.setState({ loading: false });
    }

    fetchStockItems = async () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(Endpoints.STOCK.GET.CONSIGNMENTS_BY);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)

        return GET(url)
        .then(response => {
            if (response.ok) return response.json();
        })
        .then(result => {
            const data = extractData(result) || [];
            const warning = data.items === 0 ? "No stock found" : null;
            this.setState({ items: data.items ? data.items : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false, warning: warning });
        })
        .catch(error => console.log(error));
    }

    renderConsignmentRows() {
        const { items, pageIndex, pageCount } = this.state;
        return (
            <Card className="card-table">
                <div className="table-responsive">
                    <Table striped bordered size="sm" className={!items.length ? "table-empty" : ""}>
                        <thead>
                            <tr>
                                <th>Filename/Item Name</th>
                                <th>Created on</th>
                                <th>Status</th>
                                <th>Labels</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.length
                                ? items.map(item => <StockConsignmentRow key={item.id} item={item} />)
                                : <NoResults colSpan="5" text="No inventory available." />
                            }
                        </tbody>
                    </Table>
                    <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onStart={this.onStart} onEnd={this.onEnd} pageIndex={pageIndex} pageCount={pageCount} />
                </div>
            </Card>
        );
    }

    onClear = async (e) => {
        e.preventDefault();

        this.setState({
            filter: { name: "", dateFrom: null, dateTo: null, sortBy: "" },
            loading: true
        }, await this.fetchStockItems);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true }, await this.fetchStockItems);
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, await this.fetchStockItems);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, await this.fetchStockItems);
        }
    }

    onStart = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true }, await this.fetchStockItems);
        }
    }

    onEnd = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true }, await this.fetchStockItems);
        }
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    render() {
        const { loading, warning } = this.state;

        return (
            <React.Fragment>
                {loading ? <LoadingBar /> :
                    <React.Fragment>
                        <Header title="Inventory" subNavList={INVENTORY_SUB_NAV_LIST} activeKey="Inventory" />                    
                        <Container fluid>
                            {warning ?
                                <Row><Col sm={12} md={6} className="px-2"><ErrorAlert errorMessage={warning} /></Col></Row> :
                                <React.Fragment>
                                    <Row className="my-4">
                                        <Col>
                                            <StockConsignmentFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...this.state.filter} />
                                        </Col>
                                    </Row>
                                    {this.renderConsignmentRows()}
                                </React.Fragment>}
                        </Container>
                    </React.Fragment>}
            </React.Fragment>
        );
    }
}

export default StockConsignmentList;