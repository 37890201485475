import React from 'react'
import { Row, Col, FormGroup, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const ApiKeyCopy = ({ apiKey, copied, onCopyClick }) =>
    <Row>
        <Col>
            <FormGroup>
                <FormControl id="apiKeyField" type="text" name="apiKey" value={apiKey} disabled />
            </FormGroup>
            <Button variant={copied ? "success" : "primary"} className="button-copy float-right" onClick={onCopyClick}>
                {copied ? "Copied" : "Copy"}{copied && <FontAwesomeIcon className="ml-2" icon={faCheck} />}
            </Button>
        </Col>
    </Row>

export default ApiKeyCopy
