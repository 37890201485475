import React from 'react';
import { FormGroup, FormLabel, FormControl, Row, Col } from 'react-bootstrap';

const StockDetails = ({ data, handleInputChange, loading, stockDetailsValidation, validationMessages, minGtinLength, itemLimit }) => {

    const renderGTINForm = (loading, data, validationMessages, handleInputChange, minGtinLength) => {
        return (<FormGroup as={Row} className="mt-n3 mb-5">
            <Col xs={12} md={6}>
                <FormLabel htmlFor="gtinField">GTIN Identifier</FormLabel>
                <FormControl disabled={loading} id="gtinField" type="text" name="gtin" pattern="/^(?:[A-Za-z]+|\d+)$/" value={data.gtin} onChange={handleInputChange} maxLength="50" />
            </Col>
            <Col md={12}>
                {validationMessages.gtinError && <span className="text-danger">{`Please enter a GTIN of minimum ${minGtinLength} characters`}</span>}
                <span className="text-danger">{validationMessages.gtinError}</span>
            </Col>
        </FormGroup>);
    }

    return (
        <React.Fragment>
            <h5>Stock Details</h5>
            <FormGroup as={Row}>
                <Col xs={12} md={12}>
                    <p className="title">Stock Box Quantity</p>
                    <div className="form-input-description">
                        <p>This is the amount of stock boxes that you are sending to the Selazar warehouse with this consignment.</p>
                    </div>
                </Col>
                <Col xs={12} md={3}>
                    <FormControl disabled={loading} id="boxQuantityField" type="number" name="boxQuantity" value={data.boxQuantity} onChange={handleInputChange} min="0" max="5000" />
                </Col>
                <Col md={12}>
                    <span className="text-danger">{stockDetailsValidation?.boxQuantity?.message}</span>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Col xs={12} md={12}>
                    <p className="title">Items Per Stock Box</p>
                    <div className="form-input-description">
                        <p>This is the number of items in each stock box. This must be at least 1.</p>
                    </div>
                </Col>
                <Col xs={12} md={3}>
                    <FormControl disabled={loading} id="itemsPerBoxField" type="number" name="itemsPerBox" value={data.itemsPerBox} onChange={handleInputChange} min="0" max={itemLimit} />
                </Col>
                <Col md={12}>
                    <span className="text-danger">{stockDetailsValidation?.itemsPerBox?.message}</span>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Col xs={12} md={12}>
                    <p className="title">Total Item Quantity:</p>
                    <p className="mb-0">{data.stockQuantity <= itemLimit ? data.stockQuantity : "N/A"}</p>
                    <span className="text-danger mt-0">{stockDetailsValidation?.stockQuantity?.message}</span>
                </Col>
            </FormGroup>

            <h5 className="mt-4">Stock Box Dimensions</h5>
            <FormGroup as={Row}>
                <Col xs={12} md={3}>
                    <p className="title text-nowrap">Stock Box Height (cm)</p>
                    <FormControl disabled={loading} id="heightField" type="number" name="stockBoxHeight" value={data.stockBoxHeight} onChange={handleInputChange} min="0.00" step="0.01" />
                </Col>
                <Col md={12}>
                    <span className="text-danger">{stockDetailsValidation?.stockBoxHeight?.message}</span>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Col xs={12} md={3}>
                    <p className="title text-nowrap">Stock Box Width (cm)</p>
                    <FormControl disabled={loading} id="widthField" type="number" name="stockBoxWidth" value={data.stockBoxWidth} onChange={handleInputChange} min="0.00" step="0.01" />
                </Col>
                <Col md={12}>
                    <span className="text-danger">{stockDetailsValidation?.stockBoxWidth?.message}</span>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Col xs={12} md={3}>
                    <p className="title text-nowrap">Stock Box Depth (cm)</p>
                    <FormControl disabled={loading} id="depthField" type="number" name="stockBoxDepth" value={data.stockBoxDepth} onChange={handleInputChange} min="0.00" step="0.01" />
                </Col>
                <Col md={12}>
                    <span className="text-danger">{stockDetailsValidation?.stockBoxDepth?.message}</span>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Col xs={12} md={3}>
                    <p className="title text-nowrap">Stock Box Weight (kg)</p>
                    <FormControl disabled={loading} id="weightField" type="number" name="stockBoxWeight" value={data.stockBoxWeight} onChange={handleInputChange} min="0.00" step="0.01" />
                </Col>
                <Col md={12}>
                    <span className="text-danger">{stockDetailsValidation?.stockBoxWeight?.message}</span>
                </Col>
            </FormGroup>

            <FormGroup as={Row}>
                <Col xs={12} md={12}>
                    <p className="title">Do these stock boxes have a GTIN?</p>
                    <div className="form-input-description">
                        <p>If the stock boxes in this consignment will have a GTIN identifier and label, please enter full GTIN number as it appears on the label. GTINs are typically 8, 12, 13, or 14 digits long. If you do not have a GTIN identifier, we will require stock box labels to be affixed to all stock boxes. This can be done by you, or by our warehouse for an additional processing cost.</p>
                    </div>
                </Col>
                <Col xs={12} md={12}>
                    <FormGroup className="custom-control custom-radio mb-0">
                        <input className="custom-control-input" type='radio' id='radio-1' name='requiresGTIN' value={'false'}
                            checked={data.requiresGTIN === 'false'} onChange={handleInputChange} />
                        <FormLabel className="custom-control-label" htmlFor="radio-1">No, they do not</FormLabel>
                    </FormGroup>
                    <FormGroup className="custom-control custom-radio">
                        <input className="custom-control-input" type='radio' id='radio-2' name='requiresGTIN' value={'true'}
                            checked={data.requiresGTIN === 'true'} onChange={handleInputChange} />
                        <FormLabel className="custom-control-label" htmlFor="radio-2">Yes, they do</FormLabel>
                    </FormGroup>
                </Col>
            </FormGroup>
            {data.requiresGTIN === 'true' && renderGTINForm(loading, data, validationMessages, handleInputChange, minGtinLength)}

            <h5 className="mt-2">Additional Details</h5>
            <FormGroup as={Row}>
                <Col xs={12} md={6}>
                    <p className="title">Batch Number  <span className="form-label-optional">- optional</span></p>
                    <FormControl disabled={loading} id="batchNumberField" type="text" name="batchNumber" value={data.batchNumber} onChange={handleInputChange} />
                    <span className="text-danger">{stockDetailsValidation?.batchNumber?.message}</span>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Col xs={12} md={4}>
                    <p className="title">Expiry Date  <span className="form-label-optional">- optional</span></p>
                    <FormControl className="mb-3" disabled={loading} id="expiryDateField" type="date" name="expiryDate" value={data.expiryDate} onChange={handleInputChange} />
                </Col>
            </FormGroup>

            <h5>Pallet or Consignment Box</h5>
            <p className="title">Is this a pallet?</p>
            <FormGroup as={Row}>
                <Col xs={12} md={12}>
                    <FormGroup className="custom-control custom-radio mb-0">
                        <input className="custom-control-input" type='radio' id='yesRadio' name='isPallet' value={'true'}
                            checked={data.isPallet === 'true'} onChange={handleInputChange} />
                        <FormLabel className="custom-control-label" htmlFor="yesRadio">Yes</FormLabel>
                    </FormGroup>
                    <FormGroup className="custom-control custom-radio">
                        <input className="custom-control-input" type='radio' id='noRadio' name='isPallet' value={'false'}
                            checked={data.isPallet === 'false'} onChange={handleInputChange} />
                        <FormLabel className="custom-control-label" htmlFor="noRadio">No</FormLabel>
                    </FormGroup>
                </Col>
            </FormGroup>

            {data.isPallet === 'false' &&
                <React.Fragment>
                    <FormGroup as={Row}>
                        <Col xs={12} md={12}>
                            <p className={data.isPallet === 'true' ? "title" : "title mt-n3"}>Box Height (cm)</p>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl disabled={loading} id="heightField" type="number" name="boxHeight" value={data.boxHeight} onChange={handleInputChange} min="0" />
                        </Col>
                        <Col md={12}>
                            {validationMessages.consignmentBoxHeightError && <span className="text-danger">Box height must be greater than 0</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row}>
                        <Col xs={12} md={12}>
                            <p className="title">Box Width (cm)</p>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl disabled={loading} id="widthField" type="number" name="boxWidth" value={data.boxWidth} onChange={handleInputChange} min="0" />
                        </Col>
                        <Col md={12}>
                            {validationMessages.consignmentBoxHeightError && <span className="text-danger">Box height must be greater than 0</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row}>
                        <Col xs={12} md={12}>
                            <p className="title">Box Depth(cm)</p>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl disabled={loading} id="depthField" type="number" name="boxDepth" value={data.boxDepth} onChange={handleInputChange} min="0" />
                        </Col>
                        <Col md={12}>
                            {validationMessages.consignmentBoxDepthError && <span className="text-danger">Box depth must be greater than 0</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row}>
                        <Col xs={12} md={12}>
                            <p className="title">Box Weight (kg)</p>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormControl disabled={loading} id="weightField" type="number" name="boxWeight" value={data.boxWeight} onChange={handleInputChange} min="0" />
                        </Col>
                        <Col md={12}>
                            {validationMessages.consignmentBoxWeightError && <span className="text-danger">Box weight must be greater than 0</span>}
                        </Col>
                    </FormGroup>
                </React.Fragment>}
        </React.Fragment>
    )
}

export default StockDetails;