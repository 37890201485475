import React, { Component, Fragment } from 'react';
import { Row, Col, FormGroup, FormLabel, FormControl, Button, Container, Form } from 'react-bootstrap';
import FormValidator from '../../common/FormValidator';
import { PUT, POST } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import LoadingBar from '../../common/LoadingBar';

const CYBERSOURCE_CHECKOUT_URL = process.env.REACT_APP_CYBERSOURCE_CHECKOUT;

const FormInput = ({ customerDetails, customerDetailsValidation, handleInputChange }) =>
    <Fragment>
        <h2>Add New Payment Method</h2>
        <h5 className="mt-4">Cardholder Details</h5>
        <FormGroup>
            <FormLabel htmlFor="Forename">Forename</FormLabel>
            <FormControl type="text" size="50" maxLength="50" id="Forename" onChange={(e) => handleInputChange("forename", e.target.value)} value={customerDetails.forename} />
            <span className="text-danger">{customerDetailsValidation.forename.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="Surname">Surname</FormLabel>
            <FormControl type="text" size="50" maxLength="50" id="Surname" onChange={(e) => handleInputChange("surname", e.target.value)} value={customerDetails.surname} />
            <span className="text-danger">{customerDetailsValidation.surname.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="Email">Email</FormLabel>
            <FormControl type="email" size="50" maxLength="50" id="Email" onChange={(e) => handleInputChange("email", e.target.value)} value={customerDetails.email} />
            <span className="text-danger">{customerDetailsValidation.email.message}</span>
        </FormGroup>
        <h5 className="mt-4">Cardholder Address</h5>
        <FormGroup>
            <FormLabel htmlFor="AddressLine1">Address Line 1</FormLabel>
            <FormControl type="text" size="50" maxLength="50" id="AddressLine1" onChange={(e) => handleInputChange("addressLine1", e.target.value)} value={customerDetails.addressLine1} />
            <span className="text-danger">{customerDetailsValidation.addressLine1.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="AddressLine2">Address Line 2  <span className="form-label-optional font-weight-normal">- optional</span></FormLabel>
            <FormControl type="text" size="50" maxLength="50" id="AddressLine2" onChange={(e) => handleInputChange("addressLine2", e.target.value)} value={customerDetails.addressLine2} />
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="Town">Town</FormLabel>
            <FormControl type="text" size="50" maxLength="50" id="Town" onChange={(e) => handleInputChange("town", e.target.value)} value={customerDetails.town} />
            <span className="text-danger">{customerDetailsValidation.town.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="PostCode">Postcode</FormLabel>
            <FormControl type="text" size="20" maxLength="20" id="PostCode" onChange={(e) => handleInputChange("postCode", e.target.value)} value={customerDetails.postCode} />
            <span className="text-danger">{customerDetailsValidation.postCode.message}</span>
        </FormGroup>
    </Fragment>

const CustomerDetails = ({ customerDetails, customerDetailsValidation, handleInputChange, handlePaymentClick, handleCancelClick }) =>
    <Fragment>
        <Form>
            <FormInput
                customerDetails={customerDetails}
                customerDetailsValidation={customerDetailsValidation}
                handleInputChange={handleInputChange}
            />
        </Form>
        <FormGroup>
            <div className="float-right d-none d-sm-block">
                <Button variant="primary" onClick={handlePaymentClick}>Proceed to Payment Details</Button>
            </div>
            <div className="float-right d-none d-sm-block">
                <Button variant="link" className="mr-2" onClick={handleCancelClick}>Cancel</Button>
            </div>
        </FormGroup>
    </Fragment>

const CyberSourceForm = ({ signedModel, paymentFormRef }) =>
    <form id="payment_form" action={CYBERSOURCE_CHECKOUT_URL} method="post" ref={paymentFormRef}>
        <input type="hidden" name="access_key" value={signedModel.accessKey} />
        <input type="hidden" name="profile_id" value={signedModel.profileID} />
        <input type="hidden" name="transaction_uuid" value={signedModel.transactionID} />
        <input type="hidden" name="signed_field_names" value={signedModel.signedFieldNames} />
        <input type="hidden" name="signature" value={signedModel.signature} />
        <input type="hidden" name="unsigned_field_names" />
        <input type="hidden" name="signed_date_time" value={signedModel.signedDateTime} />
        <input type="hidden" name="locale" value="en-GB" />
        <input type="hidden" name="transaction_type" size="50" value={signedModel.transactionType} />
        <input type="hidden" name="reference_number" size="50" value={signedModel.referenceNumber} />
        <input type="hidden" name="amount" size="25" value={signedModel.amount} />
        <input type="hidden" name="currency" size="25" value={signedModel.currency} />
        <input type="hidden" name="bill_to_company_name" size="25" value={signedModel.billToCompanyName} />
        <input type="hidden" name="bill_to_forename" size="25" value={signedModel.billToForename} /><br />
        <input type="hidden" name="bill_to_surname" size="25" value={signedModel.billToSurname} /><br />
        <input type="hidden" name="bill_to_email" size="25" value={signedModel.billToEmail} /><br />
        <input type="hidden" name="bill_to_address_line1" size="25" value={signedModel.billToAddressLine1} />
        <input type="hidden" name="bill_to_address_line2" size="25" value={signedModel.billToAddressLine2} />
        <input type="hidden" name="bill_to_address_city" size="25" value={signedModel.billToAddressCity} />
        <input type="hidden" name="bill_to_address_state" size="25" value={signedModel.billToAddressState} />
        <input type="hidden" name="bill_to_address_country" size="25" value={signedModel.billToAddressCountry} />
        <input type="hidden" name="bill_to_address_postal_code" size="25" value={signedModel.billToAddressPostalCode} />
        <input type="hidden" name="bill_to_phone" size="25" value={signedModel.phoneNumber} />
    </form>

export class CardForm extends Component {

    customerDetailsValidator = new FormValidator([
        {
            field: 'forename',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter your forename`
        },
        {
            field: 'surname',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter your surname`
        },
        {
            field: 'email',
            method: 'isEmail',
            validWhen: true,
            message: `This is not a valid email address`
        },
        {
            field: 'addressLine1',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter address line 1 of the card holder's address`
        },
        {
            field: 'town',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter the town of the card holder's address`
        },
        {
            field: 'postCode',
            method: 'isEmpty',
            validWhen: false,
            message: `Please enter the postcode of the card holder's address`
        }
    ]);

    paymentFormRef = React.createRef();

    state = {
        loading: false,
        replaceCardClick: this.props.replaceCardClick,
        customerDetails: {
            forename: "",
            surname: "",
            email: "",
            addressLine1: "",
            addressLine2: "",
            town: "",
            postCode: ""
        },
        customerDetailsValidation: this.customerDetailsValidator.valid(),
        signedModel: {
            accessKey: '',
            profileID: '',
            signature: '',
            transactionID: '',
            signedFieldNames: '',
            signedDateTime: '',
            transactionType: '',
            referenceNumber: '',
            amount: '',
            currency: '',
            billToEmail: '',
            billToForename: '',
            billToSurname: '',
            billToCompanyName: '',
            billToAddressLine1: '',
            billToAddressLine2: '',
            billToAddressCity: '',
            billToAddressState: '',
            billToAddressCountry: '',
            billToAddressPostalCode: '',
            phoneNumber: ''
        }
    }

    postCreateTransaction = (customerDetails) =>
        POST(Endpoints.FINANCE.CARD.POST.NEW, customerDetails)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ signedModel: results.data, loading: false });
                this.handleSubmit();
            })
            .catch(error => console.log(error));


    putReplaceTransaction = (customerDetails) =>
        PUT(Endpoints.FINANCE.CARD.PUT.REPLACE, customerDetails)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ signedModel: results.data, loading: false });
                this.handleSubmit();
            })
            .catch(error => console.log(error));


    handleInputChange = (name, value) => this.setState(prevState => ({ customerDetails: { ...prevState.customerDetails, [name]: value } }));


    handlePaymentClick = (event) => {
        const { customerDetails, replaceCardClick } = this.state
        const customerDetailsValidation = this.customerDetailsValidator.validate(customerDetails);
        this.setState({ customerDetailsValidation: customerDetailsValidation });

        if (customerDetailsValidation.isValid) {
            event.currentTarget.disabled = true;
            if (replaceCardClick) {
                return this.putReplaceTransaction(customerDetails);
            } else return this.postCreateTransaction(customerDetails);
        } else this.setState({ loading: false });
    }

    handleSubmit = () => this.paymentFormRef.current && this.paymentFormRef.current.submit();

    render() {
        const { loading, customerDetails, customerDetailsValidation, signedModel } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <Container fluid>
                        <Row>
                            <Col sm={12} md={6}>
                                <CustomerDetails
                                    customerDetails={customerDetails}
                                    customerDetailsValidation={customerDetailsValidation}
                                    handleInputChange={this.handleInputChange}
                                    handlePaymentClick={this.handlePaymentClick}
                                    handleCancelClick={this.props.handleCancelClick}
                                />
                                <CyberSourceForm signedModel={signedModel} paymentFormRef={this.paymentFormRef} />
                            </Col>
                        </Row>
                    </Container>
                </Fragment>
        )
    }
}

export default CardForm;
