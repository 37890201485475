import React, { Fragment } from 'react';

const WarehouseInformation = ({ message, addressLine1, addressLine2, town, county, country, postcode }) =>
    <Fragment>
        <h4>Warehouse Information</h4>
        <div className="form-input-description">
            <p>{message}</p>
        </div>
        <section>
            <p className="mb-0"><strong>Warehouse Address</strong></p>
            <p className="mb-0">{addressLine1}</p>
            {addressLine2 && <p className="mb-0">{addressLine2}</p>}
            <p className="mb-0">{town}</p>
            <p className="mb-0">{county}</p>
            <p className="mb-0">{country}</p>
            <p>{postcode}</p>
        </section>
    </Fragment>

export default WarehouseInformation;