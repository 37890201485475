import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const ReturnLink = ({ link, text }) =>
    <Link to={link} className="link-button">
        <Button variant="link" className="button-nav-return p-0  mb-4"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> {text}</Button>
    </Link>

export default ReturnLink;