import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { toLocalTimeString } from '../../../Utilities';
import { ErrorAlert } from '../../common/Alert';
import Endpoints from '../../common/Endpoints';
import LoadingBar from '../../common/LoadingBar';
import { GET_ASYNC } from '../../../Consumer';

const ERROR_MESSAGE = "There was a problem retrieving the order. Please try again."

const UnsuccessfulOrderDetails = () => {

    //#region State

    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [orderDetails, setOrderDetails] = useState(null);
    const [order, setOrder] = useState(null);
    const location = useLocation();
    
    //#endregion State

    //#region API

    useEffect(() => {
        if (order != null) {
            (async () => {
                setLoading(true);
                try {
                    const url = new URL(`${Endpoints.ORDERS.GET.UNSUCCESSFUL_DETAILS}${order?.id}/${order?.source}`);
                    const response = await GET_ASYNC(url);
                    
                    if (!response?.ok || response?.status !== 200) setShowError(true);
                    else {
                        const data = await response.json();
                        setOrderDetails(data.data);
                    }
                } catch (error) {
                    console.log(error);
                    setShowError(true);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [order]);

    useEffect(() => {
        setOrder(location.state.order);
    }, [location]);

    //#endregion API

    const Details = () =>
        <div className="section-item">
            <h2 className="mb-4">Order Details</h2>
            {showError ? <ErrorAlert errorMessage={ERROR_MESSAGE} />
                : <Fragment>
                    <h6>Order Breakdown</h6>
                        <p className="title">Order Identifier</p>
                        <p>{orderDetails?.orderNumber}</p>
                        {orderDetails?.internalReference && 
                            <Fragment>
                                <p className="title">Internal Reference</p>
                                <p>{orderDetails?.internalReference}</p>
                            </Fragment>}
                        
                        <p className="title">Order Received</p>
                        <p>{toLocalTimeString(orderDetails?.createdDate)}</p>
                        {orderDetails?.customerName &&
                            <Fragment>
                                <p className="title">Customer Name</p>
                                <p>{orderDetails?.customerName}</p>
                            </Fragment>}
                        <p className="title">Status</p>
                        <p>{orderDetails?.status !== "Cancelled" ? <span className="text-status-purple">{orderDetails?.status}</span> : <span className="text-status-red">Cancelled</span>}</p>
                        {orderDetails?.statusReason &&
                            <Fragment>
                                <p className="title">Issue</p>
                                <p>{orderDetails?.statusReason}</p>
                            </Fragment>}
                    </Fragment>}
        </div>

    return (
        loading ? <LoadingBar />
            : <Container fluid>
                <Row>
                    <Col sm={12} md={6}>
                        <Link to="/retailer/orders/unsuccessfulorders" className="link-button">
                            <Button variant="link" className="button-nav-return p-0 mb-4"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Orders</Button>
                        </Link>
                        <Details/>
                    </Col>
                </Row>
            </Container>
        );
}

export default UnsuccessfulOrderDetails;