import React from 'react';
import { Button, Col, FormLabel, Form, FormControl, FormGroup, Row, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const PartialOrdersFilter = ({ search, sortBy, onClear, onFilterChange, onSearch }) =>
    <Form onSubmit={onSearch}>
        <FormGroup>
            <Row>
                <Col md={4}>
                    <InputGroup>
                        <FormControl type="text" id="search" className="input-filter" name="search" value={search} onChange={onFilterChange} placeholder="Search by Order Number, Product or SKU" />
                        <InputGroup.Append>
                            <InputGroup.Text className="input-filter-icon" onClick={onSearch}>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Col md={{ span: 2, offset: 3 }} className="pr-0">
                    <FormLabel htmlFor="sortBy" className="float-right label-filter">Sort By</FormLabel>
                </Col>
                <Col md={3}>
                    <FormControl id="sortBy" name="sortBy" as="select" value={sortBy} onChange={onFilterChange}>
                        <option key="" value="">Please Select...</option>
                        <option value="ORDER_NUMBER">Order Number</option>
                        <option value="DATE_CREATED">Date Created</option>
                    </FormControl>
                </Col>
            </Row>
        </FormGroup>
        <FormGroup>
            <Button variant="primary" className="float-right" type="submit">Apply filter</Button>
            <Button variant="link" className="float-right" name="filterReset" onClick={onClear}>Clear filter</Button>
        </FormGroup>
    </Form>

export default PartialOrdersFilter;