import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import { PREFERENCES_SUB_NAV_LIST } from '../../common/constants'
import { extractData, POST } from '../../../Consumer';
import Header from '../../common/Header';
import Breadcrumbs from '../../common/Breadcrumbs';
import { SuccessAlert, ErrorAlert } from '../../common/Alert';

const SUCCESS_MESSAGE = 'Customer support access updated successfully.';
const ERROR_MESSAGE = 'There was a problem updating your customer support access preference. Please try again.';

const breadcrumbs = [
    { text: "Security", link: "/retailer/preferences/security" },
    { text: "Customer Support Access", link: null }
];

class CustomerSupportAccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            success: false,
            customerSupportAccess: props.location.state.customerSupportAccess || {}
        };
    }

    toggleCustomeSupportAccess = () => {
        this.setState({ loading: true });
        let { customerSupportAccess } = this.state;
        customerSupportAccess.access = !customerSupportAccess.access;

        POST(Endpoints.RETAILER.POST.SECURITY_PREFERENCE, customerSupportAccess)
            .then(response => {
                if (response && response.ok) return response.json();
            })
            .then(result => {
                const customerSupportAccess = extractData(result);
                this.setState({ customerSupportAccess: customerSupportAccess ? customerSupportAccess : {}, loading: false, error: result.error, success: !result.error });
            })
            .catch(error => console.log(error));
    }

    render() {
        const { loading, customerSupportAccess, error, success } = this.state;
        return (
            <React.Fragment>
                <Header title="Preferences" subNavList={PREFERENCES_SUB_NAV_LIST} activeKey="Security" headerClass="mb-2" />
                {loading ? <LoadingBar /> :
                    <Container fluid>
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                        <Row>
                            <Col sm={12} md={6}>
                                <h5 className="mb-3">Customer Support Access</h5>
                                {error && <ErrorAlert errorMessage={ERROR_MESSAGE} />}
                                {success && <SuccessAlert successMessage={SUCCESS_MESSAGE} />}
                                <p>With this setting enabled, our customer support team can generate temporary login details for your company's account.</p>
                                <p><strong>Support Access</strong></p>
                                <label className="switch">
                                    <input id="support-access-check" type="checkbox" onChange={this.toggleCustomeSupportAccess} checked={customerSupportAccess && customerSupportAccess.access} />
                                    <span className="slider round"><span className="toggle-text">{customerSupportAccess && customerSupportAccess.access ? "Enabled" : "Disabled"}</span></span>
                                </label>
                            </Col>
                        </Row>
                    </Container>}
            </React.Fragment>
        )
    }
}

export default CustomerSupportAccess;