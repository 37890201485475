import React, { Fragment, useState } from "react";
import Endpoints from '../../../common/Endpoints';
import Header from '../../../common/Header';
import Breadcrumbs from '../../../common/Breadcrumbs';
import LoadingBar from '../../../common/LoadingBar';
import { POST } from '../../../../Consumer';
import { Container, Row, Col, FormGroup, FormControl, FormLabel, Button } from 'react-bootstrap';
import { PREFERENCES_SUB_NAV_LIST } from '../../../common/constants';
import { ErrorAlert } from '../../../common/Alert';
import { Actions, ProcessingModal } from './shared/Snippets';

const SUCCESS_MESSAGE = 'Successfully updated your return selection.';
const ERROR_MESSAGE = 'Error. Something has went wrong. Please try again.';

const breadcrumbs = [
    { text: "Returns", link: "/retailer/preferences/returns" },
    { text: "Return Preferences", link: null }
];

const Preferences = ({ preferences, handleInputChange }) =>
    <FormGroup className="mb-5">
        <FormGroup className="custom-control custom-radio mb-2">
            <FormControl className="custom-control-input" type="radio" id="radio-1" name="group1" value={preferences.returnable} checked={preferences.returnable} onChange={() => handleInputChange("returnable", !preferences.returnable)} />
            <FormLabel className="custom-control-label" htmlFor="radio-1">Make all products returnable</FormLabel>
            {preferences.returnable && <Actions selection={preferences.selection} handleInputChange={handleInputChange} />}
        </FormGroup>
        <FormGroup className="custom-control custom-radio mb-0">
            <FormControl className="custom-control-input" type="radio" id="radio-2" name="group1" value={!preferences.returnable} checked={!preferences.returnable} onChange={() => handleInputChange("returnable", !preferences.returnable)} />
            <FormLabel className="custom-control-label" htmlFor="radio-2">Make all products non-returnable</FormLabel>
            {!preferences.returnable && <Actions selection={preferences.selection} handleInputChange={handleInputChange} />}
        </FormGroup>
    </FormGroup>


const EditDefaultReturnPreferences = ({ location, history }) => {

    //#region State

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [modal, setModal] = useState(false);

    const [returnPreferences, setReturnPreferences]
        = useState({ returnable: location?.state?.returnable || false, selection: location?.state?.selection || 'ApplyToAllProducts' });

    //#endregion

    //#region API

    const applyToAllAsync = async () => {
        setModal(true);
        try {
            const response = await POST(Endpoints.RETURNS.POST.RETURN_APPLY_TO_ALL);

            if (response?.ok) {
                await toggleReturnPreferences();
            } else {
                setError({});
                setModal(false);
            }
        } catch (error) {
            setError({});
            setModal(false);
        }
    }

    const applyToNewAsync = async () => {
        setModal(true);
        try {
            const response = await POST(Endpoints.RETURNS.POST.RETURN_APPLY_TO_NEW);

            if (response?.ok) {
                await toggleReturnPreferences();
            } else {
                setError({});
                setModal(false);
            }
        } catch (error) {
            setError({});
            setModal(false);
        }
    }

    const toggleReturnPreferences = async () => {
        setLoading(true);
        try {
            const response = await POST(Endpoints.RETURNS.POST.TOGGLE_RETURN_PREFERENCES, returnPreferences);

            if (response?.ok) {
                setModal(false);
                response && !response.error ? history.push({ pathname: "/retailer/preferences/returns", state: { success: true, successMessage: SUCCESS_MESSAGE } }) : history.push({ pathname: "/retailer/preferences/returns", state: { error: true } });
            } else {
                setError({});
            }
        } catch (error) {
            setError({});
        } finally {
            setLoading(false);
            setModal(false);
        }
    };

    //#endregion

    //#region Controls

    const handleInputChange = (name, value) => {
        if (name === "returnable") { setReturnPreferences({ selection: 'ApplyToAllProducts', returnable: value }); }
        else { setReturnPreferences((prevState) => ({ ...prevState, selection: value, })); }
    };

    const handleNextStep = async () => {
        return returnPreferences.selection === "ApplyToAllProducts" ? await applyToAllAsync() : await applyToNewAsync();
    };

    //#endregion

    //#region Render

    return (
        loading
            ? <LoadingBar />
            : <Fragment>
                <Header title="Preferences" subNavList={PREFERENCES_SUB_NAV_LIST} activeKey="Returns" headerClass="mb-2" />
                <Container fluid>
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                    <Row>
                        <Col sm={12} md={6}>
                            <div className="mb-5">
                                {error && <ErrorAlert errorMessage={ERROR_MESSAGE} />}
                                <h5 className="mb-3">Edit Return Preferences</h5>
                                <p>Please select an option below:</p>
                                {returnPreferences && <Preferences preferences={returnPreferences} handleInputChange={handleInputChange} />}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            <small>Note: These changes will not affect returns currently in process</small>
                            <Button variant="primary" className="float-right" onClick={handleNextStep}>Save Preferences</Button>
                        </Col>
                    </Row>
                </Container>
                <ProcessingModal showModal={modal} title="We're working on it" />
            </Fragment>
    );

    //#endregion

}

export default EditDefaultReturnPreferences;
