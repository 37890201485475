import React from 'react';
import { Table, Button, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle  } from '@fortawesome/free-solid-svg-icons';
import { toLocalTimeString } from '../../../Utilities';
import { UNTRACKED_SERVICE_PREFIX, UNTRACKED_SERVICE } from './shared/StringConstants';
import NoResults from '../../common/Tables/NoResults';

const renderStatus = (statusName) => {
    switch (statusName) {
        case "Awaiting Collection":
        case "Part Fulfilled":
        case "Collection Booked":
        case "Shipped":
            return <span className="text-status-green">{statusName}</span>;
        case "Received":
            return <span className="text-status-yellow">{statusName}</span>;
        case "Awaiting Fulfilment":
        case "Fulfilling Partial Order":
        case "Fulfilment In Progress":
        case "Picking In Progress":
        case "Packing In Progress":
            return <span className="text-status-blue">{statusName}</span>;
        default:
            return <span className="text-status-grey">{statusName}</span>;
    }
}

const renderTrackingRef = (order) => {
  let trackingRef = 'N/A';
  if(order.parcelReferences.length > 0) trackingRef = order.parcelReferences[0]?.toUpperCase().includes(UNTRACKED_SERVICE_PREFIX) ? UNTRACKED_SERVICE : order.parcelReferences.join(' ');  
  return trackingRef;
}


const OrderRow = ({ order, handleViewOrderClick }) =>
{
    return (
    <tr>
        <td>
            {order.customerReference} {order.containsMissingItems 
                ? (<OverlayTrigger
                    placement="right"
                    overlay={renderTooltip} >
                        <FontAwesomeIcon icon={faExclamationTriangle}/>
                    </OverlayTrigger>) 
                : null}
        </td>
        <td className="table-data-restricted-md">{renderTrackingRef(order)}</td>
        <td>{order.customerOrganisationPersonName}</td>
        <td>{toLocalTimeString(order.createdDate)}</td>
        <td>{renderStatus(order.status.name)} {order?.containsPartialOrder && <Image src='Images/partial-order.svg' className="image-table-data float-right" />}</td>
        <td className="text-center">
            <Button className="link-button" variant="secondary" size="sm" onClick={handleViewOrderClick}>View Order</Button>
        </td>
    </tr>)
}

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      We are trying to locate an item for this order. We'll notify you if needed.
    </Tooltip>
  );

const OrderTable = ({ orders, handleViewOrderClick }) =>
    <Table striped bordered size="sm" className={!orders.length ? "table-empty" : ""}>
        <thead>
            <tr>
                <th>Order Number</th>
                <th>Tracking Ref</th>
                <th>Customer Name</th>
                <th>Date Created</th>
                <th>Status</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {orders.length
                ? orders.map(order => <OrderRow key={order.id} order={order} handleViewOrderClick={() => handleViewOrderClick(order)} />)
                : <NoResults colSpan="6" text="No orders available." /> 
            }
        </tbody>
    </Table>

export default OrderTable;