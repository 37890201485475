import React from 'react';

export const NoBarcodeTooltip = (props) => (
  <div>
    <div>
      <div id="tooltip-inner-info" className='no-barcode-tooltip' {...props}>
        <em>We scan the product's physical barcode (EAN or SUID) throughout pick and pack for better quality control.</em>
      </div>
    </div>
  </div>
);