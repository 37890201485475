import React from 'react';
import { DropDownWithFilterAndDetails } from '../../../common/DropDownWithFilterAndDetails'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, FormControl, FormLabel, FormGroup, Button } from 'react-bootstrap';
import { COUNTRY_LIST } from '../../../common/constants';
import { ErrorAlert, SuccessAlert } from '../../../common/Alert';
import xmark from '../../../common/shared/Close_x.svg';
import './BulkOrderRecipientDetails.scss';

export const BulkOrderRecipientDetails = ({
  orderInformation,
  contactDetails,
  destinationAddress,
  contactDetailsValidation,
  orderInformationValidation,
  destinationAddressValidation,
  showAddRecipientButton,
  showRecipientDetailsForm,
  isRecipientSelected,
  saveRecipient,
  showSuccessMessage,
  successMessage,
  errorTitle = "Error",
  showErrorMessage,
  errorMessage,
  recipients,
  handleOrderInformationInputChange,
  handleContactDetailsInputChange,
  handleDestinationAddressInputChange,
  handleToggleSaveRecipientPreference,
  handleRecipientChange,
  handleCloseRecipient,
  handleShowRecipientsForm,
  handleShowRecipientsModal,
}) => {

  const { name, emailAddress, contactNumber, companyName } = contactDetails;
  const { addressLine1, addressLine2, town, county, country, postCode } = destinationAddress;

  const onEditDetails = () => handleShowRecipientsModal(true);
  
  const onCloseRecipient = () => handleCloseRecipient();

  const onClickAddRecipient = () => handleShowRecipientsForm();

  const onToggleSaveRecipientPreference = () => handleToggleSaveRecipientPreference();

  return (
    <>
      {showSuccessMessage && <SuccessAlert successMessage={successMessage} successTitle="Success" />}
      {showErrorMessage && <ErrorAlert errorMessage={errorMessage} errorTitle={ errorTitle } />}
      {
        <Form>
          <fieldset>
            <h5 className="mt-2 mb-3">Order Details</h5>
            <FormGroup>
              <FormLabel htmlFor="purchaseOrderNumber">Purchase Order Number</FormLabel>
              <FormControl id="purchaseOrderNumber" maxLength="35" type="text" name="purchaseOrderNumber" value={orderInformation.purchaseOrderNumber} pattern="/^[a-zA-Z0-9_-]*$/" onChange={handleOrderInformationInputChange} required />
              <span className="text-danger">{orderInformationValidation.purchaseOrderNumber.message}</span>
            </FormGroup>
            <div className="dropDownFilterDetails">
              <span className='form-label'>Recipient Details</span>
              {
                isRecipientSelected
                  ? (
                    <div className='recipient-div-details'>
                      <div className="recipient-details-title">
                        <span className='details-title-label'>Recipient Details</span>
                        <img className="xmark-img" onClick={onCloseRecipient} src={xmark} />
                      </div>
                      <div className="recipient-details-body">
                        <div className='display-flex-column'>
                          <span className='form-label'>Name</span>
                          <span className=''>{name}</span>
                        </div>
                        <div className='display-flex-column'>
                          <span className='form-label'>Company Name</span>
                          <span className=''>{companyName}</span>
                        </div>
                        <div className='display-flex-column'>
                          <span className='form-label'>Email</span>
                          <span className=''>{emailAddress}</span>
                        </div>
                        <div className='display-flex-column'>
                          <span className='form-label'>Phone</span>
                          <span className=''>{contactNumber}</span>
                        </div>
                        <div className='display-flex-column'>
                          <span className='form-label'>Address</span>
                          <span className=''>{addressLine1}</span>
                          <span className=''>{addressLine2}</span>
                          <span className=''>{town}</span>
                          <span className=''>{postCode}</span>
                          <span className=''>{county}</span>
                          <span className=''>{COUNTRY_LIST.find(c => c.code === country).name}</span>
                        </div>
                      </div>
                      <div className="recipient-edit-details">
                        <span className='edit-details-label' onClick={onEditDetails}>Edit Recipient Details</span>
                      </div>
                    </div>
                  )

                  : <DropDownWithFilterAndDetails
                    items={recipients}
                    selectedItem={false}
                    valueField='name'
                    onItemChange={handleRecipientChange}
                    initialValue='Select from address book...'
                  />
              }

            </div>
            {
              showAddRecipientButton &&
              (
                <Button onClick={onClickAddRecipient} variant="secondary" className="plus-sign mb-3" type="submit">
                  <FontAwesomeIcon icon={faPlus} className="mr-1" />Add new recipient
                </Button>
              )
            }

            {
              showRecipientDetailsForm &&
              <>
                <FormGroup>
                  <FormLabel htmlFor="name">Name</FormLabel>
                  <FormControl id="name" maxLength="35" type="text" name="name" value={contactDetails.name} onChange={handleContactDetailsInputChange} required />
                  <span className="text-danger">{contactDetailsValidation.name.message}</span>
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="name">Company Name</FormLabel>
                  <FormControl id="company-name" maxLength="35" type="text" name="companyName" value={contactDetails.companyName} onChange={handleContactDetailsInputChange} required />
                  <span className="text-danger">{contactDetailsValidation.companyName.message}</span>
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="emailAddress">Email</FormLabel>
                  <FormControl id="emailAddress" type="email" maxLength="50" name="emailAddress" value={contactDetails.emailAddress} onChange={handleContactDetailsInputChange} required />
                  <span className="text-danger">{contactDetailsValidation.emailAddress.message}</span>
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="contactNumber">Phone</FormLabel>
                  <FormControl id="contactNumber" type="text" maxLength="15" name="contactNumber" value={contactDetails.contactNumber} onChange={handleContactDetailsInputChange} required />
                  <span className="text-danger">{contactDetailsValidation.contactNumber.message}</span>
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="addressLine1">Address line 1</FormLabel>
                  <FormControl id="addressLine1" type="text" size="35" maxLength="35" name="addressLine1" value={destinationAddress.addressLine1} onChange={handleDestinationAddressInputChange} required />
                  <span className="text-danger">{destinationAddressValidation.addressLine1.message}</span>
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="addressLine2">Address line 2<span className="form-label-optional">- optional</span></FormLabel>
                  <FormControl id="addressLine2" type="text" size="35" maxLength="35" name="addressLine2" value={destinationAddress.addressLine2} onChange={handleDestinationAddressInputChange} />
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="town">Town</FormLabel>
                  <FormControl id="town" type="text" size="30" maxLength="30" name="town" value={destinationAddress.town} onChange={handleDestinationAddressInputChange} required />
                  <span className="text-danger">{destinationAddressValidation.town.message}</span>
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="town">Postcode</FormLabel>
                  <FormControl id="postcode" type="text" size="30" maxLength="30" name="postCode" value={destinationAddress.postCode} onChange={handleDestinationAddressInputChange} required />
                  <span className="text-danger">{destinationAddressValidation.postCode.message}</span>
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="county">County</FormLabel>
                  <FormControl id="county" type="text" size="50" maxLength="50" name="county" value={destinationAddress.county} onChange={handleDestinationAddressInputChange} required />
                  <span className="text-danger">{destinationAddressValidation.county.message}</span>
                </FormGroup>
                <FormGroup className="mb-5">
                  <FormLabel htmlFor="country">Country</FormLabel>
                  <FormControl as="select" type="text" id="country" name="country" value={destinationAddress.country} onChange={handleDestinationAddressInputChange} required>
                    <option></option>
                    {COUNTRY_LIST.map((v, i) => <option key={i} value={v.code}>{v.name}</option>)}
                  </FormControl>
                  <span className="text-danger">{destinationAddressValidation.country.message}</span>
                </FormGroup>
                <label className="save-preference-switch">
                  <input id="save-preference-option" type="checkbox" onChange={onToggleSaveRecipientPreference} checked={saveRecipient} />
                  <span className="save-preference-slider round"><span className="toggle-text">Save to Address Book</span></span>
                </label>
              </>
            }
          </fieldset>
        </Form>
      }
    </>
  );

}
