import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { ErrorAlert } from '../../common/Alert';

const PROBLEM_TITLE = "There was a problem processing your card.";

const CompleteCard = ({ errorMessages, onTryAgainClick }) => {

    return (
        <Container fluid>
        <Row>
            <Col sm={12} md={6}>
                {errorMessages.length > 0 && <ErrorAlert errorMessage={errorMessages} errorTitle={PROBLEM_TITLE} />}
                <Button variant="primary" className="px-4 float-right" onClick={onTryAgainClick}>Back to Payments</Button>
            </Col>
        </Row>
    </Container>
    );
}

export default CompleteCard;