import React from 'react';

import { Button, FormLabel, Form, FormControl, InputGroup, Col, FormGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

class PurchaseOrdersFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {  };
    }

    render() {
        const { purchaseOrderReference, sortBy, onFilterChange, onSearch, onClear } = this.props;

        return (
        <Form onSubmit={onSearch}>
            <FormGroup>
                <Row>
                    <Col md={5}>
                        <InputGroup>
                            <FormControl type="text" 
                            id="purchaseOrderReference" 
                            className="input-filter" 
                            name="purchaseOrderReference" 
                            value={ purchaseOrderReference }
                            onChange={ onFilterChange } 
                            placeholder="Search by PO Reference"  />
                            <InputGroup.Append>
                                <InputGroup.Text className="input-filter-icon" onClick={onSearch}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>

                    <Col md={{ span:2, offset: 2 }} className="pr-0">
                        <FormLabel htmlFor="sortBy" className="float-right label-filter">Sort By</FormLabel>
                    </Col>
                    <Col md={3}>
                        <FormControl id="sortBy" name="sortBy" as="select" value={sortBy} onChange={onFilterChange}>
                            <option key="" value="">Please Select...</option>;
                            <option value="POREFERENCE_ASC">PO Reference (ascending)</option>
                            <option value="POREFERENCE_DES">PO Reference (descending)</option>
                        </FormControl>
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Button id="applyFilterPO" variant="primary" className="float-right" type="submit">Apply filter</Button>
                <Button id="clearFilterPO" variant="link" className="float-right" name="filterReset" onClick={ onClear }>Clear filter</Button>
            </FormGroup>
        </Form>
        );
    }
}

export default PurchaseOrdersFilter;
