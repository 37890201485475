import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, FormLabel, FormControl, Col, Button, Row, Card, Image, Container } from 'react-bootstrap';
import FormValidator from '../../common/FormValidator';
import { PUT } from '../../../Consumer';
import ReCAPTCHA from "react-google-recaptcha";
import Endpoints from '../../common/Endpoints';
import Footer from '../../layout/Footer';
import { ErrorAlert } from '../../common/Alert';
import LoadingBar from '../../common/LoadingBar';

const captchaKey = process.env.REACT_APP_CAPTCHA_TOKEN;

const ForgotPasswordForm = ({ handleSubmit, errorMessage, email, handleEmailInputChange, validation, recaptchError, handleRecaptcha }) =>
    <Form onSubmit={handleSubmit}>
        {errorMessage && <ErrorAlert errorMessage={errorMessage} />}
        <h5 className="text-center">Password Reset</h5>
        <p>Please enter the email address associated with your account to reset your password.</p>
        <FormGroup>
            <FormLabel htmlFor="emailField">Email</FormLabel>
            <FormControl id="emailField" type="email" name="email" value={email} onChange={(e) => handleEmailInputChange(e.target.value)} />
            <span className="text-danger">{validation.email.message}</span>
        </FormGroup>
        <Container className="d-flex justify-content-center my-4">
            <ReCAPTCHA sitekey={captchaKey} onChange={handleRecaptcha} />
        </Container>
        {recaptchError && <p className="text-danger">Please complete captcha</p>}

        <Button size="lg" type="submit" block>Reset Password</Button>
        <p className="decorated mb-3 mt-3"><span>Know your password?</span></p>
        <Link to='/' className="link-button">
            <Button variant="secondary" size="lg" type="submit" block>Go to Login Screen</Button>
        </Link>
    </Form>

class ForgotPassword extends Component {

    validator = new FormValidator([
        {
            field: 'email',
            method: 'isEmpty',
            validWhen: false,
            message: 'Email is required'
        }
    ]);

    state = {
        form: {
            email: ''
        },
        recaptcha: false,
        recaptchError: false,
        loading: false,
        validation: this.validator.valid(),
        errorMessage: null
    };

    handleEmailInputChange = (value) => this.setState({ form: { email: value } });

    handleRecaptcha = () => this.setState({ recaptcha: true });

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const { form, recaptcha } = this.state;

        const validation = this.validator.validate(form);
        this.setState({ validation: validation, recaptchError: !recaptcha });

        if (validation.isValid && recaptcha) {
            return PUT(Endpoints.PASSWORD_RESET.PUT.RESET_PASSWORD + form.email)
                .then(response => response.json()).then((result) => {
                    if (!result.error) {
                        this.setState({ errorMessage: null, loading: false });
                        this.props.history.push({ pathname: "/account/password/result", state: { email: form.email } });
                    }
                    else {
                        this.setState({ errorMessage: result.message, loading: false });
                    }
                });
        } else this.setState({ loading: false });
    }

    render() {
        const { loading, form, errorMessage, validation, recaptchError } = this.state;

        return (
            <Fragment>
                {loading
                    ? <LoadingBar />
                    : <Container className="h-100">
                        <Row className="justify-content-center row-form-adjustedheight">
                            <Col md={6} className="align-self-center">
                                <Card className="p-5">
                                    <Image src='Images/logo.png' className="mx-auto mb-3 logo" />
                                    <ForgotPasswordForm
                                        handleSubmit={this.handleSubmit}
                                        errorMessage={errorMessage}
                                        email={form.email}
                                        handleEmailInputChange={this.handleEmailInputChange}
                                        validation={validation} recaptchError={recaptchError}
                                        handleRecaptcha={this.handleRecaptcha}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Footer />
                    </Container>
                }
            </Fragment>
        );
    }
}

export default ForgotPassword;