import React from 'react';
import { Form, FormControl, FormLabel, FormGroup } from 'react-bootstrap';
import './BundleWizard.scss';

class BundleDetails extends React.Component {

    render() {
        const { bundleDetails, bundleDetailsValidation, handleBundleDetailsInputChange, isEdit } = this.props;
        return (
            <Form>
                <fieldset>
                    {isEdit ? <h6>Edit Bundle Details</h6> : <h6>Bundle Details</h6>}
                    <FormGroup>
                        <FormLabel htmlFor="name">Bundle Name</FormLabel>
                        <FormControl id="name" type="text" name="name" size="100" maxLength={98} value={bundleDetails.name} onChange={handleBundleDetailsInputChange} required />
                        <span className="text-danger">{bundleDetailsValidation.name.message}</span>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="description">Bundle Description <span className="form-label-optional">- optional</span></FormLabel>
                        <FormControl id="description" type="text" as="textarea" rows="3" name="description" value={bundleDetails.description} onChange={handleBundleDetailsInputChange} required />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="skuCode">Bundle SKU</FormLabel>
                        <FormControl disabled={isEdit} id="skuCode" type="text" size="50" maxLength="50" name="skuCode" value={bundleDetails.skuCode} onChange={handleBundleDetailsInputChange} required />
                        <span className="text-danger">{bundleDetailsValidation.skuCode.message}</span>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="price">Bundle Price (£) <span className="form-label-optional">- optional</span></FormLabel>
                        <FormControl id="price" type="number" onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '+') && evt.preventDefault() } min="0" max="50000" step="0.01" name="price" value={bundleDetails.price} onChange={handleBundleDetailsInputChange} />
                        <span className="text-danger">{bundleDetailsValidation.price.message}</span>
                    </FormGroup>
                </fieldset>
            </Form>
        );
    }
}

export default BundleDetails;