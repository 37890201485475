import React from 'react';
import { Image } from 'react-bootstrap';

const NoResults = ({ text, colSpan, missingImage = 'Images/missing-data.svg' }) =>
    <tr>
        <td colSpan={colSpan} className="text-center">
            <Image src={missingImage} className="mx-auto my-3 image-table" />
            <p><strong>{text}</strong></p>
        </td>
    </tr>

export default NoResults
