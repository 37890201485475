import React from 'react';
import { FormGroup, FormLabel, FormControl, Row, Col } from 'react-bootstrap';

const Pricing = ({ companyItem, priceValidation, expectedWeeklySalesValidation, handleInputChange }) =>
    <React.Fragment>
        <h5>{"Sales & Pricing"}</h5>
        <FormGroup as={Row}>
            <Col sm={12} md={4}>
                <FormLabel htmlFor="priceField">Product Price (£)</FormLabel>
                <FormControl id="priceField" type="number" parent="currentPrice" name="price" value={companyItem.currentPrice.price} onChange={handleInputChange} min="0" step="0.01" />
            </Col>
            <Col md={12}>
                <span className="text-danger">{priceValidation.price.message}</span>
            </Col>
        </FormGroup>
        <FormGroup as={Row}>
            <Col md={12}>
                <FormLabel htmlFor="expectedWeeklySalesField" className="mb-0">Expected Weekly Sales</FormLabel>
                <div className="form-input-description">
                    <p>We need to know your expected weekly sales to send you stock alerts. If your weekly sales is 0 then we will automatically add a default of 20% to the pallet total.</p>
                </div>
            </Col>
            <Col sm={12} md={4}>
                <FormControl id="expectedWeeklySalesField" type="number" name="expectedWeeklySales" value={companyItem.expectedWeeklySales} onChange={handleInputChange} min="0" />
            </Col>
            <Col md={12}>
                <span className="text-danger">{expectedWeeklySalesValidation.expectedWeeklySales.message}</span>
            </Col>
        </FormGroup>
    </React.Fragment>

export default Pricing;