import React from 'react';
import { Button } from 'react-bootstrap';
import { ErrorAlert } from '../../../../common/Alert';
import { COUNTRY_LIST } from '../../../../common/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';

import './ConfirmProduct.scss'

const ProductOverview = ({ name, description, brand, category, handleEditStep }) => (
    <div className="section-item mt-3">
        <h6>Product Overview</h6>
        <p className="title">Product Name</p>
        <p>{name}</p>
        <p className="title">Product Description</p>
        <p>{description}</p>
        <p className="title">Brand</p>
        <p>{brand}</p>
        <p className="title">Category</p>
        <p>{category}</p>
        <Button variant="link" className="p-0" onClick={() => handleEditStep(0)}>Edit Product Overview</Button>
    </div>
);

const ProductDetails = ({ dimensions, weight, country, commodityCode, handleEditStep }) => (
    <div className="section-item">
        <h6>Product Details</h6>
        <p className="title">Dimensions (cm)</p>
        <p>{dimensions}</p>
        <p className="title">Weight (kg)</p>
        <p>{weight}</p>
        <p className="title">Country of Manufacturing</p>
        <p>{country}</p>
        <p className="title">Commodity Code</p>
        <p>{commodityCode}</p>
        <Button variant="link" className="p-0" onClick={() => handleEditStep(1)}>Edit Product Details</Button>
    </div>
);

const Pricing = ({ price, expectedWeeklySales, handleEditStep }) => (
    <div className="section-item">
        <h6>{"Sales & Pricing"}</h6>
        <p className="title">Product Price</p>
        <p>{`£${price}`}</p>
        <p className="title">Expected Weekly Sales</p>
        <p>{expectedWeeklySales}</p>
        <Button variant="link" className="p-0" onClick={() => handleEditStep(2)}>{"Edit Sales & Pricing"}</Button>
    </div>
);

const UniqueIdentifiers = ({ ean, skus = [], noScan, itemImages = [], batch, handleEditStep }) => (
    <div className="section-item">
        <h6>Unique Identifiers</h6>
        <p className="title">EAN</p>
        <p>{ean ? ean : "No EAN entered"}</p>
        <p className="title">SKUs</p>
        <div className="mb-3">
            {skus.length > 0 ? skus.map((f, i) => <p className="mb-0" key={`sku-${i}`}>{f.code}</p>) : <p className="mb-0">No SKUs entered</p>}
        </div>
        <p className="title">SUID</p>
        <p>{ean ? "SUID to be generated after submission of product" : "No SUID to be generated"}</p>
        <p className="title">Batch management</p>
        <p>{batch ? "Yes, this product is handled in batches" : "No, this product is not handled in batches"}</p>
        <p className="title">No-Barcode Product</p>
        <p>{noScan ? "No-barcode product" : "Barcoded product"}</p>
        <p className="title">Product Image</p>
        {skus.length > 0 ?
            skus.filter(s => s.itemImageID !== "" && s.itemImage !== null).length > 0 ?
                <div className="form-image-list mt-2">
                    <ul>
                        {skus.filter(s => s.itemImageID !== "" && s.itemImage !== null).map((sku, i) => <li key={i}><FontAwesomeIcon icon={faImage} className="form-fileicon" />{sku.itemImage.fileName}</li>)}
                    </ul>
                </div> : <p>You do not have an image, you can upload one at anytime.</p>
            : itemImages.length > 0 ? <div className="form-image-list mt-2">
                <ul>
                    {itemImages.map((image, i) => <li key={i}><FontAwesomeIcon icon={faImage} className="form-fileicon" />{image.name}</li>)}
                </ul>
            </div> : <p>You do not have an image, you can upload one at anytime.</p>}
        <Button variant="link" className="p-0" onClick={() => handleEditStep(3)}>Edit Unique Identifiers</Button>
    </div>
);

const PackagingPreferences = ({ isPrePacked, requiresBoxPackaging, requiresPaperPackaging, isFragile, handleEditStep }) => (
    <div className="section-item">
        <h6>Packaging Preferences</h6>
        <p className="title">Is this product pre-packed?</p>
        <p>{isPrePacked ? "Yes, it is pre-packed" : "No, it is not pre-packed"}</p>
        <p className="title">Is this product box packed?</p>
        <p>{requiresBoxPackaging ? "Yes, it is box packed" : "No, it is not box packed"}</p>
        <p className="title">Packaging Preference</p>
        <p>
            { requiresPaperPackaging ? "Paper packaging" : "No packaging preference"}
        </p>
        <p className="title">Is this product fragile</p>
        <p>{isFragile ? "Yes, it is fragile" : "No, it is not fragile"}</p>
        <Button variant="link" className="p-0" onClick={() => handleEditStep(4)}>Edit Packaging Preferences</Button>
    </div>
);

const AdditionalInformation = ({ pickFromPallet }) => (
    <div className="section-item">
        <h6>Additional Information</h6>
        {pickFromPallet &&
            <React.Fragment>
                <p className="title">Pick from Pallet</p>
                <p>We have detected you are uploading a large product. These products will be kept on pallets and picked directly from them, providing you with more cost-effective storage.</p>
            </React.Fragment>
        }
    </div>
);

const ConfirmProduct = ({ companyItem, categories, images, handleEditStep, message }) => {
    const category = categories.find(c => c.id === companyItem.item.categoryID).name;
    const country = COUNTRY_LIST.find(c => c.code === companyItem.item.countryCodeOfManufacture).name;

    return (
        <React.Fragment>
            {message && <ErrorAlert errorMessage={message} />}
            <ProductOverview name={companyItem.item.name} description={companyItem.item.description} brand={companyItem.item.brand} category={category} handleEditStep={handleEditStep} />
            <ProductDetails dimensions={`${companyItem.item.height} x ${companyItem.item.width} x ${companyItem.item.depth}`} weight={companyItem.item.weight} country={country} commodityCode={companyItem.item.commodityCode} handleEditStep={handleEditStep} />
            <Pricing price={companyItem.currentPrice.price} expectedWeeklySales={companyItem.expectedWeeklySales} handleEditStep={handleEditStep} />
            <UniqueIdentifiers ean={companyItem.item.ean} batch={companyItem.item.batch} skus={companyItem.stockKeepingUnits} noScan={companyItem.noScan} itemImages={images} handleEditStep={handleEditStep} />
            <PackagingPreferences isPrePacked={companyItem.isPrePacked} requiresBoxPackaging={companyItem.requiresBoxPackaging} requiresPaperPackaging={companyItem.requiresPaperPackaging} isFragile={companyItem.isFragile} handleEditStep={handleEditStep} />
            {companyItem.pickFromPallet && <AdditionalInformation pickFromPallet={companyItem.pickFromPallet} />}
        </React.Fragment>
    )
}

export default ConfirmProduct;