import React from 'react';
import { Card } from 'react-bootstrap';
import './BundleCard.scss';

const BundleCard = ({ productBundle, showDetails }) =>
    <Card className="p-3 mb-3 card-list" onClick={() => showDetails(productBundle)} >
        <p className="card-list-title"><strong>{productBundle.name}</strong></p>
        <div className="card-list-info">
            <p><span className="info-title">SKU</span>{productBundle.skuCode}</p>
            <p><span className="info-title">Total Products</span>{productBundle.totalProducts}</p>
            <p><span className="info-title mb-2">Bundle Stock Total</span>{productBundle.bundleStockTotal}</p>
        </div>
    </Card>

export default BundleCard;