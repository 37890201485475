import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { BULK_ORDER_SUB_NAV_LIST } from '../../../common/constants'
import { POST, extractData, GET, DELETE, PUT } from '../../../../Consumer';
import { hasTokenExpired } from '../../../../Utilities'
import { RecipientsModal } from './RecipientsModal';
import { BulkOrderRecipientDetails } from './BulkOrderRecipientDetails';
import { ErrorAlert } from '../../../common/Alert';
import Endpoints from '../../../common/Endpoints';
import Header from '../../../common/Header';
import Stepper from '../../../common/wizard/Stepper';
import StepperButtons from '../../../common/wizard/StepperButtons';
import LoadingBar from '../../../common/LoadingBar';
import BulkOrderManualLoading from './BulkOrderManualLoading';
import BulkPickOptions from './BulkOrderOptions';
import BulkOrderItemSelection from './BulkOrderItemSelection';
import FormValidator from '../../../common/FormValidator';
import BoxSplit from './BulkOrderBoxSplit';
import BulkOrderSummaryBox from './BulkOrderSummaryBox';
import BulkOrderShippingResults from './BulkOrderShippingResults';
import BulkOrderConfirmation from './BulkOrderConfirmation';
import BulkOrderSuccess from './BulkOrderSuccess';
import MissingDetails from '../../../common/MissingDetails';
import './BulkOrderWizard.scss';

const steps = ["Order Details", "Bulk Order Options", "Item Selection", "Split", "Shipping", "Confirm"];
const cancelLink = "/retailer/bulkorder/info";
const MANDATORY_FIELD_VALIDATION_MESSAGE = "You have not properly filled in all mandatory fields, please check the error messages below";
const PAPER_PACKAGING_PREFERENCE = "PAPER";
const MISSING_DETAILS_MESSAGE = "You need to complete the following before you are able to create bulk orders.";
const ADDED_RECIPIENT_SUCCESS_MESSAGE = 'Recipient has been added to your Address Book';
const DUPLICATED_RECIPIENT_MESSAGE = 'Please select recipient address from the address book';
const DELETED_RECIPIENT_SUCCESS_MESSAGE = 'Recipient has been deleted from address book';
const UPDATED_RECIPIENT_SUCCESS_MESSAGE = 'Recipient has been updated.';
const GENERIC_FETCH_RECIPIENTS_ERROR_MESSAGE = 'Something went wrong trying to retrieve the recipients from your address book, please contact customer service';
const GENERIC_RECIPIENTS_ERROR_MESSAGE = 'Recipient details could not be saved at this time';
const UNSUCCESSFUL = 'Unsuccessful';
const VALID_EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const VALID_PHONE_REGEX = /^(?:(?:\+|0)(?:[0-9]\d{0,}))?[1-9]\d*(?:\s?[.-]?\s?\d+)*$/;

const eoriErrorMessage = <p>You need an EORI number if you want to export goods from the UK or EU, or International. You need to <Link to="/retailer/preferences/taxes">add a GB EORI number</Link> before continuing.</p>;
const getBoxLimitMessage = (boxLimit) => `You have exceeded ${boxLimit} boxes in total. Please reduce this number or split the order into multiple bulk orders`;

const itemSelectionError = (errorMessages) => {
  const message =
    <Fragment>
      <p>The following products do not have enough stock to fulfill this bulk order:</p>
      {errorMessages.length > 0 ? errorMessages.map((e, i) =>
        <Fragment key={i}>
          <p className="mb-0"><b>{e.name}</b></p>
          <p className="mb-0">{e.message}</p>
          <br />
        </Fragment>)
        : null}
      <p>Please change item selection, or try again in 15 minutes.</p>
    </Fragment>

  return <ErrorAlert errorMessage={message} />
}

class BulkOrderWizard extends Component {
  constructor(props) {
    super(props);

    this.orderInformationValidator = new FormValidator([
      {
        field: 'purchaseOrderNumber',
        method: 'isEmpty',
        validWhen: false,
        message: 'Purchase Order Number is required'
      },
      {
        field: 'purchaseOrderNumber',
        method: 'isLength',
        validWhen: true,
        args: [{ max: 20 }],
        message: 'Purchase Order Number must be a maximum of 20 characters'
      }
    ]);
    this.contactDetailsValidator = new FormValidator([
      {
        field: 'name',
        method: 'isEmpty',
        validWhen: false,
        message: 'Name is required'
      },
      {
        field: 'companyName',
        method: 'isEmpty',
        validWhen: false,
        message: 'Company name is required'
      },
      {
        field: 'emailAddress',
        method: 'isEmpty',
        validWhen: false,
        message: 'Email Address is required'
      },
      {
        field: 'emailAddress',
        method: 'matches',
        validWhen: true,
        args: [VALID_EMAIL_REGEX],
        message: 'Email invalid format'
      },
      {
        field: 'contactNumber',
        method: 'isEmpty',
        validWhen: false,
        message: 'Phone number is required'
      },
      {
        field: 'contactNumber',
        method: 'matches',
        validWhen: true,
        args: [VALID_PHONE_REGEX],
        message: 'Phone invalid format'
      }
    ]);

    this.destinationAddressValidator = new FormValidator([
      {
        field: 'addressLine1',
        method: 'isEmpty',
        validWhen: false,
        message: 'Address Line 1 is required'
      },
      {
        field: 'town',
        method: 'isEmpty',
        validWhen: false,
        message: 'Town is required'
      },
      {
        field: 'county',
        method: 'isEmpty',
        validWhen: false,
        message: 'County is required'
      },
      {
        field: 'country',
        method: 'isEmpty',
        validWhen: false,
        message: 'Country is required'
      },
      {
        field: 'postCode',
        method: 'isEmpty',
        validWhen: false,
        message: 'Post code is required'
      },
    ]);

    this.optionsValidator = new FormValidator([
      {
        field: 'ownBoxSplit',
        method: 'isEmpty',
        validWhen: false,
        message: 'Please select an option'
      },
      {
        field: 'separateSKUs',
        method: 'isEmpty',
        validWhen: false,
        message: 'Please select an option'
      },
      {
        field: 'shippingRequired',
        method: 'isEmpty',
        validWhen: false,
        message: 'Please select an option'
      },
      {
        field: 'packagingRequired',
        method: 'isEmpty',
        validWhen: false,
        message: 'Please select an option'
      },
      {
        field: 'packingPreference',
        method: 'isEmpty',
        validWhen: false,
        message: 'Please select an option'
      },
      {
        field: 'fragileItems',
        method: 'isEmpty',
        validWhen: false,
        message: 'Please select an option'
      }
    ]);

    this.boxSplitValidator = new FormValidator([
    ]);

    this.shippingServiceValidator = new FormValidator([
      {
        field: 'selected',
        method: 'isBoolean',
        validWhen: true,
        message: 'Select shipping option before continuing'
      },
    ]);

    this.state = {
      loading: true,
      items: [],
      pageIndex: 1,
      pageCount: 1,
      currentPage: [],
      warning: '',
      orderInformationValidation: this.orderInformationValidator.valid(),
      contactDetailsValidation: this.contactDetailsValidator.valid(),
      destinationAddressValidation: this.destinationAddressValidator.valid(),
      optionsValidation: this.optionsValidator.valid(),
      itemsSelectionValidation: "",
      boxSplitValidation: this.boxSplitValidator.valid(),
      shippingServiceValidation: this.shippingServiceValidator.valid(),
      errors: [],
      itemSelectionErrors: [],
      success: false,
      showConfirmError: false,
      activeStep: 0,
      isRecipientSelected: false,
      selectedRecipientID: "",
      isReadyForNextStep: false,
      saveRecipient: false,
      showAddRecipientButton: true,
      showRecipientDetailsForm: false,
      showSuccessMessage: false,
      successMessage: "",
      errorTitle: "",
      showErrorMessage: false,
      errorMessage: "",
      recipients: [],
      showRecipientsModal: false,
      showDeleteConfirmationModal: false,
      orderInformation: {
        purchaseOrderNumber: "",
      },
      contactDetails: {
        name: "",
        emailAddress: "",
        contactNumber: "",
        companyName: ""
      },
      destinationAddress: {
        addressLine1: "",
        addressLine2: "",
        town: "",
        county: "",
        country: "GB",
        postCode: "",
      },
      options: {
        ownBoxSplit: 'false',
        separateSKUs: 'false',
        shippingRequired: 'true',
        packagingRequired: 'true',
        packingPreference: 'paper',
        fragileItems: 'false',
        shippingServiceTime: 'noPreference'
      },
      boxSplit: {
        numberOfBoxes: 1,
        previousBoxTotal: 1,
        showTotalBoxQuestion: true,
        showServerValidation: false,
        showManualSplitSummary: false,
        all: { "boxes": [] }
      },
      selectedItems: { "items": [] },
      displaySummary: false,
      totalAddedCount: 0,
      shippingService: {
        id: "",
        name: "",
        service: "",
        cost: "",
        selected: false
      },
      filter: {
        searchText: "",
        sortBy: ""
      },
      shippingResult: {},
      showDifferentServiceMessage: false,
      originalServiceTimePreference: "",
      noCourierError: false,
      courierCalculation: {},
      showEoriError: false,
      limitsEnabled: false,
      itemLimit: 0,
      boxLimit: 0,
      hasValidCardDetails: false,
      hasValidWarehouse: false,
      showManualLoading: false
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.fetchBulkOrderLimits(),
      this.validateCardDetails(),
      this.validateWarehouse(),
      this.fetchAllRecipients()
    ]);

    this.setState({ loading: false });
  }

  fetchAllRecipients = async () => {
    try {
      const { companyID } = JSON.parse(localStorage.getItem('user'));
      let url = Endpoints.COMPANY_CUSTOMER_ADDRESS.GET.GET_CUSTOMER_ADDRESS_FOR_COMPANY;
      const response = await GET(url + companyID);

      if (response.ok) {
        const data = extractData(await response.json());

        if (data !== null && !data.error) {
          const recipients = data.map(recipient => {
            let newRecipient = {
              ...recipient,
              emailAddress: recipient.email,
              contactNumber: recipient.phone
            }
            delete newRecipient['phone'];
            delete newRecipient['email'];
            return newRecipient;
          });

          this.setState({
            recipients,
            showErrorMessage: false,
            errorMessage: ""
          });
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, showErrorMessage: true, errorMessage: GENERIC_FETCH_RECIPIENTS_ERROR_MESSAGE, errorTitle: "Error" });
    }
  };

  handleToggleSaveRecipientPreference = () => {
    this.setState(prevState => ({ saveRecipient: !prevState.saveRecipient }));
  }

  validateCardDetails = () =>
    GET(Endpoints.FINANCE.CARD.GET.HAS_VALID_DETAILS)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          console.log(res.message);
        }
        else extractData(res) ? this.setState({ hasValidCardDetails: true }) : this.setState({ hasValidCardDetails: false });
      })
      .catch(error => console.log(error));

  validateWarehouse = () =>
    GET(Endpoints.WAREHOUSE.GET.SELECTION)
      .then(response => {
        if (response.ok) return response.json();
      })
      .then(result => {
        const data = extractData(result);
        this.setState({ hasValidWarehouse: data.id !== null && data.id !== "" });
      })
      .catch(error => console.log(error));

  validateRecipientDetails = async (orderInformation, contactDetails, destinationAddress) => {

    let orderInformationValidation = this.orderInformationValidator.validate(orderInformation);
    let contactDetailsValidation = this.contactDetailsValidator.validate(contactDetails);
    let destinationAddressValidation = this.destinationAddressValidator.validate(destinationAddress);

    this.setState({
      orderInformationValidation: orderInformationValidation,
      contactDetailsValidation: contactDetailsValidation,
      destinationAddressValidation: destinationAddressValidation
    });

    if (!orderInformationValidation.isValid) {
      return await this.setValidationState(orderInformationValidation, MANDATORY_FIELD_VALIDATION_MESSAGE);
    } else if (!contactDetailsValidation.isValid) {
      return await this.setValidationState(contactDetailsValidation, MANDATORY_FIELD_VALIDATION_MESSAGE);
    } else {

      orderInformation.purchaseOrderNumber = orderInformation.purchaseOrderNumber.trim();
      contactDetails.name = contactDetails.name.trim();
      contactDetails.contactNumber = contactDetails.contactNumber.trim();
      destinationAddress.addressLine1 = destinationAddress.addressLine1.trim();
      destinationAddress.addressLine2 = destinationAddress.addressLine2.trim();
      destinationAddress.town = destinationAddress.town.trim();
      destinationAddress.county = destinationAddress.county.trim();
      destinationAddress.postCode = destinationAddress.postCode.trim();

      if (destinationAddress.country === "GB" && destinationAddressValidation.isValid) {
        const postCodeNoSpaces = destinationAddress.postCode.replace(/\s/g, '');
        destinationAddress.postCode = postCodeNoSpaces.replace(/.{3}$/, ' $&');
      }

      return await this.setValidationState(destinationAddressValidation, MANDATORY_FIELD_VALIDATION_MESSAGE);
    }
  }

  checkForEORI = async (countryCode) => {
    return countryCode == "GB" ? true : await this.verifyEORI("GB");
  }

  validateOptions = async (options) => {
    let validation = this.optionsValidator.validate(options);

    if (options.ownBoxSplit === 'true' && options.separateSKUs === 'true') {
      validation.isValid = false;
      validation.separateSKUs.message = "You cannot have separate SKUs if you have specified your own box split";
      return await this.setValidationState(validation, "You cannot have separate SKUs if you have specified your own box split")
    }
    else return await this.setValidationState(validation, MANDATORY_FIELD_VALIDATION_MESSAGE)
  }

  validateShippingService = async (shippingService) => {
    let validation = this.shippingServiceValidator.validate(shippingService);
    if (!shippingService.selected) {
      validation.isValid = false;
      validation.selected.isInvalid = true;
      validation.selected.message = "Select shipping option before continuing";
      this.setState({ shippingResult: { destinationAddress: '', bulkOrderBoxes: '', totalCost: '' } });
    }
    else this.setState({ shippingResult: { destinationAddress: this.state.destinationAddress, bulkOrderBoxes: this.state.courierCalculation.packagingRequired, totalCost: this.state.shippingService.cost } });
    return await this.setValidationState(validation, "Please select that the shipping option is suitable before proceeding");
  }

  clearBoxSplit = async () => {
    this.setState(prevState => ({
      ...prevState,
      boxSplit: {
        ...prevState.boxSplit,
        ...{ boxes: [] },
        ...{ numberOfBoxes: 1 },
      }
    }));
  }

  validateItems = async () => {
    const { selectedItems } = this.state;
    let validation = { isValid: true };
    const noItemsSelectedMessage = "You have not selected any items to add to the order";
    let itemSelected = false;

    if (selectedItems.items === undefined) {
      validation.isValid = false;
      return await this.setValidationState(validation, noItemsSelectedMessage);
    }
    else {
      Object.entries(selectedItems.items).forEach(([key, value]) => { if (parseInt(`${value.quantity}`) !== 0) itemSelected = true });
      if (itemSelected === false) {
        validation.isValid = false;
        return await this.setValidationState(validation, noItemsSelectedMessage)
      }
    }
    validation.isValid = true;
    return await this.setValidationState(validation, '')
  }

  validateBoxSplit = async (boxSplit) => {
    const { totalAddedCount, options, selectedItems, showPackagingOptions, limitsEnabled, boxLimit } = this.state;
    let validation = { isValid: true };
    const noOfBoxes = boxSplit.numberOfBoxes;

    if (options.ownBoxSplit === 'true') {
      if (showPackagingOptions) {
        if (noOfBoxes === '' || noOfBoxes === 0) {
          validation.isValid = false;
          return await this.setValidationState(validation, "Total number of boxes is required");
        }
        else if (noOfBoxes <= 0 || noOfBoxes % 1 !== 0) {
          validation.isValid = false;
          return await this.setValidationState(validation, "Invalid number of boxes");
        }
        else if (noOfBoxes > totalAddedCount) {
          validation.isValid = false;
          return await this.setValidationState(validation, "There are more boxes requested than items picked");
        } else {
          validation.isValid = true;
          return await this.setValidationState(validation, "");
        }
      }
      else {
        let unassignedIssues = 0;
        let assignedIssues = 0;
        let unassignedBoxes = 0;

        Object.entries(selectedItems.items).map(([key, value]) => {
          if (value.unassigned !== 0 && value.assigned > 0) assignedIssues++;
          if (value.unassigned > 0) unassignedIssues++;
        });

        for (let i = 0; i < noOfBoxes; i++) {
          if (i < noOfBoxes) {
            if (boxSplit.all.boxes[i] === undefined) unassignedBoxes++;
            else if (boxSplit.all.boxes[i].items.every(i => i.quantity === 0 || i.quantity === '')) unassignedBoxes++;
          }
        }

        if (unassignedIssues > 0) {
          validation.isValid = false;
          return await this.setValidationState(validation, "There are remaining items requiring assignment.");
        }
        else if (assignedIssues > 0) {
          validation.isValid = false;
          return await this.setValidationState(validation, "There are more items assigned than selected for bulk order.");
        }
        else if (unassignedBoxes > 0) {
          validation.isValid = false;
          return await this.setValidationState(validation, "There are boxes without assigned items.");
        } else {
          validation.isValid = true;
          return await this.setValidationState(validation, "");
        }
      }
    } else {
      if (boxSplit.all.boxes.length === 0) {
        validation.isValid = false;
        return await this.setValidationState(validation, "No order or items submitted, please go back and try again.");
      } else if (limitsEnabled && boxSplit.all.boxes.length > boxLimit) {
        validation.isValid = false;
        return await this.setValidationState(validation, getBoxLimitMessage(boxLimit));
      } else {
        validation.isValid = true;
        return await this.setValidationState(validation, "");
      }
    }
  }

  handleToggleShowHide = (showOptions) => {
    if (!showOptions) {
      this.setState({ showPackagingOptions: false });
      this.setState(prevState => ({
        ...prevState,
        options: {
          ...prevState.options,
          ...{ packingPreference: 'paper' }
        }
      }));
    }
  };

  handleBoxQuantityRequest = async (validQuantity) => {
    const show = (validQuantity) ? false : true;
    this.setState(prevState => ({
      ...prevState,
      boxSplit: {
        ...prevState.boxSplit,
        ...{ showTotalBoxQuestion: show },
      }
    }));
  };

  handleSelectedItemsRequest = async (selectedItems) => {
    this.setState({ selectedItems: selectedItems });
  };

  setValidationState = async (validation, text) => {
    if (validation.isValid) {
      validation.isValid = true;
      this.setState({ errors: [] });
    }
    else {
      validation.isValid = false;
      this.setState({ errors: [text] });
    }

    return validation;
  }

  verifyEORI = (countryCode) => {
    return GET(Endpoints.RETAILER.GET.VERIFY_EORI + countryCode + '/valid')
      .then(response => { if (response.ok) return response.json(); })
      .then(result => { return result.data })
      .catch(error => console.log(error));
  }

  validateToken = () => {
    const isTokenExpired = hasTokenExpired();
    if (isTokenExpired) {
      localStorage.clear();
      window.open("/", "_self");
    }
  }

  createRecipient = async () => {
    try {
      this.setState({ loading: true });
      const { companyID } = JSON.parse(localStorage.getItem('user'));
      const { contactDetails, destinationAddress } = this.state;
      const url = Endpoints.COMPANY_CUSTOMER_ADDRESS.POST.CREATE_CUSTOMER_ADDRESS_FOR_COMPANY;

      const body = {
        companyID: companyID,
        name: contactDetails.name,
        companyName: contactDetails.companyName,
        email: contactDetails.emailAddress,
        phone: contactDetails.contactNumber,
        addressLine1: destinationAddress.addressLine1,
        addressLine2: destinationAddress.addressLine2,
        country: destinationAddress.country,
        county: destinationAddress.county,
        town: destinationAddress.town,
        postCode: destinationAddress.postCode,
      };

      const response = await POST(url, body);
      if (response.ok) {
        const data = extractData(await response.json());

        let newRecipient = {
          ...data,
          emailAddress: data.email,
          contactNumber: data.phone
        }
        delete newRecipient['phone'];
        delete newRecipient['email'];

        this.setState(prevState => ({
          selectedRecipientID: data.id,
          saveRecipient: false,
          showAddRecipientButton: false,
          showRecipientDetailsForm: false,
          isReadyForNextStep: true,
          showSuccessMessage: true,
          showErrorMessage: false,
          errorMessage: "",
          successMessage: ADDED_RECIPIENT_SUCCESS_MESSAGE,
          isRecipientSelected: true,
          loading: false,
          recipients: [newRecipient, ...prevState.recipients]
        }));
        return data;
      } else {
        this.setState({
          showErrorMessage: true,
          errorMessage: DUPLICATED_RECIPIENT_MESSAGE,
          errorTitle: "Recipient already exists",
          showSuccessMessage: false,
          successMessage: ''
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        loading: false,
        showErrorMessage: true,
        errorMessage: GENERIC_RECIPIENTS_ERROR_MESSAGE,
        errorTitle: UNSUCCESSFUL,
        showSuccessMessage: false,
        successMessage: ''
      });
    }
  }

  handleNext = async (e) => {
    e.preventDefault();
    const { activeStep, orderInformation, contactDetails, destinationAddress, options, selectedItems, boxSplit, shippingService, saveRecipient, isReadyForNextStep } = this.state;
    let validate = null;
    let bypassValidation = false;
    const updateSelectedItems = { ...selectedItems, items: selectedItems.items.filter(i => i.quantity > 0) };
    this.setState({ loading: true });

    // Validate token expiry here
    this.validateToken();

    if (activeStep === 0) {
      validate = await this.validateRecipientDetails(orderInformation, contactDetails, destinationAddress);
      if (validate === null) return;

      if (validate.isValid) {
        const verifiedEORI = await this.checkForEORI(destinationAddress.country.trim());
        const isValid = verifiedEORI ? true : false;
        validate.isValid = isValid;
        if (!isValid) this.setState({ showEoriError: true });

        if (isValid && saveRecipient && !isReadyForNextStep) {
          this.createRecipient();
        }
      }
    }
    else if (activeStep === 1) {
      validate = await this.validateOptions(options);
      this.setState({ optionsValidation: validate });
      await this.fetchStockItems();
    }
    else if (activeStep === 2) {
      validate = await this.validateItems(updateSelectedItems.items);

      const boxes = this.state.boxSplit.all.boxes;
      if (boxes !== undefined && boxes.length > 0) {
        boxes.forEach((b) => {
          b.items.forEach((i) => {
            const exists = updateSelectedItems.items.findIndex(si => si.identifier === i.identifier && si.quantity < 1) === -1 ? false : true;
            if (exists) b.items.splice([b.items.findIndex(item => item.identifier === i.identifier)]);
          });
          if (b.items.length < 1) boxes.splice(b);
        });
      }

      this.setState(({
        selectedItems: updateSelectedItems,
        boxes: boxes,
        itemsSelectionValidation: validate
      }));

      if (validate !== null && validate.isValid) {
        await this.validateSelectedStock(updateSelectedItems, validate);
        validate = this.state.itemsSelectionValidation;
      }
    }
    else if (activeStep === 3) {
      validate = await this.validateBoxSplit(boxSplit);

      boxSplit.all.boxes.forEach((b => { if (b.items.length < 1) boxSplit.all.boxes.splice(b); }));

      this.setState(prevState => ({
        ...prevState,
        boxSplit: boxSplit,
        boxSplitValidation: validate
      }));

      if (validate !== null && validate.isValid) {
        if (options.ownBoxSplit === "true") {
          this.setState({ showManualLoading: true })
          await this.validateManualSplit(boxSplit.all, validate);
          validate = this.state.boxSplitValidation;
          this.setState({ showManualLoading: false })
        }
      }
    }
    else if (activeStep === 4) {
      if (options.shippingRequired === "false") {
        bypassValidation = true;
      } else {
        validate = await this.validateShippingService(shippingService);
        this.setState({ shippingServiceValidation: validate });
      }
    } else if (activeStep === 5) {
      await this.handleSubmit();
      bypassValidation = this.state.success;
    }

    if ((validate !== null && validate.isValid) || bypassValidation) {

      if (activeStep === 0) {
        if (isReadyForNextStep || !saveRecipient) {
          this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }));
        }
        this.setState({ loading: false });
        return;
      }

      if (activeStep === 3 && options.ownBoxSplit === "true" && !boxSplit.showManualSplitSummary) {
        this.setState(prevState => ({
          ...prevState,
          boxSplit: {
            ...prevState.boxSplit,
            showTotalBoxQuestion: false,
            showManualSplitSummary: true
          },
        }));
      } else { this.setState(prevState => ({ activeStep: prevState.activeStep + 1 })); }

      if (activeStep === 2) {
        if (options.shippingRequired === "false" && options.packagingRequired === "false") {
          validate = await this.validateBoxSplit(boxSplit);
          await this.validateNoPackaging(updateSelectedItems, validate);
        } else if (options.separateSKUs === "true") {
          validate = await this.validateBoxSplit(boxSplit);
          await this.validateSkuSplit(updateSelectedItems, validate, false);
        } else if (options.ownBoxSplit === "false" && options.separateSKUs === "false") {
          validate = await this.validateBoxSplit(boxSplit);
          await this.validateSkuSplit(updateSelectedItems, validate, true);
        }
      }
    }
    this.setState({ loading: false });
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
      errors: [],
      itemSelectionErrors: [],
      showConfirmError: false,
      showSuccessMessage: false,
      successMessage: "",
    }));
  };

  handleBackBoxSplit = () => {
    const { boxSplit } = this.state;
    if (boxSplit.showTotalBoxQuestion) {
      this.setState(state => ({
        activeStep: state.activeStep - 1,
        errors: [],
        itemSelectionErrors: []
      }));
    }
    else {
      this.setState(prevState => ({
        ...prevState,
        boxSplit: {
          ...prevState.boxSplit,
          ...{ showTotalBoxQuestion: true, showManualSplitSummary: false }
        },
        errors: [],
        itemSelectionErrors: []
      }));
    }
  };

  handleContactDetailsInputChange = (e) => {
    let { name, value } = e.target;

    this.setState(prevState => ({
      ...prevState,
      contactDetails: {
        ...prevState.contactDetails,
        ...{ [name]: value }
      },
    }));
  }

  handleOrderInformationInputChange = (e) => {
    let { name, value, type } = e.target;

    if (name === "purchaseOrderNumber" && value !== "") {
      value = value.replace(/[`~!¬@#£$%^&*|=?;:'",.<>\{\} ]/gi, '');
    }

    this.setState(prevState => ({
      ...prevState,
      orderInformation: {
        ...{ [name]: value }
      },
      showSuccessMessage: false,
      successMessage: ""
    }));
  }

  handleDestinationAddressInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      ...prevState,
      destinationAddress: {
        ...prevState.destinationAddress,
        ...{ [name]: value }
      },
    }));
  }

  handleOptionInputChange = (e) => {
    const { name, value } = e.target;

    // if packaging required has changed selected items need cleared as not to include non-packed in pre-packed only items
    if (name === "packagingRequired") {
      this.setState({ selectedItems: { "items": [] }, totalAddedCount: 0 });
    }

    if (name === "packagingRequired" && value === "false") {
      this.setState(prevState => ({
        ...prevState,
        options: {
          ...prevState.options,
          ...{
            ownBoxSplit: 'false',
            separateSKUs: 'false',
          }
        },
      }));
    }

    if (name === "shippingRequired" && value === "true") {
      this.setState(prevState => ({
        ...prevState,
        options: {
          ...prevState.options,
          ...{ packagingRequired: "true" }
        },
      }));
    }

    if (name === "ownBoxSplit" || name === "separateSKUs") {
      this.setState(prevState => ({
        ...prevState,
        boxSplit: {
          ...prevState.boxSplit,
          all: { "boxes": [] }
        }
      }));
    }

    this.setState(prevState => ({
      ...prevState,
      options: {
        ...prevState.options,
        ...{ [name]: value }
      },
    }));
  };

  handleBoxSplitInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      ...prevState,
      boxSplit: {
        ...prevState.boxSplit,
        ...{ [name]: value }
      },
    }));
  }

  handleBoxTotalChange = (e) => {
    const { name, value, defaultValue } = e.target;
    this.setState(prevState => ({
      ...prevState,
      boxSplit: {
        ...prevState.boxSplit,
        showServerValidation: false,
        ...{ [name]: value },
        ...{ previousBoxTotal: defaultValue }
      },
    }));
  }

  toggleSummary = async (show) => this.setState({ displaySummary: show });

  updateCounter = () => {
    const { selectedItems } = this.state;
    let { totalAddedCount } = this.state;
    totalAddedCount = 0;
    Object.entries(selectedItems.items).forEach(([key, value]) => { if (parseInt(`${value.quantity}`) !== 0) totalAddedCount = totalAddedCount + parseInt(`${value.quantity}`) });
    this.setState({ totalAddedCount: totalAddedCount });
  }

  calculateAssignments = async () => {
    const { boxSplit, selectedItems } = this.state;

    let originalNumberOfBoxesRequested = boxSplit.previousBoxTotal;
    if (parseInt(boxSplit.numberOfBoxes) < parseInt(originalNumberOfBoxesRequested)) {
      const difference = originalNumberOfBoxesRequested - boxSplit.numberOfBoxes;

      if (difference > 0) {
        const all = boxSplit.all;
        for (let i = 0; i < all.boxes.length; i++) {
          if (i > boxSplit.numberOfBoxes - 1) {
            boxSplit.all.boxes[i].items = []
            boxSplit.all.boxes[i].companyPackagingId = null
          }
        }

        this.setState(prevState => ({
          ...prevState,
          boxSplit: {
            ...prevState.boxSplit,
            ...{ all: all }
          }
        }));
      }
    }

    for (let i = 0; i < boxSplit.numberOfBoxes; i++) {
      Object.entries(selectedItems.items).map(([key, value]) => {
        const identifier = (value.sku !== undefined && value.sku !== '' && value.sku !== null) ? value.companyItemID + "_" + value.sku : value.companyItemID;
        let selectedQuantity = 0;

        if (value.quantity !== 0) {
          const box = boxSplit.all.boxes[i];
          if (box !== undefined) {
            const item = box.items.filter(item => item.identifier === identifier)[0];
            if (item !== undefined) selectedQuantity = item.quantity;
          }
          this.recalculateAssignments(selectedQuantity, i, value.identifier, value.companyItemID, value.name, value.sku)
        }
        else {
          const box = boxSplit.all.boxes[i];
          if (box !== undefined) {
            const item = box.items.filter(item => item.identifier === identifier)[0];
            if (item !== undefined) selectedQuantity = 0;
          }
          this.recalculateAssignments(selectedQuantity, i, value.identifier, value.companyItemID, value.name, value.sku)
        }
      })
    }
  }

  recalculateAssignments = async (e, boxNumber, identifier, companyItemID, name, sku) => {
    const { boxSplit, selectedItems } = this.state;
    let requestedQuantity = e;

    if (requestedQuantity === "" || requestedQuantity === undefined || requestedQuantity < 0) requestedQuantity = 0;

    var numericValue = parseInt(requestedQuantity);
    if (numericValue === undefined) requestedQuantity = 0;

    let all = boxSplit.all;

    if (all.boxes[boxNumber] !== undefined) {
      const box = all.boxes[boxNumber];
      let existingBox = box.items.filter(item => item.identifier === identifier)[0];

      if (existingBox !== undefined) existingBox.quantity = requestedQuantity;
      else {
        const item = { identifier: identifier, quantity: requestedQuantity, companyItemID: companyItemID, name: name, sku: sku };
        all.boxes[boxNumber].items = all.boxes[boxNumber].items.concat(item);
      }
    }
    else {
      const item = { identifier: identifier, quantity: requestedQuantity, companyItemID: companyItemID, name: name, sku: sku };

      var boxItems =
      {
        "items": [],
        "companyPackagingID": null
      }

      boxItems.items = boxItems.items.concat(item);
      all.boxes[boxNumber] = boxItems;
    }

    let selectedItemsCopy = selectedItems;
    const exists = selectedItemsCopy.items.findIndex(i => i.identifier === identifier) === -1 ? false : true;

    if (exists) {
      var selectedItem = selectedItemsCopy.items[selectedItemsCopy.items.findIndex(i => i.identifier === identifier)];
      let totalAssignedCounter = 0;
      all.boxes.map(function (box) {
        let selectedItem = box.items.filter(item => item.identifier === identifier)[0];
        if (selectedItem !== undefined && selectedItem.quantity !== '') {
          totalAssignedCounter += parseInt(selectedItem.quantity);
        }
      })

      selectedItem.assigned = totalAssignedCounter;
      selectedItem.unassigned = parseInt(selectedItem.quantity) - totalAssignedCounter;
    }

    this.setState(prevState => ({
      ...prevState,
      boxSplit: {
        ...prevState.boxSplit,
        ...{ all: all }
      },
      selectedItems: selectedItemsCopy
    }));
  };

  toggleShippingService = () => {
    this.setState(prevState => ({
      ...prevState,
      shippingService: {
        ...prevState.shippingService,
        ...{ selected: !prevState.shippingService.selected }
      },
    }));
  }

  validateManualSplit = async (boxSplitAll, validate) => {
    return POST(Endpoints.RETAILER.POST.BULK_ORDER_MANUAL_SPLIT_VALIDATE, boxSplitAll)
      .then(response => {
        if (response.ok) return response.json();
      })
      .then(result => {
        const data = extractData(result);
        if (data) {
          let errors = [];

          let invalidBoxes = false;
          const emptyPackaging = data.boxes.filter(items => items.companyPackagingID === null && !items.isStockBox);

          if (emptyPackaging.length > 0) emptyPackaging.forEach(item => { item.items.forEach(i => { if (i.quantity > 0) invalidBoxes = true; }) })

          if (invalidBoxes === true) {
            validate.isValid = false;
            errors = ["The following boxes have too many products. Please return to the previous stage to increase box numbers and try again."]
          }
          this.setState(prevState => ({
            ...prevState,
            errors: errors,
            boxSplitValidation: validate,
            boxSplit: {
              ...prevState.boxSplit,
              showServerValidation: true,
              ...{ all: data }
            },
          }));
        } else {
          validate.isValid = false;
          this.setState(prevState => ({
            ...prevState,
            errors: ["There was a problem validating the box split. Please try again."],
            boxSplitValidation: validate,
            boxSplit: {
              ...prevState.boxSplit,
              showServerValidation: false,
            },
          }));
        }
      })
      .catch(error => {
        console.log(error)
        validate.isValid = false;
        this.setState(prevState => ({
          ...prevState,
          errors: ["Unable to process box split, too many items selected for number of boxes"],
          boxSplitValidation: validate,
          boxSplit: {
            ...prevState.boxSplit,
            showServerValidation: false,
          }
        }));
      })
  }

  validateSkuSplit = async (selectedItems, validate, isAutoSplit) => {
    const { limitsEnabled, boxLimit } = this.state;
    return POST(Endpoints.RETAILER.POST.BULK_ORDER_SKU_SPLIT_VALIDATE + isAutoSplit, selectedItems)
      .then(response => {
        if (response.ok) return response.json();
      })
      .then(result => {
        if (!result.error) {
          const data = extractData(result);
          if (data && data.boxes.length > 0) {
            this.setState(prevState => ({
              ...prevState,
              errors: (limitsEnabled && data.boxes.length > boxLimit) ? [getBoxLimitMessage(boxLimit)] : [],
              boxSplitValidation: validate,
              boxSplit: {
                ...prevState.boxSplit,
                ...{ all: data }
              },
              loading: false
            }));
          } else if (data && data.boxes.length === 0) {
            validate.isValid = false;
            this.setState(prevState => ({
              ...prevState,
              boxSplit: {
                ...prevState.BoxSplit,
                showTotalBoxQuestion: true,
                all: { "boxes": [] }
              },
              errors: ["No matching boxes found for items with given dimensions"],
              boxSplitValidation: validate,
              loading: false
            }));
          } else {
            validate.isValid = false;
            this.setState(prevState => ({
              ...prevState,
              boxSplit: {
                ...prevState.boxSplit,
                all: { "boxes": [] }
              },
              errors: ["There was a problem validating the split. Please try again."],
              boxSplitValidation: validate,
              loading: false
            }));
          }
        } else {
          validate.isValid = false;
          this.setState(prevState => ({
            ...prevState,
            errors: [result.message],
            boxSplitValidation: validate,
            loading: false
          }));
        }
      })
      .catch(error => {
        console.log(error)
        validate.isValid = false;
        this.setState(prevState => ({
          ...prevState,
          errors: ["An error has occurred, unable to process box split"],
          boxSplitValidation: validate,
          boxSplit: {
            ...prevState.boxSplit,
            showServerValidation: false,
          }
        }))
      })
  }

  validateNoPackaging = async (selectedItems, validate) => {
    validate.isValid = true;
    return POST(Endpoints.RETAILER.POST.BULK_ORDER_NO_PACKAGING_VALIDATE, selectedItems)
      .then(response => {
        if (response.ok) return response.json();
      })
      .then(result => {
        if (!result.error) {
          const data = extractData(result);
          if (data) {
            this.setState(prevState => ({
              ...prevState,
              errors: [],
              boxSplitValidation: validate,
              boxSplit: {
                ...prevState.boxSplit,
                ...{ all: data }
              }
            }));
          } else {
            validate.isValid = false;
            this.setState(prevState => ({
              ...prevState,
              boxSplit: {
                ...prevState.boxSplit,
                all: { "boxes": [] }
              },
              errors: ["There was a problem validating the split. Please try again."],
              boxSplitValidation: validate
            }));
          }
        } else {
          validate.isValid = false;
          this.setState(prevState => ({
            ...prevState,
            errors: [result.message],
            boxSplitValidation: validate
          }));
        }
      })
      .catch(error => console.log(error));
  }

  fetchShippingOption = async () => {
    this.setState({ loading: true });
    this.setState({ showDifferentServiceMessage: false });

    const { contactNumber, emailAddress, name } = this.state.contactDetails;

    var submissionDetails = {
      boxes: this.state.boxSplit.all.boxes,
      serviceTimePreference: this.state.options.shippingServiceTime,
      destinationAddress: this.state.destinationAddress,
      contactDetails: { contactNumber, emailAddress, name },
      orderInformation: this.state.orderInformation
    };

    let validate = this.shippingServiceValidator.validate(this.state.shippingService);

    return POST(Endpoints.INTERNAL.POST.BULK_ORDER_FIND_SHIPPING_OPTIONS, submissionDetails)
      .then(response => {
        if (response !== undefined && response.ok) return response.json();
      })
      .then(result => {
        const data = extractData(result);
        if (data) {
          if (data.noShippingResults) {
            validate.isValid = false;
            this.setState(prevState => ({
              ...prevState,
              noCourierError: true,
              shippingServiceValidation: validate,
              shippingService: { id: '', name: '', service: '', cost: '', selected: false },
              loading: false
            }));
          }
          else if (data.errorThrown) {
            validate.isValid = false;
            this.setState(prevState => ({
              ...prevState,
              errors: ["There was a problem retrieving couriers. Please try again."],
              shippingServiceValidation: validate,
              shippingService: { id: '', name: '', service: '', cost: '', selected: false },
              loading: false,
              noCourierError: false,
              courierCalculation: {},
              shippingResult: {}
            }));
          }
          else {
            this.setState(prevState => ({
              ...prevState,
              errors: [],
              shippingServiceValidation: validate,
              courierCalculation: data,
              loading: false,
              noCourierError: false,
              shippingService: { id: data.cheapestCourierDetails.courierID, name: data.cheapestCourierDetails.courierName, service: data.cheapestCourierDetails.serviceTimeDetails.textValue + " delivery", cost: data.cheapestCourierDetails.cost.toFixed(2), selected: false },
              shippingResult: {}
            }));

            if ((this.state.options.shippingServiceTime !== data.cheapestCourierDetails.serviceTimeDetails.enumValue)
              && (this.state.options.shippingServiceTime !== "noPreference")) this.setState({ showDifferentServiceMessage: true, originalServiceTimePreference: data.originalServiceTimePreference });
          }

        } else {
          validate.isValid = false;
          this.setState(prevState => ({
            ...prevState,
            errors: ["There was a problem retrieving couriers. Please try again."],
            shippingServiceValidation: validate,
            noCourierError: false,
            shippingService: { id: '', name: '', service: '', cost: '', selected: false },
            loading: false,
            courierCalculation: {},
            shippingResult: {}
          }));
        }
      })
      .catch(error => console.log(error));
  }

  handleBackShippingResults = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
      errors: [],
      showDifferentServiceMessage: false,
      noCourierError: false,
      shippingService: {
        id: "",
        name: "",
        service: "",
        cost: "",
        selected: false
      },
    }));
  };

  onReset = async (e) => {
    e.preventDefault();

    this.setState(prevState => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        ...{ searchText: "", sortBy: "" },
      }
    }), await this.fetchStockItems);
  }

  onSearch = async (e) => {
    e.preventDefault();
    this.setState({ loading: true }, await this.fetchStockItems);
  }

  onNext = async () => {
    const { pageIndex, pageCount } = this.state;
    if (pageIndex < pageCount) {
      this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, await this.fetchStockItems);
    }
  }

  onPrevious = async () => {
    const { pageIndex } = this.state;
    if (pageIndex > 1) {
      this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, await this.fetchStockItems);
    }
  }

  onStart = async () => {
    const { pageIndex } = this.state;
    if (pageIndex > 1) {
      this.setState({ pageIndex: 1, loading: true }, await this.fetchStockItems);
    }
  }

  onEnd = async () => {
    const { pageIndex, pageCount } = this.state;
    if (pageIndex < pageCount) {
      this.setState({ pageIndex: pageCount, loading: true }, await this.fetchStockItems);
    }
  }

  onFilterChange = async (e) => {
    const { name, value } = e.target;

    this.setState(prevState => ({
      ...prevState,
      pageIndex: 1,
      filter: {
        ...prevState.filter,
        ...{ [name]: value }
      }
    }));
  }

  fetchStockItems = async () => {

    this.setState({ loading: true });
    const { pageIndex } = this.state;
    const filter = { ...this.state.filter };
    let url = new URL(Endpoints.STOCK.GET.BY);

    if (filter.sortBy === "") filter.sortBy = null;

    Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
    url.searchParams.append("pageIndex", pageIndex)

    return await GET(url)
      .then(response => {
        if (response.ok) return response.json();
      })
      .then(result => {
        const data = extractData(result) || [];
        const warning = data.items === 0 ? "No products to show." : null;
        this.setState({ items: data.items ? data.items : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false, warning: warning });
      })
      .catch(error => console.log(error));
  }

  fetchBulkOrderLimits = async () => {
    return await GET(new URL(Endpoints.INTERNAL.GET.BULK_ORDER_LIMITS))
      .then(response => { if (response.ok) return response.json(); })
      .then(result => {
        const data = extractData(result);
        if (data !== null && !data.error) this.setState({ limitsEnabled: data.enabled, itemLimit: data.itemLimit, boxLimit: data.boxLimit });
      })
      .catch(error => console.log(error));
  }

  validateSelectedStock = async (selectedItems, validate) => {
    return POST(Endpoints.RETAILER.POST.BULK_ORDER_SELECTED_ITEMS_VALIDATE, selectedItems)
      .then(response => {
        if (response.ok) return response.json();
      })
      .then(result => {
        const data = extractData(result);
        if (data) {
          let errors = [];
          if (data.invalidItems.length > 0) {
            validate.isValid = false;
            Object.entries(data.invalidItems).forEach(([key, value]) => {
              if (value.quantity > 0) {
                const totalQuantity = value.availableShelfCount + value.availablePalletCount;
                const name = value.sku ? value.name + `(s) [${value.sku}]` : value.name + "(s)";
                const reserved = value.pendingPalletCount > 0 ? `${value.pendingPalletCount} are currently unavailable due to in progress replenishment.` : '';
                const errorItem = { name: name, message: `${totalQuantity} items are available, ${value.quantity} selected for bulk order. ${reserved}` };
                errors.push(errorItem);
              }
            });
          }
          else validate.isValid = true;

          this.setState(prevState => ({
            ...prevState,
            itemSelectionErrors: errors,
            itemsSelectionValidation: validate
          }));
        }
        else {
          validate.isValid = false;
          this.setState(prevState => ({
            ...prevState,
            errors: ["There was a problem validating the items. Please try again."],
            itemsSelectionValidation: validate
          }));
        }
      })
      .catch(error => console.log(error));
  }

  handleSubmit = async () => {

    const { orderInformation, contactDetails, destinationAddress, options, selectedItems, boxSplit, shippingService, shippingResult } = this.state;

    const bulkOrderModel = {
      purchaseOrderNumber: orderInformation.purchaseOrderNumber,
      contactDetails: contactDetails,
      destinationAddress: destinationAddress,
      preferences: {
        isBoxSplit: options.ownBoxSplit === "true" ? true : false,
        requireSeperateSKUs: options.separateSKUs === "true" ? true : false,
        requireShipping: options.shippingRequired === "true" ? true : false,
        requirePackaging: options.packagingRequired === "true" ? true : false,
        isFragile: options.fragileItems === "true" ? true : false,
        packagingPreference: PAPER_PACKAGING_PREFERENCE
      },
      stockSelection: selectedItems.items,
      shippingResult: shippingResult,
      boxSplits: [{ boxes: boxSplit.all.boxes }]
    };

    return POST(Endpoints.RETAILER.POST.BULK_ORDER_SUBMIT, bulkOrderModel)
      .then(response => {
        if (response.ok) return response.json();
      })
      .then(result => {
        const data = extractData(result);
        if (data) {
          let errors = [];
          if (data.invalidItems !== undefined && data.invalidItems.length > 0) {
            Object.entries(data.invalidItems).forEach(([key, value]) => {
              if (value.quantity > 0) {
                const totalQuantity = value.availableShelfCount + value.availablePalletCount;
                const name = value.sku ? value.name + `(s) [${value.sku}]` : value.name + "(s)";
                const errorItem = { name: name, message: `${totalQuantity} items in stock, ${value.quantity} selected for bulk order` };
                errors.push(errorItem);
              }
            });
            this.setState({ showConfirmError: false, success: false, itemSelectionErrors: errors });
          }
          else this.setState({ showConfirmError: false, success: true, itemSelectionErrors: [] });
        } else this.setState({ showConfirmError: true, success: false, itemSelectionErrors: [] });
      })
      .catch(error => console.log(error));
  }

  handleRecipientChange = (recipient) => {
    this.setState({
      isRecipientSelected: true,
      contactDetails: {
        name: recipient.name,
        emailAddress: recipient.emailAddress,
        contactNumber: recipient.contactNumber,
        companyName: recipient.companyName
      },
      destinationAddress: {
        addressLine1: recipient.addressLine1,
        addressLine2: recipient.addressLine2,
        town: recipient.town,
        county: recipient.county,
        country: recipient.country,
        postCode: recipient.postCode
      },
      isReadyForNextStep: true,
      showAddRecipientButton: false,
      showRecipientDetailsForm: false,
      selectedRecipientID: recipient.id,
      showSuccessMessage: false,
      successMessage: ""
    });
  }

  handleCloseRecipient = () => {
    this.setState({
      isRecipientSelected: false,
      contactDetails: {
        name: "",
        emailAddress: "",
        contactNumber: "",
        companyName: ""
      },
      destinationAddress: {
        addressLine1: "",
        addressLine2: "",
        town: "",
        county: "",
        country: "GB",
        postCode: "",
      },
      isReadyForNextStep: false,
      showAddRecipientButton: true,
      showSuccessMessage: false,
      successMessage: ""
    });
  }

  handleShowRecipientsForm = () => {
    this.setState({
      saveRecipient: true,
      showAddRecipientButton: false,
      showRecipientDetailsForm: true,
      showSuccessMessage: false,
      successMessage: ""
    });
  }

  handleShowRecipientsModal = (show = false) => {
    this.setState({ showRecipientsModal: show });
  }

  handleToggleDeleteConfirmationModal = (show = false) => {
    this.setState({ showDeleteConfirmationModal: show });
  }

  handleRecipientUpdate = async (requestBody) => {
    try {

      const { recipients } = this.state;

      this.setState({ loading: true });
      const { companyID } = JSON.parse(localStorage.getItem('user'));
      const url = Endpoints.COMPANY_CUSTOMER_ADDRESS.PUT.UPDATE_CUSTOMER_ADDRESS_FOR_COMPANY + requestBody.recipientID;

      const body = {
        ...requestBody.contactDetails,
        ...requestBody.destinationAddress,
        id: requestBody.recipientID,
        companyID
      };

      const response = await PUT(url, body);
      if (response.ok) {

        const data = extractData(await response.json());

        const newRecipientsState = recipients.map(recipient => {
          if (recipient.id === data.id) {
            let newRecipientState = {
              ...data,
              emailAddress: data.email,
              contactNumber: data.phone
            }
            delete newRecipientState["email"];
            delete newRecipientState["phone"];

            return newRecipientState;
          }
          return recipient;
        });

        this.setState({
          showAddRecipientButton: false,
          showRecipientDetailsForm: false,
          isReadyForNextStep: false,
          showSuccessMessage: true,
          successMessage: UPDATED_RECIPIENT_SUCCESS_MESSAGE,
          showErrorMessage: false,
          errorMessage: "",
          isRecipientSelected: true,
          loading: false,
          recipients: [...newRecipientsState],
          showRecipientsModal: false,
          contactDetails: {
            name: data.name,
            emailAddress: data.email,
            contactNumber: data.phone,
            companyName: data.companyName
          },
          destinationAddress: {
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            country: data.country,
            county: data.county,
            town: data.town,
            postCode: data.postCode
          },

        });
      } else {
        this.setState({
          showErrorMessage: true,
          errorMessage: DUPLICATED_RECIPIENT_MESSAGE,
          errorTitle: "Recipient already exists",
          loading: false,
          showRecipientsModal: false,
          showSuccessMessage: false,
          successMessage: ''
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        loading: false,
        showErrorMessage: true,
        errorMessage: GENERIC_RECIPIENTS_ERROR_MESSAGE,
        errorTitle: UNSUCCESSFUL,
        showSuccessMessage: false,
        successMessage: ''
      });
    } finally {
      this.handleToggleDeleteConfirmationModal(false);
    }
  }

  handleRecipientDelete = async (recipientID) => {

    try {
      const { recipients } = this.state;

      this.setState({ loading: true });
      const url = Endpoints.COMPANY_CUSTOMER_ADDRESS.DELETE.DELETE_CUSTOMER_ADDRESS_FOR_COMPANY + recipientID;

      const response = await DELETE(url);
      if (response.ok) {

        const newRecipietsState = recipients.filter(recipient => recipient.id !== recipientID);

        this.setState({
          showAddRecipientButton: true,
          showRecipientDetailsForm: false,
          isReadyForNextStep: false,
          showSuccessMessage: true,
          successMessage: DELETED_RECIPIENT_SUCCESS_MESSAGE,
          showErrorMessage: false,
          errorMessage: "",
          isRecipientSelected: false,
          loading: false,
          recipients: [...newRecipietsState],
          contactDetails: {
            name: "",
            emailAddress: "",
            contactNumber: "",
            companyName: ""
          },
          destinationAddress: {
            addressLine1: "",
            addressLine2: "",
            town: "",
            county: "",
            country: "GB",
            postCode: "",
          },
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        loading: false,
        showErrorMessage: true,
        errorMessage: GENERIC_RECIPIENTS_ERROR_MESSAGE,
        errorTitle: UNSUCCESSFUL,
        showSuccessMessage: false,
        successMessage: ''
      });
    } finally {
      this.handleToggleDeleteConfirmationModal(false);
    }
  }

  cancelRecipientDelete = () => {
    this.handleToggleDeleteConfirmationModal(false);
    this.handleShowRecipientsModal(true);
  }

  render() {
    const {
      loading, errors, itemSelectionErrors, showConfirmError, activeStep, orderInformationValidation, contactDetailsValidation,
      destinationAddressValidation, optionsValidation, orderInformation, contactDetails, destinationAddress, options, selectedItems,
      displaySummary, boxSplit, boxSplitValidation, totalAddedCount, shippingService, shippingServiceValidation, items,
      pageCount, pageIndex, filter, showDifferentServiceMessage, originalServiceTimePreference, noCourierError, showEoriError,
      limitsEnabled, itemLimit, boxLimit, hasValidWarehouse, hasValidCardDetails, showManualLoading, isRecipientSelected, saveRecipient,
      selectedRecipient, showAddRecipientButton, showRecipientDetailsForm, showSuccessMessage, selectedRecipientID, successMessage, showRecipientsModal,
      showDeleteConfirmationModal, recipients, showErrorMessage, errorMessage: message, errorTitle
    } = this.state;

    const errorMessage = errors.length > 0 ? errors.map((e, i) =>
      e && e.length ? <Fragment key={i}><p className="mb-0">{e}</p><br /></Fragment> : null) : null;

    return (
      showManualLoading
        ? <BulkOrderManualLoading />
        : loading
          ? <LoadingBar />
          : <Fragment>
            <Header title="Bulk Order" subNavList={BULK_ORDER_SUB_NAV_LIST} activeKey="Create Bulk Order" />
            <Container fluid>
              {hasValidWarehouse && hasValidCardDetails
                ? <Fragment>
                  <Stepper steps={steps} activeStep={activeStep} />
                  <div className="pt-4">
                    {errors.length > 0 && <Row><Col sm={12} md={6}><ErrorAlert errorMessage={errorMessage}></ErrorAlert></Col></Row>}
                    {itemSelectionErrors.length > 0 ? <Row><Col sm={12} md={6}>{itemSelectionError(itemSelectionErrors)}</Col></Row> : ''}
                    {showEoriError && <Row><Col sm={12} md={6}><ErrorAlert errorMessage={eoriErrorMessage}></ErrorAlert></Col></Row>}
                    {activeStep === 0 ?
                      <Row>
                        <Col sm={12} md={6}>
                          <BulkOrderRecipientDetails
                            orderInformation={orderInformation}
                            contactDetails={contactDetails}
                            destinationAddress={destinationAddress}
                            orderInformationValidation={orderInformationValidation}
                            contactDetailsValidation={contactDetailsValidation}
                            destinationAddressValidation={destinationAddressValidation}
                            saveRecipient={saveRecipient}
                            isRecipientSelected={isRecipientSelected}
                            showAddRecipientButton={showAddRecipientButton}
                            showRecipientDetailsForm={showRecipientDetailsForm}
                            showSuccessMessage={showSuccessMessage}
                            successMessage={successMessage}
                            selectedRecipientID={selectedRecipientID}
                            recipients={recipients}
                            errorTitle={errorTitle}
                            showErrorMessage={showErrorMessage}
                            errorMessage={message}
                            handleOrderInformationInputChange={this.handleOrderInformationInputChange}
                            handleContactDetailsInputChange={this.handleContactDetailsInputChange}
                            handleDestinationAddressInputChange={this.handleDestinationAddressInputChange}
                            handleToggleSaveRecipientPreference={this.handleToggleSaveRecipientPreference}
                            handleRecipientChange={this.handleRecipientChange}
                            handleCloseRecipient={this.handleCloseRecipient}
                            handleShowRecipientsForm={this.handleShowRecipientsForm}
                            handleRecipientDelete={this.handleRecipientDelete}
                            handleShowRecipientsModal={this.handleShowRecipientsModal}
                            handleRecipientUpdate={this.handleRecipientUpdate}

                          />
                          <StepperButtons cancelLink={cancelLink} handleNext={this.handleNext} showNext={true} isRecipientSelected={isRecipientSelected} selectedRecipient={selectedRecipient} />
                        </Col>
                      </Row> : activeStep === 1 ?
                        <Row>
                          <Col sm={12} md={6}>
                            <BulkPickOptions options={options} validation={optionsValidation}
                              handleInputChange={this.handleOptionInputChange} handleToggleShowHide={this.handleToggleShowHide} />
                            <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} />
                          </Col>
                        </Row> : activeStep === 2 ?
                          <Fragment>
                            <BulkOrderItemSelection handleSelectedItemsRequest={this.handleSelectedItemsRequest} updateCounter={this.updateCounter}
                              totalAddedCount={totalAddedCount} options={options} handleInputChange={this.handleInputChange}
                              items={items} filter={filter} limitsEnabled={limitsEnabled} itemLimit={itemLimit}
                              selectedItems={selectedItems} toggleSummary={this.toggleSummary} displaySummary={displaySummary}
                              onFilterChange={this.onFilterChange}
                              onStart={this.onStart} onEnd={this.onEnd} onPrevious={this.onPrevious} onNext={this.onNext} onSearch={this.onSearch}
                              onReset={this.onReset} pageCount={pageCount} pageIndex={pageIndex} validateItems={this.validateItems} />
                            {displaySummary ? null : <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} />}
                          </Fragment> : activeStep === 3 ?
                            <Row>
                              <Col sm={12} md={6}>
                                <BoxSplit calculateAssignments={this.calculateAssignments} recalculateAssignments={this.recalculateAssignments}
                                  selectedItems={selectedItems} handleBoxQuantityRequest={this.handleBoxQuantityRequest} handleBack={this.handleBackBoxSplit}
                                  totalAddedCount={totalAddedCount} options={options} boxSplit={boxSplit} validation={boxSplitValidation}
                                  handleBoxTotalChange={this.handleBoxTotalChange} clearBoxSplit={this.clearBoxSplit} limitsEnabled={limitsEnabled} boxLimit={boxLimit} />
                                {boxSplit.showTotalBoxQuestion && options.ownBoxSplit === 'true'
                                  ? null
                                  : <StepperButtons cancelLink={cancelLink} handleBack={this.handleBackBoxSplit} showBack={true} handleNext={this.handleNext}
                                    showNext={true} nextButtonText={options.ownBoxSplit === "true" && !boxSplit.showManualSplitSummary ? "Show Split Summary" : "Next Step"} />}
                              </Col>
                              {!boxSplit.showTotalBoxQuestion && !boxSplit.showManualSplitSummary
                                && boxSplit.showManualSplitSummary !== undefined && boxSplit.showTotalBoxQuestion !== undefined
                                ? <Col><BulkOrderSummaryBox selectedItems={selectedItems} /></Col>
                                : null
                              }
                            </Row> : activeStep === 4 ?
                              <Row>
                                <Col sm={12} md={6}>
                                  <BulkOrderShippingResults shippingRequired={options.shippingRequired} destinationAddress={destinationAddress}
                                    shippingServiceTime={options.shippingServiceTime} handleInputChange={this.handleOptionInputChange}
                                    fetchShippingOption={this.fetchShippingOption} shippingService={shippingService} toggleShippingService={this.toggleShippingService}
                                    validation={shippingServiceValidation} showDifferentServiceMessage={showDifferentServiceMessage}
                                    originalServiceTimePreference={originalServiceTimePreference} noCourierError={noCourierError} />
                                  <StepperButtons cancelLink={cancelLink} handleBack={this.handleBackShippingResults} showBack={true} handleNext={this.handleNext} showNext={true} />
                                </Col>
                              </Row> : activeStep === 5 ?
                                <Col sm={12} md={6}>
                                  <BulkOrderConfirmation orderInformation={orderInformation} contactDetails={contactDetails} destinationAddress={destinationAddress} options={options}
                                    boxSplit={boxSplit} shippingService={shippingService} showConfirmError={showConfirmError} />
                                  <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext}
                                    showNext={true} nextButtonText="Confirm and Process Bulk Order" />
                                </Col> : activeStep === 6 ?
                                  <Col sm={12} md={6}>
                                    <BulkOrderSuccess />
                                  </Col> : null}
                  </div>
                </Fragment>
                : <Row>
                  <Col sm={12} md={6}>
                    <MissingDetails message={MISSING_DETAILS_MESSAGE} showWarehouse={!hasValidWarehouse} showCard={!hasValidCardDetails} />
                  </Col>
                </Row>
              }
            </Container>

            <RecipientsModal
              show={showRecipientsModal}
              recipientID={selectedRecipientID}
              {...contactDetails}
              {...destinationAddress}
              showDeleteConfirmationModal={showDeleteConfirmationModal}
              modalStatusForm="edit"
              handleShowRecipientsModal={this.handleShowRecipientsModal}
              handleToggleDeleteConfirmationModal={this.handleToggleDeleteConfirmationModal}
              handleContactDetailsInputChange={this.handleContactDetailsInputChange}
              handleDestinationAddressInputChange={this.handleDestinationAddressInputChange}
              handleEdit={this.handleRecipientUpdate}
              cancelRecipientDelete={this.cancelRecipientDelete}
              handleRecipientDelete={this.handleRecipientDelete}
            />

          </Fragment>);
  }
}

export default BulkOrderWizard;