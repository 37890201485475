import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { checkPermission, toLocalTimeString } from '../../../Utilities';
import NoResults from '../../common/Tables/NoResults';

const OrderRow = ({ order, showCancellationConfirmationModal }) => {
    return (
        <tr>
            <td>{order.identifier}</td>
            <td>{order.source}</td>
            <td>{toLocalTimeString(order.createdDate)}</td>
            <td>{!order.cancelled ? <span className="text-status-purple">{order.status}</span> : <span className="text-status-red">{order.status}</span>}</td>
            {!order.cancelled && <td className="text-center py-2">{checkPermission("CancelOrder", <Button variant="danger" size="sm" onClick={showCancellationConfirmationModal}>Cancel</Button>)}</td>}
        </tr>
    );
};

const UnprocessedOrderTable = ({ orders, showCancellationConfirmationModal = null }) =>
    <Table striped bordered size="sm" className={!orders.length ? "table-empty" : ""}>
        <thead>
            <tr>
                <th>Identifier</th>
                <th>Source</th>
                <th>Date Created</th>
                <th>Status</th>
                {showCancellationConfirmationModal && <th></th>}
            </tr>
        </thead>
        <tbody>
            {orders.length
                ? orders.map(order => showCancellationConfirmationModal
                    ? <OrderRow key={order.id} order={order} showCancellationConfirmationModal={() => { showCancellationConfirmationModal(order.id, order.identifier, order.source, true); }} />
                    : <OrderRow key={order.id} order={order} />
                )
                : <NoResults colSpan="6" text="No pending orders available." />     
            }
        </tbody>
    </Table>

export default UnprocessedOrderTable;