import React, { Component, Fragment } from 'react';
import FormValidator from '../../common/FormValidator';
import { Form, FormGroup, FormLabel, FormControl, Container, Button, Row, Col, Card, Image } from 'react-bootstrap';
import { GET, PUT, extractData } from '../../../Consumer';
import ReCAPTCHA from "react-google-recaptcha";
import Endpoints from '../../common/Endpoints';
import Footer from '../../layout/Footer';
import { ErrorAlert } from '../../common/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { STRONG_PASSWORD_PATTERN, NUMBER_PATTERN, SPECIAL_CHAR_PATTERN, LENGTH_PATTERN } from '../../common/constants';
import LoadingBar from '../../common/LoadingBar';

const captchaKey = process.env.REACT_APP_CAPTCHA_TOKEN;

const ResetPasswordForm = ({ handleSubmit, errorMessage, passwordInputType, form, handleInputChange, handleShowHidePassword, passwordInputIcon, passwordChecks, validation, handleRecaptcha, recaptchaError }) =>
    <Form onSubmit={handleSubmit}>
        {errorMessage && <ErrorAlert errorMessage={errorMessage} />}
        <FormGroup>
            <FormLabel htmlFor="passwordField" className="font-weight-bold">Password</FormLabel>
            <FormControl id="passwordField" type={passwordInputType} name="password" value={form.password} maxLength="50" onChange={handleInputChange} />
            <span className="form-password-icon" onClick={handleShowHidePassword}><FontAwesomeIcon icon={passwordInputIcon} /></span>
            <span className="text-danger">{validation.password.message}</span>
        </FormGroup>
        <div className="form-password-checklist">
            <ul>
                <li className={passwordChecks.number ? "pass" : "fail"}>One number</li>
                <li className={passwordChecks.specialChar ? "pass" : "fail"}>One special character</li>
                <li className={passwordChecks.length ? "pass" : "fail"}>Six characters long</li>
            </ul>
        </div>
        <FormGroup>
            <FormLabel htmlFor="confirmPasswordField" className="font-weight-bold">Confirm Password</FormLabel>
            <FormControl id="confirmPasswordField" type="password" name="confirmPassword" value={form.confirmPassword} onChange={handleInputChange} />
            <span className="text-danger">{validation.confirmPassword.message}</span>
        </FormGroup>
        <Container className="d-flex justify-content-center my-4">
            <ReCAPTCHA sitekey={captchaKey} onChange={handleRecaptcha} />
        </Container>
        {recaptchaError && <p className="text-danger">Please complete captcha</p>}

        <FormGroup>
            <Button size="lg" type="submit" value="Submit" block>Confirm Password Reset</Button>
        </FormGroup>
        <p className="text-center"><small>Having issues? Contact us <a href="mailto:support@selazar.com">support@selazar.com</a>.</small></p>
    </Form>

class ResetPassword extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'password',
                method: 'matches',
                args: [STRONG_PASSWORD_PATTERN],
                validWhen: true,
                message: 'Password must contain at least one number, one special character and at least 6 characters'
            },
            {
                field: 'password',
                method: 'isLength',
                args: [{min: 6, max: 50}],
                validWhen: true,
                message: 'Password must be between 6 and 50 characters long'
            },
            {
                field: 'confirmPassword',
                method: 'isEmpty',
                validWhen: false,
                message: 'Confirm Password is required'
            }
        ]);

        this.state = {
            form: {
                operatorUserID: null,
                userID: '',
                password: '',
                confirmPassword: ''
            },
            passwordResetRequestID: this.props.match.params.id || null,
            passwordResetRequest: '',
            passwordInputType: 'password',
            passwordInputIcon: faEye,
            passwordChecks: {
                number: false,
                specialChar: false,
                length: false
            },
            validation: this.validator.valid(),
            errorMessage: null,
            recaptcha: false,
            recaptchaError: false,
            loading: true
        };
    }

    async componentDidMount() {
        await this.getPasswordResetRequest();
        this.setState({ loading: false });
    }

    getPasswordResetRequest() {
        const { passwordResetRequestID } = this.state;
        return GET(Endpoints.PASSWORD_RESET.GET.BY_ID + passwordResetRequestID)
            .then(response => response.json()).then((result) => {
                if (!result.error) {
                    const passwordResetRequest = extractData(result);
                    this.setState(prevState => ({
                        errorMessage: null,
                        passwordResetRequest: passwordResetRequest,
                        form: {
                            ...prevState.form,
                            userID: passwordResetRequest.userID
                        }
                    }));
                }
                else {
                    this.props.history.push('/');
                }
            });
    }

    handleRecaptcha = () => this.setState({ recaptcha: true });

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" 
            ? this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) 
            : this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "password") {
            const numberPass = NUMBER_PATTERN.test(value);
            const specialCharPass = SPECIAL_CHAR_PATTERN.test(value);
            const lengthPass = LENGTH_PATTERN.test(value);

            this.setState({
                passwordChecks: {
                    number: numberPass,
                    specialChar: specialCharPass,
                    length: lengthPass
                }
            });
        }

        this.setState(prevState => ({
            form: {
                ...prevState.form,
                ...{ [name]: value }
            }
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const { form, recaptcha } = this.state;
        const validation = this.validator.validate(form);
        this.setState({ validation: validation, recaptchaError: !recaptcha });

        if (validation.isValid && recaptcha) {
            return PUT(Endpoints.PASSWORD_RESET.PUT.CHANGE_PASSWORD, form)
                .then(response => response.json()).then((result) => {
                    if (!result.error) {
                        this.setState({ errorMessage: null, loading: false });
                        this.props.history.push({ pathname: "/account/password/result", state: { changed: true, email: '' } });
                    }
                    else {
                        this.setState({ errorMessage: result.message, message: result.message, loading: false });
                    }
                });
        } else this.setState({ loading: false });
    }

    render() {
        const { loading, errorMessage, passwordInputType, passwordInputIcon, passwordChecks, form, validation, recaptchaError } = this.state;

        return (
            <Fragment>
                {loading
                    ? <LoadingBar />
                    : <Container className="h-100">
                        <Row className="justify-content-center row-form-adjustedheight">
                            <Col md={6} className="align-self-center">
                                <Card className="p-5">
                                    <Image src='Images/logo.png' className="mx-auto mb-5 logo" />
                                    <h5 className="text-center mb-2">Password Reset</h5>
                                    <p className="text-center mb-4">Please enter your new password below.</p>
                                    <ResetPasswordForm
                                        handleSubmit={this.handleSubmit}
                                        errorMessage={errorMessage}
                                        passwordInputType={passwordInputType}
                                        passwordInputIcon={passwordInputIcon}
                                        passwordChecks={passwordChecks}
                                        handleShowHidePassword={this.handleShowHidePassword}
                                        form={form}
                                        handleInputChange={this.handleInputChange}
                                        validation={validation}
                                        handleRecaptcha={this.handleRecaptcha}
                                        recaptchaError={recaptchaError}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Footer />
                    </Container>
                }
            </Fragment>
        );
    }
}

export default ResetPassword;
