import React from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';

const UniqueIdentifiers = ({ companyItem, handlePackagingInputChange, noPackagingNeeded }) =>
    <React.Fragment>
        <h5>Packaging Preferences</h5>

        <div className="mt-3">
            <FormLabel>Is this product pre-packed?</FormLabel>
            <div className="form-input-description">
                <p>A pre-packed product must be provided in an already shippable container. You may be charged a packing charge if an order contains multiple products.</p>
            </div>
            <FormGroup className="custom-control custom-radio">
                <input className="custom-control-input" type='radio' id='radio-isPrePacked-yes' name='isPrePacked' value='true'
                    checked={companyItem.isPrePacked === true} onChange={handlePackagingInputChange} />
                <FormLabel className="custom-control-label" htmlFor="radio-isPrePacked-yes">Yes, it is pre-packed</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio">
                <input className="custom-control-input" type='radio' id='radio-isPrePacked-no' name='isPrePacked' value='false'
                    checked={companyItem.isPrePacked === false} onChange={handlePackagingInputChange} />
                <FormLabel className="custom-control-label" htmlFor="radio-isPrePacked-no">No, I need packaging</FormLabel>
            </FormGroup>
        </div>

        {!companyItem.isPrePacked ?
            <React.Fragment>
                <div className="mt-3">
                    <FormLabel>Does this product require box packaging?</FormLabel>
                    <FormGroup className="custom-control custom-radio">
                        <input className="custom-control-input" type='radio' id='radio-requiresBoxPackaging-yes' name='requiresBoxPackaging' value='true'
                            checked={companyItem.requiresBoxPackaging === true} onChange={handlePackagingInputChange} />
                        <FormLabel className="custom-control-label" htmlFor="radio-requiresBoxPackaging-yes">Yes, it requires box packaging</FormLabel>
                    </FormGroup>
                    <FormGroup className="custom-control custom-radio">
                        <input className="custom-control-input" type='radio' id='radio-requiresBoxPackaging-no' name='requiresBoxPackaging' value='false'
                            checked={companyItem.requiresBoxPackaging === false} onChange={handlePackagingInputChange} />
                        <FormLabel className="custom-control-label" htmlFor="radio-requiresBoxPackaging-no">No, box packaging is not needed</FormLabel>
                    </FormGroup>
                </div>
                <div className="mt-3">
                    <FormLabel>Please select your preferred packaging</FormLabel>
                    <FormGroup className="custom-control custom-radio">
                        <input className="custom-control-input" type='radio' id='radio-requiresPaperPackaging' name='requiresPaperPackaging' value='true'
                            checked={companyItem.requiresPaperPackaging === true} onChange={handlePackagingInputChange} />
                        <FormLabel className="custom-control-label" htmlFor="radio-requiresPaperPackaging">Paper packaging</FormLabel>
                    </FormGroup>
                    <FormGroup className="custom-control custom-radio">
                        <input className="custom-control-input" type='radio' id='radio-noPackagingNeeded' name='noPackagingNeeded' value='true'
                            checked={noPackagingNeeded === true} onChange={handlePackagingInputChange} />
                        <FormLabel className="custom-control-label" htmlFor="radio-noPackagingNeeded">No packaging needed</FormLabel>
                    </FormGroup>
                </div>
            </React.Fragment> : null}

        <div className="mt-3">
            <FormLabel>Is the product fragile?</FormLabel>
            <FormGroup className="custom-control custom-radio">
                <input className="custom-control-input" type='radio' id='radio-isFragile-yes' name='isFragile' value='true'
                    checked={companyItem.isFragile === true} onChange={handlePackagingInputChange} />
                <FormLabel className="custom-control-label" htmlFor="radio-isFragile-yes">Yes, it is fragile</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-0">
                <input className="custom-control-input" type='radio' id='radio-isFragile-no' name='isFragile' value='false'
                    checked={companyItem.isFragile === false} onChange={handlePackagingInputChange} />
                <FormLabel className="custom-control-label" htmlFor="radio-isFragile-no">No, it is not fragile</FormLabel>
            </FormGroup>
        </div>
    </React.Fragment>


export default UniqueIdentifiers;