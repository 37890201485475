import React from 'react';
import { Container, Row, Col, Button, FormGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SubNav } from '../../common/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import './PurchaseOrders.scss';

export const PurchaseOrdersHeader = ({ subNavList = [], isMainPage = false }) =>
    <Container className="mb-0 p-0" fluid>
        <Row className='purchaseorders-header'>        
            <Col md={ 6 }>
                <h1 className="page-title mb-3">Inventory</h1>
            </Col>
            <Col>            
                <FormGroup>
                    <Link to='' className="link-button">                    
                        <Button variant="primary" className="float-right plus-sign" type="submit">                            
                            <FontAwesomeIcon icon={ faPlus } className="mr-1" />Send Inventory
                            </Button>
                    </Link>
                    { 
                        isMainPage ? 
                        <Link to='/supplier/purchaseorders/create' className="link-button">                        
                            <Button variant="secondary" className="float-right mr-4" type="submit">Add Purchase Order</Button>
                        </Link>
                        : null
                    }
                    
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={ 12 }>
                {subNavList.length > 0 && <SubNav navList={subNavList} activeKey='PO References' />}                
            </Col>
        </Row>
    </Container>
