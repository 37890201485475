import React, { useState } from 'react';
import Actions from '../../../common/Tables/Actions';
import { toLocalTimeString, hasPermission } from '../../../../Utilities';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faUserLock, faUserTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { RecipientsModal } from '../createbulkorder/RecipientsModal';
import Endpoints from '../../../common/Endpoints';
import { POST, extractData, GET, DELETE, PUT } from '../../../../Consumer'
import { layer } from '@fortawesome/fontawesome-svg-core';
import { COUNTRY_LIST } from "../../../common/constants";
const ADDED_RECIPIENT_SUCCESS_MESSAGE = 'Recipient has been added to your Address Book';
const DUPLICATED_RECIPIENT_MESSAGE = 'Please select recipient address from the address book';

const UPDATED_RECIPIENT_SUCCESS_MESSAGE = 'Recipient has been updated.';
const UNSUCCESSFUL = 'Unsuccessful';

const AddressBookRow = ({ item, handleShowSuccessMessage, handleShowErrorMessage, handleLoading }) => {

    const [showModal, setShowModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
    const [contactDetails, setContactDetails] = useState(null)
    const [recipients, setRecipients] = useState([])
    const [showSuccessMessage, setSetshowSuccessMessage] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")
    const [errorTitle, setErrorTitle] = useState("")
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [isRecipientSelected, setIsRecipientSelected] = useState(true)
    const [showRecipientsModal, setShowRecipientsModal] = useState(false)
    const [modalStatusForm, setModalStatusForm] = useState("")
    const [validForm, setValidForm] = useState({})

    const handleShowRecipientsModal = (show = false) => {
        setModalStatusForm("edit");
        setShowModal(show);
    }

    const handleToggleDeleteConfirmationModal = (show = false) => {
        setShowDeleteConfirmationModal(show);
    }

    const cancelRecipientDelete = (e) => {        
        handleToggleDeleteConfirmationModal(false);
        handleShowRecipientsModal(false);
    }

    const handleDestinationAddressInputChange = (e) => {
        const { name, value } = e.target;

        setContactDetails({ ...contactDetails, [name]: value })
    }

    const handleContactDetailsInputChange = (e) => {
        let { name, value } = e.target;

        if (name === "contactNumber" && value !== "") value = value.replace(/[^0-9.]/gi, '');

        setContactDetails({ ...contactDetails, [name]: value })
    }

    const handleRecipientUpdate = async (requestBody) => {
        try {
            const { companyID } = JSON.parse(localStorage.getItem('user'));
            const url = Endpoints.COMPANY_CUSTOMER_ADDRESS.PUT.UPDATE_CUSTOMER_ADDRESS_FOR_COMPANY + requestBody.recipientID;

            const body = {
                ...requestBody.contactDetails,
                ...requestBody.destinationAddress,
                id: requestBody.recipientID,
                companyID
            };

            
                handleLoading(true);

                const response = await PUT(url, body);

                if (response.ok) {

                    const data = extractData(await response.json());

                    const newRecipientsState = recipients.map(recipient => {
                        if (recipient.id === data.id) {
                            let newRecipientState = {
                                ...data,
                                emailAddress: data.email,
                                contactNumber: data.phone
                            }
                            delete newRecipientState["email"];
                            delete newRecipientState["phone"];

                            return newRecipientState;
                        }
                        return recipient;
                    });


                    handleShowSuccessMessage(true, true);
                    handleShowErrorMessage(false);

                    setErrorMessage("");
                    handleLoading(false);
                    setRecipients([...newRecipientsState])
                    setShowModal(false);
                    setContactDetails({
                        name: data.name,
                        emailAddress: data.email,
                        contactNumber: data.phone,
                        companyName: data.companyName,
                        addressLine1: data.addressLine1,
                        addressLine2: data.addressLine2,
                        country: data.country,
                        county: data.county,
                        town: data.town,
                        postCode: data.postCode
                    })

                } else {

                    handleShowErrorMessage(true)
                    handleLoading(false)
                    handleShowSuccessMessage(false, true);
                }
            
        } catch (error) {
            console.log(error);
            handleShowErrorMessage(true)
            handleLoading(false)
            handleShowSuccessMessage(false, true);

        } finally {
            handleToggleDeleteConfirmationModal(false);
        }
    }

    const handleRecipientDelete = async (recipientID) => {

        try {
            handleLoading(true)
            const url = Endpoints.COMPANY_CUSTOMER_ADDRESS.DELETE.DELETE_CUSTOMER_ADDRESS_FOR_COMPANY + recipientID;

            const response = await DELETE(url);
            if (response.ok) {

                const newRecipietsState = recipients.filter(recipient => recipient.id !== recipientID);
                handleLoading(false);
                setErrorMessage("");
                handleShowSuccessMessage(true, false);
                handleShowErrorMessage(false);

            }
        } catch (error) {
            console.log(error);
            handleLoading(false);
        } finally {
            handleToggleDeleteConfirmationModal(false);
        }
    }

    const getActions = (item) => {
        const editItem = <Button onClick={() => handleShowRecipientsModal(true)} variant="link"><FontAwesomeIcon icon={faEdit} />Edit</Button>;
        const deleteItem = <Button variant="link" className="action-danger" onClick={() => handleToggleDeleteConfirmationModal(true)}><FontAwesomeIcon icon={faTrashAlt} />Delete</Button>;

        const rowActions = [];
        rowActions.push({ action: editItem, danger: false });
        rowActions.push({ action: deleteItem, danger: true });

        return rowActions;
    }

    return (
        <>
            <tr>
                <td>{item.name}</td>
                <td>{item.companyName}</td>
                <td>{item.email}</td>
                <td>{item.phone}</td>
                <td>{`${item.addressLine1}, ${item.addressLine2}${item.addressLine2 ? ', ' : ''}${item.town}, ${item.postCode}, ${item.county}, ${COUNTRY_LIST.find((obj) => obj["code"] === item.country).name}`}</td>
                <td><Actions title="Recipient Details" actions={getActions(item)} /></td>
            </tr>
            <RecipientsModal
                show={showModal}
                recipientID={item.id}
                {...item}
                emailAddress={item.email}
                contactNumber={item.phone}
                addressLine2={item.addressLine2}
                showDeleteConfirmationModal={showDeleteConfirmationModal}
                handleShowRecipientsModal={handleShowRecipientsModal}
                handleToggleDeleteConfirmationModal={handleToggleDeleteConfirmationModal}
                handleContactDetailsInputChange={handleContactDetailsInputChange}
                handleDestinationAddressInputChange={handleDestinationAddressInputChange}
                handleEdit={handleRecipientUpdate}
                cancelRecipientDelete={cancelRecipientDelete}
                handleRecipientDelete={handleRecipientDelete}
                modalStatusForm={modalStatusForm}
            />
        </>

    );
};

export default AddressBookRow;