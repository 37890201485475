import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './Confirm.scss';

const Confirm = ({ title, text, buttonText, buttonVariant, handleConfirmClose, handleConfirmAction, closeButtonVariant="link", show = true, cancelText = "Cancel", size = "", showCloseButton = true, closeButton = true, id = "" }) =>
    <Modal id={id} size={size} centered show={show} onHide={handleConfirmClose}>
        <Modal.Header closeButton={closeButton}>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{text}</p>
        </Modal.Body>
        <Modal.Footer className="pt-0 mb-2">
            {showCloseButton && <Button variant={closeButtonVariant} onClick={handleConfirmClose}>{cancelText}</Button>}
            <Button variant={buttonVariant} onClick={handleConfirmAction}>{buttonText}</Button>
        </Modal.Footer>
    </Modal>

export default Confirm;