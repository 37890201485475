import React, { Component } from 'react';
import { Col, Container, Row, Button, Form, FormGroup, FormLabel, FormControl, Table, Modal, Card } from 'react-bootstrap';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import { GET, extractData, POST } from '../../../Consumer';
import { PREFERENCES_SUB_NAV_LIST } from '../../common/constants'
import Header from '../../common/Header';
import { SuccessAlert, ErrorAlert, WarningAlert } from '../../common/Alert';
import FormValidator from '../../common/FormValidator';
import { EORI_REGIONS, EU_COUNTRY_LIST } from '../../common/constants';
import Actions from '../../common/Tables/Actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faPlus, faTrash, faPen } from '@fortawesome/free-solid-svg-icons';
import Confirm from './../../common/Confirm';
import './Taxes.scss';

const GB_PLACEHOLDER = "Example: GB123456789";
const NI_PLACEHOLDER = "Example: X1123456789";
const EU_PLACEHOLDER = "Example: XX123456789";

const EORI_FORMATTING_ERROR = "EORI Number is formatted incorrectly for this region.";
const EORI_GENERIC_SAVE_ERROR = "There has been an issue saving this EORI.";
const EORI_GENERIC_UPDATE_ERROR = "There has been an issue updating this EORI.";
const EORI_ISSUE_RETRIEVING_ERROR = "There has been an issue retrieving EORI numbers.";
const EORI_ALL_ADDED_MESSAGE = "You have added EORI numbers for all regions. Please edit your EORI Number, or delete the existing EORI Number before trying to add a new one.";
const EORI_INFORMATION = "Enter your EORI number(s) here. Your GB EORI is required for orders going to Northern Ireland, the EU, and Rest of World. You can get more information on EORI numbers and how to get one at";
const EORI_REMOVAL_MESSAGE = "Are you sure you want to remove the EORI number? Removing your EORI number will result in us being unable to process orders for ";
const EORI_GENERIC_REMOVE_ERROR = "There has been an issue removing this EORI.";

const VAT_INFORMATION = "Choose below with which EU countries you are VAT Registered. You must add an EU EORI number before making your selection. You can find out more information";
const VAT_UPDATE_MESSAGE = "Successfully saved your VAT Registered details";
const VAT_GENERIC_SAVE_ERROR = "There has been an issue saving your VAT Registered details.";
const VAT_GENERIC_RETRIEVE_ERROR = "There has been an issue retrieving your VAT Registered details.";
const VAT_UNSAVED_CHANGES = "You have not saved your changes to the VAT Registered details.";

const IOSS_NUMBER_INFORMATION = "The IOSS (Import One Stop Shop) number is used in order to sell goods to buyers in the EU under the IOSS scheme. The IOSS VAT identification number consists of 12 alphanumeric characters.";
const IOSS_NUMBER_OPT_IN = "Please use my IOSS number on the shipments delivered to end customer in the EU that are below €150.";
const IOSS_UNSAVED_CHANGES = "You have not saved your changes to the IOSS number details.";
const IOSS_GENERIC_RETRIEVE_ERROR = "There has been an issue retrieving your IOSS Number.";
const IOSS_CREATE_MESSAGE = "Successfully saved IOSS number";
const IOSS_DELETE_MESSAGE = "Successfully deleted IOSS number";
const IOSS_CREATE_ERROR_MESSAGE = "Unable to save IOSS number. Please try again.";
const IOSS_DELETE_ERROR_MESSAGE = "Unable to delete IOSS number. Please try again.";
const IOSS_INVALID_NUMBER = "Please make sure the number is in the correct format, which is IM + 3 numbers or letters + 7 numbers. For example: IM1234567890 or IMDEU4567890";
const IOSS_DELETE_CONFIRMATION_MESSAGE = "By removing the IOSS number, it will no longer be included in any orders."

const iossNumberPattern = /IM[a-zA-Z\d]{3}[\d]{7}/;
const iossNumberRegex = new RegExp(iossNumberPattern);

const AddEoriNumberModal = ({ currentRegion, eoriUpdate, eoriListing, eoriSubmitted, eoriValidation, onSubmitEori, currentlySelectedEori, eoriPopupError, showEoriPopupError, showEoriModal, handleClose, handleEoriInputChange }) => {
  const identifier = currentlySelectedEori.identifier;
  const placeholder = identifier === "GB" ? GB_PLACEHOLDER : identifier === "NI" ? NI_PLACEHOLDER : identifier === "EU" ? EU_PLACEHOLDER : "";

  let availableRegions = [];
  EORI_REGIONS.map(region => { if (!eoriListing.some(el => el.identifier === region.code)) availableRegions = availableRegions.concat(region); });

  return (
    <Modal show={showEoriModal} onHide={handleClose}>
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>EORI Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            {showEoriPopupError && <Row><Col><ErrorAlert errorMessage={eoriPopupError}></ErrorAlert></Col></Row>}
            <FormGroup>
              <Row>
                <Col md={12}>
                  <FormLabel htmlFor="identifier">Region</FormLabel>
                  {eoriUpdate ? <FormControl type="text" id="identifier" name="identifier" value={currentRegion} disabled /> :
                    <FormControl as="select" id="identifier" name="identifier" value={currentlySelectedEori.identifier} onChange={handleEoriInputChange} required>
                      <option value="">Select Region</option>
                      {availableRegions.map((v, i) => <option key={i} value={v.code}>{v.name}</option>)}
                    </FormControl>}
                  {eoriSubmitted && <span className="text-danger">{eoriValidation.identifier.message}</span>}
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <FormLabel htmlFor="number">EORI Number</FormLabel>
                  <FormControl type="text" placeholder={placeholder} maxLength="15" id="number" name="number" onChange={handleEoriInputChange} value={currentlySelectedEori.number} required />
                  {eoriSubmitted && <span className="text-danger">{eoriValidation.number.message}</span>}
                </Col>
              </Row>
            </FormGroup>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" className="button-nav-link mr-3" onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={onSubmitEori}>Add EORI Number</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

class Taxes extends Component {

  eoriValidator = new FormValidator([
    {
      field: 'identifier',
      method: 'isEmpty',
      validWhen: false,
      message: 'Select a Region before continuing'
    },
    {
      field: 'number',
      method: 'isEmpty',
      validWhen: false,
      message: 'Enter your EORI number before continuing'
    }
  ]);

  state = {
    loading: true,
    eoriValidation: this.eoriValidator.valid(),
    showEoriError: false,
    showEoriSuccess: false,
    eoriErrorMessage: '',
    eoriSuccessMessage: '',
    showEoriModal: false,
    eoriSubmitted: false,
    currentlySelectedEori: {
      identifier: "",
      number: ""
    },
    eoriListing: [],
    showEoriPopupError: false,
    eoriPopupError: "",
    showEoriMaxModal: false,
    showEoriRemovalModal: false,
    currentRegion: "",
    eoriUpdate: false,
    showVatError: false,
    vatErrorMessage: "",
    showVatSuccess: false,
    vatSuccessMessage: "",
    vatCountries: [],
    showVatRetrievalError: false,
    euEoriPresent: false,
    unsavedVatChanges: false,
    initialIossNumberState: {
      euIossNumber: "",
      activeIossNumber: false,
    },
    hasIossNumber: false,
    euIossNumber: "",
    activeIossNumber: false,
    showIossNumberSuccess: false,
    showIossNumberError: false,
    showIossNumberRetrievalError: false,
    unsavedIossNumberChanges: false,
    disableIossNumberInput: false,
    showIossActionButtons: true,
    showInvalidIossMessage: false,
    showDeleteIossConfirmation: false,
    successIOSSMessage: "",
    errorIOSSMessage: ""
  };

  iossInputRef = React.createRef(null);

  async componentDidMount() {
    await this.fetchCurrentEoriDetails();
    await this.fetchCurrentVatDetails();
    await this.fetchCurrentIossNumber();
    this.setState({ loading: false });
  }

  async fetchCurrentEoriDetails() {
    return await GET(Endpoints.RETAILER.GET.EORI_DETAILS)
      .then(response => { if (response.ok) return response.json(); })
      .then(results => {
        const result = extractData(results);
        if (result === null) this.setState({ showEoriError: true, eoriListing: [], eoriErrorMessage: EORI_ISSUE_RETRIEVING_ERROR });
        else {
          const euEoriPresent = result.eoriNumbers.length > 0 ? result.eoriNumbers.filter(num => num.identifier === "EU").length !== 0 : false;
          this.setState({ euEoriPresent: euEoriPresent, eoriListing: result.eoriNumbers, showEoriError: false, eoriErrorMessage: '' });
        }
      })
      .catch(error => {
        this.setState({ showEoriError: true, eoriErrorMessage: EORI_ISSUE_RETRIEVING_ERROR });
        console.log(error);
      });
  }

  async fetchCurrentVatDetails() {
    return await GET(Endpoints.RETAILER.GET.VAT_DETAILS)
      .then(response => { if (response.ok) return response.json(); })
      .then(results => {
        const result = extractData(results);
        this.setState({ vatCountries: result.vatCountries, showVatError: false, vatErrorMessage: "", showVatRetrievalError: false });
      })
      .catch(error => {
        this.setState({ showVatError: true, vatErrorMessage: VAT_GENERIC_RETRIEVE_ERROR, showVatRetrievalError: true });
        console.log(error);
      });
  }

  async fetchCurrentIossNumber() {
    return await GET(Endpoints.RETAILER.GET.IOSS_NUMBER)
      .then(response => { if (response.ok) return response.json(); })
      .then(results => {
        const result = extractData(results);

        if (result.isActiveIOSS) {
          this.setState({
            euIossNumber: result.euioss,
            showIossNumberRetrievalError: false,
            activeIossNumber: result.isActiveIOSS,
            hasIossNumber: true,
            initialIossNumberState: {
              euIossNumber: result.euioss,
              activeIossNumber: result.isActiveIOSS,
            },
            disableIossNumberInput: true,
            showIossActionButtons: true
          });
        } else { 
          this.setState({ 
            euIossNumber: "IM" , 
            showIossNumberRetrievalError: false, 
            activeIossNumber:  result.isActiveIOSS, 
            hasIossNumber: false, 
            initialIossNumberState: { euIossNumber: "IM", activeIossNumber: result.isActiveIOSS },
            disableIossNumberInput:  true,
            showIossActionButtons: false
          });
        }
      })
      .catch(error => {
        this.setState({ showIossNumberRetrievalError: true, showIossNumberErrorMessage: IOSS_GENERIC_RETRIEVE_ERROR });
        console.log(error);
      });
  }


  handlePopupClose = () => this.setState({ showEoriModal: false, eoriUpdate: false, showEoriPopupError: false, eoriPopupError: "", eoriSubmitted: false, currentlySelectedEori: { identifier: "", number: "" } });

  handleMaxModalClose = () => this.setState({ showEoriMaxModal: false, currentlySelectedEori: { identifier: "", number: "" } });

  handleUpdateEoriModalShow = (eori) => this.setState({ eoriUpdate: true, showEoriModal: true, currentlySelectedEori: { identifier: eori.identifier, number: eori.eoriNumber }, currentRegion: eori.region });

  handleRemoveEoriModalShow = (eori) => this.setState({ showEoriRemovalModal: true, currentlySelectedEori: { identifier: eori.identifier, number: eori.eoriNumber }, currentRegion: eori.region });

  handleRemoveEoriModalClose = () => this.setState({ showEoriRemovalModal: false, currentlySelectedEori: { identifier: "", number: "" }, currentRegion: "" });


  onSubmitEori = () => {
    const { currentlySelectedEori, currentRegion, eoriUpdate } = this.state;
    const errorText = eoriUpdate ? EORI_GENERIC_UPDATE_ERROR : EORI_GENERIC_SAVE_ERROR;

    const eoriValidation = this.eoriValidator.validate(currentlySelectedEori);
    this.setState({ eoriValidation: eoriValidation, eoriSubmitted: true });

    if (eoriValidation.isValid) {
      this.setState({ loading: true });
      POST(Endpoints.RETAILER.POST.UPDATE_EORI, currentlySelectedEori)
        .then(response => { if (response && response.ok) return response.json(); })
        .then(results => {
          const result = extractData(results);
          if (result.updated === true) {
            const successText = eoriUpdate ? "Successfully edited your " + currentRegion + " EORI Number" : "Successfully added your " + result.region + " EORI Number";
            this.setState({ loading: false, eoriSubmitted: false, eoriUpdate: false, showEoriPopupError: false, showEoriSuccess: true, eoriSuccessMessage: successText, showEoriModal: false, currentlySelectedEori: { identifier: "", number: "" } });
            this.fetchCurrentEoriDetails();
          }
          else if (result.formattingError === true) this.setState({ loading: false, showEoriPopupError: true, showEoriSuccess: false, eoriPopupError: EORI_FORMATTING_ERROR, eoriSuccessMessage: "" });
          else this.setState({ loading: false, showEoriPopupError: true, showEoriSuccess: false, eoriPopupError: errorText, eoriSuccessMessage: "" });
        })
        .catch(error => {
          this.setState({ loading: false, showEoriPopupError: true, eoriPopupError: errorText, showEoriSuccess: false, eoriSuccessMessage: "" });
          console.log(error);
        });
    }
  }

  handleRemoveEori = () => {
    const { currentlySelectedEori, currentRegion } = this.state;
    const successText = "Successfully removed your " + currentRegion + " EORI Number";
    this.setState({ loading: true });
    POST(Endpoints.RETAILER.POST.REMOVE_EORI + currentlySelectedEori.identifier)
      .then(response => { if (response && response.ok) return response.json(); })
      .then(results => {
        const result = extractData(results);
        if (result === true) {
          this.setState({ loading: false, showEoriRemovalModal: false, showEoriSuccess: true, eoriSuccessMessage: successText, showEoriError: false, eoriErrorMessage: "", currentlySelectedEori: { identifier: "", number: "" } });
          this.fetchCurrentEoriDetails();
        }
        else this.setState({ loading: false, showEoriRemovalModal: false, showEoriSuccess: false, eoriSuccessMessage: "", showEoriError: true, eoriErrorMessage: EORI_GENERIC_REMOVE_ERROR });
      })
      .catch(error => {
        this.setState({ loading: false, showEoriRemovalModal: false, showEoriSuccess: false, eoriSuccessMessage: "", showEoriError: true, eoriErrorMessage: EORI_GENERIC_REMOVE_ERROR, });
        console.log(error);
      });
  }

  getEoriActions = (eori) => {
    const editDetails = <Button onClick={() => this.handleUpdateEoriModalShow(eori)} variant="link"><FontAwesomeIcon icon={faEdit} />Edit Details</Button>;
    const remove = <Button onClick={() => this.handleRemoveEoriModalShow(eori)} variant="link" className="action-danger"><FontAwesomeIcon icon={faTrashAlt} />Remove</Button>;

    return [{ action: editDetails, danger: false }, { action: remove, danger: true }];
  }

  handleEoriInputChange = (e) => {
    const { name, value } = e.target;

    this.setState(prevState => ({
      ...prevState,
      currentlySelectedEori: {
        ...prevState.currentlySelectedEori,
        ...{ [name]: value }
      }
    }));
  }

  handleAddEoriClick = (eoriListing) => {
    if (eoriListing !== undefined && eoriListing.length === 3) this.setState({ showEoriMaxModal: true });
    else this.setState({ showEoriModal: true, eoriPopupError: "" });
  }

  handleCheckBoxChange = (e) => {
    const { checked, name } = e.target;

    this.setState(prevState => ({
      vatCountries: {
        ...prevState.vatCountries,
        ...{ [name]: checked }
      },
      unsavedVatChanges: true,
      showVatSuccess: false,
      showVatError: false
    }));
  }

  handleIossInputChange = (e) => {
    const { value } = e.target;

    if(value.trim().length < 2) return;

    this.setState(prevState => ({
      euIossNumber: value.trim() === "IM" ? "IM" : value,
      unsavedIossNumberChanges: prevState.initialIossNumberState.euIossNumber !== value,
      showIossNumberSuccess: false,
      showIossNumberError: false,
    }));
  }

  handleIossNumberCheckBoxChange = (v) => {
    const isChecked = v.target.checked;
    const {activeIossNumber, euIossNumber } = this.state

    if(isChecked && euIossNumber.length > 2)
    {
      //Show the edit buttons but disable the input
      this.setState(prevState => ({
        unsavedIossNumberChanges: prevState.initialIossNumberState.activeIossNumber !== isChecked,
        disableIossNumberInput: true,
        showIossNumberSuccess: false,
        showIossNumberError: false,
        showInvalidIossMessage: false,
      }));
      this.setState(prevState => ({activeIossNumber: isChecked}));
      return;
    }
    else if(!isChecked && euIossNumber.length > 2) 
    {
      //Delete the IOSS when not using an IOSS number
      this.handleDeleteShowHide(true);
    }
    else
    {
      //Show the edit buttons but disable the input
      this.setState(prevState => ({
        unsavedIossNumberChanges: prevState.initialIossNumberState.activeIossNumber !== isChecked,
        disableIossNumberInput: true,
        showIossActionButtons : true,
        showIossNumberSuccess: false,
        showIossNumberError: false,
        showInvalidIossMessage: false,
      }));
      this.setState(prevState => ({activeIossNumber: isChecked}));
    }
  }

  handleEditIossNumber = () => {
    this.setState({ disableIossNumberInput: false, showIossActionButtons: false}, () => this.iossInputRef.current.focus()); 
  }

  handleDeleteIossNumber = () => {
    this.setState({ loading: true });
    POST(Endpoints.RETAILER.POST.IOSS_NUMBER_REMOVE, { euIoss: "", isActiveIOSS: false })
      .then(response => { if (response && response.ok) return response.json(); })
      .then(results => {
        const result = extractData(results);
        if (result) {
          this.setState({ 
              unsavedIossNumberChanges: false, 
              loading: false, 
              disableIossNumberInput: true,
              showIossNumberError: false, 
              showIossNumberSuccess: true, 
              successIOSSMessage: IOSS_DELETE_MESSAGE, 
              errorIOSSMessage: "" });
          this.fetchCurrentIossNumber();
        }
        else this.setState({  unsavedIossNumberChanges: false, 
                              loading: false, 
                              showIossNumberError: true, 
                              showIossNumberSuccess: false, 
                              errorIOSSMessage: IOSS_DELETE_ERROR_MESSAGE, 
                              successIOSSMessage: "" });
      })
      .catch(error => {
        this.setState({ unsavedIossNumberChanges: false, 
                        loading: false, 
                        showIossNumberError: true, 
                        showIossNumberSuccess: false, 
                        errorIOSSMessage: IOSS_DELETE_ERROR_MESSAGE, 
                        successIOSSMessage: "" });
        console.log(error);
      });
  }

  handleSubmit = async () => {
    const { unsavedIossNumberChanges, unsavedVatChanges } = this.state;
    if(unsavedVatChanges) await this.submitVat();
    if(unsavedIossNumberChanges) await this.submitIoss();
  }

  submitVat = () => {
    const { vatCountries } = this.state;
    const data = { VatCountries: vatCountries };

    this.setState({ loading: true });
    POST(Endpoints.RETAILER.POST.UPDATE_VAT, data)
      .then(response => { if (response && response.ok) return response.json(); })
      .then(results => {
        const result = extractData(results);
        if (result === true) {
          this.setState({ unsavedVatChanges: false, vatCountries: [], loading: false, showVatError: false, vatErrorMessage: "", showVatSuccess: true, vatSuccessMessage: VAT_UPDATE_MESSAGE });
          this.fetchCurrentVatDetails();
        }
        else this.setState({ unsavedVatChanges: false, loading: false, showVatError: true, showVatSuccess: false, vatErrorMessage: VAT_GENERIC_SAVE_ERROR, vatSuccessMessage: "" });
      })
      .catch(error => {
        this.setState({ unsavedVatChanges: false, loading: false, showVatError: true, showVatSuccess: false, vatErrorMessage: VAT_GENERIC_SAVE_ERROR, vatSuccessMessage: "" });
        console.log(error);
      });
  }

  submitIoss = () => {
    const { euIossNumber, activeIossNumber } = this.state;

    const isValidIossNumber = this.validateIossNumber(euIossNumber) && iossNumberRegex.test(euIossNumber);

    if(!isValidIossNumber && activeIossNumber) {
      this.setState({ showInvalidIossMessage: true, showIossActionButtons: false, disableIossNumberInput: false }, () => this.iossInputRef.current.focus());
      return;
    }

    const success = { unsavedIossNumberChanges: false, loading: false, showIossNumberError: false, showIossNumberSuccess: true, successIOSSMessage: IOSS_CREATE_MESSAGE, errorIOSSMessage: "" , showInvalidIossMessage: false };
    const error = { unsavedIossNumberChanges: false, loading: false, showIossNumberError: true, showIossNumberSuccess: false, errorIOSSMessage: IOSS_CREATE_ERROR_MESSAGE, successIOSSMessage: "" };

    this.setState({ loading: true });
    POST(Endpoints.RETAILER.POST.IOSS_NUMBER, { euIoss: euIossNumber === "IM" ? null : euIossNumber, isActiveIOSS: activeIossNumber })
      .then(response => { if (response && response.ok) return response.json(); })
      .then(results => {
        const result = extractData(results);
        if (result) {
          this.setState(success);
          this.fetchCurrentIossNumber();
        }
        else this.setState(error);
      })
      .catch(error => {
        this.setState(error);
        console.log(error);
      });
  }

  validateIossNumber = (euIossNumber) => {
    const firstCountryCodeCharacter = parseInt(euIossNumber[2]);
    const secondCountryCodeCharacter = parseInt(euIossNumber[3]);
    const thirdCountryCodeCharacter = parseInt(euIossNumber[4]);

    return (isNaN(firstCountryCodeCharacter) && isNaN(secondCountryCodeCharacter) && isNaN(thirdCountryCodeCharacter))
    || (!isNaN(firstCountryCodeCharacter) && !isNaN(secondCountryCodeCharacter) && !isNaN(thirdCountryCodeCharacter))
  }

  handleDeleteShowHide = (v) => {
    if(v = true)
    {
    this.setState(prevState => ({ 
      showDeleteIossConfirmation: !prevState.showDeleteIossConfirmation, 
      unsavedIossNumberChanges: false 
     }));  
     this.activeIossNumber = false;
    }
    else
    {
      this.activeIossNumber = true;
    }
  }

  confirmDeleteIoss = () => {
    this.setState({ showDeleteIossConfirmation: false });
    this.handleDeleteIossNumber();
  }

  buildVatForm() {
    const { vatCountries, showVatRetrievalError, euEoriPresent } = this.state;
    return (
      <React.Fragment>
        {showVatRetrievalError ? null :
          <Form>
            {
              EU_COUNTRY_LIST.map((c, i) => {
                const value = (vatCountries !== null || vatCountries === undefined || vatCountries === []) ? vatCountries[c.code] : c.code;
                const checked = (vatCountries !== null || vatCountries === undefined || vatCountries === []) ? vatCountries[c.code] !== undefined ? vatCountries[c.code] : false : false;

                return (
                  <FormGroup key={c.code} className="custom-control custom-checkbox mb-2">
                    <input id={c.code} name={c.code} value={value} className="custom-control-input" type="checkbox" onChange={this.handleCheckBoxChange} checked={checked} disabled={euEoriPresent ? false : true} />
                    <FormLabel className="custom-control-label" htmlFor={c.code}>{c.name}</FormLabel>
                  </FormGroup>
                )
              })
            }
          </Form>
        }
      </React.Fragment>
    );
  }

  render() {
    const { unsavedVatChanges, showVatSuccess, vatErrorMessage, showVatError, vatSuccessMessage, 
            eoriUpdate, eoriSuccessMessage, showEoriRemovalModal, currentRegion, eoriListing, showEoriMaxModal, 
            loading, showEoriModal, currentlySelectedEori, eoriPopupError, showEoriError, 
            showEoriSuccess, eoriErrorMessage, showEoriPopupError, eoriSubmitted, euIossNumber, activeIossNumber, 
            unsavedIossNumberChanges, hasIossNumber, showIossNumberSuccess, showIossNumberError, disableIossNumberInput, 
            showIossActionButtons, showInvalidIossMessage, showDeleteIossConfirmation, successIOSSMessage, 
            errorIOSSMessage, showIossNumberRetrievalError, showIossNumberErrorMessage } = this.state;
    const eoriValidation = this.eoriSubmitted ? this.eoriValidator.validate(currentlySelectedEori) : this.state.eoriValidation;
    
    return loading ? (<LoadingBar />) : (
      <React.Fragment>
        <Header title="Preferences" subNavList={PREFERENCES_SUB_NAV_LIST} activeKey="Taxes" />
        <Container fluid>
          <Row>
            <Col sm={12} md={8}>
              <h5 className="mb-3">EORI Number</h5>
              <p>{EORI_INFORMATION} <a href="https://www.gov.uk/eori">gov.uk/eori</a>.</p>

              {showEoriError && <ErrorAlert errorMessage={eoriErrorMessage} />}
              {showEoriSuccess && <SuccessAlert successMessage={eoriSuccessMessage} />}

              {eoriListing.length ?
                <Card className="card-table">
                  <div className="table-responsive">
                    <Table striped bordered hover className="mb-0">
                      <thead>
                        <tr>
                          <th>Region</th>
                          <th>EORI Number</th>
                          <th>Last Edited</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {eoriListing.map((e) => (
                          <tr key={e.identifier}>
                            <td>{e.region}</td>
                            <td>{e.eoriNumber}</td>
                            <td>{e.lastEdited}</td>
                            <td><Actions actions={this.getEoriActions(e)} /></td>
                          </tr>))}
                        <tr bordered className="table-light interactive-row">
                          <td className="row-buffer"><FontAwesomeIcon icon={faPlus} /> <a className="table-link" onClick={() => this.handleAddEoriClick(eoriListing)}>Add EORI Number</a></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Card> : !showEoriError && <Button className="mt-1" variant="secondary" onClick={() => this.handleAddEoriClick()}>Add EORI Number</Button>
              }

              <div className="top-buffer">
                <h5 className="mb-3">IOSS Number</h5>
                {showIossNumberSuccess && <SuccessAlert successMessage={successIOSSMessage} />}
                {showIossNumberError && <ErrorAlert errorMessage={errorIOSSMessage} />}
                {showIossNumberRetrievalError && <ErrorAlert errorMessage={showIossNumberErrorMessage} />}
                {unsavedIossNumberChanges && <WarningAlert warningTitle="Unsaved Changes" warningMessage={IOSS_UNSAVED_CHANGES} />}
                <p className='mb-5'>{IOSS_NUMBER_INFORMATION}</p>

                <FormGroup className="custom-control custom-checkbox mb-4">
                  <input id="opt-in-ioss-number" name="opt-in-ioss-number" value={false} className="custom-control-input" type="checkbox" onChange={v => this.handleIossNumberCheckBoxChange(v)} checked={activeIossNumber} />
                  <FormLabel className="custom-control-label" htmlFor="opt-in-ioss-number">{IOSS_NUMBER_OPT_IN}</FormLabel>
                </FormGroup>

                <FormGroup id="ioss-input-container" className="mb-2">                  
                  <label id="ioss-number-label" className='mr-3' htmlFor="ioss-number">IOSS Number</label>
                  <input ref={ this.iossInputRef } className='ioss-number-input mr-4' disabled={disableIossNumberInput} id="ioss-number" name="ioss-number" onChange={ this.handleIossInputChange } value={ euIossNumber } maxLength={12} type='text' ></input>
                  {showInvalidIossMessage && <span className="text-danger">{ IOSS_INVALID_NUMBER }</span> }
                  {showIossActionButtons && <FontAwesomeIcon className='edit-iossnumber-button mr-3' onClick={ this.handleEditIossNumber } icon={ faPen } />}
                  {showIossActionButtons && <FontAwesomeIcon className='delete-iossnumber-button mr-3' onClick={ () => this.handleDeleteShowHide(false) } icon={ faTrash } />}
                </FormGroup>

              </div>

              <div className="top-buffer">
                <h5 className="mb-3">VAT Registered</h5>
                <p>{VAT_INFORMATION} <a href="https://ec.europa.eu/taxation_customs/business/vat/eu-country-specific-information-vat_en">here</a>.</p>

                {showVatError && <ErrorAlert errorMessage={vatErrorMessage} />}
                {showVatSuccess && <SuccessAlert successMessage={vatSuccessMessage} />}
                {unsavedVatChanges && <WarningAlert warningTitle="Unsaved Changes" warningMessage={VAT_UNSAVED_CHANGES} />}
              </div>
              <div id='vat-countries-container'>
                {this.buildVatForm()}
              </div>

              {(unsavedVatChanges || unsavedIossNumberChanges) && <Button id="save-taxes-button" onClick={ this.handleSubmit } variant="primary" className="mt-3" type='button'><span>Save</span></Button>}

              {showDeleteIossConfirmation && <Confirm title="Remove IOSS Number" text={ IOSS_DELETE_CONFIRMATION_MESSAGE } buttonText="Yes, delete" buttonVariant="danger" cancelText="No, keep it" handleConfirmClose={ () => this.handleDeleteShowHide(false) } handleConfirmAction={ this.confirmDeleteIoss } />}
              <AddEoriNumberModal currentRegion={currentRegion} eoriUpdate={eoriUpdate} eoriListing={eoriListing} eoriSubmitted={eoriSubmitted} eoriValidation={eoriValidation} onSubmitEori={this.onSubmitEori} currentlySelectedEori={currentlySelectedEori} showEoriPopupError={showEoriPopupError} eoriPopupError={eoriPopupError} showEoriModal={showEoriModal} handleClose={this.handlePopupClose} handleEoriInputChange={this.handleEoriInputChange} />
              <Confirm title="EORI Number Alert" text={EORI_ALL_ADDED_MESSAGE} buttonText="Ok" buttonVariant="primary" handleConfirmAction={this.handleMaxModalClose} handleConfirmClose={this.handleMaxModalClose} show={showEoriMaxModal} showCloseButton={false} />
              <Confirm title="Remove EORI Number" text={EORI_REMOVAL_MESSAGE + currentRegion + "."} buttonText="Remove EORI Number" buttonVariant="danger" handleConfirmAction={this.handleRemoveEori} handleConfirmClose={this.handleRemoveEoriModalClose} show={showEoriRemovalModal} showCloseButton={true} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Taxes;