import React, { Component } from 'react';
import Header from '../../common/Header';
import { Container, Button } from 'react-bootstrap';
import { PRODUCTS_SUB_NAV_LIST } from '../../common/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

class OrderBundleDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bundleDetails: this.props.productBundle,
            selectedItems: { productBundleCompanyItems: this.props.productBundle.productBundleCompanyItems }
        };
    }

    render() {
        const { bundleDetails, selectedItems } = this.state;
        const { hideDetails, awaitingStockSkuCodes = [] } = this.props;

        return (
            <React.Fragment>
                <Header title="Products" subNavList={PRODUCTS_SUB_NAV_LIST} activeKey="Bundles" />
                <Container fluid>
                    <div className="mb-3" >
                        <Button variant="link" className="breadcrumb-link p-0" onClick={hideDetails}>
                            Order Details
                        </Button>
                        &nbsp;<FontAwesomeIcon className="mx-1" icon={faAngleRight} />&nbsp;
                            <span>Bundle Details</span>
                    </div>
                    <React.Fragment>
                        <h5>{bundleDetails.name}</h5>
                        <div className="mt-3">
                            <h6>Bundle Details</h6>
                            <dl>
                                <dt className="mb-0">Bundle Name</dt>
                                <dd className="mb-1">{bundleDetails.name}</dd>
                                <dt className="mb-0">Bundle Description</dt>
                                <dd className="mb-1">{bundleDetails.description ? bundleDetails.description : "N/A"}</dd>
                                <dt className="mb-0">Bundle SKU</dt>
                                <dd className="mb-1">{bundleDetails.skuCode ? bundleDetails.skuCode : "N/A"}</dd>
                                <dt className="mb-0">Bundle Price (£)</dt>
                                <dd className="mb-1">{bundleDetails.price ? bundleDetails.price.toFixed(2) : "N/A"}</dd>
                            </dl>
                        </div>
                        <div className="mt-4 mb-4">
                            <h6>Product Selection</h6>
                            {selectedItems.productBundleCompanyItems.map((productBundleCompanyItem, i) =>
                                <React.Fragment key={i}>
                                    <dl>
                                        <dt className="mb-0">Product {i + 1}</dt>
                                        <dd className="mb-0">{productBundleCompanyItem.companyItem.item.name}</dd>
                                        <dd className="mb-0">EAN: {productBundleCompanyItem.companyItem.item.ean ? productBundleCompanyItem.companyItem.item.ean : "N/A"}</dd>
                                        <dd className="mb-0">SKU: {productBundleCompanyItem.skuCode ? productBundleCompanyItem.skuCode : "N/A"}</dd>
                                        <dd className="mb-0">SUID: {productBundleCompanyItem.companyItem.item.selazarUniqueID ? productBundleCompanyItem.companyItem.item.selazarUniqueID : "N/A"}</dd>
                                        <dd className="mb-0">Quantity: {productBundleCompanyItem.quantity}</dd>
                                        {awaitingStockSkuCodes.includes(productBundleCompanyItem.skuCode) ? 
                                        <dd className="mb-0"><span className="text-status-yellow">Awaiting Stock</span></dd>
                                         : null
                                        }       
                                    </dl>
                                </React.Fragment>
                            )}
                        </div>
                    </React.Fragment>
                </Container>
            </React.Fragment>
        )
    }
}

export default OrderBundleDetails;