import React, { Component } from 'react';
import { FormGroup, FormLabel, Row, Col, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GET, GET_ASYNC, PUT, extractData } from '../../../../../Consumer';
import { defaultGuid } from '../../../../../Utilities';
import Endpoints from '../../../../common/Endpoints';
import LoadingBar from '../../../../common/LoadingBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { SuccessAlert, ErrorAlert } from '../../../../common/Alert';
import ProductHeader from '../../ProductHeader';

class EditPackagingPreferences extends Component {

    constructor(props) {
        super(props);

        const user = JSON.parse(localStorage.getItem('user'));

        this.state = {
            loading: true,
            warning: null,
            companyItemID: (this.props.match && this.props.match.params) ? this.props.match.params.id : null,
            pageIndex: this.props.match.params.pageIndex || 1,
            categories: [],
            companyItem: {
                id: defaultGuid,
                editable: false,
                active: false,
                companyID: user.companyID,
                itemID: defaultGuid,
                expectedWeeklySales: 0,
                isPrePacked: false,
                requiresBoxPackaging: false,
                requiresPaperPackaging: false,
                isFragile: false,
                stockKeepingUnits: [],
                item: {
                    id: defaultGuid,
                    name: '',
                    description: '',
                    brand: '',
                    height: 0.00,
                    width: 0.00,
                    depth: 0.00,
                    weight: 0.00,
                    categoryID: '',
                    ean: null,
                    selazarUniqueID: null,
                    jan: null,
                    upc: null,
                    isbn: null,
                    batch: false,
                    mpn: null,
                    countryCodeOfManufacture: '',
                    commodityCode: '',
                    itemImages: []
                },
                currentPrice:
                {
                    id: defaultGuid,
                    companyItemID: defaultGuid,
                    price: 0.00,
                    active: true
                },
                noBarcode: false,
                dropzoneEnabled: true
            },
            noPackagingNeeded: true
        };
    }

    async componentDidMount() {
        await this.getCompanyItem();
        this.setState({ loading: false });
    }

    getCompanyItem = () => {
        const { companyItemID } = this.state;
        return GET(Endpoints.STOCK.GET.BY_ID + companyItemID)
            .then(response => response.json())
            .then(data => {
                const companyItem = extractData(data);
                const warning = companyItem ? null : "No product available."
                this.setState({ companyItem: companyItem, warning: warning });
            });
    }

    handlePackagingInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "isPrePacked") {
            if (value === "true") {
                this.setState(prevState => ({
                    noPackagingNeeded: true,
                    companyItem: {
                        ...prevState.companyItem,
                        isPrePacked: true,
                        requiresPaperPackaging: false,
                        requiresBoxPackaging: false,     
                    }
                }));
            } else {
                this.setState(prevState => ({
                    companyItem: {
                        ...prevState.companyItem,
                        isPrePacked: false
                    }
                }));
            }
        }

        if (name === "requiresBoxPackaging") {
            this.setState(prevState => ({
                companyItem: {
                    ...prevState.companyItem,
                    requiresBoxPackaging: value === "true" ? true : false,
                }
            }));
        }

        if (name === "requiresPaperPackaging" && value === "true") {
            this.setState(prevState => ({
                noPackagingNeeded: false,
                companyItem: {
                    ...prevState.companyItem,
                    requiresPaperPackaging: true
                }
            }));
        }

        if (name === "noPackagingNeeded" && value === "true") {
            this.setState(prevState => ({
                noPackagingNeeded: true,
                companyItem: {
                    ...prevState.companyItem,
                    requiresPaperPackaging: false
                }
            }));
        }

        if (name === "isFragile") {
            this.setState(prevState => ({
                companyItem: {
                    ...prevState.companyItem,
                    isFragile: value === "true" ? true : false,
                }
            }));
        }
    }

    handleSubmit = async () => {
        this.setState({ loading: true });
        const { companyItem } = this.state;
        this.submitted = true;

        return await PUT(Endpoints.STOCK.PUT.UPDATE, companyItem)
            .then(response => response.json()).then((result) => {
                if (!result.error) {
                    this.setState({ loading: false, success: true, warning: null });
                } else {
                    this.setState({ loading: false, success: false, warning: result.message });
                }
            });
    }

    checkPackagingNeeded = (noPackagingNeeded, companyItem) => {
        if (noPackagingNeeded && !companyItem.requiresPaperPackaging) {
            return true;
        }

        return false;
    }

    render() {
        const { companyItem, loading, warning, success, noPackagingNeeded, pageIndex } = this.state;

        return (
            <React.Fragment>
                <ProductHeader activeKey="Products" headerClass="mb-0" />
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Link to={`/supplier/products/edit/${companyItem.id}/${pageIndex}`} className="link-button">
                                <Button variant="link" className="button-nav-return p-0 mb-3"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Product</Button>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        {loading ? <LoadingBar /> :
                            <React.Fragment>
                                <Col sm={12} md={6}>
                                    <h5>Packaging Preferences</h5>
                                    {warning && <ErrorAlert errorMessage={warning} />}
                                    {success && <SuccessAlert successMessage="Product successfully updated." />}
                                    <div className="mt-3">
                                        <FormLabel>Is this product pre-packed?</FormLabel>
                                        <div className="form-input-description">
                                            <p>A pre-packed product must be provided in an already shippable container. You may be charged a packing charge if an order contains multiple products.</p>
                                        </div>
                                        <FormGroup className="custom-control custom-radio">
                                            <input className="custom-control-input" type='radio' id='radio-isPrePacked-yes' name='isPrePacked' value='true'
                                                checked={companyItem.isPrePacked === true} onChange={this.handlePackagingInputChange} />
                                            <FormLabel className="custom-control-label" htmlFor="radio-isPrePacked-yes">Yes, it is pre-packed</FormLabel>
                                        </FormGroup>
                                        <FormGroup className="custom-control custom-radio">
                                            <input className="custom-control-input" type='radio' id='radio-isPrePacked-no' name='isPrePacked' value='false'
                                                checked={companyItem.isPrePacked === false} onChange={this.handlePackagingInputChange} />
                                            <FormLabel className="custom-control-label" htmlFor="radio-isPrePacked-no">No, I need packaging</FormLabel>
                                        </FormGroup>
                                    </div>

                                    {!companyItem.isPrePacked ?
                                        <React.Fragment>
                                            <div className="mt-3">
                                                <FormLabel>Does this product require box packaging?</FormLabel>
                                                <FormGroup className="custom-control custom-radio">
                                                    <input className="custom-control-input" type='radio' id='radio-requiresBoxPackaging-yes' name='requiresBoxPackaging' value='true'
                                                        checked={companyItem.requiresBoxPackaging === true} onChange={this.handlePackagingInputChange} />
                                                    <FormLabel className="custom-control-label" htmlFor="radio-requiresBoxPackaging-yes">Yes, it requires box packaging</FormLabel>
                                                </FormGroup>
                                                <FormGroup className="custom-control custom-radio">
                                                    <input className="custom-control-input" type='radio' id='radio-requiresBoxPackaging-no' name='requiresBoxPackaging' value='false'
                                                        checked={companyItem.requiresBoxPackaging === false} onChange={this.handlePackagingInputChange} />
                                                    <FormLabel className="custom-control-label" htmlFor="radio-requiresBoxPackaging-no">No, box packaging is not needed</FormLabel>
                                                </FormGroup>
                                            </div>
                                            <div className="mt-3">
                                                <FormLabel>Please select your preferred packaging</FormLabel>
                                                <FormGroup className="custom-control custom-radio">
                                                    <input className="custom-control-input" type='radio' id='radio-requiresPaperPackaging' name='requiresPaperPackaging' value='true'
                                                        checked={companyItem.requiresPaperPackaging === true} onChange={this.handlePackagingInputChange} />
                                                    <FormLabel className="custom-control-label" htmlFor="radio-requiresPaperPackaging">Paper packaging</FormLabel>
                                                </FormGroup>
                                                <FormGroup className="custom-control custom-radio">
                                                    <input className="custom-control-input" type='radio' id='radio-noPackagingNeeded' name='noPackagingNeeded' value='true'
                                                        checked={this.checkPackagingNeeded(noPackagingNeeded, companyItem)} onChange={this.handlePackagingInputChange} />
                                                    <FormLabel className="custom-control-label" htmlFor="radio-noPackagingNeeded">No packaging needed</FormLabel>
                                                </FormGroup>
                                            </div>
                                        </React.Fragment> : null}

                                    <div className="mt-3">
                                        <FormLabel>Is the product fragile?</FormLabel>
                                        <FormGroup className="custom-control custom-radio">
                                            <input className="custom-control-input" type='radio' id='radio-isFragile-yes' name='isFragile' value='true'
                                                checked={companyItem.isFragile === true} onChange={this.handlePackagingInputChange} />
                                            <FormLabel className="custom-control-label" htmlFor="radio-isFragile-yes">Yes, it is fragile</FormLabel>
                                        </FormGroup>
                                        <FormGroup className="custom-control custom-radio mb-0">
                                            <input className="custom-control-input" type='radio' id='radio-isFragile-no' name='isFragile' value='false'
                                                checked={companyItem.isFragile === false} onChange={this.handlePackagingInputChange} />
                                            <FormLabel className="custom-control-label" htmlFor="radio-isFragile-no">No, it is not fragile</FormLabel>
                                        </FormGroup>
                                    </div>
                                    <Button variant="primary" className="float-right" onClick={this.handleSubmit}>Save</Button>
                                </Col>
                            </React.Fragment>}
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default EditPackagingPreferences;