import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { checkPermission } from '../../../Utilities';
import { WarningAlert } from '../../common/Alert';

const NO_BANK_ACCOUNT_MESSAGE = "You have not setup a direct debit. Please be aware that currently we can only accept UK bank accounts.";

const NoDirectDebit = ({ onLoadForm }) =>
    <Container fluid>
        <Row>
            <Col sm={12} md={6}>
                <WarningAlert warningTitle="No Direct Debit Linked" warningMessage={NO_BANK_ACCOUNT_MESSAGE} />
                {checkPermission('NewCard', <Button variant="primary" className="float-right mt-3" type="submit" onClick={onLoadForm}>Setup Direct Debit</Button>)}
            </Col>
        </Row>
    </Container>

export default NoDirectDebit;
