import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BULK_ORDER_SUB_NAV_LIST } from '../../../common/constants'
import Header from '../../../common/Header';
import BulkOrderTable from './BulkOrderTable';
import { GET, extractData } from '../../../../Consumer';
import LoadingBar from '../../../common/LoadingBar';
import Endpoints from '../../../common/Endpoints';
import { ErrorAlert } from '../../../common/Alert';
import BulkOrderListFilter from './BulkOrderListFilter';

const HEADER_KEY = "Unsuccessful";

class UnsuccessfulBulkOrders extends Component {

    state = {
        pageCount: 0,
        pageIndex: 1,
        bulkOrders: [],
        loading: true,
        filter: {
            purchaseOrderNumber: "",
            dateFrom: undefined,
            dateTo: undefined,
            sortBy: ""
        }
    };

    async componentDidMount() {
        await this.fetchUnsuccessfulBulkOrders();
        this.setState({ loading: false });
    }

    fetchUnsuccessfulBulkOrders = async () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(Endpoints.RETAILER.GET.BULK_ORDER_UNSUCCESSFUL);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)

        return GET(url)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const warning = data.bulkOrders === 0 ? "No unsuccessful bulk orders found" : null;
                this.setState({ bulkOrders: data.bulkOrders ? data.bulkOrders : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false, warning: warning });
            })
            .catch(error => console.log(error));
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.fetchUnsuccessfulBulkOrders);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.fetchUnsuccessfulBulkOrders);
        }
    }

    onStart = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true }, this.fetchUnsuccessfulBulkOrders);
        }
    }

    onEnd = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true }, this.fetchUnsuccessfulBulkOrders);
        }
    }

    onClear = async (e) => {
        e.preventDefault();
        this.setState({
            filter: { purchaseOrderNumber: "", dateFrom: null, dateTo: null, sortBy: "" },
            loading: true
        }, await this.fetchUnsuccessfulBulkOrders);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true }, await this.fetchUnsuccessfulBulkOrders);
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    handleViewBulkOrderClick = (id) => this.props.history.push({ pathname: `/retailer/bulkorder/bulkorderdetails/${id}`, state: { headerKey: HEADER_KEY } });

    render() {
        const { bulkOrders, loading, pageIndex, pageCount, warning } = this.state;

        return (
            <Fragment>
                {loading
                    ? <LoadingBar />
                    : <Fragment>
                        <Header title="Bulk Order" subNavList={BULK_ORDER_SUB_NAV_LIST} activeKey={HEADER_KEY} />
                        <Container fluid>
                            {warning && <Row><Col sm={12} md={6} className="px-2"><ErrorAlert errorMessage={warning} /></Col></Row>}
                            <Row className="my-4">
                                <Col>
                                    <BulkOrderListFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...this.state.filter} />
                                </Col>
                            </Row>
                            <BulkOrderTable bulkOrders={bulkOrders} handleViewBulkOrderClick={this.handleViewBulkOrderClick} showCancel={false} onNext={this.onNext} onPrevious={this.onPrevious} onStart={this.onStart} onEnd={this.onEnd} pageIndex={pageIndex} pageCount={pageCount} />
                        </Container>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default UnsuccessfulBulkOrders;