import React, { Fragment, useEffect, useState } from 'react';
import { Form, FormGroup, FormControl, FormLabel, Button } from 'react-bootstrap';
import { COUNTRY_LIST } from '../../../common/constants';
import { ErrorAlert } from '../../../common/Alert';
import Endpoints from '../../../common/Endpoints';
import { extractData, GET } from '../../../../Consumer';
import LoadingBar from '../../../common/LoadingBar';

const NO_SHIPPING_TEXT = "You have selected that this order does not require shipping therefore you do not need to complete this section.";
const NO_PREFERENCE_HELP_TEXT = "Selecting 'No preference' will use your general courier preferences.";
const PREFERENCE_HELP_TEXT = "Selecting a service time will overrule the service times in your general courier preferences for this bulk order only. If we cannot find an option for the service selection you have made, we will return the quickest option we have.";
const NO_SHIPPING_AVAILABLE_TEXT = "Sorry, no shipping results have been found for this order. You can return to previous steps and alter the bulk pick, or contact our customer service team on 020 39507860 for alternative options.";
const NO_SERVICE_TIME_OPTIONS = 'No service time options found. Please contact customer service.';

const serviceTimesLabels = {
    noPreference: 'No preference, cheapest option',
    TwentyFourPreTenThirty:'24 hours (pre-10:30)',
    TwentyFourPreNoon: '24 hours (pre-noon)',
    TwentyFour: '24 hours',
    OneToTwo: '1-2 days',
    FortyEight: '48 hours',
    TwoToThree: '2-3 days',
    SeventyTwo: '72 hours',
    ThreeToFive: '3-5 days',
    FiveToTen: '5-10 days'
}

const findCountryName = (code) => COUNTRY_LIST.find(c => c.code === code).name;

const ShippingResults = ({ shippingService, toggleShippingService, validation, showDifferentServiceMessage, originalServiceTimePreference }) =>
    <Fragment>
        <h6>Shipping Results</h6>
        <React.Fragment>
            {showDifferentServiceMessage
                ? <p>Sorry, we could not find a {originalServiceTimePreference} shipping option, however we can provide the following shipping option:</p>
                : null
            }
            <div className="form-check-detailed mb-3">
                <FormGroup className="custom-control-center custom-checkbox mb-2">
                    <input id={shippingService.id} className="custom-control-input" type="checkbox" onChange={toggleShippingService} checked={shippingService.selected} />
                    <FormLabel className="custom-control-label" htmlFor={shippingService.id}><span>{`${shippingService.name}, ${shippingService.service}, £${shippingService.cost}`}</span></FormLabel>
                </FormGroup>
            </div>
            <span className="text-danger">{validation.selected.message}</span>
        </React.Fragment>
    </Fragment>

const ShippingOptions = ({ shippingServiceTime, handleInputChange, fetchShippingOption, country }) => {

    const [serviceTimes, setServiceTimes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
      fetchAvailableServiceTimes();
    }, [])
    
    const fetchAvailableServiceTimes = async () => {
        setLoading(true);
        let url = new URL(Endpoints.INTERNAL.GET.BULK_ORDER_AVAILABLE_SERVICE_TIMES);
        url.searchParams.append("country", country)

        try {
            const response = await GET(url);
            if (response.ok) {
                const data = extractData(await response.json());
                setServiceTimes(data);
                return;
            }

            setError(true);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            {loading ? <LoadingBar /> :
                <>
                    <h6>Shipping Options</h6>
                    <p><strong>Please select a service time preference</strong></p>
                    <div className="form-input-description">
                        <p>{NO_PREFERENCE_HELP_TEXT}</p>
                        <p>{PREFERENCE_HELP_TEXT}</p>
                    </div>
                    <FormGroup>
                        {
                            error ? <ErrorAlert errorTitle='Error' errorMessage={ NO_SERVICE_TIME_OPTIONS } /> 
                            :
                            <>
                                <FormGroup className="custom-control custom-radio mb-0">
                                    <FormControl className="custom-control-input" type='radio' id='shippingServiceTime-noPreference' name='shippingServiceTime' value={'noPreference'}
                                        checked={shippingServiceTime === 'noPreference'} onChange={handleInputChange} />
                                    <FormLabel className="custom-control-label" htmlFor="shippingServiceTime-noPreference">No preference, cheapest option</FormLabel>
                                </FormGroup>
                                { serviceTimes.length > 0 && serviceTimes.map(serviceTime => 
                                    <FormGroup key={ serviceTime } className="custom-control custom-radio mb-0">
                                        <FormControl className="custom-control-input" type='radio' id={`shippingServiceTime-${serviceTime}`} name='shippingServiceTime' value={ serviceTime }
                                            checked={ shippingServiceTime === serviceTime } onChange={ handleInputChange } />
                                        <FormLabel className="custom-control-label" htmlFor={`shippingServiceTime-${serviceTime}`}>{ serviceTimesLabels[serviceTime] }</FormLabel>
                                    </FormGroup>
                                )}
                            </>
                        }
                    </FormGroup>
                    <FormGroup>
                        <Button variant="secondary" onClick={ fetchShippingOption }>Find Shipping Options</Button>
                    </FormGroup>
                </>
            }   
        </>
    )
    
}

const ShippingAddress = ({ destinationAddress }) =>
    <div className="mb-4">
        <h6>Shipping Address</h6>
        <p className="mb-1">{destinationAddress.addressLine1}</p>
        {destinationAddress.addressLine2
            ? <p className="mb-1">{destinationAddress.addressLine2}</p>
            : null
        }
        <p className="mb-1">{destinationAddress.town}</p>
        <p className="mb-1">{destinationAddress.county}</p>
        <p className="mb-1">{findCountryName(destinationAddress.country)}</p>
        <p>{destinationAddress.postcode}</p>
    </div>

const BulkOrderShippingResults = ({ shippingRequired, destinationAddress, shippingServiceTime, handleInputChange, fetchShippingOption, shippingService, toggleShippingService, validation, showDifferentServiceMessage, originalServiceTimePreference, noCourierError }) =>
    <Form>
        <fieldset>
            <h5 className="mb-4">Shipping Results</h5>
            {shippingRequired === "true"
                ? <Fragment>
                    <ShippingAddress destinationAddress={destinationAddress} />
                    <ShippingOptions country={ destinationAddress.country } shippingServiceTime={shippingServiceTime} handleInputChange={handleInputChange} fetchShippingOption={fetchShippingOption} />
                    {shippingService && shippingService.id !== ""
                        ? <ShippingResults shippingService={shippingService} toggleShippingService={toggleShippingService} validation={validation} showDifferentServiceMessage={showDifferentServiceMessage} originalServiceTimePreference={originalServiceTimePreference} />
                        : null
                    }
                    {noCourierError &&
                        <Fragment>
                            <h6>Shipping Results</h6>
                            <ErrorAlert errorMessage={NO_SHIPPING_AVAILABLE_TEXT} />
                        </Fragment>
                    }
                </Fragment>
                : <p>{NO_SHIPPING_TEXT}</p>
            }
        </fieldset>
    </Form>

export default BulkOrderShippingResults;