import React from 'react';
import { GET,POST, extractData } from '../../../../Consumer';
import { Table, Button } from 'react-bootstrap';
import { toLocalDateString } from '../../../../Utilities';
import NoResults from '../../../common/Tables/NoResults';
import Endpoints from "../../../common/Endpoints";
import { isNullOrEmptyGuid } from '../../../../Utilities';

const getType = (invoiceType) => {
    switch (invoiceType) {
        case "STOCK_HOLDING":
            return <span className="text-status-green">Stock Holding</span>
        case "STOCK_PROCESSING":
            return <span className="text-status-yellow">Stock Processing</span>
        case "STOCK_CONSIGNMENT_PROCESSING":
            return <span className="text-status-yellow">Stock Consignment Processing</span>
        case "STOCK_REPLENISHMENT":
            return <span className="text-status-grey">Stock Replenishment</span>
        case "ORDERS":
            return <span className="text-status-blue">Orders</span>
        case "RETURNS":
            return <span className="text-status-aqua">Returns</span>
        case "AD_HOC_CHARGE":
            return <span className="text-status-orange">Additional Cost</span>
        case "CREDIT_NOTE":
            return <span className="text-status-pink">Credit Note</span>
        case "BULK_ORDER":
            return <span className="text-status-purple">Bulk Order</span>
        default:
            return <span className="text-status-grey">{invoiceType}</span>
    }
}

const getNewInvoiceLink = (invoiceID) =>{

    if (!isNullOrEmptyGuid(invoiceID)) {
        
        const url = new URL(Endpoints.INVOICES.POST.REGENERATE_INVOICE_LINK + invoiceID) ;
        
        return POST(url)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {                
                if(result.data != null){                
                    const data = extractData(result) || [];
                    window.open(data, '_blank');  
                }         
                              
            })
            .catch(error => console.log(error));
    }
}


const InvoiceRow = ({ invoice }) =>
    <tr>
        <td>{toLocalDateString(invoice.invoiceDate)}</td>
        <td>{invoice.invoiceReferenceNumber}</td>
        <td>{getType(invoice.invoiceType)}</td>
        <td>{invoice.currencyAbbreviation}</td>
        <td>{invoice.formattedTotal}</td>
        {invoice.link != null 
            ? <td className="text-center py-2"><Button variant="secondary" size="sm" onClick={() => window.open(invoice.link, '_blank')}>View Invoice</Button></td> 
            : <td className="text-center py-2">                
                <Button variant="secondary" size="sm" onClick={() => getNewInvoiceLink(invoice.id)}>View Invoice</Button></td> 
        }
        
    </tr>

const InvoiceTable = ({ invoices }) =>
    <Table striped bordered size="sm" className={!invoices.length ? "table-empty" : ""}>
        <thead>
            <tr>
                <th>Date</th>
                <th>Invoice Number</th>
                <th>Invoice Type</th>
                <th>Currency</th>
                <th>Total</th>
                <th></th>
            </tr>
        </thead>
        <tbody>        
            {invoices.length
                ? invoices.map(invoice => <InvoiceRow key={invoice.id} invoice={invoice} />)
                : <NoResults colSpan="5" text="No invoices available" />
            }
        </tbody>
    </Table>

export default InvoiceTable;