import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Button, Col, Form, FormGroup, Row, Container } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { POST_FILE, extractData, GET_ASYNC } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import ItemUploadPdf from '../Selazar-Item-Upload-Instructions.pdf';
import ItemUploadExample from '../ItemUploadExample.csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faDownload, faFileUpload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ErrorAlert, WarningAlert, SuccessAlert } from '../../common/Alert';
import MissingDetails from '../../common/MissingDetails';
import ProductHeader from './ProductHeader'

const MISSING_DETAILS_MESSAGE = "You need to complete the following before you are able to upload products.";

const getUploadResults = (results, retailerReturnConfiguration, redirect) => results.length ? results.map(result => result && getUploadResult(result, retailerReturnConfiguration, redirect)) : "";
const getPickFromPalletResults = (results) => results.length ? results.map(result => result && getPickFromPalletResult(result)) : "";

const UploadForm = ({results, retailerReturnConfiguration, files, error, message, onSubmit, removeFile, getRootProps, getInputProps, redirect}) =>
    <Form onSubmit={onSubmit}>
        <FormGroup>
            <Fragment>
                <div {...getRootProps({ className: "file-upload text-center" })}>
                    <input {...getInputProps()} />
                    <FontAwesomeIcon icon={faFileUpload} className="file-upload-icon" />
                    <p className="file-upload-primarytext my-2">Drag and drop your CSV file(s)</p>
                    <p className="file-upload-secondarytext my-2">or</p>
                    <Button variant="secondary">Browse</Button>
                </div>
                <div className="file-list mt-4">
                    <ul>
                        {files.length 
                            ? files.map((f, i) => <li key={i}><FontAwesomeIcon icon={faFile} className="form-fileicon" />{f.name} - {f.size} bytes
                            <FontAwesomeIcon icon={faTrashAlt} className="form-fileicon-action float-right" index={i} onClick={removeFile} /></li>)
                            : ""}
                    </ul>
                </div>
            </Fragment>
        </FormGroup>
        {getUploadResults(results, retailerReturnConfiguration, redirect)}
        {getPickFromPalletResults(results)}
        {error && message && <ErrorAlert errorMessage={message} />}
        <FormGroup className="float-right mt-2">
            <Button variant="primary" type="submit">Upload File(s)</Button>
        </FormGroup>
    </Form>

const getUploadResult = (result, retailerReturnConfiguration, redirect) => {
    if (result.isError) {
        const errors = result.message ? result.message.split(',') : [];

        if (result.isMalformed) {
            return <ErrorAlert errorMessage={`Formatting error, ${result.fileName} error detected with formatting`} />;
        } else if (result.message && result.isError && errors.length > 0) {
            const errorMessage = <Fragment>
                <p>Upload Error</p>
                {errors.map((e, i) => <p key={i}>{e}</p>)}
            </Fragment>;
            return <ErrorAlert errorMessage={errorMessage} />;
        } else if (result.message && result.isError) {
            return <ErrorAlert errorMessage={`Upload error, ${result.message}`} />;
        } else {
            const errorMessage = <Fragment>
                <p>Upload error</p>
                <p>{result.fileName}: {result.successfulUploads} Items uploaded successfully.</p>
                <p>{result.errorUploads} Items could not be uploaded.</p>
                {result.errorLines.length && <Fragment>
                    <p>Errors on lines:</p>
                    <ul>
                        {result.errorLines.map((e, i) => <li key={i}>{e}</li>)}
                    </ul>
                </Fragment>}
            </Fragment>;

            return <ErrorAlert errorMessage={errorMessage} />;
        }
    } else {
        return retailerReturnConfiguration && retailerReturnConfiguration.enabled 
            ? redirect()
            : <SuccessAlert successMessage={`${result.fileName} uploaded successfully.`} />;
    }
}

const getPickFromPalletResult = (result) => {
    if (result.isError) return null;
    const fileName = result.data.fileName;
    const lines = result.data.lines.filter(l => l.pickFromPallet === true);
    if (!lines.length) return null;

    const warningMessage = <Fragment>
        <p>We have detected that you have added the following large product(s) to {fileName}:</p>
        <ul>
            {lines.map((l, i) => <li key={`${l.name}-${i}`}><span>{l.name} {l.skuCode ? ` - SKU: ${l.skuCode}` : ""}</span></li>)}
        </ul>
        <p>Any large products will be kept on pallets and picked directly from them, providing you the most cost-effective storage.</p>
    </Fragment>;

    return <WarningAlert warningTitle="Product Size Information" warningMessage={warningMessage} />;
}

const UploadCompanyItemCsv = ({ location: { state }, history }) => {

    //#region State

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [hasValidCardDetails, setHasValidCardDetails] = useState(false);
    const [hasValidWarehouse, setHasValidWarehouse] = useState(false);
    const [results, setResults] = useState([]);
    const [files, setFiles] = useState([]);
    const [message, setMessage] = useState(null);
    const [retailerReturnConfiguration, setRetailerReturnConfiguration] = useState(null);

    const {getRootProps, getInputProps} = useDropzone({
        maxFiles: 1,
        maxSize: 999999,
        accept: ".csv",
        onDrop: (acceptedFiles, rejectedFiles) => onDrop(acceptedFiles, rejectedFiles)
      });
    
    //#endregion

    //#region API

    const validateCardDetails = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.FINANCE.CARD.GET.HAS_VALID_DETAILS);

            if (response?.ok) {
                const result = await response.json();
                const data = extractData(result);
                setHasValidCardDetails(data)
            } else {
                setHasValidCardDetails(false)
            }
        } catch (error) {
            setHasValidCardDetails(false)
        }
    }

    const validateWarehouse = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.WAREHOUSE.GET.SELECTION);

            if (response?.ok) {
                const result = await response.json();
                const data = extractData(result);
                setHasValidWarehouse(data.id !== null && data.id !== "");
            } else {
                setHasValidWarehouse(false);
            }
        } catch (error) {
            setHasValidWarehouse(false);
        }
    }

    const fetchRetailerReturnConfiguration = async () => {
        try {
          const response = await GET_ASYNC(Endpoints.RETURNS.GET.CONFIGURATION);
    
          if (response?.ok) {
            const data = await response.json();
            setRetailerReturnConfiguration(extractData(data));
          } else {
            setRetailerReturnConfiguration({});
          }
        } catch (error) {
          setRetailerReturnConfiguration({});
        }
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (files.length) {
                let formData = new FormData();
                files.forEach((f, i) => formData.append(`files`, files[i], f.name));
                const response = await POST_FILE(Endpoints.STOCK.POST.UPLOAD_ITEM_CSV, formData)

                if (response?.ok) {
                    const result = await response.json();
                    setFiles([]);
                    setResults(extractData(result));
                    setError(false);
                    setMessage("Item records successfully uploaded.");
                } else {
                    setFiles([]);
                    setError(true);
                    setMessage("An error occurred, please try again later.");
                }
            } else {
                setResults([]);
                setError(true);
                setMessage("No file selected, Ensure a file is selected for upload.");
            }
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const fetchData = useCallback(async () => {
        setLoading(true);
        await Promise.all([validateCardDetails(), validateWarehouse(), fetchRetailerReturnConfiguration()])
        setLoading(false);
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData]);

    //#endregion

    //#region Controls

    const removeFile = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const idx = e.target.getAttribute('index');
        const updatedFiles = [
            ...files.slice(0, idx),
            ...files.slice(idx + 1)
        ];

        setFiles(updatedFiles);
    }

    const onDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            setResults([])
            setError(true);
            setMessage("File could not be uploaded. Please ensure the file type is CSV and the file size is under 1MB." )
        }

        var updatedFiles = files.concat(acceptedFiles);
        setFiles(updatedFiles);
    }

    const handleInactiveProductsRedirect = () => {
        history.push({ pathname: "/supplier/products/inactive" });
      };
    
    //#endregion

    //#region Render

    return (
        loading
            ? <LoadingBar />
            : <Fragment>
                <ProductHeader activeKey="Upload Product CSV" />
                <Container fluid>
                    <Row>
                        <Col sm={12} md={6}>
                            {hasValidWarehouse && hasValidCardDetails
                                ? <Fragment>
                                    <h4>CSV Example and Documentation</h4>
                                    <p><strong>Before you upload please read the following information.</strong></p>
                                    <p>Please use the following CSV example and Documentation to learn how to bulk upload Products.</p>
                                    <div className="file-list">
                                        <ul>
                                            <li className="file-list-file"><FontAwesomeIcon icon={faFile} className="form-fileicon" />Product CSV example
                                                <span className="text-status-new ml-3" >New!</span><a href={ItemUploadExample} target='_blank'
                                                    rel="noopener noreferrer" className="float-right" alt='Item CSV example - opens in a new window'><FontAwesomeIcon
                                                        className="form-fileicon-download" icon={faDownload} /></a>
                                            </li>
                                            <li className="file-list-file"><FontAwesomeIcon icon={faFile} className="form-fileicon" />Product CSV instructions
                                                <span className="text-status-new ml-3" >New!</span><a href={ItemUploadPdf} target='_blank'
                                                    rel="noopener noreferrer" className="float-right" alt='Item CSV instructions - opens in a new window'><FontAwesomeIcon
                                                        className="form-fileicon-download" icon={faDownload} /></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <p>Each row in the CSV represents a specific SKU of a product. The EAN is used as the primary identifier for your products,
                                            you can upload multiple SKUs using the same EAN. </p>
                                    <p>If you wish to upload images for you products this must be done from the Edit Product page after the upload is complete.</p>
                                    <p>If your product does not have a barcode leave the EAN field blank and we will generate a Selazar Unique ID for you.
                                            If in doubt or you have any questions about this please contact us via support@selazar.com.</p>
                                    <UploadForm 
                                        results={results} 
                                        retailerReturnConfiguration={retailerReturnConfiguration} 
                                        files={files} 
                                        error={error} 
                                        message={message} 
                                        onSubmit={onSubmit} 
                                        removeFile={removeFile}
                                        getRootProps={getRootProps} 
                                        getInputProps={getInputProps} 
                                        redirect={handleInactiveProductsRedirect}/>
                                </Fragment>
                                : <MissingDetails message={MISSING_DETAILS_MESSAGE} showWarehouse={!hasValidWarehouse} showCard={!hasValidCardDetails} />
                            }
                        </Col>
                    </Row>
                </Container>
            </Fragment>
    );

    //#endregion
}

export default UploadCompanyItemCsv;