import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { extractData, GET } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { toLocalDateString, hasPermission } from '../../../Utilities';
import { DefaultCard, ListCard, PieChartCard, BarCard } from '../../common/DashboardCards';

const chartColours = ['#A071FA', '#38D9A9', '#4DABF7'];
const barChartColour = "#229BD8";

const getRegionLabel = (region) => region === "RestOfWorld" ? "Rest of World" : region === "EU" ? "Europe" : "UK";

const extractAreaData = (areaData) => {
    const labels = areaData.map(area => getRegionLabel(area.region));
    const data = areaData.map(area => area.total);
    const areaTotals = {
        labels: labels,
        data: data,
        loading: false
    }
    return areaTotals;
}

const extractFulfilmentData = (fulfilmentData) => {
    const labels = fulfilmentData.map(fulfilment => fulfilment.status);
    const data = fulfilmentData.map(fulfilment => fulfilment.count);
    const fulfilmentTotals = {
        labels: labels,
        data: data,
        loading: false
    }
    return fulfilmentTotals;
}

const extractOrderTotalsByDayData = (orderTotalData) => {
    const labels = orderTotalData.map(orderTotal => toLocalDateString(orderTotal.date));
    const data = orderTotalData.map(orderTotal => orderTotal.total);
    const orderTotalsByDay = {
        labels: labels,
        data: data,
        loading: false
    }
    return orderTotalsByDay;
}

class Dashboard extends Component {

    state = {
        awaitingFulfilmentCount: {
            count: 0,
            loading: true
        },
        orderCount: {
            count: 0,
            status: "NO_CHANGE",
            loading: true
        },
        orderAverage: {
            averageCost: 0.00,
            status: "NO_CHANGE",
            loading: true
        },
        popularOrders: {
            data: [],
            loading: true
        },
        areaTotals: {
            labels: [],
            data: [],
            loading: true
        },
        fulfilmentTotals: {
            labels: [],
            data: [],
            loading: true
        },
        orderTotalsByDay: {
            labels: [],
            data: [],
            loading: true
        }
    }

    componentDidMount() {
        this.getAwaitingFulfilmentCount();
        this.getOrderCount();
        this.getOrderAverage();
        this.getPopularOrders();
        this.getDeliveryDestinations();
        this.getFulfilmentTotals();
        this.getOrderTotalsByDate();
    }

    getAwaitingFulfilmentCount = () =>
        GET(Endpoints.ORDERS.GET.AWAITING_FULFILMENT_ORDER_COUNT)
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ awaitingFulfilmentCount: { count: data, loading: false } });
                }
            }, err => {
                console.error(err);
            })
            .catch(error => console.log(error));

    getOrderCount = () => {
        return GET(Endpoints.ORDERS.GET.ORDER_COUNT)
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ orderCount: { ...data, loading: false } });
                }
            }, err => {
                console.error(err);
            })
            .catch(error => console.log(error));
    }

    getOrderAverage = () => {
        return GET(Endpoints.ORDERS.GET.ORDER_AVERAGE)
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ orderAverage: { ...data, loading: false } });
                }
            }, err => {
                console.error(err);
            })
            .catch(error => console.log(error));
    }

    getPopularOrders = () => {
        return GET(Endpoints.ORDERS.GET.POPULAR_ORDERS)
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ popularOrders: { data: data, loading: false } });
                }
            }, err => {
                console.error(err);
            })
            .catch(error => console.log(error));
    }

    getDeliveryDestinations = () => {
        return GET(Endpoints.ORDERS.GET.TOTALS_BY_AREA)
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    const areaTotals = extractAreaData(data);
                    this.setState({ areaTotals: areaTotals });
                }
            }, err => {
                console.error(err);
            })
            .catch(error => console.log(error));
    }

    getFulfilmentTotals = () => {
        return GET(Endpoints.ORDERS.GET.FULFILMENT_TOTALS)
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    const fulfilmentTotals = extractFulfilmentData(data);
                    this.setState({ fulfilmentTotals: fulfilmentTotals });
                }
            }, err => {
                console.error(err);
            })
            .catch(error => console.log(error));
    }

    getOrderTotalsByDate = () => {
        return GET(Endpoints.ORDERS.GET.TOTALS_BY_DATE)
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    const orderTotalsByDay = extractOrderTotalsByDayData(data);
                    this.setState({ orderTotalsByDay: orderTotalsByDay });
                }
            }, err => {
                console.error(err);
            })
            .catch(error => console.log(error));
    }

    render() {
        const { awaitingFulfilmentCount, orderCount, orderAverage, popularOrders, areaTotals, fulfilmentTotals, orderTotalsByDay } = this.state;

        const deliveryDestinationData = {
            labels: areaTotals.labels,
            datasets: [{
                data: areaTotals.data,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartColours
            }]
        };

        const fulfilmentData = {
            labels: fulfilmentTotals.labels,
            datasets: [{
                data: fulfilmentTotals.data,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartColours
            }]
        };

        const orderData = {
            labels: orderTotalsByDay.labels,
            datasets: [
                {
                    backgroundColor: barChartColour,
                    data: orderTotalsByDay.data
                }
            ]
        };

        const showCosts = hasPermission("ViewInvoice");

        return (
            <Container fluid>
                <h1 className="page-title">Dashboard</h1>
                <Row>
                    <Col sm="12" md={showCosts ? 4 : 6} className="mt-4">
                        <DefaultCard title="Orders Awaiting Fulfilment" count={(awaitingFulfilmentCount.count).toLocaleString()} loading={awaitingFulfilmentCount.loading} text="orders currently awaiting fulfilment" />
                    </Col>
                    <Col sm="12" md={showCosts ? 4 : 6} className="mt-4">
                        <DefaultCard title="Total Number of Orders" count={(orderCount.count).toLocaleString()} loading={orderCount.loading} text="in the past 28 days" status={orderCount.status} statusText="from the previous 28 days" />
                    </Col>
                    {showCosts && <Col sm="12" md="4" className="mt-4">
                        <DefaultCard title="Average Order Cost" count={`£${parseFloat(orderAverage.averageCost).toFixed(2)}`} loading={orderAverage.loading} text="in the past 28 days" status={orderAverage.status} statusText="from the previous 28 days" />
                    </Col>}
                </Row>

                <Row>
                    <Col sm="12" md="4" className="mt-4">
                        <ListCard title="Most Popular Products" data={popularOrders.data} loading={popularOrders.loading} />
                    </Col>
                    <Col sm="12" md="8">
                        <Row>
                            <Col sm="12" md="6" className="mt-4">
                                <PieChartCard title="Delivery Destinations" data={areaTotals.data.reduce((a, b) => a + b, 0) > 0 ? deliveryDestinationData : null} loading={areaTotals.loading} />
                            </Col>
                            <Col sm="12" md="6" className="mt-4">
                                <PieChartCard title="Today's Fulfilment Process" data={fulfilmentTotals.data.reduce((a, b) => a + b, 0) > 0 ? fulfilmentData : null} loading={fulfilmentTotals.loading} />
                            </Col>
                            <Col md="12" className="mt-4">
                                <BarCard title="Order Totals for the Past 28 Days" data={orderTotalsByDay.data.reduce((a, b) => a + b, 0) > 0 ? orderData : null} loading={orderTotalsByDay.loading} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Dashboard;