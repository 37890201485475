import React, { Component } from 'react';
import { FormGroup, FormLabel, FormControl, Row, Col, Button, Container, Form, Modal } from 'react-bootstrap';
import ReactDropzone from 'react-dropzone';
import update from 'immutability-helper';
import { Link } from 'react-router-dom';
import { GET, GET_ASYNC, PUT, POST_FILE, DELETE, extractData, POST } from '../../../../../Consumer';
import { defaultGuid } from '../../../../../Utilities';
import Endpoints from '../../../../common/Endpoints';
import LoadingBar from '../../../../common/LoadingBar';
import FormValidator from '../../../../common/FormValidator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { SuccessAlert, ErrorAlert, WarningAlert } from '../../../../common/Alert';
import Breadcrumbs from '../../../../common/Breadcrumbs';

import { Prompt } from 'react-router'

import '../EditProduct.scss'
import ProductHeader from '../../ProductHeader';

const NO_BARCODE_TITLE_VALIDATION = "No-Barcode Product SKU Variants";

const NO_BARCODE_IMAGE_REQUIRED = "You are required to upload a product image for each SKU variant.";
const NO_BARCODE_DELETE_IMAGE = "Unable to remove image from a no-barcode product.";
const NO_BARCODE_DELETE_SKU = "Unable to delete sku from a no-barcode product.";

const RETURNS_TITLE = "Returns Active";
const RETURNS_DELETE_IMAGE = "Unable to remove image while returns are active.";

const breadcrumbs = (overviewLink, identifiersLink, currentText) => [
    { text: "Products", link: "/supplier/products" },
    { text: "Product Overview", link: overviewLink },
    { text: "Unique Identifiers", link: identifiersLink },
    { text: currentText, link: null }
];

class EditSKU extends Component {
    constructor(props) {
        super(props);
        this.skuValidator = new FormValidator([
            {
                field: 'code',
                method: 'isEmpty',
                validWhen: false,
                message: 'Code is required'
            },
            {
                field: 'description',
                method: 'isEmpty',
                validWhen: false,
                message: 'Description is required'
            },
        ]);

        this.state = {
            currentSku: {
                id: defaultGuid,
                code: "",
                description: "",
                itemImageID: "",
                companyItemID: "",
                quantity: 0
            },
            companyItem: {},
            skuFiles: [],
            loading: true,
            skuDropzoneEnabled: true,
            currentSkuIndex: null,
            noPackagingNeeded: true,
            skuValidation: this.skuValidator.valid(),
            warning: "",
            isDirty: false,
            showDelete: false,
            isSkuInUse: false,
            showSkuInUse: false,
            showBarcodeImageWarning: false,
            showReturnsImageWarning: false,
            retailerReturnConfiguration: null
        }
    }

    async componentDidMount() {
        await Promise.all([
            this.getCompanyItem(),
            this.fetchRetailerReturnConfiguration()
        ]);
        if (this.props.match.params.skuIndex !== 'add') await this.getQuantityAndShow(this.props.match.params.skuIndex);
        this.setState({ loading: false });
    }

    getCompanyItem = (currentWarning = "") => {
        return GET(Endpoints.STOCK.GET.BY_ID + this.props.match.params.id)
            .then(response => response.json())
            .then(data => {
                const companyItem = extractData(data);
                const warning = companyItem ? currentWarning : "No product available."

                this.setState({ companyItem: companyItem, warning: warning });
            });
    }

    fetchRetailerReturnConfiguration = async () => {
        const response = await GET_ASYNC(Endpoints.RETURNS.GET.CONFIGURATION);
        if (response?.ok) {
          const data = await response.json();
          this.setState({ retailerReturnConfiguration: extractData(data)});
        }
    }

    checkSKU = (skuCode) => {
        return GET(new URL(encodeURIComponent(skuCode), Endpoints.STOCK.GET.PRODUCT_BUNDLE_SKU_CHECK))
            .then(response => response.json())
            .then(result => {
                this.setState({ isSkuInUse: result.data });
            });
    }

    getQuantityAndShow = async (idx) => {
        const { companyItem } = this.state;
        const sku = companyItem.stockKeepingUnits[idx];
        const skuDropzoneEnabled = sku.itemImageID ? false : true;

        this.setState({ currentSkuIndex: idx, currentSku: sku, skuDropzoneEnabled: skuDropzoneEnabled });
        await Promise.all([this.getTotalStockCount(companyItem.id, sku.code), this.checkSKU(sku.code)]);
    }

    getTotalStockCount = async (id, skuCode) => {
        const endpoint = new URL(id, Endpoints.STOCK.GET.TOTAL_STOCK_COUNT);
        if (skuCode !== null) endpoint.pathname += "/" + encodeURIComponent(skuCode);

        return await GET(endpoint)
            .then(raw => raw.json())
            .then(result => {
                const data = extractData(result);
                if (data === undefined) {
                    this.setState(prevState => ({
                        ...prevState,
                        currentSku: {
                            ...prevState.currentSku,
                            ...{ quantity: 0 }
                        }
                    }));
                }
                else {
                    this.setState(prevState => ({
                        ...prevState,
                        currentSku: {
                            ...prevState.currentSku,
                            ...{ quantity: data }
                        }
                    }));
                }
            });
    }

    handleInputChangeSKU = (e) => {
        const { name, value } = e.target;
        const { currentSku, isSkuInUse } = this.state;

        if (name === 'code') {
            if (!currentSku.quantity > 0 || !isSkuInUse) this.setStateOfInputChange(name, value);
        }
        else this.setStateOfInputChange(name, value);
    }

    setStateOfInputChange = (name, value) => {
        this.setState(prevState => ({
            ...prevState,
            currentSku: {
                ...prevState.currentSku,
                ...{ [name]: value }
            },
            isDirty: true
        }));
    }

    removeSKU = () => {
        const { currentSku, isSkuInUse } = this.state;

        if (currentSku.quantity > 0) alert(currentSku.quantity + " product(s) exist for this SKUCode - cannot remove");
        else if (isSkuInUse) this.setState({ showSkuInUse: true });
        else this.setState({ showDelete: true });
    }


    delete = () => {
        this.deleteSKU();
        this.clearSKUs();
    }

    deleteSKU = () => {
        let { companyItem } = this.state;
        const { currentSkuIndex } = this.state;

        if (currentSkuIndex) {
            companyItem = update(companyItem, { stockKeepingUnits: { $splice: [[currentSkuIndex, 1]] } });

            return PUT(Endpoints.STOCK.PUT.UPDATE, companyItem)
                .then(response => response.json()).then((result) => {
                    if (!result.error) {
                        this.getCompanyItem();
                        this.setState({ loading: false, success: true, warning: null, isDirty: false });
                        this.props.history.push({ pathname: `/supplier/products/edit/uniqueidentifiers/${companyItem.id}/${this.props.match.params.pageIndex}` });
                    } else {
                        this.setState({ loading: false, companyItem: companyItem, success: false, warning: result.message });
                    }
                });
        }
    }

    clearSKUs = () => {
        let { companyItem } = this.state;
        const { currentSku } = this.state;

        return POST(Endpoints.STOCK.POST.CLEAR_DELETED_SKU_STOCK_COUNTS + companyItem.id + "/" + currentSku.code)
            .then(response => response.json()).then((result) => {
                if (!result.error) {
                    this.setState({ loading: false, success: true, warning: null, isDirty: false });
                } else {
                    this.setState({ loading: false, success: false, warning: result.message });
                }
            });
    }

    onDropSku = (acceptedFiles, rejectedFiles) => {
        const skuDropzoneEnabled = (acceptedFiles.length === 0);


        if (rejectedFiles.length > 0) {
            alert("File could not be uploaded. Ensure file size is under 1MB.");
        }

        this.setState({ skuFiles: acceptedFiles, skuDropzoneEnabled: skuDropzoneEnabled });

        if (!skuDropzoneEnabled && rejectedFiles.length === 0) {
            this.setState({ loading: true });
            this.uploadSkuImage();
        }
    }

    removeFileSku = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const idx = e.target.getAttribute('index');
        const skuFiles = [
            ...this.state.skuFiles.slice(0, idx),
            ...this.state.skuFiles.slice(idx + 1)
        ];
        const skuDropzoneEnabled = (skuFiles.length === 0);

        this.setState({ skuFiles: skuFiles, skuDropzoneEnabled: skuDropzoneEnabled, isDirty: true });
    }

    uploadSkuImage = () => {
        const skuValidation = this.skuValidator.validate(this.state.currentSku);

        this.setState({ skuValidation: skuValidation, loading: skuValidation.isValid });
        this.skuSubmitted = true;

        if (this.state.skuFiles.length > 0) {
            this.setState({ loading: true });

            let formData = new FormData();
            this.state.skuFiles.forEach((f, i) => formData.append(`files`, this.state.skuFiles[i], f.name));

            return POST_FILE(Endpoints.STOCK.POST.UPLOAD_IMAGE + "/" + this.state.companyItem.itemID, formData)
                .then(r => r.json())
                .then(response => {
                    if (!response.error) {
                        const itemImage = extractData(response);
                        const skuDropzoneEnabled = itemImage.id ? false : true;

                        this.setState(prevState => ({
                            ...prevState,
                            currentSku: {
                                ...prevState.currentSku,
                                ...{
                                    itemImageID: itemImage.id,
                                    itemImage: itemImage
                                }
                            },
                            skuDropzoneEnabled: skuDropzoneEnabled,
                            loading: false,
                            error: false,
                            success: true,
                            message: "SKU Image saved successfully.",
                            skuFiles: [],
                            isDirty: true,
                            showBarcodeImageWarning: false
                        }), null);
                    }
                    else {
                        const skuDropzoneEnabled = (this.state.currentSku.itemImageID) ? true : false;
                        this.setState({ loading: false, error: true, message: response.message, skuFiles: [], skuDropzoneEnabled: skuDropzoneEnabled }, this.handleSkuSubmit);
                    }
                })
                .catch(error => console.log(error));

        } else {
            const skuDropzoneEnabled = (this.state.currentSku.itemImageID) ? true : false;
            this.setState({ loading: false, error: false, skuFiles: [], skuDropzoneEnabled: skuDropzoneEnabled }, this.handleSkuSubmit);
        }
    }

    deleteImageSku = (id) => {
        const { currentSku } = this.state;

        return DELETE(Endpoints.STOCK.DELETE.IMAGE_FOR_SKU + id + '/' + currentSku.id)
            .then(r => r.json())
            .then(response => {
                if (!response.error) {
                    this.setState(prevState => ({
                        ...prevState,
                        currentSku: {
                            ...prevState.currentSku,
                            ...{
                                itemImageID: "",
                                itemImage: null
                            }
                        },
                        skuDropzoneEnabled: true,
                        loading: false,
                        error: false,
                        message: "SKU Image deleted successfully.",
                        skuFiles: [],
                        isDirty: true
                    }));
                }
                else this.setState({ loading: false, error: true, files: [], isDirty: true });
            })
            .catch(error => console.log(error));
    }

    handleSkuSubmit = () => {
        let { companyItem, currentSku, currentSkuIndex } = this.state;

        currentSku.code = currentSku.code.trim();

        if (currentSkuIndex) companyItem = update(companyItem, { stockKeepingUnits: { [currentSkuIndex]: { $set: currentSku } } })
        else {
            currentSku.companyItemID = companyItem.id;
            companyItem.stockKeepingUnits.push(currentSku);
        }
        this.getCompanyItem();
        if (this.props.match.params.skuIndex !== 'add') this.getQuantityAndShow(this.props.match.params.skuIndex);

        this.setState({ loading: false });
    }

    handleSubmit = async () => {
        this.setState({ loading: true });

        let { companyItem } = this.state;
        const { currentSku, currentSkuIndex, retailerReturnConfiguration } = this.state;
        const skuValidation = this.skuValidator.validate(currentSku);
        this.setState({ skuValidation: skuValidation, isDirty: false });

        let valid = skuValidation.isValid;
        const returnsEnabled = retailerReturnConfiguration && retailerReturnConfiguration.enabled;

        if ((companyItem.noScan === true || returnsEnabled) && !currentSku.itemImage) {
            if(returnsEnabled) this.setState({ showReturnsImageWarning: true });
            if(companyItem.noScan) this.setState({ showBarcodeImageWarning: true });
            valid = false;
        } else {
            this.setState({ showBarcodeImageWarning: false, showReturnsImageWarning: false });
        }

        if (valid) {
            this.submitted = true;

            currentSku.code = currentSku.code.trim();

            if (currentSkuIndex) companyItem = update(companyItem, { stockKeepingUnits: { [currentSkuIndex]: { $set: currentSku } } })
            else {
                currentSku.companyItemID = companyItem.id;
                companyItem.stockKeepingUnits.push(currentSku);
            }

            return await PUT(Endpoints.STOCK.PUT.UPDATE, companyItem)
                .then(response => response.json()).then((result) => {
                    if (!result.error) {
                        this.getCompanyItem();
                        this.setState({ loading: false, success: true, warning: null });
                        this.props.history.push({ pathname: `/supplier/products/edit/uniqueidentifiers/${companyItem.id}/${this.props.match.params.pageIndex}` });
                    } else {
                        this.getCompanyItem(result.message);
                        this.setState({ loading: false, success: false });
                    }
                });
        } else {
            const skuDropzoneEnabled = currentSku.itemImageID ? false : true;
            this.setState({ loading: false, error: false, skuFiles: [], skuDropzoneEnabled: skuDropzoneEnabled });
        }
    }

    handleDeleteClose = () => {
        this.setState({ showDelete: false });
    }

    renderDeleteModal = () => {
        return (
            <Modal centered show={this.state.showDelete} onHide={this.handleDeleteClose} backdrop="static" animation={false}>
                <Modal.Header closeButton className="px-4">
                    <Modal.Title>Delete SKU</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    Are you sure that you want to delete this SKU?
                    </Modal.Body>
                <Modal.Footer className="px-4">
                    <Button variant="link" onClick={() => this.handleDeleteClose()}>Cancel</Button>
                    <Button variant="danger"
                        onClick={() => this.delete()}>Delete</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    render() {
        const { companyItem, currentSku, loading, message, success, warning, isSkuInUse, showSkuInUse, showBarcodeImageWarning, showReturnsImageWarning, retailerReturnConfiguration } = this.state;
        const disabled = (currentSku.quantity > 0 || isSkuInUse) ? "disabled" : "";
        const skuValidation = this.skuSubmitted ? this.skuValidator.validate(currentSku) : this.state.skuValidation;
        const existing = currentSku.id !== defaultGuid;
        const disableDelete = (existing && companyItem.noScan && companyItem.stockKeepingUnits.length === 1);
        const returnsEnabled = retailerReturnConfiguration && retailerReturnConfiguration.enabled;

        return (
            <React.Fragment>
                <Prompt message="Confirm that you would like to cancel without adding a new SKU." when={this.state.isDirty} />
                <ProductHeader activeKey="Products" headerClass="mb-0" />
                <Container fluid>
                    <Row>
                        <Col sm={12} md={6}>
                            <Breadcrumbs breadcrumbs={breadcrumbs(`/supplier/products/edit/${this.props.match.params.id}/${this.props.match.params.pageIndex}`, `/supplier/products/edit/uniqueidentifiers/${this.props.match.params.id}/${this.props.match.params.pageIndex}`, existing ? ' Edit SKU' : ' Add SKU')} />
                            {loading ? <LoadingBar /> :
                                <React.Fragment>
                                    <React.Fragment>
                                        <div className="section-item mt-3">
                                            <Form onSubmit={this.handleSkuSubmit}>
                                                <h5 className="mb-3 ">{existing ? 'Edit' : 'Add'} SKU</h5>
                                                {success && <SuccessAlert successMessage={message} />}
                                                {showSkuInUse && <ErrorAlert errorMessage="This SKU is currently assigned to a bundle and it cannot be deleted." />}
                                                {warning && <ErrorAlert errorMessage={warning} />}
                                                {companyItem.stockKeepingUnits.length === 0 && <div className="form-input-description">
                                                    <p>By adding a SKU variant for this existing product, you will first need to enter a SKU identification for the product
                                                            so that all variants are identifiable. All stock and orders associated to the original product will be re-assigned to this SKU.</p>
                                                </div>}
                                                {!existing && companyItem.stockKeepingUnits.length > 0 && <h6>Existing SKUs</h6>}
                                                {!existing && companyItem.stockKeepingUnits.length > 0 && companyItem.stockKeepingUnits.map((sku, i) => {
                                                    return <p key={i}>{sku.code}</p>;
                                                })}
                                                {!existing && <h6 className="mt-3">New SKU</h6>}
                                                <FormGroup>
                                                    <FormLabel htmlFor="skuCodeField">{companyItem.stockKeepingUnits.length > 0 ? 'SKU Code' : 'SKU Code for Existing Product'}</FormLabel>
                                                    <FormControl id="skuCodeField" index={1} type="text" name="code" value={currentSku.code} onChange={this.handleInputChangeSKU} disabled={disabled} />
                                                    <span className="text-danger">{skuValidation.code.message}</span>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormLabel htmlFor="descriptionField">Description</FormLabel>
                                                    <FormControl id="descriptionField" index={1} name="description" as="textarea" rows="4" value={currentSku.description} onChange={this.handleInputChangeSKU} />
                                                    <span className="text-danger">{skuValidation.description.message}</span>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormLabel>SKU Image {!companyItem.noScan && <span className="form-label-optional">- optional</span>}</FormLabel>
                                                    <div className="form-input-description mt-n2">
                                                        <p>Use the uploader below to add a photo of your product. The image must 1MB or smaller.</p>
                                                    </div>
                                                    {this.state.skuDropzoneEnabled &&
                                                        <ReactDropzone accept="image/*" onDrop={this.onDropSku} maxSize={999999} multiple={false}>
                                                            {({ getRootProps, getInputProps }) => (
                                                                <div {...getRootProps()} className="file-upload text-center">
                                                                    <input {...getInputProps()} />
                                                                    <p className="file-upload-primarytext my-2">Drag and drop your image</p>
                                                                    <p className="file-upload-secondarytext my-2">or</p>
                                                                    <Button variant="secondary">Browse</Button>
                                                                </div>
                                                            )}
                                                        </ReactDropzone>}
                                                    {this.state.skuFiles.length > 0 && <div className="form-image-list">
                                                        <ul>
                                                            {this.state.skuFiles.map((f, i) => <li key={i}><FontAwesomeIcon icon={faFileImage} className="form-fileicon" />{f.name} - {f.size / 1000} KB <FontAwesomeIcon icon={faTrashAlt} className="form-fileicon-action float-right" index={i} onClick={this.removeFileSku} /></li>)}
                                                        </ul>
                                                    </div>}
                                                    {(currentSku.itemImage && !this.state.skuDropzoneEnabled) && <div className="file-list mt-4">
                                                        {companyItem.noScan &&
                                                            <div className="mt-2">
                                                                <WarningAlert
                                                                    warningTitle={NO_BARCODE_TITLE_VALIDATION}
                                                                    warningMessage={NO_BARCODE_DELETE_IMAGE}
                                                                />
                                                            </div>
                                                        }
                                                        <ul>
                                                            {companyItem.noScan || returnsEnabled
                                                                ? <li><FontAwesomeIcon icon={faFileImage} className="form-fileicon" />{currentSku.itemImage.fileName}</li>
                                                                : <li><FontAwesomeIcon icon={faFileImage} className="form-fileicon" />{currentSku.itemImage.fileName}<FontAwesomeIcon icon={faTrashAlt} className="form-fileicon-action float-right" onClick={() => this.deleteImageSku(currentSku.itemImage.id)} /></li>
                                                            }
                                                        </ul>
                                                    </div>}
                                                </FormGroup>
                                                {showReturnsImageWarning &&
                                                    <div className="mt-2">
                                                        <WarningAlert
                                                            warningTitle={RETURNS_TITLE}
                                                            warningMessage={NO_BARCODE_IMAGE_REQUIRED}
                                                        />
                                                    </div>
                                                }
                                                {showBarcodeImageWarning &&
                                                    <div className="mt-2">
                                                        <WarningAlert
                                                            warningTitle={NO_BARCODE_TITLE_VALIDATION}
                                                            warningMessage={NO_BARCODE_IMAGE_REQUIRED}
                                                        />
                                                    </div>
                                                }
                                                {(existing && returnsEnabled) ?
                                                    <div className="card-container-warning">
                                                        <WarningAlert
                                                            warningTitle={RETURNS_TITLE}
                                                            warningMessage={RETURNS_DELETE_IMAGE}
                                                        />
                                                    </div> : null
                                                }
                                                {(existing && disableDelete) ?
                                                    <div className="card-container-warning">
                                                        <WarningAlert
                                                            warningTitle={NO_BARCODE_TITLE_VALIDATION}
                                                            warningMessage={NO_BARCODE_DELETE_SKU}
                                                        />
                                                    </div> : null
                                                }
                                                <div className="w-100 float-left mt-4">
                                                    <div className="w-50 float-left">
                                                        {existing &&
                                                            <Button className="float-left" variant="danger" onClick={this.removeSKU} disabled={disableDelete}>Delete SKU</Button>
                                                        }
                                                    </div>
                                                    <div className="float-right">
                                                        <Link to={`/supplier/products/edit/uniqueidentifiers/${this.props.match.params.id}/${this.props.match.params.pageIndex}`} className="link-button">
                                                            <Button variant="link" className="mr-3">Cancel</Button>
                                                        </Link>
                                                        <Button variant="primary" onClick={this.handleSubmit}>{existing ? 'Save' : 'Add SKU'}</Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </React.Fragment>
                                </React.Fragment>}
                        </Col>
                    </Row>
                    {this.renderDeleteModal()}
                </Container>
            </React.Fragment>
        )
    }
}

export default EditSKU;