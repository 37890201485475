import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { PREFERENCES_SUB_NAV_LIST } from '../../common/constants';
import Endpoints from '../../common/Endpoints';
import { GET, POST, extractData } from '../../../Consumer';
import Header from '../../common/Header';
import LoadingBar from '../../common/LoadingBar';
import { ErrorAlert } from '../../common/Alert';

class PartialOrderPreference extends Component {

    state = {
        loading: true,
        partialOrderPreference: false,
        showError: false
    };

    async componentDidMount() {
        await this.fetchPartialOrderPreference();
        this.setState({ loading: false });
    }

    fetchPartialOrderPreference = async () =>
        GET(Endpoints.RETAILER.GET.PARTIAL_ORDER_PREFERENCE)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result);
                if (data != null) {
                    this.setState({ partialOrderPreference: data });
                }
                else this.setState({ showError: true });
            })
            .catch(error => console.log(error));

    handleToggleChange = async () => {
        const { partialOrderPreference } = this.state;
        this.setState({ loading: true });

        POST(Endpoints.RETAILER.POST.PARTIAL_ORDER_PREFERENCE + !partialOrderPreference)
            .then(response => {
                if (response && response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result);
                if (data !== null) {
                    this.setState({ partialOrderPreference: data, showError: false, loading: false })
                }
                else this.setState({ showError: true, loading: false });
            })
            .catch(error => {
                console.log(error);
                this.setState({ showError: true, loading: false });
            });
    }

    render() {
        const { loading, partialOrderPreference, showError } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <Header title="Preferences" subNavList={PREFERENCES_SUB_NAV_LIST} activeKey="Orders" headerClass="mb-2" />
                    <Container fluid>
                        <Row>
                            <Col sm={12} md={6}>
                                {showError
                                    ? <ErrorAlert errorMessage="Partial Order preference selection failed, please try again later." />
                                    : <Fragment>
                                        <h5 className="mb-3">Order Settings</h5>
                                        <p><strong>By default, a B2C order is fulfilled only when all products in that order are in stock.</strong></p>
                                        <p>
                                            Turning on partial orders means we will fulfil any part of a B2C order that is in stock now. Any out of stock part of the order will be fulfilled when stock is later
                                            available. This may result in more parcels for a single order than expected.
                                        </p>
                                        <p>If turned off, any existing partial orders will remain in the queue, but we will not accept any new.</p>
                                        <p><strong>Partial Orders</strong></p>
                                        <label className="switch">
                                            <input id="partialOrderOption" type="checkbox" onChange={() => this.handleToggleChange()} checked={partialOrderPreference} />
                                            <span className="slider round"></span>
                                        </label>
                                    </Fragment>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Fragment>
        )
    }
}

export default PartialOrderPreference;

