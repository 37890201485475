import React from 'react';
import ReactDropzone from 'react-dropzone';
import { FormGroup, FormLabel, FormControl, Button, Col, Row, Container, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faImage, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { WarningAlert } from '../../../../common/Alert';
import './UniqueIdentifiers.scss';
import { EanTooltip, NoBarcodeTooltip, BatchManagementTooltip, Tooltip } from './../shared/';

const RETURNS_TITLE_VALIDATION = "Returns Active";

const NO_BARCODE_TITLE_VALIDATION = "No-Barcode Product";
const NO_BARCODE_MESSAGE_VALIDATION = "You are required to upload a product image.";

const NO_BARCODE_SKU_TITLE_VALIDATION = "No-Barcode Product SKU Variants";
const NO_BARCODE_SKU_MESSAGE_VALIDATION = "You are required to upload a product image for each SKU variant.";

const ImageUpload = ({ images = [], dropzoneEnabled, onDrop, deleteImage }) =>
  <FormGroup>
    <FormLabel className="mb-0">Product Image</FormLabel>
    <div className="form-input-description mb-3">
      <p>A product image is required for no-barcode products.</p>
    </div>
    {dropzoneEnabled &&
      <ReactDropzone accept="image/*" onDrop={onDrop} maxSize={999999} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div id="file-upload-container" {...getRootProps()} className="file-upload text-center">
            <input {...getInputProps()} />
            <Image src='Images/upload-cloud.svg' className="upload-cloud" />
            <p className="file-upload-secondarytext my-2">Drag and drop image here</p>
            <p className="file-upload-secondarytext my-2">or</p>
            <Button variant="primary">Browse</Button>
          </div>
        )}
      </ReactDropzone>
    }
    {images.length > 0 && <div className="form-image-list mt-3">
      <ul>
        {images.map((image, i) =>
          <li key={i}>
            <FontAwesomeIcon icon={faImage} className="form-fileicon" />{image.name}<FontAwesomeIcon icon={faTrashAlt} className="form-fileicon-action float-right" id={image.id} onClick={() => deleteImage(i)} />
          </li>)
        }
      </ul>
    </div>}
  </FormGroup>

const UniqueIdentifiers = ({ companyItem, handleInputChange, handleShowForNewSKU, handleShowForExistingSKU, eanValidation, showBarcodeWarning, images, dropzoneEnabled, onDrop, deleteImage, showReturnsWarning }) =>
  <React.Fragment>
    <Container className="unique-identifiers-container" fluid>
      <Row>
        <Col id="title-container" sm={12} md={6} className="mt-3">
          <h5>Unique Identifiers</h5>
          <p><strong>Enter existing unique product identifiers here:</strong></p>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup id="ean-container" className='ean-container'>
            <FormLabel htmlFor="eanField">EAN <span className="form-label-optional">- optional</span><Tooltip placement="top" text={EanTooltip} /></FormLabel>
            <FormControl id="eanField" className='text-input' type="text" placeholder="EAN" parent="item" name="ean" value={companyItem.item.ean} onChange={handleInputChange} />
          </FormGroup>

          <FormGroup id="isbn-container">
            <FormLabel htmlFor="isbnField">ISBN <span className="form-label-optional">- optional</span></FormLabel>
            <FormControl id="isbnField" className='text-input' type="text" placeholder="ISBN" parent="item" name="isbn" value={companyItem.item.isbn} onChange={handleInputChange} />
          </FormGroup>

          <FormGroup id="sku-container">
            <FormLabel htmlFor="addSku">SKU <span className="form-label-optional">- optional</span></FormLabel>

            {(companyItem.stockKeepingUnits || []).length > 0 && <div className="identifier-list">
              <ul>
                {companyItem.stockKeepingUnits.map((f, i) => (
                  <li key={i}>
                    <span>SKU</span>
                    <span>{f.code}</span>
                    <FontAwesomeIcon className="form-fileicon-edit float-right" onClick={() => handleShowForExistingSKU(i)} icon={faEdit} />
                  </li>
                ))}
              </ul>
            </div>}
            <Button variant="secondary" id="addSku" className="display-block" onClick={handleShowForNewSKU}>Add SKU</Button>
          </FormGroup>

          <FormGroup id="batch-management">
            <FormLabel className="mb-0"><span>Batch management</span><Tooltip placement="top" text={BatchManagementTooltip} /></FormLabel>
            <FormGroup className="custom-control custom-checkbox">
              <input id="batch" className="custom-control-input" type="checkbox" name="batch" checked={companyItem.item.batch} onChange={handleInputChange} />
              <FormLabel className="custom-control-label" htmlFor="batch">This product is handled in batches</FormLabel>
            </FormGroup>
          </FormGroup>
        </Col>

        <Col>
          <FormGroup id="no-barcode-container">
            <FormLabel><span>No-Barcode Product</span><Tooltip placement="top" text={NoBarcodeTooltip} /></FormLabel>
            <div className="form-input-description mb-3 barcode-product">
              <p className="">If your product cannot have a physical barcode, please select below. This product will be manually confirmed using its image throughout pick and pack.</p>
            </div>
            <FormGroup className="custom-control custom-checkbox">
              <input id="noScan" className="custom-control-input" type="checkbox" name="noScan" checked={companyItem.noScan} onChange={handleInputChange} />
              <FormLabel className="custom-control-label" htmlFor="noScan">This is a no-barcode product</FormLabel>
            </FormGroup>
            {showBarcodeWarning &&
              <WarningAlert
                warningTitle={(companyItem.stockKeepingUnits || []).length > 0 ? NO_BARCODE_SKU_TITLE_VALIDATION : NO_BARCODE_TITLE_VALIDATION}
                warningMessage={(companyItem.stockKeepingUnits || []).length > 0 ? NO_BARCODE_SKU_MESSAGE_VALIDATION : NO_BARCODE_MESSAGE_VALIDATION}
              />
            }
            {showReturnsWarning &&
              <WarningAlert
                warningTitle={RETURNS_TITLE_VALIDATION}
                warningMessage={(companyItem.stockKeepingUnits || []).length > 0 ? NO_BARCODE_SKU_MESSAGE_VALIDATION : NO_BARCODE_MESSAGE_VALIDATION}
              />
            }
          </FormGroup>
          {!companyItem.stockKeepingUnits.length > 0 && <ImageUpload images={images} dropzoneEnabled={dropzoneEnabled} onDrop={onDrop} deleteImage={deleteImage} />}
        </Col>
      </Row>
    </Container>
  </React.Fragment>

export default UniqueIdentifiers;