import React from 'react';
import { Button, Col, FormLabel, Form, FormGroup, Row, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const InvoiceFilter = ({ types, invoiceNumber, dateFrom, dateTo, invoiceType, onSearch, onFilterChange, onClear }) =>
    <Form onSubmit={onSearch}>
        <FormGroup>
            <Row>
                <Col md={3}>
                    <InputGroup>
                        <FormControl id="invoiceNumberField" className="input-filter" type="text" name="invoiceNumber" placeholder="Search by Invoice Number" value={invoiceNumber} onChange={onFilterChange} />
                        <InputGroup.Append>
                            <InputGroup.Text className="input-filter-icon" onClick={onSearch}>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Col md={4}>
                    <InputGroup>
                        <FormControl id="dateFromField" type="date" name="dateFrom" value={dateFrom} onChange={onFilterChange} />
                        <FormControl id="dateToField" type="date" name="dateTo" value={dateTo} onChange={onFilterChange} />
                    </InputGroup>
                </Col>
                <Col md={2}>
                    <FormLabel htmlFor="invoiceType" className="float-right label-filter">Invoice Type</FormLabel>
                </Col>
                <Col md={3}>
                    <FormControl id="invoiceType" name="invoiceType" as="select" value={invoiceType} onChange={onFilterChange}>
                        <option key="" value="">Please Select...</option>;
                        {types.map(type => (<option key={type.value} value={type.value}>{type.name}</option>))}
                    </FormControl>
                </Col>
            </Row>
        </FormGroup>

        <FormGroup>
            <Button variant="primary" className="float-right" type="submit">Apply filter</Button>
            <Button variant="link" className="float-right" onClick={onClear}>Clear filter</Button>
        </FormGroup>
    </Form>

export default InvoiceFilter;