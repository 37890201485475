import React, { Component } from 'react';
import { Button, Card, Image, Row, Col } from 'react-bootstrap';

import './Integrations.scss';
const SHOPIFY_INTEGRATION_TYPE = 'Shopify';
const oldConnectionDateLimit = process.env.REACT_APP_OLD_CONNECTION_DATE_LIMIT;
const SHOPIFY_APP_CONNECTION_URL = process.env.REACT_APP_SHOPIFY_APP_CONNECTION_URL;

class SelectIntegration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            integrationSources: [
                {
                    name: 'Shopify',
                    imageUrl: 'Images/shopify-logo.png'
                },
                {
                     name: 'Shopify V2',
                     imageUrl: 'Images/shopify-logo.png'
                },
                {
                    name: 'WooCommerce',
                    imageUrl: 'Images/woocommerce-logo.png'
                },
            ],
            newCustomer: false
        }
    }

    componentDidMount() {
        this.props.setIntegrationSource(this.props.integrationSource);
        this.setState({ newCustomer: this.showPreviousCard() });
    }

    addActiveClass = (selectedSource, currentSource) => {
        if (selectedSource === currentSource) return "integration-selected";
        else return "";
    }

    previousVersionConnection = () => {
        if (this.showPreviousCard()) return (
            <div>
                <p className='mt-2'><strong>Previous Versions</strong></p>
                <Row>
                    <Col sm="12" lg="6">
                        <Card id={`integration-source-Shopify`} className={"integration-select-card " + this.addActiveClass("Shopify", this.props.integrationSource)}
                            onClick={() => this.props.setIntegrationSource(this.state.integrationSources[0].name)}>
                            <Image src={this.state.integrationSources[0].imageUrl} className="mx-auto my-4 mx-5 integration-image" />
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }


    showPreviousCard = () => {
        const oldConnections = this.props.integrations.
            filter(integration => integration.appIntegration === false && integration.integrationType === SHOPIFY_INTEGRATION_TYPE);
        const createdDate = Date.parse(JSON.parse(localStorage.getItem("user")).createdDate);
        const startDate = Date.parse(new Date(oldConnectionDateLimit));

        return oldConnections.length !== 0 || createdDate <= startDate;
    }

    navigateToShopifyAppStore = () => {
        window.open(SHOPIFY_APP_CONNECTION_URL, '_blank').focus();
        this.props.setStep('home');
        return;
    }

    render() {
        const { integrationSources } = this.state;

        return (
            <React.Fragment>
                <p>Please select the platform you would like to add as an integration.</p>
                {/* <p className='mt-4'><strong>Current Integrations</strong></p> */}
                <Row>
                    {integrationSources.map((source, i) => {
                        //if (source.name !== "Shopify") 
                        return (
                            <Col key={source.name + i}>
                                <Card id={`integration-source-${source.name}`} className={"integration-select-card " + this.addActiveClass(source.name, this.props.integrationSource)}
                                    onClick={() => (source.name === 'Shopify V2' ?  this.navigateToShopifyAppStore() : this.props.setIntegrationSource(source.name))}>
                                    <div className={'version-container' + (source.name === 'Shopify V2' ? '' : ' d-none')}>
                                        <span className="version-span">v2.0</span>
                                    </div>
                                    <Image src={source.imageUrl} className="mx-auto my-4 mx-5 integration-image" />
                                </Card>
                            </Col>
                        );
                    })}
                </Row>

                {/*this.previousVersionConnection()*/}

                <Button className="btn btn-primary float-right" type="submit" disabled={this.props.integrationSource === ''} onClick={this.props.setStep}>Next</Button>
                <Button variant="link" className="float-right" onClick={() => this.props.setStep('home')}>Cancel</Button>
            </React.Fragment>
        )
    }
}

export default SelectIntegration;