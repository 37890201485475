import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const reasonDescription = (reason) => {
    switch (reason) {
        case 0:
            return "I no longer want/need the product"
        case 1:
            return "The product is not as described"
        case 2:
            return "I received a different product"
        case 3:
            return "I received the incorrect size or colour"
        case 4:
            return "The product does not work"
        case 5:
            return "The product is faulty or defective"
        case 6:
            return "The product arrived damaged"
        case 7:
            return "I ordered the wrong product"
        case 8:
            return "The product is faulty or defective"
        case 9:
            return "I found a better price elsewhere"
        default:
            return "Reason no found"
    }
}

const ReturnModal = ({ show, handleClose, modalData, modalProcessed }) =>
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
            <Modal.Title>Return Reference: {modalData.reference}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p className="title">Return Summary</p>
            <p className="mb-0">Status: {modalData.status === 0 ? "Unprocessed" : modalData.status === 1 ? "Checked-in" : "Processed"}</p>
            <p className="mb-0">Order Reference: {modalData.orderReference}</p>
            {modalData && modalData.items ? modalData.items.map((i) => {
                return (<React.Fragment>
                    <p className="title mt-3">Item Name: {i.name}</p>
                    {i.skuCode && <p className="mb-0">SKU: {i.skuCode}</p>}
                    <p className="mb-0">Quantity: {i.quantity}</p>
                    {modalProcessed && <React.Fragment>
                        <p className="mb-0">Reason: {reasonDescription(i.reason)}</p>
                        {i.reasonComment ? <p className="mb-0">{i.reasonComment}</p> : null}
                        <p className="title mt-3">Item Quality Assurance</p>
                        <p className="mb-0">Item is Present: {i.itemPresent ? "Yes" : "No"}</p>
                        <p className="mb-0">Return is Valid: {i.returnValid ? "Yes" : "No"}</p>
                        <p className="mb-0">Return Condition is Acceptable: {i.returnConditionAcceptable ? "Yes" : "No"}</p>
                        <p className="mb-0">No Other Issues: {i.noOtherIssues ? "Yes" : "No"}</p> </React.Fragment>}
                </React.Fragment>);
            }) : <p>No item details found.</p>}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="link" onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>

export default ReturnModal
