import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import './Header.scss';

export const SubNav = ({ navList, activeKey }) =>
    <Breadcrumb>
        {navList.map((nav, i) => nav.text === activeKey ?
            <Breadcrumb.Item key={'link-active-' + i} active>
                <span className='active'>{nav.text}</span>
            </Breadcrumb.Item> :
            <LinkContainer key={'link-' + i} to={nav.link}>
                <Breadcrumb.Item>
                    {nav.text}
                </Breadcrumb.Item>
            </LinkContainer>)
        }
    </Breadcrumb>

const Header = ({ title, subNavList = [], activeKey, headerClass = null }) =>
    <Container className={headerClass ? headerClass : "mb-4"} fluid>
        <Row>
            <Col md={12}>
                <h1 className="page-title mb-3">{title}</h1>
                {subNavList.length > 0 && <SubNav navList={subNavList} activeKey={activeKey} />}
            </Col>
        </Row>
    </Container>

export default Header;