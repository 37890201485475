import React from 'react';

export const EanTooltip = (props) => (
  <div>
    <div>
      <div id="tooltip-inner-info" className='ean-tooltip' {...props}>
        <em>This is the number that is below the barcode displayed on either the packaging or the product itself.</em>
      </div>
    </div>
  </div>
);