import React, { Fragment, useCallback, useEffect, useState } from 'react';
import LoadingBar from '../../common/LoadingBar';
import { Col, Row, Button, Container } from 'react-bootstrap';
import { GET, POST, extractData, GET_ASYNC } from '../../../Consumer';
import { defaultGuid } from '../../../Utilities';
import Endpoints from '../../common/Endpoints';
import { INVENTORY_SUB_NAV_LIST } from '../../common/constants'
import Header from '../../common/Header';
import { SuccessAlert } from '../../common/Alert';
import { Link } from 'react-router-dom';
import Stepper from '../../common/wizard/Stepper';
import StepperButtons from '../../common/wizard/StepperButtons';
import ItemSelection from './StockUploadWizard/ItemSelection';
import StockDetails from './StockUploadWizard/StockDetails';
import Shipping from './StockUploadWizard/Shipping';
import SingleConfirmation from './StockUploadWizard/SingleConfirmation';
import Confirm from '../../common/Confirm';
import MissingDetails from '../../common/MissingDetails';
import WarehouseInformation from './WarehouseInformation';
import { useFormValidator, useCombinedLoadingState } from "@selazar-ui/core";
import { hasTokenExpired } from '../../../Utilities'


const steps = ["Item Selection", "Stock Details", "Shipping & Labelling", "Confirmation"];
const cancelLink = "/supplier/stockconsignments"

const MODAL_TITLE = "Confirm Stock Box Quantity";
const MODAL_CANCEL = "No, return and edit stock details";

const STOCK_BOX_MODAL_LIMIT = 150;
const BOX_CONFIGURATION_MODAL_CONFIRM = "Yes";

const PFP_ITEMS_PER_BOX_MODAL_LIMIT = 1;
const PFP_MODAL_CONFIRM = "Create consignment as pick from pallet";

const PFP_STOCK_BOX_HEIGHT = 200;
const PFP_STOCK_BOX_WIDTH = 60;
const PFP_STOCK_BOX_DEPTH = 65;
const PFP_STOCK_BOX_WEIGHT = 70;

const MINIMUM_GTIN_LENGTH = 8;
const REGEX_MAX_TWO_DECIMAL_PLACES = /^\d*\.?\d{0,2}$/;
const REGEX_SPECIAL_CHARS = /[`~!@#£$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi;

const PFP_MODAL_TITLE = "Pick From Pallet Alert";
const PFP_MODAL_CANCEL = "No, return and edit stock details";
const PFP_MODAL_TEXT = "You have indicated that your stock box size is above our shelf storage restrictions (200cm x 60cm x 65cm x 70kg). This qualifies the stock on this consignment to be picked from pallet and will require stock to be sent in on a pallet. Do you want this consignment to be pick from pallet?";
const PFP_ITEM_MODAL_TEXT = "You have selected to upload a pick from pallet item. This qualifies the stock on this consignment to be picked from pallet and will require stock to be sent in on a pallet. Do you want this consignment to be pick from pallet?";

const MISSING_DETAILS_MESSAGE = "You need to complete the following before you are able to upload inventory.";

const WAREHOUSE_MESSAGE = "Once your inventory consignment is created and labelled, please send to the following warehouse address. "
    + "If you need help, contact our customer services on 020 3950 7860 or support@selazar.com.";

const getBoxConfigurationModalText = (isPallet, boxQuantity, itemsPerBox, stockQuantity) => `You have indicated that this ${isPallet === "true" ? "pallet" : "shelf consignment"} will have ${parseInt(boxQuantity, 10)} stock boxes with ${itemsPerBox} items in each box. This is ${stockQuantity} items in total, is this correct?`;
const getPickFromPalletModelText = (itemsPerBox) => `You have indicated that you are sending in ${itemsPerBox} item(s) per stock box. This qualifies the stock on this consignment to be picked from pallet and will require stock to be sent in on a pallet. Do you want this consignment to be pick from pallet?`;

const defaultStock = {
    gtin: "",
    companyItemID: "",
    stockQuantity: 0,
    boxQuantity: 0,
    itemsPerBox: 0,
    batchNumber: "",
    expiryDate: "",
    id: defaultGuid,
    companyID: defaultGuid,
    fileName: "",
    labelsAffixed: false,
    processedBy: null,
    dateReceived: null,
    dateProcessed: null,
    supplierPrintedLabels: "",
    requiresGTIN: "false",
    shippingReference: "",
    isPallet: "true",
    boxHeight: 0,
    boxWidth: 0,
    boxDepth: 0,
    boxWeight: 0,
    stockBoxHeight: 0,
    stockBoxWidth: 0,
    stockBoxDepth: 0,
    stockBoxWeight: 0,
    skuCode: "",
    pickFromPallet: false,
    companyItemIsPFP: false
};

const initialValidationMessages = {
    boxHeightError: false,
    boxWidthError: false,
    boxDepthError: false,
    boxWeightError: false,
    gtinError: false,
    isPalletError: false,
    consignmentBoxHeightError: false,
    consignmentBoxWidthError: false,
    consignmentBoxDepthError: false,
    consignmentBoxWeightError: false
};

const initialLabelChoice = {
    stockBox: false,
    product: false
};

const UploadSingleStock = () => {
    
    
    //#region State
    const [cardLoading, setCardLoading] = useState(false);
    const [warehouseLoading, setWarehouseLoading] = useState(false);
    const [stockLoading, setStockLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [itemLimitLoading, setItemLimitLoading] = useState(false);
    const { loading } = useCombinedLoadingState([cardLoading, warehouseLoading, stockLoading, submitLoading, itemLimitLoading]);
    const [stock, setStock] = useState(defaultStock);
    const [hasValidCardDetails, setHasValidCardDetails] = useState(false);
    const [hasValidWarehouse, setHasValidWarehouse] = useState(false);
    const [warehouse, setWarehouse] = useState(null);
    const [companyItems, setCompanyItems] = useState([]);
    const [error, setError] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [labelChoice, setLabelChoice] = useState(initialLabelChoice);
    const [skuCodes, setSkuCodes] = useState(null);
    const [skuCodeEmptyMessage, setSkuCodeEmptyMessage] = useState("");
    const [showBoxConfigurationModal, setShowBoxConfigurationModal] = useState(false);
    const [showPickFromPalletModal, setShowPickFromPalletModal] = useState(false);
    const [showPickFromPalletBoxModal, setShowPickFromPalletBoxModal] = useState(false);
    const [showPickFromPalletItemModal, setShowPickFromPalletItemModal] = useState(false);
    const [itemLimit, setItemLimit] = useState(20000);
    const [boxLimit, setBoxLimit] = useState(5000);
    const [message, setMessage] = useState(initialValidationMessages);
    const [validationMessages, setValidationMessages] = useState(initialValidationMessages);
    
    //#endregion

    //#region API

      useEffect(() => {
          (() => {
            setCardLoading(true);
            GET(Endpoints.FINANCE.CARD.GET.HAS_VALID_DETAILS)
            .then(res => res.json())
            .then(res => {
                if (res.error) { console.log(res.message); }
                else {
                    const existing = extractData(res);
                    setHasValidCardDetails(existing);
                }
            })
            .catch(error => console.log(error))
            .finally(() => setCardLoading(false))
          })()
      }, []);

      useEffect(() => {
        (() => {
            setWarehouseLoading(true);

            GET(Endpoints.WAREHOUSE.GET.SELECTION)
            .then(response => { if (response.ok) return response.json(); })
            .then(result => {
                const data = extractData(result);
                setWarehouse(data);
                setHasValidWarehouse(data.id !== null && data.id !== "");
            })
            .catch(error => console.log(error))
            .finally(() => setWarehouseLoading(false))
        })();
      }, [])

      useEffect(() => {
        if(!hasValidCardDetails) return;
        (() => {
            setStockLoading(true);

            GET(Endpoints.STOCK.GET.ALL)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const stock = extractData(results);
                if (!Array.isArray(stock)) stock = [];

                setCompanyItems(stock);
            })
            .catch(error => console.log(error))
            .finally(() => setStockLoading(false));
        })()
      }, [hasValidCardDetails])
        
    const [ validateItem, itemValidation ] = useFormValidator([
        {
            field: 'companyItemID',
            method: 'isEmpty',
            validWhen: false,
            message: 'Item is required'
        }
    ]);

    const [ validateSupplierLabel, supplierLabelValidation ] = useFormValidator([
        {
            field: 'supplierPrintedLabels',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please choose a label printing method'
        }
    ]);

    const [ validateStockDetails, stockDetailsValidation ] = useFormValidator([
        {
            field: 'stockQuantity',
            method: 'isInt',
            validWhen: true,
            args: [{ min: 1, max: itemLimit}],
            message: 'Stock quantity must be between 1 and ' + itemLimit
        },
        {
            field: 'boxQuantity',
            method: 'isInt',
            validWhen: true,
            args: [{ min: 1, max: boxLimit }],
            message: 'Box quantity must be between 1 and ' + boxLimit
        },
        {
            field: 'itemsPerBox',
            method: 'isInt',
            validWhen: true,
            args: [{ min: 1, max: itemLimit }],
            message: 'Items per box must be between 1 and ' + itemLimit
        },
        {
            field: 'batchNumber',
            method: 'isLength',
            validWhen: true,
            args: [{ max: 20 }],
            message: 'Batch Number must be 20 characters or less'
        },
        {
            field: 'stockBoxHeight',
            method: 'isFloat',
            validWhen: true,
            args: [{ min: 0.01, max: 2000 }],
            message: 'Stock box height must be greater than 0 and smaller than 2000'
        },
        {
            field: 'stockBoxHeight',
            method: 'matches',
            validWhen: true,
            args: [REGEX_MAX_TWO_DECIMAL_PLACES],
            message: 'Stock box height cannot have more than 2 decimal places'
        },
        {
            field: 'stockBoxWidth',
            method: 'isFloat',
            validWhen: true,
            args: [{ min: 0.01, max: 2000 }],
            message: 'Stock box width must be greater than 0 and smaller than 2000'
        },
        {
            field: 'stockBoxWidth',
            method: 'matches',
            validWhen: true,
            args: [REGEX_MAX_TWO_DECIMAL_PLACES],
            message: 'Stock box width cannot have more than 2 decimal places'
        },
        {
            field: 'stockBoxDepth',
            method: 'isFloat',
            validWhen: true,
            args: [{ min: 0.01, max: 2000 }],
            message: 'Stock box depth must be greater than 0 and smaller than 2000'
        },
        {
            field: 'stockBoxDepth',
            method: 'matches',
            validWhen: true,
            args: [REGEX_MAX_TWO_DECIMAL_PLACES],
            message: 'Stock box depth cannot have more than 2 decimal places'
        },
        {
            field: 'stockBoxWeight',
            method: 'isFloat',
            validWhen: true,
            args: [{ min: 0.01, max: 2000 }],
            message: 'Stock box weight must be greater than 0 and smaller than 2000'
        },
        {
            field: 'stockBoxWeight',
            method: 'matches',
            validWhen: true,
            args: [REGEX_MAX_TWO_DECIMAL_PLACES],
            message: 'Stock box weight cannot have more than 2 decimal places'
        },
        {
            field: 'isPallet',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please confirm if consignment is a pallet'
        },
        {
            field: 'requiresGTIN',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please choose a GTIN option'
        }
    ]);

    const getUploadLimit = useCallback(async () => {
        setItemLimitLoading(true);

        try {
            const response = await GET_ASYNC(Endpoints.STOCK.GET.MAX_LIMIT_UPLOADS + stock.companyItemID);
            if (response?.ok) {
                const res = await response.json();
                setItemLimit(res.data);
                setBoxLimit(res.data);
            } 
        } catch (error) { console.log({ error }); }
        finally { setItemLimitLoading(false); }
    });

    const updateNextStep = () => setActiveStep(activeStep + 1);

    const handleBack = () => {
        const updatedStep = activeStep - 1;
        if (updatedStep === 1) setStock(prevState => ({ ...prevState, pickFromPallet: true }));

        setActiveStep(updatedStep);
    }
    const handleEditStep = (step) => setActiveStep(step);

    const getSkuCodes = async (companyItemID) => {
        return GET(Endpoints.STOCK.GET.SKUS.replace(":id", companyItemID))
            .then(response => { if (response.ok) return response.json(); })
            .then(results => setSkuCodes(extractData(results)) )
            .catch(error => console.log(error));
    }

    const handleInputChange = (e) => {
        let { name, value, type } = e.target;

        if (type === "checkbox") {
            let { checked } = e.target;
            value = checked;
            setLabelChoice(prevState => ({ ...prevState, ...{ [name]: value } }));

        } else {
            if (e.target.getAttribute('parent')) {
                let parent = e.target.getAttribute('parent');
                let parentValue = stock[parent];
                parentValue[name] = value;

                name = parent;
                value = parentValue;
            }

            if (name === "gtin" && value !== "") value = value.replace(REGEX_SPECIAL_CHARS, "");

            var newStockQuantity = determineStockQuantity(name, value);
            setStock(prevState => ({ ...prevState, ...{ [name]: value }, stockQuantity: newStockQuantity }));

            if (name === "companyItemID" && value !== "") {
                var companyItem = companyItems.find(ci => ci.companyItemID === value);
                if (companyItem !== null) {

                    if (companyItem.pickFromPallet) setStock(prevState => ({ ...prevState, companyItemIsPFP: true }));
                    else setStock(prevState => ({ ...prevState, companyItemIsPFP: false }));

                    if (companyItem.selazarUniqueID === null) {
                        getSkuCodes(companyItem.companyItemID)
                            .then(() => {
                                setStock(prevState => ({ ...prevState, [name]: value, skuCode: "", supplierPrintedLabels: false }));
                            });
                    } else {
                        getSkuCodes(companyItem.companyItemID)
                            .then(() => {
                                setStock(prevState => ({ ...prevState, ...{ [name]: value }, skuCode: "" }));
                            });
                    }
                    setSkuCodeEmptyMessage("");
                }
            } else if(name === "companyItemID" && value === "") setSkuCodes(null);
        }
    }

    const handlePickFromPalletModalClose = () => setShowPickFromPalletModal(false);
    const handlePickFromPalletModalConfirm = () => {
        setStock(prevState => ({ ...prevState, isPallet: "true", pickFromPallet: true }));
        setShowPickFromPalletModal(false);
        updateNextStep();
    }

    const handleBoxConfigurationModalClose = () => setShowBoxConfigurationModal(false);
    const handleBoxConfigurationModalConfirm = () => {
        setShowBoxConfigurationModal(false);
        updateNextStep();
    }

    const handlePickFromPalletBoxModalClose = () => setShowPickFromPalletBoxModal(false);
    const handlePickFromPalletBoxModalConfirm = () => {
        setStock(prevState => ({ ...prevState, isPallet: "true", pickFromPallet: true }));
        setShowPickFromPalletBoxModal(false);
        stock.boxQuantity >= STOCK_BOX_MODAL_LIMIT ? setShowBoxConfigurationModal(true) : updateNextStep();
    }

    const handlePickFromPalletItemModalClose = () => setShowPickFromPalletItemModal(false);
    const handlePickFromPalletItemModalConfirm = () => {
        setStock(prevState => ({ ...prevState, isPallet: "true", pickFromPallet: true }));
        setShowPickFromPalletItemModal(false);
        updateNextStep();
    }

    const determineStockQuantity = (inputName, newValue) => {
        var updatedStockQuantity = stock.stockQuantity;

        if (inputName === 'boxQuantity') updatedStockQuantity = parseInt(newValue) * stock.itemsPerBox;
        if (inputName === 'itemsPerBox') updatedStockQuantity = parseInt(newValue) * stock.boxQuantity;

        return updatedStockQuantity;
    }

    const validateToken = () => {
        
        const isTokenExpired = hasTokenExpired();
        if(isTokenExpired) {                                           
            localStorage.clear();
            window.open("/", "_self");  
                            
        }
    }

    const handleNext = async () => {

        validateToken();

        if (activeStep === 0) {
            var validation = validateItem(stock);

            if (stock.skuCode === "" && skuCodes?.length > 0) setSkuCodeEmptyMessage("SKU is required");
            else if (validation.isValid) {
                setSkuCodeEmptyMessage("");
                await getUploadLimit();
                updateNextStep();
            }
        } else if (activeStep === 1) {
            var validation = validateStockDetails(stock);

            validateGTIN();
            if (validationMessages.gtinError) validation.isValid = false;

            const validateConsignmentBoxMessages = validateConsignmentBox(stock);
            if (stock.isPallet === 'false' && (validateConsignmentBoxMessages.consignmentBoxHeightError || validateConsignmentBoxMessages.consignmentBoxHeightError || validateConsignmentBoxMessages.consignmentBoxHeightError || validateConsignmentBoxMessages.consignmentBoxHeightError)) {
                validation.isValid = false;
            }

            validateBox();
            if (validationMessages.boxHeightError || validationMessages.boxWidthError || validationMessages.boxDepthError || validationMessages.boxWeightError) {
                validation.isValid = false;
            }

            if (validation.isValid) {
                if (stock.companyItemIsPFP && stock.isPallet === 'true') setShowPickFromPalletItemModal(true);
                else if (stock.isPallet === 'true' && stock.itemsPerBox <= PFP_ITEMS_PER_BOX_MODAL_LIMIT) setShowPickFromPalletModal(true);
                else if (stock.stockBoxHeight > PFP_STOCK_BOX_HEIGHT || stock.stockBoxWidth > PFP_STOCK_BOX_WIDTH || stock.stockBoxDepth > PFP_STOCK_BOX_DEPTH || stock.stockBoxWeight > PFP_STOCK_BOX_WEIGHT)
                    setShowPickFromPalletBoxModal(true);
                else if (stock.boxQuantity >= STOCK_BOX_MODAL_LIMIT) setShowBoxConfigurationModal(true);
                else updateNextStep();
            }
        } else if (activeStep === 2) {
            var validation = validateSupplierLabel(stock);
            if (validation.isValid) updateNextStep();
        } else {
            await handleSubmit();
            !error && updateNextStep();
        }
    }

    const handleSubmit = async () => {
        setSubmitLoading(true);

        if (stock.skuCode === "") stock.skuCode = null;
        if (stock.gtin === "") stock.gtin = null;

        return POST(Endpoints.STOCK.PUT.ADD_STOCK, stock)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    setError(false);
                    setMessage("Stock record successfully uploaded.");
                }
                else
                {
                    setError(true);
                    setMessage(result.message);
                }
            })
            .finally(() => setSubmitLoading(false));
    }

    const validateGTIN = () => {
        validationMessages.gtinError = stock.requiresGTIN === 'true' ? 
            stock.gtin === "" || stock.gtin === null || stock.gtin.length < MINIMUM_GTIN_LENGTH : false;

            setValidationMessages(prevState => ({ 
                ...prevState,
                gtinError: validationMessages.gtinError,
                isPallet: "true",
                pickFromPallet: true 
            }));

        return validationMessages;
    }

    const validateConsignmentBox = () => {
        if (stock.isPallet === 'false') {
            const hasHeightError = isBoxSizeError(stock.boxHeight);
            const hasHeightOrWeightError = stock.boxWeight <= 0 || stock.boxHeight === "" || stock.boxHeight === null;

            validationMessages.consignmentBoxHeightError = hasHeightError;
            validationMessages.consignmentBoxWidthError = hasHeightError;
            validationMessages.consignmentBoxDepthError = hasHeightError;
            validationMessages.consignmentBoxWeightError = hasHeightOrWeightError;
        }

        setValidationMessages(prevState => ({ 
            ...prevState,
            consignmentBoxHeightError: validationMessages.consignmentBoxHeightError,
            consignmentBoxWidthError: validationMessages.consignmentBoxWidthError,
            consignmentBoxDepthError: validationMessages.consignmentBoxDepthError,
            consignmentBoxWeightError: validationMessages.consignmentBoxWeightError
        }));

        return validationMessages;
    }

    const validateBox = () => {
        if (stock.isPallet === 'false') {
            validationMessages.boxHeightError = isBoxSizeError(stock.stockBoxHeight);
            validationMessages.boxWidthError = isBoxSizeError(stock.stockBoxWidth);
            validationMessages.boxDepthError = isBoxSizeError(stock.stockBoxDepth);
            validationMessages.boxWeightError = isBoxSizeError(stock.stockBoxWeight);
        } else {
            validationMessages.boxHeightError = false;
            validationMessages.boxWidthError = false;
            validationMessages.boxDepthError = false;
            validationMessages.boxWeightError = false;
        }

        setValidationMessages(prevState => ({ 
            ...prevState,
            boxHeightError: validationMessages.boxHeightError,
            boxWidthError: validationMessages.boxWidthError,
            boxDepthError: validationMessages.boxDepthError,
            boxWeightError: validationMessages.boxWeightError
        }));
    }

    const isBoxSizeError = (value) => value <= 0 || value === "" || value === null;

    const renderForm = () => {
        return (
            <Fragment>
                <Row className="mt-3">
                    <Col sm={12} md={6}>
                        {activeStep === 0 ?
                            <Fragment>
                                <WarehouseInformation
                                    message={WAREHOUSE_MESSAGE}
                                    addressLine1={warehouse.addressLine1}
                                    addressLine2={warehouse.addressLine2}
                                    town={warehouse.town}
                                    county={warehouse.county}
                                    country={warehouse.country}
                                    postcode={warehouse.postcode}
                                />
                                <ItemSelection data={stock} handleInputChange={handleInputChange} loading={loading} itemValidation={itemValidation} companyItems={companyItems} skuCodes={skuCodes} skuCodeEmptyMessage={skuCodeEmptyMessage} />
                                <StepperButtons cancelLink={cancelLink} handleNext={handleNext} showNext={true} />
                            </Fragment>
                            : activeStep === 1 ?
                                <Fragment>
                                    <StockDetails data={stock} handleInputChange={handleInputChange} loading={loading} stockDetailsValidation={stockDetailsValidation} validationMessages={validationMessages} minGtinLength={MINIMUM_GTIN_LENGTH} itemLimit={itemLimit} />
                                    <StepperButtons cancelLink={cancelLink} handleBack={handleBack} showBack={true} handleNext={handleNext} showNext={true} />
                                </Fragment>
                                : activeStep === 2 ?
                                    <Fragment>
                                        <Shipping data={stock} handleInputChange={handleInputChange} labelChoice={labelChoice} supplierLabelValidation={supplierLabelValidation} />
                                        <StepperButtons cancelLink={cancelLink} handleBack={handleBack} showBack={true} handleNext={handleNext} showNext={true} />
                                    </Fragment>
                                    : activeStep === 3 ?
                                        <Fragment>
                                            <SingleConfirmation data={stock} companyItems={companyItems} handleEditStep={handleEditStep} />
                                            <StepperButtons cancelLink={cancelLink} handleBack={handleBack} showBack={true} handleNext={handleNext} showNext={true} nextButtonText="Confirm Stock Upload" />
                                        </Fragment>
                                        : <Fragment>
                                            <SuccessAlert successMessage={message}></SuccessAlert>
                                            <WarehouseInformation
                                                message={WAREHOUSE_MESSAGE}
                                                addressLine1={warehouse.addressLine1}
                                                addressLine2={warehouse.addressLine2}
                                                town={warehouse.town}
                                                county={warehouse.county}
                                                country={warehouse.country}
                                                postcode={warehouse.postcode}
                                            />
                                            <Link to={"/supplier/stockconsignments"} className="link-button">
                                                <Button variant="primary" className="float-right">Continue to Inventory</Button>
                                            </Link>
                                        </Fragment>
                        }
                    </Col>
                </Row>

                <Confirm title={MODAL_TITLE} text={getPickFromPalletModelText(stock.itemsPerBox)} buttonText={PFP_MODAL_CONFIRM} 
                    handleConfirmClose={handlePickFromPalletModalClose} handleConfirmAction={handlePickFromPalletModalConfirm} 
                    show={showPickFromPalletModal} cancelText={MODAL_CANCEL} size="lg" />

                <Confirm title={MODAL_TITLE} text={getBoxConfigurationModalText(stock.isPallet, stock.boxQuantity, stock.itemsPerBox, stock.stockQuantity)} 
                    buttonText={BOX_CONFIGURATION_MODAL_CONFIRM} handleConfirmClose={handleBoxConfigurationModalClose} 
                    handleConfirmAction={handleBoxConfigurationModalConfirm} show={showBoxConfigurationModal} cancelText={MODAL_CANCEL} />

                <Confirm title={PFP_MODAL_TITLE} text={PFP_MODAL_TEXT} buttonText="Yes" show={showPickFromPalletBoxModal} cancelText={PFP_MODAL_CANCEL} size="lg"
                    handleConfirmClose={handlePickFromPalletBoxModalClose} handleConfirmAction={handlePickFromPalletBoxModalConfirm} />

                <Confirm title={PFP_MODAL_TITLE} text={PFP_ITEM_MODAL_TEXT} buttonText="Yes" show={showPickFromPalletItemModal} cancelText={PFP_MODAL_CANCEL} size="lg"
                    handleConfirmClose={handlePickFromPalletItemModalClose} handleConfirmAction={handlePickFromPalletItemModalConfirm} />
            </Fragment>
        );
    }

    //#endregion

    //#region Render
    if (loading) return <LoadingBar />;

    return (
        <Fragment>
            <Header title="Inventory" subNavList={INVENTORY_SUB_NAV_LIST} activeKey="Send Single Product" />
            <Container fluid>
                {hasValidWarehouse && hasValidCardDetails
                    ? <Fragment>
                        <Stepper steps={steps} activeStep={activeStep} />
                        {renderForm()}
                    </Fragment>
                    : <Row>
                        <Col sm={12} md={6}>
                            <MissingDetails message={MISSING_DETAILS_MESSAGE} showWarehouse={!hasValidWarehouse} showCard={!hasValidCardDetails} />
                        </Col>
                    </Row>
                }
            </Container>
        </Fragment>
    );

    //#endregion
};

export default UploadSingleStock;