import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import { checkPermission, checkProfile } from '../../Utilities';

const ProtectedRoute = ({ feature, path, component, profile}) => {

    let route = <Route path={path} component={component} />;

    let result = checkPermission(feature, route);

    if(profile){
        result = checkProfile(profile, result);
    }
    
    return (
        <div>
            {result}
         </div>
    );
};

ProtectedRoute.propTypes = {
    component: PropTypes.func.isRequired,
    feature: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired  
};

export default ProtectedRoute;

