import React, { Component, useState, useEffect } from 'react';
import { Container, Row, Col, Button, ThemeProvider } from 'react-bootstrap';
import { POST} from '../../../../Consumer';
import Endpoints from '../../../common/Endpoints';
import Header from '../../../common/Header';
import { BULK_ORDER_SUB_NAV_LIST } from '../../../common/constants';
import Breadcrumbs from '../../../common/Breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { ErrorAlertDynamic } from '../../../common/Alert';
import './BulkOrderDetails.scss';
import { F, pipeP } from 'ramda';

const breadcrumbs = (headerKey, bulkOrderID) => [
    { text: "Bulk Orders", link: headerKey === "Pending Orders" ? "/retailer/bulkorder" : headerKey === "Orders in Process" ? "/retailer/bulkorder/inprocessorders" : headerKey === "Processed Orders" ? "/retailer/bulkorder/processedorders" : "/retailer/bulkorder/unsuccessfulorders" },
    { text: "Bulk Order Details", link: `/retailer/bulkorder/bulkorderdetails/${bulkOrderID}`, state: { headerKey: headerKey } },
    { text: "Split Details", link: null }
];



class BulkOrderSplitDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bulkOrder: props.location.state.bulkOrder || [],
            purchaseOrderNumber: props.location.state.purchaseOrderNumber || "",
            boxSplit: props.location.state.boxSplit || {},
            headerKey: props.location.state.headerKey || "",
            bulkOrderID: props.location.state.bulkOrderID || "",
            generalTracking: props.location.state.boxSplit.generalTracking || [{trackingNumber:'',courierProvider:''}],
            stateInputsGeneralTrackingCharged: true,
            stateInputsGeneralTracking: false,
            stateInputsBox: false,
            stateInputsBoxCharged: true,
            checkedButons: false,
            checked: props.location.state.boxSplit.generalTracking === null || props.location.state.boxSplit.generalTracking === undefined || props.location.state.boxSplit.generalTracking === '' ? false : true,
            checkedBox: props.location.state.boxSplit.boxes.courierProvider === null || props.location.state.boxSplit.boxes.courierProvider === undefined || props.location.state.boxSplit.boxes.courierProvider === '' ? false : true,
            error: false,
            errorMessage: null
        };        

        this.input = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.onFieldChange= this.onFieldChange.bind(this);
        this.onFieldChangeBox= this.onFieldChangeBox.bind(this);
        this.handleChangeBoxBreakdown = this.handleChangeBoxBreakdown.bind(this);
    }

    resultPost ( result ) {
        if (!result.data) {
            this.setState({ error: true, errorMessage : "Unable to save tracking details. Please try again."});

            return <ErrorAlertDynamic/>
            
        }else{
            this.editChangesGeneral();
            this.editChangesBox(true);                                          
            this.setState({ stateInputsGeneralTracking: true,stateInputsGeneralTrackingCharged: true, stateInputsBoxCharged: true,stateInputsBox: true, checkedButons: false, checkedBox: false});

        }
    }

    postBulkOrderPayload = () => {  
        
        this.setState({ error: false });
        this.state.bulkOrder.boxSplit.generalTracking= this.state.generalTracking;

        return POST(Endpoints.RETAILER.POST.BULK_ORDER_UPDATEPAYLOAD, this.state.bulkOrder)
            .then(response => response.json())
            .then(data => {
                this.resultPost(data);
                    
            })
            .catch(error => {              
                this.setState({ error: true });
                console.error(error); 
            });
    }


    handleChange(checkedButons, generalTracking) {
        this.setState({ checkedButons });
        if (generalTracking.trackingNumber === '' && generalTracking.courierProvider === '') {
            this.addNewTracking();
        }
    }

    handleChangeBoxBreakdown(checkedBox) {
        this.setState({ checkedBox });
    }

    addNewTracking() {
        let arr = [...this.state.generalTracking, { trackingNumber: "", courierProvider: "" }];

        this.state.bulkOrder.boxSplit.generalTracking= arr;
    
        this.setState({
             generalTracking: arr,
             bulkOrder: this.state.bulkOrder,
        });
    }

    onFieldChange = (event) =>{
        const fieldName = event.target.name;
        const fieldId = event.target.id;
        const fieldValue = event.target.value;
        const onlyId=fieldId.split('_')[1];

        let arr = [...this.state.generalTracking];
        
        if(fieldName==='trackingNumber'){
            arr[onlyId].trackingNumber=fieldValue;
        }else{
            arr[onlyId].courierProvider=fieldValue;
        }  

    }

    onFieldChangeBox = (event) =>{
        const fieldName = event.target.name;
        const fieldId = event.target.id;
        const fieldValue = event.target.value;
        const onlyId=fieldId.split('_')[1];

        let arr = [...this.state.boxSplit.boxes];
        
        if(fieldName==='trackingNumber'){
            arr[onlyId].trackingNumber=fieldValue;
        }else{
            arr[onlyId].courierProvider=fieldValue;
        }

    }
 
    editChangesGeneral(){
        this.setState({ stateInputsGeneralTracking: false,stateInputsGeneralTrackingCharged: false });
    }
    saveChangesGeneral(){
        this.postBulkOrderPayload();
    }  

    editChangesBox(){
        this.setState({ stateInputsBox: false, stateInputsBoxCharged: false});
    }
    saveChangesBox(){
        this.postBulkOrderPayload();
    }

    createGeneralTrackingSection = (section, i) => (
        <div key={`generalTracking_${i}`}>
            {section === "" || section === undefined || section === null || (section.trackingNumber === "" && section.courierProvider === "") ?
                this.state.checkedButons ? (
                    <React.Fragment>
                        <div className='textFields'>
                            <div>
                                <input name="trackingNumber" readOnly={this.state.stateInputsGeneralTracking} placeholder="Tracking Number" maxLength={50} type="text" id={`trackingNumber_${i}`} onChange={this.onFieldChange.bind(this)}  className="input-filter form-control marginBottom borderButtons" ></input>
                                <input name="courierProvider" readOnly={this.state.stateInputsGeneralTracking}  placeholder="Courier Provider" maxLength={50} type="text" id={`courierProvider_${i}`} onChange={this.onFieldChange.bind(this)}  className="input-filter form-control marginBottom borderButtons" ></input>
                            </div>

                        </div>
                    </React.Fragment>
                    ) : (
                        null
                    ) 
                : (
                    <div className='textFields'>                        
                        <div>
                            <input name="trackingNumber" maxLength={50} readOnly={this.state.stateInputsGeneralTrackingCharged} placeholder="Tracking Number" type="text" id={`trackingNumber_${i}`} onChange={this.onFieldChange.bind(this)} ref={myinput => (this.input = myinput)} className="input-filter form-control marginBottom borderButtons" defaultValue={this.state.generalTracking[i].trackingNumber}></input>
                            <input name="courierProvider"  maxLength={50} readOnly={this.state.stateInputsGeneralTrackingCharged} placeholder="Courier Provider" type="text" id={`courierProvider_${i}`} onChange={this.onFieldChange.bind(this)} ref={myinput => (this.input = myinput)}  className="input-filter form-control marginBottom borderButtons" defaultValue={this.state.generalTracking[i].courierProvider}></input>
                        </div>
                    </div>
                )
            }
        </div>
    )


    createBoxBreakdown = (box, i) => (

        <div key={`box_${i}`} className="mb-3 boxBreakdown">
            <div>
                <p className="title">Box {i + 1}</p>
                {box.items.map(item => item.quantity > 0 ?
                    item.sku
                        ? <React.Fragment key={`box_${i}_item_${item.companyItemID}`}>
                            <div>
                                <div>
                                    <p className="mb-1">{`${item.name}: ${item.quantity}`}</p><p className="small">{item.sku}</p>
                                </div>

                            </div>
                        </React.Fragment>
                        :
                        <p key={`box_${i}_item_${item.companyItemID}`} className="mb-1">{`${item.name}: ${item.quantity}`}</p>
                    : null)
                }
            </div>
            {box.courierProvider == null && box.trackingNumber == null ?
                this.state.checkedBox ? (
                    <div className='textFieldsBox'>
                        <div>
                            <input name="trackingNumber" readOnly={this.state.stateInputsBox} placeholder="Tracking Number" maxLength={50} type="text" id={`boxTrackingNumber_${i}`} onChange={this.onFieldChangeBox.bind(this)} className="input-filter form-control marginBottom borderButtons"></input>
                            <input name="courierProvider" readOnly={this.state.stateInputsBox} placeholder="Courier Provider" maxLength={50} type="text" id={`boxCourierProvider_${i}`} onChange={this.onFieldChangeBox.bind(this)} className="input-filter form-control marginBottom borderButtons"  ></input>
                        </div>
                    </div>

                ) :
                    (
                        null
                    )
                : (
                    <div className='textFieldsBox'>

                        <div>
                            <input name="trackingNumber" readOnly={this.state.stateInputsBoxCharged} maxLength={50} placeholder="Tracking Number" type="text" id={`boxTrackingNumber_${i}`} onChange={this.onFieldChangeBox.bind(this)} className="input-filter form-control marginBottom borderButtons" ref={myinput => (this.input = myinput)} defaultValue={this.state.boxSplit.boxes[i].trackingNumber}></input>
                            <input name="courierProvider" readOnly={this.state.stateInputsBoxCharged} maxLength={50} placeholder="Courier Provider" type="text" id={`boxCourierProvider_${i}`} onChange={this.onFieldChangeBox.bind(this)} className="input-filter form-control marginBottom borderButtons" ref={myinput => (this.input = myinput)} defaultValue={this.state.boxSplit.boxes[i].courierProvider}></input>
                        </div>
                    </div>
                )
            }

        </div>
    );



    render() {
        const { purchaseOrderNumber, boxSplit, headerKey, bulkOrderID, generalTracking} = this.state;


        return (
            <React.Fragment>
                <Header title="Bulk Order" subNavList={BULK_ORDER_SUB_NAV_LIST} activeKey={headerKey} headerClass="mb-2" />
                <Container fluid>
                    <Breadcrumbs breadcrumbs={breadcrumbs(headerKey, bulkOrderID)} />
                    <Row className="mb-4">
                        <Col sm={12} md={6}>
                            {purchaseOrderNumber &&
                                <React.Fragment>
                                    <h5>Split Details</h5>
                                    <section className="mt-3 mb-3">
                                        <p className="title">Purchase Order Number</p>
                                        <p>{purchaseOrderNumber}</p>
                                    </section>
                                </React.Fragment>
                            }
                            {boxSplit &&
                                <React.Fragment>
                                    <section>
                                        <div id='generalTracking' className='addTrackings'>
                                            <h6 className="mt-3 mb-3 title trackingButtons">General Tracking<span className="ml=1"><Button onClick={() => this.handleChange(true, generalTracking)} variant="link" className="p-1 ml-2" >Add Tracking</Button><FontAwesomeIcon size="sm" icon={faEdit} /></span></h6>
                                            {this.state.checkedButons ? (
                                                <React.Fragment>
                                                    <div className='marginLeftButtons'>

                                                        <section className='buttonsTracking' id='buttonsTracking'>
                                                            <button onClick={() => this.editChangesGeneral()}  type="submit" className="link-button btn btn-secondary btn-sm">Edit</button>
                                                            <button onClick={() => this.saveChangesGeneral()} type="submit" className="btn btn-primary btn-sm">Save</button>
                                                        </section>
                                                    </div>
                                                    {/* {results.map((result, i) => <UploadResult key={`upload-result-${i}`} result={result} />)} */}
                                                    
                                                </React.Fragment>
                                            ) : (
                                                null
                                            )}
                                        </div>
                                        <section className="mt-3">
                                            { this.state.error ?<ErrorAlertDynamic errorMessage={this.state.errorMessage} />:null}
                                            { this.state.generalTracking !== null ? (

                                                generalTracking.map((section, i) => this.createGeneralTrackingSection(section, i))
                                            )
                                            : (
                                                null
                                            )}
                                            {this.state.checkedButons ? (
                                                <div className='buttonFixed'>
                                                    <Button variant="link" onClick={() => this.addNewTracking()}>
                                                        <FontAwesomeIcon size="lg" className='circleButton' icon={faPlusCircle} />
                                                    </Button>
                                                </div>
                                            ):(
                                                null
                                            )}
                                        </section>

                                    </section>
                                    <section>
                                        <div id='boxTracking' className='addTrackings'>
                                            <h5>Box Breakdown<span className="ml=1"><Button onClick={() => this.handleChangeBoxBreakdown(true)} variant="link" className="p-1 ml-2" >Add Tracking</Button><FontAwesomeIcon size="sm" icon={faEdit} /></span></h5>
                                            {this.state.checkedBox ? (
                                                <React.Fragment>
                                                    <div className='marginLeftButtons'>
                                                        <section className='buttonsTracking marginTop' id='buttonsBoxTracking'>

                                                            <button id='editBoxTracking' onClick={() => this.editChangesBox()}  type="submit" className="link-button btn btn-secondary btn-sm">Edit</button>
                                                            <button  id='saveBoxTracking' onClick={() => this.saveChangesBox()} type="submit" className="btn btn-primary btn-sm">Save</button>
                                                        </section>
                                                    </div>
                                                </React.Fragment>
                                            ) : (
                                                null
                                            )}
                                        </div>
                                        <section className="mt-3">
                                            {boxSplit.boxes.map((box, i) => this.createBoxBreakdown(box, i))}

                                        </section>
                                    </section>
                                </React.Fragment>
                            }
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default BulkOrderSplitDetails;