import React from "react";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";

export const INVENTORY_READ_AND_WRITE_ACCESS_ID =
  "014102c3-d046-433f-b448-d646bb3d87af";
export const ORDER_READ_AND_WRITE_ACCESS_ID =
  "fac63c12-4496-411c-b63b-3e232bb5a4a4";

export const SQSFormValidationRules = [
  {
    field: "url",
    method: "isEmpty",
    validWhen: false,
    message: "SQS URL is required",
  },
  {
    field: "accessKey",
    method: "isEmpty",
    validWhen: false,
    message: "Access Key is required",
  },
  {
    field: "privateKey",
    method: "isEmpty",
    validWhen: false,
    message: "Secret Key is required",
  },
];

export const SQSForm = ({ validator, changeHandler, sqsSettings, type }) => (
  <section className="mt-med">
    <FormGroup className="custom-control custom-checkbox">
      <input
        id={`${type}-Enabled`}
        className="custom-control-input"
        type="checkbox"
        name={`${type}SqsEnabled`}
        checked={sqsSettings.enabled}
        onChange={(e) => changeHandler("enabled", e.target.checked)}
      />
      <FormLabel className="custom-control-label" htmlFor={`${type}-Enabled`}>
        Receive notifications via SQS
      </FormLabel>
    </FormGroup>
    {sqsSettings.enabled && (
      <React.Fragment>
        <p className="mb-1">SQS URL</p>
        <FormGroup>
          <FormControl
            id={`${type}-URL`}
            type="text"
            name="url"
            value={sqsSettings.url}
            onChange={(e) => changeHandler("url", e.target.value)}
          />
          <span className="text-danger">{validator.url.message}</span>
        </FormGroup>
        <p className="mb-1">Access Key</p>
        <FormGroup>
          <FormControl
            id={`${type}-AccessKey`}
            type="text"
            name="accessKey"
            value={sqsSettings.accessKey}
            onChange={(e) => changeHandler("accessKey", e.target.value)}
          />
          <span className="text-danger">{validator.accessKey.message}</span>
        </FormGroup>
        <p className="mb-1">Secret Key</p>
        <FormGroup>
          <FormControl
            id={`${type}-PrivateKey`}
            type="password"
            name="privateKey"
            value={sqsSettings.privateKey}
            onChange={(e) => changeHandler("privateKey", e.target.value)}
            autocomplete="new-password"
          />
          <span className="text-danger">{validator.privateKey.message}</span>
        </FormGroup>
      </React.Fragment>
    )}
  </section>
);
