import React, { Fragment, useState, useEffect } from 'react';
import Endpoints from '../../../common/Endpoints';
import LoadingBar from '../../../common/LoadingBar';
import { ErrorAlert } from '../../../common/Alert';
import { GET_ASYNC, extractData } from '../../../../Consumer';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from "react-router-dom";

const ERROR_MESSAGE = "Cannot retrieve return information";

const CustomerDetails = ({ returnData }) => (
    <div className="section-item">
        <h6>Customer Details</h6>
        <p className="title">Order Number</p>
        <p>{returnData.orderReference}</p>
        <p className="title">Name</p>
        <p>{returnData.customerName}</p>
        <Link to={"/retailer/orders/orderdetails/" + returnData.orderID} >
            <p>View Original Order</p>
        </Link>
        <p className='title'>Return Service</p>
        <p>{returnData.returnService}</p>
    </div>
);

const ProductDetails = ({ returnItems }) => (
    returnItems.length ? returnItems.map(r => <ProductDetail key={`${r.retailerReturnID}`} item={r} />) : <p>No Products</p>
)

const ProductDetail = ({ item }) => (
    <div className="section-item">
        <h6>Products</h6>
        <p className="title">{item.itemName}</p>
        <p>{item.skucode}</p>
        <Row>
            <Col sm={12} md={6}>
                <p className="title">Product Images</p>
                <div className="image-product-container">
                    <Image src={item.productImage}  />
                </div>
            </Col>
            <Col sm={12} md={6}>
                <p className="title">Warehouse Photo</p>
                <div className="image-product-container">
                    <Image src={`data:image/jpeg;base64,${item.warehouseImage}`}  />
                </div>
            </Col>
        </Row>
        <p className="title">Warehouse Grading</p>
        <p>{item.grade} - {item.action}</p>
    </div>
);

const ViewReturn = () => {

    //#region State

    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const [returnData, setReturnData] = useState(null);

    //#endregion

    //#region API

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {

                const url = new URL(Endpoints.RETURNS.GET.PROCESSED_RETURN_BY_ID);
                const response = await GET_ASYNC(url + id);

                if (response?.ok) {
                    const responseData = await response.json();
                    const data = extractData(responseData)
                    setReturnData(data);
                } else {
                    setShowError(true);
                }

            } catch (error) {
                console.log({ error });
                setShowError(true);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    //#endregion

    return (
        loading
            ? <LoadingBar />
            : <Fragment>
                <Container fluid>
                    <Col sm={12} md={6}>
                        <h2 className="mb-4">Return Details</h2>
                        <Link to={"/retailer/returns"} className="link-button">
                            <Button variant="link" className="button-nav-return p-0 mb-4"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Returns</Button>
                        </Link>
                        {showError
                            ? <ErrorAlert errorMessage={ERROR_MESSAGE} />
                            : <Fragment>
                                {returnData && <CustomerDetails returnData={returnData} />}
                                {returnData && <ProductDetails returnItems={returnData.retailerReturnItems} />}
                            </Fragment>
                        }
                    </Col>
                </Container>
            </Fragment>
    );
}

export default ViewReturn;
