import React, { Component } from 'react';
import { Container, Row, Col, Form, FormControl, FormLabel, FormGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoadingBar from '../../common/LoadingBar';
import { GET, extractData, PUT } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import FormValidator from '../../common/FormValidator';
import { isNullOrEmptyGuid } from '../../../Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ErrorAlert, SuccessAlert } from '../../common/Alert';

const strongPasswordPattern = /^(?=.*[a-z])(?=.*[0-9])(?=.*[#$^+=!*()@%&"'[\]{},_\\/;:`\-~<>]).{6,}/;
const numberPattern = /^(?=.*[0-9])/;
const specialCharPattern = /^(?=.*[#$^+=!*()@%&"'[\]{},_\\/;:`\-~<>])/;
const lengthPattern = /^.{6,}/;

const USER_ERROR_MESSAGE = "Could not retrieve user";
const PASSWORD_ERROR_MESSAGE = "Could not update password";

const SuccessfulUpdate = ({ userId }) =>
    <React.Fragment>
        <SuccessAlert successTitle="Success" successMessage="Password change successfully" />
        <Link to={`/company/users/account/${userId}`} className="float-right">
            <Button variant="primary">Return to User Account</Button>
        </Link>
    </React.Fragment>

class Password extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'password',
                method: 'matches',
                args: [strongPasswordPattern],
                validWhen: true,
                message: 'Password must contain at least one number, one special character and at least 6 characters'
            },
            {
                field: 'password',
                method: 'isLength',
                args: [{min: 6, max: 50}],
                validWhen: true,
                message: 'Password must be between 6 and 50 characters long'
            },
        ]);

        this.state = {
            loading: true,
            validation: this.validator.valid(),
            errors: [],
            success: false,
            passwordNumberCheck: false,
            passwordSpecialCharCheck: false,
            passwordLengthCheck: false,
            passwordInputType: 'password',
            passwordInputIcon: faEye,
            user: {
                id: '',
                password: '',
            },
            validationMessages: {
                passwords: false
            }
        };
    }

    componentDidMount = () => this.fetchUser()

    validate = (user) => {
        let validation = this.validator.validate(user);

        if (!user.password) {
            validation.isValid = false;
            validation.password.isInvalid = true;
        }
        return validation;
    }

    fetchUser = () => {
        const { id } = this.props.location.state.user;
        if (!isNullOrEmptyGuid(id)) {
            return GET(Endpoints.GET_USER + id)
                .then(response => response.json())
                .then(result => {
                    const data = extractData(result);
                    if (data === null) this.setState({ user: [], errors: [result.message] || [USER_ERROR_MESSAGE], loading: false });
                    else if (data === undefined) this.setState({ user: [], errors: [USER_ERROR_MESSAGE], loading: false });
                    else {
                        const user = { ...data, roles: data.userRoles.map((ur) => ur.roleID) };
                        this.setState({ user: user, loading: false });
                    }
                })
                .catch(error => {
                    this.setState({ errors: [USER_ERROR_MESSAGE], loading: false });
                    console.log(error);
                });

        } else this.setState({ loading: false });
    }

    handleInputChange = (e) => {
        const { value, name } = e.target;

        const numberPass = numberPattern.test(value);
        const specialCharPass = specialCharPattern.test(value);
        const lengthPass = lengthPattern.test(value);

        this.setState(prevState => ({
            passwordNumberCheck: numberPass,
            passwordSpecialCharCheck: specialCharPass,
            passwordLengthCheck: lengthPass,
            user: {
                ...prevState.user,
            },
            validationMessages: {
                ...prevState.validationMessage
            }
        }));

        this.setState(prevState => ({
            ...prevState,
            user: {
                ...prevState.user,
                ...{ [name]: value }
            }
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { user } = this.state;

        if (!user.password) user.password = '';

        const validation = this.validator.validate(user);
        this.setState({ validation: validation, loading: validation.isValid });
        this.submitted = true;

        if (validation.isValid) {
            PUT(Endpoints.EDIT_PASSWORD_USER, user)
                .then(response => response.json())
                .then(result => {
                    const data = extractData(result);
                    if (data === null) this.setState({ errors: [result.message] || [PASSWORD_ERROR_MESSAGE], success: false, loading: false });
                    else if (data === undefined) this.setState({ errors: [PASSWORD_ERROR_MESSAGE], success: false, loading: false });
                    else this.setState({ success: true, errors: [], loading: false });
                })
                .catch(error => {
                    this.setState({ errors: [PASSWORD_ERROR_MESSAGE], loading: false });
                    console.log(error);
                });
        }
        else this.setState({ loading: false });
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ?
            this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) :
            this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    render() {
        const { loading, errors, user, success, passwordNumberCheck, passwordSpecialCharCheck, passwordLengthCheck, passwordInputType, passwordInputIcon } = this.state;
        const validation = this.submitted ? this.validate(user) : this.state.validation;

        return (
            <Container fluid>
                <h2><strong>Change Password</strong></h2>
                {loading ? <LoadingBar /> : null}
                {
                    <Row className="mt-4">
                        <Col sm={12} md={6}>
                            {errors.length > 0 && <ErrorAlert errorMessage={<React.Fragment>{errors.map((e, i) => e ? <p key={i}>{e}</p> : null)}</React.Fragment>} />}
                            {success ? <SuccessfulUpdate userId={user.id} /> :
                                <Form onSubmit={this.handleSubmit}>
                                    <fieldset>
                                        <FormGroup>
                                            <FormLabel htmlFor="password"><strong>Password</strong></FormLabel>
                                            <FormControl id="password" type={passwordInputType} name="password" value={user.password} maxLength="50" onChange={this.handleInputChange} />
                                            <span className="form-password-icon" onClick={this.handleShowHidePassword}><FontAwesomeIcon icon={passwordInputIcon} /></span>
                                            <span className="text-danger">{validation.password.message}</span>
                                        </FormGroup>
                                        <div className="form-password-checklist">
                                            <ul>
                                                <li className={passwordNumberCheck ? "pass" : "fail"}>One number</li>
                                                <li className={passwordSpecialCharCheck ? "pass" : "fail"}>One special character</li>
                                                <li className={passwordLengthCheck ? "pass" : "fail"}>Six characters long</li>
                                            </ul>
                                        </div>
                                    </fieldset>
                                    <FormGroup className="d-flex float-right">
                                        <Link to={`/company/users/account/${user.id}`} className="mr-3 mt-2">Cancel</Link>
                                        <Button className="btn btn-primary" type="submit">Change Password</Button>
                                    </FormGroup>
                                </Form>}
                        </Col>
                    </Row>
                }
            </Container>
        );
    }
}

export default Password;