import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { PREFERENCES_SUB_NAV_LIST } from '../../../common/constants'
import Header from '../../../common/Header';
import Endpoints from '../../../common/Endpoints';
import { GET, extractData } from '../../../../Consumer';
import LoadingBar from '../../../common/LoadingBar';

const CustomPackagingPreferences = ({ customPackaging, extras, history }) => {
    const enabled = (customPackaging.boxes.count > 0 || customPackaging.mailingBags.count > 0 || extras.find(e => e.enabled === true) !== undefined);

    return (
        <Fragment>
            <p className="my-1"><strong>Custom Packaging</strong></p>
            <p className="my-1">{enabled ? "Enabled" : "Disabled"}</p>
            <Button variant="link" className="p-0" onClick={() => history.push("/retailer/preferences/packaging/custompackaging")}>Change Preference</Button>
        </Fragment>
    )
}

class Packaging extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            customPackaging: {},
            extras: []
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchPackagingOverview(),
            this.fetchPackagingExtras()
        ]);
        this.setState({ loading: false });
    }

    fetchPackagingOverview = async () => {
        return await GET(Endpoints.PACKAGING.GET.CUSTOM_OVERVIEW)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ customPackaging: extractData(results) });
            })
            .catch(error => console.log(error));
    }

    fetchPackagingExtras = async () => {
        return await GET(Endpoints.PACKAGING.GET.EXTRAS)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ extras: extractData(results) });
            })
            .catch(error => console.log(error));
    }

    render() {
        const { loading, customPackaging, extras } = this.state;
        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <Header title="Preferences" subNavList={PREFERENCES_SUB_NAV_LIST} activeKey="Packaging" />
                    <Container fluid>
                        <Row>
                            <Col sm={12} md={6}>
                                <h5 className="mb-3">Packaging Preferences</h5>
                                <CustomPackagingPreferences customPackaging={customPackaging} extras={extras} history={this.props.history} />
                            </Col>
                        </Row>
                    </Container>
                </Fragment>
        )
    }
}

export default Packaging;